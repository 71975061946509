import React, { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import { Button, Skeleton } from "antd"
import { toast } from "react-toastify"

import { Form, Input } from "@dbai/ui-staples"

import { selectRole } from "selectors"
import { actions } from "reducers/roleReducer"
import { EditablePolicyMatrix, RuleEffect, OnCol } from "./PolicyMatrix"
import { ActionsSelect, SubjectSelect } from "./Policies"

// Unfortunately, AntD's Skeleton component applies the className to two
// places, the wrapping `div` and the internal element that forms the gray
// bar we see as the actual "skeleton". This prevents us from simply targeting
// the outermost element with a className, as the inner object gets
const StyledSkeleton = styled(Skeleton.Input)`
  &&.ant-skeleton {
    // This is the outmost element.
    display: block;
  }

  && & {
    // This selector is not a typo! The className ends up being a child of
    // itself, so we can target it with '& &'. The extra ampersands are for
    // specificity.

    width: 100%;
    min-width: initial;
  }
`

const NewRow = props => {
  const dispatch = useDispatch()

  const addPolicy = useCallback(() => {
    dispatch(actions.addPolicy())
  }, [dispatch])

  return (
    <>
      <StyledSkeleton />
      <StyledSkeleton />
      <StyledSkeleton />
      <StyledSkeleton />
      <Button onClick={addPolicy}>Add</Button>
    </>
  )
}

const EditableRow = props => {
  const { policy } = props

  const policyId = policy.id
  const dispatch = useDispatch()

  const setRuleActions = useCallback(
    ({ value }) => dispatch(actions.setPolicyActions({ policyId, value })),
    [policyId, dispatch]
  )

  const setRuleSubjects = useCallback(
    ({ value }) => dispatch(actions.setPolicySubjects({ policyId, value })),
    [policyId, dispatch]
  )

  const deletePolicy = useCallback(
    () => dispatch(actions.deletePolicy({ policyId })),
    [policyId, dispatch]
  )

  return (
    <>
      <RuleEffect effect="ALLOW">ALLOW</RuleEffect>
      <ActionsSelect value={policy.rule.actions} onChange={setRuleActions} />
      <OnCol />
      <SubjectSelect value={policy.rule.subjects} onChange={setRuleSubjects} />
      <Button onClick={deletePolicy} danger>
        Delete
      </Button>
    </>
  )
}

const RoleForm = props => {
  const { closePanel } = props

  const role = useSelector(selectRole)
  const dispatch = useDispatch()

  const handleSubmit = useCallback(() => {
    const actionFn = role.id === null ? actions.createRole : actions.updateRole

    return dispatch(actionFn())
      .then(result => {
        closePanel()
        toast.success("Saved Role!")
        return result
      })
      .catch(error => {
        console.error(error?.message)
        toast.error("Error saving Role!")
      })
  }, [dispatch, role.id, closePanel])

  return (
    <Form
      state={role}
      dispatch={dispatch}
      actions={actions}
      onSubmit={handleSubmit}
    >
      <Input name="name" />
      <Input name="description" />
      <div className="form-group">
        <label className="form-label">Policy Rules</label>
        <EditablePolicyMatrix>
          {role?.policies.map(policy => {
            return <EditableRow key={policy.id} policy={policy} />
          })}
          <NewRow />
        </EditablePolicyMatrix>
      </div>
    </Form>
  )
}

export default RoleForm
