import React, { useCallback } from "react"
import styled from "styled-components"
import { actions } from "reducers/notebookReducer"
import {
  faAtom,
  faBarChart,
  faBars,
  faBarsFilter,
  faBarsProgress,
  faBarsSort,
  faBell,
  faBinoculars,
  faBolt,
  faBoxArchive,
  faBracketsCurly,
  faBrain,
  faBrainCircuit,
  faBullhorn,
  faBullseye,
  faCalculator,
  faCalendar,
  faChartArea,
  faChartBar,
  faChartCandlestick,
  faChartColumn,
  faChartLine,
  faChartPie,
  faChartScatterBubble,
  faChartSimple,
  faChartTreeMap,
  faCheck,
  faCircleInfo,
  faCirclesOverlap,
  faClock,
  faClone,
  faCloud,
  faCloudArrowDown,
  faCloudArrowUp,
  faCodeCompare,
  faComputerClassic,
  faCopy,
  faCrosshairs,
  faCube,
  faCubes,
  faDatabase,
  faDesktop,
  faDiagramVenn,
  faDownload,
  faEraser,
  faExpand,
  faEye,
  faFile,
  faFilter,
  faFingerprint,
  faFlask,
  faFloppyDisk,
  faFolderOpen,
  faGear,
  faGlasses,
  faHand,
  faLaptop,
  faLayerGroup,
  faLightbulb,
  faLink,
  faListCheck,
  faMedal,
  faMerge,
  faMessageBot,
  faMicrochip,
  faMicrochipAi,
  faNetworkWired,
  faNotebook,
  faPaintRoller,
  faPallet,
  faPaperclip,
  faPaperPlane,
  faPaste,
  faPencil,
  faPenToSquare,
  faPlusCircle,
  faProjectDiagram,
  faPuzzlePiece,
  faRankingStar,
  faRecycle,
  faRepeat,
  faRocketLaunch,
  faRuler,
  faServer,
  faSitemap,
  faSliders,
  faSplit,
  faStar,
  faStopwatch,
  faSuperscript,
  faTable,
  faTableCells,
  faTag,
  faTerminal,
  faText,
  faToggleOn,
  faTrash,
  faUserRobot,
  faUsers,
  faWandSparkles,
  faWatch,
  faWavePulse,
  faWrenchSimple,
} from "@fortawesome/pro-solid-svg-icons"
import { useDispatch } from "react-redux"
import { FAMemo } from "@dbai/ui-staples"

const nodeIconMap = {
  Atom: faAtom,
  "Bar Chart": faBarChart,
  Bars: faBars,
  "Filter Bars": faBarsFilter,
  "Progress Bars": faBarsProgress,
  "Bars Sort": faBarsSort,
  Bell: faBell,
  Binoculars: faBinoculars,
  Bolt: faBolt,
  Archive: faBoxArchive,
  "Curly Brackets": faBracketsCurly,
  Brain: faBrain,
  "Brain Circuit": faBrainCircuit,
  Bullhorn: faBullhorn,
  Bullseye: faBullseye,
  Calculator: faCalculator,
  Calendar: faCalendar,
  "Area Chart": faChartArea,
  "Chart Bar": faChartBar,
  "Candlestick Chart": faChartCandlestick,
  "Column Chart": faChartColumn,
  "Line Chart": faChartLine,
  "Pie Chart": faChartPie,
  "Chart Scatter Bubble": faChartScatterBubble,
  "Chart Simple": faChartSimple,
  "Chart Tree Map": faChartTreeMap,
  Check: faCheck,
  Info: faCircleInfo,
  "Circles Overlap": faCirclesOverlap,
  Clock: faClock,
  Clone: faClone,
  Cloud: faCloud,
  "Cloud Download": faCloudArrowDown,
  "Cloud Upload": faCloudArrowUp,
  "Code Compare": faCodeCompare,
  Computer: faComputerClassic,
  Copy: faCopy,
  Crosshairs: faCrosshairs,
  Cube: faCube,
  Cubes: faCubes,
  Database: faDatabase,
  Desktop: faDesktop,
  Venn: faDiagramVenn,
  Download: faDownload,
  Eraser: faEraser,
  Expand: faExpand,
  Eye: faEye,
  File: faFile,
  Filter: faFilter,
  Fingerprint: faFingerprint,
  Flask: faFlask,
  Floppy: faFloppyDisk,
  Folder: faFolderOpen,
  Gear: faGear,
  Glasses: faGlasses,
  Hand: faHand,
  Laptop: faLaptop,
  Layers: faLayerGroup,
  Lightbulb: faLightbulb,
  Link: faLink,
  Checklist: faListCheck,
  Medal: faMedal,
  Merge: faMerge,
  Bot: faMessageBot,
  Microchip: faMicrochip,
  "AI Microchip": faMicrochipAi,
  Network: faNetworkWired,
  Notebook: faNotebook,
  Roller: faPaintRoller,
  Pallet: faPallet,
  Paperclip: faPaperclip,
  Plane: faPaperPlane,
  Paste: faPaste,
  Pencil: faPencil,
  Pen: faPenToSquare,
  Plus: faPlusCircle,
  Project: faProjectDiagram,
  Puzzle: faPuzzlePiece,
  Ranking: faRankingStar,
  Recycle: faRecycle,
  Repeat: faRepeat,
  Rocket: faRocketLaunch,
  Ruler: faRuler,
  Server: faServer,
  Sitemap: faSitemap,
  Sliders: faSliders,
  Split: faSplit,
  Star: faStar,
  Stopwatch: faStopwatch,
  Superscript: faSuperscript,
  Table: faTable,
  Cells: faTableCells,
  Tag: faTag,
  Terminal: faTerminal,
  Text: faText,
  Toggle: faToggleOn,
  Trash: faTrash,
  Robot: faUserRobot,
  Users: faUsers,
  Wand: faWandSparkles,
  Watch: faWatch,
  Pulse: faWavePulse,
  Wrench: faWrenchSimple,
}

const iconMap = {
  script: "Terminal",
  download: "Download",
  source: "Database",
  sink: "Database",
  empty: "Expand",
  action: "Link",
}

const StyledIconList = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
  font-size: 24px;
  font-weight: normal;
`

const StyledIcon = styled.div`
  padding: 2px 7px;
  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.primary};
  }
  background-color: ${props => (props.selected ? props.theme.primary : "")};
`

const IconPicker = props => {
  const { nodeIdx, selectedIcon, afterChange } = props
  const dispatch = useDispatch()

  const changeIcon = useCallback(
    icon => {
      if (!icon) return
      dispatch(
        actions.updateNodeField({
          nodeIdx,
          field: "icon",
          value: icon,
        })
      )
      afterChange && afterChange()
    },
    [dispatch, afterChange, nodeIdx]
  )

  return (
    <StyledIconList>
      {Object.keys(nodeIconMap).map(icon => {
        return (
          <StyledIcon
            selected={selectedIcon === icon}
            onClick={() => changeIcon(icon)}
          >
            <FAMemo icon={nodeIconMap[icon]} />
          </StyledIcon>
        )
      })}
    </StyledIconList>
  )
}

export { nodeIconMap, iconMap, StyledIconList, StyledIcon }
export default IconPicker
