import { gql } from "@apollo/client"

const getIdentitiesName = "GetIdentites"
export const GET_IDENTITIES = gql`
  query ${getIdentitiesName}($cname: String!) {
    customer(name: $cname) {
      id
      identities {
        id
        name
        createdAt
        roles {
          id
          name
        }

        ... on User {
          email
        }

        ... on ServiceAccount {
          uuid
        }
      }
      invitations {
        id
        email
        accepted
        roles {
          id
          name
        }
      }
    }
  }
`
