import React from "react"
import styled from "styled-components"

import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"

const HeaderOptions = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
  button {
    margin: 0 10px;
  }
`
const workflowsPath = "/:cname/workflows"

export const workflowPaths = {
  workflows: workflowsPath,
  schedules: `${workflowsPath}/schedules`,
  library: `${workflowsPath}/library`,
  components: `${workflowsPath}/components`,
  packages: `${workflowsPath}/packages`,
}

const IndexHeader = props => {
  const { customer } = props
  const routes = [
    { breadcrumbName: customer.name },
    { breadcrumbName: "Workflows" },
    { breadcrumbName: "Schedules", path: workflowPaths.schedules },
    { breadcrumbName: "Library", path: workflowPaths.library },
    { breadcrumbName: "Components", path: workflowPaths.components },
    { breadcrumbName: "Packages", path: workflowPaths.packages },
  ]

  return (
    <PageHeader routes={routes}>
      <HeaderOptions>{props.children}</HeaderOptions>
    </PageHeader>
  )
}

export default withCustomer(IndexHeader)
