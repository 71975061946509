import appThemeSchema from "./appThemeSchema"

const appConfigSchema = {
  title: "App Settings",
  type: "object",
  required: ["name"],
  metadata: {
    sectionType: "tabs",
    sections: {
      theme: {
        title: "App Theme",
        properties: ["name", "themeId", "theme"],
      },
      appHeader: {
        title: "App Header",
        properties: ["appHeader"],
      },
      layout: {
        title: "Page Settings",
        properties: ["breakpoint", "layout"],
      },
      dataProperties: {
        title: "Data-Related Properties",
        properties: ["refreshInterval"],
      },
    },
  },
  properties: {
    refreshInterval: {
      type: ["number", "null"],
      title: "Refresh Interval (minutes)",
      minimum: 1,
      default: 15,
      nullable: true,
      description:
        "Determines how often the page automatically refreshes to show new data.",
      metadata: {
        fieldProps: {
          step: 1,
          precision: 2,
          placeholder: "Refresh interval",
        },
        formatter: {
          format: "number",
          suffix: "min",
        },
      },
    },
    name: {
      type: "string",
      title: "App Name",
      default: "My App",
    },
    appHeader: {
      type: "object",
      title: "App Header",
      properties: {
        enablePageHeader: {
          title: "Show Page Header",
          type: "boolean",
          default: false,
        },
        "/logo": {
          $ref: "#/$defs/base64ImageUpload",
          title: "Logo",
        },
        title: {
          title: "Title",
          type: "string",
        },
      },
    },
    themeId: {
      type: ["string", "null"],
      nullable: true,
      default: "default",
      title: "Theme Presets",
      metadata: {
        themeSchemaPath: "theme",
        component: "ThemeSelector",
      },
    },
    theme: appThemeSchema,
    layout: {
      type: "object",
      title: "Page Layout",
      default: {},
      dependencies: {
        compactionMethod: {
          allOf: [
            {
              if: {
                properties: {
                  compactionMethod: {
                    enum: ["horizontal", "vertical"],
                  },
                },
              },
              then: {
                properties: {
                  preventCollision: {
                    title: "Keep Widgets Static on Move",
                    type: "boolean",
                    default: false,
                  },
                  horizontalMargin: {
                    title: "Left/Right Widget Padding",
                    type: "number",
                    default: 1,
                    minimum: 0,
                    maximum: 100,
                  },
                  verticalMargin: {
                    title: "Top/Bottom Widget Padding",
                    type: "number",
                    default: 1,
                    minimum: 0,
                    maximum: 100,
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  compactionMethod: {
                    enum: ["freeform"],
                  },
                },
              },
              then: {
                properties: {
                  allowOverlap: {
                    title: "Allow Overlap",
                    type: "boolean",
                    default: true,
                  },
                },
              },
            },
          ],
        },
      },
      properties: {
        compactionMethod: {
          type: "string",
          default: "freeform",
          title: "Compaction Method",
          enum: ["freeform", "horizontal", "vertical"],
          description:
            "The method used to compact the widgets in the container. Freeform allows widgets to overlap and move freely. Horizontal and vertical compact the widgets into a single column or row respectively.",
          metadata: {
            component: "Segmented",
            labels: ["Freeform", "Horizontal", "Vertical"],
          },
        },
        verticalPadding: {
          title: "Top/Bottom Container Padding",
          type: "number",
          minimum: 0,
          maximum: 100,
          metadata: {
            component: "SliderInput",
            showNumberInput: true,
          },
        },
        horizontalPadding: {
          title: "Left/Right Container Padding",
          type: "number",
          minimum: 0,
          maximum: 100,
          metadata: {
            component: "SliderInput",
            showNumberInput: true,
          },
        },
        rowHeight: {
          title: "Row Height",
          type: "number",
          minimum: 1,
          maximum: 100,
          default: 30,
          metadata: {
            component: "SliderInput",
            showNumberInput: true,
          },
        },
        // breakpoints: {
        //   title: "Breakpoint Configurations",
        //   type: "object",
        //   default: {},
        //   // metadata: {
        //   //             component: "PageColumnsInput",
        //   //             showNumberInput: true,
        //   //           },
        //   properties: {
        //     lg: {
        //       title: "First Breakpoint",
        //       type: "object",
        //       metadata: {
        //         hideLabel: false,
        //       },
        //       properties: {
        //         "breakpoints/lg/pxVal": {
        //           title: "Pixel Threshold",
        //           type: "number",
        //           minimum: 0,
        //           default: 1200,
        //           maximum: 2000,
        //           metadata: {
        //             component: "SliderInput",
        //             showNumberInput: true,
        //           },
        //         },
        //         "breakpoints/lg/cols": {
        //           title: "Columns",
        //           type: "number",
        //           minimum: 1,
        //           default: 120,
        //           maximum: 200,
        //           metadata: {
        //             component: "SliderInput",
        //             showNumberInput: true,
        //           },
        //         },
        //       },
        //     },
        //     md: {
        //       title: "Second Breakpoint",
        //       type: "object",
        //       metadata: {
        //         hideLabel: false,
        //       },
        //       properties: {
        //         "breakpoints/lg/pxVal": {
        //           title: "Pixel Threshold",
        //           type: "number",
        //           minimum: 0,
        //           default: 996,
        //           maximum: 2000,
        //           metadata: {
        //             component: "SliderInput",
        //             showNumberInput: true,
        //           },
        //         },
        //         "breakpoints/md/cols": {
        //           title: "Columns",
        //           type: "number",
        //           minimum: 1,
        //           default: 99,
        //           maximum: 200,
        //           metadata: {
        //             component: "SliderInput",
        //             showNumberInput: true,
        //           },
        //         },
        //       },
        //     },
        //     sm: {
        //       title: "Third Breakpoint",
        //       type: "object",
        //       metadata: {
        //         hideLabel: false,
        //       },
        //       properties: {
        //         "breakpoints/sm/pxVal": {
        //           title: "Pixel Threshold",
        //           type: "number",
        //           minimum: 0,
        //           default: 768,
        //           maximum: 2000,
        //           metadata: {
        //             component: "SliderInput",
        //             showNumberInput: true,
        //           },
        //         },
        //         "breakpoints/sm/cols": {
        //           title: "Columns",
        //           type: "number",
        //           minimum: 1,
        //           default: 76,
        //           maximum: 200,
        //           metadata: {
        //             component: "SliderInput",
        //             showNumberInput: true,
        //           },
        //         },
        //       },
        //     },
        //     xs: {
        //       title: "Fourth Breakpoint",
        //       type: "object",
        //       metadata: {
        //         hideLabel: false,
        //       },
        //       properties: {
        //         "breakpoints/xs/pxVal": {
        //           title: "Pixel Threshold",
        //           type: "number",
        //           minimum: 0,
        //           default: 375,
        //           maximum: 2000,
        //           metadata: {
        //             component: "SliderInput",
        //             showNumberInput: true,
        //           },
        //         },
        //         "breakpoints/xs/cols": {
        //           title: "Columns",
        //           type: "number",
        //           minimum: 1,
        //           default: 37,
        //           maximum: 200,
        //           metadata: {
        //             component: "SliderInput",
        //             showNumberInput: true,
        //           },
        //         },
        //       },
        //     },
        //   },
        // },
      },
    },
  },
}

export default appConfigSchema
