import React from "react"
import styled from "styled-components"
import { faClock, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import { FAMemo } from "@dbai/ui-staples"

const RedIcon = styled(FAMemo)`
  color: #aa2323;
`

const ErrorIcon = props => <RedIcon icon={faExclamationCircle} {...props} />

export const getStatusIndicator = (
  status,
  { ErrorComponent = ErrorIcon, size = "1x", filteredStatuses = [] } = {}
) => {
  if (filteredStatuses.includes(status)) return null
  if (status === "loading") {
    return (
      <img src="/icons/cell-loading.svg" alt="cell loading icon" size={size} />
    )
  }

  if (status === "waiting") return <FAMemo icon={faClock} size={size} />
  if (status === "error") return <ErrorComponent size={size} />
  return null
}

const StatusIndicator = props => {
  const { filteredStatuses, status, children = null, ErrorComponent } = props
  const indicator = getStatusIndicator(status, {
    ErrorComponent,
    filteredStatuses,
  })
  return indicator || children
}

export default StatusIndicator
