import React, { useRef, useEffect } from "react"
import { useSelector } from "react-redux"
import { Editor } from "@tinymce/tinymce-react"

import TextContainer from "./TextContainer"
import { useWidgetContext } from "../../hooks"
import { useLocalFormFieldControls } from "../hooks"
import { defaultInitOptions } from "../../lib/tinyMce"
import { selectAppVariables } from "../../selectors/app"
import { withFormFieldWrapper } from "../FormFieldWrapper"

const RichTextCore = props => {
  const { appConfig } = useWidgetContext()

  const { onChange, value: _value, schema } = props
  const { metadata = {} } = schema
  const { height = 300 } = metadata

  const [value, handleChange] = useLocalFormFieldControls(_value, onChange)
  const appVariables = useSelector(selectAppVariables)
  const appVariablesRef = useRef(appVariables)
  const valueRef = useRef(value)

  useEffect(() => {
    appVariablesRef.current = appVariables
  }, [appVariables])

  const init = {
    ...defaultInitOptions,
    height,
    toolbar:
      "undo redo | fontsizeinput | bold italic | align numlist bullist | link image | table media pageembed | lineheight  outdent indent | strikethrough forecolor backcolor formatpainter removeformat | charmap emoticons checklist | code fullscreen preview | save print export | pagebreak anchor codesample footnotes mergetags | addtemplate inserttemplate | addcomment showcomments | ltr rtl casechange | spellcheckdialog a11ycheck", // Note: if a toolbar item requires a plugin, the item will not present in the toolbar if the plugin is not also loaded.
    menubar: false,
    toolbar1: null,
    toolbar2: null,
    branding: false,
    elementpath: false,
    forced_root_block: "div",
  }

  if (!appConfig?.tinyMCEApiKey) return null
  return (
    <TextContainer>
      <Editor
        init={init}
        onEditorChange={handleChange}
        initialValue={valueRef.current}
        apiKey={appConfig.tinyMCEApiKey}
      />
    </TextContainer>
  )
}

const RichText = withFormFieldWrapper(RichTextCore)
RichText.Core = RichTextCore
export default RichText
