import React, { useMemo } from "react"
import { useParams } from "react-router-dom"

import { Widget, useDatasetColumns } from "@dbai/applet"

const getTableWidgetForDataset = (datasetId, columns) => {
  return {
    type: "TableWidget",
    options: {
      columns: columns.map(
        ({ __typename, type, name, label, ...restColumn }, i) => ({
          ...restColumn,
          type,
          key: name,
          width: 150,
          ellipsis: true,
          dataIndex: name,
          column: name || label,
          title: __typename === "ComputedColumnSpec" ? label : label || name,
        })
      ),
      datasetId: datasetId,
      limit: 10,
      offset: 0,
      table: {
        bordered: true,
        pagination: {
          defaultPageSize: 10,
        },
        scroll: {
          x: "100vw",
          y: "calc(100vh - 295px)",
        },
      },
    },
  }
}

const DatasetPreview = props => {
  const { customer } = props
  const { id } = useParams()
  const { columns } = useDatasetColumns({
    datasetId: id,
    cname: customer.normalizedName,
  })
  const widget = useMemo(() => {
    return getTableWidgetForDataset(id, columns)
  }, [columns, id])
  return <Widget.Core cname={customer.normalizedName} widget={widget} />
}

export default DatasetPreview
