import { uuidv4 } from "@dbai/tool-box"

// Jupyter Notebook API version
const version = "5.2"

// make* functions in this file are used to construct Jupyter Comm messages.
// Documenation for Jupyter Comms can be found here:
// https://jupyter-client.readthedocs.io/en/latest/messaging.html
//
// The general message format is:
// {
//   channel: String,
//   header: Object,
//   parent_header: Object (optional),
//   metadata: Object {
//     msg_id: UUID,
//     msg_type: String,
//     ...other metadata
//   },
//   buffers: List<Object>,
//   content: Object {
//     comm_id: UUID (optional),
//     ...other message data
//   },
// }
//
// More specifics about the message format can be found here:
// https://jupyter-client.readthedocs.io/en/latest/messaging.html#general-message-format

const defaultFrame = {
  channel: "",
  header: {},
  metadata: {},
  content: {},
  buffers: [],
  parent_header: {},
}

const makeFrame = overrides => {
  const header = {
    msg_id: uuidv4(),
    session: "",
    username: "username", // This is what Jupyter Notebook sends ¯\_(ツ)_/¯
    msg_type: "",
    version,
    ...overrides,
  }

  return {
    ...defaultFrame,
    header,
  }
}

const makeExec = (code, metadata = {}) => {
  const { header, ...rest } = makeFrame(metadata)

  return {
    ...rest,
    header: {
      ...header,
      msg_type: "execute_request",
    },
    metadata,
    content: {
      code,
      silent: false,
      store_history: true,
      user_expressions: {},
      allow_stdin: true,
      stop_on_error: true,
    },
  }
}

const makeCommOpen = ({ target, data }) => {
  const { header, ...rest } = makeFrame()
  header.msg_type = "comm_open"

  return {
    ...rest,
    header,
    content: {
      comm_id: uuidv4(),
      target_name: target,
      data,
    },
  }
}

const makeCommMsg = ({
  commId,
  data,
  msgType = "comm_msg",
  content = {},
  header = {},
}) => {
  const frame = makeFrame(header)
  frame.header.msg_type = msgType
  frame.content = {
    comm_id: commId,
    data,
    ...content,
  }

  return frame
}

const makeCommClose = ({ commId }) => {
  const frame = makeFrame()
  frame.header.msg_type = "comm_close"
  frame.content.comm_id = commId

  return frame
}

const frames = { makeFrame, makeExec, makeCommOpen, makeCommMsg, makeCommClose }
export default frames
