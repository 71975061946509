import { flow } from "lodash"

import {
  processDatePickerOptions,
  processScalarFieldOptions,
} from "../../lib/generateSchemaHelpers"

const generateSchema = flow([
  processDatePickerOptions,
  processScalarFieldOptions,
])

export default generateSchema
