import React, { useRef, useEffect, useCallback, useState } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { debounce, curry, isArray } from "lodash"
import { Space, Button, Input, Tree } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faWindowMaximize,
  faWindowMinimize,
} from "@fortawesome/free-solid-svg-icons"

import ControlledForm from "./ControlledForm"
import { actions } from "../../reducers/formsReducer"
import { ROOT_TREE_KEY, getTreeNodeFromSchema } from "./utils"
import { useFormContext } from "../../JSONSchemaForm/hooks"
import { withoutLeadingDot } from "../../JSONSchemaForm/utils"
import { useFormData, useFormSchema } from "../../JSONSchemaForm/hooks"

const { Search } = Input
const { DirectoryTree } = Tree

const PaddedDiv = styled.div`
  padding: 8px;

  .ant-tree .hidden-tree-node {
    display: none;
  }

  .ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
    color: #fff;
    background: bottom;
  }
`

const searchStyle = {
  marginBottom: 8,
}

const getParentPath = path => {
  return path.split(".").slice(0, -1).join(".")
}

const allowDrop = info => {
  const { dropNode, dragNode, dropPosition } = info

  if (getParentPath(dragNode?.path) !== dropNode?.path) return false
  if (!dropNode.children) {
    if (dropPosition === 0) return false
  }
  return true
}

const checkDraggable = node => {
  return node.draggable
}

const MAX_DEFAULT_TREE_HEIGHT = 1

const getParentNodeKeys = curry((level, openLevelLimit, keys, node) => {
  if (level === openLevelLimit) return keys
  return node.children
    ? [
        node.key,
        ...node.children.reduce(
          getParentNodeKeys(level + 1, openLevelLimit),
          keys
        ),
      ]
    : keys
})

const TreeView = props => {
  const rootSchema = useFormSchema()
  const {
    onSelect,
    path = ".",
    treeNodesCallback,
    schema = rootSchema,
    schemaKey = "options",
    defaultSelectedKeys = [ROOT_TREE_KEY],
  } = props
  const formData = useFormData()
  const dispatch = useDispatch()
  const treeIsLoaded = useRef(false)
  const userInteracted = useRef(false)
  const scheduledSelection = useRef([])
  const [openAll, setOpenAll] = useState()
  const [treeData, setTreeData] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [expandedKeys, setExpandedKeys] = useState([])
  const { onFormChange, formId: id } = useFormContext()
  const [selectedKeys, setSelectedKeys] = useState(defaultSelectedKeys)

  const handleSearch = useCallback(e => {
    const { value } = e.target
    setSearchValue(value)
  }, [])

  const handleSelect = useCallback(
    (selected, nodeSpec) => {
      const { node } = nodeSpec || {}
      onSelect(node)
      setSelectedKeys(selected.length ? selected : [ROOT_TREE_KEY])
    },
    [onSelect]
  )

  // select root tree node when a field is removed. this is to prevent
  // the form editor from showing the options for a field that no longer exists
  const onRemove = useCallback(() => {
    handleSelect([])
  }, [handleSelect])

  const scheduleSelect = useCallback(selected => {
    scheduledSelection.current = selected
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceReloadTreeData = useCallback(
    debounce(getTreeNodeProps => {
      const treeNodes = getTreeNodeFromSchema({
        ...getTreeNodeProps,
      })
      const newData = [treeNodes].filter(Boolean)
      setTreeData(newData)
    }, 800),
    [setTreeData]
  )

  useEffect(() => {
    if (treeIsLoaded.current) {
      debounceReloadTreeData({
        path,
        schema,
        onRemove,
        schemaKey,
        rootSchema,
        searchValue,
        data: formData,
        onSelect: scheduleSelect,
      })
      return
    } else {
      const treeNodes = getTreeNodeFromSchema({
        path,
        schema,
        onRemove,
        schemaKey,
        rootSchema,
        searchValue,
        data: formData,
        onSelect: scheduleSelect,
      })
      const newData = [treeNodes].filter(Boolean)
      setTreeData(newData)
      treeIsLoaded.current = true
    }
  }, [
    path,
    schema,
    onRemove,
    formData,
    schemaKey,
    rootSchema,
    searchValue,
    scheduleSelect,
    debounceReloadTreeData,
  ])

  const flattenedTreeData = useRef({})
  useEffect(() => {
    const flattenTreeData = nodes => {
      return nodes.reduce((acc, node) => {
        if (node.children) {
          return { ...acc, [node.key]: node, ...flattenTreeData(node.children) }
        }
        return { ...acc, [node.key]: node }
      }, {})
    }
    // treeNodesCallback(flattenTreeData(treeData))
    flattenedTreeData.current = flattenTreeData(treeData)

    if (scheduledSelection.current.length) {
      handleSelect(scheduledSelection.current)
      scheduledSelection.current = []
    }
  }, [handleSelect, treeData, treeNodesCallback])

  useEffect(() => {
    if (!userInteracted.current) {
      setExpandedKeys(
        treeData.reduce(getParentNodeKeys(0, MAX_DEFAULT_TREE_HEIGHT), [])
      )
    }
  }, [treeData, expandedKeys.length])

  const handleDrop = useCallback(
    info => {
      const { node } = info
      const { data, path, schemaKey } = node

      const dropPos = node.pos.split("-")
      const dragPos = info.dragNode.pos.split("-")
      const toIndex = info.dropPosition - Number(dropPos[dropPos.length - 1])

      const fromIndex = dragPos[dragPos.length - 1]
      if (!isArray(data)) return
      if (toIndex < 0) return // Ignores if outside designated area
      const items = [...data]
      const item = items.splice(fromIndex, 1)[0]
      items.splice(toIndex, 0, item)

      const name = `${withoutLeadingDot(path)}.${schemaKey}`
      dispatch(
        actions.setFormField({
          id,
          name,
          value: items,
        })
      )
      onFormChange({ action: "moved", name, value: items })
    },
    [dispatch, onFormChange, id]
  )

  const handleExpand = useCallback(keys => {
    setExpandedKeys(keys)
    userInteracted.current = true
  }, [])

  const toggleOpenAll = useCallback(() => {
    userInteracted.current = true
    if (openAll) {
      setExpandedKeys([])
      setOpenAll(false)
    } else {
      setExpandedKeys(treeData.reduce(getParentNodeKeys(0, null), []))
      setOpenAll(true)
    }
  }, [treeData, openAll])

  return (
    <PaddedDiv>
      <Space style={searchStyle}>
        <Button size="small" onClick={toggleOpenAll}>
          {openAll ? (
            <FontAwesomeIcon icon={faWindowMaximize} />
          ) : (
            <FontAwesomeIcon icon={faWindowMinimize} />
          )}
        </Button>
        <Search placeholder="Search" onChange={handleSearch} />
      </Space>
      {treeData.length ? (
        <DirectoryTree
          blockNode
          showIcon={false}
          defaultExpandAll
          onDrop={handleDrop}
          treeData={treeData}
          allowDrop={allowDrop}
          onExpand={handleExpand}
          onSelect={handleSelect}
          draggable={checkDraggable}
          expandedKeys={expandedKeys}
          selectedKeys={selectedKeys}
          expandAction="doubleClick"
          showLine={{ showLeafIcon: false }}
        />
      ) : null}
    </PaddedDiv>
  )
}

TreeView.Form = ControlledForm
export default TreeView
