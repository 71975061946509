import { get } from "lodash"
import Highcharts from "highcharts"

var hexRegex = /^#([0-9a-f]{3}){1,2}$/i

const MUTED_COLOR_ALPHA = 0.2
const DEFAULT_BORDER_COLOR = "#ffffff"
const EXCLUDED_CHART_TYPES = ["solidgauge", "heatmap"]
const POINT_VALUE_NAMES = ["category", "name", "x", "y"]
const SERIES_COLOR_NAME = "userOptions.custom.originalColor"
const CHART_PAGE_ID_NAME = "userOptions.plotOptions.series.custom.pageId"
const CHART_WIDGET_ID_NAME = "userOptions.plotOptions.series.custom.widgetId"
const DEFAULT_LABEL_COLOR = "contrast" // a pseudo color that Highcharts picks up and applies the maximum contrast to the underlying point item
const DEFAULT_MUTED_COLOR = `rgba(0, 0, 0, ${MUTED_COLOR_ALPHA})`

const getSeriesColor = (chart, series, point) => {
  // fallback to the default chart colors if user-defined color is not set
  return (
    get(series, SERIES_COLOR_NAME) ||
    chart.options.colors[series.colorIndex] ||
    // in the case that there is no series color, use the point color
    (point ? chart.options.colors[point.colorIndex] : undefined)
  )
}

const getMutedSeriesColor = hex => {
  if (!hex) return DEFAULT_MUTED_COLOR
  if (!hexRegex.test(hex)) return hex
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16),
    a = MUTED_COLOR_ALPHA
  return "rgba(" + r + ", " + g + ", " + b + ", " + a + ")"
}

const getColor = (chart, series, point, isMuted) => {
  const seriesColor = getSeriesColor(chart, series, point)
  return isMuted ? getMutedSeriesColor(seriesColor) : seriesColor
}

const getBorderColor = (series, isMuted) => {
  const borderColor = series.borderColor || DEFAULT_BORDER_COLOR
  return isMuted ? getMutedSeriesColor(borderColor) : borderColor
}

const getDataLabelColor = (point, isMuted) => {
  const color = isMuted ? DEFAULT_MUTED_COLOR : DEFAULT_LABEL_COLOR
  const dataLabels = point.dataLabels || []
  if (!dataLabels.length) return { style: { color } }
  return dataLabels.map(label => {
    return { style: { color } }
  })
}

const seriesIsSelected = (series, selectedPoints, widgetId) => {
  const { group, label } = series.options.custom || {}

  // If the series has no group or label, it cannot be selected
  if (!group || !label) return false

  return selectedPoints.reduce((acc, selectedPoint) => {
    // If the selected point was derived from this chart then we only want to select the one point, not the series
    if (selectedPoint.pointId && selectedPoint.widgetId === widgetId) {
      return false
    }

    if (acc) return acc

    // Otherwise, we want to check if we should select the entire series
    return selectedPoint.value === label && selectedPoint.column === group
  }, false)
}

const getDefaultPointOptions = (chart, point) => {
  let opts = {}
  const seriesType = point.series.type

  if (seriesType === "pie") {
    opts.sliced = false
  }

  if (["bar", "column"].includes(seriesType)) {
    opts.borderColor = getBorderColor(point.series)
    opts.dataLabels = getDataLabelColor(point)
  }

  return {
    ...opts,
    selected: false,
    color: getColor(chart, point.series, point),
  }
}

const getMutedPointOptions = (chart, point) => {
  let opts = {}
  const seriesType = point.series.type

  if (seriesType === "pie") {
    opts.sliced = false
  }

  if (["bar", "column"].includes(seriesType)) {
    opts.borderColor = getBorderColor(point.series, true)
    opts.dataLabels = getDataLabelColor(point, true)
  }

  return {
    ...opts,
    selected: false,
    color: getColor(chart, point.series, point, true),
  }
}

const getSelectedPointOptions = (chart, point) => {
  let opts = {}
  const seriesType = point.series.type
  const color = getColor(chart, point.series, point)

  if (seriesType === "pie") {
    opts.sliced = true
  }

  if (["bar", "column"].includes(seriesType)) {
    opts.borderColor = getBorderColor(point.series)
    opts.dataLabels = getDataLabelColor(point)
  }

  return {
    ...opts,
    color,
    selected: true,
  }
}

const resetChart = (chart, series) => {
  series.update({ color: getColor(chart, series) }, false)

  Highcharts.each(series.data, function (point) {
    const options = getDefaultPointOptions(chart, point)
    point.update(options, false)
  })
}

const muteChart = (chart, series) => {
  series.update({ color: getColor(chart, series, null, true) }, false)

  Highcharts.each(series.data, function (point) {
    const options = getMutedPointOptions(chart, point)
    point.update(options, false)
  })
}

const selectChartPoints = (chart, series, points) => {
  const seriesColor = getColor(chart, series)
  series.update(
    {
      color: seriesColor,
      states: {
        select: {
          color: seriesColor,
          borderColor: seriesColor,
        },
      },
      marker: {
        states: {
          select: {
            fillColor: seriesColor,
            lineColor: seriesColor,
          },
        },
      },
    },
    false
  )

  Highcharts.each(series.data, function (point) {
    const isSelected = points.find(p => p.id === point.id)
    // apply selection options to each point based on selection state
    const options = isSelected
      ? getSelectedPointOptions(chart, point, points)
      : getMutedPointOptions(chart, point)
    point.update(options, false)
  })
}

const getSelectedPointsInSeries = (series, selectedPoints, widgetId) => {
  if (seriesIsSelected(series, selectedPoints, widgetId)) {
    // if series is selected, only include non-null points
    return series.data.filter(point => {
      return !point.isNull
    })
  }

  return series.data.filter(point => {
    // if the point is null, it cannot be selected
    if (point.isNull) return false

    const pointValue = POINT_VALUE_NAMES.reduce(
      (value, name) => value ?? get(point, name),
      null
    )

    return selectedPoints.find(selectedPoint => {
      // Find selected point based on point id if the selected point was derived from this chart
      if (selectedPoint.pointId && selectedPoint.widgetId === widgetId) {
        return selectedPoint.pointId === point.id
      }
      // Otherwise, find selected point based on value
      return selectedPoint.value === pointValue
    })
  })
}

export const selectPointInAllCharts = selectedPoints => {
  Highcharts.charts.forEach(chart => {
    if (!chart || EXCLUDED_CHART_TYPES.includes(chart.options?.chart?.type)) {
      return
    }

    const pageId = get(chart, CHART_PAGE_ID_NAME)
    const widgetId = get(chart, CHART_WIDGET_ID_NAME)

    // aggregate points to be shown in the tooltip
    let pointsToShow = []

    // filter selected points to only those on the current page
    const pointsOnPage = selectedPoints.filter(p => p.pageId === pageId)

    // iterate over each series to apply selection logic
    chart.series?.forEach(series => {
      if (!series.visible) return

      // Find the point with the matching category and select it
      const points = pointsOnPage.length
        ? getSelectedPointsInSeries(series, pointsOnPage, widgetId)
        : []

      pointsToShow = [...pointsToShow, ...points]

      if (pointsOnPage.length) {
        if (!points.length) {
          muteChart(chart, series)
          return
        }
        selectChartPoints(chart, series, points)
      } else {
        resetChart(chart, series)
      }
    })

    // update tooltip behavior based on selection state
    if ((pointsOnPage.length && !pointsToShow.length) || !pointsOnPage.length) {
      chart.update({ tooltip: { hideDelay: 500, shared: false } }, false)
    } else {
      // set the hideDelay to a very large number to prevent the tooltip from
      // being hidden when the mouse leaves the chart area
      chart.update({ tooltip: { hideDelay: 9e9, shared: true } }, false)
    }

    if (pointsToShow.length) {
      chart.tooltip.refresh(pointsToShow)
    }

    // Update the visual state of the chart
    chart.redraw()
  })
}
