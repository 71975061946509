import { actions } from "../../reducers/appReducer"
import { observeYjsMapDeep } from "./utils"

const handleExternalChange =
  (dispatch, pageId) =>
  ({ yMap, key }) => {
    const updatedData = yMap.get(key)
    if (key === "name") {
      dispatch(actions.renamePage({ pageId, name: updatedData }))
    }
    if (key === "widgetIds") {
      dispatch(actions.setPageWidgetIds({ pageId, widgetIds: updatedData }))
    }
  }

const addPageObserver = (dispatch, pagesMap, pageId) => {
  const pageMap = pagesMap.get(pageId)
  observeYjsMapDeep({
    yMap: pageMap,
    onExternalChange: handleExternalChange(dispatch, pageId), // passing in pageId since it is not part of the page state
  })
  return pageMap
}

const pageObserver = (yDoc, dispatch) => {
  const specMap = yDoc.getMap("spec")
  const pagesMap = specMap.get("pages")
  const pages = pagesMap.toJSON()

  // for each widget that already exists, add an observer
  Object.keys(pages).forEach(pageId => {
    addPageObserver(dispatch, pagesMap, pageId)
  })
}

export { addPageObserver }
export default pageObserver
