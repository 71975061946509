import React from "react"
import { Row, Col } from "antd"

import { numberSorter, dateSorter } from "@dbai/tool-box"
import { CodeEditor, TableWithFolders, DateFormatter } from "@dbai/ui-staples"

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: numberSorter("id"),
  },
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    render: ({ value }) => <span>{value.name}</span>,
  },
  {
    title: "Created",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (_, row) => {
      return (
        <DateFormatter value={row.createdAt} format={"MM/DD/YYYY h:mm:ss a"} />
      )
    },
    type: "date",
    sorter: dateSorter("createdAt"),
  },
]

const columnOrder = ["id", "createdAt", "user"]

const DisplayPrediction = ({ prediction }) => {
  return (
    <Row gutter={[16, 24]}>
      <Col span={12}>
        <h2>Inputs</h2>
        <CodeEditor
          mode="javascript"
          options={{ readOnly: true }}
          initialValue={JSON.stringify(prediction.inputs, null, 2)}
        />
      </Col>
      <Col span={12}>
        <h2>Prediction</h2>
        <CodeEditor
          mode="javascript"
          options={{ readOnly: true }}
          initialValue={JSON.stringify(prediction.results, null, 2)}
        />
      </Col>
    </Row>
  )
}

const Predictions = props => {
  const { predictions, revisionId } = props

  if (revisionId === null) {
    return <div>Please Select A Revision</div>
  }

  return (
    <div>
      <TableWithFolders
        columns={columns}
        columnOrder={columnOrder}
        dataSource={predictions}
        folderType="modelPredictions"
        expandable={{
          expandedRowRender: record => (
            <DisplayPrediction prediction={record} />
          ),
          rowExpandable: record => !!record.results,
        }}
      />
    </div>
  )
}

export default Predictions
