import React, { useEffect, useState, useRef, useId, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import styled from "styled-components"
import Highcharts from "highcharts"
import { isEqual } from "lodash"
import { Alert } from "antd"

import { useWidgetContext } from "../../hooks"
import { actions } from "../../reducers/appReducer"
import { selectAppVariables } from "../../selectors/app"

const StyledIFrame = styled.iframe`
  border: none;
`

const StyledAlert = styled(Alert)`
  z-index: 1000;
  position: sticky;
  bottom: 0;
`

const CustomWidget = props => {
  const { widget, registerOnResize } = props
  const { srcDoc } = widget.options
  const id = useId()
  const iframeRef = useRef()
  const dispatch = useDispatch()
  const [width, setWidth] = useState(500)
  const [height, setHeight] = useState(500)
  const widgetRef = useRef(widget.options)
  const widgetContext = useWidgetContext()
  const [error, setError] = useState(null)
  const appVariables = useSelector(selectAppVariables)
  const appVariablesRef = useRef(appVariables)
  const [reloadKey, setReloadKey] = useState(Date.now())

  // whenever the data changes, reload key is updated to subsequently refresh the iframe
  useEffect(() => {
    const appVariablesChanged = !isEqual(appVariables, appVariablesRef.current)
    const widgetChanged = !isEqual(widget.options, widgetRef.current)
    if (appVariablesChanged || widgetChanged) {
      setReloadKey(Date.now())
      widgetRef.current = widget.options
      appVariablesRef.current = appVariables
    }
  }, [widget.options, appVariables])

  const updateAppVariable = useCallback(
    ({ id, value }) => {
      if (!id) return
      dispatch(actions.setAppVariableWithSync({ ...value, id }))
    },
    [dispatch]
  )

  // function that will be called from the iframe to inject the delta app's context
  const connectDeltaAppContext = useCallback(
    Component => {
      const chartsInApp = Highcharts.charts.filter(Boolean)
      return propsFromIframe => (
        <Component
          {...widgetContext}
          {...propsFromIframe}
          charts={chartsInApp}
          appVariables={appVariables}
          setAppVariable={updateAppVariable}
        />
      )
    },
    [widgetContext, updateAppVariable, appVariables]
  )

  useEffect(() => {
    if (iframeRef.current) {
      const iframeWindow = iframeRef.current.contentWindow
      const onError = e => {
        console.log(e)
        setError(`${e.message} (${e.filename}:${e.lineno}:${e.colno})`)
      }

      iframeWindow.addEventListener("error", onError)

      return () => {
        iframeWindow.removeEventListener("error", onError)
      }
    }
  }, [iframeRef])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onResize = useCallback(rect => {
    const { width, height } = rect || {}
    setWidth(width)
    setHeight(height)
  }, [])

  useEffect(() => {
    registerOnResize(() => onResize)
  }, [registerOnResize, onResize])

  // expose applet api to iframe
  window.connectContext = connectDeltaAppContext

  return (
    <>
      {error ? (
        <StyledAlert
          type="error"
          description={error}
          message="Invalid Javascript"
        />
      ) : null}

      <StyledIFrame
        id={id}
        name={id}
        ref={iframeRef}
        width={width}
        height={height}
        srcDoc={`${srcDoc}<!-- Reload Key: ${reloadKey} -->`}
      />
    </>
  )
}

export default CustomWidget
