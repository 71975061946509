import { gql } from "@apollo/client"

export const GET_APPS = gql`
  query Apps($cname: String!) {
    customer(name: $cname) {
      id
      apps {
        id
        name
        pageId
        tags
        createdAt
        updatedAt
        theme
      }
    }
  }
`

export const GET_APP = gql`
  query Apps($appId: Int!, $cname: String!) {
    customer(name: $cname) {
      id
      app(id: $appId) {
        id
        name
        pageId
        tags
        createdAt
        updatedAt
        logo
        spec
      }
    }
  }
`

export const CREATE_APP = gql`
  mutation CreateApp($cname: String!, $name: String!, $spec: JsonInput!) {
    createApp(cname: $cname, name: $name, spec: $spec) {
      id
      name
      spec
      pageId
      createdAt
      updatedAt
    }
  }
`

export const DELETE_APP = gql`
  mutation deleteApp($id: Int!, $cname: String!) {
    deleteApp(id: $id, cname: $cname) {
      id
    }
  }
`

export const UPDATE_APP = gql`
  mutation UpdateApp(
    $id: Int!
    $pageId: Int!
    $name: String!
    $cname: String!
    $spec: JsonInput!
  ) {
    updateApp(
      id: $id
      name: $name
      spec: $spec
      cname: $cname
      pageId: $pageId
    ) {
      id
      name
      pageId
      spec
    }
  }
`

export const CREATE_APP_SNAPSHOT = gql`
  mutation SnapshotApp(
    $id: Int!
    $name: String!
    $cname: String!
    $description: String!
  ) {
    createAppSnapshot(
      id: $id
      name: $name
      cname: $cname
      description: $description
    ) {
      id
      name
    }
  }
`

export const GET_APP_SNAPSHOTS = gql`
  query GetAppSnapshots($appId: Int!, $cname: String!) {
    customer(name: $cname) {
      id
      appSnapshots(appId: $appId) {
        id
        name
        description
        createdAt
      }
    }
  }
`

export const GET_ACTIVE_APP_SNAPSHOT = gql`
  query GetActiveAppSnapshot($appId: Int!, $cname: String!) {
    customer(name: $cname) {
      id
      activeAppSnapshot(appId: $appId) {
        id
        name
        description
        createdAt
        spec
      }
      app(id: $appId) {
        id
        logo
        spec
      }
    }
  }
`
