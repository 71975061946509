import {
  createContext,
  useContext,
  useReducer,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from "react"
import { produce } from "immer"

import { onlyType } from "components/pages/Workflows/Edit/shared/kernel"

// The same widget can cut across multiple cells, so we need to track their
// state high up the DOM. This follows much the same pattern as the standard
// Form reducer but does not sync to the API. At present, widgets are local to
// the life of the page.
const WidgetContext = createContext()

const WIDGET_ADD = "widget/add"
const WIDGET_UPDATE = "widget/update"

const widgetReducer = produce((state, action) => {
  switch (action.type) {
    case WIDGET_ADD:
      state.widgets[action.commId] = action.widget
      break
    case WIDGET_UPDATE:
      const widget = state.widgets[action.commId]
      widget.data.state = {
        ...widget.data.state,
        ...action.patch,
      }
      state.widgets[action.commId] = widget
      break
    default:
      return
  }
})

const addWidget = ({ commId, widget }) => {
  return {
    type: WIDGET_ADD,
    widget,
    commId,
  }
}

const updateWidget = ({ commId, patch }) => {
  return {
    type: WIDGET_UPDATE,
    commId,
    patch,
  }
}

const initialState = {
  widgets: {},
}

const useWidgetReducer = () => {
  const [state, dispatch] = useReducer(widgetReducer, initialState)
  const stateRef = useRef()
  stateRef.current = state

  const wrappedDispatch = useCallback(
    action => {
      return typeof action === "function"
        ? action(dispatch, () => stateRef.current)
        : dispatch(action)
    },
    [dispatch]
  )

  return [state, wrappedDispatch]
}

const useWidgets = () => useContext(WidgetContext)

const useWidget = id => {
  const [state] = useWidgets()
  return state.widgets[id] || null
}

const useWidgetDispatch = () => {
  const [, dispatch] = useWidgets()
  return dispatch
}

const useWidgetActions = () => {
  const [, dispatch] = useWidgets()

  return useMemo(
    () => ({
      addWidget: (...args) => dispatch(addWidget(...args)),
      updateWidget: (...args) => dispatch(updateWidget(...args)),
    }),
    [dispatch]
  )
}

// Keep the widget state up-to-date in the widget store.
const useDefaultPatchEffect = kernel => {
  const { updateWidget } = useWidgetActions()

  useEffect(() => {
    const [iopub] = kernel.channel("iopub")
    const sub = iopub
      .filter(onlyType("comm_msg"))
      .filter(({ content }) => content.data.method === "update")
      .subscribe(message => {
        const {
          data: { state: patch },
          comm_id: commId,
        } = message.content

        updateWidget({ commId, patch })
      })

    return sub.unsubscribe
  }, [kernel, updateWidget])
}

export {
  useWidgetReducer,
  useWidget,
  useWidgets,
  useWidgetDispatch,
  useWidgetActions,
  useDefaultPatchEffect,
  WidgetContext,
  addWidget,
  updateWidget,
}
