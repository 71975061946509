import formatOptionsSchema from "./formatOptionsSchema"

const datetimeColumnSchema = {
  type: "object",
  dependencies: formatOptionsSchema.dependencies,
  properties: {
    format: {
      type: "string",
      title: "Format",
      default: "",
      enum: ["", "number", "string", "datetime"],
      metadata: {
        layout: { span: 24 },
        labels: ["Default", "Number", "Text", "Datetime"],
        fieldProps: {
          placeholder: "Select a format",
        },
      },
    },
  },
}

const boxplotNumericalSchema = {
  type: "object",
  dependencies: formatOptionsSchema.dependencies,
  properties: {
    column: {
      title: "Column",
      type: "string",
      metadata: {
        validateFirst: true,
        component: "ColumnSelect",
        fieldProps: {
          popupMatchSelectWidth: false,
        },
      },
    },
    format: {
      type: "string",
      title: "Format",
      default: "",
      enum: ["", "number", "string", "currency", "percent"],
      metadata: {
        layout: { span: 24 },
        labels: ["Default", "Number", "Text", "Currency", "Percent"],
        fieldprops: {
          placeholder: "select a format",
        },
      },
    },
    type: {
      $ref: "#/$defs/dataColumnType",
      metadata: { hidden: true },
    },
  },
}

const numericalColumnSchema = {
  type: "object",
  dependencies: formatOptionsSchema.dependencies,
  properties: {
    column: {
      $ref: "#/$defs/dataColumn",
    },
    aggregate: {
      $ref: "#/$defs/aggregate",
      metadata: {
        validateFirst: true,
        labels: ["Average", "Sum", "Minimum", "Maximum", "Count", "None"],
        fieldProps: {
          placeholder: "(All)",
          popupMatchSelectWidth: false,
        },
      },
    },
    format: {
      type: "string",
      title: "Format",
      default: "",
      enum: ["", "number", "string", "currency", "percent"],
      metadata: {
        layout: { span: 24 },
        labels: ["Default", "Number", "Text", "Currency", "Percent"],
        fieldprops: {
          placeholder: "select a format",
        },
      },
    },
  },
}

const categoricalColumnSchema = {
  type: "object",
  dependencies: formatOptionsSchema.dependencies,
  properties: {
    format: {
      type: "string",
      title: "Format",
      default: "",
      enum: ["", "number", "currency", "percent"],
      metadata: {
        layout: { span: 24 },
        labels: ["Default", "Number", "Currency", "Percent"],
        fieldprops: {
          placeholder: "select a format",
        },
      },
    },
  },
}

const datasetColumnSchema = {
  type: "object",
  dependencies: {
    type: {
      allOf: [
        {
          if: {
            properties: {
              type: {
                enum: ["datetime"],
              },
            },
          },
          then: datetimeColumnSchema,
        },
        {
          if: {
            properties: {
              type: {
                enum: ["numerical", "float", "int", "computed"],
              },
            },
          },
          then: numericalColumnSchema,
        },
        {
          if: {
            properties: {
              type: {
                enum: ["string", "categorical"],
              },
            },
          },
          then: categoricalColumnSchema,
        },
      ],
    },
  },
  properties: {
    column: {
      title: "Column",
      type: "string",
      metadata: {
        validateFirst: true,
        component: "ColumnSelect",
        fieldProps: {
          popupMatchSelectWidth: false,
        },
      },
    },
    type: {
      $ref: "#/$defs/dataColumnType",
      metadata: { hidden: true },
    },
  },
}

export {
  datetimeColumnSchema,
  numericalColumnSchema,
  boxplotNumericalSchema,
  categoricalColumnSchema,
}
export default datasetColumnSchema
