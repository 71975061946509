import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useApolloClient } from "@apollo/client"

import useCurrentCustomer from "../hooks/useCurrentCustomer"
import { actions } from "../reducers/currentCustomerReducer"

const cnameIgnore = [
  "login",
  "admin",
  "profile",
  "accept_invite",
  "reset_password",
  "dev",
  "undefined",
]

const CurrentCustomer = props => {
  const { cname } = useParams()
  const [customer] = useCurrentCustomer()
  const dispatch = useDispatch()
  const client = useApolloClient()

  useEffect(() => {
    if (!cname || cnameIgnore.includes(cname)) {
      if (customer) dispatch(actions.set({ name: "customer", value: null }))
      return
    }

    dispatch(actions.getCurrentCustomer(cname, client))
  }, [client, cname, customer, dispatch])

  return null
}

export default CurrentCustomer
