import { Table } from "antd"
import styled from "styled-components"

const StyledTable = styled(Table)`
  width: 100%;
  heigth: 100%;
  table thead .thead-light {
    position: sticky;
  }
  table thead th {
    position: sticky;
  }
  .dataset-column {
    min-width: 150px;
  }
`

export default StyledTable
