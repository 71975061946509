import { curry } from "lodash"

import { stringToColor } from "@dbai/tool-box"

import {
  orderLabels,
  findLabelConfig,
  findColumnConfig,
  formatColumnValue,
  findIndexForColumn,
  getDefaultColumnName,
} from "../../../../lib/datasetColumns"
import {
  getSeriesId,
  getXAxisTitle,
  persistTooltip,
  filterOnPointClick,
  getAxisLabelOptions,
  getCustomSeriesOptions,
  filterOnCategoryLabelClick,
} from "../utils"

const getZonesForSeries = thresholds => {
  if (!thresholds) return {}
  const zones = [...thresholds]
    .sort((a, b) => (a.value > b.value ? 1 : -1))
    .reduce((acc, thresholdConfig) => {
      const {
        value,
        color,
        dashStyle,
        upperLimit,
        includeZone,
        direction = "below",
      } = thresholdConfig
      switch (true) {
        case !includeZone:
          return acc
        case direction === "between":
          return [
            ...acc,
            {
              value,
            },
            {
              color,
              dashStyle,
              value: upperLimit,
            },
          ]
        case direction === "below":
          return [
            ...acc,
            {
              value,
              color,
              dashStyle,
            },
          ]
        case direction === "above":
          return [
            ...acc,
            {
              value,
            },
            {
              color,
              dashStyle,
            },
          ]
        case direction === "outside":
          return [
            ...acc,
            {
              value,
              color,
              dashStyle,
            },
            {
              value: upperLimit,
            },
            {
              color,
              dashStyle,
            },
          ]
        default:
          return acc
      }
    }, [])

  return { zones }
}

const getSeriesData = (xInput, dataset, yInput, highchart) => {
  const xIdx = findIndexForColumn(xInput, dataset.columns)
  const yIdx = findIndexForColumn(yInput, dataset.columns)

  switch (xInput.type) {
    case "categorical":
    case "string":
    case "datetime":
      /**
       * When the xAxis is categorical, the labels can be random. For this reason,
       * we force each row value to its corresponding xAxis label
       */
      const categories = highchart.xAxis.categories
      if (!categories) return []
      return categories?.map(category => {
        if (yIdx === -1) return null
        const found = dataset.rows.find(r => category === r[xIdx])
        return found ? found[yIdx] : null
      })
    default:
      return dataset.rows.map(row => {
        return [row[xIdx], row[yIdx]]
      })
  }
}

const getSeriesDataLabels = (dataLabels, datasetColumn) => {
  if (!dataLabels?.enabled) return {}
  return {
    enabled: dataLabels?.enabled,
    formatter: function () {
      return formatColumnValue(datasetColumn, this.y)
    },
  }
}

const getColumnColor = column => {
  const { formatOptions, name } = column || {}
  const color = formatOptions?.color
  return color || stringToColor(name || "")
}

const getLabelSpec = (column, label) => {
  if (!column || !label) return null
  const { formatOptions } = column
  const { labels } = formatOptions || {}
  return labels?.find(l => l.label === label)
}

const getLabelColor = labelSpec => {
  if (!labelSpec) return null
  return labelSpec.color || stringToColor(labelSpec.label || "")
}

const getSeriesOptions = (
  options,
  yInput,
  data,
  index,
  selectPoints,
  crossFilters
) => {
  const {
    yAxis,
    chart = {},
    highchart = {},
    xAxisData,
    groupByData,
    seriesOptions = [],
  } = options || {}
  const { combineAxes } = yAxis || {}
  const yDatasetColumn = findColumnConfig(yInput, data.columns)
  const xDatasetColumn = findColumnConfig(xAxisData[0], data.columns)
  const groupedColumn = findColumnConfig(groupByData[0], data.columns)

  // series id needs to be computed so that the series can be referenced.
  // without a computed series id, the series will be different on each rerender,
  // it it becomes impossible to tie user-set series options to the series
  const seriesId = getSeriesId(yInput, data)
  const series = seriesOptions.find(s => s.seriesId === seriesId) || {}
  const {
    thresholds,
    dataLabels,
    name: seriesName,
    type: seriesType,
    color: seriesColor,
    ...restSeriesOpts
  } = series
  // const groupLabel = findColumnLabelName(yDatasetColumn, data.label.name)
  const columnColor = getColumnColor(yDatasetColumn)

  const labelSpec = getLabelSpec(groupedColumn, data.label.name)
  const labelColor = getLabelColor(labelSpec)

  return {
    ...getZonesForSeries(thresholds),
    ...restSeriesOpts,
    type: seriesType || yInput.chartType || chart.type,
    index,
    color: seriesColor || labelColor || columnColor,
    seriesId,
    yAxis: !!combineAxes ? 0 : index,
    data: getSeriesData(
      xDatasetColumn,
      data,
      yDatasetColumn,
      highchart,
      yInput
    ),
    dataLabels: getSeriesDataLabels(dataLabels, yDatasetColumn),
    name: seriesName
      ? seriesName
      : getDefaultColumnName(yDatasetColumn, labelSpec),
    point: {
      events: {
        // This function is triggered when a point is clicked.
        // 'this' refers to the point that was clicked.
        click: filterOnPointClick("category", selectPoints, crossFilters),
        mouseOver: persistTooltip(crossFilters),
      },
    },
    marker: {
      enabled: true,
      radius: 1,
      states: {
        select: {
          radius: 5,
        },
      },
    },
    allowPointSelect: true,
    custom: {
      ...getCustomSeriesOptions(data, yInput, xAxisData[0], series.color),
      thresholds,
      combineAxes,
    },
  }
}

const getValuesForColumn = (column, datasets) => {
  const idx = findIndexForColumn(column, datasets[0].columns)
  const values = datasets.reduce((acc, dataset) => {
    return [...acc, ...dataset.rows?.map(row => row[idx])]
  }, [])
  return [...new Set(values)]
}

const getLabelCategories = (datasetColumn, values) => {
  const { formatOptions } = datasetColumn || {}
  const labelFormats = formatOptions?.labels || []
  const orderedLabels = orderLabels(values, labelFormats)
  return orderedLabels.reduce((acc, value) => {
    const labelConfig = findLabelConfig(value, labelFormats)
    // if label is explicitly set to unusable, then exclude it in chart
    if (labelConfig && !labelConfig.usable) return acc
    return [...acc, value]
  }, [])
}

const getXAxisCategories = (datasetColumn, datasets) => {
  if (!datasets?.length) return []
  const { type } = datasetColumn
  const values = getValuesForColumn(datasetColumn, datasets)
  switch (type) {
    case "categorical":
    case "string":
      return getLabelCategories(datasetColumn, values)
    case "datetime":
      return values
    default:
      return undefined
  }
}

const getLabelEvents = (datasetColumn, selectPoints, crossFilters) => {
  switch (datasetColumn.type) {
    case "category":
    case "string":
    case "datetime":
      return {
        click: filterOnCategoryLabelClick(
          datasetColumn,
          selectPoints,
          crossFilters
        ),
      }
    default:
      return {}
  }
}

const getXAxis = (data, options, selectPoints, crossFilters) => {
  const { xAxisData = [] } = options || {}
  if (!xAxisData?.length) return options.xAxis
  const { categories: _deprecatedCategories, ...restXAxis } = options.xAxis
  const datasetColumn = findColumnConfig(xAxisData[0], data?.[0]?.columns)
  const labelOptions = getAxisLabelOptions(options.xAxis, datasetColumn)
  const categories = getXAxisCategories(datasetColumn, data)
  const title = getXAxisTitle(options, datasetColumn)
  return {
    ...restXAxis,
    categories,
    type: ["string", "categorical", "datetime"].includes(datasetColumn.type)
      ? "category"
      : "linear",
    title,
    labels: {
      ...labelOptions,
      events: getLabelEvents(datasetColumn, selectPoints, crossFilters),
    },
  }
}

const constructBasicSeries = curry(
  (dataset, options, selectPoints, crossFilters) => {
    const { xAxisData, yAxisData } = options || {}
    if (!xAxisData?.length || !yAxisData?.length) {
      return { ...options, highchart: { series: [] } }
    }

    const xAxis = getXAxis(dataset.data, options, selectPoints, crossFilters)
    const highchart = { ...(options.highchart || {}), xAxis }

    let index = -1
    const chartSeries = dataset.data.flatMap(data => {
      return yAxisData.map(yInput => {
        index++
        return getSeriesOptions(
          { ...options, highchart },
          yInput,
          data,
          index,
          selectPoints,
          crossFilters
        )
      })
    })

    return {
      ...options,
      highchart: {
        ...highchart,
        series: chartSeries.filter(Boolean),
      },
    }
  }
)

export default constructBasicSeries
