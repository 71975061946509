import React, { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

import { actions } from "../reducers/appReducer"
import JSONSchemaForm from "../JSONSchemaForm"
import appConfigSchema from "../schemas/appConfigSchema"
import { selectAppTheme } from "../selectors/app"

const AppSettings = () => {
  const appTheme = useSelector(selectAppTheme)
  const dispatch = useDispatch()

  const save = useCallback(
    value => {
      dispatch(actions.setAppThemeWithSync(value))
    },
    [dispatch]
  )

  return (
    <JSONSchemaForm
      hideSave
      value={appTheme}
      schema={appConfigSchema}
      onFormChange={save}
    />
  )
}

export default AppSettings
