import React from "react"
import PropTypes from "prop-types"
import { withInput, getDisplayName } from "./Input"
// TODO: Migrate this to styled-components
import "./toggle.scss"

const Toggle = props => {
  const { onLabel = "ON", offLabel = "OFF", onChange, name, value } = props

  const displayName = getDisplayName(name)
  const id = `toggle-${name}`

  const onCheckBoxChange = evt => {
    onChange({ target: { name, value: !!evt.target.checked } })
  }

  return (
    <div className="toggle-container">
      <div className="toggle-label">{displayName}</div>
      <div className="toggle-switch">
        <input
          type="checkbox"
          name={name}
          className="toggle-switch-checkbox"
          id={id}
          checked={value}
          onChange={onCheckBoxChange}
        />
        {id && (
          <label className="toggle-switch-label" htmlFor={id}>
            <span
              className="toggle-switch-inner"
              data-yes={onLabel}
              data-no={offLabel}
            />
            <span className="toggle-switch-switch" />
          </label>
        )}
      </div>
    </div>
  )
}

Toggle.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default withInput(Toggle)
