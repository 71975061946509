import React, { useMemo } from "react"
import ReactSelect from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
import { Tooltip, Button, Col, Row } from "antd"

import { stringSorter } from "@dbai/tool-box"
import { DBTable, Form, Card, Input, SidePane, Select } from "@dbai/ui-staples"

import { isNodeNamespaced } from "lib/utils"
import { pythonTypes } from "constants/index"
import { actions } from "reducers/notebookReducer"
import { selectNodes, selectEdges } from "selectors"
import { useConsumableOutputs, useCommsChannels } from "hooks"
import { actions as inputActions } from "reducers/workflowInputReducer"

const NewInputWrapper = styled.div`
  margin-bottom: 20px;
  h4 {
    margin-bottom: 10px;
  }
  .form-group {
    margin-bottom: 10px;
  }
`

const findArgInParameters = argname => edge => {
  const { parameters = [] } = edge
  return parameters.find(param => param.argument === argname)
}

const getAssignedOutput = (argName, edges) => {
  const findArg = findArgInParameters(argName)
  const param = edges.map(edge => findArg(edge)).filter(Boolean)

  if (!param.length) return null

  return param[0].artifactId
}

const getArgumentsWithAssignments = (args, edges) => {
  return (
    args?.map(arg => ({
      ...arg,
      source: getAssignedOutput(arg.name, edges),
    })) || []
  )
}

const ArgumentActions = props => {
  const { nodeId, dispatch, row } = props

  const handleClick = () => {
    dispatch(
      actions.removeArgument({
        nodeId,
        argumentName: row.name,
      })
    )
  }

  return (
    <Tooltip title="Remove Argument">
      <Button className="btn-icon btn-danger" onClick={handleClick} danger>
        <FontAwesomeIcon icon={faTrashAlt} />
      </Button>
    </Tooltip>
  )
}

const mapOutputToOption = nodes => output => {
  const value = output.title || output.id
  const [nodeId, varName] = value.split(":")
  const node = nodes.find(node => node?.id === nodeId)
  const label = node && `${node.data.label}:${varName}`
  return { value, label }
}

const SourceSelect = props => {
  const {
    row,
    nodes,
    nodeId,
    isNamespaced,
    dispatch,
    consumableOutputs = [],
  } = props

  const { assignInputsChannel } = useCommsChannels()

  const options = useMemo(() => {
    if (row.type === "any") {
      return consumableOutputs.map(mapOutputToOption(nodes))
    }

    const matchingOutputs = consumableOutputs.filter(
      output => output.type === row.type
    )

    return matchingOutputs.map(mapOutputToOption(nodes))
  }, [consumableOutputs, nodes, row.type])

  const selectedValue = useMemo(() => {
    if (!row.source) return null

    return options.find(opt => opt.value === row.source)
  }, [options, row.source])

  const handleChange = ({ value }) => {
    dispatch(
      actions.removeParameters({
        argumentNames: [row.name],
      })
    )

    dispatch(
      actions.assignEdgeParameters({
        argumentName: row.name,
        artifactId: value,
        targetId: nodeId,
      })
    )

    const data = {
      inputs: [
        {
          input_is_namespaced: isNamespaced,
          input_namespace: nodeId,
          input_var_name: row.name,
          output_key: value,
        },
      ],
    }

    assignInputsChannel.send(data)
  }

  return (
    <div data-testid={`${row.name}-source-select`}>
      <ReactSelect
        onChange={handleChange}
        options={options}
        value={selectedValue}
        defaultValue={null}
        placeholder={"None"}
      />
    </div>
  )
}

const typeOptions = pythonTypes.map(typeName => ({
  value: typeName,
  label: typeName,
}))

const NewInput = props => {
  const { nodeId, dispatch } = props
  const { addInputChannel } = useCommsChannels()
  const inputState = useSelector(state => state.workflowInput)
  const addArgument = argument => {
    if (!argument.name) return null

    const type = argument.type || "any"

    dispatch(
      actions.addArgument({
        nodeId,
        argument: {
          name: argument.name,
          type,
        },
      })
    )

    dispatch(inputActions.resetForm())
    const data = { input_var_name: argument.name, input_namespace: nodeId }
    addInputChannel.send(data)
  }

  return (
    <NewInputWrapper>
      <Row>
        <Col span={24}>
          <h4>New Input</h4>
        </Col>
      </Row>
      <Form
        name="workflowNodeInput"
        actions={inputActions}
        onSubmit={addArgument}
        dispatch={dispatch}
        state={inputState}
        submitText="Create Input"
      >
        <Row>
          <Col span={11}>
            <Input name="name" noLabel={true} />
          </Col>
          <Col span={1} />
          <Col span={12}>
            <div className="form-group">
              <Select options={typeOptions} label="Type" name="type" />
            </div>
          </Col>
        </Row>
      </Form>
    </NewInputWrapper>
  )
}

const InputTable = props => {
  const nodes = useSelector(selectNodes)
  const { node, edges, dispatch } = props
  const consumableOutputs = useConsumableOutputs(node?.id)
  const isNamespaced = isNodeNamespaced(node)

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: stringSorter("name"),
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
    },
    {
      title: "Source",
      key: "source",
      render: (_, row) => {
        return (
          <SourceSelect
            row={row}
            nodes={nodes}
            nodeId={node?.id}
            isNamespaced={isNamespaced}
            dispatch={dispatch}
            consumableOutputs={consumableOutputs}
          />
        )
      },
    },
    {
      title: "",
      key: "actions",
      width: 1,
      render: (_, row) => {
        return (
          <ArgumentActions row={row} nodeId={node?.id} dispatch={dispatch} />
        )
      },
    },
  ]

  return (
    <Card title="Inputs" nopad>
      <DBTable
        dataSource={getArgumentsWithAssignments(node.arguments, edges)}
        columns={columns}
        emptyText="No Inputs Found"
      />
    </Card>
  )
}

const InputDetails = props => {
  const { node = {}, dispatch } = props
  const edges = useSelector(selectEdges)
  if (!node?.id) return null
  return (
    <div>
      <NewInput nodeId={node?.id} dispatch={dispatch} />
      <InputTable node={node} edges={edges} dispatch={dispatch} />
    </div>
  )
}

const InputForm = props => {
  const dispatch = useDispatch()
  const nodes = useSelector(selectNodes)
  const nodeId = useSelector(state => state.workflowInput.selectedNodeId)
  const node = useMemo(() => {
    if (!nodeId) return null
    return nodes.find(node => node?.id === nodeId)
  }, [nodeId, nodes])

  return (
    <SidePane
      width="50vw"
      title={`Node Arguments For ${node && node.data.label}`}
      isVisible={nodeId}
      onCloseClicked={() => dispatch(inputActions.setSelectedNodeId(null))}
      mask
    >
      <InputDetails node={node} dispatch={dispatch} />
    </SidePane>
  )
}

export default InputForm
