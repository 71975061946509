import { createSelector } from "reselect"

const selectSlice = state => state?.component || {}
export const selectComponentLoading = createSelector(
  selectSlice,
  component => component.loading
)
export const selectComponentError = createSelector(
  selectSlice,
  component => component.error
)
export const selectComponentData = createSelector(
  selectSlice,
  component => component.data || {}
)
export const selectComponent = createSelector(
  selectComponentData,
  data => data.component || {}
)
export const selectComponentSpec = createSelector(
  selectComponent,
  component => component?.spec || {}
)
export const selectComponentLoadedRevision = createSelector(
  selectComponent,
  component => component.revisionId || null
)
export const selectComponentColor = createSelector(
  selectComponentSpec,
  spec => spec.color
)
export const selectComponentIcon = createSelector(
  selectComponentSpec,
  spec => spec.icon
)
export const selectComponentInputs = createSelector(
  selectComponentSpec,
  spec => spec.arguments || []
)
export const selectComponentCells = createSelector(
  selectComponentSpec,
  spec => spec.cells || []
)
export const selectComponentSource = createSelector(
  selectComponentCells,
  // Currently components are single cell. This may change in the future.
  cells => cells[0]?.source || ""
)
