import { createSelector } from "reselect"

import { splitPath, getName, checkIfDir } from "lib/utils/datalake"

/*
 * Only show directories or nodes that have completed their uploading process.
 */
const filterNode = node => !node.status || node.status === "COMPLETED"

// findNode gets the node at keys in tree.
const findNode = (keys, tree) => {
  if (!tree) return null
  if (keys.length === 0 || !keys[0])
    return {
      ...tree,
      nodes: tree?.nodes?.filter(filterNode) || [],
    }

  const filteredNodes = tree?.nodes?.filter(filterNode) || []
  const node = filteredNodes.find(n => String(n.id) === keys[0])
  return findNode(keys.slice(1), node)
}

// visit finds each node in tree for each key in keys, passing each node to fn
// in order.
const visit = (keys, tree, fn) => {
  if (!tree) return []
  if (!keys.some(Boolean)) return [fn(tree)]

  const node = tree.nodes.find(n => String(n.id) === keys[0])
  return [fn(tree), ...visit(keys.slice(1), node, fn)]
}

export const selectDatalakeRoot = state => state?.datalake?.root || {}
export const selectDatalakePath = state => state?.datalake?.path || ""
export const selectDatalakeOpen = createSelector(
  selectDatalakeRoot,
  selectDatalakePath,
  (root, path) => findNode(splitPath(path), root)
)

// Return a folderChain for Chonky to render breadcrumbs to the open
// directory.
export const selectDatalakeOpenChain = createSelector(
  selectDatalakeRoot,
  selectDatalakePath,
  (root, path) =>
    [
      ...visit(splitPath(path), root, node => ({
        node,
        id: node?.id || "root",
        prefix: "",
        isDir: checkIfDir(node),
        name: getName(node),
      })),
    ].filter(node => !node.status || node.status === "COMPLETED")
)

export const selectDatalakeTreeAt = createSelector(
  selectDatalakeRoot,
  selectDatalakePath,
  (root, path) => findNode(splitPath(path), root)
)
