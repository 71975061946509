import { scalarFieldOptions } from "../../lib/fieldOptionsSchemas"

export default {
  default: {},
  type: "object",
  metadata: {
    layout: { gutter: [16] },
    sections: {
      ...scalarFieldOptions.metadata.sections,
      columnSelectOptions: {
        title: "Column Select Options",
        properties: [
          "metadata/datasetId",
          "metadata/fieldProps/popupMatchSelectWidth",
        ],
      },
    },
  },
  properties: {
    ...scalarFieldOptions.properties,
    title: {
      default: "Column Selector",
      type: "string",
      title: "Label",
      metadata: {
        fieldProps: {
          placeholder: "Enter a label",
        },
      },
    },
    nullable: {
      type: "boolean",
      title: "Clearable",
      description: "Allow field to be removed from form state",
    },
    "metadata/datasetId": {
      type: "number",
      title: "Default Dataset",
      description:
        "The dataset to use. If none is set, the dataset will be automatically determined based on other form fields.",
      metadata: {
        component: "Dataset",
      },
    },
    "metadata/fieldProps/placeholder": {
      type: "string",
      title: "Placeholder",
    },
    "metadata/fieldProps/popupMatchSelectWidth": {
      default: false,
      type: "boolean",
      title: "Dropdown Match Select Width",
    },
  },
}
