import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Space, Statistic, Button, Card } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faSyncAlt } from "@fortawesome/pro-solid-svg-icons"

import { SidePane } from "@dbai/ui-staples"

import NewInstance from "./NewInstance"
import { selectInstances } from "selectors"
import InstanceDetails from "./InstanceDetails"
import { actions } from "reducers/instancesReducer"
import DBBoundary from "components/shared/DBBoundary"

const Flow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
`

const StatsFlow = styled.div`
  display: flex;
  justify-content: center;
`

const Stat = styled(Card)`
  text-align: center;
  margin-right: 5px;

  p {
    font-size: 30px;
    margin-bottom: 0;
  }
`

const VerticalFlow = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 350px;

  /*
   * NOTE: The 10px l/r margin works with the -10px margin on Flow to make sure
   * the gutters only exist
   */
  margin: 15px 10px 0;

  padding: 10px 5px 5px 5px;
  background-color: #eee;
  border-radius: 2px;

  & > h5 {
    text-align: center;
    margin-bottom: 0;
  }
`

const instanceStates = (pre, next) => ({
  ...pre,
  [next.state]: (pre[next.state] || 0) + 1,
})

const instanceTypes = (pre, next) => ({
  ...pre,
  [next.type]: [...(pre[next.type] || []), next],
})

const Summary = props => {
  const { instances, setIsOpen, reload } = props
  const stateTotals = instances.reduce(instanceStates, {})

  return (
    <Card
      title="DEDICATED INSTANCES"
      extra={
        <Space>
          <Button
            type="default"
            onClick={reload}
            icon={<FontAwesomeIcon icon={faSyncAlt} />}
          >
            Reload
          </Button>
          <Button
            type="primary"
            onClick={() => setIsOpen(true)}
            icon={<FontAwesomeIcon icon={faPlus} />}
          >
            Add Instance
          </Button>
        </Space>
      }
    >
      <StatsFlow>
        <Card>
          <Statistic
            title="Total"
            value={instances.length}
            style={{ textAlign: "center" }}
          />
        </Card>
        {Object.keys(stateTotals).map(key => {
          return (
            <Stat key={key}>
              <h6>{key}</h6>
              <p>{stateTotals[key]}</p>
            </Stat>
          )
        })}
      </StatsFlow>
    </Card>
  )
}

export default props => {
  const { customer } = props
  const instances = useSelector(selectInstances)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()

  const reload = () =>
    dispatch(actions.loadInstances({ cname: customer.normalizedName }))

  useEffect(() => {
    const cname = customer.normalizedName
    dispatch(actions.loadInstances({ cname }))

    // NOTE: Leaving this commented code here because it could be useful. But it
    // could also lead to excessive API requests being sent to EC2. I'd rather
    // avoid hitting AWS rate limiting. But if we want it on, here it is.
    //
    // https://docs.aws.amazon.com/AWSEC2/latest/APIReference/throttling.html
    const interval = setInterval(() => {
      dispatch(actions.loadInstances({ cname }))
    }, 5000)
    return () => clearInterval(interval)
  })

  const groups = instances.reduce(instanceTypes, {})

  return (
    <DBBoundary>
      <Summary reload={reload} instances={instances} setIsOpen={setIsOpen} />
      <Flow>
        {Object.keys(groups).map(key => {
          const group = groups[key]

          return (
            <VerticalFlow key={key}>
              <h5>{key}</h5>
              {group.map(instance => {
                return (
                  <InstanceDetails
                    key={instance.id}
                    customer={customer}
                    instance={instance}
                  />
                )
              })}
            </VerticalFlow>
          )
        })}
      </Flow>
      <SidePane
        title="Create Instance"
        width="40vw"
        isVisible={isOpen}
        onCloseClicked={() => setIsOpen(false)}
        mask
      >
        <NewInstance afterSubmit={() => setIsOpen(false)} />
      </SidePane>
    </DBBoundary>
  )
}
