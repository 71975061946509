import styled from "styled-components"
import { Typography } from "antd"

const { Title } = Typography

const StyledTitle = styled(Title)`
  && {
    overflow: hidden;
    padding-top: 5px;
    margin-bottom: 0;
    padding-left: 5px;
    white-space: nowrap;
    padding-bottom: 2px;
    text-overflow: ellipsis;
    border-bottom: 1px solid #eee;
  }
`

export default StyledTitle
