import React from "react"
import { Space } from "antd"

import FieldTypeIcon from "../../shared/FieldTypeIcon"

const getColumnSelectOptions = columns => {
  if (!columns?.length) return []
  return columns.map(({ name, label, type, format, formatOptions }) => ({
    value: name,
    label: (
      <Space>
        <FieldTypeIcon type={type} />
        {label || name}
      </Space>
    ),
    format,
    columnType: type,
    formatOptions,
  }))
}

export default getColumnSelectOptions
