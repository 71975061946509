import React, { useCallback, useRef, useState } from "react"
import styled from "styled-components"
import { Row, Col, Tabs, Button } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faEdit } from "@fortawesome/free-solid-svg-icons"

import { CodeEditor, useCheckForDBAdmin } from "@dbai/ui-staples"

import StandardEditor from "../StandardEditor"
import { useSavedFormValueSpec } from "../../JSONSchemaForm/hooks"
import useFormFieldActions from "../../JSONSchemaForm/hooks/useFormFieldActions"

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
    z-index: 1;
  }
  .ant-tabs-content-holder {
    height: 100%;
    overflow: hidden;
    .ant-tabs-content {
      height: 100%;
    }
  }
`

const jsonEditorContainerStyles = {
  height: "calc(100vh - 159px)",
  overflowY: "auto",
}

const JSONEditor = props => {
  const { initialValue, onChange, readOnly } = props
  const [data, setData] = useState(initialValue)
  const initCodeMirror = useCallback(
    codeMirror => {
      const handleChange = val => {
        try {
          const jsonData = JSON.parse(val.getValue())
          setData(val.getValue())
          if (onChange) {
            onChange(jsonData)
          }
        } catch (err) {
          console.log(err)
          // If the user enters invalid json, just silently fail
        }
      }
      codeMirror.on("change", handleChange)
    },
    [onChange]
  )
  return (
    <CodeEditor
      mode="javascript"
      initialValue={data}
      initCodeMirror={initCodeMirror}
      options={{ json: true, readOnly }}
    />
  )
}

//this shows the form data that produces the form preview
const FormSchemaPreview = props => {
  const { path, value: formProperties } = useSavedFormValueSpec("properties")
  const { onChange } = useFormFieldActions(path)
  const jsonEditorData = useRef(formProperties)
  const [editing, setEditing] = useState(false)
  const handleSave = e => {
    e.preventDefault()
    e.stopPropagation()
    setEditing(false)
    onChange && onChange(jsonEditorData.current)
  }
  const handleChange = data => {
    jsonEditorData.current = data
  }
  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        {editing ? (
          <Button onClick={handleSave}>
            <FontAwesomeIcon icon={faCheck} /> Save
          </Button>
        ) : (
          <Button onClick={() => setEditing(true)}>
            <FontAwesomeIcon icon={faEdit} /> Edit
          </Button>
        )}
      </Col>
      <Col span={24} style={jsonEditorContainerStyles}>
        {editing ? (
          <JSONEditor
            key="json-editor"
            readOnly={false}
            onChange={handleChange}
            initialValue={JSON.stringify(formProperties, null, 2)}
          />
        ) : (
          <JSONEditor
            key="json-preview"
            readOnly="nocursor"
            watch={JSON.stringify(formProperties, null, 2)}
            initialValue={JSON.stringify(formProperties, null, 2)}
          />
        )}
      </Col>
    </Row>
  )
}

const Previewer = () => {
  const items = [
    {
      key: "preview",
      label: "Preview",
      children: <StandardEditor.Previewer />,
    },
    {
      key: "widget-data",
      label: "Widget Data",
      children: <FormSchemaPreview />,
    },
  ]
  return <StyledTabs items={items} />
}

const FormEditor = props => {
  const isAdmin = useCheckForDBAdmin()
  if (!isAdmin) return <StandardEditor {...props} />
  return <StandardEditor {...props} Previewer={Previewer} />
}

export default FormEditor
