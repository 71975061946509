import { gql } from "@apollo/client"

export const CREATE_POLICY = gql`
  mutation createPolicy($customerId: Int!, $input: PolicyInput!) {
    createPolicy(customerId: $customerId, input: $input) {
      id
    }
  }
`

export const UPDATE_POLICY = gql`
  mutation updatePolicy(
    $customerId: Int!
    $policyId: Int!
    $input: PolicyInput!
  ) {
    updatePolicy(customerId: $customerId, policyId: $policyId, input: $input) {
      id
    }
  }
`

export const GET_SUBJECTS = gql`
  query {
    subjects
  }
`

export const GET_POLICY_ACTIONS = gql`
  query {
    policyActions
  }
`

export const UPDATE_ROLE = gql`
  mutation UpdateRole($customerId: Int!, $id: Int!, $input: RoleInput!) {
    updateRole(customerId: $customerId, id: $id, input: $input) {
      id
    }
  }
`

export const DELETE_ROLE = gql`
  mutation DeleteRole($customerId: Int!, $id: Int!) {
    deleteRole(customerId: $customerId, id: $id) {
      id
    }
  }
`

export const CREATE_ROLE = gql`
  mutation CreateRole($customerId: Int!, $input: RoleInput!) {
    createRole(customerId: $customerId, input: $input) {
      id
    }
  }
`
