import React, { useCallback, useRef, useEffect } from "react"
import { isEqual } from "lodash"

import FormList from "../FormList"
import { useFormData } from "../hooks"
import FormFieldWrapper from "../FormFieldWrapper"
import { DBAI_NODE_CLASSNAME, getConfigurableNodes } from "../../lib/tinyMce"

const ListItemWrapper = props => {
  const { index, children, ...rest } = props
  const widgetId = useFormData("id")
  const hoveredNodeStyleRef = useRef()
  const hoveredNode = useRef()

  // ensure box shadow is removed if component is unmounted
  useEffect(() => {
    return () => {
      if (hoveredNode.current) {
        hoveredNode.current.style.boxShadow = hoveredNodeStyleRef.current
      }
    }
  })

  if (!window.tinymce) return

  const editor = tinymce.get(widgetId)

  const handleMouseOver = index => () => {
    const targetNode = editor
      .getBody()
      .querySelectorAll(`.${DBAI_NODE_CLASSNAME}`)[index]
    if (targetNode) {
      hoveredNode.current = targetNode
      hoveredNodeStyleRef.current = targetNode.style.boxShadow
      targetNode.style.boxShadow = "inset 0 0 0 2px #63c363"
    }
  }

  const handleMouseOut = index => () => {
    const targetNode = editor
      .getBody()
      .querySelectorAll(`.${DBAI_NODE_CLASSNAME}`)[index]
    if (targetNode) {
      targetNode.style.boxShadow = hoveredNodeStyleRef.current
      hoveredNode.current = null
    }
  }
  return React.cloneElement(children, {
    ...rest,
    onMouseOut: handleMouseOut(index),
    onMouseOver: handleMouseOver(index),
  })
}

const TextNodesCore = props => {
  const {
    name,
    path,
    onAppend,
    onChange,
    onRemove,
    schema,
    value = [],
    ...rest
  } = props

  const widgetId = useFormData("id")
  const editor = window.tinymce ? tinymce.get(widgetId) : null

  const updateNodes = useCallback(() => {
    if (!editor) return
    // Get all app variable nodes from text editor
    const body = editor.getBody()

    if (!body) return

    const dbaiNodes = Array.from(
      body.querySelectorAll(`.${DBAI_NODE_CLASSNAME}`)
    )

    const newNodes = getConfigurableNodes(dbaiNodes).map(node => {
      const existingNodeConfig = value.find(v => v.nodeId === node.nodeId) || {}
      return {
        ...node,
        ...existingNodeConfig,
      }
    })

    // Update the nodes if the computed nodes are differnet from the existing nodes
    if (!isEqual(newNodes, value)) {
      onChange(newNodes)
    }
  }, [editor, value, onChange])

  const handleChange = useCallback(
    value => {
      onChange(value)
    },
    [onChange]
  )

  useEffect(() => {
    updateNodes() // Initial update

    if (editor) {
      // Attach a change event listener to the editor to listen for content changes
      editor.on("change", updateNodes)

      // Cleanup: Remove the event listener when the component is unmounted
      return () => {
        editor.off("change", updateNodes)
      }
    }
  }, [editor, updateNodes])

  return (
    <FormList
      path={path}
      name={name}
      value={value}
      schema={schema}
      onRemove={onRemove}
      onChange={handleChange}
      {...rest}
      ListItemWrapper={ListItemWrapper}
    />
  )
}

const TextNodes = props => {
  return (
    <FormFieldWrapper noStyle {...props}>
      <TextNodesCore />
    </FormFieldWrapper>
  )
}
TextNodes.Core = TextNodesCore
export default TextNodes
