import React, { useMemo, useState, useCallback, useEffect } from "react"
import { toast } from "react-toastify"
import { useSelector, useDispatch } from "react-redux"

import { TableWithFolders } from "@dbai/ui-staples"

import FormatToD from "./FormatToD"
import FormatWorkflow from "./FormatWorkflow"
import TableRowActions from "./TableRowActions"
import { actions } from "reducers/schedulesReducer"
import AlertManagementPanel from "./AlertManagementPanel"

const columns = [
  {
    title: "Time of Day",
    key: "spec",
    dataIndex: "spec",
    render: (value, _) => <FormatToD value={value} />,
  },
  {
    title: "Workflow",
    key: "package",
    dataIndex: "package",
    render: (value, _) => <FormatWorkflow value={value} />,
  },
]

const columnOrder = ["spec", "package", "createdAt", "actions"]
export const ScheduleTableContext = React.createContext({})

const SchedulesTable = props => {
  const { customer } = props
  const dispatch = useDispatch()
  const schedules = useSelector(state => state.schedules.schedules)

  const [scheduleId, selectSchedule] = useState(null)
  const closeAlertsPanel = useCallback(() => selectSchedule(null), [])
  const openAlertsPanel = useCallback(id => selectSchedule(id), [])
  const alertsPanelContext = useMemo(
    () => ({
      scheduleId,
      closeAlertsPanel,
      openAlertsPanel,
    }),
    [scheduleId, closeAlertsPanel, openAlertsPanel]
  )

  useEffect(() => {
    dispatch(actions.loadSchedules({ cname: customer.normalizedName })).catch(
      () => toast.error("There was an error loading your Schedules")
    )
  }, [dispatch, customer.normalizedName])

  return (
    <ScheduleTableContext.Provider value={alertsPanelContext}>
      <TableWithFolders
        columns={columns}
        folderType="schedules"
        dataSource={schedules}
        columnOrder={columnOrder}
        Actions={TableRowActions}
        emptyText="No Schedules Found"
        openAlertsPanel={openAlertsPanel}
      />
      <AlertManagementPanel />
    </ScheduleTableContext.Provider>
  )
}

export default SchedulesTable
