import React, { useState } from "react"
import { Button } from "antd"
import { useDispatch } from "react-redux"

import { SidePane } from "@dbai/ui-staples"

import NewSchedule from "./NewSchedule"
import SchedulesTable from "./SchedulesTable"
import { actions } from "reducers/schedulesReducer"
import { actions as scheduleActions } from "reducers/scheduleReducer"
import IndexHeader from "components/pages/Workflows/shared/IndexHeader"
import WorkflowsContent from "components/pages/Workflows/shared/WorkflowsContent"

const Schedules = props => {
  const { customer } = props
  const dispatch = useDispatch()
  const [showPanel, setShowPanel] = useState(false)

  const openPanel = () => setShowPanel(true)
  const closePanel = () => {
    setShowPanel(false)
    dispatch(scheduleActions.reset())
  }

  const afterCreate = () => {
    closePanel()
    const cname = customer.normalizedName
    dispatch(actions.loadSchedules({ cname }))
  }

  return (
    <>
      <IndexHeader>
        <Button onClick={openPanel} type="primary">
          + NEW SCHEDULE
        </Button>
      </IndexHeader>
      <WorkflowsContent>
        <div>
          <SchedulesTable
            showNew={showPanel}
            customer={customer}
            onNewClose={closePanel}
          />
          <SidePane
            title="Schedule A Workflow"
            isVisible={showPanel}
            onCloseClicked={closePanel}
            width="50vw"
            mask
          >
            <NewSchedule afterCreate={afterCreate} customer={customer} />
          </SidePane>
        </div>
      </WorkflowsContent>
    </>
  )
}

export default Schedules
