import objectHash from "object-hash"

const getUsableWidgets = (isAdmin, widgetRegistry) => {
  const usableWidgets = Object.entries(widgetRegistry).filter(
    ([, { adminOnly }]) => (adminOnly ? isAdmin : true)
  )
  const values = usableWidgets.map(([widgetKey]) => widgetKey)
  const labels = usableWidgets.map(([, { label }]) => label)
  return [values, labels]
}

const getWidgetTypeConditions = widgetRegistry => {
  const types = Object.keys(widgetRegistry)
  return types
    .filter(t => Boolean(widgetRegistry[t].schema))
    .map(type => {
      const widgetSchema = { ...widgetRegistry[type].schema }
      const widgetSchemaSha = objectHash(widgetSchema)
      widgetSchema.ajvId = widgetSchemaSha

      return {
        if: {
          properties: {
            type: {
              enum: [type],
            },
          },
        },
        then: {
          properties: {
            name: {
              title: "Name",
              type: "string",
              default: widgetRegistry[type].label,
              metadata: {
                hidden: true,
              },
            },
            "/options": { ...widgetSchema, title: "Options" },
          },
        },
      }
    })
}

const getLayoutProperties = (prefix, disabled) => {
  return {
    [`${prefix}/x`]: {
      minimum: 0,
      type: "number",
      title: "Horizontal Position",
      metadata: {
        fieldProps: {
          disabled,
        },
      },
    },
    [`${prefix}/y`]: {
      minimum: 0,
      type: "number",
      title: "Vertical Position",
      metadata: {
        fieldProps: {
          disabled,
        },
      },
    },
    [`${prefix}/w`]: {
      minimum: 1,
      maximum: 120,
      type: "number",
      title: "Width",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
        fieldProps: {
          disabled,
        },
      },
    },
    [`${prefix}/h`]: {
      minimum: 1,
      maximum: 60,
      type: "number",
      title: "Height",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
        fieldProps: {
          disabled,
        },
      },
    },
  }
}

const getLayoutSchema = breakpoint => {
  const prefix = `layout/${breakpoint}`
  return {
    type: "object",
    dependencies: {
      [`${prefix}/interpolate`]: {
        if: {
          properties: {
            [`${prefix}/interpolate`]: {
              const: true,
            },
          },
        },
        then: {
          properties: getLayoutProperties(prefix, true),
        },
        else: {
          properties: getLayoutProperties(prefix, false),
        },
      },
    },
    properties: {
      [`${prefix}/interpolate`]: {
        default: true,
        type: "boolean",
        title: "Interpolate",
      },
    },
  }
}

const generateWidgetSchema = (isAdmin, widgetRegistry) => {
  const [enumValues, enumLabels] = getUsableWidgets(isAdmin, widgetRegistry)
  const widgetTypeConditions = getWidgetTypeConditions(widgetRegistry)

  const widgetSchema = {
    type: "object",
    default: {},
    title: "Widget",
    required: ["name", "type"],
    dependencies: {
      type: {
        allOf: widgetTypeConditions,
      },
    },
    metadata: {
      sectionType: "tabs",
      sections: {
        widget: {
          title: "Widget",
          properties: ["name", "type", "id", "/options"],
          containerStyle: {
            padding: 0,
            paddingTop: 10,
          },
        },
        layout: {
          title: "Layout",
          properties: ["layout"],
        },
      },
    },
    properties: {
      name: {
        title: "Name",
        type: "string",
        metadata: {
          hidden: true,
        },
      },
      type: {
        type: "string",
        title: "Type",
        enum: enumValues,
        metadata: {
          hideOnSelect: true,
          labels: enumLabels,
          wrapperStyle: {
            padding: "16px 16px 0 16px",
          },
        },
      },
      id: {
        type: ["string", "number"],
        metadata: {
          hidden: true,
        },
      },
      layout: {
        default: {},
        type: "object",
        title: "Layout",
        metadata: {
          component: "LayoutInput",
        },
        properties: {
          lg: {
            type: "object",
            properties: getLayoutProperties("layout/lg", false),
          },
          md: getLayoutSchema("md"),
          sm: getLayoutSchema("sm"),
          xs: getLayoutSchema("xs"),
          xxs: getLayoutSchema("xxs"),
        },
      },
    },
  }
  const widgetSchemaSha = objectHash(widgetSchema)
  widgetSchema.ajvId = widgetSchemaSha
  return widgetSchema
}

export { generateWidgetSchema }
