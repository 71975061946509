import config from "config"
import { curry } from "lodash"

const { host, secure, includeCredentials } = config.jupyterhub

const protoFor = proto => (secure ? proto + "s" : proto)
const hubHost = `${protoFor("http")}://${host}`
const wsHubHost = `${protoFor("ws")}://${host}`
const hubAPI = `${hubHost}/hub/api`
const getToken = () => localStorage.getItem("user/jwt")

const send = (url, opts = {}) => {
  return fetch(url, {
    ...(includeCredentials ? { credentials: "include" } : {}),
    ...opts,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      ...opts.headers,
    },
  })
}

const checkStatusCode = response => {
  if (response.status > 299) {
    throw response.message || response
  }
  return response
}

const parseBody = response => response.json()

// wait pasues a promise chain for `time` before continuing.
const wait = time => new Promise(resolve => setTimeout(resolve, time))

const cancelationMessage = "Request Canceled"
/*
 * retry wraps a function, `op`, and retries it up to `max` times when `execute` is called
 * while backing off exponentially. Any extra `args` are passed through to the wrapped
 * function unchanged. `op` must return a promise. Set a max of null for infinite retries.
 * Set incremental to false to prevent incremental backoff.
 * Call `cancel` on the returned object to cancel future attempts.
 */
const retry = (op, opts = {}) => {
  let canceled = false

  const execute = (...args) => {
    const { max = 3, initialBackoff = 2, incremental = true } = opts

    const tryIt = curry((count, backoff, resolve, reject) => {
      if (canceled) return reject(cancelationMessage)

      const handleCancelation = data => {
        if (canceled) return reject(cancelationMessage)
        return data
      }

      const errHandler = err => {
        if (max !== null && count >= max) {
          reject(err)
          return
        }

        return wait(backoff * 1000).then(() => {
          const newBackoff = incremental ? backoff * 2 : backoff
          return tryIt(count + 1, newBackoff, resolve, reject)
        })
      }

      return op(...args)
        .then(resolve)
        .then(handleCancelation)
        .catch(errHandler)
    })

    return new Promise(tryIt(0, initialBackoff))
  }

  const cancel = () => {
    canceled = true
  }

  return {
    execute,
    cancel,
  }
}

// CRUD Operations for Jupyterhub Servers.
const serversAPI = {
  create: (cname, name, spawnParams = {}) => {
    const url = `${hubAPI}/users/${cname}/servers/${name}`
    const body = JSON.stringify({
      ...spawnParams,
      deltabravo_token: getToken(),
    })
    return send(url, { method: "POST", body }).then(checkStatusCode)
  },
  all: cname => {
    const url = `${hubAPI}/users/${cname}`
    return send(url).then(checkStatusCode).then(parseBody)
  },
  stop: (cname, name) => {
    const url = `${hubAPI}/users/${cname}/servers/${name}`
    const body = JSON.stringify({
      remove: true,
    })
    return send(url, { method: "DELETE", body }).then(checkStatusCode)
  },
}

// CRUD Operations for Jupyter Notebook Kernels.
const kernelsAPI = {
  create: (cname, serverName) => {
    const url = `${hubHost}/user/${cname}/${serverName}/api/kernels`
    return send(url, { method: "POST" }).then(checkStatusCode).then(parseBody)
  },
  all: (cname, serverName) => {
    const url = `${hubHost}/user/${cname}/${serverName}/api/kernels`
    return send(url).then(checkStatusCode).then(parseBody)
  },
  channels: (cname, serverName, id) => {
    const url = `${wsHubHost}/user/${cname}/${serverName}/api/kernels/${id}/channels?_token=${getToken()}`

    return new Promise((resolve, reject) => {
      const socket = new WebSocket(url)

      socket.onopen = () => resolve(socket)
      socket.onerror = reject
    })
  },
  interrupt: (cname, serverName, id) => {
    const url = `${hubHost}/user/${cname}/${serverName}/api/kernels/${id}/interrupt`
    return send(url, { method: "POST" }).then(checkStatusCode)
  },
  restart: (cname, serverName, id) => {
    const url = `${hubHost}/user/${cname}/${serverName}/api/kernels/${id}/restart`
    return send(url, { method: "POST" }).then(checkStatusCode)
  },
  destroy: (cname, serverName, id) => {
    const url = `${hubHost}/user/${cname}/${serverName}/api/kernels/${id}`
    return send(url, { method: "DELETE" }).then(checkStatusCode)
  },
}

export { serversAPI, kernelsAPI, retry }
