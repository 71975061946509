import React, { memo, useCallback } from "react"
import { kebabCase } from "lodash"
import ReactDragListView from "react-drag-listview"

import DefaultListItems from "./DefaultListItems"
import CollapsedListItems from "./CollapsedListItems"
import { useFormData } from "../../hooks"

const ListsMap = {
  default: DefaultListItems,
  collapse: CollapsedListItems,
}

const ArrayList = props => {
  const { name, onChange, schema = {} } = props
  const value = useFormData(name)
  const { metadata = {} } = schema
  const { itemType = "default", draggable = true } = metadata

  const handleDragEnd = useCallback(
    (fromIndex, toIndex) => {
      if (toIndex < 0) return // Ignores if outside designated area
      const items = [...value]
      const item = items.splice(fromIndex, 1)[0]
      items.splice(toIndex, 0, item)
      onChange && onChange(items, "moved", fromIndex, toIndex)
    },
    [onChange, value]
  )

  if (!value?.length) {
    return null
  }

  const List = ListsMap[itemType]

  if (!draggable) {
    return <List {...props} value={value} />
  }

  const handleClassName = kebabCase(schema.title) + "-drag-handle"
  const nodeClassName = kebabCase(schema.title) + "-drag-item"
  return (
    <ReactDragListView
      onDragEnd={handleDragEnd}
      nodeSelector={`.${nodeClassName}`}
      handleSelector={`.${handleClassName}`}
    >
      <List
        {...props}
        value={value}
        nodeClassName={nodeClassName}
        handleClassName={handleClassName}
      />
    </ReactDragListView>
  )
}

export default memo(ArrayList)
