import React, { useCallback, useMemo, useState } from "react"
import { ConfigProvider, Input, Row, Col, Skeleton } from "antd"

import { ErrorMessage } from "@dbai/ui-staples"

import DnDList from "./DnDList"
import { useWidgetQuery } from "../hooks"
import EmptyPrompt from "../shared/EmptyPrompt"
import FilterColumnTypes from "../shared/FilterColumnTypes"
import useDatasetColumns from "../../hooks/useDatasetColumns"

const { Search } = Input

const getColumnDndContent = (column, index) => ({
  index,
  id: column.name,
  itemType: "column",
  content: {
    column: column.name,
    label: column.label || column.name,
    type: column.__typename === "ComputedColumnSpec" ? "computed" : column.type,
  },
})

const ColumnList = props => {
  const { initialValue = [], clickOnly, onChange, allowedTypes } = props
  const [filterTypes, setFilterTypes] = useState([])
  const { datasetId } = useWidgetQuery()
  const [search, setSearch] = useState("")

  const {
    error,
    loading,
    columns: datasetColumns,
  } = useDatasetColumns({ datasetId, allowedTypes, search, filterTypes })

  const handleFilterType = useCallback(
    type => () => {
      if (filterTypes.includes(type)) {
        return setFilterTypes(filterTypes.filter(ft => ft !== type))
      }
      setFilterTypes(filterTypes.concat(type))
    },
    [filterTypes]
  )

  const handleSearch = useCallback(value => {
    setSearch(value)
  }, [])

  const columns = useMemo(() => {
    return datasetColumns.map(getColumnDndContent)
  }, [datasetColumns])

  const initialColumns = useMemo(() => {
    const initialIDs = initialValue.map(item => item.id)
    const initialColumns = columns.filter(column =>
      initialIDs.includes(column.id)
    )
    return initialColumns
  }, [columns, initialValue])

  switch (true) {
    case Boolean(error):
      return <ErrorMessage error={error} />
    case Boolean(loading):
      return <Skeleton />
    case !Boolean(datasetId):
      return <EmptyPrompt>Please select a dataset.</EmptyPrompt>
    default:
      return (
        <DnDList
          list={columns}
          onChange={onChange}
          clickOnly={clickOnly}
          initialValue={initialColumns}
          header={
            <Row>
              <Col span={24}>
                <ConfigProvider theme={{ token: { borderRadius: 0 } }}>
                  <Search allowClear onSearch={handleSearch} />
                </ConfigProvider>
              </Col>
              <Col span={24}>
                {!columns.length ? (
                  <EmptyPrompt>No Columns.</EmptyPrompt>
                ) : !allowedTypes?.length ? (
                  <FilterColumnTypes
                    filterTypes={filterTypes}
                    columns={columns}
                    handleChange={handleFilterType}
                  />
                ) : null}
              </Col>
            </Row>
          }
        />
      )
  }
}

export default ColumnList
