import { get } from "lodash"

/*
 * Given a nodeIdx and possibly a cellIdx and a suffix, create a "path" to the
 * object in the reducer.
 * suffix will always be appended at the end of the given path and have it's
 * leading "." added.
 */
const getCellIndex = cellIdx => {
  if (cellIdx !== 0 && !cellIdx) return ""
  return `.cells[${cellIdx}]`
}

export const createPath = ({ nodeIdx, cellIdx, suffix }) => {
  const nodeSelector = `workflow.spec.nodes[${nodeIdx}]`
  const cellSelector = getCellIndex(cellIdx)
  const dotSuffix = suffix ? `.${suffix}` : ""
  return `${nodeSelector}${cellSelector}${dotSuffix}`
}

/*
 * Check if the given value exists and that it is a node of the given type.
 */
export const isNodeOfType = type => node => {
  return node && node.type === type
}

export const isScriptNode = isNodeOfType("script")
export const isDownloadNode = isNodeOfType("download")
export const isComponentNode = isNodeOfType("component")

/*
 * Get the path to a node at the given index.
 */
export const getNodePath = index => `workflow.spec.nodes[${index}]`

/*
 * Given either a string that is a node's id or the node it's self, return the
 * node's id.
 */
export const getNodeId = nodeData => {
  if (typeof nodeData === "string") return nodeData
  return nodeData.id
}

/*
 * Find the node for the given nodeId contained in nodes.
 */
export const getNodeById = (nodeId, nodes) => nodes.find(n => n.id === nodeId)

/*
 * Find the nodeIdx for the given nodeId contained in nodes.
 */
export const getNodeIdxById = (nodeId, nodes) =>
  nodes.findIndex(n => n.id === nodeId)

export const findNodeById = (nodeId, state) => {
  const notebookRoot = state.notebook ? state.notebook : state
  const nodes = notebookRoot?.workflow?.spec?.nodes || []
  return getNodeById(nodeId, nodes)
}

/*
 * Given an index and either the root state of the redux store or the notebook
 * slice, find the node located at the given index.
 */
export const getNodeAtIndex = (index, state) => {
  const notebookRoot = state.notebook ? state.notebook : state
  const path = getNodePath(index)
  const node = get(notebookRoot, path)
  if (!node) {
    console.warn("Unable to find node: ", path)
    return
  }

  return node
}

export const getNodeByIdOrIndex = ({ nodeIdx, nodeId }, state) => {
  if (nodeId) {
    return findNodeById(nodeId, state)
  }
  return getNodeAtIndex(nodeIdx, state)
}

/*
 * Given a node or it's id, and a list of edges, find the edges pointing
 * _towards_ the node identified.
 */
export const getEdgesTargettingNode = (nodeData, edges) => {
  return edges.filter(edge => edge.target === getNodeId(nodeData))
}

/*
 * Given a list of edges, compile all the nodes that reside at their "source"
 * values and return them in an array.
 */
export const getSourceNodes = (edges, nodes) => {
  return edges.map(edge => getNodeById(edge.source, nodes)).filter(Boolean)
}

/*
 * Given a list of edges, compile all the nodes that are of type "script" that
 * reside at their "source" values and return them in an array.
 */
export const getSourceScriptNodes = (edges, nodes) => {
  const filterOnlyScripts = node =>
    node.type === "script" || node.type === "component"
  return getSourceNodes(edges, nodes).filter(filterOnlyScripts)
}

/*
 * Given either a node's id or a node and the spec of a workflow, find all the
 * prior script nodes' outputs that feed into the current node.
 */
export const getAvailableOutputs = (nodeData, { nodes, edges }) => {
  const nodeId = getNodeId(nodeData)
  const edgesPointingIn = getEdgesTargettingNode(nodeId, edges)
  const sourceNodes = getSourceScriptNodes(edgesPointingIn, nodes)

  return sourceNodes.reduce((acc, next) => {
    return [...acc, ...next.artifacts]
  }, [])
}

/*
 * Given the id of a target node and the id of a source node, isolate and return
 * the edge that connects them.
 */
export const getConnectingEdge = ({ sourceId, targetId }, edges) => {
  return (
    edges.find(edge => {
      return edge.source === sourceId && edge.target === targetId
    }) || null
  )
}

/*
 * Given either a string that is a output's id or the output it's self, return the
 * output's id.
 */
export const getOutputId = outputData => {
  if (typeof outputData === "string") return outputData
  return outputData.id
}

/*
 * Given an output or it's id, find the node that it originated from.
 */
export const findOutputSource = (outputData, nodes) => {
  const outputId = getOutputId(outputData)
  return (
    nodes.find(node => {
      return node.artifacts.find(output => outputId === output.id)
    }) || null
  )
}

export const getNodesFromSpecOrNodes = data => {
  if (data.workflow) return data.workflow.spec.nodes
  if (data.notebook) return data.notebook.workflow.spec.nodes
  return data
}

/*
 * Takes either an array of nodes or the current state of the reducer and
 * returns an object containing the cell that has a uuid of cellId, the
 * node.id of the containing node, and the index of the cell.
 */
export const getCellById = (cellId, data) => {
  let cellIdx = null
  const nodes = getNodesFromSpecOrNodes(data)
  const nodeIdx = nodes.findIndex(node => {
    if (!node.cells) return false
    return node.cells.find((cell, index) => {
      if (cell.uuid === cellId) {
        cellIdx = index
        return true
      }
      return false
    })
  })
  if (nodeIdx === -1 || cellIdx === null) return {}

  const node = nodes[nodeIdx]
  const path = createPath({ nodeIdx, cellIdx })

  return {
    path,
    cellId,
    cellIdx,
    nodeIdx,
    nodeId: node.id,
    cell: node.cells[cellIdx],
  }
}

const namespacePattern = /%%dbai_namespace/

// Checks all the cells in given node to see if any have the namespace magic command applied
export const isNodeNamespaced = node =>
  node?.cells
    ?.flatMap(c => c.source)
    ?.filter(source => source)
    ?.some(source => namespacePattern.test(source))
