import {
  execute,
  toPromise,
  ApolloLink,
  Observable,
  createHttpLink,
} from "@apollo/client"
import dayjs from "dayjs"
import { jwtDecode } from "jwt-decode"
import { createOperation } from "@apollo/client/link/utils"
import { setFromJwt } from "./currentUser"
import { REFRESH_AUTH, SIGN_OUT } from "../queries/auth"
import { deleteJwt } from "../lib/jwt"

const httpLink = graphqlUri => createHttpLink({ uri: graphqlUri })
const opBase = {
  extensions: {},
  context: {
    credentials: "include",
  },
  operationName: "RefreshAuth",
  query: REFRESH_AUTH,
  variables: {},
}

// RefreshLink makes the JWT isn't expired, and if it is it refreshes it from
// the API.
const createRefreshLink = (cache, graphqlUri) => {
  // inFlightRefresh is a Promise of an incoming JWT. Used to coordinate API
  // requests to ensure only one RefreshAuth query is sent.
  let inFlightRefresh = null

  const saveJwt = result => {
    setFromJwt(cache, result.data.refreshAuth)
    inFlightRefresh = null
    return result
  }

  return new ApolloLink((op, forward) => {
    const { jwt, claims } = op.getContext()

    if (!claims) {
      return forward(op)
    }

    const expiresAt = dayjs.unix(claims.exp)
    const cutoff = expiresAt.add(-1, "minute")

    // Don't refresh if the JWT is still good.
    if (dayjs() < cutoff) {
      return forward(op)
    }

    const refreshOp = createOperation(
      { credentials: "include" },
      { ...opBase, variables: { jwt } }
    )

    const signoutOp = createOperation(
      { credentials: "include" },
      { ...opBase, operationName: "SignOut", query: SIGN_OUT }
    )

    if (!inFlightRefresh) {
      inFlightRefresh = toPromise(
        execute(httpLink(graphqlUri), refreshOp)
      ).then(saveJwt)
    }

    return new Observable(observer => {
      inFlightRefresh
        .then(({ data, error, errors }) => {
          if (!error && !errors?.length) {
            op.setContext({
              jwt: data.refreshAuth,
              claims: jwtDecode(data.refreshAuth),
            })
            forward(op).subscribe(observer)
          } else {
            toPromise(execute(httpLink(graphqlUri), signoutOp)).then(deleteJwt)
          }
        })
        .catch(e => observer.error(e))
    })
  })
}

export default createRefreshLink
