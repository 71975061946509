import cronstrue from "cronstrue"

const format = ({ value, step }) => (step ? `${value}/${step}` : value)

export const makeCrontab = ({ minute, hour, dow, month, dom }) =>
  `${minute.map(format).join(",") || "*"} ${
    hour.map(format).join(",") || "*"
  } ${dom.map(format).join(",") || "*"} ${month.map(format).join(",") || "*"} ${
    dow.map(format).join(",") || "*"
  }`

export const scheduleSpecToSentence = spec => {
  return cronstrue.toString(makeCrontab(spec), {
    dayOfWeekStartIndexZero: true,
    use24HourTimeFormat: true,
  })
}
