import React, { useCallback } from "react"
import { Button } from "antd"
import { useDispatch } from "react-redux"

import { actions } from "reducers/componentReducer"

const SaveComponentButton = props => {
  const { beforeSubmit, afterSubmit, saveText } = props
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    e => {
      beforeSubmit && beforeSubmit(e)
      dispatch(actions.saveComponent()).then(() => {
        afterSubmit && afterSubmit(e)
      })
    },
    [dispatch, beforeSubmit, afterSubmit]
  )

  return (
    <Button type="primary" onClick={handleSubmit}>
      {saveText}
    </Button>
  )
}

export default SaveComponentButton
