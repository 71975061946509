import styled from "styled-components"

const ActionHeaderButton = styled.button`
  position: relative;
  border: none;
  &:focus {
    outline: none;
  }
`

export default ActionHeaderButton
