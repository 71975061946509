import React from "react"
import styled from "styled-components"
import { Button, Typography, Empty } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"

import { useWidgetContext } from "../../hooks"
import { selectAppPage } from "../../selectors/app"
import { actions } from "../../reducers/appReducer"

const { Title } = Typography

const StyledEmpty = styled(Empty)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const NoPageWidgets = props => {
  const { pageId } = props
  const dispatch = useDispatch()
  const { editable } = useWidgetContext()
  const pageName = useSelector(state => selectAppPage(state, { pageId })?.name)

  if (!editable) return null

  const handleAddWidget = () => {
    dispatch(actions.editWidget({ pageId, openFullEditor: true }))
  }

  return (
    <StyledEmpty
      key={pageId}
      description={
        <>
          <Title level={5}>No widgets</Title>
          <Button
            type="primary"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={handleAddWidget}
          >
            Add Widget to {pageName || "Page"}
          </Button>
        </>
      }
    />
  )
}

export default NoPageWidgets
