const genericWigdetTheme = {
  type: "object",
  default: {},
  properties: {
    "token/fontSize": {
      minimum: 2,
      maximum: 72,
      nullable: true,
      title: "Font Size",
      type: ["number", "null"],
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    colors: {
      type: "object",
      default: {},
      metadata: {
        layout: { gutter: [16, 16] },
      },
      properties: {
        lightModeColors: {
          type: "object",
          title: "Light Theme",
          default: {},
          metadata: {
            hideLabel: false,
            layout: { span: 12 },
          },
          properties: {
            "lightModeColors/colorTextBase": {
              nullable: true,
              title: "Text Color",
              type: ["string", "null"],
              description: "Color representing text",
              metadata: {
                layout: { span: 24 },
                component: "ColorPicker",
              },
            },
            "lightModeColors/colorBgBase": {
              nullable: true,
              type: ["string", "null"],
              title: "Background Color",
              description: "Primary text color",
              metadata: {
                layout: { span: 24 },
                component: "ColorPicker",
              },
            },
          },
        },
        darkModeColors: {
          type: "object",
          title: "Dark Theme",
          default: {},
          metadata: {
            hideLabel: false,
            layout: { span: 12 },
          },
          properties: {
            "darkModeColors/colorTextBase": {
              nullable: true,
              type: ["string", "null"],
              title: "Text Color",
              description: "Color representing text",
              metadata: {
                layout: { span: 24 },
                component: "ColorPicker",
              },
            },
            "darkModeColors/colorBgBase": {
              nullable: true,
              type: ["string", "null"],
              title: "Background Color",
              description: "Primary text color",
              metadata: {
                layout: { span: 24 },
                component: "ColorPicker",
              },
            },
          },
        },
      },
    },
  },
}

export default genericWigdetTheme
