import { useMemo, useCallback, useState, useEffect } from "react"
import { curry, flow } from "lodash"
import { useLazyQuery } from "@apollo/client"

import { stringToColor } from "@dbai/tool-box"
import { GET_DATASET_COLUMNS } from "@dbai/ui-staples"

import { findLabelConfig } from "../lib/datasetColumns"
import useWidgetContext from "./useWidgetContext"

export const getAllowedColumns = (allowedTypes, columns) => {
  if (!allowedTypes?.length) return columns
  return columns.filter(column => allowedTypes.includes(column.type))
}

const normalizeColumnType = {
  string: "categorical",
  categorical: "categorical",
  float: "numerical",
  int: "numerical",
  numerical: "numerical",
  datetime: "datetime",
  unknown: "numerical",
}

const filterColumnNames = curry((search, items) => {
  if (!search) return items
  const filterVal = search.toUpperCase()
  return items.filter(({ column, name, label }) => {
    const columnName = (column || name)?.toUpperCase().includes(filterVal)
    const columnLabel = label?.toUpperCase().includes(filterVal)
    return columnName || columnLabel
  })
})

const filterColumnTypes = curry((filterTypes, items) => {
  if (!filterTypes?.length) return items
  return items.filter(item => {
    return filterTypes.includes(normalizeColumnType[item.type])
  })
})

const filterAndSearchColumns = (search, filterTypes) => {
  return flow(filterColumnTypes(filterTypes), filterColumnNames(search))
}

const getDatasetColumns = dataset => {
  const { meta = {}, tableMeta = {} } = dataset || {}
  const { computedColumns = [] } = tableMeta || {}
  const { datasetColumns = [] } = meta || {}
  const columns = [
    ...datasetColumns,
    ...computedColumns.map(c => ({ ...c, type: "computed" })),
  ]
  return columns
}

export const getDefaultLabelConfigsForColumn = (
  column,
  labels,
  includeColor
) => {
  const columnConfigLabels = column.formatOptions?.labels || []
  return labels.map(label => {
    const existingLabelConfig = findLabelConfig(label, columnConfigLabels)
    return (
      existingLabelConfig || {
        label,
        usable: true,
        color: includeColor ? stringToColor(label) : null,
      }
    )
  })
}

const useFetchDatasetColumns = (cname, fetchPolicy) => {
  const [loadDatasetColumns] = useLazyQuery(GET_DATASET_COLUMNS, {
    fetchPolicy,
  })

  const fetchColumns = useCallback(
    async datasetId => {
      return await loadDatasetColumns({
        skip: !datasetId,
        variables: { id: datasetId, cname },
      }).then(async ({ data, error }) => {
        if (error) {
          console.error(error)
          return { columns: [], uniqueKeys: [] }
        }
        // merge dataset columns and computed columns
        const columns = getDatasetColumns(data.customer.dataset)
        const uniqueKeys = data.customer.dataset.tableMeta.uniqueKeys || []
        return { columns, uniqueKeys }
      })
    },
    [cname, loadDatasetColumns]
  )

  return fetchColumns
}

const useDatasetColumns = ({
  cname: _cname,
  search,
  datasetId,
  filterTypes,
  allowedTypes,
  fetchPolicy = "cache-and-network",
}) => {
  const [loading, setLoading] = useState(true)
  const [columns, setColumns] = useState([])
  const [uniqueKeys, setUniqueKeys] = useState([])
  const { cname = _cname } = useWidgetContext()

  if (!cname && process.env.NODE_ENV !== "production") {
    console.warn("useDatasetColumns: cname is not defined")
  }

  const fetchColumns = useFetchDatasetColumns(cname, fetchPolicy)

  const execFetchColumns = useCallback(async () => {
    if (!datasetId) return
    const { columns, uniqueKeys } = await fetchColumns(datasetId)
    setColumns(columns)
    setUniqueKeys(uniqueKeys)
    setLoading(false)
  }, [fetchColumns, datasetId])

  useEffect(() => {
    execFetchColumns()
  }, [execFetchColumns])

  const refetch = useCallback(() => {
    execFetchColumns()
  }, [execFetchColumns])

  const filteredColumns = useMemo(() => {
    const typeFilteredColumns = getAllowedColumns(allowedTypes, columns)
    return filterAndSearchColumns(search, filterTypes)(typeFilteredColumns)
  }, [search, allowedTypes, columns, filterTypes])

  return { loading, columns: filteredColumns, refetch, uniqueKeys }
}

export { getDatasetColumns }
export default useDatasetColumns
