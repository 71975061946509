import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import Select from "../../shared/Select"
import { actions } from "../../../reducers/formsReducer"
import FormFieldWrapper from "../../FormFieldWrapper"
import { getSelectMode } from "../../utils"
import useDatasetColumns from "../../../hooks/useDatasetColumns"
import getColumnSelectOptions from "../../lib/getColumnSelectOptions"
import { useWidgetQuery, useFormContext } from "../../hooks"

const ColumnSelectCore = props => {
  const { schema, parentName, parentSchema, ...rest } = props
  const { datasetId } = useWidgetQuery(schema, parentSchema, parentName)
  const { allowedTypes } = schema.metadata || {}
  const { loading, error, columns } = useDatasetColumns({
    datasetId,
    allowedTypes,
  })
  const options = getColumnSelectOptions(columns)
  if (error) return null
  return <Select loading={loading} options={options} {...rest} />
}

const ColumnSelect = props => {
  const { name, schema } = props
  const { metadata = {} } = schema
  const { columnTypeName = "type", saveColumnType = true } = metadata
  const dispatch = useDispatch()
  const { formId } = useFormContext()
  const mode = getSelectMode(schema)

  const handleChange = useCallback(
    (value, { value: column, columnType: type }) => {
      if (saveColumnType) {
        dispatch(
          actions.setFormField({
            id: formId,
            value: type,
            name: name ? `${name}.${columnTypeName}` : columnTypeName,
          })
        )
      }
    },
    [dispatch, saveColumnType, formId, name, columnTypeName]
  )

  return (
    <FormFieldWrapper {...props}>
      <ColumnSelectCore onChange={handleChange} parentName={name} mode={mode} />
    </FormFieldWrapper>
  )
}

ColumnSelect.Core = ColumnSelectCore
export default ColumnSelect
