import React from "react"
import { find } from "lodash"
import { Tooltip } from "antd"
import styled from "styled-components"
import { faClock } from "@fortawesome/free-solid-svg-icons"

import { FAMemo } from "@dbai/ui-staples"

const parseResultsToObject = (a = []) => {
  return a.map(v => {
    if (typeof v === "string") return JSON.parse(v)
    return v
  })
}

const StyledLoader = styled.img`
  width: 30px;
`

const RunCount = styled.span`
  cursor: default;
`

const isErrorStatus = (status, execResults) => {
  if (status === "error") return true

  //exec results are converted to strings when saved to the db. This checks
  //and converts it back to an object for processing.
  const results = parseResultsToObject(execResults)
  const found = find(results, ["output_type", "error"])
  return !!found
}

const PromptContent = React.memo(props => {
  const { status, execCount, execResults, cellType, runTime } = props
  if (cellType === "markdown") {
    return null
  }

  if (status === "loading") {
    return (
      <StyledLoader src="/icons/cell-loading.svg" alt="cell loading icon" />
    )
  }

  if (status === "waiting") return <FAMemo icon={faClock} />

  const hasError = isErrorStatus(status, execResults)
  const className = `badge badge-${hasError ? "danger" : "success"}`
  if (execCount) {
    const runTip = runTime ? `Run Time: ${runTime} Seconds` : null
    return (
      <Tooltip title={runTip}>
        <RunCount className={className}>{execCount}</RunCount>
      </Tooltip>
    )
  }

  return null
})

export default PromptContent
