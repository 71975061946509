import React, { useRef, useCallback, useState } from "react"
import { Modal, Button } from "antd"
import styled from "styled-components"
import { toast } from "react-toastify"

import { useAddRow } from "./hooks"
import JSONSchemaForm from "../../JSONSchemaForm"

const StyledModal = styled(Modal)`
  .ant-modal-body {
    width: 100%;
    overflow-y: auto;
    padding-right: 5px;
    max-height: calc(100vh - 33vh);
  }
`

const AddRow = props => {
  const { schema, datasetId, columns, refetch } = props
  const [showAddRowModal, setShowAddRowModal] = useState(false)
  const newRow = useRef({})
  const formErrors = useRef([])

  const handleFormChange = useCallback((data, errors) => {
    newRow.current = data
    formErrors.current = errors
  }, [])

  const openNewRowModal = useCallback(() => setShowAddRowModal(true), [])
  const handleAddRow = useAddRow(datasetId, columns, refetch)
  const handleOk = useCallback(() => {
    if (formErrors.current.length) {
      toast.error("Please fill all required fields or cancel")
      return
    }
    handleAddRow(newRow.current)
      .then(() => {
        newRow.current = {}
        formErrors.current = []
        setShowAddRowModal(false)
        toast.success("Row added successfully")
      })
      .catch(err => {
        console.error(err)
        toast.error("Unable to add new row. Please try again or cancel")
      })
  }, [handleAddRow])

  const handleCancel = useCallback(() => {
    setShowAddRowModal(false)
    newRow.current = {}
  }, [])

  return (
    <>
      <Button onClick={openNewRowModal}>Add Row</Button>
      <StyledModal
        centered
        width={500}
        destroyOnClose
        title="Add Row"
        onOk={handleOk}
        open={showAddRowModal}
        onCancel={handleCancel}
      >
        <JSONSchemaForm
          hideSave
          schema={schema}
          onFormChange={handleFormChange}
        />
      </StyledModal>
    </>
  )
}

export default AddRow
