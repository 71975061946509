import React, { useEffect } from "react"
import { useMutation } from "@apollo/client"
import { useParams, useHistory } from "react-router"

import PageSpinner from "../PageSpinner"
import { ACCEPT_INVITE } from "../../queries/users"

// These are errors that result from visiting an expired invite link. Handle
// them with a redirect.
const inviteErrors = [
  "Error: Invitation Previously Accepted",
  "Error: Invitation Expired",
]

const AcceptInvitation = props => {
  const { token } = useParams()
  const history = useHistory()

  const [accept, { data, error }] = useMutation(ACCEPT_INVITE, {
    variables: { token },
  })

  const dataReady = !!data

  const safeResetToken = dataReady ? data.acceptInvite.resetPasswordToken : ""
  const safeEmail = dataReady ? data.acceptInvite.email : ""
  const safeName = dataReady ? data.acceptInvite.normalizedName : ""

  useEffect(() => {
    accept()
  }, [accept])

  useEffect(() => {
    if (!dataReady && !error) return

    switch (true) {
      case inviteErrors.includes(error?.toString()):
        return history.push(`/login`)
      case Boolean(safeResetToken):
        return history.push(`/reset_password/${safeResetToken}/${safeEmail}`)
      default:
        return history.push(`/${safeName}`)
    }
  }, [error, history, dataReady, safeName, safeResetToken, safeEmail])

  return <PageSpinner />
}

export default AcceptInvitation
