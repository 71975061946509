import React, { useMemo, useCallback, useState } from "react"
import styled from "styled-components"
import { useParams } from "react-router"
import { Row, Col, Button } from "antd"
import { useMutation } from "@apollo/client"
import { faCheck, faTrash } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { uuidv4 } from "@dbai/tool-box"
import { SchemaForm, formatOptionsSchema } from "@dbai/applet"

import { debounce } from "lodash"
import { UPDATE_DATASET_META } from "queries"
import useDatasetOverview from "../useDatasetOverview"

const ListItem = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme }) => (theme ? theme.colorBorder : "#ccc")};
  background-color: ${({ selected, theme }) => {
    if (selected) {
      return theme.colorFillContentHover || "#eee"
    }
    return theme.colorFillContent || "#fff"
  }};
`

const StyledRow = styled(Row)`
  padding: 10px 0px;
  height: calc(100vh - 174px);
`

const StyledCol = styled(Col)`
  height: 100%;
  padding: 0 10px;
  overflow-y: auto;
`

const NEW_COMPUTED_COLUMN = {
  computation: [],
  label: "Computed Column",
}

const getComputedColumnSchema = datasetId => ({
  type: "object",
  metadata: {
    order: ["label", "format", "formatOptions", "computation"],
    sectionProps: {
      defaultActiveKey: ["computation"],
    },
    sections: {
      formatOptions: {
        title: "Format Options",
        properties: ["formatOptions"],
      },
      computation: {
        title: "Computation",
        properties: ["computation"],
      },
    },
  },
  dependencies: formatOptionsSchema.dependencies,
  properties: {
    datasetId: {
      title: "Dataset",
      default: datasetId,
      type: "string",
      metadata: {
        hidden: true,
        component: "Dataset",
      },
    },
    name: {
      title: "Name",
      type: "string",
      metadata: {
        hidden: true,
      },
    },
    label: {
      title: "Label",
      type: "string",
    },
    ...formatOptionsSchema.properties,
    computation: { $ref: "#/$defs/computation" },
  },
})

const ComputedColumns = props => {
  const { customer } = props
  const { id } = useParams()
  const [status, setStatus] = useState("idle")
  const [selectedColumn, setSelectedColumn] = useState(null)
  const [updateTableColumnMeta] = useMutation(UPDATE_DATASET_META)

  const { datasetName, refetch, columns } = useDatasetOverview(id, customer)
  const computedColumns = columns
    ?.filter(({ __typename }) => __typename === "ComputedColumnSpec")
    ?.map(({ __typename, type, ...column }) => column)

  const saveComputedColumns = useCallback(
    newComputedColumns => {
      if (!id || !customer.id || !datasetName) return
      return updateTableColumnMeta({
        variables: {
          id,
          name: datasetName,
          customerId: customer.id,
          tableMeta: {
            computedColumns: newComputedColumns,
            // save the other columns as well
            columns: columns
              .filter(({ __typename }) => __typename !== "ComputedColumnSpec")
              .map(({ __typename, type, ...column }) => column),
          },
        },
      }).then(refetch)
    },
    [customer, columns, id, datasetName, refetch, updateTableColumnMeta]
  )

  //eslint-disable-next-line
  const saveComputedColumn = useCallback(
    debounce((columnName, { datasetId, ...updatedColumn }) => {
      if (!id || !customer.id || !datasetName) return
      const newComputedColumns = computedColumns.map((column, i) =>
        column.name === columnName ? updatedColumn : column
      )
      saveComputedColumns(newComputedColumns).then(() => setStatus("idle"))
    }, 300),
    [customer, id, datasetName, refetch, updateTableColumnMeta, computedColumns]
  )

  const handleFormChange = useCallback(
    value => {
      setStatus("saving")
      return saveComputedColumn(selectedColumn, value)
    },
    [saveComputedColumn, selectedColumn]
  )

  const handleAdd = useCallback(() => {
    setStatus("saving")
    const name = uuidv4()
    saveComputedColumns([
      ...computedColumns,
      { ...NEW_COMPUTED_COLUMN, name },
    ]).then(() => {
      setSelectedColumn(name)
      setStatus("idle")
    })
  }, [saveComputedColumns, computedColumns])

  const selectColumn = useCallback(
    columnName => () => {
      setSelectedColumn(columnName)
    },
    []
  )

  const removeColumn = useCallback(
    columnName => () => {
      setStatus("saving")
      const newComputedColumns = computedColumns.filter(({ name }) => {
        return name !== columnName
      })
      saveComputedColumns(newComputedColumns).then(() => {
        if (selectedColumn === columnName) {
          setSelectedColumn(null)
        }
        setStatus("idle")
      })
    },
    [saveComputedColumns, selectedColumn, setSelectedColumn, computedColumns]
  )

  const selectedColumnSchema = useMemo(() => getComputedColumnSchema(id), [id])
  return (
    <StyledRow>
      <StyledCol span={8}>
        <Row gutter={[0, 10]}>
          <Col span={24}>
            <Row>
              <Col flex={1}>
                <Button onClick={handleAdd}>Add Computed Column</Button>
              </Col>
              <Col>
                {status === "saving" ? (
                  "Saving..."
                ) : (
                  <>
                    <FontAwesomeIcon icon={faCheck} /> Saved
                  </>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            {computedColumns.map(column => (
              <ListItem
                key={column.name}
                onClick={selectColumn(column.name)}
                selected={selectedColumn === column.name}
              >
                <Row justify="space-between" align="middle">
                  <Col>{column.label}</Col>
                  <Col>
                    <Button
                      ghost
                      danger
                      type="text"
                      onClick={removeColumn(column.name)}
                      icon={<FontAwesomeIcon icon={faTrash} />}
                    />
                  </Col>
                </Row>
              </ListItem>
            ))}
          </Col>
        </Row>
      </StyledCol>
      <StyledCol span={16}>
        {selectedColumn !== null ? (
          <SchemaForm
            hideSubmit
            key={selectedColumn}
            schema={selectedColumnSchema}
            cname={customer.normalizedName}
            onFormChange={handleFormChange}
            initialState={computedColumns.find(c => c.name === selectedColumn)}
          />
        ) : null}
      </StyledCol>
    </StyledRow>
  )
}

export default ComputedColumns
