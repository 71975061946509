import { useMemo } from "react"
import { useQuery } from "@apollo/client"

import { GET_ME, checkIfDbUser } from "@dbai/ui-staples"

/*
 * Wrap content with this component if you don't want it to show up outside of
 * Delta-Bravo.
 */
export const useCheckForDBUser = () => {
  const { data } = useQuery(GET_ME)
  return useMemo(() => checkIfDbUser(data?.me?.customers), [data])
}
