import { useMemo, useState, useEffect } from "react"
import { flatten } from "lodash"
import { useSelector, useDispatch } from "react-redux"

import { getAvailableOutputs } from "lib/utils"
import { actions } from "reducers/notebookReducer"
import { useCommSubscription } from "./ipythonComms"
import { selectNodes, selectEdges } from "selectors/workflow"
import { isNodeNamespaced } from "lib/utils"

/*
 * Given either a node or it's id, find all the outputs that feed into it and
 * return those outputs in an array.
 */
export const useConsumableOutputs = nodeData => {
  const nodes = useSelector(selectNodes)
  const edges = useSelector(selectEdges)

  const availableOutputs = useMemo(
    () => getAvailableOutputs(nodeData, { nodes, edges }),
    [nodeData, nodes, edges]
  )
  return availableOutputs
}

export const useOutputManager = () => {
  const dispatch = useDispatch()
  const filter = ({ content }) => {
    return Boolean(content.data && content.data.outputs)
  }

  const handleMessage = ({ content }) => {
    const {
      metadata: { nodeId },
      outputs,
    } = content.data

    dispatch(
      actions.updateNodeOutputs({
        nodeId,
        outputs,
      })
    )
  }

  useCommSubscription({ filter, handleMessage })
}

const mapParamsToInputs = params => {
  const flatParams = flatten(params).filter(Boolean)
  return flatParams.map(param => ({
    input_is_namespaced: param.isNamespaced,
    input_namespace: param.target,
    input_var_name: param.argument,
    output_key: param.artifactId,
  }))
}

const hydrateInputs = (edges, nodes) => {
  const params = edges.map(edge => {
    const targetNode = nodes.find(n => n.id === edge.target)
    const targetNodeNamespaced = isNodeNamespaced(targetNode)

    return edge.parameters.map(p => ({
      ...p,
      isNamespaced: targetNodeNamespaced,
      target: edge.target,
    }))
  })
  const inputs = mapParamsToInputs(params)

  return { inputs }
}

export const useHydrateInputs = assignInputsChannel => {
  const edges = useSelector(selectEdges)
  const nodes = useSelector(selectNodes)
  const [hydrated, setHydrated] = useState(false)

  useEffect(() => {
    const { send, commId } = assignInputsChannel
    if (!hydrated && commId && edges) {
      send(hydrateInputs(edges, nodes))
      setHydrated(true)
    }
  }, [assignInputsChannel, edges, hydrated, setHydrated, nodes])

  return () => setHydrated(false)
}
