import { uuidv4 } from "@dbai/tool-box"

import {
  findColumnConfig,
  findIndexForColumn,
  getUsableColumnLabels,
} from "../../lib/datasetColumns"
import { ROW_LABEL_DATA_INDEX } from "./utils"
import {
  getOnCell,
  getSafeTitle,
  getColumnSort,
  getColumnSearch,
} from "../../lib/tableColumns"

const processTableColumn = (columnConfig, tableColumnOpts) => {
  const key = uuidv4()
  return {
    key,
    dataIndex: key,
    ...columnConfig,
    ...tableColumnOpts,
    title: getSafeTitle(columnConfig),
  }
}

const processValueColumns = (values, tableColumnOpts, dataset) => {
  if (!values.length) return []
  return values.map(value => {
    const columnConfig = findColumnConfig(value, dataset.columns)
    const tableColumnConfig = processTableColumn(columnConfig, tableColumnOpts)
    return {
      ...tableColumnConfig,
      ...getOnCell(tableColumnConfig),
      ...getColumnSort(tableColumnConfig),
      ...getColumnSearch(tableColumnConfig),
      ...getLeafColumnProps(tableColumnConfig, tableColumnOpts),
    }
  })
}

const getLeafColumnProps = (tableColumnConfig, columnOptions) => {
  return {
    ...columnOptions,
    ...getOnCell(tableColumnConfig),
    ...getColumnSort(tableColumnConfig),
    ...getColumnSearch(tableColumnConfig),
  }
}

const getUniqueColumnValues = (columnConfig, dataset) => {
  const rowIndex = findIndexForColumn(columnConfig, dataset.columns)
  const values = dataset.rows.map(row => row[rowIndex])
  return [...new Set(values)]
}

const getChildColumnLabels = ({
  values,
  dataset,
  columnLabels,
  columnOptions,
  columnRowLevel,
}) => {
  const [nextColumnLabel, ...remainingColumnLabels] = columnLabels || []
  const childColumns = processColumnLabel({
    values,
    dataset,
    columnOptions,
    columnLabel: nextColumnLabel,
    columnLabels: remainingColumnLabels,
    columnRowLevel: columnRowLevel + 1,
  })
  return childColumns.length ? { children: childColumns } : {}
}

const getAdditionalColumnOptions = ({
  values,
  dataset,
  columnLabels,
  columnOptions,
  columnRowLevel,
  tableColumnConfig,
}) => {
  const [nextColumnLabel] = columnLabels || []
  return Boolean(nextColumnLabel)
    ? getChildColumnLabels({
        values,
        dataset,
        columnLabels,
        columnOptions,
        columnRowLevel,
      })
    : {
        children: processValueColumns(values, columnOptions, dataset),
        ...getLeafColumnProps(tableColumnConfig, columnOptions),
      }
}

// recursive function that processes a column label and returns all unique labels for that
// column as separate table columns
const processColumnLabel = ({
  values,
  dataset,
  columnLabel,
  columnLabels,
  columnOptions,
  columnRowLevel,
}) => {
  const columnConfig = findColumnConfig(columnLabel, dataset.columns)
  if (!columnConfig) return []

  const labels = getUniqueColumnValues(columnConfig, dataset)
  return getUsableColumnLabels(labels, columnConfig).reduce(
    (acc, { label, value }, columnIndex) => {
      const fullColumnConfig = {
        label: label || value,
        column: columnConfig.column,
      }
      const tableColumnConfig = processTableColumn(
        fullColumnConfig,
        columnOptions
      )
      const additionalColumnOpts = getAdditionalColumnOptions({
        values,
        dataset,
        columnLabel,
        columnLabels,
        columnOptions,
        columnRowLevel,
        tableColumnConfig,
      })
      return [
        ...acc,
        {
          ...tableColumnConfig,
          ...additionalColumnOpts,
        },
      ]
    },
    []
  )
}

const constructColumns = (dataset, widget, editable) => {
  if (!dataset) return []

  const {
    values = [],
    columnOptions,
    rowLabels = [],
    columnLabels = [],
  } = widget || {}

  let columns = []

  // when row labels are present, then create a column for the row labels
  if (rowLabels.length) {
    const rowLabelColumn = {
      title: "",
      key: ROW_LABEL_DATA_INDEX,
      dataIndex: ROW_LABEL_DATA_INDEX,
      ...getLeafColumnProps(
        {
          dataIndex: ROW_LABEL_DATA_INDEX,
        },
        columnOptions
      ),
    }
    columns = [...columns, rowLabelColumn]
  }

  let labelColumns = []
  if (columnLabels.length) {
    const [columnLabel, ...remainingColumnLabels] = columnLabels
    labelColumns = processColumnLabel({
      dataset,
      values,
      columnLabel,
      columnOptions,
      columnRowLevel: 0,
      columnLabels: remainingColumnLabels,
    })
  } else {
    labelColumns = values.map(value => {
      const datasetColumnConfig = findColumnConfig(value, dataset.columns)
      const tableColumnConfig = processTableColumn(
        datasetColumnConfig,
        columnOptions
      )
      const additionalColumnOpts = getLeafColumnProps(
        tableColumnConfig,
        columnOptions
      )
      return {
        ...tableColumnConfig,
        ...additionalColumnOpts,
      }
    })
  }

  //add value columns to be the leaf child for all label columns
  columns = [...columns, ...labelColumns]

  // TODO: finish this
  // if the column label type is computed, then it is a standalone column.

  // additional columns include:
  // - average column
  // - total column
  // - Count column

  return columns
  // return column.map((column, index) => {
  //   return {
  //     ...column,
  //     ...getColumnSort(column),
  //     ...getColumnSearch(column),
  //     ...getOnCell(column),
  //     title: getSafeTitle(column),

  //     // passes props to cells (relevant when cells are editable)
  //     onHeaderCell: getHeaderCellProps(index),
  //   }
  // })
}

export default constructColumns
