import { scalarFieldOptions } from "../../lib/fieldOptionsSchemas"

export default {
  type: "object",
  default: {},
  metadata: {
    layout: { gutter: [16] },
    sections: scalarFieldOptions.metadata.sections,
  },
  properties: {
    ...scalarFieldOptions.properties,
    nullable: {
      type: "boolean",
      title: "Clearable",
      description: "Allow field to be removed from form state",
    },
    title: {
      default: "Color Picker Form Field",
      type: "string",
      title: "Label",
      metadata: {
        fieldProps: {
          placeholder: "Enter a label",
        },
      },
    },
    defaultValue: {
      type: "string",
      title: "Default Value",
      metadata: {
        component: "ColorPicker",
      },
    },
  },
}
