import React from "react"
import { Col } from "antd"
import { useParams } from "react-router"
import { useQuery } from "@apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons"

import {
  DBTable,
  Card,
  Content,
  withUser,
  PageSpinner,
  AuthBoundary,
  DateFormatter,
  BytesFormatter,
} from "@dbai/ui-staples"
import { stringSorter, dateSorter } from "@dbai/tool-box"

import { GET_USER_UPLOADS } from "queries"
import CustomerUpload from "./CustomerUpload"

const CustomerUploads = props => {
  const { user } = props
  const { cname } = useParams()
  const { data, loading, error, refetch } = useQuery(GET_USER_UPLOADS, {
    variables: { name: cname, userId: user.id },
  })

  if (loading) return <PageSpinner />
  if (error) return <div>An Error Occurred</div>

  const columns = [
    {
      title: "Created",
      key: "createdAt",
      dataIndex: "createdAt",
      defaultSortOrder: "descend",
      sorter: dateSorter("createdAt"),
      render: value => {
        return <DateFormatter format="MM/DD/YYYY HH:mm:ss" value={value} />
      },
    },
    {
      title: "File Name",
      key: "originalFilename",
      dataIndex: "originalFilename",
      sorter: stringSorter("originalFilename"),
    },
    {
      title: "Size",
      key: "size",
      dataIndex: "size",
      render: value => {
        return <BytesFormatter value={value} />
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      sorter: stringSorter("status"),
    },
  ]

  return (
    <AuthBoundary subject="datalake" action="LIST">
      <Content>
        <Col span={8}>
          <Card
            title="UPLOAD FILES"
            icon={<FontAwesomeIcon icon={faCloudUploadAlt} />}
          >
            <CustomerUpload refetch={refetch} customerId={data.customer.id} />
          </Card>
        </Col>
        <Col span={16}>
          <Card title="UPLOAD HISTORY" nopad>
            <DBTable
              rowClassName="clickable-row"
              dataSource={data.customer.uploads}
              onRow={row => {
                return {
                  onClick: event => {
                    window.open(row.urls.downloadUrl, "_blank")
                  },
                }
              }}
              columns={columns}
              emptyText="No Uploads Found"
            />
          </Card>
        </Col>
      </Content>
    </AuthBoundary>
  )
}

export default withUser(CustomerUploads)
