//Helper function to generate a Highcharts CSS object
//Ref: https://api.highcharts.com/class-reference/Highcharts.CSSObject
const generateStyles = ({
  background,
  backgroundColor,
  border,
  borderRadius,
  color = "#666666",
  cursor,
  fontFamily = '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
  fontSize = "12px",
  fontWeight = "12px",
  height,
  lineWidth,
  opacity,
  padding,
  pointerEvents,
  position,
  textAlign,
  textDecoration,
  textOutline,
  textOverflow,
  top,
  transition,
  whiteSpace,
  width,
  ...rest
}) => {
  const font = `${fontWeight} ${fontSize} ${fontFamily}`
  return {
    background,
    backgroundColor,
    border,
    borderRadius,
    color,
    cursor,
    fontFamily,
    fontSize,
    fontWeight,
    font,
    height,
    lineWidth,
    opacity,
    padding,
    pointerEvents,
    position,
    textAlign,
    textDecoration,
    textOutline,
    textOverflow,
    top,
    transition,
    whiteSpace,
    width,
    ...rest,
  }
}

const marker = {
  enabledThreshold: 5,
  lineColor: "#ffffff",
  lineWidth: 0,
  radius: 2,
  states: {
    normal: {
      animation: true,
    },
    hover: {
      animation: {
        duration: 50,
      },
      enabled: true,
      radiusPlus: 2,
      lineWidthPlus: 1,
    },
    select: {
      fillColor: "#cccccc",
      lineColor: "#000000",
      lineWidth: 2,
    },
  },
  symbol: "circle",
}

const plotOptions = {
  line: {
    marker,
    dataLabels: {},
  },
  area: {
    marker,
    dataLabels: {},
    shadow: false,
    fillOpacity: 0.5,
  },
  column: {
    dataLabels: {},
  },
  bar: {
    dataLabels: {},
  },
  boxplot: {
    dataLabels: {},
    fillColor: "#F7F7F7",
    medianWidth: 2,
    stemWidth: 1,
    whiskerWidth: 2,
  },
  heatmap: {
    tooltip: {
      headerFormat: "",
    },
  },
  pie: {
    tooltip: {
      headerFormat: "{series.name}<br/>",
      pointFormat: "{point.name}: <b>{point.y:.4f}</b>",
    },
    innerSize: 0,
  },
}

const defaultTheme = {
  plotOptions,
  colors: [
    "#2d8f28",
    "#FB8C00",
    "#0067a9",
    "#e74c3c",
    "#f1c40f",
    "#9b59b6",
    "#333333",
    "#FF3399",
    "#1A237E",
    "#999999",
  ],
  chart: {
    panning: {
      enabled: true,
      type: "xy",
    },
    panKey: "shift",
    // defaultSeriesType: "line",
    // type: "line",
    animation: true,
    zoomType: "x",
  },
  legend: {
    enabled: true,
  },
  tooltip: {
    outside: true,
  },
  xAxis: {},
  yAxis: {},
  colorAxis: undefined,
  labels: {
    style: {},
  },
  title: { text: "" },
  subtitle: { title: "" },
  caption: { title: "" },
  credits: {
    enabled: false,
  },
  loading: {
    hideDuration: 1000,
    showDuration: 1000,
  },
}

export default defaultTheme
