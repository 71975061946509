import React, { useCallback } from "react"
import { set, get } from "lodash"

import DraggableTableRow from "./DraggableTableRow"

const draggableTableComponents = {
  body: {
    row: DraggableTableRow,
  },
}

const removeRowFromParent = (name, rawDS, ds) => {
  const { id: rowId } = get(rawDS, name, {})
  const parentName = name.split(".children")[0]
  const parentRow = get(rawDS, parentName, {})
  const parentChildren = get(parentRow, "children", [])
  return set(ds, parentName, {
    ...parentRow,
    children: parentChildren.filter(c => c.id !== rowId),
  })
}

const addRowToParent = (name, rawDS, ds, row) => {
  const targetName = `${name}.children`
  const existingFolderContent = get(rawDS, targetName, [])
  return set(ds, targetName, [...existingFolderContent, row])
}

const withDragDropRows = TableComp => props => {
  const { dataSource, onMove, components = {} } = props

  const moveRow = useCallback(
    (dragRowName, dropRowName) => {
      let rowsMoved = false
      const dsCopy = structuredClone(dataSource)
      const dragRow = get(dataSource, dragRowName, {})
      const dropRow = get(dataSource, dropRowName, {})

      // case for when a row is dragged from one folder to another
      if (dragRowName.includes("children")) {
        removeRowFromParent(dragRowName, dataSource, dsCopy)
        rowsMoved = true
      }

      if (dragRow.isFolder) return null

      // case for when a non-folder row is dragged into a folder row
      if (!dragRow.isFolder && dropRow.isFolder) {
        addRowToParent(dropRowName, dataSource, dsCopy, dragRow)
        rowsMoved = true
      }

      // case for when a row is dragged into a non-folder row
      if (!dragRow.isFolder && !dropRow.isFolder) {
        const dropRowIsInFolder = dropRowName.includes("children")
        const dragRowIsInFolder = dragRowName.includes("children")

        switch (true) {
          // case for when a row is dragged from a folder to the root level
          case dragRowIsInFolder && !dropRowIsInFolder:
            removeRowFromParent(dragRowName, dataSource, dsCopy)
            break
          // case for when a row is dragged onto a nested folder's row
          case dropRowIsInFolder:
            const dropRowParentName = dropRowName.split(".children")[0]
            addRowToParent(dropRowParentName, dataSource, dsCopy, dragRow)
            break
          default:
            return null
        }
      }

      return rowsMoved ? onMove && onMove(dsCopy) : null
    },
    [onMove, dataSource]
  )

  const handleRow = useCallback(
    (row, index) => {
      const attr = {
        index,
        moveRow,
        rowName: row.rowName,
        isFolder: row.isFolder,
      }
      return attr
    },
    [moveRow]
  )

  return (
    <TableComp
      onRow={handleRow}
      components={{ ...components, ...draggableTableComponents }}
      {...props}
    />
  )
}

export default withDragDropRows
