import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import processConditions from "./processConditions"
import { selectAppVariables } from "../../selectors/app"
import { updateNodeStyles, updateNodeText, updateVariableValues } from "./utils"
import { getStylesFromEffects } from "../../lib/conditionalFormat"

export const useAppVariableValues = (editor, mounted) => {
  const appVariables = useSelector(selectAppVariables)
  // update app variable tags when variables change
  useEffect(() => {
    if (editor.current && mounted) {
      const doc = editor.current
      updateVariableValues(doc, appVariables)
    }
  }, [appVariables, mounted, editor])
}

export const useConditionalFormatting = (editor, widget, mounted) => {
  const dispatch = useDispatch()
  const appVariables = useSelector(selectAppVariables)

  // update nodes based on conditions
  useEffect(() => {
    const nodes = widget.options.nodes || []

    if (editor.current && mounted) {
      const doc = editor.current
      nodes.forEach(async node => {
        const { conditionalFormat = [] } = node
        const conditionEffects = await conditionalFormat.reduce(
          async (acc, { conditions, effects }) => {
            const validConditions = await processConditions(
              dispatch,
              conditions,
              appVariables
            )
            if (validConditions) {
              const styles = getStylesFromEffects(effects)
              return { ...acc, ...styles }
            }
            return acc
          },
          {}
        )
        updateNodeStyles(doc, node.nodeId, conditionEffects)
      })
    }
  }, [widget.options.nodes, appVariables, editor, mounted, dispatch])
}

export const useConditionalText = (editor, widget, mounted) => {
  const dispatch = useDispatch()
  const appVariables = useSelector(selectAppVariables)

  // update nodes based on conditions
  useEffect(() => {
    const nodes = widget.options.nodes || []

    if (editor.current && mounted) {
      const doc = editor.current
      nodes
        .filter(node => node.nodeType === "conditionalText")
        .forEach(async node => {
          const { rules = [], text: defaultText } = node
          const conditionalText = await rules.reduce(
            async (acc, { conditions, text }) => {
              const validConditions = await processConditions(
                dispatch,
                conditions,
                appVariables
              )
              return validConditions ? text : acc
            },
            defaultText
          )
          updateNodeText(doc, node.nodeId, conditionalText)
        })
    }
  }, [widget.options.nodes, appVariables, editor, mounted, dispatch])
}
