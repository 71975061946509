import { scalarFieldOptions } from "../../lib/fieldOptionsSchemas"

export default {
  type: "object",
  default: {},
  metadata: {
    layout: { gutter: [16] },
    sectionPtops: {
      defaultActiveKey: ["listProperties"],
    },
    sections: {
      listProperties: {
        title: "List Items",
        properties: ["/items"],
      },
      listOptions: {
        title: "List Options",
        properties: [
          "minItems",
          "maxItems",
          "uniqueItems",
          "metadata/hideAdd",
          "metadata/itemType",
          "addButtonProps",
          "errorMessage",
        ],
      },
      ...scalarFieldOptions.metadata.sections,
    },
  },
  properties: {
    key: scalarFieldOptions.properties.key,
    title: {
      ...scalarFieldOptions.properties.title,
      default: "List Container",
    },
    "/items": { $ref: "#/$defs/formFieldSelect" },
    "metadata/layout/span":
      scalarFieldOptions.properties["metadata/layout/span"],
    "metadata/hidden": scalarFieldOptions.properties["metadata/hidden"],
    "metadata/hideLabel": scalarFieldOptions.properties["metadata/hideLabel"],
    "metadata/hideAdd": {
      title: "Hide Add Button",
      type: "boolean",
    },
    uniqueItems: {
      title: "Unique Items",
      type: "boolean",
    },
    "metadata/itemType": {
      title: "Item Style",
      type: "string",
      enum: ["default", "collapse"],
      default: "default",
      metadata: {
        labels: ["None", "Collapse"],
      },
    },
    minItems: {
      title: "Min Items",
      type: "number",
      minimum: 0,
      default: 0,
    },
    maxItems: {
      title: "Max Items",
      type: "number",
      minimum: 0,
    },
    errorMessage: {
      type: "object",
      default: {},
      title: "Error Messages",
      metadata: {
        hideLabel: false,
      },
      properties: {
        "errorMessage/minItems": {
          title: "Min Items Error Message",
          type: ["string", "null"],
          nullable: true,
        },
        "errorMessage/maxItems": {
          title: "Max Items Error Message",
          type: ["string", "null"],
          nullable: true,
        },
      },
    },
    addButtonProps: {
      type: "object",
      default: {},
      title: "Add Button Options",
      metadata: {
        hideLabel: false,
      },
      properties: {
        "metadata/addButtonProps/addButtonText": {
          type: "string",
          default: "ADD",
          title: "Add Button Text",
        },
        "metadata/addButtonProps/block": {
          type: "boolean",
          title: "Block Style",
        },
        "metadata/addButtonProps/type": {
          type: "string",
          title: "Button Type",
          default: "default",
          enum: ["primary", "ghost", "dashed", "link", "text", "default"],
          metadata: {
            labels: ["Primary", "Ghost", "Dashed", "Link", "Text", "Default"],
          },
        },
      },
    },
  },
}
