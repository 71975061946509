import React, { useMemo } from "react"
import { Col, Row } from "antd"

import { useModelFormInput } from "./hooks"

const Tensor = props => {
  const { input } = props
  const spec = input["tensor-spec"]
  const Comp = useModelFormInput(spec?.dtype)
  const tensorShape = useMemo(() => {
    const startIdx = Math.floor(0, spec.shape[0])
    const endIdx = spec.shape[1]
    return [...Array(endIdx - startIdx).keys()].map(n => n.toString())
  }, [spec])

  return (
    <>
      {tensorShape.map(idx => {
        return (
          <Col key={idx} xl={12} lg={12} md={24}>
            <Comp displayName={`anon-${idx}`} name={idx} />
          </Col>
        )
      })}
    </>
  )
}

const TensorForm = props => {
  const { inputs } = props

  return (
    <Row gutter={16}>
      {inputs.map((input, idx) => {
        return <Tensor key={idx} input={input} />
      })}
    </Row>
  )
}

export default TensorForm
