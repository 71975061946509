import { useMemo } from "react"
import { useSelector } from "react-redux"

import { selectSelectedWidgets } from "../selectors/app"

const useYjsSelections = widgetId => {
  const selections = useSelector(selectSelectedWidgets)

  const widgetSelections = useMemo(() => {
    if (!selections) return []
    return Object.entries(selections).reduce(
      (acc, [, { color, name, widgetId: selectedWidgetId }]) => {
        if (widgetId !== selectedWidgetId) return acc
        return [...acc, { color, name }]
      },
      []
    )
  }, [widgetId, selections])

  return widgetSelections
}

export default useYjsSelections
