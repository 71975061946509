import store from "reducers"

const notebookTemplate = {
  cells: [],
  metadata: {
    kernelspec: {
      display_name: "Python 3",
      language: "python",
      name: "python3",
    },
    language_info: {
      codemirror_mode: {
        name: "ipython",
        version: 3,
      },
      file_extension: ".py",
      mimetype: "text/x-python",
      name: "python",
      nbconvert_exporter: "python",
      pygments_lexer: "ipython3",
      version: "3.7.4",
    },
  },
  nbformat: 4,
  nbformat_minor: 2,
}

const cellTemplate = {
  cell_type: "code",
  execution_count: 1,
  metadata: {},
  source: ["#some multi-line code"],
  outputs: [],
}

export const exportNotebook = () => {
  const workflow = store.getState().notebook.workflow
  const notebook = { ...notebookTemplate }
  const cells = []
  const {
    spec: { nodes },
  } = workflow
  nodes.forEach(node => {
    if (node.type === "script") {
      cells.push({ ...cellTemplate, source: [`# ${node.data.label}`] })
      node.cells.forEach(jupyterCell => {
        const { outputs } = jupyterCell
        let cell = {
          cell_type: jupyterCell.cellType,
          metadata: jupyterCell.metadata,
          source: jupyterCell.source,
        }

        if (jupyterCell.cellType === "code") {
          cell = {
            ...cell,
            execution_count: jupyterCell.executionCount,
            outputs:
              outputs?.map(o => {
                const { transient, ...output } = o
                return output
              }) || [],
          }
        }

        if (cell.source) {
          cells.push(cell)
        }
      })
    }
  })

  notebook.cells = cells
  const notebookData = new Blob([JSON.stringify(notebook, null, 1)], {
    type: "application/json",
  })

  const url = window.URL.createObjectURL(notebookData)

  const a = document.createElement("a")
  a.download = `${workflow.name}.ipynb`
  a.rel = "noopener" // tabnabbing
  a.href = url
  a.dispatchEvent(new MouseEvent("click"))
}
