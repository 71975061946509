import { useEffect, useCallback, useRef, useState } from "react"
import { curry } from "lodash"

export const beginDrag = curry((field, selectedFields, { selectedSource }) => {
  //overrides item on return
  const includes = selectedFields.find(f => f.name === field.name)
  const dragFields = includes ? selectedFields : [...selectedFields, field]

  return { fields: dragFields, source: selectedSource }
})

export const endDrag = curry((handleEndDrag, item, monitor) => {
  if (monitor.didDrop()) handleEndDrag()
  return null
})

const addDraggableItem = (
  list,
  selectedItems,
  lastSelectedIndex,
  index,
  evt
) => {
  const ctrlKey = evt.ctrlKey
  const shiftKey = evt.shiftKey

  const field = index < 0 ? {} : list[index]
  const foundIndex = selectedItems.findIndex(f => f.index === index)

  let selectedItemsCopy = [...selectedItems]
  let sameFieldSelected = -1

  switch (true) {
    case !ctrlKey && !shiftKey:
      sameFieldSelected = field.id.localeCompare(selectedItems[0]?.id)
      selectedItemsCopy = [field]
      break
    case foundIndex >= 0:
      // If found remove it to unselect it.
      selectedItemsCopy.splice(foundIndex)
      return selectedItemsCopy
    case shiftKey:
      const { current } = lastSelectedIndex
      const additionalFields =
        current >= index
          ? list.slice(index, current)
          : list.slice(current + 1, index + 1)
      selectedItemsCopy.push(...additionalFields)
      break
    case ctrlKey:
      selectedItemsCopy.push(field)
      break
    default:
  }

  return sameFieldSelected === -1 ? selectedItemsCopy : []
}
const addClickableItem = (
  list,
  selectedItems,
  lastSelectedIndex,
  index,
  evt
) => {
  const ctrlKey = evt.ctrlKey
  const shiftKey = evt.shiftKey
  let newSelectedFields
  const foundIndex = selectedItems.findIndex(f => f.index === index)
  const field = index < 0 ? {} : list[index]

  // if found remove it to unselect it
  if (foundIndex >= 0) {
    const newSelectedItems = [
      ...selectedItems.slice(0, foundIndex),
      ...selectedItems.slice(foundIndex + 1),
    ]

    return newSelectedItems
  }

  if (!ctrlKey && !shiftKey) {
    newSelectedFields = [...selectedItems, field]
  } else if (shiftKey) {
    //shift key handler
    if (lastSelectedIndex >= index) {
      newSelectedFields = [].concat.apply(
        selectedItems,
        list.slice(index, lastSelectedIndex)
      )
    } else {
      newSelectedFields = [].concat.apply(
        selectedItems,
        list.slice(lastSelectedIndex + 1, index + 1)
      )
    }
  } else if (ctrlKey) {
    //cmd key handler
    newSelectedFields = [...selectedItems, field]
  }

  return newSelectedFields
}

export const useSelectedListItems = (initialValue, list, clickOnly) => {
  const lastSelectedIndex = useRef(-1)
  const [selectedItems, setSelectedItems] = useState(initialValue)
  const adder = clickOnly ? addClickableItem : addDraggableItem

  useEffect(() => {
    setSelectedItems(initialValue)
  }, [initialValue])

  const addItem = useCallback(
    (index, evt) => {
      const items = adder(list, selectedItems, lastSelectedIndex, index, evt)
      setSelectedItems(items)
      lastSelectedIndex.current = index
      return items
    },
    [adder, lastSelectedIndex, selectedItems, list]
  )

  const clearItemSelection = () => {
    setSelectedItems([])
    lastSelectedIndex.current = -1
  }

  return [selectedItems, clearItemSelection, addItem]
}
