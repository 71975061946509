import navItemsSchema from "./shared/navItemsSchema"
const tabsSchema = {
  title: "Tabs",
  type: "object",
  required: ["navItems"],
  metadata: {
    fieldProps: {
      style: {
        padding: "0 16px",
      },
    },
  },
  properties: {
    navItems: {
      title: "Tabs",
      default: [],
      ...navItemsSchema,
    },
    defaultActiveKey: {
      title: "Default Tab",
      type: ["string", "null"],
      nullable: true,
      metadata: {
        component: "LocalSelect",
        listPath: "options.navItems",
        valuePath: "pageId",
        labelPath: "title",
      },
    },
    tabPosition: {
      title: "Tab Position",
      type: "string",
      enum: ["top", "bottom", "right", "left", null],
      metadata: {
        labels: ["Top", "Bottom", "Right", "Left", "None"],
      },
    },
    tabType: {
      title: "Type",
      type: "string",
      enum: ["card", "line"],
      default: "card",
      metadata: {
        labels: ["Card", "Line"],
      },
    },
    tabBarGutter: {
      title: "Gutter Width",
      type: "number",
    },
    centered: {
      title: "Centered",
      type: "boolean",
    },
  },
}

export default tabsSchema
