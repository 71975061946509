import React, { useCallback } from "react"

import ColumnList from "./ColumnList"
import FormFieldWrapper from "../FormFieldWrapper"

const ColumnListInputCore = props => {
  const { value, onChange, schema } = props
  const { metadata } = schema
  const { allowedTypes, style } = metadata || {}

  const handleChange = useCallback(
    items => {
      const selectedColumns = items.map(item => item.content ?? item)
      const uniqueColumns = Array.from(
        new Set(selectedColumns.map(c => c.column))
      ).map(column => {
        return selectedColumns.find(c => c.column === column)
      })
      onChange(uniqueColumns)
    },
    [onChange]
  )

  const initialValue =
    value?.map(value => ({ id: value.column, ...value })) || []

  return (
    <div style={style}>
      <ColumnList
        clickOnly
        onChange={handleChange}
        allowedTypes={allowedTypes}
        initialValue={initialValue}
      />
    </div>
  )
}

const ColumnListInput = props => {
  return (
    <FormFieldWrapper noStyle {...props}>
      <ColumnListInputCore />
    </FormFieldWrapper>
  )
}

ColumnListInput.Core = ColumnListInputCore
export default ColumnListInput
