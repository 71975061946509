import React from "react"

import { AuthBoundary } from "@dbai/ui-staples"

import SaveComponentButton from "../SaveComponentButton"
import ComponentSettingsForm from "../ComponentSettingsForm"

const NewComponent = props => {
  const { afterSubmit } = props

  return (
    <AuthBoundary subject="actions" action="CREATE">
      <ComponentSettingsForm />
      <SaveComponentButton afterSubmit={afterSubmit} saveText="CREATE" />
    </AuthBoundary>
  )
}

export default NewComponent
