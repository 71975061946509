import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { faCog } from "@fortawesome/pro-solid-svg-icons"

import HeaderButton from "./HeaderButton"
import { actions } from "../reducers/appReducer"
import { selectShowAppSettings } from "../selectors/app"

const ShowAppSettingsButton = () => {
  const dispatch = useDispatch()
  const showAppSettings = useSelector(selectShowAppSettings)

  const toggleShowAppSettings = () => {
    dispatch(actions.toggleShowAppSettings())
  }

  return (
    <HeaderButton
      icon={faCog}
      title="Settings"
      selected={showAppSettings}
      onClick={toggleShowAppSettings}
      data-testid="app-header-settings-button"
    />
  )
}

export default ShowAppSettingsButton
