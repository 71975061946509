import React, { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { Button } from "antd"

import { capitalize } from "lib/strings"
import { actions } from "reducers/scheduleReducer"
import CronControl, { Flex, FlexLabel } from "./CronControl"

const CronControls = props => {
  const {
    cronField,
    selector,
    name = capitalize(cronField),
    addText = `Add ${name} to Run at`,
    ...rest
  } = props

  const dispatch = useDispatch()
  const entries = useSelector(selector) || []

  const capped = capitalize(cronField)
  const handleClickAdd = useCallback(() => {
    const action = actions[`add${capped}`]
    dispatch(action())
  }, [dispatch, capped])

  const isAddDisabled = entries[0]?.value === "*"

  return (
    <>
      {entries.map((entry, idx) => (
        <CronControl
          key={idx}
          idx={idx}
          name={name}
          cronField={cronField}
          entry={entry}
          value={entry.value}
          stepValue={entry.step}
          entriesLength={entries.length}
          {...rest}
        />
      ))}
      <Flex className="form-group">
        <FlexLabel className="form-label"></FlexLabel>
        <Button
          type="primary"
          onClick={handleClickAdd}
          disabled={isAddDisabled}
        >
          <FontAwesomeIcon icon={faPlus} className="action-icon" />
          &nbsp; {addText}
        </Button>
      </Flex>
    </>
  )
}

export * from "./CronControl"
export default CronControls
