import React from "react"

import { useSavedFormValue } from "../hooks"
import JSONSchemaField from "../JSONSchemaField"
import FormFieldWrapper from "../FormFieldWrapper"
import useFilterWidgetSchema from "../../hooks/useFilterWidgetSchema"

const FilterWidgetInputCore = props => {
  const { schema, schemaKey } = props
  const normalizedFormData = useSavedFormValue("options")

  const inputFieldSchema = useFilterWidgetSchema({
    ...normalizedFormData,
    label: schema.title,
    key: schemaKey,
  })

  return (
    <JSONSchemaField
      {...props}
      schema={inputFieldSchema.properties[schemaKey]}
    />
  )
}

const FilterWidgetInput = props => {
  return (
    <FormFieldWrapper noStyle {...props}>
      <FilterWidgetInputCore />
    </FormFieldWrapper>
  )
}

FilterWidgetInput.Core = FilterWidgetInputCore
export default FilterWidgetInput
