import getTableColumnSchema from "./getTableColumnSchema"
import datasetColumnSchema from "../datasetColumnSchema"
import getConditionalFormatSchema from "../shared/getConditionalFormatSchema"

const tableWidgetSchema = requireAggregate => ({
  default: {},
  type: "object",
  title: "Table Widget",
  required: ["datasetId"],
  metadata: {
    sideEffects: [
      {
        resetValue: [],
        type: "resetField",
        targetName: "options.where",
        sourceName: "options.datasetId",
      },
      {
        resetValue: [],
        type: "resetField",
        targetName: "options.columns",
        sourceName: "options.datasetId",
      },
      {
        resetValue: [],
        type: "resetField",
        targetName: "options.groupBy",
        sourceName: "options.datasetId",
      },
      {
        resetValue: [],
        type: "resetField",
        targetName: "options.orderBy",
        sourceName: "options.datasetId",
      },
      {
        resetValue: {},
        type: "resetField",
        sourceName: "options.datasetId",
        targetName: "options.groupByTime",
      },
    ],
  },
  properties: {
    datasetId: {
      $ref: "#/$defs/datasetId",
      metadata: {
        validateFirst: true,
        component: "Dataset",
        fieldProps: {
          placeholder: "Select dataset",
          popupMatchSelectWidth: false,
        },
        wrapperStyle: {
          padding: "0 16px",
        },
        errorMessage: {
          required: "Dataset is required",
        },
      },
    },
  },
  dependencies: {
    datasetId: {
      metadata: {
        order: ["columns", "datasetId", "dataset", "groupBy"],
        noStyle: true,
        sectionType: "tabs",
        sections: {
          columns: {
            title: "Data",
            properties: ["inputZones"],
            containerStyle: {
              padding: 0,
              overflowY: "auto",
            },
          },
          table: {
            title: "Table Options",
            properties: ["table"],
            containerStyle: {
              padding: 0,
              overflowY: "auto",
              height: "calc(100vh - 185px)",
            },
          },
          dataOptions: {
            title: "Dataset Options",
            properties: ["datasetId", "dataset", "where"],
            containerStyle: {
              padding: "16px",
              overflowY: "auto",
              height: "calc(100vh - 185px)",
            },
          },
        },
      },
      properties: {
        datasetId: {
          metadata: {
            wrapperStyle: {
              padding: "0",
            },
          },
        },
        inputZones: {
          type: "object",
          title: "Data",
          default: {},
          metadata: {
            schemaName: "dependencies.datasetId.properties.inputZones",
            component: "ColumnInputZones",
            fieldProps: {
              style: {
                height: "calc(100vh - 185px)",
              },
            },
          },
          properties: {
            columns: {
              title: "Columns",
              type: "array",
              minItems: 1,
              maxItems: 100,
              default: [],
              items: getTableColumnSchema(requireAggregate),
              metadata: {
                validateFirst: true,
                component: "DnDDropzone",
                addText: "Add Computed Column",
                defaultAddedFieldData: {
                  columnType: "computed",
                },
                defaultDroppedFieldValue: {
                  columnType: "datasetColumn",
                },
                errorMessage: {
                  minItems: "At least one column is required",
                  maxItems: "Maximum number of columns is 100",
                },
                fieldProps: {
                  style: {
                    minHeight: "100px",
                  },
                },
              },
            },
            groupBy: {
              title: "Group By",
              type: "array",
              default: [],
              metadata: {
                noStyle: true,
                validateFirst: true,
                component: "DnDDropzone",
                allowedTypes: ["int", "string", "numerical", "categorical"],
                fieldProps: {
                  style: {
                    minHeight: "100px",
                  },
                },
              },
              items: {
                type: "object",
                required: ["column"],
                dependencies: datasetColumnSchema.dependencies,
                properties: {
                  column: {
                    $ref: "#/$defs/dataColumn",
                    title: "Column",
                    metadata: {
                      component: "ColumnSelect",
                      hideLabel: true,
                      allowedTypes: [
                        "int",
                        "float",
                        "string",
                        "numerical",
                        "categorical",
                      ],
                      fieldProps: {
                        popupMatchSelectWidth: false,
                      },
                    },
                  },
                  type: {
                    $ref: "#/$defs/dataColumnType",
                    metadata: { hidden: true },
                  },
                  columnType: {
                    default: "datasetColumn",
                    metadata: { hidden: true },
                  },
                  dataIndex: {
                    $ref: "#/$defs/dataIndex",
                    metadata: {
                      hidden: true,
                      defaultValueSource: "uid",
                    },
                  },
                  // title gets used as the table column's title instead of the column name
                  title: {
                    $ref: "#/$defs/title",
                    metadata: {
                      placeholderSource: "column",
                      validateFirst: true,
                      fieldProps: {
                        placeholder: "<Current Column Name>",
                      },
                    },
                  },
                  // textAlign determines how the values are aligned in the cells for this column
                  textAlign: { $ref: "#/$defs/textAlign" },
                  // searchable determines whether or not to show the search icon for this column
                  searchable: {
                    title: "Show Search",
                    type: "boolean",
                  },
                  // adds conditional formatting rules
                  conditionalFormat:
                    getConditionalFormatSchema(requireAggregate),
                  // fixed determines whether or not the column show be fixed to the left or right side of the table
                  fixed: { $ref: "#/$defs/fixed" },
                },
              },
            },
          },
        },
        table: {
          default: {},
          type: "object",
          title: "Table Options",
          metadata: {
            layout: { gutter: [16] },
            sectionProps: {
              defaultActiveKey: ["general"],
            },
            sections: {
              general: {
                title: "General Options",
                properties: ["general"],
              },
              pagination: {
                title: "Pagination",
                properties: ["pagination"],
              },
              scroll: {
                title: "Scrolling",
                properties: ["scroll"],
              },
            },
          },
          properties: {
            general: {
              type: "object",
              default: {},
              title: "General Options",
              metadata: {
                layout: { gutter: [16] },
              },
              properties: {
                "table/size": {
                  type: "string",
                  title: "Table Size",
                  default: "small",
                  enum: ["default", "middle", "small"],
                  metadata: {
                    labels: ["Large", "Medium", "Small"],
                    component: "Segmented",
                    fieldProps: {
                      placeholder: "(auto)",
                    },
                  },
                },
                "table/bordered": {
                  default: false,
                  type: "boolean",
                  title: "Bordered",
                  description: "Whether to show borders around all table cells",
                },
                "table/showHeader": {
                  default: true,
                  type: "boolean",
                  title: "Show Header",
                  description:
                    "Whether to show the table header, which includes column names and sorting controls",
                },
                "table/showTotals": {
                  default: false,
                  type: "boolean",
                  title: "Show Totals",
                  description: "Whether to show totals for numerical columns",
                },
              },
            },
            scroll: {
              type: "object",
              default: {},
              title: "Scroll Options",
              metadata: {
                layout: { gutter: [16] },
              },
              properties: {
                "table/scroll/scrollToFirstRowOnChange": {
                  title: "Scroll to First Row on Change",
                  type: "boolean",
                  description:
                    "Whether to scroll to the top of the table when paging, sorting, filtering changes",
                },
                "table/scroll/x": {
                  title: "Horizontal Scrolling Enabled",
                  type: "boolean",
                  default: true,
                  description:
                    "If horizontal scrolling is disabled, the table will try to fit all data into the width of the table",
                },
                "table/scroll/y": {
                  title: "Vertical Scrolling",
                  type: ["number", "null"],
                  nullable: true,
                  default: 300,
                  description:
                    "Set vertical scrolling, can also be used to specify the height of the scroll area",
                },
              },
            },
            pagination: {
              type: "object",
              default: {},
              title: "Pagination",
              metadata: {
                layout: { gutter: [16] },
              },
              properties: {
                "table/pagination/verticalPosition": {
                  type: "string",
                  title: "Position",
                  default: "top",
                  enum: ["top", "bottom", "both"],
                  metadata: {
                    labels: ["Top", "Bottom", "Top and Bottom"],
                  },
                },
                "table/pagination/alignment": {
                  type: "string",
                  title: "Alignment",
                  default: "left",
                  enum: ["left", "right"],
                  metadata: {
                    labels: ["Left", "Right"],
                  },
                },
                "table/pagination/hideOnSinglePage": {
                  default: false,
                  type: "boolean",
                  title: "Hide if there is only one page",
                },
                "table/pagination/defaultPageSize": {
                  default: 10,
                  type: "number",
                  title: "Default Rows Per Page",
                  description: "Determines how many rows show up on each page",
                },
                "table/pagination/pageSizeOptions": {
                  type: "array",
                  default: [10, 20, 50, 100],
                  title: "Page Size Options",
                  items: {
                    type: "number",
                  },
                },
                "table/pagination/showSizeChanger": {
                  type: "boolean",
                  title: "Show Page Size Selector",
                  description:
                    "Determine whether to show page size selection dropdown",
                },
              },
            },
          },
        },
        dataset: {
          default: {},
          type: "object",
          title: "Dataset Options",
          metadata: {
            layout: { gutter: [16] },
            sectionProps: {
              defaultActiveKey: ["advanced"],
            },
            sections: {
              advanced: {
                title: "Advanced Options",
                properties: ["distinctOn"],
              },
            },
          },
          properties: {
            pollInterval: { $ref: "#/$defs/pollInterval" },
            format: {
              title: "Format",
              default: "table",
              type: "string",
              enum: ["chart", "table"],
              metadata: {
                hidden: true,
              },
            },
            limit: {
              title: "Row Limit",
              type: "number",
              description:
                "Determines the number of rows displayed. If you are using an aggregate query (sum, average, etc), the query will limit the rows AFTER you perform the aggregate. For example, if you have 1,000 rows and have a 100 'Row Limit', the query will still perform the aggregate against 1,000 rows.  The number of rows returned would be 100 or less. Increasing the 'Row Limit' may result in slower load times.",
              default: 10,
              nullable: true,
              metadata: {
                hidden: true,
              },
            },
            offset: {
              title: "Row Offset",
              description:
                "Determines the number of rows to skip. For example, if your data is ordered by ID, and you have an offset of 100, the first 100 rows will be skipped.",
              default: 0,
              type: "number",
              nullable: true,
              metadata: {
                hidden: true,
              },
            },
            groupByTime: {
              default: null,
              nullable: true,
              title: "Group By Time",
              type: ["object", "null"],
              properties: {
                "groupByTime/column": {
                  type: "string",
                  title: "Column",
                  metadata: {
                    component: "ColumnSelect",
                    allowedTypes: ["datetime"],
                    columnTypeName: "groupByTime/type",
                    fieldProps: {
                      placeholder: "Select Column",
                    },
                  },
                },
                "groupByTime/type": {
                  type: "string",
                  metadata: {
                    hidden: true,
                  },
                },
                "groupByTime/interval": {
                  title: "Interval",
                  type: "number",
                  default: 1,
                  minimum: 1,
                  metadata: {
                    layout: { span: 12 },
                  },
                },
                "groupByTime/unit": {
                  title: "Unit",
                  type: "string",
                  default: "DAY",
                  enum: [
                    "SECOND",
                    "MINUTE",
                    "HOUR",
                    "DAY",
                    "WEEK",
                    "MONTH",
                    "YEAR",
                  ],
                  metadata: {
                    layout: { span: 12 },
                  },
                },
                // TODO: Add support for start date when we have a date picker form field
                // startDate: {
                //   nullable: true,
                //   title: "Start Date",
                //   type: ["string", "null"],
                //   metadata: {
                //     hidden: true,
                //     layout: { span: 12 },
                //   },
                // },
              },
            },
            orderBy: {
              title: "Order By",
              type: "array",
              metadata: {
                layout: { gutter: [] },
                component: "DnDDropzone",
                fieldProps: {
                  style: { minHeight: "100px" },
                },
              },
              items: {
                type: "object",
                required: ["column", "type"],
                properties: {
                  column: { $ref: "#/$defs/dataColumn" },
                  type: { $ref: "#/$defs/dataColumnType" },
                  direction: {
                    type: "string",
                    enum: ["asc", "desc"],
                    title: "Direction",
                    metadata: { labels: ["Ascending", "Descending"] },
                    default: "asc",
                  },
                },
              },
            },
            where: {
              title: "Filters",
              default: [],
              type: "array",
              metadata: {
                layout: { gutter: [] },
                component: "DnDDropzone",
                fieldProps: {
                  style: { minHeight: "100px" },
                },
              },
              items: {
                type: "object",
                required: ["column", "type"],
                properties: {
                  column: { $ref: "#/$defs/dataColumn" },
                  type: { $ref: "#/$defs/dataColumnType" },
                  op: { $ref: "#/$defs/scalarOperators" },
                  conj: { $ref: "#/$defs/conjunction" },
                  value: { $ref: "#/$defs/scalarValue" },
                  conditions: {
                    type: "array",
                    items: {
                      type: "object",
                      required: ["column", "type"],
                      properties: {
                        column: { $ref: "#/$defs/dataColumn" },
                        type: { $ref: "#/$defs/dataColumnType" },
                        op: { $ref: "#/$defs/scalarOperators" },
                        conj: { $ref: "#/$defs/conjunction" },
                        value: { $ref: "#/$defs/scalarValue" },
                        conditions: {
                          $ref: "#/$defs/queryWhere",
                          type: ["array", "null"],
                        },
                      },
                    },
                  },
                },
              },
            },
            distinctOn: {
              default: [],
              title: "Distinct Columns",
              type: "array",
              items: { type: "string" },
              metadata: {
                validateFirst: true,
                component: "ColumnSelect",
                fieldProps: {
                  popupMatchSelectWidth: false,
                },
              },
            },
          },
        },
      },
    },
  },
})

const tableSchemaDefinitions = {
  tableComputation: {
    title: "Column Computation",
    type: "array",
    minItems: 2,
    maxItems: 10,
    default: [],
    items: {
      title: "Computation",
      type: "object",
      required: ["propertyType", "op"],
      allOf: [
        {
          if: {
            properties: {
              propertyType: {
                enum: ["datasetColumn"],
              },
            },
          },
          then: {
            required: ["column"],
            properties: {
              type: { $ref: "#/$defs/dataColumnType" },
              column: { $ref: "#/$defs/dataColumn" },
              aggregate: { $ref: "#/$defs/aggregate" },
            },
          },
        },
        {
          if: {
            properties: {
              propertyType: {
                enum: ["staticValue"],
              },
            },
          },
          then: {
            required: ["value"],
            properties: {
              value: {
                title: "Value",
                default: 0,
                type: "number",
              },
            },
          },
        },
        {
          if: {
            properties: {
              propertyType: {
                enum: ["appVariable"],
              },
            },
          },
          then: {
            required: ["appVariableId"],
            properties: {
              appVariableId: {
                type: "string",
                title: "Global Variable",
                metadata: {
                  component: "AppVariableSelect",
                },
              },
            },
          },
        },
        {
          if: {
            properties: {
              propertyType: {
                enum: ["subExpression"],
              },
            },
          },
          then: {
            properties: {
              computation: { $ref: "#/$defs/tableComputation" },
            },
          },
        },
      ],
      metadata: {
        itemType: "collapse",
      },
      properties: {
        propertyType: {
          type: "string",
          default: "datasetColumn",
          title: "Property Type",
          enum: [
            "datasetColumn",
            "staticValue",
            "appVariable",
            "subExpression",
          ],
          metadata: {
            labels: [
              "Data Value",
              "Static Value",
              "App Variable",
              "Expression",
            ],
          },
        },
        op: {
          title: "Operation",
          type: "string",
          default: "+",
          enum: ["+", "-", "*", "/", "%"],
          metadata: {
            hidden: {
              itemIndex: 0,
            },
          },
        },
      },
    },
    metadata: {
      itemType: "collapse",
      validateFirst: true,
      component: "DnDDropzone",
      fieldProps: {
        style: {
          minHeight: "100px",
        },
      },
    },
  },
  columnType: {
    title: "Type",
    type: "string",
    enum: ["datasetColumn", "computed"],
    metadata: {
      hideOnSelect: true,
      labels: ["Dataset Column", "Computed Column"],
      fieldProps: {
        popupMatchSelectWidth: false,
      },
    },
  },
  dataIndex: {
    type: "string",
    metadata: {
      hidden: true,
      defaultValueSource: "uid",
    },
  },
  title: {
    type: ["string", "null"],
    title: "Title",
    nullable: true,
  },
  width: {
    title: "Width",
    type: ["number", "null"],
    nullable: true,
    metadata: {
      validateFirst: true,
      fieldProps: {
        placeholder: "(auto)",
      },
    },
  },
  textAlign: {
    type: "string",
    default: "left",
    title: "Text Align",
    enum: ["left", "right", "center"],
    metadata: {
      labels: ["Left", "Right", "Center"],
      fieldProps: {
        popupMatchSelectWidth: false,
      },
    },
  },
  fixed: {
    title: "Fixed",
    type: ["string", "null"],
    nullable: true,
    enum: ["left", "right"],
    metadata: {
      labels: ["Left", "Right"],
      fieldProps: {
        placeholder: "(none)",
      },
    },
  },
}

const tableWidgetSchemaDefault = tableWidgetSchema(false)
const tableWidgetSchemaAggregateRequired = tableWidgetSchema(true)
export { tableSchemaDefinitions, tableWidgetSchemaAggregateRequired }
export default tableWidgetSchemaDefault
