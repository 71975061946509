import { createSlice } from "@reduxjs/toolkit"
import { isEqual, flatten, compact, get } from "lodash"

import { selectCellsWithNodeData } from "selectors"
import { getCodeMirrorInstance } from "lib/codeMirrorStore"
import { actions as notebookActions } from "./notebookReducer"

/*
 * `searchTerm` is what a user is typing, while `activeSearchTerm` is set
 * after a search is initialized.
 */
const initialState = {
  searchTerm: "",
  searchOpen: false,
  searchResults: [],
  activeSearchTerm: "",
  currentMatchIndex: null,
}

const searchSourceFor = activeSearchTerm => (sourceString, lineNumber) => {
  return [...sourceString.matchAll(activeSearchTerm)].map(match => ({
    index: match.index,
    lineNumber,
  }))
}

/*
 * Given a string for `activeSearchTerm`, return a mapping function that
 * expects to be called with an object containing a cell and relevant metadata
 * related to it's parent node. Returns an array of match results that
 * includes data on the containing cell and node.
 * Example return value:
 * [
 *   {
 *    cellUuid: 'c3ll1d',
 *    nodeId: 'n0d31d',
 *    cellIdx: 0,
 *    index: 20,
 *    lineNumber: 3,
 *   }
 * ]
 */
const searchCellFor =
  activeSearchTerm =>
  ({ cell, parentData }) => {
    const splitSource =
      cell.source.length === 1 ? cell.source[0].split("\n") : cell.source
    const matches = flatten(splitSource.map(searchSourceFor(activeSearchTerm)))
    if (matches.length) {
      return matches.map(match => ({
        cellUuid: cell.uuid,
        nodeId: parentData.id,
        cellIdx: parentData.cellIdx,
        ...match,
      }))
    }
  }

/*
 * Search for all matches in all cells based on the current state of the app.
 */
const searchCellsForCode = state => {
  const results = selectCellsWithNodeData(state).map(
    searchCellFor(state.codeSearch.activeSearchTerm)
  )
  return compact(flatten(results))
}

const codeSearchSlice = createSlice({
  initialState,
  name: "codeSearch",
  reducers: {
    setSearchOpen: draft => {
      draft.searchOpen = true
    },
    setSearchClosed: draft => {
      draft.searchOpen = false
    },
    setActiveSearchTerm: draft => {
      draft.activeSearchTerm = draft.searchTerm
    },
    setSearchTerm: (draft, { payload }) => {
      draft.searchTerm = payload || ""
    },
    setSearchResults: (draft, { payload }) => {
      if (Array.isArray(payload)) {
        draft.searchResults = payload
        return
      }
      draft.searchResults = []
    },
    setCurrentMatchIndex: (draft, { payload }) => {
      if (isNaN(payload)) {
        draft.currentMatchIndex = null
        return
      }

      draft.currentMatchIndex = payload
    },
    reset: () => {
      return initialState
    },
  },
})

const { actions } = codeSearchSlice

/*
 * Stores the current `searchTerm` under `activeSearchTerm` and then populates
 * `searchResults` with matches from all cells.
 */
const search = () => (dispatch, getState) => {
  dispatch(actions.setActiveSearchTerm(getState().codeSearch.searchTerm))
  const { searchResults } = getState().codeSearch
  const newResults = searchCellsForCode(getState())
  // If the search results have changed, save them and reset the
  // `currentMatchIndex`.
  if (!isEqual(searchResults, newResults)) {
    dispatch(actions.setSearchResults(newResults))
    dispatch(actions.setCurrentMatchIndex(null))
  }
}

/*
 * Will try for up to 1.4 seconds to set a selection in code mirror based on
 * `selectOptions`.
 */
const maxIterations = 15
const selectInCodeMirror = (selectOptions, iteration = 1) => {
  if (iteration > maxIterations) return

  const { index, cellUuid, lineNumber, activeSearchTerm } = selectOptions
  const select = () => {
    const cm = getCodeMirrorInstance(cellUuid)
    if (!cm) {
      return selectInCodeMirror(selectOptions, iteration + 1)
    }
    const head = {
      line: lineNumber,
      ch: index + activeSearchTerm.length,
    }
    const anchor = {
      line: lineNumber,
      ch: index,
    }
    cm.setSelection(anchor, head)
  }

  if (iteration === 1) return select()
  window.setTimeout(select, 100)
}

/*
 * Always returns a number that is valid as an index for the given search
 * results. Returns 0 if `requestIndex` is invalidly high for the given result
 * set, and returns the final index if given a negative value. Basically
 * creating the start of a loop.
 */
const getSafeMatchIndex = (requestedIndex, searchResults) => {
  if (isNaN(requestedIndex)) return 0
  if (requestedIndex > searchResults.length - 1) return 0
  if (requestedIndex < 0) return searchResults.length - 1
  return requestedIndex
}

/*
 * Given an index of the match to navigate to, go to that node and select
 * the coresponding match in CodeMirror.
 */
const goToMatch = index => (dispatch, getState) => {
  const { searchResults } = getState().codeSearch
  const { workflow } = getState().notebook
  const nodes = get(workflow, "spec.nodes", [])
  const matchIndex = getSafeMatchIndex(index, searchResults)
  if (!searchResults.length) return
  const { cellIdx, nodeId } = searchResults[matchIndex]
  const nodeIndex = nodes.findIndex(element => element.id === nodeId)
  if (nodeIndex >= 0) {
    dispatch(
      notebookActions.updateNodeField({
        nodeIdx: nodeIndex,
        field: "collapsed",
        value: false,
      })
    )
  }

  dispatch(actions.setCurrentMatchIndex(matchIndex))
  // Wrapping the following fixes a Chrome specific issue I was seeing where
  // this call to focus the cell was not working. Everything worked perfectly
  // in FF even without this, but Chrome was having an issue. This, which is
  // the browser equivalent of `nextTick` solves the issue.
  Promise.resolve().then(() => {
    dispatch(notebookActions.focusCell({ node: nodeId, cellIdx }))
    selectInCodeMirror({
      ...getState().codeSearch,
      ...searchResults[matchIndex],
    })
  })
}

const goToNextMatch = () => (dispatch, getState) => {
  const { currentMatchIndex } = getState().codeSearch
  if (currentMatchIndex === null) {
    return dispatch(goToMatch(0))
  }
  dispatch(goToMatch(currentMatchIndex + 1))
}

const goToPriorMatch = () => (dispatch, getState) => {
  const { currentMatchIndex } = getState().codeSearch
  const current = currentMatchIndex || 0
  dispatch(goToMatch(current - 1))
}

const actionsAndThunks = {
  ...actions,
  search,
  goToMatch,
  goToNextMatch,
  goToPriorMatch,
}

export { actionsAndThunks as actions }
export default codeSearchSlice
