import { gql } from "@apollo/client"

export const GET_MODELS = gql`
  query GetModels($cname: String!) {
    customer(name: $cname) {
      id
      models {
        id
        name
        tags
        description
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_MODEL = gql`
  query GetModel($cname: String!, $id: Int!) {
    customer(name: $cname) {
      id
      model(id: $id) {
        id
        name
        tags
        description
        createdAt
        updatedAt
        modelRevisions {
          id
          type
          isRunning
          schema
          example
        }
      }
    }
  }
`

export const DELETE_MODEL = gql`
  mutation DeleteModel($cname: String!, $id: Int!) {
    deleteModel(cname: $cname, id: $id) {
      id
    }
  }
`

export const GET_MODEL_REVISIONS = gql`
  query GetModelRevisions($cname: String!, $modelId: Int!) {
    modelRevisions(cname: $cname, modelId: $modelId) {
      id
      comment
      createdAt
      modelId
      type
      filename
      userId
      isRunning
      uploadUrl
      downloadUrl
      schema
      example
    }
  }
`

export const GET_MODEL_REVISION = gql`
  query GetModelRevision($cname: String!, $id: Int!) {
    modelRevision(cname: $cname, id: $id) {
      id
      comment
      createdAt
      modelId
      type
      filename
      userId
      isRunning
      uploadUrl
      downloadUrl
      schema
      example
      predictions {
        id
        createdAt
        updatedAt
        modelRevisionId
        userId
        inputs
        results
        user {
          id
          name
          email
        }
      }
    }
  }
`

export const START_MODEL_REVISION_SERVER = gql`
  mutation StartModelRevisionServer($cname: String!, $mrId: Int!) {
    startModelRevisionServer(cname: $cname, id: $mrId) {
      modelRevisionId
      running
      predictionUrl
      schemaUrl
    }
  }
`

export const STOP_MODEL_REVISION_SERVER = gql`
  mutation StopModelRevisionServer($cname: String!, $mrId: Int!) {
    stopModelRevisionServer(cname: $cname, id: $mrId) {
      modelRevisionId
      running
      predictionUrl
      schemaUrl
    }
  }
`
