import { createSelector } from "reselect"
import { generateFullLayout, responsiveBreakpointNames } from "../lib/layout"
import { findFilter } from "../lib/filters"

// app selectors
export const selectApp = state => state?.app || {}
export const selectAppSpec = createSelector(selectApp, app => app.spec)
export const selectAppStatus = createSelector(selectApp, app => app.status)
export const selectAppSessionFilters = createSelector(
  selectApp,
  app => app.sessionFilters
)
export const selectCrossFilters = createSelector(
  selectApp,
  app => app.selectedPoints
)
export const selectCrossFiltersOnPage = createSelector(
  selectCrossFilters,
  (_, { pageId }) => pageId,
  (points, pageId) => points.filter(p => p.pageId === pageId)
)
export const selectRootPageId = createSelector(
  selectAppSpec,
  spec => spec.rootPageId
)

export const selectAppVariables = createSelector(
  selectAppSpec,
  spec => spec.variables || []
)

export const selectAppBreakpoint = createSelector(
  selectApp,
  app => app.breakpoint
)

export const selectAppTheme = createSelector(
  selectAppSpec,
  appSpec => appSpec.theme || {}
)

export const selectRefreshInterval = createSelector(
  selectAppSpec,
  appSpec => appSpec.refreshInterval
)

export const selectAllAppFilters = createSelector(
  selectAppSpec,
  spec => spec.filters
)

export const selectAllAppEndpoints = createSelector(
  selectAppSpec,
  spec => spec.endpoints || []
)

export const selectAppEndpoint = createSelector(
  selectAllAppEndpoints,
  (_, { id }) => id,
  (endpoints, id) => endpoints.find(e => e.id === id)
)

export const selectAppFilters = createSelector(
  selectAllAppFilters,
  (_, query) => query,
  (filters, query) => {
    return filters.filter(filter => {
      const { datasetId, column } = filter || {}
      if (datasetId !== query.datasetId) return false
      if (query.where?.some(w => w?.column === column)) return false
      return true
    })
  }
)

// ----- Widget Selectors -----
export const selectAppWidgets = createSelector(
  selectAppSpec,
  spec => spec.widgets
)
export const selectAppWidget = createSelector(
  selectAppWidgets,
  (_, { widgetId }) => widgetId,
  (widgets, widgetId) => widgets[widgetId]
)
export const selectAppWidgetLayout = createSelector(
  selectAppWidget,
  widget => widget?.layout
)
export const selectAppWidgetType = createSelector(
  selectAppWidget,
  widget => widget?.type
)
export const selectAppWidgetName = createSelector(
  selectAppWidget,
  widget => widget?.name
)

const selectWidgets = createSelector(
  selectAppWidgets,
  (_, { widgetIds }) => widgetIds,
  (widgets, widgetIds) => {
    return Object.entries(widgets)
      .filter(([widgetId]) => widgetIds?.includes(widgetId))
      .map(([widgetId, widget]) => {
        const layoutI = widgetId
        return generateFullLayout(widget.layout, layoutI)
      })
  }
)

export const selectSessionFiltersForDataset = createSelector(
  selectAppSessionFilters,
  (_, { datasetId }) => datasetId,
  (sessionFilters, datasetId) => {
    return sessionFilters[datasetId] || []
  }
)

export const selectSessionFilters = createSelector(
  selectSessionFiltersForDataset,
  (_, { pageId }) => pageId,
  (sessionFilters, pageId) => {
    return sessionFilters.filter(f => f.pageId === pageId)
  }
)

export const selectSessionFilter = createSelector(
  selectSessionFilters,
  (_, { filterId }) => filterId,
  (filters, filterId) => {
    return findFilter(filters, filterId)
  }
)

// ----- Page Selectors -----
export const selectAppPages = createSelector(selectAppSpec, spec => spec.pages)
export const selectAppPage = createSelector(
  selectAppPages,
  (_, { pageId }) => pageId,
  (pages, pageId) => pages[pageId]
)
export const selectPageWidgetIds = createSelector(
  selectAppPage,
  page => page?.widgetIds
)

// This selector fetches the z-order (position) of a specific widget on a page.
export const selectWidgetOrderOnPage = createSelector(
  selectPageWidgetIds,
  (_, { widgetId }) => widgetId,
  (widgetIds, widgetId) => widgetIds?.findIndex(i => i === widgetId)
)

export const selectResponsiveLayouts = createSelector(
  selectWidgets,
  filteredWidgets =>
    responsiveBreakpointNames.reduce((acc, bp) => {
      return {
        ...acc,
        [bp]: filteredWidgets.reduce((layouts, layout) => {
          const { interpolate, ...restLayout } = layout[bp]
          // always include lg layout
          if (bp === "lg" || [true, undefined, null].includes(interpolate)) {
            return [...layouts, layout.lg]
          }

          const safeLayout = {
            ...restLayout,
            w: restLayout.w || 10,
            h: restLayout.h || 10,
          }

          return [...layouts, safeLayout]
        }, []),
      }
    }, {})
)

// ----- Context Menu selectors -----
export const selectCurrentContext = createSelector(
  selectApp,
  app => app.contextMenu
)

export const selectContextMenuWidgetId = createSelector(
  selectCurrentContext,
  context => context.widgetId
)

export const selectMenuContent = createSelector(
  selectAppWidgets,
  selectAppPages,
  selectCurrentContext,
  (widgets, appPages, contextMenu) => {
    const { widgetId, pages: contextMenuPages } = contextMenu
    const selectedPageIds = Object.keys(contextMenuPages)

    let pages = []
    let widgetPageId = null
    Object.entries(appPages).forEach(([pageId, page]) => {
      if (selectedPageIds?.includes(pageId)) {
        pages.push({ ...page, ...contextMenuPages[pageId], id: pageId })
      }
      if (page.widgetIds.includes(widgetId)) {
        widgetPageId = pageId
      }
    })

    const widget = widgets[widgetId]
    return {
      pages,
      widget,
      widgetPageId,
    }
  }
)

// ----- Widget Editor Selectors -----
export const selectWidgetEditor = createSelector(
  selectApp,
  app => app.widgetEditor
)
export const selectEditingWidgetId = createSelector(
  selectWidgetEditor,
  editor => editor.widgetId
)
export const selectWidgetEditorPageId = createSelector(
  selectWidgetEditor,
  editor => editor.pageId
)
export const selectFullWidgetEditorOpen = createSelector(
  selectWidgetEditor,
  editor => editor.open
)
export const selectEditingAppWidget = createSelector(
  selectAppWidgets,
  selectEditingWidgetId,
  (widgets, widgetId) => widgets?.[widgetId]
)
export const selectCopyWidgetId = createSelector(
  selectWidgetEditor,
  editor => editor.copyWidgetId
)
export const selectShowAppSettings = createSelector(
  selectWidgetEditor,
  editor => editor.showAppSettings
)
export const selectShowAppFilters = createSelector(
  selectWidgetEditor,
  editor => editor.showAppFilters
)
export const selectShowAppVariables = createSelector(
  selectWidgetEditor,
  editor => editor.showAppVariables
)
export const selectShowEndpoints = createSelector(
  selectWidgetEditor,
  editor => editor.showAppEndpoints
)
export const selectShowEndpoint = createSelector(
  selectWidgetEditor,
  editor => editor.showAppEndpoint
)
export const selectCopiedWidget = createSelector(
  selectAppWidgets,
  selectCopyWidgetId,
  (widgets, widgetId) => (widgetId ? widgets[widgetId] : null)
)
export const selectEditingAppEndpoint = createSelector(
  selectWidgetEditor,
  editor => editor.endpointId
)

export const selectSelectedWidgets = createSelector(
  selectApp,
  app => app.selectedWidgets
)
