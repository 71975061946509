import React, { useMemo } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"

import { Form, Select, TextArea } from "@dbai/ui-staples"

import { actions } from "reducers/scheduleReducer"
import EmailAutoComplete from "./EmailAutoComplete"

const submitText = "Add Notification"
const messagePlaceholder =
  "Enter a customized message here or leave blank for a generic message"
const eventTypes = [
  { value: "Succeeded", label: "Succeeded" },
  { value: "Failed", label: "Failed" },
]

const StyledForm = styled(Form)`
  margin-bottom: 16px;
`

const NotificationForm = props => {
  const { cname, closeAlertsPanel } = props
  const dispatch = useDispatch()

  const initialState = useMemo(
    () => ({
      eventType: "",
      addresses: [],
      content: "",
    }),
    []
  )

  const handleSubmit = state => {
    dispatch(actions.createNotification(state)).then(() => {
      closeAlertsPanel()
    })
  }

  return (
    <StyledForm
      initialState={initialState}
      submitText={submitText}
      onSubmit={handleSubmit}
    >
      <EmailAutoComplete cname={cname} />
      <Select label="Event" options={eventTypes} name="eventType" />
      <TextArea placeholder={messagePlaceholder} name="content" />
    </StyledForm>
  )
}

export default NotificationForm
