import React from "react"
import { useQuery } from "@apollo/client"

import {
  Card,
  Select,
  ApiForm,
  GET_PREFS,
  PageSpinner,
  UPDATE_PREFS,
  ErrorMessage,
} from "@dbai/ui-staples"

const themes = [
  { value: "default", label: "default" },
  { value: "material", label: "material" },
  { value: "material-darker", label: "material-darker" },
  { value: "solarized dark", label: "solarized dark" },
  { value: "solarized light", label: "solarized light" },
]
const fontSizes = [
  { value: "small", label: "small" },
  { value: "medium", label: "medium" },
  { value: "large", label: "large" },
]

const EditPreferences = props => {
  const { error, loading, data } = useQuery(GET_PREFS)

  if (error) return <ErrorMessage error={error} />
  if (loading) return <PageSpinner />

  return (
    <Card title="SCRIPT EDITOR PREFERENCES">
      <ApiForm
        initialState={data.me.preferences}
        mutation={UPDATE_PREFS}
        saveMessage="Preferences Updated!"
      >
        <label>Theme</label>
        <Select name="theme" options={themes} />
        <br />

        <label>Font Size</label>
        <Select name="fontSize" options={fontSizes} />
        <br />
      </ApiForm>
    </Card>
  )
}

export default EditPreferences
