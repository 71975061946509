import { set, get, curry } from "lodash"

const setLastChangedTime = draft => (draft.lastChanged = new Date().getTime())

export const createCommonActions = (initialState, opts = {}) => ({
  reset: () => initialState,
  set: (draft, { payload }) => {
    if (opts.setLastChange) setLastChangedTime(draft)
    set(draft, payload.name, payload.value)
  },
  append: (draft, { payload }) => {
    if (opts.setLastChange) setLastChangedTime(draft)
    const existingArray = get(draft, payload.name)
    if (existingArray) {
      existingArray.push(payload.value)
      return
    }

    set(draft, payload.name, [payload.value])
  },
  join: (draft, { payload }) => {
    if (opts.setLastChange) setLastChangedTime(draft)
    const existing = get(draft, payload.name, [])
    set(draft, payload.name, [...existing, ...payload.value])
  },
  remove: (draft, { payload }) => {
    if (opts.setLastChange) setLastChangedTime(draft)
    const { idx, name, key } = payload
    let target = get(draft, name)
    if (!key) {
      target.splice(idx, 1)
    } else {
      target = target.filter(item => {
        return item[key] !== idx
      })
    }
  },
})

const capitalizeResource = resourceName => {
  if (!resourceName) return ""
  return resourceName.charAt(0).toUpperCase() + resourceName.slice(1)
}

const ensureResourceValid = (resourceName, attribute, initialState) => {
  const resource = resourceName ? initialState[resourceName] : initialState
  if (!resource) {
    console.warn(`${resourceName} is not a valid resource type`)
    return false
  }

  if (!Object.keys(resource).includes(attribute)) {
    console.warn(`No attribute of ${attribute} exists on ${resourceName}`)
    return false
  }

  return true
}

export const setResourceAttribute = curry(
  (resourceName, attribute, initialState, draft, { payload }) => {
    if (ensureResourceValid(resourceName, attribute, initialState)) {
      if (resourceName) {
        draft[resourceName][attribute] = payload
      } else {
        draft[attribute] = payload
      }
    }
  }
)

/*
 * Creates a few simple actions to help handle loading data in a reducer.
 * Emulates the Apollo data shape with the attributes `data`, `loading`, and
 * `error` assumed to exist on the target slice. When given a `resourceName`
 * can be used with a scoped resource.
 */
export const createResourceActions = ({
  resourceName = "",
  initialState = null,
}) => {
  const upperCaseResource = capitalizeResource(resourceName)
  const setString = `set${upperCaseResource}`
  return {
    [`${setString}Loading`]: setResourceAttribute(
      resourceName,
      "loading",
      initialState
    ),
    [`${setString}Error`]: setResourceAttribute(
      resourceName,
      "error",
      initialState
    ),
    [`${setString}Data`]: setResourceAttribute(
      resourceName,
      "data",
      initialState
    ),
  }
}

/*
 * Assuming that `createResourceActions` was used in the slice in question,
 * this thunk can be used to load remote resources and track their loading and
 * error statuses in redux. Requires that the reducer's actions are passed in
 * as well as a function that returns a promise that will actually fetch the
 * data. Can also be used with a scoped resoruce.
 */
export const loadResource =
  ({ resource = "", fetchFn, actions }) =>
  dispatch => {
    const upperCaseResource = capitalizeResource(resource)
    const setString = `set${upperCaseResource}`
    const loadingKey = `${setString}Loading`
    const errorKey = `${setString}Error`
    const dataKey = `${setString}Data`

    dispatch(actions[loadingKey](true))
    dispatch(actions[errorKey](null))

    return fetchFn()
      .then(value => dispatch(actions[dataKey](value)))
      .catch(err => dispatch(actions[errorKey](err.message || err)))
      .finally(() => dispatch(actions[loadingKey](false)))
  }
