import React, { useCallback } from "react"
import { Input } from "antd"
import { useSelector } from "react-redux"

import { useAction } from "hooks"
import { actions } from "reducers/componentReducer"
import ColorPicker from "./ColorPicker"
import withCustomer from "components/shared/withCustomer"
import { selectComponent, selectComponentColor } from "selectors"

const { TextArea } = Input

const NameEditor = props => {
  const { value, onChange } = props
  const handleChange = useCallback(e => onChange(e.target.value, e), [onChange])

  return (
    <div className="form-group">
      <label className="form-label">Name</label>
      <Input id="name" value={value} onChange={handleChange} name="name" />
    </div>
  )
}

const DescriptionEditor = props => {
  const { value, onChange } = props
  const handleChange = useCallback(e => onChange(e.target.value, e), [onChange])

  return (
    <div className="form-group">
      <label className="form-label">Description</label>
      <TextArea
        id="description"
        value={value}
        onChange={handleChange}
        name="description"
      />
    </div>
  )
}

const ComponentSettingsForm = props => {
  const { name, description } = useSelector(selectComponent)
  const color = useSelector(selectComponentColor)
  const handleNameChange = useAction(actions.setName)
  const handleDescriptionChange = useAction(actions.setDescription)
  const handleColorChange = useAction(actions.setColor)

  return (
    <>
      <ColorPicker onChange={handleColorChange} color={color} />
      <NameEditor value={name} onChange={handleNameChange} />
      <DescriptionEditor
        value={description}
        onChange={handleDescriptionChange}
      />
    </>
  )
}

export default withCustomer(ComponentSettingsForm)
