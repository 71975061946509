import React from "react"
import styled from "styled-components"
import { GithubPicker } from "react-color"

const nodeColors = [
  "#aa2323",
  "#DB3E00",
  "#FCCB00",
  "#008B02",
  "#006B76",
  "#1273DE",
  "#004DCF",
  "#5300EB",
  "#0c2963",
  "#EB9694",
]

const StyledColorPicker = styled(GithubPicker)`
  border: none !important;
  box-shadow: none !important;
  padding: 0 0 15px !important;
  background-color: transparent !important;
  padding-bottom: 0 !important;
  span > div {
    height: ${props => props.size || "25px"} !important;
    width: ${props => props.size || "25px"} !important;
  }
`

const ComponentColorPicker = props => {
  const { onChange, color, size } = props

  return (
    <StyledColorPicker
      size={size}
      width="100%"
      onChangeComplete={onChange}
      triangle="hide"
      colors={nodeColors}
      color={color || {}}
    />
  )
}

export default ComponentColorPicker
