import { jwtDecode } from "jwt-decode"
import { onError } from "@apollo/client/link/error"
import {
  from,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  defaultDataIdFromObject,
} from "@apollo/client"

import createRefreshLink from "./refreshClient"
import { GET_CURRENT_USER } from "../queries/users"
import { queries, mutations, initializers } from "./resolvers"

/*
 * Any resources that could possibly have the same id based on tenant scoping
 * should be placed in here. This will allow us to ensure that apollo's cache
 * is correctly configured to avoid collisions.
 */
const tenantResources = [
  "Action",
  "ActionRevision",
  "App",
  "Component",
  "ComponentRevision",
  "ComputeInstance",
  "Dataset",
  "Folder",
  "Invitation",
  "Package",
  "Policy",
  "Role",
  "Schedule",
  "Theme",
  "Upload",
  "User",
  "Workflow",
]

const scopeDataId = responseObject => {
  const path = window.location.pathname.split("/").filter(Boolean)
  const cname = path[0]
  return `${cname}:${responseObject.__typename}:${responseObject.id}`
}

const cache = new InMemoryCache({
  typePolicies: {
    Dataset: {
      fields: {
        tableMeta: {
          merge(existing, incoming) {
            // Your custom merge logic
            // For example, you can deep merge the existing and incoming data:
            return { ...existing, ...incoming }
          },
        },
        meta: {
          merge(existing, incoming) {
            // Your custom merge logic
            // For example, you can deep merge the existing and incoming data:
            return { ...existing, ...incoming }
          },
        },
        stats: {
          merge(existing, incoming) {
            // Your custom merge logic
            // For example, you can deep merge the existing and incoming data:
            return { ...existing, ...incoming }
          },
        },
      },
    },
  },
  possibleTypes: {
    WorkflowNode: [
      "WorkflowSourceNode",
      "WorkflowSinkNode",
      "WorkflowScriptNode",
      "WorkflowEmptyNode",
      "WorkflowComponentNode",
    ],
  },
  dataIdFromObject(responseObject) {
    if (tenantResources.includes(responseObject.__typename)) {
      return scopeDataId(responseObject)
    }
    if (responseObject.__typename === "Action") {
      return `${responseObject.__typename}:${responseObject.id}:${responseObject.createdAt}`
    }
    return defaultDataIdFromObject(responseObject)
  },
})

// Initialize the Apollo client-side cache to a sane state when the
// application boots. Initializers are imported from the resolvers directory.
const initialState = initializers.reduce((pre, next) => next(pre), {})

cache.writeQuery({
  query: GET_CURRENT_USER,
  data: initialState,
})

// Inject the raw and parsed JWT from localStorage.
const createJwtLink = () => ctx => {
  const jwt = localStorage.getItem("user/jwt")
  if (!jwt) {
    return ctx
  }

  return {
    jwt,
    claims: jwtDecode(jwt),
    ...ctx,
  }
}

// Inject the JWT into the Authorization header.
const creatAuthLink =
  () =>
  ({ jwt, headers }) => {
    if (!jwt) {
      return {}
    }

    return {
      headers: {
        ...headers,
        authorization: `Bearer ${jwt}`,
      },
    }
  }

// Only allow cookies when Signing in or out.
const createCredentialsLink =
  ({ operationName }) =>
  () => {
    const shouldAllowCookies = [
      "SignIn",
      "SignOut",
      "AcceptInvite",
      "ResetPassword",
    ].includes(operationName)

    if (shouldAllowCookies) {
      return { credentials: "include" }
    }
  }

const createLink = fn => {
  return new ApolloLink((operation, forward) => {
    operation.setContext(fn(operation))
    return forward(operation)
  })
}

// appLink is all of our other links concatenated in order.
const constructAppLink = (graphql, handleError) => {
  return from([
    onError(handleError),
    createLink(createCredentialsLink),
    createLink(createJwtLink),
    createRefreshLink(cache, graphql),
    createLink(creatAuthLink),
    new HttpLink({ uri: graphql }),
  ])
}

const resolvers = {
  Mutation: {
    ...mutations,
  },
  Query: {
    ...queries,
  },
}

const apolloClient = {
  cache,
  resolvers,
  initialState,
  constructAppLink,
}
export default apolloClient
