import React from "react"

import KernelProvider from "./KernelProvider"
import WidgetProvider from "./WidgetProvider"

const KernelManager = props => {
  const { name, spawnParams } = props

  return (
    <KernelProvider name={name} spawnParams={spawnParams}>
      <WidgetProvider>{props.children}</WidgetProvider>
    </KernelProvider>
  )
}

export default KernelManager
