import { actions } from "../../reducers/appReducer"
import { observeYjsMap } from "./utils"

const addEndpointObserver = (dispatch, endpointMap) => {
  observeYjsMap({
    yMap: endpointMap,
    onExternalChange: ({ yMap }) => {
      dispatch(actions.updateAppEndpoint(yMap.toJSON()))
    },
  })
  return endpointMap
}

const endpointObserver = (yDoc, dispatch) => {
  const specMap = yDoc.getMap("spec")
  const endpointsArray = specMap.get("endpoints")
  const endpoints = endpointsArray.toArray()

  endpoints.forEach(endpointMap => {
    addEndpointObserver(dispatch, endpointMap)
  })
}

export { addEndpointObserver }
export default endpointObserver
