import { useState, useEffect } from "react"
import { useMutation } from "@apollo/client"

const useFormMutation = (mutationGQL, opts) => {
  const { afterSubmit, onError, ...mutationOpts } = opts
  const [existingError, setExistingError] = useState(null)
  const [confirmed, setConfirmed] = useState(false)
  const [mutation, { data, loading, error }] = useMutation(
    mutationGQL,
    mutationOpts
  )

  useEffect(() => {
    if (!existingError && error) {
      setExistingError(error)
      onError(error)
    }
  }, [error, onError, existingError])

  useEffect(() => {
    if (!confirmed && data && !error && !loading) {
      afterSubmit(data)
      setConfirmed(true)
    }
  }, [confirmed, data, error, loading, afterSubmit])

  const handleSubmit = state => {
    setExistingError(null)
    setConfirmed(false)
    mutation({ variables: state })
  }

  return {
    data,
    error,
    loading,
    mutation,
    handleSubmit,
  }
}

export default useFormMutation
