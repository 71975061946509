import { createSlice } from "@reduxjs/toolkit"

import { toastAndRethrow, createCommonActions } from "@dbai/tool-box"

import { SEARCH } from "queries"
import client from "apolloClient"

/*
 * term - Current value to filter by
 * currentTerm - Value to search by last sent to the server
 * results - Server returned values
 */
const initialState = {
  term: "",
  currentTerm: "",
  results: [],
}

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    ...createCommonActions(initialState),
    clearResults: draft => {
      draft.results = initialState.results
      draft.currentTerm = ""
    },
    syncTerms: draft => {
      draft.currentTerm = draft.term
    },
  },
})

const { actions } = searchSlice

/*
 * We don't want to search more often then we have to. Provided the term that
 * was used to search is contained perfectly at the beginning of the current
 * search term it should be safe to filter the existing result set on the
 * client side.
 */
const checkIfTermChanged = (currentTerm = "", term = "") => {
  if (term && !currentTerm) return true

  return !term.startsWith(currentTerm)
}

const search = () => (dispatch, getState) => {
  const { term, currentTerm } = getState().search
  const { normalizedName: cname } = getState().currentCustomer.customer

  // Don't search for less than three characters.
  if (term.length < 3) return dispatch(actions.clearResults())

  if (checkIfTermChanged(currentTerm, term)) {
    const query = SEARCH
    const variables = { term, cname }
    dispatch(actions.syncTerms())
    return client
      .query({ variables, query })
      .then(({ data, loading, error }) => {
        dispatch(actions.set({ name: "results", value: data.customer.search }))
      })
      .catch(toastAndRethrow("Error getting search results!"))
  }
}

const allActions = {
  search,
  ...actions,
}

export { allActions as actions }
export default searchSlice
