import { curry } from "lodash"

const getColorAxis = options => {
  const { colorAxis } = options
  return {
    min: colorAxis?.min || -1,
    max: colorAxis?.max || 1,
    stops: colorAxis?.stops?.length
      ? colorAxis.stops
          .filter(stop => stop?.value && stop?.color)
          .map(({ value, color }) => {
            return [value, color]
          })
      : [
          [0, "#9CD3F1"],
          [0.5, "#ffffff"],
          [1, "#F19C9C"],
        ],
  }
}

const constructHeatmapSeries = curry((dataset, options) => {
  const { columns, legend = {}, highchart = {} } = options || {}
  if (!columns?.length || !dataset?.stats?.correlation) {
    return { ...options, highchart: { ...highchart, series: [] } }
  }

  const data = []
  const categories = []
  var xIdx = 0

  for (const row in dataset.stats.correlation) {
    categories.push(row)
    var yIdx = 0
    for (const point in dataset.stats.correlation[row]) {
      data.push([xIdx, yIdx, dataset.stats.correlation[row][point]])
      yIdx++
    }
    xIdx++
  }

  return {
    ...options,
    yAxis: {
      categories,
      title: { enabled: false },
    },
    legend: {
      y: 25,
      ...legend,
    },
    highchart: {
      ...highchart,
      xAxis: {
        categories,
        title: { enabled: false },
      },
      colorAxis: getColorAxis(options),
      series: [
        {
          data,
          custom: {
            columns: dataset.meta.datasetColumns.reduce((acc, c) => {
              return { ...acc, [c.name]: c }
            }, {}),
          },
        },
      ],
    },
  }
})

export default constructHeatmapSeries
