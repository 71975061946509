import React, { useCallback, useState, useEffect } from "react"

import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { Card, Row, Col, Typography, Tag, Space } from "antd"
import { getJwt } from "lib/jwt"
import styled from "styled-components"

import { PageSpinner, ErrorMessage } from "@dbai/ui-staples"
import { SchemaForm } from "@dbai/applet"
import config from "config"

import { actions } from "reducers/componentReducer"
import { selectComponent } from "selectors"
import withCustomer from "components/shared/withCustomer"
import RevisionsTable from "./RevisionsTable"

const allowedMethods = ["get", "post", "put", "delete", "patch"]

const useSwaggerDoc = (cname, path) => {
  const [spec, setSpec] = useState({})
  const swaggerURL = `${config.api}endpoints/${cname}/openapi.json`
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [method, setMethod] = useState("GET")
  const [parameters, setParameters] = useState([])
  const [requestBody, setRequestBody] = useState({})
  const [responses, setResponses] = useState({})
  const [schema, setSchema] = useState({})

  const getSchemaName = useCallback(requestBody => {
    const schemaPath = requestBody.content["application/json"].schema["$ref"]
    const schemaName = schemaPath.split("/")
    return schemaName.pop()
  }, [])

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getJwt()}`,
    }
    fetch(swaggerURL, {
      headers,
    })
      .then(res => {
        return res.json()
      })
      .then(res => {
        setSpec(res)

        const pathItem = res.paths[path]
        allowedMethods.forEach(method => {
          if (pathItem[method]) {
            setMethod(method)
            setParameters(pathItem[method].parameters)
            setRequestBody(pathItem[method].requestBody)
            setResponses(pathItem[method].responses)
            const schemaName = getSchemaName(pathItem[method].requestBody)
            setSchema(res.components.schemas[schemaName])
          }
        })
        setError(false)
        setLoading(false)
      })
      .catch(_err => {
        setSpec({})
        setError(true)
        setLoading(false)
      })
  }, [swaggerURL, setSpec, path, getSchemaName])

  return {
    spec,
    method,
    parameters,
    requestBody,
    responses,
    schema,
    loading,
    error,
  }
}

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 0;
  }
`

const StyledTag = styled(Tag)`
  padding: 0px 1rem 0px 0px;
  width: 100%;
`

const StyledForm = styled(Row)`
  width: 100%;
`

const StyledCardForm = styled(Card)`
  .ant-card-body {
    min-height: 200px;
  }
`
const TestingForm = ({ path }) => {
  const [apiResponse, setApiResponse] = useState({
    status: "",
    response: {},
  })
  const { cname } = useParams()
  const { method, schema, error, loading } = useSwaggerDoc(cname, path)

  const handleSubmit = useCallback(
    payload => {
      setApiResponse({ status: "", response: {} })
      const componentURL = `${config.api}endpoints/${cname}${path}`
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${getJwt()}`,
      }
      fetch(componentURL, {
        method: method,
        headers,
        body: JSON.stringify(payload),
      })
        .then(res => {
          setApiResponse({ status: res.status })
          if (res.status === 500) return { error: true }
          return res.json()
        })
        .then(data => {
          setApiResponse(prev => ({ ...prev, response: data }))
        })
    },
    [method, path, cname]
  )

  if (error) return <ErrorMessage error={error} />
  if (loading) return <PageSpinner />

  return (
    <Row gutter={16}>
      <Col span={12}>
        <StyledCardForm>
          <Row gutter={[16, 16]}>
            <StyledTag color="default">
              <Tag color="black">{method.toUpperCase()}</Tag>
              <Typography.Text> {path}</Typography.Text>
            </StyledTag>
            <StyledForm>
              <SchemaForm schema={schema} onSubmit={handleSubmit} />
            </StyledForm>
          </Row>
        </StyledCardForm>
      </Col>
      <Col span={12}>
        <StyledCardForm>
          <Space>
            <Typography.Text strong underline>
              Response
            </Typography.Text>
            {apiResponse.status ? (
              <Tag color={apiResponse.status === 200 ? "success" : "error"}>
                {apiResponse.status}
              </Tag>
            ) : null}
          </Space>
          <pre>{JSON.stringify(apiResponse.response, null, 2)}</pre>
        </StyledCardForm>
      </Col>
    </Row>
  )
}

const ComponentTesting = props => {
  const dispatch = useDispatch()
  const { customer } = props
  const { id: componentId } = useParams()
  const { id } = useSelector(selectComponent)
  const closePanel = useCallback(() => dispatch(actions.reset()), [dispatch])
  const path = `/components/${componentId}`

  return (
    <Row gutter={[16, 16]}>
      <Col span={16}>
        <TestingForm path={path} />
      </Col>
      <Col span={8}>
        <StyledCard title="Revision History">
          <RevisionsTable
            showNew={id}
            onNewClose={closePanel}
            customerId={customer.id}
            componentId={componentId}
            cname={customer.normalizedName}
          />
        </StyledCard>
      </Col>
    </Row>
  )
}

export default withCustomer(ComponentTesting)
