import React from "react"
import { useQuery } from "@apollo/client"
import { Redirect } from "react-router-dom"

import { GET_ME } from "@dbai/ui-staples"

import { isSuperAdmin } from "lib/roles"

import { useDefaultPath } from "hooks"

const DefaultPath = props => {
  const { data } = useQuery(GET_ME, {
    fetchPolicy: "network-only",
  })

  const path = useDefaultPath(data?.me?.customers?.[0])

  if (data) {
    const currentUser = data.me
    if (isSuperAdmin(currentUser)) {
      return <Redirect to={`/admin/customers`} />
    } else {
      const cname = currentUser.customers[0].normalizedName
      return <Redirect to={`/${cname}/${path}`} />
    }
  }
  return null
}

export default DefaultPath
