import React, { useRef, useMemo, useCallback, useState } from "react"
import { Space, Input, Table, Row, Col, Button } from "antd"
import { useQuery, useMutation } from "@apollo/client"
import styled from "styled-components"

import { uuidv4 } from "@dbai/tool-box"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons"
import DeleteButton from "../DeleteButton"
import withDragDropRows from "./withDragDropRows"
import { withDefaultTableColumns } from "../Table/AltTable"
import { GET_FOLDER, SAVE_FOLDER } from "../../queries/folders"
import useCurrentCustomer from "../../hooks/useCurrentCustomer"

const DEFAULT_FOLDER_SPEC = { isFolder: true, name: "New Folder", children: [] }

const GhostInput = styled(Input)`
  border: 1px solid #d9d9d9;
  background-color: transparent;
`

const TableWithMovableRows = withDragDropRows(Table)

const generateNamesForTableRows = rows => {
  const generateName = previousName => (row, index) => {
    const { children } = row
    const name = `${previousName ? `${previousName}.children.` : ""}${index}`
    return {
      ...row,
      rowName: name,
      children: children?.map(generateName(name)),
    }
  }
  return rows.map(generateName())
}

const generateFolderTree = (data, folderEntries, changeFolderName) => {
  const dataCopy = structuredClone(data)

  const processFolderContent = ([key, folder]) => {
    const { children } = folder
    return {
      ...folder,
      id: key,
      isFolder: true,
      onChange: changeFolderName,
      children: children?.reduce((acc, child) => {
        const idx = dataCopy.findIndex(d => d.id === child.id)
        if (idx >= 0) {
          const dataRow = dataCopy[idx]
          dataCopy.splice(idx, 1)
          return [...acc, dataRow]
        }
        return acc
      }, []),
    }
  }

  const folderRows = folderEntries.map(processFolderContent)
  const mergedRows = [...folderRows, ...dataCopy]
  return generateNamesForTableRows(mergedRows)
}

const FolderName = props => {
  const { value, onChange, onRemove, rowKey } = props
  const [localValue, setLocalValue] = useState(value)
  const [isOpen, setIsOpen] = useState(false)

  function toggle() {
    setIsOpen(isOpen => !isOpen)
  }

  const handleChange = e => {
    setLocalValue(e.target.value)
    onChange && onChange(rowKey, e.target.value)
  }

  const handleRemove = () => {
    onRemove && onRemove(rowKey)
  }

  const stopPropagation = e => {
    e.stopPropagation()
  }

  return (
    <Row gutter={[16]}>
      <Col flex={1}>
        <p>{localValue}</p>
        {isOpen && (
          <GhostInput
            type="text"
            value={localValue}
            onChange={handleChange}
            onClick={stopPropagation}
          />
        )}
      </Col>
      <Col>
        <Button.Group>
          <Button
            onClick={toggle}
            icon={
              <FontAwesomeIcon icon={faPencilAlt} className="action-icon" />
            }
          />
          <DeleteButton onDelete={handleRemove}>Delete</DeleteButton>
        </Button.Group>
      </Col>
    </Row>
  )
}

const folderRenderFn =
  ({ onRemove, onChange }, render) =>
  (text, record) => {
    if (record.isFolder) {
      return (
        <FolderName
          value={text}
          rowKey={record.id}
          onRemove={onRemove}
          onChange={onChange}
        />
      )
    }
    return render ? render(text, record) : text
  }

const getColumnWithFolderRender = (column, folderRowActions, columns) => {
  const { dataIndex, render } = column
  if (dataIndex === "name") {
    return {
      ...column,
      render: folderRenderFn(folderRowActions, render),
      onCell: (row, index) => {
        return row.isFolder
          ? {
              colSpan: Object.entries(columns).length,
            }
          : {}
      },
    }
  }
  return {
    ...column,
    onCell: (row, index) => (row.isFolder ? { colSpan: 0 } : {}),
  }
}

const getColumnsWithFolderRender = (columns, folderRowActions) => {
  return columns.reduce((acc, column) => {
    const columnSpec = getColumnWithFolderRender(
      column,
      folderRowActions,
      columns
    )
    return [...acc, columnSpec]
  }, [])
}

const TableWithFolders = props => {
  const { extra, columns, folderType, dataSource, ...rest } = props
  const [{ normalizedName: cname }] = useCurrentCustomer()
  const [folderTree, setFolderTree] = useState({})
  const folderSpecId = useRef(null)
  useQuery(GET_FOLDER, {
    skip: !cname || !folderType,
    variables: { cname, folderType: folderType },
    onCompleted: ({ folder }) => {
      folderSpecId.current = folder.id
      setFolderTree(folder.folderTree || [])
    },
  })

  const [saveFolder] = useMutation(SAVE_FOLDER)

  const setAndSaveFolder = useCallback(
    newFolderTree => {
      setFolderTree(newFolderTree)
      saveFolder({
        variables: {
          cname,
          folderType,
          id: folderSpecId.current,
          folderTree: newFolderTree,
        },
      })
    },
    [saveFolder, cname, folderType]
  )
  const addFolder = useCallback(() => {
    const id = uuidv4()
    const tree = {
      ...folderTree,
      [id]: { id, ...DEFAULT_FOLDER_SPEC },
    }
    setAndSaveFolder(tree)
  }, [setAndSaveFolder, folderTree])

  // called after a move event occurs. this function takes table data and turns it into a folder-entity linked list
  const saveFolderStruct = useCallback(
    newData => {
      const folderData = newData.filter(d => d.isFolder)
      const folderDataObj = folderData.reduce(
        (acc, folder) => ({ ...acc, [folder.id]: folder }),
        {}
      )
      setAndSaveFolder(folderDataObj)
    },
    [setAndSaveFolder]
  )

  const updateFolderName = useCallback(
    (key, name) => {
      const newFolderTree = {
        ...folderTree,
        [key]: {
          ...folderTree[key],
          name,
        },
      }
      setAndSaveFolder(newFolderTree)
    },
    [setAndSaveFolder, folderTree]
  )

  const removeFolder = useCallback(
    key => {
      const newFolderTree = structuredClone(folderTree)
      delete newFolderTree[key]
      setFolderTree(newFolderTree)
      saveFolder({
        variables: {
          cname,
          folderType,
          id: folderSpecId.current,
          folderTree: newFolderTree,
        },
      })
    },
    [saveFolder, cname, folderType, folderTree]
  )

  const formattedDS = useMemo(() => {
    if (!dataSource) return []
    if (!Object.entries(folderTree).length) return dataSource
    return generateFolderTree(dataSource, Object.entries(folderTree))
  }, [dataSource, folderTree])

  const formattedColumns = useMemo(() => {
    if (!columns) return []
    const folderRowActions = {
      onRemove: removeFolder,
      onChange: updateFolderName,
    }
    return getColumnsWithFolderRender(columns, folderRowActions)
  }, [updateFolderName, removeFolder, columns])

  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Space>
          <Button onClick={addFolder}>Add Folder</Button>
          {extra ? extra : null}
        </Space>
      </Col>
      <Col span={24}>
        <TableWithMovableRows
          bordered
          dataSource={formattedDS}
          onMove={saveFolderStruct}
          columns={formattedColumns}
          expandable={{
            expandRowByClick: true,
          }}
          {...rest}
        />
      </Col>
    </Row>
  )
}

export default withDefaultTableColumns(TableWithFolders)
