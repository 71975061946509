import React, { useRef, useEffect, useCallback, useState } from "react"
import { pipe } from "lodash/fp"
import { createPortal } from "react-dom"
import styled from "styled-components"
import { useHotkeys } from "react-hotkeys-hook"

import { useCommSubscription } from "hooks"

const codeCheck = RegExp(/[A-Za-z0-9.]+/)
const lastWords = RegExp(/[a-zA-Z0-9.]*$/)

// Calculates the position to put the auto select menu on the page.
const calculatePosition = el => {
  if (!el) return {}
  const rect = el.getBoundingClientRect()
  const viewHeight = window.scrollY + window.innerHeight
  const left = rect.left + window.scrollX
  const top = rect.top + window.scrollY
  const bottom = viewHeight - rect.bottom
  // If we are below half way down the screen, send the menu up from here.
  if (viewHeight / 2 < top) {
    const max = viewHeight - bottom
    return { left, bottom, max }
  }
  const max = viewHeight - top
  return { left, top, max }
}

const findWord = opts => {
  const { codeMirror } = opts
  const cursor = codeMirror.doc.getCursor()
  const word = codeMirror.findWordAt({ ...cursor, sticky: "before" })
  return { ...opts, word }
}

const selectWord = opts => {
  const { codeMirror, word, code } = opts
  if (!codeCheck.test(code)) return opts
  const { anchor, head } = word || findWord(codeMirror)
  codeMirror.setSelection(anchor, head)
  return opts
}

const getCode = opts => {
  const { codeMirror, word } = opts
  const { head } = word || findWord(codeMirror)
  const line = codeMirror.getLine(head.line)
  const toWord = line.slice(0, head.ch)
  const codeMatch = toWord.match(lastWords) || [""]
  const code = codeMatch[0]
  return { ...opts, code }
}

const getCodeTabKey = opts => {
  const { codeMirror, word } = opts
  const { head } = word || findWord(codeMirror)
  const line = codeMirror.getLine(head.line)
  const toWord = line.slice(0, head.ch)
  const codeMatch = toWord.match(lastWords) || [""]
  const code = codeMatch[0]
  const cursor = codeMirror.getCursor()
  const charBeforeCursor = codeMirror.getRange(
    { line: cursor.line, ch: cursor.ch - 1 },
    cursor
  )
  const isAfterSpaceOrTab =
    charBeforeCursor === " " || charBeforeCursor === "\t"
  const isEmptySpace = isAfterSpaceOrTab || cursor?.ch === 0
  return { ...opts, code: !isEmptySpace ? code : "" }
}

const requestSuggestions = opts => {
  const { codeMirror, completeRequestChannel, code, cellId, sessionId } = opts
  if (!codeCheck.test(code)) {
    codeMirror.replaceSelection("\t")
    return opts
  }
  const metadata = { cellId, sessionId }
  const message = { code, cursor_pos: code.length }
  completeRequestChannel.send({}, message, { metadata })
  return opts
}

const setPosition = opts => {
  const { codeMirror, setAutoCompletePosition } = opts
  const cursorDiv = codeMirror.display.cursorDiv
  const position = calculatePosition(cursorDiv?.firstChild)
  setAutoCompletePosition(position)
  return opts
}

const initiateAutoComplete = pipe(
  findWord,
  getCode,
  selectWord,
  requestSuggestions,
  setPosition
)

const initiateAutoCompleteTabKey = pipe(
  findWord,
  getCodeTabKey,
  selectWord,
  requestSuggestions,
  setPosition
)

export const runAutoComplete = opts => codeMirror => {
  initiateAutoComplete({ ...opts, codeMirror })
}

export const runAutoCompleteTabKey = opts => codeMirror => {
  initiateAutoCompleteTabKey({ ...opts, codeMirror })
}

const StyledLi = styled.li`
  padding-left: 5px;
  padding-right: 5px;
`

const StyledUl = styled.ul`
  background-color: #ffffff;
  border: 1px solid #cbd3da;
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  li:focus {
    background-color: #e9ecef;
  }
  li:hover {
    background-color: #e9ecef;
  }
`

const StyledPortal = styled.div`
  position: absolute;
  ${props => {
    const { bottom } = props
    if (bottom) return `bottom: ${Math.round(props.bottom)}px;`
    return ""
  }}
  ${props => {
    const { top } = props
    if (top) return `top: ${Math.round(props.top)}px;`
    return ""
  }}
  ${props => {
    const { max } = props
    if (max) return `max-height: ${Math.round(props.max)}px;`
    return ""
  }}
  overflow: auto;
  left: ${props => `${Math.round(props.left)}px`};
  background-color: ${props => props.theme?.colors?.background3};
  z-index: 500;
`

const CompletionPortal = props => {
  const el = document.querySelector("#root")
  return createPortal(props.children, el)
}

const filterCompletion =
  (cellId, sessionId) =>
  ({ content, parent_header }) => {
    const { metadata = {} } = parent_header || {}
    if (metadata.cellId !== cellId) return false
    if (metadata.sessionId !== sessionId) return false
    return Boolean(content.matches)
  }

const AutoCompleteOption = props => {
  const { opt, onBlur, replace, selected, setSelected } = props
  const isSelected = opt === selected
  const wasSelected = useRef(false)
  const li = useRef()
  useEffect(() => {
    if (isSelected !== wasSelected.current) {
      li.current.focus()
    }
  }, [isSelected, opt, selected])

  const handleFocus = useCallback(() => {
    setSelected(opt)
  }, [opt, setSelected])

  return (
    <StyledLi
      ref={li}
      tabIndex="0"
      onBlur={onBlur}
      onClick={replace}
      onFocus={handleFocus}
    >
      {opt}
    </StyledLi>
  )
}

const AutoCompleteList = props => {
  const { options, close, replace, selected, setSelected } = props
  const ref = useRef()
  const alterSelection = useCallback(
    change => {
      const selectedIndex = options.findIndex(opt => opt === selected)
      const newIndex = selectedIndex + change
      switch (true) {
        case newIndex + 1 > options.length:
          return setSelected(options[0])
        case newIndex < 0:
          return setSelected(options[options.length - 1])
        default:
          return setSelected(options[newIndex])
      }
    },
    [options, selected, setSelected]
  )

  const handleBlur = useCallback(
    e => {
      if (!ref.current.contains(e.relatedTarget)) {
        e.preventDefault()
        close()
      }
    },
    [close]
  )

  const handleTab = useCallback(
    direction => e => {
      e.preventDefault()
      e.stopPropagation()
      alterSelection(direction)
    },
    [alterSelection]
  )

  useHotkeys("enter", replace, {}, [selected])
  useHotkeys("esc,backspace", e => close(e, true), {}, [])
  useHotkeys("up", () => alterSelection(-1), {}, [alterSelection])
  useHotkeys("down", () => alterSelection(1), {}, [alterSelection])
  useHotkeys("ctrl+space", handleTab(1), {}, [handleTab])

  return (
    <StyledUl ref={ref}>
      {options.map((opt, idx) => {
        return (
          <AutoCompleteOption
            key={opt}
            opt={opt}
            idx={idx}
            replace={replace}
            selected={selected}
            setSelected={setSelected}
            onBlur={handleBlur}
          />
        )
      })}
    </StyledUl>
  )
}

const AutoComplete = props => {
  const { top, max, left, bottom, cellId, sessionId, codeMirror } = props
  const { doc } = codeMirror || {}
  const [options, setOptions] = useState(null)
  const [selected, setSelected] = useState("")

  const close = useCallback(
    (e, noChange = false) => {
      const { display } = codeMirror
      if (noChange) {
        const code = codeMirror.doc.getSelection()
        codeMirror.doc.replaceSelection(code)
      }

      display.input.textarea.focus()
      setOptions(null)
      setSelected("")
      if (e) e.preventDefault()
    },
    [codeMirror]
  )

  const replace = useCallback(
    e => {
      const code = doc.getSelection()
      const replacement = code === "." ? `.${selected}` : selected
      doc.replaceSelection(replacement)
      close(e)
    },
    [close, doc, selected]
  )

  const handleMessage = useCallback(({ content }) => {
    setOptions(content.matches)
    setSelected(content.matches[0] || "")
  }, [])

  useCommSubscription({
    handleMessage,
    channel: "shell",
    filter: filterCompletion(cellId, sessionId),
  })

  useEffect(() => {
    if (!options) return
    const notebookEl = document.querySelector("#notebook-container")
    const scrollDiv = notebookEl.firstChild
    const handleScroll = e => {
      close(e, true)
    }
    scrollDiv.addEventListener("scroll", handleScroll)
    return () => scrollDiv.removeEventListener("scroll", handleScroll)
  }, [close, options])

  if (!options || !options.length) return null

  return (
    <CompletionPortal>
      <StyledPortal top={top} max={max} left={left} bottom={bottom}>
        <AutoCompleteList
          close={close}
          options={options}
          replace={replace}
          selected={selected}
          setSelected={setSelected}
        />
      </StyledPortal>
    </CompletionPortal>
  )
}

export default AutoComplete
