import React, { useMemo, createContext } from "react"

export const ErrorLoggerContext = createContext()

const ErrorLoggerProvider = ({ children, initLogger }) => {
  const ctx = useMemo(
    () => ({
      logger: initLogger(),
    }),
    [initLogger]
  )

  return (
    <ErrorLoggerContext.Provider value={ctx}>
      {children}
    </ErrorLoggerContext.Provider>
  )
}

export default ErrorLoggerProvider
