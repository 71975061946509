import gql from "graphql-tag"

export const SIGNIN = gql`
  mutation SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password)
  }
`

export const SIGN_OUT = gql`
  mutation SignOut {
    signOut
  }
`

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $password: String!
    $passwordConfirm: String!
    $token: String!
    $email: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirm: $passwordConfirm
      token: $token
      email: $email
    )
  }
`

export const REFRESH_AUTH = gql`
  mutation RefreshAuth($jwt: String!) {
    refreshAuth(jwt: $jwt)
  }
`
