import React, { useRef, useCallback, useMemo } from "react"
import dayjs from "dayjs"
import { DatePicker as AntDatePicker } from "antd"

import { defaultDtFormatter } from "../../../lib/format"
import { useLocalFormFieldControls } from "../../hooks"
import { withFormFieldWrapper } from "../../FormFieldWrapper"
import {
  isRelativeDt,
  deconstructRelativeDt,
  computeRelativeDt,
} from "../../../lib/datetime"

const presetConfigs = [
  {
    label: "Today",
    value: "P0D",
  },
  {
    label: "1 Hour Ago",
    value: "P-1H",
  },
  {
    label: "12 Hours Ago",
    value: "P-12H",
  },
  {
    label: "24 Hours Ago",
    value: "P-1D",
  },
  {
    label: "7 Days Ago",
    value: "P-7D",
  },
  {
    label: "1 Month Ago",
    value: "P-1M",
  },
]

const relativeDtFormatter = value => {
  const [quantity, timeUnit] = deconstructRelativeDt(value)
  if (!timeUnit) return "Invalid format"

  const isPast = quantity < 0
  const absQuantity = Math.abs(quantity)

  let timeUnitLabel
  switch (timeUnit) {
    case "hour":
      timeUnitLabel = absQuantity === 1 ? "Hour" : "Hours"
      break
    case "day":
      timeUnitLabel = absQuantity === 1 ? "Day" : "Days"
      break
    case "month":
      timeUnitLabel = absQuantity === 1 ? "Month" : "Months"
      break
    case "year":
      timeUnitLabel = absQuantity === 1 ? "Year" : "Years"
      break
    default:
      return "Invalid time unit"
  }

  if (quantity === 0) return "Today"
  if (isPast) return `${absQuantity} ${timeUnitLabel} Ago`
  return `In ${absQuantity} ${timeUnitLabel}`
}

const formatter = (metadata, presetSelected) => value => {
  const { presetsAreRelative } = metadata
  // if a preset is selected, show the preset label
  if (presetSelected.current && presetsAreRelative) {
    return relativeDtFormatter(presetSelected.current)
  }
  return defaultDtFormatter(metadata, value)
}

const getDateString = (date, picker) => {
  // TODO: add support for year, week and quarter
  switch (picker) {
    case "month":
      return date.utc(true).startOf(picker).toISOString()
    default:
      // convert date to utc and save in ISO 1806 format ('2019-01-25T02:00:00.000Z')
      return date.utc(true).toISOString()
  }
}

const DatePickerCore = props => {
  const { schema = {}, onChange: _onChange, value: _value, ...rest } = props
  const { nullable, metadata = {} } = schema
  const { showPresets, presetsAreRelative, picker } = metadata
  const [value, onChange] = useLocalFormFieldControls(_value, _onChange)
  const presetSelected = useRef(isRelativeDt(value) ? value : null)

  // create presets from presetConfigs and compute the relative datetime value
  // so that when a preset is selected, the date picker will use the actual datetime value internally,
  // and the preset value will be saved to the form
  const presets = useMemo(() => {
    return presetConfigs.map(({ label, value }) => ({
      label,
      value: () => {
        if (presetsAreRelative) {
          presetSelected.current = value
        }
        return computeRelativeDt(value)
      },
    }))
  }, [presetsAreRelative])

  const handleChange = useCallback(
    date => {
      if (!date) return onChange(null)

      // convert date to utc and save in ISO 1806 format ('2019-01-25T02:00:00.000Z')
      const dateString = getDateString(date, picker)

      // when a preset is selected, save the preset value rather than the datetime value
      if (presetsAreRelative && isRelativeDt(presetSelected.current)) {
        /**
         * Since the date picker does not indicate whether the value is a preset or not, we need to
         * compute the relative datetime value to determine if the value is a preset or not. If the
         * value is not a preset, then we need to reset the presetSelected ref to false so that the
         * date picker will use the actual datetime value internally.
         */
        const computedDt = computeRelativeDt(presetSelected.current)
        // check if dates are the same down to the second
        if (!date.isSame(computedDt, "second")) {
          presetSelected.current = false
          onChange(dateString)
          return
        }
        onChange(presetSelected.current)
      } else {
        onChange(dateString)
      }
    },
    [presetsAreRelative, picker, onChange]
  )

  const datetimeValue = useMemo(() => {
    if (!value) return null
    if (isRelativeDt(value)) return computeRelativeDt(value)
    return dayjs.utc(value)
  }, [value])

  return (
    <AntDatePicker
      picker={picker}
      allowClear={nullable}
      value={datetimeValue}
      onChange={handleChange}
      presets={showPresets ? presets : []}
      format={formatter(schema.metadata, presetSelected)}
      {...rest}
    />
  )
}

const DatePicker = withFormFieldWrapper(DatePickerCore)
DatePicker.Core = DatePickerCore
export default DatePicker
