import React, { useState, useRef } from "react"
import { Popover, Row, Col, Layout, Button } from "antd"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { NavLink, useHistory } from "react-router-dom"
import {
  faHdd,
  faCogs,
  faDatabase,
  faChartLine,
  faProjectDiagram,
} from "@fortawesome/free-solid-svg-icons"

import {
  Form,
  Input,
  BigLetter,
  AuthBoundary,
  useFormState,
  GET_CUSTOMERS,
  useCurrentCustomer,
} from "@dbai/ui-staples"

const SideNavContainer = styled(Layout.Sider)`
  && {
    top: 0;
    left: 0;
    bottom: 0;
    height: 100vh;
    padding-top: 64px;
    overflow: auto;
    position: fixed;
    background: ${props => props.theme.header};
  }
`

const NavList = styled.ul`
  padding: 10px 0;
  justify-content: center;
`

const NavItem = styled.li`
  position: relative;
  width: 80px;
  margin-top: 5px;
  font-weight: bold;
  padding: 0;
  align-items: flex-start;
  text-align: center;
  color: #fff;
  font-size: 11px;
  margin-bottom: 10px;
`

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #fff;
  &:hover {
    text-decoration: none;
    color: #d1b800;
  }
  &.active {
    text-decoration: none;
    color: #d1b800;
  }
`

const WorkflowIcon = styled.div`
  transform: scaleX(-1);
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 5px;
`

const NavIcon = styled.div`
  margin-top: 5px;
`

const NewCustomerItem = styled.div`
  &&& {
    display: block;
    text-align: center;
    width: 100%;
  }
`

const CustomerButton = props => {
  const searchRef = useRef(null)
  const { name } = props
  const [open, setOpen] = useState(false)
  const history = useHistory()

  const { data, loading } = useQuery(GET_CUSTOMERS, {
    fetchPolicy: "cache-and-network",
  })

  const handleOpenChange = newOpen => {
    setOpen(newOpen)
  }

  const closePopover = () => {
    setOpen(false)
  }

  if (loading || data?.customers?.length < 2) return null

  return (
    <Popover
      placement="rightBottom"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      afterOpenChange={() => searchRef.current?.focus()}
      content={
        <div
          style={{
            width: "300px",
            maxHeight: "calc(100vh - 150px)",
            overflowY: "scroll",
          }}
        >
          <Form initialState={{ search: "" }} onSubmit={() => {}} hideSubmit>
            <Row gutter={[0, 16]}>
              <AuthBoundary subject="customers" action="LIST">
                <NewCustomerItem className="btn-group">
                  <Button
                    type="default"
                    size="small"
                    style={{ marginRight: "5px" }}
                    onClick={() => {
                      history.push("/admin/customers")
                      closePopover()
                    }}
                  >
                    View All Customers
                  </Button>
                  <AuthBoundary subject="customers" action="CREATE">
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => {
                        history.push("/admin/customers/new")
                        closePopover()
                      }}
                    >
                      Create Customer
                    </Button>
                  </AuthBoundary>
                </NewCustomerItem>
              </AuthBoundary>
              <Col span={24}>
                <Input
                  ref={searchRef}
                  name="search"
                  placeholder="Start Typing..."
                  autoComplete="off"
                  noWrapper
                />
              </Col>
              <Col span={24}>
                <CustomerList data={data} closePopover={closePopover} />
              </Col>
            </Row>
          </Form>
        </div>
      }
    >
      <NavItem>
        <BigLetter word={name || "?"} />
      </NavItem>
    </Popover>
  )
}

const CustomerNavWrapper = props => {
  if (props.cname) return props.children
  return null
}

const linkToCustomer =
  closePopover =>
  ({ id, name, normalizedName }) => (
    <Col span={24} key={id}>
      <NavLink
        to={`/${normalizedName}`}
        className="dropout-link"
        onClick={closePopover}
      >
        {name}
      </NavLink>
    </Col>
  )

const CustomerList = props => {
  const { data, closePopover } = props
  const { search } = useFormState()
  const sortByName = (a, b) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()
    if (nameA < nameB) return -1
    if (nameA > nameB) return 1
    return 0
  }

  if (!data) return null

  const filter = c => c.name.toLowerCase().startsWith(search.toLowerCase())

  return data.customers
    .filter(filter)
    .sort(sortByName)
    .map(linkToCustomer(closePopover))
}

const SideNav = props => {
  const [customer] = useCurrentCustomer()
  const { normalizedName: cname, name } = customer || {}

  return (
    <SideNavContainer width="95px">
      <NavList className="nav nav-items">
        <CustomerButton name={name} />
        <CustomerNavWrapper cname={cname}>
          <AuthBoundary subject="datalake" action="LIST">
            <NavItem>
              <StyledNavLink
                to={`/${cname}/datalake`}
                activeClassName="active"
                className="nav-link"
              >
                <NavIcon>
                  <FontAwesomeIcon icon={faHdd} size="2x" />
                </NavIcon>
                DATA LAKE
              </StyledNavLink>
            </NavItem>
          </AuthBoundary>

          <AuthBoundary subject="workflows" action="LIST">
            <NavItem>
              <StyledNavLink
                to={`/${cname}/workflows`}
                activeClassName="active"
              >
                <WorkflowIcon>
                  <FontAwesomeIcon
                    icon={faProjectDiagram}
                    size="2x"
                    transform={{ rotate: 90 }}
                    className="workflow-icon"
                  />
                </WorkflowIcon>
                WORKFLOW
              </StyledNavLink>
            </NavItem>
          </AuthBoundary>

          <AuthBoundary subject="datasets" action="LIST">
            <NavItem>
              <StyledNavLink to={`/${cname}/datasets`} activeClassName="active">
                <NavIcon>
                  <FontAwesomeIcon icon={faDatabase} size="2x" />
                </NavIcon>
                DATASETS
              </StyledNavLink>
            </NavItem>
          </AuthBoundary>

          <AuthBoundary subject="apps" action="LIST">
            <NavItem>
              <StyledNavLink to={`/${cname}/apps`} activeClassName="active">
                <NavIcon>
                  <FontAwesomeIcon icon={faChartLine} size="2x" />
                </NavIcon>
                APPS
              </StyledNavLink>
            </NavItem>
          </AuthBoundary>

          <AuthBoundary subject="users" action="LIST">
            <NavItem>
              <StyledNavLink to={`/${cname}/settings`} activeClassName="active">
                <NavIcon>
                  <FontAwesomeIcon icon={faCogs} size="2x" />
                </NavIcon>
                SETTINGS
              </StyledNavLink>
            </NavItem>
          </AuthBoundary>
        </CustomerNavWrapper>
      </NavList>
    </SideNavContainer>
  )
}

export default SideNav
