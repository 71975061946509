import { ajv } from "./ajv"

export const schemaCache = new Map()

const shouldResolveSchema = schema => {
  return (
    schema.$ref ||
    schema.items?.$ref ||
    schema.dependencies ||
    schema.allOf ||
    schema.if
  )
}

const hashString = str => {
  if (!str) return ""
  var hash = 0,
    i,
    chr
  if (str.length === 0) return hash
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

const removeEmptyValues = data => {
  if (typeof data === "object" && !Array.isArray(data) && data !== null) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if ([null, undefined].includes(value)) return acc
      return { ...acc, [key]: value }
    }, {})
  }
  return data
}

export const getResolvedSchema = (schema, rootSchema, value) => {
  // prevent null or undefined values from resolving schema conditions
  const data = removeEmptyValues(value)

  const schemaHash = hashString(JSON.stringify(schema))
  const valueHash = hashString(JSON.stringify(data))
  const cacheKey = `${schemaHash}-${valueHash}`
  if (schemaCache.has(cacheKey)) {
    return schemaCache.get(cacheKey)
  }
  let result = schema
  if (shouldResolveSchema(schema)) {
    result = ajv.retrieveSchema(schema, rootSchema, data)
  }

  schemaCache.set(cacheKey, result)
  return result
}
