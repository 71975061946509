import React, { useReducer, useCallback, useRef } from "react"
import { produce } from "immer"
import PropTypes from "prop-types"
import { get, set, partial } from "lodash"
import { Button } from "antd"

import FormContext from "./FormContext"
import AutoSave from "./AutoSave"
import {
  formSet,
  formAppend,
  formRemove,
  formClear,
  DBAI_SET,
  DBAI_APPEND,
  DBAI_CLEAR,
} from "./actions"

const defaultState = {}

const composeReducers = (...reducers) => {
  return (prevState, action) => {
    return reducers.reduce((newState, reducer, index) => {
      if (typeof reducer === "undefined") {
        throw new TypeError(
          `An undefined reducer was passed in at index ${index}`
        )
      }

      return reducer(newState, action)
    }, prevState)
  }
}

// The baseFormReducer is a curried Immer producer.
const baseFormReducer = produce((draft, action) => {
  switch (action.type) {
    case DBAI_SET:
      set(draft, action.name, action.value)
      break
    case DBAI_APPEND:
      const old = get(draft, action.name) || []

      // Object.assign makes a copy of the action.value to prevent it from
      // being frozen by Immer.
      const newArray = [...old, Object.assign({}, action.value)]
      set(draft, action.name, newArray)
      break
    case DBAI_CLEAR:
      return {}
    default:
      return
  }
})

const wrapSubmit = (submit, state) => evt => {
  if (evt.preventDefault) {
    evt.preventDefault()
  }

  return submit(state)
}

const FormBody = props => {
  const { name, children, hideSubmit = false, submitText = "SAVE" } = props

  return (
    <>
      {children}
      {!hideSubmit && (
        <Button form={name} type="primary" htmlType="submit">
          {submitText}
        </Button>
      )}
    </>
  )
}

const withLocalReducer = Component => props => {
  const { clientReducer, initialState = defaultState } = props

  const fullReducer = clientReducer
    ? composeReducers(baseFormReducer, clientReducer)
    : baseFormReducer

  const [state, dispatch] = useReducer(fullReducer, initialState)
  const stateForHook = useRef(null)
  stateForHook.current = state

  // Mimic redux-thunk, run actions if they are functions.
  const wrappedDispatch = useCallback(
    action => {
      return typeof action === "function"
        ? action(dispatch, () => stateForHook.current)
        : dispatch(action)
    },
    [dispatch]
  )

  return (
    <Component
      state={state}
      dispatch={wrappedDispatch}
      actions={null}
      {...props}
    />
  )
}

const ControlledForm = props => {
  const {
    name,
    state,
    actions,
    children,
    dispatch,
    className,
    allowNested,
    handleSubmit,
  } = props

  const submit = handleSubmit(state)
  const ctxValue = [state, dispatch, { name, actions, submit }]

  return (
    <FormContext.Provider value={ctxValue}>
      <form id={name} className={className} onSubmit={submit}>
        {!allowNested && <FormBody {...props}>{children}</FormBody>}
      </form>
      {allowNested && <FormBody {...props}>{children}</FormBody>}
    </FormContext.Provider>
  )
}

const UncontrolledForm = withLocalReducer(ControlledForm)

const Form = props => {
  const { onSubmit, state, dispatch } = props
  const handleSubmit = partial(wrapSubmit, onSubmit)

  if (state && dispatch) {
    return <ControlledForm handleSubmit={handleSubmit} {...props} />
  }

  return <UncontrolledForm handleSubmit={handleSubmit} {...props} />
}

Form.AutoSave = AutoSave

// A custom validator to ensure that we either have both dispatch and state,
// or neither of them.
const checkForDispatchAndState = (
  { dispatch, state },
  propName,
  componentName
) => {
  if (Boolean(dispatch) !== Boolean(state)) {
    const messageHeader = `${componentName} Validation failed`
    const messageBody =
      "Either both 'dispatch' and 'state' must be defined, or neither."
    const messageDetails = `dispatch: ${dispatch} state: ${state}`
    return new Error([messageHeader, messageBody, messageDetails].join("\n"))
  }
}

Form.propTypes = {
  name: PropTypes.string,
  onSubmit: PropTypes.func,
  actions: PropTypes.object,
  hideSubmit: PropTypes.bool,
  allowNested: PropTypes.bool,
  submitText: PropTypes.string,
  initialState: PropTypes.object,
  state: checkForDispatchAndState,
  dispatch: checkForDispatchAndState,
}

export default Form
export {
  Form,
  FormContext,
  formSet,
  formAppend,
  formRemove,
  composeReducers,
  formClear,
}
