import React from "react"
import { Drawer } from "antd"
import styled from "styled-components"

import useClickAwayHandler from "../hooks/useClickAwayHandler"

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    transition: all 0.5 ease;
    .ant-drawer-body {
      transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
      height: 100%;
      margin: 0;
      display: flex;
      flex-flow: column;
      height: 100%;
      background-color: ${props => props.theme.neutral};
      overflow-y: auto;
    }
  }
`

const SidePane = props => {
  const {
    children,
    isVisible,
    mask = false,
    width = "60vw",
    onCloseClicked,
    closeOnClickAway = false,
    ...rest
  } = props

  const ref = useClickAwayHandler(
    !mask && closeOnClickAway ? onCloseClicked : () => {}
  )

  return (
    <StyledDrawer
      mask={mask}
      size="large"
      width={width}
      zIndex={1001}
      open={isVisible}
      destroyOnClose={true}
      onClose={onCloseClicked}
      {...rest}
    >
      <div ref={ref}>{children}</div>
    </StyledDrawer>
  )
}

export default SidePane
