import { useState, useCallback } from "react"
import useClickAwayHandler from "./useClickAwayHandler"

// useDropout will control the state and position of the Dropout component
// relative to it's parent. Pass a `direction` prop to control the direction
// of the Dropout; some combination of up, down, left, or right. The first
// directive will determine where the dropdown is positioned relative to the
// parent, and the second will determine the direction the Dropout extends in.
// E.g. `left down` will put the box to the left of the parent (aligned with
// the top) and extend down as long as needed.
const useDropout = (opts = {}) => {
  const {
    onOpen,
    onClose,
    direction = "right",
    offsetX = "0px",
    offsetY = "0px",
  } = opts

  const [open, setOpen] = useState(false)

  const close = useCallback(() => setOpen(false), [setOpen])
  const ref = useClickAwayHandler(close)

  const position = { offsetX, offsetY }
  const onClick = e => e.stopPropagation()
  return {
    props: { open, position, direction, onClick },
    trigger: ref,
    open: () => {
      onOpen && onOpen()
      setOpen(true)
    },
    close: () => {
      onClose && onClose()
      setOpen(false)
    },
    toggle: () => {
      const action = open ? onClose : onOpen
      action && action()
      setOpen(!open)
    },
  }
}

export default useDropout
