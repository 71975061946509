const genericShapeSchema = {
  metadata: {
    layout: { gutter: [16] },
    sections: {
      shapeSettings: {
        title: "Position & Size",
        properties: ["rotation", "height", "width", "x", "y"],
      },
    },
  },
  properties: {
    id: {
      title: "ID",
      type: "string",
      metadata: {
        hidden: true,
        defaultValueSource: "uid",
      },
    },
    type: {
      title: "Type",
      type: "string",
      enum: ["richText", "datasetColumn", "circle", "rect", "star", "image"],
      metadata: {
        labels: [
          "Rich Text",
          "Dataset Column",
          "Circle",
          "Box",
          "Star",
          "Image",
        ],
      },
    },
    x: {
      title: "Horizontal Position",
      type: "number",
      default: 20,
      metadata: {
        layout: { xs: 24, md: 12 },
      },
    },
    y: {
      title: "Vertical Position",
      type: "number",
      default: 20,
      metadata: {
        layout: { xs: 24, md: 12 },
      },
    },
    rotation: {
      title: "Rotation",
      type: ["number", "null"],
      nullable: true,
      metadata: {
        layout: { span: 12 },
        fieldProps: {
          placeholder: "None",
        },
      },
    },
  },
}

export default genericShapeSchema
