import React from "react"
import { Table } from "antd"

import { dateSorter } from "@dbai/tool-box"
import { DateFormatter } from "@dbai/ui-staples"

const paginationOptions = { hideOnSinglePage: true }

const tableColumns = [
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
  },
  {
    key: "description",
    title: "Description",
    dataIndex: "description",
  },
  {
    key: "createdAt",
    title: "Created",
    dataIndex: "createdAt",
    defaultSortOrder: "descend",
    sorter: dateSorter("createdAt"),
    render: (_, row) => {
      return (
        <DateFormatter value={row.createdAt} format={"MM/DD/YYYY h:mm:ss a"} />
      )
    },
  },
]

const PublishHistory = props => {
  const { loading, appSnapshots } = props
  return (
    <Table
      size="small"
      loading={loading}
      columns={tableColumns}
      bordered
      dataSource={appSnapshots}
      pagination={paginationOptions}
    />
  )
}

export default PublishHistory
