// REF: https://github.com/rjsf-team/react-jsonschema-form/blob/main/packages/validator-ajv8/src/validator.ts

import Ajv from "ajv"
import { retrieveSchema, getDefaultFormState } from "@rjsf/utils"

import dictionary from "../schemas/dictionary"
import { getSafeErrors } from "./validations"

const ROOT_SCHEMA_PREFIX = "__dbai_rootSchema"

const isValidCache = new Map()
const compileCache = new Map()

const deepCleanseArray = arr => {
  return arr.reduce((acc, item) => {
    if ([null, undefined].includes(item)) return acc
    const cleansedItem = deepCleanse(item)
    return [...acc, cleansedItem]
  }, [])
}

const falsyValues = [null, undefined, "", [], {}]
const deepCleanseObj = obj => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (falsyValues.includes(value)) return acc
    const cleansedValue = deepCleanse(value)
    return { ...acc, [key]: cleansedValue }
  }, {})
}

const deepCleanse = data => {
  switch (true) {
    case !data:
      return data
    case Array.isArray(data):
      return deepCleanseArray(data)
    case typeof data === "object":
      return deepCleanseObj(data)
    default:
      return data
  }
}

class AJVValidator {
  constructor() {
    this.ajv = new Ajv({
      esm: true,
      $data: true,
      strict: false,
      verbose: true,
      allErrors: true,
      removeAdditional: true,
    })
  }

  /** Takes a `node` object and transforms any contained `$ref` node variables with a prefix, recursively calling
   * `withIdRefPrefix` for any other elements.
   *
   * @param node - The object node to which a ROOT_SCHEMA_PREFIX is added when a REF_KEY is part of it
   * @private
   */
  withIdRefPrefixObject(node) {
    for (const key in node) {
      const realObj = node
      const value = realObj[key]
      if (
        key === "$ref" &&
        typeof value === "string" &&
        value.startsWith("#")
      ) {
        realObj[key] = ROOT_SCHEMA_PREFIX + value
      } else {
        realObj[key] = this.withIdRefPrefix(value)
      }
    }
    return node
  }

  /** Takes a `node` object list and transforms any contained `$ref` node variables with a prefix, recursively calling
   * `withIdRefPrefix` for any other elements.
   *
   * @param nodeThe - list of object nodes to which a ROOT_SCHEMA_PREFIX is added when a REF_KEY is part of it
   * @private
   */
  withIdRefPrefixArray = node => {
    for (let i = 0; i < node.length; i++) {
      node[i] = this.withIdRefPrefix(node[i])
    }
    return node
  }

  /** Validates data against a schema, returning true if the data is valid, or
   * false otherwise. If the schema is invalid, then this function will return
   * false.
   *
   * @param schema - The schema against which to validate the form data   * @param schema
   * @param formData- - The form data to validate
   * @param rootSchema - The root schema used to provide $ref resolutions
   */
  isValid(schema, formData, rootSchema) {
    // Performance issue from here: https://github.com/rjsf-team/react-jsonschema-form/issues/1961#issuecomment-1113179218
    let validator
    if (rootSchema.ajvId) {
      validator = isValidCache.get(rootSchema.ajvId)

      if (!validator) {
        validator = this.ajv.addSchema(rootSchema, ROOT_SCHEMA_PREFIX)

        isValidCache.set(rootSchema.ajvId, validator)
      }
    } else {
      validator = this.ajv.addSchema(rootSchema, ROOT_SCHEMA_PREFIX)
    }

    try {
      // add the rootSchema ROOT_SCHEMA_PREFIX as id.
      // then rewrite the schema ref's to point to the rootSchema
      // this accounts for the case where schema have references to models
      // that lives in the rootSchema but not in the schema in question.
      const result = validator.validate(this.withIdRefPrefix(schema), formData)
      return result
    } catch (e) {
      return false
    } finally {
      // make sure we remove the rootSchema from the global ajv instance
      this.ajv.removeSchema(ROOT_SCHEMA_PREFIX)
    }
  }

  /** This function processes the `formData` with an optional user contributed `customValidate` function, which receives
   * the form data and a `errorHandler` function that will be used to add custom validation errors for each field. Also
   * supports a `transformErrors` function that will take the raw AJV validation errors, prior to custom validation and
   * transform them in what ever way it chooses.
   *
   * @param formData - The form data to validate
   * @param schema - The schema against which to validate the form data
   * @param [customValidate] - An optional function that is used to perform custom validation
   * @param [transformErrors] - An optional function that is used to transform errors after AJV validation
   */
  validateFormData = (formData, schema) => {
    // Include form data with undefined values, which is required for validation.
    let validationError = null
    try {
      this.ajv.validate(schema, formData)
    } catch (err) {
      validationError = err
    }
    return { errors: validationError || [] }
  }

  /** Recursively prefixes all $ref's in a schema with `ROOT_SCHEMA_PREFIX`
   * This is used in isValid to make references to the rootSchema
   *
   * @param schemaNode - The object node to which a ROOT_SCHEMA_PREFIX is added when a REF_KEY is part of it
   * @protected
   */
  withIdRefPrefix = schemaNode => {
    if (schemaNode.constructor === Object) {
      return this.withIdRefPrefixObject({ ...schemaNode })
    }
    if (Array.isArray(schemaNode)) {
      return this.withIdRefPrefixArray([...schemaNode])
    }
    return schemaNode
  }

  // compile will add default values to form data and validate the form data with relevant schema
  compile = async (schema, formData) => {
    let validator
    const cleansedSchema = JSON.parse(JSON.stringify(schema))
    const { $defs = {} } = cleansedSchema
    if (cleansedSchema.ajvId) {
      validator = compileCache.get(cleansedSchema.ajvId)

      if (!validator) {
        validator = this.ajv.compile({
          ...cleansedSchema,
          $defs: { ...dictionary, ...$defs },
        })

        compileCache.set(cleansedSchema.ajvId, validator)
      }
    } else {
      validator = this.ajv.compile({
        ...cleansedSchema,
        $defs: { ...dictionary, ...$defs },
      })
    }
    // validate cleansed data since AJV treats `null` values as real values.
    const cleansedData = deepCleanse(structuredClone(formData))
    await validator(cleansedData)
    return getSafeErrors(validator.errors)
  }

  retrieveSchema = (schema, rootSchema, formData) => {
    const { $defs = {} } = rootSchema || schema
    return retrieveSchema(
      this,
      schema,
      { ...schema, $defs: { ...dictionary, ...$defs } },
      formData
    )
  }

  getDefaultFormState = (schema, formData) => {
    const { $defs = {} } = schema
    return getDefaultFormState(this, schema, formData, {
      ...schema,
      $defs: { ...dictionary, ...$defs },
    })
  }

  // TODO: Finish this
  // This function returns the data representation of a schema. This is useful for
  // visualizing how the data will be structured.
  // schemaToObject = (schema, rootSchema, formData) => {
  //   if (!schema || typeof schema !== "object") return {}

  //   const resolvedSchema = ajv.retrieveSchema(schema, rootSchema, formData)

  //   let shape = {}

  //   if (schema.allOf) {
  //     schema.allOf.forEach(subSchema => {
  //       const subShape = this.schemaToObject(subSchema, rootSchema, formData)
  //       shape = { ...shape, ...subShape }
  //     })
  //   }

  //   // Handle the 'if', 'then', and 'else' conditions
  //   if (schema.if && schema.then) {
  //     const thenShape = this.schemaToObject(schema.then, rootSchema, formData)
  //     shape = { ...shape, ...thenShape }
  //   }

  //   if (schema.if && schema.else) {
  //     const elseShape = this.schemaToObject(schema.else, rootSchema, formData)
  //     shape = { ...shape, ...elseShape }
  //   }

  //   // Handle the 'dependencies'
  //   if (schema.dependencies) {
  //     Object.values(schema.dependencies).forEach(depSchema => {
  //       const depShape = this.schemaToObject(depSchema, rootSchema, formData)
  //       shape = { ...shape, ...depShape }
  //     })
  //   }

  //   if (resolvedSchema.type === "object" && resolvedSchema.properties) {
  //     shape = Object.keys(resolvedSchema.properties).reduce((obj, key) => {
  //       const property = resolvedSchema.properties[key]
  //       if (property && typeof property === "object") {
  //         obj[key] = this.schemaToObject(property, rootSchema, formData)
  //       }
  //       return obj
  //     }, {})
  //   } else if (resolvedSchema.type === "array" && resolvedSchema.items) {
  //     shape = [this.schemaToObject(resolvedSchema.items, rootSchema, formData)]
  //   } else {
  //     return resolvedSchema.type || "unknown"
  //   }

  //   return shape
  // }
}

const ajv = new AJVValidator()
export { ajv }
