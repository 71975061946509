import React, { useState } from "react"
import { Row, Col } from "antd"
import styled from "styled-components"
import { Panel, PanelGroup } from "react-resizable-panels"

import TreeView from "./TreeView"
import { useWidgetContext } from "../hooks"
import PanelTitle from "./shared/PanelTitle"
import WidgetPreview from "./shared/WidgetPreview"
import PanelResizeHandle from "./PanelResizeHandle"
import FormFieldWrapper from "../JSONSchemaForm/FormFieldWrapper"
import { useFormData, useEditingWidgetSchema } from "../JSONSchemaForm/hooks"

const PreviewContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  flex-flow: column nowrap;
`

const ScrollableDiv = styled.div`
  overflow-y: auto;
  height: calc(100vh - 106px);
`

const TreeViewSection = props => {
  const { setSelected } = props
  const schema = useEditingWidgetSchema()
  return (
    <>
      <PanelTitle level={3}>Widget</PanelTitle>
      <ScrollableDiv>
        <TreeView
          path=""
          schema={schema}
          schemaKey="."
          onSelect={setSelected}
        />
      </ScrollableDiv>
    </>
  )
}

const TypedWidgetEditor = props => {
  const {
    selected,
    Previewer,
    topContent,
    setSelected,
    leftContent,
    middleContent,
    previewProps = {},
    colStyles = { height: `calc(100vh - 65px)` },
  } = props
  const type = useFormData("type")
  const { widgetRegistry } = useWidgetContext()
  const { metadata } = widgetRegistry[type]?.schema || {}
  const { hideTree, hidePreviewer } = metadata || {}
  return (
    <Row gutter={gutter}>
      {topContent ? (
        <Col style={colStyles} xs={24}>
          {topContent}
        </Col>
      ) : null}
      <Col span={24}>
        <PanelGroup direction="horizontal" autoSaveId="widget-editor-panels">
          {hideTree ? null : (
            <>
              <Panel order={1} collapsible minSize={10} defaultSize={20}>
                {leftContent ?? <TreeViewSection setSelected={setSelected} />}
              </Panel>
              <PanelResizeHandle />
            </>
          )}
          <Panel order={2} minSize={15} collapsible>
            {middleContent ?? <TreeView.Form selectedTreeNode={selected} />}
          </Panel>
          {hidePreviewer ? null : (
            <>
              <PanelResizeHandle />
              <Panel order={3} defaultSize={50} minSize={20}>
                {Previewer ? (
                  <FormFieldWrapper noStyle path="" schemaKey=".">
                    <Previewer {...previewProps} />
                  </FormFieldWrapper>
                ) : (
                  <StandardEditorPreviewer {...previewProps} />
                )}
              </Panel>
            </>
          )}
        </PanelGroup>
      </Col>
    </Row>
  )
}

const StandardEditorPreviewer = props => {
  return (
    <PreviewContent>
      <FormFieldWrapper noStyle path="" schemaKey=".">
        <WidgetPreview {...props} />
      </FormFieldWrapper>
    </PreviewContent>
  )
}

const gutter = []
const StandardEditor = props => {
  const {
    topContent,
    middleContent,
    colStyles = { height: `calc(100vh - 65px)` },
  } = props
  const widgetType = useFormData("type")
  const [selected, setSelected] = useState()

  if (Boolean(widgetType)) {
    return (
      <TypedWidgetEditor
        {...props}
        selected={selected}
        setSelected={setSelected}
      />
    )
  }

  return (
    <Row gutter={gutter}>
      {topContent ? (
        <Col style={colStyles} xs={24}>
          {topContent}
        </Col>
      ) : null}
      <Col span={24}>
        {middleContent ?? (
          <>
            <PanelTitle level={3}>Add New Widget</PanelTitle>
            <TreeView.Form selectedTreeNode={selected} showTitle={false} />
          </>
        )}
      </Col>
    </Row>
  )
}

StandardEditor.Previewer = StandardEditorPreviewer
export default StandardEditor
