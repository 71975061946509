import { flatten } from "lodash"
import { createSelector } from "reselect"

import { determineNodeStatus } from "lib/utils"

const isScriptNode = node => node?.type === "script"
const isComponentNode = node => node?.type === "component"
const outputHasError = output => output?.output_type === "error"
const cellHasError = cell => cell?.outputs?.some(outputHasError)
const nodeHasError = node => node?.cells?.some(cellHasError)

export const selectSpec = state => state?.notebook?.workflow?.spec
export const selectNodes = createSelector(selectSpec, spec => spec?.nodes)
export const selectEdges = createSelector(selectSpec, spec => spec?.edges)
export const selectNotebook = state => state.notebook?.notebook
export const selectWorkflow = state => state.notebook?.workflow
export const selectAutoScroll = createSelector(
  selectNotebook,
  notebook => notebook.autoScroll
)
export const selectDisableMoveCursor = createSelector(
  selectNotebook,
  notebook => notebook.disableMoveCursor
)
export const selectClonableNodes = createSelector(selectNodes, nodes => {
  return nodes
    .filter(n => n.type === "script")
    .map(n => ({
      label: n.data.label,
      value: { spec: n },
    }))
})
export const selectLayout = createSelector(
  selectNotebook,
  notebook => notebook?.layout
)
export const selectIsLoading = state => state.notebook?.isLoading
export const selectHistoryPanelOpen = createSelector(
  selectNotebook,
  notebook => notebook?.historyPanelOpen
)
export const selectFocused = createSelector(
  selectNotebook,
  notebook => notebook?.focused
)
export const selectCellStatuses = createSelector(
  selectNotebook,
  notebook => notebook?.cellStatuses
)
export const selectMode = createSelector(
  selectNotebook,
  notebook => notebook?.mode
)
export const selectFocusedNode = createSelector(
  selectNodes,
  selectFocused,
  (nodes, focused) => {
    return nodes.find(node => node.id === focused.node) || null
  }
)
export const selectFocusedNodeType = createSelector(
  selectFocusedNode,
  node => node?.type || null
)
export const selectFocusedCell = createSelector(
  selectFocused,
  selectFocusedNode,
  (focused, node) => {
    return node?.cells[focused.cellIdx] || null
  }
)
export const selectCells = createSelector(selectNodes, nodes => {
  return flatten(
    nodes?.map(node => {
      if (!isScriptNode(node) && !isComponentNode(node)) return []
      return node.cells || []
    }) || []
  )
})

/*
 * Selects all of the cells in the spec but also adds information about the
 * parent node to the returned array. It's return value is in the following
 * shape:
 * []{ cell, parentData: { id, nodeIdx }
 */
export const selectCellsWithNodeData = createSelector(selectNodes, nodes => {
  return flatten(
    nodes?.map((node, nodeIdx) => {
      if (!isScriptNode(node) && !isComponentNode(node)) return []
      return (
        node.cells?.map((cell, cellIdx) => {
          return {
            cell,
            parentData: { id: node.id, nodeIdx, cellIdx },
          }
        }) || []
      )
    }) || []
  )
})

export const selectCellErrorUuids = createSelector(selectCells, cells => {
  return cells.reduce((acc, next) => {
    if (cellHasError(next)) {
      return [...acc, next.uuid]
    }
    return acc
  }, [])
})

export const selectNodeErrorIds = createSelector(
  selectNodes,
  selectCellErrorUuids,
  (nodes, cellErrors) => {
    return nodes.reduce((acc, next) => {
      if (isScriptNode(next)) {
        return {
          ...acc,
          [next.id]: nodeHasError(next),
        }
      }
      return acc
    }, {})
  }
)

export const selectNodeStatuses = createSelector(
  selectNodes,
  selectCellStatuses,
  selectNodeErrorIds,
  (nodes, cellStatuses, erroredNodeIds) => {
    return nodes.reduce((acc, next) => {
      return {
        ...acc,
        [next.id]: determineNodeStatus(next, cellStatuses, erroredNodeIds),
      }
    }, {})
  }
)

export const selectNodeStatus = createSelector(
  selectNodes,
  selectCellStatuses,
  selectNodeErrorIds,
  (_, { nodeId }) => nodeId,
  (nodes, cellStatuses, erroredNodeIds, nodeId) => {
    const node = nodes.find(node => node.id === nodeId)
    return determineNodeStatus(node, cellStatuses, erroredNodeIds)
  }
)
