import React from "react"
import { useSelector } from "react-redux"

import Select from "../../shared/Select"
import { getSelectMode } from "../../utils"
import { selectAppSpec } from "../../../selectors/app"
import { withFormFieldWrapper } from "../../FormFieldWrapper"

const EndpointSelectCore = props => {
  const { schema } = props
  const { endpoints } = useSelector(selectAppSpec)
  const options = endpoints?.map(v => ({
    value: v.id,
    label: v.name,
  }))
  const mode = getSelectMode(schema)
  return <Select options={options} mode={mode} {...props} />
}

const EndpointSelect = withFormFieldWrapper(EndpointSelectCore)
EndpointSelect.Core = EndpointSelectCore
export default EndpointSelect
