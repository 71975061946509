import { useState, useEffect } from "react"

/*
 * Allows a consuming component to track it's visibility in the viewport. If
 * `preserve` is defined the hook will always return true after the first time
 * that the component has entered the viewport. `observerOptions` will be
 * passed to the created observer. For details on what options can be passed
 * please reference:
 * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
 */
const useIsOnScreen = (ref, { preserve, observerOptions = {} }) => {
  const [isIntersecting, setIntersecting] = useState(false)
  const [wasRendered, setWasRendered] = useState(false)
  // The verbose version of this causes jest not to complain.
  const intersectionObserverSupported =
    typeof IntersectionObserver !== "undefined"

  const observer =
    intersectionObserverSupported &&
    new IntersectionObserver(([entry]) => {
      if (preserve && entry.isIntersecting) return setWasRendered(true)
      setIntersecting(entry.isIntersecting)
    })

  useEffect(() => {
    if (!ref.current || !observer) return
    observer.observe(ref.current, observerOptions)
    return () => {
      observer.disconnect()
    }
  })

  // If we are either in IE or jest where this interface is not supported,
  // return true.
  if (!intersectionObserverSupported) return true

  return wasRendered || isIntersecting
}

export default useIsOnScreen
