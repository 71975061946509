import React, { useCallback } from "react"
import { DatePicker } from "antd"
import styled from "styled-components"

import { useFormState, useFormSet, Input } from "@dbai/ui-staples"

const useValue = (path, defaultValue = "") => {
  return useFormState(path, defaultValue)
}
const useSetNumber = name => {
  const set = useFormSet()
  return useCallback(
    value => {
      set({ name, value: Number(value) })
    },
    [name, set]
  )
}

const NumberInput = props => {
  const { name, format = e => Number(e?.target?.value), displayName } = props
  const value = useValue(name)
  const set = useSetNumber(name)
  const handleChange = e => {
    const val = format(e)
    return set(val)
  }

  return (
    <Input
      type="number"
      name={name}
      value={value}
      onChange={handleChange}
      displayName={displayName}
    />
  )
}

export const StringInput = props => {
  const { name, displayName } = props
  const value = useValue(name)

  return (
    <Input type="string" name={name} value={value} displayName={displayName} />
  )
}

export const IntegerInput = props => {
  return <NumberInput step={1} {...props} />
}

export const Float64Input = props => {
  return <NumberInput step={0.01} {...props} />
}

export const DoubleInput = props => {
  return <NumberInput step="any" {...props} />
}

const StyledCheckbox = styled(Input)`
  height: 20px;
  width: 20px;
  margin-top: 8px;
`

export const BooleanInput = props => {
  const { name, displayName } = props
  const value = useValue(name, false)
  const set = useFormSet()
  const handleChange = () => {
    return set({ name, value: !value })
  }

  return (
    <StyledCheckbox
      type="checkbox"
      step="any"
      name={name}
      value={value}
      onChange={handleChange}
      displayName={displayName}
    />
  )
}

export const DateTimeInput = props => {
  return <DatePicker {...props} />
}

export const BinaryInput = props => {
  const format = e => e.target.value?.replace(/[2-9a-zA-Z]/g, "")

  return <NumberInput format={format} {...props} />
}
