import React, { useState, useEffect, useRef } from "react"
import { resizeObserverFactory } from "@dbai/tool-box"
import { useCallback } from "react"

const withWidthProvider = Comp => props => {
  const { innerRef, ...rest } = props
  const childRef = useRef()
  const [width, setWidth] = useState(1280)

  const onResize = useCallback(() => {
    const node = innerRef.current || childRef.current
    if (node instanceof HTMLElement && node.offsetWidth) {
      setWidth(node.offsetWidth)
    }
  }, [innerRef])

  useEffect(() => {
    if (!innerRef.current && !childRef.current) return null
    const observer = resizeObserverFactory(onResize)
    observer.observe(innerRef.current || childRef.current)
    return () => {
      observer.disconnect()
    }
  }, [innerRef, onResize])

  return <Comp innerRef={innerRef || childRef} {...rest} width={width} />
}

export default withWidthProvider
