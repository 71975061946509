import React, { useState, useMemo, useCallback } from "react"
import { Menu } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { get } from "lodash"
import styled from "styled-components"
import {
  faClock,
  faCamera,
  faColumns,
  faTerminal,
  faProjectDiagram,
  faFileExport,
  faChartArea,
  faClipboard,
  faPlay,
  faHistory,
  faCube,
  faArrowAltCircleDown,
  faArrowAltCircleRight,
  faKeyboard,
  faArrowsFromLine,
  faArrowsToLine,
} from "@fortawesome/pro-solid-svg-icons"

import { FAMemo } from "@dbai/ui-staples"

import CommentsPanel from "./CommentsPanel"
import HistoryButton from "./HistoryButton"
import SnapshotButton from "./SnapshotButton"
import ExecutePackage from "./ExecutePackage"
import WorkflowHistory from "./WorkflowHistory"
import AutoScrollButton from "./AutoScrollButton"
import DisableMoveCursorButton from "./DisableMoveCursorButton"
import { actions } from "reducers/notebookReducer"
import WorkflowHelpButton from "./WorkflowHelpButton"
import ResourceUsageButton from "./ResourceUsageButton"
import PackageWorkflowButton from "./PackageWorkflowButton"
import { exportNotebook } from "./ExportNotebookOption/export"
import { useLayoutOption } from "./LayoutOption"

const StyledMenu = styled(Menu)`
  width: 24rem;
  border: 0px;
  background: ${props => props?.theme?.colors?.background};
`

/*
 * Due to AntD's inability to track Menu.Items that are "active" when the
 * `Menu.Item` in question is the top level componenent rendered in another
 * component, we can not easily use self contained SidePane components and
 * make the entire `Menu.Item` clickable due to an inversion of control. This
 * means that we have to maintain the entirety of the open/closed panels'
 * states _above_ our menu instead of seperating it all into the consuming
 * component code.
 */
const keys = [
  "export",
  "package",
  "execute",
  "comments",
  "snapshot",
  "workflow",
  "resources",
  "priorRuns",
  "keyMappings",
].reduce((acc, next) => ({ ...acc, [next]: next }), {})

const stopMaybeAntEvent = questionableEvent => {
  if (!questionableEvent) return
  if (questionableEvent.domEvent) {
    return questionableEvent.domEvent.stopPropagation()
  }

  if (questionableEvent.stopPropagation) questionableEvent.stopPropagation()
}

const menuStyles = {
  background: "transparent",
}

const WorkflowMenu = props => {
  const { customerId, normalizedName, workflowId } = props
  const nodes = useSelector(state =>
    get(state?.notebook, "workflow.spec.nodes")
  )
  const dispatch = useDispatch()
  const [currentKey, setCurrentKey] = useState(null)
  const openHistory = useCallback(
    () => dispatch(actions.setHistoryPanelOpen()),
    [dispatch]
  )
  const toggleAutoScroll = useCallback(
    () => dispatch(actions.toggleAutoScroll()),
    [dispatch]
  )
  const toggleDisableMoveCursor = useCallback(
    () => dispatch(actions.toggleDisableMoveCursor()),
    [dispatch]
  )
  const closePanel = useCallback(e => {
    stopMaybeAntEvent(e)
    setCurrentKey(null)
  }, [])
  const openKey = useCallback(antEvent => {
    antEvent.domEvent.stopPropagation()
    setCurrentKey(antEvent.key)
  }, [])

  const setAllCollapsed = useCallback(
    collapsed => {
      dispatch(actions.pauseAutosave())
      const indexes = nodes.reduce(
        (pre, n, idx) => ({ [n.id]: idx, ...pre }),
        {}
      )
      nodes.forEach(node => {
        dispatch(
          actions.updateNodeField({
            nodeIdx: indexes[node.id],
            field: "collapsed",
            value: collapsed,
          })
        )
      })
      dispatch(actions.unpauseAutosave())
    },
    [dispatch, nodes]
  )

  const selectCodeLayout = useLayoutOption("code")
  const selectBothLayout = useLayoutOption("both")
  const selectGraphLayout = useLayoutOption("graph")

  const items = useMemo(() => {
    return [
      {
        key: "workflow",
        label: "Workflow",
        children: [
          {
            key: "export",
            label: "Export IPYNB",
            onClick: exportNotebook,
            icon: <FAMemo icon={faFileExport} />,
          },
          {
            key: keys.resources,
            onClick: openKey,
            icon: <FAMemo icon={faChartArea} />,
            label: "Resources",
          },
          {
            key: keys.snapshot,
            onClick: openKey,
            icon: <FAMemo icon={faCamera} />,
            label: "Take Snapshot",
          },
          {
            key: keys.history,
            onClick: openHistory,
            icon: <FAMemo icon={faClock} />,
            label: <HistoryButton />,
          },
          {
            key: keys.comments,
            onClick: openKey,
            icon: <FAMemo icon={faClipboard} />,
            label: "Comments",
          },
        ],
      },
      {
        key: "packaging",
        label: "Packaging",
        children: [
          {
            key: keys.execute,
            onClick: openKey,
            icon: <FAMemo icon={faPlay} />,
            label: <span id="execute-package">Execute</span>,
          },
          {
            key: keys.package,
            onClick: openKey,
            icon: <FAMemo icon={faCube} />,
            label: "Package",
          },
          {
            key: keys.priorRuns,
            onClick: openKey,
            icon: <FAMemo icon={faHistory} />,
            label: "History",
          },
        ],
      },
      {
        key: "view",
        label: "View",
        children: [
          {
            key: "code-and-graph",
            label: "Code And Graph",
            onClick: selectBothLayout,
            icon: <FAMemo icon={faColumns} />,
          },
          {
            key: "code-only",
            label: "Code Only",
            onClick: selectCodeLayout,
            icon: <FAMemo icon={faTerminal} />,
          },
          {
            key: "graph-only",
            label: "Graph Only",
            onClick: selectGraphLayout,
            icon: <FAMemo icon={faProjectDiagram} />,
          },
          {
            key: "autoscroll",
            label: <AutoScrollButton />,
            onClick: toggleAutoScroll,
            icon: <FAMemo icon={faArrowAltCircleDown} />,
          },
          {
            key: "disable-move-cursor",
            label: <DisableMoveCursorButton />,
            onClick: toggleDisableMoveCursor,
            icon: <FAMemo icon={faArrowAltCircleRight} />
          },
          {
            key: "collapse-all",
            onClick: () => setAllCollapsed(true),
            icon: <FAMemo icon={faArrowsToLine} />,
            label: "Collapse All Nodes",
          },
          {
            key: "expand-all",
            onClick: () => setAllCollapsed(false),
            icon: <FAMemo icon={faArrowsFromLine} />,
            label: "Expand All Nodes",
          },
        ],
      },
      {
        key: keys.keyMappings,
        onClick: openKey,
        icon: <FAMemo icon={faKeyboard} />,
        label: (
          <WorkflowHelpButton
            closePanel={closePanel}
            panelOpen={keys.keyMappings === currentKey}
          />
        ),
      },
    ]
  }, [
    openKey,
    closePanel,
    currentKey,
    openHistory,
    selectBothLayout,
    selectCodeLayout,
    toggleAutoScroll,
    toggleDisableMoveCursor,
    selectGraphLayout,
    setAllCollapsed,
  ])

  return (
    <>
      <ResourceUsageButton
        closePanel={closePanel}
        panelOpen={keys.resources === currentKey}
      />
      <SnapshotButton
        closePanel={closePanel}
        panelOpen={keys.snapshot === currentKey}
      />
      <CommentsPanel
        closePanel={closePanel}
        panelOpen={keys.comments === currentKey}
      />
      <ExecutePackage
        closePanel={closePanel}
        panelOpen={keys.execute === currentKey}
      />
      <PackageWorkflowButton
        customerId={customerId}
        workflowId={workflowId}
        closePanel={closePanel}
        normalizedName={normalizedName}
        panelOpen={keys.package === currentKey}
      />
      <WorkflowHistory
        id={workflowId}
        cname={normalizedName}
        closePanel={closePanel}
        panelOpen={keys.priorRuns === currentKey}
      />
      <StyledMenu
        items={items}
        mode="horizontal"
        selectable={false}
        style={menuStyles}
        triggerSubMenuAction="click"
      />
    </>
  )
}

export default WorkflowMenu
