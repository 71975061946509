import React, { useEffect } from "react"
import { Redirect } from "react-router-dom"
import { toast } from "react-toastify"
import { useMutation } from "@apollo/client"
import { set } from "lodash"

import { Form } from "."

const onSubmit = submit => state => {
  const newState = {}
  //handle nested objects.  This assumes the input name uses periods to separate nested keys
  Object.keys(state).forEach(key => {
    set(newState, key, state[key])
  })
  return submit({ variables: newState })
}

const ApiForm = props => {
  const {
    ignoreResults = false,
    refetchQueries,
    saveMessage,
    mutation,
    variables,
    afterRoute,
    afterSubmit,
    onCompleted,
    ...rest
  } = props

  const options = { ignoreResults, refetchQueries, onCompleted }
  const [submit, { error, loading, data }] = useMutation(mutation, options)

  useEffect(() => {
    if (error) {
      // TODO: Create better server side messages so we don't have to remove
      // 'GraphQL error:' from the error message string
      const errorMessage = error.message.replace("GraphQL error: ", "")

      toast.error(errorMessage, { autoClose: 8000 })
    }
    if (afterSubmit && (error || loading || data)) {
      afterSubmit({ error, loading, data })
    }
  })

  useEffect(() => {
    if (data && saveMessage) {
      toast.success(saveMessage, { autoClose: 2000 })
    }
  }, [data, saveMessage])

  if (data && afterRoute) {
    return <Redirect to={afterRoute} />
  }

  return (
    <Form {...rest} onSubmit={onSubmit(submit)}>
      {props.children}
    </Form>
  )
}

export default ApiForm
