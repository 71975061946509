import { gql } from "@apollo/client"

export const COLLECT_DATA = gql`
  mutation CollectData($customerId: Int!, $id: Int!, $input: CollectInput!) {
    collectData(customerId: $customerId, id: $id, input: $input) {
      rowsAffected
    }
  }
`

export const DELETE_DATA = gql`
  mutation DeleteData($cname: String!, $id: Int!, $filters: DeleteInput!) {
    deleteData(cname: $cname, id: $id, filters: $filters) {
      rowsAffected
    }
  }
`
