import { useMemo } from "react"

import useYjs from "./useYjs"

const useYjsUsers = () => {
  const yjsContext = useYjs()
  return useMemo(() => {
    return yjsContext.users || []
  }, [yjsContext])
}

export default useYjsUsers
