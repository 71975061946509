import { useMemo } from "react"
import { useQuery } from "@apollo/client"

import { GET_ME } from "../queries/users"
import { checkForDBAdmin } from "../lib/userHelpers"

/*
 * Wrap content with this component if you don't want it to show up outside of
 * Delta-Bravo.
 */
const useCheckForDBAdmin = () => {
  const { data } = useQuery(GET_ME)
  return useMemo(() => checkForDBAdmin(data?.me?.customers), [data])
}

export default useCheckForDBAdmin
