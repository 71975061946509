import React, { useState, useCallback, useMemo } from "react"

import BaseInput from "../BaseInput"
import DatePicker from "../DatePicker"
import Select from "../../shared/Select"
import SwitchInput from "../SwitchInput"
import NumberInput from "../NumberInput"
import FormFieldWrapper from "../../FormFieldWrapper"
import usePresetOptions from "../../hooks/usePresetOptions"
import {
  getMode,
  filterOption,
  getRenderValue,
  getSelectedValue,
} from "../../../lib/selectInputField"

const ColumnValuesSelect = props => {
  const {
    schema,
    onChange,
    parentName,
    parentSchema,
    isMultiSelect,
    value: _value,
    ...rest
  } = props
  const [open, setOpen] = useState(false)
  const { metadata, nullable } = schema
  const { allowInput, valuePresets } = metadata
  const mode = getMode(isMultiSelect, allowInput, valuePresets)

  const { loading, fetchMore, refetch, error, options, column } =
    usePresetOptions({
      schema,
      parentSchema,
      parentName,
      metadata: {
        ...schema.metadata,
        // setting showPresets to false will prevent the usePresetOptions hook from running.
        showPresets: !valuePresets || valuePresets !== "none",
      },
      fetchPolicy: "cache-and-network",
    })

  const onSearch = useCallback(
    value => {
      fetchMore({
        where: [{ column, cast: "text", op: "like", value: `${value}%` }],
      })
    },
    [fetchMore, column]
  )

  const handleChange = useCallback(
    value => {
      fetchMore({ where: [] })
      onChange(getSelectedValue(isMultiSelect, value))
    },
    [fetchMore, isMultiSelect, onChange]
  )

  const value = useMemo(() => {
    return getRenderValue(mode, _value)
  }, [mode, _value])

  const toggleOpen = useCallback(
    visible => {
      // if there are no presets, we don't want to show the dropdown
      if (valuePresets === "none") {
        setOpen(false)
        return
      }
      visible && refetch && refetch()
      setOpen(visible)
    },
    [valuePresets, refetch]
  )

  // if there are no presets, we don't want to show the dropdown arrow icon
  const suffixIcon = valuePresets === "none" ? null : undefined

  if (error) return null

  return (
    <Select
      showSearch
      open={open}
      mode={mode}
      value={value}
      options={options}
      loading={loading}
      onSearch={onSearch}
      allowClear={nullable}
      onChange={handleChange}
      suffixIcon={suffixIcon}
      filterOption={filterOption}
      onDropdownVisibleChange={toggleOpen}
      {...rest}
    />
  )
}

const ColumnValueInputCore = React.memo(props => {
  const { schema } = props
  const { metadata } = schema
  const { columnType, isMulti, valuePresets = "none" } = metadata
  const isMultiSelect = isMulti || schema.type === "array"

  if (isMultiSelect || valuePresets !== "none") {
    return <ColumnValuesSelect {...props} isMultiSelect={isMultiSelect} />
  }

  switch (columnType) {
    case "numerical":
    case "number":
    case "int":
    case "float":
      return <NumberInput.Core {...props} />
    case "boolean":
      return <SwitchInput.Core {...props} />
    case "datetime":
      return <DatePicker.Core {...props} />
    case "string":
    case "categorical":
    default:
      return <BaseInput.Core {...props} />
  }
})

const ColumnValueInput = props => {
  const { name, value } = props
  return (
    <FormFieldWrapper {...props}>
      <ColumnValueInputCore parentValue={value} parentName={name} />
    </FormFieldWrapper>
  )
}

ColumnValueInput.Core = ColumnValueInputCore
export default ColumnValueInput
