import React, { useMemo, useState, useCallback } from "react"
import { Cascader, Space } from "antd"
import { useQuery } from "@apollo/client"
import { useDispatch } from "react-redux"

import { GET_DATASET_COLUMNS } from "@dbai/ui-staples"

import { useWidgetContext } from "../../hooks"
import FormFieldWrapper from "../FormFieldWrapper"
import FieldTypeIcon from "../../shared/FieldTypeIcon"
import { GET_DATASETS } from "../../queries/datasets"
import { getDatasetColumns } from "../../hooks/useDatasetColumns"

const useDatasets = () => {
  const { customerId } = useWidgetContext()
  const { error, loading, data } = useQuery(GET_DATASETS, {
    variables: { customerId },
    fetchPolicy: "network-only",
  })

  return { error, loading, data }
}

const queryDatasetColumns =
  ({ allowedTypes, datasetId, cname }) =>
  (_, __, { client }) => {
    return client
      .query({
        query: GET_DATASET_COLUMNS,
        variables: { id: datasetId, cname },
      })
      .then(results => {
        return getDatasetColumns(results, allowedTypes)
      })
  }

const displayRender = (labels, selectedOptions) => {
  const [dataset, column] = labels
  return (
    <Space>
      {dataset ? (
        <span>
          <b>Dataset:</b> {dataset}
        </span>
      ) : null}
      {column ? (
        <>
          <span>/</span>
          <span>
            <b>Column:</b> {column}
          </span>
        </>
      ) : null}
    </Space>
  )
}

const DatasetAndColumnSelectCore = props => {
  const { onChange, value, schema } = props
  const [columns, setColumns] = useState([])
  const { cname } = useWidgetContext()
  const datasets = useDatasets()
  const dispatch = useDispatch()

  const cascaderValue = useMemo(() => {
    if (value?.datasetId && value?.column) {
      return [value.datasetId, value.column]
    }
    return value?.datasetId ? [value.datasetId] : []
  }, [value])

  const options = useMemo(() => {
    if (datasets.loading || datasets.error) return []
    return datasets.data.datasets.map(ds => {
      // only add columns to the selected dataset
      if (value?.datasetId === ds.id) {
        return {
          value: ds.id,
          isLeaf: false,
          label: ds.name,
          key: ds.id,
          children: columns.map(({ column, type }) => ({
            type,
            key: column,
            value: column,
            label: (
              <Space>
                <FieldTypeIcon type={type} />
                {column}
              </Space>
            ),
          })),
        }
      }
      return {
        value: ds.id,
        isLeaf: false,
        label: ds.name,
        key: ds.id,
      }
    })
  }, [datasets, columns, value])

  const loadColumns = async selectedOptions => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true
    const columns = await dispatch(
      queryDatasetColumns({
        cname,
        datasetId: targetOption.value,
        allowedTypes: schema.metadata?.allowedTypes,
      })
    )
    setColumns(await columns)
  }

  const handleChange = useCallback(
    (_, selectedValueObjects) => {
      const [dataset, column] = selectedValueObjects
      onChange({
        ...value,
        datasetId: dataset.value,
        column: column?.value,
        type: column?.type,
      })
    },
    [value, onChange]
  )

  if (datasets.error) return null
  return (
    <Cascader
      changeOnSelect
      options={options}
      value={cascaderValue}
      loadData={loadColumns}
      onChange={handleChange}
      loading={datasets.loading}
      displayRender={displayRender}
    />
  )
}

const DatasetAndColumnSelect = props => {
  return (
    <FormFieldWrapper {...props}>
      <DatasetAndColumnSelectCore />
    </FormFieldWrapper>
  )
}

DatasetAndColumnSelect.Core = DatasetAndColumnSelectCore
export default DatasetAndColumnSelect
