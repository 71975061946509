import { useRef, useMemo, useState, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { useSelector } from "react-redux"

import { GET_TIME_RANGE, GET_LABELS_FOR_COLUMN } from "@dbai/ui-staples"

import useWidgetContext from "./useWidgetContext"
import NullishWidget from "../Widget/NullishWidget"
import { selectAppBreakpoint } from "../selectors/app"
import {
  getLabelsConfigForColumn,
  findColumnConfigFromName,
} from "../lib/datasetColumns"

export const useTimeRange = ({
  column,
  datasetId,
  skip,
  onCompleted,
  cname,
}) => {
  const { data, loading } = useQuery(GET_TIME_RANGE, {
    variables: { cname, column, datasetId },
    skip: skip || !datasetId || !column,
    onCompleted: results => {
      onCompleted && onCompleted(results)
    },
  })

  return { loading, ...data?.customer?.dataset?.stats?.timeRange }
}

/*
 * Returns either the breakpoint manually set in the app or the breakpoint
 * that currently corresponds to the size of the viewport. Updates the return
 * value on resize events.
 */
export const useCurrentBreakpoint = () => {
  const breakpoint = useSelector(selectAppBreakpoint)
  return breakpoint
}

export const useRegisteredWigetSpec = widget => {
  const { widgetRegistry } = useWidgetContext()
  if (!widget?.type) return { Component: NullishWidget }
  return widgetRegistry[widget.type] ?? { Component: NullishWidget }
}

// If you need to inspect something on the app page, ctrl+right-click will
// ignore the context menu.
const ctrlWrapper = callback => e => {
  if (e.ctrlKey) return
  callback(e)
}

export const useContextMenu = callback => {
  const ref = useRef()
  const { editable } = useWidgetContext()
  useEffect(() => {
    if (!editable || !ref.current) return

    const container = ref.current
    const handler = ctrlWrapper(callback)
    container.addEventListener("contextmenu", handler)
    return () => {
      container.removeEventListener("contextmenu", handler)
    }
  }, [callback, editable])

  return ref
}

// This function returns a list of raw column labels merged with the label configs for a column
const mergeColumnConfigLabels = (column, labels, columnConfigs) => {
  const columnConfig = findColumnConfigFromName(column, columnConfigs) || {}
  return getLabelsConfigForColumn(columnConfig, labels)
}

export const useColumnLabels = (datasetId, column) => {
  const { cname } = useWidgetContext()
  const { data, loading, error } = useQuery(GET_LABELS_FOR_COLUMN, {
    skip: !datasetId || !cname || !column,
    variables: { id: datasetId, column, cname },
  })

  const labels = useMemo(() => {
    const rawLabels = data?.customer?.dataset?.meta?.labels || []
    const columnConfigs = data?.customer?.dataset?.meta?.datasetColumns || []
    return mergeColumnConfigLabels(column, rawLabels, columnConfigs)
  }, [data, column])

  return { loading, error, labels }
}

export const useHover = () => {
  const [value, setValue] = useState(false)
  const ref = useRef(null)
  const handleMouseOver = () => !value && setValue(true)
  const handleMouseOut = () => setValue(false)
  useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener("mouseover", handleMouseOver)
        node.addEventListener("mouseout", handleMouseOut)
        return () => {
          node.removeEventListener("mouseover", handleMouseOver)
          node.removeEventListener("mouseout", handleMouseOut)
        }
      }
    },
    [ref.current] // Recall only if ref changes
  )

  return [ref, value]
}

export { default as useDatasetData } from "./useDatasetData"
export { default as useWidgetContext } from "./useWidgetContext"

export { default as useYjs } from "./useYjs"
export { default as useYjsUsers } from "./useYjsUsers"
export { default as useYjsSelections } from "./useYjsSelections"
