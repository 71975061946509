import React from "react"
import styled from "styled-components"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import { Button } from "antd"

import {
  Card,
  Form,
  Input,
  useFormMutation,
  useCurrentCustomer,
} from "@dbai/ui-staples"

import { GET_WORKFLOWS, CLONE_WORKFLOW_FROM_SNAPSHOT } from "queries"

const ButtonGroup = styled.div`
  width: 100%;
`

const getPath = (cname, id) => `/${cname}/workflows/${id}`

const CloneSnapshotForm = props => {
  const [customer] = useCurrentCustomer()
  const { snapshotId, afterSubmit, onCancel } = props
  const { handleSubmit } = useFormMutation(CLONE_WORKFLOW_FROM_SNAPSHOT, {
    afterSubmit: data => {
      const safeData = data?.cloneWorkflowFromSnapshot || {}
      const newId = safeData.id
      const newName = safeData.name

      toast.success(
        <Link to={getPath(customer.normalizedName, newId)}>
          {newName} created
        </Link>
      )
      afterSubmit && afterSubmit()
    },
    onError: error => {
      toast.error(error?.message)
    },
    refetchQueries: [
      {
        query: GET_WORKFLOWS,
        variables: { cname: customer.normalizedName },
      },
    ],
  })

  const initialState = {
    name: "",
    snapshotId,
    customerId: customer.id,
  }

  const handleCancel = e => {
    e.preventDefault()
    e.stopPropagation()
    onCancel()
  }

  return (
    <Card>
      <Card.Header>Clone Into New Workflow</Card.Header>
      <Card.Body>
        <Form hideSubmit initialState={initialState} onSubmit={handleSubmit}>
          <Input name="name" />
          <ButtonGroup className="btn-group">
            <Button type="primary" htmlType="submit">
              CLONE
            </Button>
            <Button danger type="primary" onClick={handleCancel}>
              CANCEL
            </Button>
          </ButtonGroup>
        </Form>
      </Card.Body>
    </Card>
  )
}

export default CloneSnapshotForm
