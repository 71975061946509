import React from "react"
import { useMutation } from "@apollo/client"
import Dropzone from "react-dropzone-uploader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons"

import { withUser } from "@dbai/ui-staples"

import withCustomer from "components/shared/withCustomer"
import { UPDATE_UPLOAD_STATUS, CREATE_UPLOAD } from "queries"

import "react-dropzone-uploader/dist/styles.css"

const statusHandler =
  (customerId, updateStatus, refetch, data) =>
  ({ file, meta, remove }, status, files) => {
    if (!data) {
      return
    }

    const id = meta.uploadId

    switch (status) {
      case "uploading":
        files.forEach(file => {
          updateStatus({
            variables: {
              id: file.meta.uploadId,
              customerId: customerId,
              status: "UPLOADING",
            },
          }).then(() => refetch())
        })
        break
      case "done":
        updateStatus({
          variables: {
            id,
            customerId: customerId,
            status: status.toUpperCase(),
          },
        }).then(() => refetch())

        remove()
        break
      case "aborted":
        updateStatus({
          variables: {
            id,
            customerId: customerId,
            status: status.toUpperCase(),
          },
        }).then(() => refetch())

        break

      default:
    }
  }

const handleSubmit = (files, allFiles) => {
  allFiles.forEach(f => f.remove())
}

const InputContent = () => {
  return (
    <div key="drag-drop" className="upload-input-content">
      Click or Drag/Drop to Upload
      <br />
      <FontAwesomeIcon icon={faCloudUploadAlt} size="3x" />
    </div>
  )
}

const CustomerUpload = props => {
  const { customer, refetch, user } = props
  const [createUpload, { data }] = useMutation(CREATE_UPLOAD)
  const [updateStatus] = useMutation(UPDATE_UPLOAD_STATUS)

  const handleChangeStatus = statusHandler(
    customer.id,
    updateStatus,
    refetch,
    data
  )

  const getUploadParams = ({ file, meta }) =>
    createUpload({
      variables: {
        userId: user.id,
        size: meta.size,
        customerId: customer.id,
        originalFilename: meta.name,
      },
    }).then(results => ({
      method: "put",
      url: results.data.createUpload.urls.uploadUrl,
      uploadId: results.data.createUpload.id,
      meta: { uploadId: results.data.createUpload.id },
      body: file,
    }))

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      inputWithFilesContent="Add More Files"
      inputContent={InputContent}
      styles={{ dropzone: { minHeight: 250, maxHeight: 250 } }}
    />
  )
}

export default withCustomer(withUser(CustomerUpload))
