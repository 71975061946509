import { useMemo } from "react"
import { actions } from "reducers/scheduleReducer"
import { useDispatch } from "react-redux"

import { capitalize } from "lib/strings"

const collectActions = field => {
  const capped = capitalize(field)
  return {
    setStart: actions[`set${capped}Start`],
    setEnd: actions[`set${capped}End`],
    setStep: actions[`set${capped}Step`],
    remove: actions[`remove${capped}`],
  }
}

const useCronChangeHandlers = (idx, field) => {
  const dispatch = useDispatch()
  const actions = useMemo(() => collectActions(field), [field])

  return useMemo(() => {
    const handleStartChange = value =>
      dispatch(actions.setStart({ idx, start: value }))

    const handleEndChange = value =>
      dispatch(actions.setEnd({ idx, end: value }))

    const handleStepChange = value =>
      dispatch(actions.setStep({ idx, step: value }))

    const handleRemove = value => dispatch(actions.remove({ idx }))

    return {
      handleStartChange,
      handleEndChange,
      handleStepChange,
      handleRemove,
    }
  }, [dispatch, idx, actions])
}

export default useCronChangeHandlers
