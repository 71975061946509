const generateSchema = (options, appVariables) => {
  const { appVariableId } = options
  const appVariable = appVariables?.find(
    variable => variable.id === appVariableId
  )
  const title = options.title || appVariable?.name

  const valueSchema = {
    title,
    default: appVariable?.value,
    metadata: {
      appVariable: appVariable?.name,
    },
  }

  if (appVariable?.type === "number") {
    return {
      type: "number",
      ...valueSchema,
    }
  }

  if (appVariable?.type === "datetime") {
    return {
      type: "string",
      ...valueSchema,
      metadata: {
        appVariable: appVariable?.name,
        component: "DatePicker",
      },
    }
  }
  return {
    type: "string",
    ...valueSchema,
  }
}

export default generateSchema
