import backgroundColor from "../../schemas/shared/backgroundColor"

const schema = {
  type: "object",
  title: "Button",
  default: {},
  metadata: {
    fieldProps: {
      style: {
        padding: "0 16px",
      },
    },
  },
  dependencies: {
    clickActions: {
      if: {
        properties: {
          clickActions: {
            contains: { enum: ["triggerEndpoint"] },
          },
        },
      },
      then: {
        properties: {
          endpoints: {
            type: "array",
            title: "Trigger Endpoints",
            items: { type: "string" },
            metadata: {
              isMulti: true,
              component: "EndpointSelect",
            },
          },
        },
      },
    },
  },
  properties: {
    text: {
      type: "string",
      title: "Button Text",
      default: "Click Me",
    },
    buttonType: {
      type: "string",
      title: "Button Type",
      enum: ["default", "primary", "dashed", "link", "text"],
      default: "default",
      metadata: {
        labels: ["Default", "Primary", "Dashed", "Link", "Text"],
      },
    },
    shape: {
      type: "string",
      title: "Shape",
      enum: ["default", "circle", "round"],
      default: "default",
      metadata: {
        labels: ["Default", "Circle", "Round"],
      },
    },
    danger: {
      type: "boolean",
      title: "Danger",
      description: "Set the danger status of button",
    },
    ghost: {
      type: "boolean",
      title: "Ghost",
      description:
        "Make background transparent and invert text and border colors",
    },
    block: {
      default: true,
      type: "boolean",
      title: "Full Width",
    },
    fullHeight: {
      default: true,
      type: "boolean",
      title: "Full Height",
    },
    href: {
      type: "string",
      title: "Link",
      description: "Redirect URL",
    },
    backgroundColor: backgroundColor,
    clickActions: {
      type: "array",
      title: "Click Actions",
      metadata: {
        labels: ["Trigger Endpoint", "Clear Filters", "Logout"],
      },
      items: {
        type: "string",
        enum: ["triggerEndpoint", "clearFilters", "logout"],
      },
    },
    "style/fontSize": {
      type: "string",
      title: "Text Size",
      minimum: 2,
      maximum: 72,
      default: 12,
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "style/color": {
      type: ["string", "null"],
      title: "Text Color",
      nullable: true,
      metadata: {
        component: "ColorPicker",
      },
    },
  },
}

export default schema
