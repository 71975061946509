import { datetimeFormatOptionsSchema } from "../formatOptionsSchema"

import datasetColumnSchema, {
  numericalColumnSchema,
  datetimeColumnSchema,
  categoricalColumnSchema,
} from "../datasetColumnSchema"
import highchartsProperties from "./highchartsProperties"

const basicSchema = aggregateRequired => ({
  type: "object",
  title: "Data",
  default: {},
  properties: {
    inputZones: {
      metadata: {
        schemaName: "dependencies.datasetId.properties.inputZones",
        component: "ColumnInputZones",
        order: ["xAxisData", "yAxisData"],
        style: {
          height: "calc(100vh - 259px)",
        },
      },
      type: "object",
      default: {},
      properties: {
        xAxisData: {
          default: [],
          minItems: 1,
          maxItems: 1,
          type: "array",
          title: "X-Axis",
          uniqueItems: true,
          items: {
            type: "object",
            required: ["column"],
            metadata: {
              labelPath: "column",
              sections: {
                formatting: {
                  title: "Formatting",
                  properties: [
                    "formatRange",
                    "formatOptions/suffix",
                    "formatOptions/prefix",
                    "formatOptions/weekday",
                    "formatOptions/year",
                    "formatOptions/month",
                    "formatOptions/day",
                    "formatOptions/hour",
                    "formatOptions/minute",
                    "formatOptions/second",
                    "formatOptions/timeZone",
                    "formatOptions/timeZoneName",
                    "formatOptions/hour12",
                  ],
                },
              },
            },
            dependencies: {
              type: {
                allOf: [
                  {
                    if: {
                      properties: {
                        type: {
                          enum: ["datetime"],
                        },
                      },
                    },
                    then: {
                      dependencies: {
                        // only add datetime format options if format is datetime
                        format: {
                          allOf: [
                            {
                              if: {
                                properties: {
                                  format: {
                                    enum: ["datetime"],
                                  },
                                },
                              },
                              then: {
                                properties: {
                                  "formatOptions/suffix": {
                                    title: "Suffix",
                                    default: "",
                                    type: ["string", "null"],
                                    nullable: true,
                                    metadata: {
                                      layout: { span: 24 },
                                      fieldProps: {
                                        placeholder: "None",
                                      },
                                    },
                                  },
                                  "formatOptions/prefix": {
                                    title: "Prefix",
                                    default: "",
                                    type: ["string", "null"],
                                    nullable: true,
                                    metadata: {
                                      layout: { span: 24 },
                                      fieldProps: {
                                        placeholder: "None",
                                      },
                                    },
                                  },
                                  ...datetimeFormatOptionsSchema.properties,
                                },
                              },
                            },
                          ],
                        },
                        groupByTime: {
                          properties: {
                            formatRange: {
                              type: "boolean",
                              title: "Show as Range",
                              default: false,
                            },
                          },
                        },
                      },
                      properties: {
                        groupByTime: {
                          default: null,
                          nullable: true,
                          title: "Group By Time",
                          type: ["object", "null"],
                          required: ["groupByTime/interval"],
                          metadata: {
                            hideLabel: true,
                          },
                          properties: {
                            "groupByTime/unit": {
                              title: "Unit",
                              type: "string",
                              default: "DAY",
                              enum: [
                                "SECOND",
                                "MINUTE",
                                "HOUR",
                                "DAY",
                                "WEEK",
                                "MONTH",
                                "YEAR",
                              ],
                              metadata: {
                                labels: [
                                  "Second",
                                  "Minute",
                                  "Hour",
                                  "Day",
                                  "Week",
                                  "Month",
                                  "Year",
                                ],
                              },
                            },
                            "groupByTime/interval": {
                              default: 1,
                              minimum: 1,
                              type: "number",
                              title: "Interval",
                              description: `Specify an interval for the date/time grouping.  For example, if the unit is "Day" and you set the interval to "2", the data will be grouped by 2 day time periods.`,
                            },
                          },
                        },
                        ...datetimeColumnSchema.properties,
                      },
                    },
                  },
                  {
                    if: {
                      properties: {
                        type: {
                          enum: ["numerical", "float", "int"],
                        },
                      },
                    },
                    then: numericalColumnSchema,
                  },
                  {
                    if: {
                      properties: {
                        type: {
                          enum: ["string", "categorical"],
                        },
                      },
                    },
                    then: categoricalColumnSchema,
                  },
                ],
              },
            },
            properties: {
              ...datasetColumnSchema.properties,
              id: {
                title: "ID",
                type: "string",
                metadata: {
                  hidden: true,
                  defaultValueSource: "uid",
                },
              },
            },
          },
          metadata: {
            itemType: "collapse",
            noStyle: true,
            component: "DnDDropzone",
            fieldProps: {
              style: {
                minHeight: "100px",
              },
            },
          },
        },
        yAxisData: {
          minItems: 1,
          type: "array",
          maxItems: 500,
          title: "Y-Axis",
          uniqueItems: true,
          default: [],
          items: {
            default: {},
            type: "object",
            title: "Dataset Column",
            required: ["column", ...(aggregateRequired ? ["aggregate"] : [])],
            metadata: {
              order: ["type", "column", "aggregate"],
              layout: { gutter: [16] },
              labelPath: "column",
            },
            dependencies: datasetColumnSchema.dependencies,
            properties: {
              ...datasetColumnSchema.properties,
              id: {
                title: "ID",
                type: "string",
                metadata: {
                  hidden: true,
                  defaultValueSource: "uid",
                },
              },
            },
          },
          metadata: {
            itemType: "collapse",
            noStyle: true,
            layout: { gutter: [] },
            component: "DnDDropzone",
            allowedTypes: ["numerical", "float", "int", "computed"],
            fieldProps: {
              style: { minHeight: "100px" },
            },
          },
        },
        groupByData: {
          title: "Group By",
          type: "array",
          default: [],
          metadata: {
            noStyle: true,
            validateFirst: true,
            component: "DnDDropzone",
            allowedTypes: [
              "int",
              "float",
              "string",
              "numerical",
              "categorical",
            ],
            fieldProps: {
              style: {
                minHeight: "100px",
              },
            },
          },
          items: {
            type: "object",
            required: ["column"],
            properties: {
              column: {
                $ref: "#/$defs/dataColumn",
                title: "Column",
                metadata: {
                  allowedTypes: [
                    "int",
                    "float",
                    "string",
                    "numerical",
                    "categorical",
                  ],
                  component: "ColumnSelect",
                  hideLabel: true,
                  fieldProps: {
                    popupMatchSelectWidth: false,
                  },
                },
              },
              type: {
                $ref: "#/$defs/dataColumnType",
                metadata: { hidden: true },
              },
            },
          },
        },
        orderBy: {
          title: "Order By",
          type: "array",
          default: [],
          metadata: {
            noStyle: true,
            validateFirst: true,
            component: "DnDDropzone",
            allowedTypes: [
              "int",
              "float",
              "string",
              "numerical",
              "categorical",
            ],
            fieldProps: {
              style: {
                minHeight: "100px",
              },
            },
          },
          items: {
            type: "object",
            required: ["column", ...(aggregateRequired ? ["aggregate"] : [])],
            properties: {
              column: {
                $ref: "#/$defs/dataColumn",
                title: "Column",
                metadata: {
                  allowedTypes: [
                    "int",
                    "float",
                    "string",
                    "datetime",
                    "numerical",
                    "categorical",
                  ],
                  component: "ColumnSelect",
                  hideLabel: true,
                  fieldProps: {
                    popupMatchSelectWidth: false,
                  },
                },
              },
              type: {
                $ref: "#/$defs/dataColumnType",
                metadata: { hidden: true },
              },
              direction: {
                type: "string",
                enum: ["asc", "desc"],
                title: "Direction",
                metadata: { labels: ["Ascending", "Descending"] },
                default: "asc",
              },
            },
          },
        },
      },
    },
    seriesOptions: {
      type: "array",
      title: "Series",
      default: [],
      metadata: {
        hideLabel: true,
        hideAdd: true,
        draggable: false,
        removable: false,
        itemType: "collapse",
      },
      items: {
        type: "object",
        default: {},
        metadata: {
          labelPath: "name",
          sectionProps: {
            defaultActiveKey: [
              "general",
              "marker",
              "thresholds",
              "tooltip",
              "dataLabels",
            ],
          },
          sections: {
            general: {
              title: "General",
              properties: [
                "visible",
                "name",
                "type",
                "color",
                "dashStyle",
                "lineWidth",
              ],
            },
            marker: {
              title: "Marker",
              properties: ["marker"],
              tree: {
                enabled: false,
              },
            },
            thresholds: {
              title: "Threshold Zones",
              properties: ["thresholds"],
              tree: {
                enabled: false,
              },
            },
            tooltip: {
              title: "Tooltip",
              properties: ["tooltip"],
            },
            dataLabels: {
              title: "Data Labels",
              properties: ["dataLabels"],
            },
          },
        },
        properties: {
          seriesId: {
            type: "string",
            metadata: {
              hidden: true,
            },
          },
          computedSeriesName: {
            type: "string",
            metadata: {
              hidden: true,
            },
          },
          name: {
            type: ["string", "null"],
            title: "Series Name",
            nullable: true,
            description:
              "The name of the series, which will be displayed in the legend and the tooltip.",
            metadata: {
              placeholderSource: "computedSeriesName",
              fieldProps: {
                placeholder: "<Dataset Column Name>",
              },
            },
          },
          visible: {
            type: "boolean",
            title: "Visible",
            default: true,
          },
          type: {
            nullable: true,
            type: ["string", "null"],
            enum: [
              "line",
              "area",
              "column",
              "spline",
              "scatter",
              "pie",
              "boxplot",
              "heatmap",
              "bar",
            ],
            title: "Series Type",
            description:
              "The type of series to create, such as 'line', 'area', 'column', 'spline', 'scatter', 'pie', 'boxplot', 'heatmap' or 'bar'.",
            metadata: {
              fieldProps: {
                placeholder: "Auto",
              },
              labels: [
                "Line",
                "Area",
                "Column",
                "Spline",
                "Scatter",
                "Pie",
                "Boxplot",
                "Heatmap",
                "Bar",
              ],
            },
          },
          color: {
            nullable: true,
            type: ["string", "null"],
            title: "Series Color",
            description:
              "The color of the series, specified as a hexadecimal value or a named color.",
            metadata: {
              component: "ColorPicker",
            },
          },
          dashStyle: {
            type: "string",
            title: "Line Style",
            default: "Solid",
            enum: [
              "Solid",
              "ShortDash",
              "ShortDot",
              "ShortDashDot",
              "ShortDashDotDot",
              "Dot",
              "Dash",
              "LongDash",
              "DashDot",
              "LongDashDot",
              "LongDashDotDot",
            ],
            metadata: {
              labels: [
                "Solid",
                "Short Dash",
                "Short Dot",
                "Short Dash Dot",
                "Short Dash Dot Dot",
                "Dot",
                "Dash",
                "Long Dash",
                "Dash Dot",
                "Long Dash Dot",
                "Long Dash Dot Dot",
              ],
            },
          },
          lineWidth: {
            minimum: 0,
            maximum: 20,
            nullable: true,
            title: "Line Width",
            type: ["number", "null"],
            description:
              "The width of the line in a line chart, or the thickness of the columns in a column chart/",
            metadata: {
              showNumberInput: true,
              component: "SliderInput",
            },
          },
          marker: {
            type: "object",
            title: "Marker Options",
            default: {},
            description:
              "Options for the data points in the series, such as their shape, size, and color.",
            properties: {
              "marker/enabled": {
                default: true,
                type: "boolean",
                title: "Enabled",
              },
              "marker/symbol": {
                default: "circle",
                type: "string",
                title: "Shape",
                enum: [
                  "circle",
                  "square",
                  "diamond",
                  "triangle",
                  "triangle-down",
                ],
                metadata: {
                  labels: [
                    "Circle",
                    "Square",
                    "Diamond",
                    "Up Triangle",
                    "Down Triangle",
                  ],
                },
              },
              "marker/radius": {
                minimum: 0,
                maximum: 20,
                nullable: true,
                title: "Marker Size",
                type: ["number", "null"],
                description: "The radius of the marker.",
                metadata: {
                  showNumberInput: true,
                  component: "SliderInput",
                },
              },
              "marker/fillColor": {
                nullable: true,
                title: "Marker Color",
                type: ["string", "null"],
                description: "The fill color of the marker.",
                metadata: {
                  component: "ColorPicker",
                },
              },
              "marker/lineWidth": {
                minimum: 0,
                maximum: 20,
                nullable: true,
                type: ["number", "null"],
                title: "Marker Outline Width",
                metadata: {
                  showNumberInput: true,
                  component: "SliderInput",
                },
              },
              "marker/lineColor": {
                type: "string",
                title: "Marker Outline Color",
                description: "The color of the marker's outline.",
                metadata: {
                  component: "ColorPicker",
                },
              },
            },
          },
          thresholds: {
            type: "array",
            title: "Threshold Zones",
            default: [],
            metadata: {
              hideLabel: true,
              addButtonText: "ADD THRESHOLD",
              addButtonProps: { block: true },
            },
            items: {
              type: "object",
              title: "Threshold",
              metadata: {
                layout: { gutter: [16] },
                order: [
                  "direction",
                  "value",
                  "upperLimit",
                  "includeZone",
                  "color",
                  "dashStyle",
                  "plotLine",
                  "plotBand",
                ],
              },
              dependencies: {
                direction: {
                  if: {
                    properties: {
                      direction: {
                        enum: ["between", "outside"],
                      },
                    },
                  },
                  then: {
                    properties: {
                      value: {
                        default: 0,
                        type: "number",
                        title: "Lower Limit",
                      },
                      upperLimit: {
                        type: "number",
                        title: "Upper Limit",
                      },
                    },
                  },
                  else: {
                    properties: {
                      value: {
                        default: 0,
                        type: "number",
                        title: "Threshold Value",
                      },
                    },
                  },
                },
                includeZone: {
                  if: {
                    properties: {
                      includeZone: {
                        enum: [true],
                      },
                    },
                  },
                  then: {
                    properties: {
                      dashStyle: {
                        type: "string",
                        default: "Solid",
                        title: "Series Line Type",
                        description:
                          "The line type of the series when inside the threshold zone.",
                        enum: [
                          "Solid",
                          "Dash",
                          "Dot",
                          "DashDot",
                          "ShortDash",
                          "ShortDot",
                          "ShortDashDot",
                          "ShortDashDotDot",
                          "LongDash",
                          "LongDashDot",
                          "LongDashDotDot",
                        ],
                        metadata: {
                          labels: [
                            "Solid",
                            "Dashed",
                            "Dotted",
                            "Dash-Dot",
                            "Short Dash",
                            "Short Dot",
                            "Short Dash-Dot",
                            "Short Dash-Dot-Dot",
                            "Long Dash",
                            "Long Dash-Dot",
                            "Long Dash-Dot-Dot",
                          ],
                        },
                      },
                      color: {
                        type: "string",
                        title: "Series Color",
                        default: "#FF0000",
                        metadata: {
                          component: "ColorPicker",
                        },
                      },
                    },
                  },
                },
              },
              properties: {
                includeZone: {
                  type: "boolean",
                  default: false,
                  title: "Add Series Zone",
                },
                direction: {
                  type: "string",
                  default: "below",
                  title: "Direction",
                  enum: ["above", "below", "between", "outside"],
                  metadata: {
                    hideLabel: true,
                    component: "Segmented",
                    labels: ["Above", "Below", "Between", "Outside"],
                  },
                },
                plotLine: {
                  default: {},
                  type: "object",
                  title: "Plot Line Options",
                  metadata: {
                    hideLabel: false,
                    layout: { gutter: [16] },
                  },
                  dependencies: {
                    "plotOptions/showLine": {
                      if: {
                        properties: {
                          "plotOptions/showLine": {
                            enum: [true],
                          },
                        },
                      },
                      then: {
                        properties: {
                          "plotOptions/dashStyle": {
                            type: "string",
                            default: "Solid",
                            title: "Line Type",
                            enum: [
                              "Solid",
                              "Dash",
                              "Dot",
                              "DashDot",
                              "ShortDash",
                              "ShortDot",
                              "ShortDashDot",
                              "ShortDashDotDot",
                              "LongDash",
                              "LongDashDot",
                              "LongDashDotDot",
                            ],
                            metadata: {
                              labels: [
                                "Solid",
                                "Dashed",
                                "Dotted",
                                "Dash-Dot",
                                "Short Dash",
                                "Short Dot",
                                "Short Dash-Dot",
                                "Short Dash-Dot-Dot",
                                "Long Dash",
                                "Long Dash-Dot",
                                "Long Dash-Dot-Dot",
                              ],
                            },
                          },
                          "plotOptions/color": {
                            type: "string",
                            default: "#FF0000",
                            title: "Color",
                            metadata: {
                              component: "ColorPicker",
                            },
                          },
                          "plotOptions/width": {
                            nullable: true,
                            type: ["number", "null"],
                            title: "Line Width",
                          },
                        },
                      },
                    },
                  },
                  properties: {
                    "plotOptions/showLine": {
                      default: true,
                      type: "boolean",
                      title: "Show Line",
                    },
                  },
                },
                plotBand: {
                  default: {},
                  type: "object",
                  title: "Plot Band Options",
                  metadata: {
                    hideLabel: false,
                    layout: { gutter: [16] },
                  },
                  dependencies: {
                    "plotBand/showBand": {
                      if: {
                        properties: {
                          "plotBand/showBand": {
                            enum: [true],
                          },
                        },
                      },
                      then: {
                        properties: {
                          "plotBand/borderWidth": {
                            default: 0,
                            type: "number",
                            title: "Border Width",
                          },
                          "plotBand/color": {
                            type: "string",
                            default: "#fde9e9",
                            title: "Background Color",
                            metadata: {
                              component: "ColorPicker",
                            },
                          },
                          "plotBand/borderColor": {
                            type: "string",
                            default: "#FF0000",
                            title: "Border Color",
                            metadata: {
                              component: "ColorPicker",
                            },
                          },
                        },
                      },
                    },
                  },
                  properties: {
                    "plotBand/showBand": {
                      type: "boolean",
                      title: "Show Band",
                    },
                  },
                },
              },
            },
          },
          dataLabels: {
            default: {},
            type: "object",
            title: "Data Labels",
            // dependencies: formatOptionsSchema.dependencies,
            properties: {
              "dataLabels/enabled": {
                type: "boolean",
                title: "Enabled",
                default: false,
              },
              // ...formatOptionsSchema.properties,
            },
          },
        },
      },
    },
    highchart: {
      default: {},
      title: "Chart Options",
      type: "object",
      metadata: {
        sections: {
          title: {
            title: "Title",
            properties: [
              "title/align",
              "title/margin",
              "title/floating",
              "title/showTitle",
              "title/verticalAlign",
            ],
          },
          appearance: {
            title: "Appearance",
            properties: [
              "chart/backgroundColor",
              "chart/height",
              "chart/width",
              "chart/showTitle",
              "chart/zoomType",
              "chart/plotBackgroundColor",
              "chart/borderWidth",
              "chart/borderColor",
              "chart/plotBorderWidth",
              "chart/plotBorderColor",
              "chart/marginTop",
              "chart/marginRight",
              "chart/marginBottom",
              "chart/marginLeft",
              "chart/polar",
              "plotOptions/column/stacking",
            ],
          },
          legend: {
            title: "Legend",
            properties: [
              "legend/enabled",
              "legend/align",
              "legend/vertialAlign",
              "legend/layout",
              "legend/borderWidth",
              "legend/borderRadius",
              "legend/borderColor",
              "legend/verticalAlign",
              "legend/backgroundColor",
              "legend/itemStyle/color",
              "legend/itemStyle/fontWeight",
              "legend/itemStyle/fontFamily",
            ],
          },
          tooltip: {
            title: "Tooltip",
            properties: [
              "tooltip/useHTML",
              "tooltip/enabled",
              "tooltip/animation",
              "tooltip/shadow",
              "tooltip/split",
              "tooltip/backgroundColor",
              "tooltip/borderColor",
              "tooltip/borderRadius",
              "tooltip/borderWidth",
              "tooltip/style",
              "tooltip/valueDecimals",
              "tooltip/valuePrefix",
              "tooltip/valueSuffix",
            ],
          },
          xAxis: {
            title: "X-Axis",
            properties: [
              "xAxis/labels/useHTML",
              "xAxis/labels/enabled",
              "xAxis/labels/format",
              "xAxis/labels/align",
              "xAxis/labels/rotation",
              "xAxis/labels/padding",
              "xAxis/labels/style/fontSize",
              "xAxis/labels/style/color",
              "xAxis/title/text",
              "xAxis/title/align",
              "xAxis/title/margin",
              "xAxis/title/style/color",
              "xAxis/title/style/fontSize",
              "xAxis/crosshair/color",
              "xAxis/crosshair/width",
              "xAxis/crosshair/dashStyle",
              "xAxis/crosshair/zIndex",
              "xAxis/crosshair/snap",
              "xAxis/plotLines",
              "xAxis/opposite",
              "xAxis/reversed",
              "xAxis/min",
              "xAxis/max",
              "xAxis/categories",
            ],
          },
          yAxis: {
            title: "Y-Axis",
            properties: [
              "yAxis/labels/enabled",
              "yAxis/labels/format",
              "yAxis/labels/align",
              "yAxis/labels/rotation",
              "yAxis/labels/style/fontSize",
              "yAxis/labels/style/color",
              "yAxis/title/text",
              "yAxis/title/align",
              "yAxis/title/margin",
              "yAxis/title/style/color",
              "yAxis/title/style/fontSize",
              "yAxis/crosshair/color",
              "yAxis/crosshair/width",
              "yAxis/crosshair/dashStyle",
              "yAxis/crosshair/zIndex",
              "yAxis/crosshair/snap",
              "yAxis/plotLines",
              "yAxis/combineAxes",
              "yAxis/reversed",
              "yAxis/min",
              "yAxis/max",
            ],
          },
          format: {
            title: "Datetime Format",
            properties: [
              "datetimeFormat",
              "datetimeFormatStruct",
              "datetimeFormatPattern",
            ],
          },
          exporting: {
            title: "Exporting",
            properties: [
              "exporting/menuItems",
              "exporting/filename",
              "exporting/align",
              "exporting/verticalAlign",
            ],
          },
        },
      },
      properties: {
        ...highchartsProperties.title,
        ...highchartsProperties.tooltip,
        ...highchartsProperties.chart,
        "chart/polar": {
          type: "boolean",
          title: "Polar",
        },
        "chart/zoomType": highchartsProperties["chart/zoomType"],
        ...highchartsProperties.legend,
        ...highchartsProperties.xAxis,
        ...highchartsProperties.yAxis,
        ...highchartsProperties.exporting,
      },
    },
  },
})

export default basicSchema
