import React from "react"
import { Button } from "antd"
import FormFieldWrapper from "../FormFieldWrapper"

const EditImageButton = props => {
  return (
    <FormFieldWrapper {...props}>
      <Button onClick={() => tinymce.activeEditor.execCommand("mceImage")}>
        Edit Image
      </Button>
    </FormFieldWrapper>
  )
}

EditImageButton.Core = EditImageButton
export default EditImageButton
