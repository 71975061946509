import React from "react"
import { Typography, Space, Layout } from "antd"
import styled from "styled-components"
import { useSelector } from "react-redux"

import { selectAppSpec } from "./selectors/app"

const { Text } = Typography
const { Header } = Layout

const StyledText = styled(Text)`
  color: #fff;
`

const StyledHeader = styled(Header)`
  width: 100%;
  && {
    background: ${props => props.theme.header};
    padding: 0 1rem;
    position: sticky;
    top: 0;
    z-index: 2;
  }
`

const AppHeader = props => {
  const { enablePageHeader, title, logo } = useSelector(selectAppSpec)
  if (!enablePageHeader) return null
  return (
    <StyledHeader>
      <Space>
        {logo?.url ? <img src={logo?.url} className="logo" alt="logo" /> : null}
        <StyledText>{title}</StyledText>
      </Space>
    </StyledHeader>
  )
}

export default AppHeader
