import highchartsProperties from "./highchartsProperties"
import { boxplotNumericalSchema } from "../datasetColumnSchema"

const boxplotSchema = {
  type: "object",
  title: "Data",
  default: {},
  properties: {
    inputZones: {
      title: "Data",
      metadata: {
        schemaName: "dependencies.datasetId.properties.inputZones",
        component: "ColumnInputZones",
        order: ["yAxisData", "groupByData"],
        style: {
          height: "calc(100vh - 259px)",
        },
      },
      type: "object",
      default: {},
      properties: {
        yAxisData: {
          minItems: 1,
          type: "array",
          maxItems: 1,
          title: "Show",
          uniqueItems: true,
          default: [],
          metadata: {
            noStyle: true,
            layout: { gutter: [] },
            component: "DnDDropzone",
            allowedTypes: ["numerical", "float", "int"],
            fieldProps: {
              style: { minHeight: "100px" },
            },
          },
          items: {
            default: {},
            type: "object",
            required: ["column"],
            title: "Dataset Column",
            metadata: {
              order: ["type", "column"],
              layout: { gutter: [16] },
              labelPath: "column",
            },
            dependencies: boxplotNumericalSchema.dependencies,
            properties: {
              ...boxplotNumericalSchema.properties,
              id: {
                title: "ID",
                type: "string",
                metadata: {
                  hidden: true,
                  defaultValueSource: "uid",
                },
              },
            },
          },
        },
        groupByData: {
          title: "Group By",
          type: "array",
          default: [],
          metadata: {
            noStyle: true,
            validateFirst: true,
            component: "DnDDropzone",
            allowedTypes: [
              "string",
              "categorical",
              "numerical",
              "float",
              "int",
            ],
            fieldProps: {
              style: {
                minHeight: "100px",
              },
            },
          },
          items: {
            type: "object",
            required: ["column"],
            properties: {
              column: {
                $ref: "#/$defs/dataColumn",
                title: "Column",
                metadata: {
                  component: "ColumnSelect",
                  hideLabel: true,
                  allowedTypes: [
                    "string",
                    "categorical",
                    "numerical",
                    "float",
                    "int",
                  ],
                  fieldProps: {
                    popupMatchSelectWidth: false,
                  },
                },
              },
              type: {
                $ref: "#/$defs/dataColumnType",
                metadata: { hidden: true },
              },
              format: {
                type: "string",
                default: "string",
                metadata: {
                  hidden: true,
                },
              },
            },
          },
        },
      },
    },
    highchart: {
      default: {},
      title: "Chart Options",
      type: "object",
      metadata: {
        sections: {
          title: {
            title: "Title",
            properties: [
              "title/align",
              "title/margin",
              "title/floating",
              "title/showTitle",
              "title/verticalAlign",
            ],
          },
          appearance: {
            title: "Appearance",
            properties: [
              "chart/backgroundColor",
              "chart/height",
              "chart/width",
              "chart/showTitle",
              "chart/zoomType",
              "chart/plotBackgroundColor",
              "chart/borderWidth",
              "chart/borderColor",
              "chart/plotBorderWidth",
              "chart/plotBorderColor",
              "chart/marginTop",
              "chart/marginRight",
              "chart/marginBottom",
              "chart/marginLeft",
            ],
          },
          legend: {
            title: "Legend",
            properties: [
              "legend/enabled",
              "legend/align",
              "legend/vertialAlign",
              "legend/layout",
              "legend/borderWidth",
              "legend/borderRadius",
              "legend/borderColor",
              "legend/verticalAlign",
              "legend/backgroundColor",
              "legend/itemStyle/color",
              "legend/itemStyle/fontWeight",
              "legend/itemStyle/fontFamily",
            ],
          },
          exporting: {
            title: "Exporting",
            properties: [
              "exporting/menuItems",
              "exporting/filename",
              "exporting/align",
              "exporting/verticalAlign",
            ],
          },
          xAxis: {
            title: "X-Axis",
            properties: [
              "xAxis/title/text",
              "xAxis/title/align",
              "xAxis/title/margin",
              "xAxis/title/style/color",
              "xAxis/title/style/fontSize",
              "xAxis/plotLines",
            ],
          },
          yAxis: {
            title: "Y-Axis",
            properties: [
              "yAxis/title/text",
              "yAxis/title/align",
              "yAxis/title/margin",
              "yAxis/title/style/color",
              "yAxis/title/style/fontSize",
              "yAxis/plotLines",
            ],
          },
        },
      },
      properties: {
        ...highchartsProperties.title,
        ...highchartsProperties.chart,
        ...highchartsProperties.exporting,
        ...highchartsProperties.legend,
        "chart/zoomType": highchartsProperties["chart/zoomType"],
        "xAxis/title/text": {
          type: "string",
          title: "Title",
          description: "The title of the x-axis.",
          metadata: {
            fieldProps: {
              placeholder: "Auto",
            },
          },
        },
        "xAxis/title/align": {
          type: "string",
          default: "middle",
          title: "Alignment",
          enum: ["low", "middle", "high"],
          description:
            "The horizontal alignment of the x-axis title. Can be one of 'left', 'center', or 'right'.",
          metadata: {
            component: "Segmented",
            labels: ["Left", "Center", "Right"],
          },
        },
        "xAxis/title/margin": {
          minimum: 0,
          maximum: 64,
          nullable: true,
          title: "Margin",
          type: ["number", "null"],
          description:
            "The margin between the x-axis title and the x-axis labels, in pixels.",
          metadata: {
            showNumberInput: true,
            component: "SliderInput",
          },
        },
        "xAxis/title/style/color": {
          nullable: true,
          type: ["string", "null"],
          title: "Title Text Color",
          description:
            "The color of the x-axis title text, in CSS format (e.g. '#FF037777777777' for red).",
          metadata: {
            component: "ColorPicker",
          },
        },
        "xAxis/title/style/fontSize": {
          nullable: true,
          title: "Font Size",
          type: ["string", "null"],
          description:
            "The font size of the x-axis title text, in CSS units (e.g. '15px').",
        },
        "yAxis/title/text": {
          type: "string",
          title: "Title",
          description: "The title of the Y-Axis.",
          metadata: {
            fieldProps: {
              placeholder: "Auto",
            },
          },
        },
        "yAxis/title/align": {
          type: "string",
          default: "middle",
          title: "Alignment",
          enum: ["low", "middle", "high"],
          description:
            "The horizontal alignment of the Y-Axis title. Can be one of 'left', 'center', or 'right'.",
          metadata: {
            component: "Segmented",
            labels: ["Bottom", "Middle", "Top"],
          },
        },
        "yAxis/title/margin": {
          minimum: 0,
          maximum: 64,
          nullable: true,
          title: "Margin",
          type: ["number", "null"],
          description:
            "The margin between the Y-Axis title and the x-axis labels, in pixels.",
          metadata: {
            showNumberInput: true,
            component: "SliderInput",
          },
        },
        "yAxis/title/style/color": {
          nullable: true,
          type: ["string", "null"],
          title: "Title Text Color",
          description:
            "The color of the Y-Axis title text, in CSS format (e.g. '#FF037777777777' for red).",
          metadata: {
            component: "ColorPicker",
          },
        },
        "yAxis/title/style/fontSize": {
          nullable: true,
          title: "Font Size",
          type: ["string", "null"],
          description:
            "The font size of the Y-Axis title text, in CSS units (e.g. '15px').",
        },
        "yAxis/plotLines": {
          type: "array",
          default: [],
          title: "Plot Lines",
          metadata: {
            addButtonProps: { block: true },
          },
          items: {
            type: "object",
            default: {},
            properties: {
              color: {
                type: "string",
                deafult: "#999998",
                title: "Line Color",
                metadata: {
                  component: "ColorPicker",
                },
              },
              value: {
                type: "number",
                title: "Value",
              },
              width: {
                minimum: 0,
                default: 2,
                maximum: 64,
                title: "Width",
                type: "number",
                metadata: {
                  component: "SliderInput",
                  showNumberInput: true,
                },
              },
              zIndex: {
                minimum: 0,
                maximum: 64,
                type: "number",
                title: "Z-Index",
                metadata: {
                  component: "SliderInput",
                  showNumberInput: true,
                },
              },
            },
          },
        },
      },
    },
  },
}

export default boxplotSchema
