const backgroundColor = {
  title: "Background Color",
  type: ["string", "null"],
  nullable: true,
  metadata: {
    component: "ColorPicker",
  },
}

export default backgroundColor
