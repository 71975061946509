const errorLevel = "error"
const warningLevel = "warn"
const infoLevel = "info"
const debugLevel = "debug"

const errorLevels = [errorLevel]
const warningLevels = [...errorLevels, warningLevel]
const infoLevels = [...warningLevels, infoLevel]
const debugLevels = [...infoLevels, debugLevel]

const logLevels = {
  [errorLevel]: errorLevels,
  [warningLevel]: warningLevels,
  [infoLevel]: infoLevels,
  [debugLevel]: debugLevels,
}

const logAtLevel =
  level =>
  (...message) => {
    const currentLevel =
      window.localStorage.getItem("DBAI_LOG_LEVEL") || errorLevel
    const levels = logLevels[currentLevel]

    if (levels && levels.includes(level)) {
      console.log(...message)
    }
  }

const logger = {
  error: logAtLevel(errorLevel),
  warn: logAtLevel(warningLevel),
  info: logAtLevel(infoLevel),
  debug: logAtLevel(debugLevel),
}

export default logger
