import gql from "graphql-tag"

export const TAG_RESOURCE = gql`
  mutation TagResource($customerId: Int!, $input: TagInput!) {
    tagResource(customerId: $customerId, input: $input)
  }
`

export const UNTAG_RESOURCE = gql`
  mutation UntagResource($customerId: Int!, $input: TagInput!) {
    untagResource(customerId: $customerId, input: $input)
  }
`
