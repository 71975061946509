import { createSlice } from "@reduxjs/toolkit"

import {
  loadResource,
  createCommonActions,
  createResourceActions,
} from "@dbai/tool-box"

import client from "apolloClient"
import { GET_WORKFLOWS } from "queries"

const initialState = {
  data: [],
  loading: false,
  error: null,
}

const workflowsSlice = createSlice({
  name: "workflows",
  initialState,
  reducers: {
    ...createResourceActions({ initialState }),
    ...createCommonActions(initialState),
  },
})

const { actions } = workflowsSlice

const loadWorkflows = variables =>
  loadResource({
    fetchFn: () =>
      client
        .query({ query: GET_WORKFLOWS, variables })
        .then(({ data }) => data?.customer?.workflows),
    actions,
  })

const allActions = {
  loadWorkflows,
  ...actions,
}

export { allActions as actions }
export default workflowsSlice
