import { actions } from "../../reducers/appReducer"
import { observeYjsMap } from "./utils"

const addFilterObserver = (dispatch, filterMap) => {
  observeYjsMap({
    yMap: filterMap,
    onExternalChange: ({ yMap }) => {
      dispatch(actions.setAppFilter({ filter: yMap.toJSON() }))
    },
  })
  return filterMap
}

const filterObserver = (yDoc, dispatch) => {
  const specMap = yDoc.getMap("spec")
  const filtersMap = specMap.get("filters")
  const filters = filtersMap.toArray()

  filters.forEach(filterMap => {
    addFilterObserver(dispatch, filterMap)
  })
}

export { addFilterObserver }
export default filterObserver
