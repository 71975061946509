import React, { memo } from "react"
import { Row } from "antd"

import ListItem from "./ListItem"

const DefaultListItems = props => {
  const {
    path,
    name,
    value,
    onRemove,
    schema = {},
    nodeClassName,
    handleClassName,
    layout = { gutter: [] },
  } = props
  const { metadata = {} } = schema
  const { removable = true } = metadata
  return (
    <Row gutter={[0, 16]}>
      {value?.map((_, idx) => {
        return (
          <ListItem
            idx={idx}
            draggable
            path={path}
            schema={schema}
            layout={layout}
            onRemove={onRemove}
            removable={removable}
            key={`${path}.[${idx}]`}
            name={`${name}[${idx}]`}
            nodeClassName={nodeClassName}
            handleClassName={handleClassName}
            pathWithIndex={`${path}.[${idx}]`}
          />
        )
      })}
    </Row>
  )
}

export default memo(DefaultListItems)
