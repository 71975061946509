import { curry } from "lodash"

import { getSeriesId } from "../utils"
import {
  findColumnConfig,
  formatColumnValue,
} from "../../../../lib/datasetColumns"

const getSeriesOptions = (data, options) => {
  const { yAxisData } = options || {}
  const yInput = yAxisData[0]

  const seriesId = getSeriesId(yInput, data)
  return {
    seriesId,
  }
}

const getSeriesData = data => {
  return data[0].rows.reduce((acc, row) => {
    return [...acc, row[0]]
  }, [])
}

const gaugeOptions = {
  pane: {
    center: ["50%", "85%"],
    size: "140%",
    startAngle: -90,
    endAngle: 90,
    background: {
      innerRadius: "60%",
      outerRadius: "100%",
      shape: "arc",
    },
  },
}

const formatPointData = (context, column) => {
  if (!column) {
    return null
  }
  return formatColumnValue(column, context.y)
}

const dataLabelFormatter = column => {
  return function (formatter) {
    return formatPointData(this, column)
  }
}

const constructGaugeSeries = curry((dataset, options) => {
  const { highchart = {} } = options || {}
  if (!dataset.data?.length) {
    return options
  }
  const data = getSeriesData(dataset.data)
  const seriesOptions = getSeriesOptions(dataset.data, options)
  const columns = dataset.data?.length
    ? dataset.data[0]?.columns
    : dataset.meta?.datasetColumns
  const yColumnConfig = findColumnConfig(options.yAxisData[0], columns)

  return {
    ...options,
    highchart: {
      ...highchart,
      ...gaugeOptions,
      series: [
        {
          ...seriesOptions,
          data,
          dataLabels: {
            formatter: dataLabelFormatter(yColumnConfig),
          },
        },
      ],
    },
  }
})

export default constructGaugeSeries
