import React from "react"
import { FAMemo } from "@dbai/ui-staples"
import {
  nodeIconMap,
  StyledIconList,
  StyledIcon,
} from "components/pages/Workflows/shared/IconPicker"

const ComponentIconPicker = props => {
  const { onChange, selectedIcon } = props

  const handleIconClick = icon => {
    onChange(icon)
  }

  return (
    <StyledIconList onChangeComplete={onChange}>
      {Object.keys(nodeIconMap).map(icon => {
        return (
          <StyledIcon
            selected={selectedIcon === icon}
            onClick={() => handleIconClick(icon)}
          >
            <FAMemo icon={nodeIconMap[icon]} />
          </StyledIcon>
        )
      })}
    </StyledIconList>
  )
}

export default ComponentIconPicker
