import React from "react"

import { SidePane, DBDropzone } from "@dbai/ui-staples"
import { Collapse } from "antd"

const useBuildParams = getSignedUrl => {
  return async ({ file, meta }) => {
    const method = "put"
    const asyncUrl = new Promise((resolve, reject) => {
      getSignedUrl(file, (err, url) => {
        if (err) reject(err)
        resolve({ method, url, body: file })
      })
    })
    return await asyncUrl
  }
}

const useHandleChangeStatus = refreshFiles => {
  return (file, status) => {
    if (status === "done") {
      refreshFiles()
    }
  }
}

const Uploader = ({ getSignedUrl, refreshFiles }) => {
  const handleStatusChange = useHandleChangeStatus(refreshFiles)
  const getUploadParams = useBuildParams(getSignedUrl)

  return (
    <DBDropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleStatusChange}
    />
  )
}

const DatalakeUpload = props => {
  const { isVisible, handleClose, getSignedUrl, refreshFiles } = props

  return (
    <SidePane
      isVisible={isVisible}
      onCloseClicked={handleClose}
      title="UPLOAD FILES"
    >
      <Uploader getSignedUrl={getSignedUrl} refreshFiles={refreshFiles} />
      <br />
      <span style={{ color: "red" }}>
        <b>Note: </b>
        The maximum file size for web-based uploads is 5GB. If you need to
        upload larger files, please click below to expand the instructions:
      </span>
      <Collapse
        items={[
          {
            key: "1",
            label: "Large File Upload Instructions",
            children: (
              <div>
                <p>
                  1. Download and install the free S3 client:{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://s3browser.com/download/s3browser-11-1-5.exe"
                  >
                    https://s3browser.com/download/s3browser-11-1-5.exe
                  </a>
                </p>
                <p>
                  2. Login to Delta Bravo, navigate to the Data Lake and click
                  the "SHOW CREDENTIALS" button in the top right corner. You
                  will need to set up a connection in the S3 Browser software
                  using these details.
                </p>
                <p>
                  3. When you open S3 Browser, it will prompt you to add a new
                  account. Use the Access Key and Secret Key from step #2 and
                  click "Add new account".
                </p>
                <p>
                  4. It will prompt you to add a bucket name. Use the bucket
                  from step #2 and click "Add External bucket".
                </p>
                <p>
                  5. After you add the external bucket, you can use the "Upload"
                  button below the list of files to select the large files on
                  your computer and transfer them to the data lake.
                </p>
              </div>
            ),
          },
        ]}
      />
    </SidePane>
  )
}

export default DatalakeUpload
