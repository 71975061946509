import React from "react"
import styled from "styled-components"

import PanelTitle from "../shared/PanelTitle"
import { getSchemaTitle, ROOT_TREE_KEY } from "./utils"
import { useFormSchema } from "../../JSONSchemaForm/hooks"
import JSONSchemaField from "../../JSONSchemaForm/JSONSchemaField"

const ScrollableDiv = styled.div`
  overflow-y: auto;
  height: calc(100vh - 106px);
`

const getPaddingStyles = schema => {
  const { sections, component } = schema.metadata || {}
  if (sections || (["object", "array"].includes(schema.type) && component)) {
    return {}
  }
  return { padding: "16px" }
}

const ControlledForm = props => {
  const { selectedTreeNode, containerStyles = {}, showTitle = true } = props
  const rootSchema = useFormSchema()

  if (!selectedTreeNode || selectedTreeNode.key === ROOT_TREE_KEY) {
    return (
      <>
        {showTitle ? <PanelTitle level={3}>Widget Options</PanelTitle> : null}
        <ScrollableDiv style={containerStyles}>
          <JSONSchemaField path="" schemaKey="." schema={rootSchema} />
        </ScrollableDiv>
      </>
    )
  }

  const { schema, parentSchema, schemaKey, path } = selectedTreeNode
  const styles = getPaddingStyles(schema)
  const title = getSchemaTitle(schema, parentSchema, selectedTreeNode)
  return (
    <>
      {showTitle ? <PanelTitle level={3}>{title}</PanelTitle> : null}
      <ScrollableDiv style={{ ...styles, ...containerStyles }}>
        <JSONSchemaField
          path={path}
          schema={schema}
          schemaKey={schemaKey}
          parentSchema={parentSchema}
        />
      </ScrollableDiv>
    </>
  )
}

export default ControlledForm
