import { useMemo } from "react"
import { useSelector } from "react-redux"

import { selectNodeStatuses } from "selectors"

export const useGetNodeStatus = () => {
  const nodeStatuses = useSelector(selectNodeStatuses)
  return node => nodeStatuses[node.id]
}

export const useNodeStatus = node => {
  const getNodeStatus = useGetNodeStatus()
  const nodeStatus = useMemo(() => {
    return getNodeStatus(node)
  }, [getNodeStatus, node])

  return nodeStatus
}
