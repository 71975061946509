import { actions } from "../../reducers/appReducer"
import { observeYjsMapDeep } from "./utils"

const handleExternalChange =
  (dispatch, widgetId) =>
  ({ change, key, yMap, yMapEvent }) => {
    if (change.action === "update") {
      const path = yMapEvent.path
      const rootKey = path.length ? path[0] : key
      const updatedData = yMap.get(rootKey)

      if (rootKey === "options") {
        const options = yMap.get("options").toJSON()
        dispatch(
          actions.setWidgetOptions({
            widgetId,
            value: options,
          })
        )
        return
      }

      if (rootKey === "layout") {
        const layout = yMap.get("layout").toJSON()
        dispatch(
          actions.setWidgetLayout({
            widgetId,
            value: layout,
          })
        )
        return
      }

      if (rootKey === "name") {
        dispatch(
          actions.setWidget({ widgetId, name: rootKey, value: updatedData })
        )
        return
      }

      console.error("Unknown widget update", change, key, yMapEvent)
    }
  }

const addWidgetObserver = (dispatch, widgetsMap, widgetId) => {
  const widgetMap = widgetsMap.get(widgetId)

  observeYjsMapDeep({
    yMap: widgetMap,
    onExternalChange: handleExternalChange(dispatch, widgetId),
  })
}

const widgetObserver = (yDoc, dispatch) => {
  const specMap = yDoc.getMap("spec")
  const widgetsMap = specMap.get("widgets")
  const widgets = widgetsMap.toJSON()

  // for each widget that already exists, add an observer
  Object.keys(widgets).forEach(widgetId => {
    addWidgetObserver(dispatch, widgetsMap, widgetId)
  })
}

export { addWidgetObserver }
export default widgetObserver
