import config from "config"

const crypto = window.crypto

const key = config.datalakeEncryptionKey

async function decrypt(nonceCiphertextTag) {
  const SERVER_ENCRYPTION_IV_LENGTH = 12 // For GCM a nonce length of 12 bytes is recommended!
  var nonce = nonceCiphertextTag.subarray(0, SERVER_ENCRYPTION_IV_LENGTH)
  var ciphertextTag = nonceCiphertextTag.subarray(SERVER_ENCRYPTION_IV_LENGTH)

  var aesKey = base64ToArrayBuffer(btoa(key))
  aesKey = await crypto.subtle.importKey("raw", aesKey, "AES-GCM", true, [
    "encrypt",
    "decrypt",
  ])
  var decrypted = await crypto.subtle.decrypt(
    { name: "AES-GCM", iv: nonce },
    aesKey,
    ciphertextTag
  )
  return new TextDecoder().decode(decrypted)
}
// Helper
// https://stackoverflow.com/a/21797381/9014097
function base64ToArrayBuffer(base64) {
  var binary_string = atob(base64)
  var len = binary_string.length
  var bytes = new Uint8Array(len)
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}

export async function decryptDatalakeResources(datalakeResources) {
  var nonceCiphertextTag = base64ToArrayBuffer(datalakeResources)
  nonceCiphertextTag = new Uint8Array(nonceCiphertextTag)
  const decrypted = await decrypt(nonceCiphertextTag)
  return JSON.parse(decrypted)
}
