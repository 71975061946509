import React from "react"

import * as Entrypoint from "components/pages/Workflows/Edit/shared/kernel/widgets/Entrypoint"
import { useWidget } from "components/pages/Workflows/Edit/shared/kernel/widgets/hooks"

const JupyterModelView = props => {
  const widget = useWidget(props.id)
  if (!widget) return null

  switch (widget.data.state._view_name) {
    case "EntrypointView":
      return <Entrypoint.View {...props} widget={widget} />
    default:
      return null
  }
}

export default JupyterModelView
