import { scalarFormFields, containerFields } from "./formFieldRegistry"

const getSchemaCondition = ([type, spec]) => {
  return {
    if: {
      properties: {
        type: {
          enum: [type],
        },
      },
    },
    then: {
      ...spec.fieldOptionsSchema,
      type: "object",
      title: `${spec.label} Options`,
    },
  }
}

const properties = {
  propertyType: {},
  fieldLabel: {
    type: "string",
    title: "Label",
    metadata: {
      fieldProps: {
        placeholder: "Enter a label",
      },
    },
  },
}

if (process.env.NODE_ENV === "development") {
  Object.entries({ ...scalarFormFields, ...containerFields })
    .map(getSchemaCondition)
    .forEach(({ then }) => {
      const properties = Object.entries(then.properties)
      properties.forEach(([schemaKey, property]) => {
        if (!property) {
          console.error("Missing property for schema", schemaKey, property)
          throw new Error("Missing property for schema")
        }
      })
    })
}

export default {
  formFieldSelect: {
    default: {},
    type: "object",
    required: ["type"],
    dependencies: {
      type: {
        allOf: Object.entries({
          ...scalarFormFields,
          object: containerFields.object,
        }).map(getSchemaCondition),
        required: ["type", "title"],
        properties: {
          title: properties.fieldLabel,
        },
      },
    },
    properties: {
      type: {
        type: "string",
        enum: Object.keys({
          ...scalarFormFields,
          object: containerFields.object,
        }).map(fieldKey => fieldKey),
        title: "Field Type",
        description: "Field type",
        metadata: {
          fieldProps: {
            placeholder: "Select a field type",
          },
          labels: Object.values({
            ...scalarFormFields,
            ...containerFields,
          }).map(({ label }) => label),
        },
      },
    },
  },
  formFieldListWithDependencies: {
    type: "object",
    default: {},
    title: "Form Field",
    required: ["type", "title"],
    dependencies: {
      dependency: {
        properties: {
          dependencyValues: {
            type: "array",
            title: "Dependency Values",
            items: { type: "string" },
          },
        },
      },
      type: {
        allOf: Object.entries({ ...scalarFormFields, ...containerFields }).map(
          getSchemaCondition
        ),
        properties: {
          title: properties.fieldLabel,
          dependencies: {
            default: [],
            type: "array",
            title: "Conditions",
            metadata: {
              addButtonProps: { block: true },
              addButtonText: "ADD CONDITION",
            },
            items: {
              type: "object",
              title: "Dependency",
              default: {},
              required: ["dependentField"],
              metadata: {
                layout: { gutter: [16] },
              },
              dependencies: {
                dependentField: {
                  if: {
                    properties: {
                      condition: {
                        enum: ["hasValue"],
                      },
                    },
                  },
                  then: {
                    properties: {
                      dependencyValues: {
                        type: "array",
                        title: "Value(s)",
                        items: { type: "string" },
                      },
                    },
                  },
                },
              },
              properties: {
                dependentField: {
                  type: "string",
                  title: "Dependent Field",
                  metadata: {
                    component: "LocalSelect",
                    listPath: "options.properties",
                    valuePath: "key",
                    labelPath: ["title", "column"],
                  },
                },
                condition: {
                  type: "string",
                  default: "exists",
                  title: "When Dependency",
                  enum: ["exists", "hasValue"],
                  metadata: {
                    component: "Segmented",
                    labels: ["Exists", "Equals Value"],
                  },
                },
              },
            },
          },
        },
      },
    },
    properties: {
      type: {
        type: "string",
        enum: Object.keys({ ...scalarFormFields, ...containerFields }).map(
          fieldKey => fieldKey
        ),
        title: "Field Type",
        description: "Field type",
        metadata: {
          fieldProps: {
            placeholder: "Select a field type",
          },
          hideOnSelect: true,
          labels: Object.values({
            ...scalarFormFields,
            ...containerFields,
          }).map(({ label }) => label),
        },
      },
    },
  },
}
