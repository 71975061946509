import { curry } from "lodash"
import {
  findLabelConfig,
  findColumnConfig,
  findIndexForColumn,
  getDefaultColumnName,
} from "../../../../lib/datasetColumns"
import {
  getSeriesId,
  persistTooltip,
  filterOnPointClick,
  getCustomSeriesOptions,
} from "../utils"

const getSeriesOptions = (data, options) => {
  const { yAxisData, groupByData } = options || {}
  const yInput = yAxisData[0]
  const xInput = groupByData[0]
  const datasetColumns = data[0].columns
  const groupLabel = data[0].label?.group
  // const labelSpec =getLabelSpec(xInput, data[0].label?.name)

  const yDatasetColumn = findColumnConfig(yInput, datasetColumns)

  const seriesId = getSeriesId(yInput, data)
  return {
    seriesId,
    name: getDefaultColumnName(yDatasetColumn) || groupLabel,
    custom: getCustomSeriesOptions(
      { columns: data[0].columns },
      yInput,
      xInput
    ),
  }
}

const getSlice = (columnConfig, data, labelConfigs, xIdx, yIdx) => {
  const row = data.rows[0]
  const label = row[xIdx]
  const labelConfig = findLabelConfig(label, labelConfigs)
  return {
    y: row[yIdx],
    color: labelConfig?.color,
    name: labelConfig?.title || labelConfig?.label || label,
    custom: {
      label,
      group: columnConfig.column || columnConfig.name,
    },
  }
}

const getSeriesData = (data, options) => {
  const { yAxisData, groupByData } = options || {}
  // pie charts only support one y axis and one x axis
  const yInput = yAxisData[0]
  const xInput = groupByData[0]

  // each dataset is a group of data. each group contains the same columns
  const datasetColumns = data[0].columns

  // obtain the row index for the x and y axis data points
  const xIdx = findIndexForColumn(xInput, datasetColumns)
  const yIdx = findIndexForColumn(yInput, datasetColumns)

  // the x axis represents the grouped data. friendly names for each label can be
  // specified in the x axis options
  const xColumnConfig = findColumnConfig(xInput, datasetColumns)
  const labelConfigs = xColumnConfig?.formatOptions?.labels || []
  return data.slice(0, 50).flatMap(labelData => {
    return getSlice(xColumnConfig, labelData, labelConfigs, xIdx, yIdx)
  })
}

const constructPieSeries = curry(
  (dataset, options, selectPoints, crossFilters) => {
    const { groupByData, yAxisData, highchart = {} } = options || {}
    if (!groupByData?.length || !yAxisData?.length || !dataset.data?.length) {
      return { ...options, highchart: { ...highchart, series: [] } }
    }

    const data = getSeriesData(dataset.data, options)
    const seriesOptions = getSeriesOptions(dataset.data, options)

    return {
      ...options,
      highchart: {
        ...highchart,
        series: [
          {
            data,
            ...seriesOptions,
            point: {
              events: {
                // This function is triggered when a point is clicked.
                // 'this' refers to the point that was clicked.
                click: filterOnPointClick("name", selectPoints, crossFilters),
                mouseOver: persistTooltip(crossFilters),
              },
            },
          },
        ],
      },
    }
  }
)

export default constructPieSeries
