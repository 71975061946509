import React, { memo } from "react"
import { Flex, Col } from "antd"
import styled from "styled-components"

import ListItemIcon from "./ListItemIcon"
import LayoutWrapper from "../../LayoutWrapper"
import JSONSchemaField from "../../JSONSchemaField"
import { useFormData, useFormSchema } from "../../hooks"
import { getResolvedSchema, isSchemaHidden } from "../../utils"

const StyledFlex = styled(Flex)`
  width: 100%;
  border: 1px solid ${props => props.theme.colorBorder};
  padding: 8px 16px;
  overflow: auto;
  background: ${props => props.theme.colorFillAlter};
`

// checks if a schema has a single visual property
const hasSingleProperty = (schema, rootSchema, data, path) => {
  const resolvedSchema = getResolvedSchema(schema, rootSchema, data)
  if (resolvedSchema.type !== "object") return true
  const { properties = {} } = schema
  const nonHiddenProperties = Object.keys(properties).filter(key => {
    const property = properties[key]
    const resolvedSubSchema = getResolvedSchema(
      property,
      rootSchema,
      data[property]
    )
    return !isSchemaHidden(resolvedSubSchema, data?.[key], path)
  })

  return nonHiddenProperties.length === 1
}

const ListItem = props => {
  const {
    idx,
    path,
    name,
    schema,
    layout,
    onRemove,
    pathWithIndex,
    nodeClassName,
    handleClassName,
    removable = true,
    draggable = true,
  } = props

  const value = useFormData(name)
  const rootSchema = useFormSchema()
  const wrapperStyles = hasSingleProperty(schema.items, rootSchema, value, path)
    ? { margin: "0 1em 0 0" }
    : {}

  if (!removable && !draggable) {
    return (
      <Col span={24}>
        <LayoutWrapper
          path={path}
          layout={layout}
          key={pathWithIndex}
          schema={schema.items || schema}
        >
          <JSONSchemaField
            path={path}
            name={name}
            schema={schema.items}
            parentSchema={schema}
            schemaKey={`.[${idx}]`}
            wrapperStyles={wrapperStyles}
          />
        </LayoutWrapper>
      </Col>
    )
  }

  return (
    <Col span={24} className={nodeClassName} key={pathWithIndex}>
      <StyledFlex gap="small" wrap={false}>
        <ListItemIcon
          idx={idx}
          onRemove={onRemove}
          removable={removable}
          handleClassName={handleClassName}
        />
        <JSONSchemaField
          path={path}
          name={name}
          schema={schema.items}
          parentSchema={schema}
          schemaKey={`.[${idx}]`}
          wrapperStyles={wrapperStyles}
        />
      </StyledFlex>
    </Col>
  )
}

export default memo(ListItem)
