import { flow } from "lodash"

import {
  processEnumOptions,
  processMultiSelect,
  processScalarFieldOptions,
} from "../../lib/generateSchemaHelpers"

const generateSchema = flow([
  options => ({ ...options, type: "string" }),
  processScalarFieldOptions,
  processEnumOptions,
  processMultiSelect,
])

export default generateSchema
