import { isArray, merge } from "lodash"

export const mergeFn = (target, src) => {
  if (isArray(src)) {
    return src
  }
}

/**
 * This is a custom assign function used to handle complex chart updates.
 * Since some options on the chart can be an object or an array,
 * we want to handle transitions between attribute types here.
 **/
export const safeAssign = (target, src) => {
  // If the source is an array, then override the existing value
  if (isArray(src)) return src

  // If source is not an array, but the target is, then merge the source onto each
  // target element
  if (isArray(target)) return target.map(val => merge(val, src))
}
