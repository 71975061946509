import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { SidePane } from "@dbai/ui-staples"

import { actions } from "reducers/actionReducer"
import withCustomer from "components/shared/withCustomer"
import ActionForm from "components/pages/Workflows/shared/ActionForm"

const CloneForm = props => {
  const { nodeId, afterSubmit } = props
  const [loaded, setLoaded] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.createActionFromNode(nodeId))
    setLoaded(true)

    return () => dispatch(actions.reset())
  }, [dispatch, nodeId])

  if (!loaded) return null
  return <ActionForm saveText="SAVE" afterSubmit={afterSubmit} />
}

const SaveActionPane = props => {
  const { visible, onClose, nodeId } = props

  return (
    <SidePane
      title="Save Action"
      width="30vw"
      isVisible={visible}
      onCloseClicked={onClose}
    >
      <CloneForm afterSubmit={onClose} nodeId={nodeId} />
    </SidePane>
  )
}

export default withCustomer(SaveActionPane)
