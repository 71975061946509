import React from "react"
import { useQuery } from "@apollo/client"

import { Spinner, ErrorMessage, useCurrentCustomer } from "@dbai/ui-staples"

import PackagesTable from "./PackagesTable"
import { GET_CUSTOMER_PACKAGES } from "queries"
import IndexHeader from "components/pages/Workflows/shared/IndexHeader"
import WorkflowsContent from "components/pages/Workflows/shared/WorkflowsContent"

const Packages = props => {
  const [customer] = useCurrentCustomer()
  const variables = { cname: customer.normalizedName }
  const { data, loading, error } = useQuery(GET_CUSTOMER_PACKAGES, {
    variables,
  })

  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  return (
    <>
      <IndexHeader />
      <WorkflowsContent>
        <PackagesTable packages={data?.customer?.packages || []} />
      </WorkflowsContent>
    </>
  )
}

export default Packages
