import * as Y from "yjs"
import { isEqual } from "lodash"

import { checkTokenExpired, checkJwtExpired, PING_ME } from "@dbai/ui-staples"

/*
 * Through out the react app it's preferable where possible to access these via
 * context/hooks.
 */
export let yDoc = null
export let yDocProvider = null

export const getYDoc = () => yDoc
export const getYDocProvider = () => yDocProvider

export const setYDoc = val => {
  yDoc = val
}
export const setYDocProvider = val => {
  yDocProvider = val
}

const getAuth = () => `Bearer ${localStorage.getItem("user/jwt")}`

export const getDocId = (cname, resourceId, id) =>
  `${cname}/${resourceId}/${id}`

export const getYProviderParams = customer => {
  const auth = getAuth()
  return { auth, customerId: customer?.id, cname: customer?.normalizedName }
}

const sendAuthUpdate = wsProvider => {
  if (!wsProvider?.ws || !wsProvider.wsconnected) return

  const authUpdate = JSON.stringify({
    messageType: "authUpdate",
    auth: getAuth(),
  })

  wsProvider.ws.send(authUpdate)
}

const updateAuth = (wsProvider, customer) => {
  const params = new URLSearchParams(getYProviderParams(customer))
  const baseUrl = wsProvider.url.split("?")[0]
  const urlWithUpdatedAuth = [baseUrl, params].join("?")
  wsProvider.url = urlWithUpdatedAuth
}

export const updateProviderUrl = (client, wsProvider, customer) => {
  if (!wsProvider || !customer) return
  const authBearer = new URL(wsProvider?.url).searchParams.get("auth")
  const wsToken = authBearer?.replace("Bearer ", "")

  if (checkTokenExpired(wsToken)) {
    if (!checkJwtExpired()) {
      sendAuthUpdate(wsProvider)
      updateAuth(wsProvider, customer)
      return
    }

    client
      .query({
        fetchPolicy: "network-only",
        query: PING_ME,
      })
      .then(() => {
        sendAuthUpdate(wsProvider)
        updateAuth(wsProvider, customer)
      })
      .catch(e => {
        console.error("Failed to synchronize auth with YJS.", e)
      })
  }
}

export const postYjs = fn => {
  const yDoc = getYDoc()
  const wsProvider = getYDocProvider()
  if (yDoc && wsProvider) {
    try {
      yDoc.transact(() => {
        fn(yDoc, wsProvider)
      })
    } catch (e) {
      console.log("Failed to execute YJS transaction.", e)
    }
  }
}

const constructYMap = obj => {
  if (!obj) return new Y.Map()
  return new Y.Map(Object.entries(obj))
}

export const mergeYMap = (yMap, obj) => {
  if (!(yMap instanceof Y.Map) || typeof obj !== "object") return
  Object.entries(obj).forEach(([k, v]) => {
    if (!isEqual(yMap.get(k), v)) {
      yMap.set(k, v)
    }
  })
}

export const removeYMapItem = (yMap, id) => {
  if (!id) return
  yMap.delete(id)
}

export const moveYArrayItem = (yArray, from, to) => {
  if (!yArray || from === to) return
  // Y.Map instances can only be present once once in the document. Because of that, we cannot simply copy it from one array position to another.
  const item = yArray.get(from).toJSON()
  const itemMap = new Y.Map(Object.entries(item))
  yArray.delete(from)
  yArray.insert(to, [itemMap])
}

export const addYMapToYArray = (yArray, obj) => {
  if (!yArray || !(yArray instanceof Y.Array)) return
  yArray.push([constructYMap(obj)])
}

// Assumptions:
// - each item in the yArray has an id field that is unique
// - each item in the yArray is a yMap
export const updateYArrayItem = (yArray, value, indexFallback) => {
  if (!yArray) return
  let index
  const arr = yArray.toArray()
  index = arr.findIndex(e => e.get("id") === value.id)

  if (index < 0) {
    if ([null, undefined].includes(indexFallback)) return
    index = indexFallback
  }

  const yMap = yArray.get(index)
  mergeYMap(yMap, value)
}

export const removeYArrayItem = (yArray, id, fallbackIndex) => {
  if (!id) return
  const arr = yArray.toArray()
  let index = arr.findIndex(e => e.get("id") === id)

  if (index < 0) {
    if ([null, undefined].includes(fallbackIndex)) return
    index = fallbackIndex
    return
  }

  yArray.delete(index, 1)
}

// adds widget to yjs doc when a new widget is added or during yjs init setup
export const addWidgetYDoc = (widgetsMap, widget) => {
  if (!widget) return
  const { layout, options, ...rest } = widget
  const layoutMap = new Y.Map(Object.entries(layout))
  const optionsMap = new Y.Map(Object.entries(options))

  const widgetMap = constructYMap(rest)
  widgetMap.set("layout", layoutMap)
  widgetMap.set("options", optionsMap)

  widgetsMap.set(rest.id, widgetMap)
}

export const addPageYDoc = (pagesMap, pageId, page) => {
  if (!page) return
  pagesMap.set(pageId, constructYMap(page))
}

export const addWidgetToPageYDoc = (pagesMap, pageId, widgetId) => {
  const pageMap = pagesMap.get(pageId)
  const widgetIds = pageMap.get("widgetIds")
  pageMap.set("widgetIds", [...widgetIds, widgetId])
}

const removeWidgetFromPage = (pagesMap, pageId, widgetId) => {
  if (!pageId || !widgetId) return
  const pageMap = pagesMap.get(pageId)
  const widgetIds = pageMap.get("widgetIds")
  const filteredWidgetIds = widgetIds.filter(id => id !== widgetId)
  pageMap.set("widgetIds", filteredWidgetIds)
}

export const removeWidgetYDoc = (widgetsMap, pagesMap, widgetId, pageId) => {
  if (!widgetId) return

  // delete widget reference from page
  removeWidgetFromPage(pagesMap, pageId, widgetId)

  // delete pages nested inside deleted widget
  const widgetMap = widgetsMap.get(widgetId)
  const widgetOptions = widgetMap.get("options").toJSON()

  // if widget is a container, remove all nested widgets and pages
  if (widgetOptions.navItems?.length) {
    widgetOptions.navItems.forEach(({ pageId }) => {
      const pageMap = pagesMap.get(pageId)
      const widgetIds = pageMap.get("widgetIds")

      // recursively remove widgets from yDoc
      widgetIds.forEach(nestedWidgetId => {
        removeWidgetYDoc(widgetsMap, pagesMap, nestedWidgetId, pageId)
      })

      // remove page from yDoc
      removeYMapItem(pagesMap, pageId)
    })
  }

  // remove widget
  removeYMapItem(widgetsMap, widgetId)

  // TODO: force exit of widget editor
}

export const removeWidgetSelectionYDoc = (yDoc, clientId) => {
  const metadataMap = yDoc.getMap("metadata")
  const selectionsMap = metadataMap.get("selections")
  selectionsMap.delete(`${clientId}`)
}

export const addWidgetSelectionYDoc = (yDoc, wsProvider, widgetId) => {
  const awareness = wsProvider.awareness
  const { user } = awareness.getLocalState()
  const clientId = awareness.clientID
  const metadataMap = yDoc.getMap("metadata")
  const selectionsMap = metadataMap.get("selections")
  selectionsMap.set(`${clientId}`, {
    name: user.name,
    color: user.color[0],
    widgetId,
  })
}
