import client from "apolloClient"
import { createSlice } from "@reduxjs/toolkit"

import {
  storeToState,
  toastAndRethrow,
  createCommonActions,
  createResourceActions,
} from "@dbai/tool-box"

import * as selectors from "selectors/component"
import { findNodeById } from "lib/utils/specHelpers"
import {
  GET_COMPONENT,
  GET_COMPONENTS,
  UPDATE_COMPONENT,
  CREATE_COMPONENT,
  GET_COMPONENT_REVISIONS,
  SET_COMPONENT_CURRENT_REVISION,
} from "queries"

const cleanCell = {
  cellType: "code",
  metadata: {},
  outputs: [],
  source: [""],
  uuid: null,
}

const initialState = {
  loading: false,
  error: null,
  data: {
    component: {
      id: null,
      description: "",
      name: "",
      isEndpoint: false,
      componentRevisions: [],
      currentRevision: {},
      revisionId: null,
      spec: {
        arguments: [],
        artifacts: [],
        cells: [cleanCell],
        color: "#0c2963",
        type: "component",
        icon: "Cube",
      },
      updatedAt: null,
    },
  },
}

const componentSlice = createSlice({
  name: "component",
  initialState,
  reducers: {
    ...createCommonActions(initialState),
    ...createResourceActions({
      // resourceName: "action",
      initialState,
    }),
    setName: (draft, { payload }) => {
      draft.data.component.name = payload
    },
    setDescription: (draft, { payload }) => {
      draft.data.component.description = payload
    },
    setIsEndpoint: (draft, { payload = false }) => {
      draft.data.component.isEndpoint = payload
    },
    setColor: (draft, { payload }) => {
      draft.data.component.spec.color = payload.hex
    },
    setIcon: (draft, { payload }) => {
      draft.data.component.spec.icon = payload
    },
    setArguments: (draft, { payload = [] }) => {
      draft.data.component.spec.arguments = payload
    },
    setCellSource: (draft, { payload }) => {
      const cell = draft.data.component.spec.cells[payload.cellIdx]
      cell.source = [payload.value]
    },
    addScriptArgument: (draft, { payload }) => {
      const identifyMatch = input => input.name === payload.name
      if (-1 === draft.data.component.spec.arguments.findIndex(identifyMatch)) {
        draft.data.component.spec.arguments.push(payload)
      }
    },
    updateRevisions: (draft, { payload }) => {
      draft.data.component.componentRevisions = payload
    },
    loadRevision: (draft, { payload }) => {
      const component = draft.data.component
      const spec = (
        component.componentRevisions.find(
          revision => revision.id === payload
        ) || {}
      ).spec
      component.spec = spec
      component.revisionId = payload
    },
    deleteInputByName: (draft, { payload }) => {
      const inputs = draft.data.component.spec.arguments
      draft.data.component.spec.arguments = inputs.reduce((acc, next) => {
        if (next.name === payload) return acc
        return [...acc, next]
      }, [])
    },
  },
})

const { actions } = componentSlice

const formatComponentWithRevision = (component, revision) => {
  const initialComponent = initialState.data.component
  return {
    ...(component || initialComponent),
    revisionId: revision.id,
    spec: revision?.spec || initialComponent.spec,
  }
}

const formatComponentWithCurrentRevision = data => {
  const component = data?.customer?.component || data?.updateComponent
  return formatComponentWithRevision(component, component?.currentRevision)
}

/*
 * `variables` is expected to be of the following form:
 * { name, id } where `id` is the action id and `name` is the customer
 * normalized name.
 */
const loadComponent = variables => dispatch => {
  dispatch(actions.setLoading(true))
  return client
    .query({ query: GET_COMPONENT, variables })
    .then(({ data, error }) => {
      if (error) {
        dispatch(actions.setError(error.message))
      }

      dispatch(
        actions.set({
          name: "data.component",
          value: formatComponentWithCurrentRevision(data),
        })
      )
    })
    .catch(error => {
      dispatch(actions.setError(error.message))
      toastAndRethrow(error.message)()
    })
    .finally(() => {
      dispatch(actions.setLoading(false))
    })
}

const createComponent = () => (dispatch, getState) => {
  const state = getState()
  const component = selectors.selectComponent(state)
  const { customer } = state.currentCustomer
  const variables = {
    cid: customer.id,
    input: {
      description: component.description,
      name: component.name,
    },
    componentRevisionInput: { spec: component.spec },
  }

  const refetchQueries = [GET_COMPONENTS]

  return client
    .mutate({ mutation: CREATE_COMPONENT, variables, refetchQueries })
    .then(() => dispatch(actions.reset()))
    .catch(error => {
      dispatch(actions.setError(error.message))
      toastAndRethrow(error.message)()
    })
}

const updateComponent = () => (dispatch, getState) => {
  const state = getState()
  const component = selectors.selectComponent(state)
  const { customer } = state.currentCustomer
  const variables = {
    cname: customer.normalizedName,
    componentId: component.id,
    revision: { spec: storeToState(component.spec) },
    component: {
      description: component.description,
      name: component.name,
    },
  }

  const refetchQueries = [GET_COMPONENT_REVISIONS]

  return client
    .mutate({ mutation: UPDATE_COMPONENT, variables, refetchQueries })
    .then(({ data, errors }) => {
      const updatedRevisions = data?.updateComponent?.componentRevisions
      if (updatedRevisions) dispatch(actions.updateRevisions(updatedRevisions))
    })
    .catch(error => {
      dispatch(actions.setError(error.message))
      toastAndRethrow(error.message)()
    })
}

const setCurrentRevision = revisionId => (dispatch, getState) => {
  const state = getState()
  const component = selectors.selectComponent(state)
  const { customer } = state.currentCustomer
  const variables = {
    cname: customer.normalizedName,
    componentId: component.id,
    input: {
      description: component.description,
      name: component.name,
      currentRevisionId: revisionId,
    },
  }

  const refetchQueries = [GET_COMPONENT_REVISIONS]

  return client
    .mutate({
      mutation: SET_COMPONENT_CURRENT_REVISION,
      variables,
      refetchQueries,
    })
    .catch(error => {
      dispatch(actions.setError(error.message))
      toastAndRethrow(error.message)()
    })
}

const saveComponent = () => (dispatch, getState) => {
  const component = selectors.selectComponent(getState())
  if (component.id) {
    return dispatch(updateComponent())
  }
  return dispatch(createComponent())
}

const joinCells = node =>
  node.cells
    .filter(c => c.cellType === "code")
    .map(c => c.source.join(""))
    .join("\n\n")

const cellIdx = 0
const createComponentFromNode = nodeId => (dispatch, getState) => {
  const node = findNodeById(nodeId, getState())
  const code = joinCells(node)

  dispatch(actions.setName(node.data.label))
  dispatch(actions.setColor({ hex: node.data.color }))
  dispatch(actions.setIcon(node.data.icon))
  dispatch(actions.setArguments(node.arguments))
  dispatch(
    actions.setCellSource({
      value: code,
      cellIdx,
    })
  )
}

const allActions = {
  ...actions,
  loadComponent,
  createComponent,
  updateComponent,
  saveComponent,
  setCurrentRevision,
  createComponentFromNode,
}
export { allActions as actions }
export default componentSlice
