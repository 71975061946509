import basicChartSchema from "./basic"
import boxplotSchema from "./boxplot"
import pieSchema from "./pie"
import heatmapSchema from "./heatmap"
import columnSchema from "./column"
import barSchema from "./bar"
import gaugeSchema from "./gauge"

const defaultMetadata = {
  noStyle: true,
  sectionType: "tabs",
  order: [
    "xAxis",
    "yAxis",
    "groupBy",
    "highchart",
    "datasetId",
    "dataset",
    "where",
  ],
  sections: {
    data: {
      title: "Data",
      properties: ["inputZones"],
      containerStyle: {
        padding: -1,
      },
    },
    chart: {
      title: "Chart Options",
      properties: ["highchart"],
      containerStyle: {
        padding: -1,
        overflowY: "auto",
        height: "calc(100vh- 259px)",
      },
    },
    dataOptions: {
      title: "Dataset Options",
      properties: ["datasetId", "dataset", "datasetId", "where"],
    },
  },
}

const basicChartTypeMetadata = {
  noStyle: true,
  sectionType: "tabs",
  order: [
    "xAxis",
    "yAxis",
    "groupBy",
    "highchart",
    "datasetId",
    "dataset",
    "where",
  ],
  sections: {
    data: {
      title: "Data",
      properties: ["inputZones"],
      containerStyle: {
        // padding: -1,
      },
    },
    series: {
      title: "Series",
      properties: ["seriesOptions"],
      containerStyle: {
        overflowY: "auto",
        overflowX: "hidden",
        height: "calc(100vh - 259px)",
      },
    },
    chart: {
      title: "Chart Options",
      properties: ["highchart"],
      containerStyle: {
        // padding: -1,
        overflowY: "auto",
        height: "calc(100vh - 259px)",
      },
    },
    dataOptions: {
      title: "Dataset Options",
      properties: ["datasetId", "dataset", "datasetId", "where"],
    },
  },
}

const chartWidgetSchema = {
  default: {},
  type: "object",
  title: "Chart Widget",
  required: ["datasetId"],
  metadata: {
    sideEffects: [
      {
        resetValue: [],
        type: "resetField",
        targetName: "options.xAxisData",
        sourceName: "options.datasetId",
      },
      {
        resetValue: [],
        type: "resetField",
        targetName: "options.yAxisData",
        sourceName: "options.datasetId",
      },
      {
        resetValue: [],
        type: "resetField",
        targetName: "options.where",
        sourceName: "options.datasetId",
      },
      {
        resetValue: [],
        type: "resetField",
        targetName: "options.columns",
        sourceName: "options.datasetId",
      },
      {
        resetValue: [],
        type: "resetField",
        sourceName: "options.datasetId",
        targetName: "options.groupByData",
      },
      {
        resetValue: {},
        type: "resetOnChartTypeChange",
        targetName: "options.plotOptions",
        chartTypeName: "options.chart.type",
      },
      {
        resetValue: undefined,
        type: "resetOnChartTypeChange",
        targetName: "options.legend.align",
        chartTypeName: "options.chart.type",
      },
      {
        resetValue: undefined,
        type: "resetOnChartTypeChange",
        targetName: "options.legend.layout",
        chartTypeName: "options.chart.type",
      },
      {
        resetValue: undefined,
        type: "resetOnChartTypeChange",
        chartTypeName: "options.chart.type",
        targetName: "options.legend.verticalAlign",
      },
      {
        resetValue: undefined,
        type: "resetOnChartTypeChange",
        chartTypeName: "options.chart.type",
        targetName: "options.tooltip.enabled",
      },
    ],
  },
  properties: {
    datasetId: {
      $ref: "#/$defs/datasetId",
      metadata: {
        errorMessage: {
          required: "Dataset is required",
        },
        validateFirst: true,
        component: "Dataset",
        wrapperStyle: {
          padding: "0 16px",
        },
        fieldProps: {
          placeholder: "Select dataset",
          popupMatchSelectWidth: false,
        },
      },
    },
  },
  dependencies: {
    datasetId: {
      dependencies: {
        "chart/type": {
          allOf: [
            {
              if: {
                properties: {
                  "chart/type": {
                    enum: ["boxplot"],
                  },
                },
              },
              then: {
                metadata: defaultMetadata,
                properties: boxplotSchema.properties,
              },
            },
            {
              if: {
                properties: {
                  "chart/type": {
                    enum: ["heatmap"],
                  },
                },
              },
              then: {
                metadata: defaultMetadata,
                properties: heatmapSchema.properties,
              },
            },
            {
              if: {
                properties: {
                  "chart/type": {
                    enum: ["pie"],
                  },
                },
              },
              then: {
                metadata: defaultMetadata,
                properties: pieSchema.properties,
              },
            },
            {
              if: {
                properties: {
                  "chart/type": {
                    enum: ["solidgauge"],
                  },
                },
              },
              then: {
                metadata: defaultMetadata,
                properties: gaugeSchema.properties,
              },
            },
            {
              if: {
                properties: {
                  "chart/type": {
                    enum: ["column"],
                  },
                },
                aggregateRequired: {
                  enum: [false],
                },
              },
              then: {
                metadata: basicChartTypeMetadata,
                properties: columnSchema(false).properties,
              },
            },
            {
              if: {
                properties: {
                  "chart/type": {
                    enum: ["column"],
                  },
                },
                aggregateRequired: {
                  enum: [true],
                },
              },
              then: {
                metadata: basicChartTypeMetadata,
                properties: columnSchema(true).properties,
              },
            },
            {
              if: {
                properties: {
                  "chart/type": {
                    enum: ["bar"],
                  },
                },
                aggregateRequired: {
                  enum: [false],
                },
              },
              then: {
                metadata: basicChartTypeMetadata,
                properties: barSchema(false).properties,
              },
            },
            {
              if: {
                properties: {
                  "chart/type": {
                    enum: ["bar"],
                  },
                },
                aggregateRequired: {
                  enum: [true],
                },
              },
              then: {
                metadata: basicChartTypeMetadata,
                properties: barSchema(true).properties,
              },
            },
            {
              if: {
                properties: {
                  "chart/type": {
                    enum: ["line", "area", "scatter"],
                  },
                  aggregateRequired: {
                    enum: [false],
                  },
                },
              },
              then: {
                metadata: basicChartTypeMetadata,
                properties: basicChartSchema(false).properties,
              },
            },
            {
              if: {
                properties: {
                  "chart/type": {
                    enum: ["line", "area", "scatter"],
                  },
                  aggregateRequired: {
                    enum: [true],
                  },
                },
              },
              then: {
                metadata: basicChartTypeMetadata,
                properties: basicChartSchema(true).properties,
              },
            },
          ],
        },
      },
      properties: {
        aggregateRequired: {
          type: "boolean",
          default: false,
          metadata: {
            hidden: true,
          },
        },
        datasetId: {
          metadata: {
            wrapperStyle: {
              padding: "0",
            },
          },
        },
        "chart/type": {
          type: "string",
          enum: [
            "line",
            "bar",
            "area",
            "column",
            "scatter",
            "pie",
            "boxplot",
            "heatmap",
            "solidgauge",
          ],
          default: "line",
          title: "Chart Type",
          metadata: {
            labels: [
              "Line",
              "Bar",
              "Area",
              "Column",
              "Scatter",
              "Pie",
              "Boxplot",
              "Heatmap",
              "Gauge",
            ],
            wrapperStyles: {
              padding: "10px 0 10px 16px",
            },
          },
        },
        where: {
          title: "Filters",
          default: [],
          type: "array",
          metadata: {
            layout: { gutter: [] },
            component: "DnDDropzone",
            fieldProps: {
              style: { minHeight: "100px" },
            },
          },
          items: {
            type: "object",
            required: ["column", "type"],
            properties: {
              column: { $ref: "#/$defs/dataColumn" },
              type: { $ref: "#/$defs/dataColumnType" },
              op: { $ref: "#/$defs/scalarOperators" },
              conj: { $ref: "#/$defs/conjunction" },
              value: { $ref: "#/$defs/scalarValue" },
              conditions: {
                type: "array",
                items: {
                  type: "object",
                  required: ["column", "type"],
                  properties: {
                    column: { $ref: "#/$defs/dataColumn" },
                    type: { $ref: "#/$defs/dataColumnType" },
                    op: { $ref: "#/$defs/scalarOperators" },
                    conj: { $ref: "#/$defs/conjunction" },
                    value: { $ref: "#/$defs/scalarValue" },
                    conditions: {
                      $ref: "#/$defs/queryWhere",
                      type: ["array", "null"],
                    },
                  },
                },
              },
            },
          },
        },
        dataset: {
          title: "Dataset Options",
          default: {},
          type: "object",
          properties: {
            format: {
              title: "Format",
              default: "chart",
              type: "string",
              enum: ["chart", "table"],
              metadata: {
                hidden: true,
              },
            },
            limit: {
              title: "Data Limit",
              description:
                "The 'Data Limit' applies to the number of rows displayed. If you are using an aggregate query (sum, average, etc), the query will limit the rows AFTER you perform the aggregate. For example, if you have 1,000 rows and have a 100 'Data Limit', the query will still perform the aggregate against 1,000 rows.  The number of rows returned would be 100 or less. Increasing the 'Data Limit' may result in slower load times.",
              type: "number",
              default: 1000,
            },
            offset: {
              title: "Data Offset",
              description:
                "Determines the number of rows to skip. For example, if your data is ordered by ID, and you have an offset of 100, the first 100 rows will be skipped.",
              type: ["number", "null"],
              nullable: true,
            },
            pollInterval: {
              type: ["number", "null"],
              title: "Refresh Interval (minutes)",
              description:
                "Determines how often the chart will refresh automatically in minutes",
              minimum: 1,
              nullable: true,
              metadata: {
                formatter: {
                  format: "number",
                  suffix: "min",
                },
                fieldProps: {
                  step: 1,
                  precision: 2,
                  placeholder: "Poll interval",
                },
              },
            },
          },
        },
      },
    },
  },
}

export default chartWidgetSchema
