import React from "react"
import styled from "styled-components"
import { Tooltip } from "antd"

import { useYjsUsers } from "hooks"

const UserCircle = styled.div`
  background-color: ${props => props.color};
  color: white;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: -3px;
  line-height: 25px;
`

const UserWrapper = styled.div`
  display: flex;
`

const UserIcons = () => {
  const users = useYjsUsers()
  return (
    <UserWrapper>
      {users.map(({ key, name, color }) => {
        if (!name) return null

        return (
          <Tooltip key={key} title={name} placement="bottom">
            <UserCircle key={key} color={color[0]}>
              {name[0]}
            </UserCircle>
          </Tooltip>
        )
      })}
    </UserWrapper>
  )
}

export default UserIcons
