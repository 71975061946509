import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExport, faImagePolaroid } from "@fortawesome/pro-solid-svg-icons"

import { useWidgetContext } from "../../hooks"

const createWidgetContentMap = (
  dispatch,
  exportDataset,
  tableQueryResolver
) => ({
  TableWidget: [
    {
      label: "Export",
      icon: <FontAwesomeIcon icon={faFileExport} />,
      onClick: widget => () => {
        const query = tableQueryResolver(widget.options)
        dispatch(exportDataset(query.datasetId, query))
      },
    },
  ],
  Image: [
    {
      label: "Edit Image",
      icon: <FontAwesomeIcon icon={faImagePolaroid} />,
      onClick: widget => () => {
        const id = widget.id
        tinymce.get(`widget-${id}`).execCommand("mceImage")
      },
    },
  ],
})

const useWidgetMenuItems = (widget, handleClick) => {
  const { exportDataset, widgetRegistry } = useWidgetContext()
  const dispatch = useDispatch()
  const widgetMenuItems = useMemo(() => {
    const tableQueryResolver = widgetRegistry["TableWidget"].queryResolver
    return createWidgetContentMap(dispatch, exportDataset, tableQueryResolver)
  }, [dispatch, exportDataset, widgetRegistry])

  return useMemo(() => {
    const menuItems = widgetMenuItems[widget?.type]
    if (!menuItems) return []
    return menuItems.map(mi => ({
      ...mi,
      onClick: handleClick(mi.onClick(widget)),
    }))
  }, [widgetMenuItems, handleClick, widget])
}

export default useWidgetMenuItems
