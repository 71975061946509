import { useState } from "react"

const sortBy = field => (l, r) => {
  if (l[field] < r[field]) return -1
  if (l[field] > r[field]) return 1
  return 0
}

const useSort = (initialField = "", initialAsc = true) => {
  const [field, setField] = useState(initialField)
  const [asc, setAsc] = useState(initialAsc)

  const set = newField => {
    field !== newField ? setField(newField) : setAsc(!asc)
  }

  const reverse = fn => (l, r) => fn(l, r) * -1
  const normal = sortBy(field)
  const sort = asc ? normal : reverse(normal)

  return [sort, set]
}

export default useSort
