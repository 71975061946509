import mergeWith from "lodash/mergeWith"
import isArray from "lodash/isArray"

// helper function to merge two schemas
const mergeSchema = (schema, additionalOptions) => {
  return mergeWith({}, schema, additionalOptions, (a, b) => {
    if (isArray(b)) {
      return b
    }
  })
}

export { mergeSchema }
