import React from "react"
import { Space, Flex, Tooltip } from "antd"
import { capitalize } from "lodash"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faSpinner,
  faCheck,
  faEllipsis,
} from "@fortawesome/pro-solid-svg-icons"

import { useYjsUsers } from "../hooks"

const UserCircle = styled.div`
  background-color: ${props => props.color};
  color: white;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: -3px;
  line-height: 25px;
`

const UserIcons = () => {
  const users = useYjsUsers()
  return (
    <Flex align="center">
      {users.map(({ key, name, color }) => {
        if (!name) return null

        return (
          <Tooltip key={key} title={name} placement="bottom">
            <UserCircle key={key} color={color[0]}>
              {name[0]}
            </UserCircle>
          </Tooltip>
        )
      })}
    </Flex>
  )
}

const StyledIndicator = styled.div`
  ${({ status, theme }) => {
    switch (status) {
      case "saving":
        return `
        color: ${theme.colorSuccessText || "#5eba00"};
        :hover{
          color: ${theme.colorSuccessTextHover || "#448700"};
        }
        `
      case "waiting":
        return `
        color: ${theme.colorWarningText || "#f1c40f"};
        :hover{
          color: ${theme.colorWarningTextHover || "#c29d0b "};
        }
        `
      default:
        return `
        color: ${theme.colorInfoText || "#cccc"};
        :hover{
          color: ${theme.colorInfoTextHover || "#60686f "};
        }
        `
    }
  }};
`

const statusIconMap = {
  idle: faCheck,
  saving: faSpinner,
  waiting: faEllipsis,
}

const AppStatusIndicator = props => {
  const { appStatus } = props
  return (
    <Space>
      <Tooltip title={capitalize(appStatus)}>
        <StyledIndicator status={appStatus}>
          <FontAwesomeIcon icon={statusIconMap[appStatus]} />
        </StyledIndicator>
      </Tooltip>
      <UserIcons />
    </Space>
  )
}

export default AppStatusIndicator
