import React, { useState } from "react"
import { Button } from "antd"
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons"
import { faCopy } from "@fortawesome/free-regular-svg-icons"
import { faEye } from "@fortawesome/pro-solid-svg-icons"

import { CLONE_APP } from "queries"
import { GET_APP, GET_APPS } from "@dbai/applet"
import {
  ApiForm,
  DeleteButton,
  ErrorMessage,
  SidePane,
  TableWithFolders,
  useCurrentCustomer,
  Input,
} from "@dbai/ui-staples"

import { useTagColumn } from "hooks"
import { actions } from "reducers/appsReducer"

const columnOrder = ["name", "tags", "createdAt", "updatedAt", "actions"]
const tagColumnOptions = {
  type: "apps",
  query: GET_APP,
}
const AppActions = props => {
  const dispatch = useDispatch()
  const [{ id: customerId, normalizedName: cname }] = useCurrentCustomer()
  const { row } = props

  const handleDelete = () => {
    return dispatch(actions.deleteApp(row.id))
  }

  const refetch = () => {
    return dispatch(actions.loadApps())
  }

  const [showClone, setShowClone] = useState(false)

  const initial = {
    name: `${row.name} (clone)`,
    id: row.id,
    customerId: customerId,
  }

  const history = useHistory()

  return (
    <Button.Group>
      <Button onClick={() => history.push(`/${cname}/apps/${row.id}`)} icon={<FontAwesomeIcon icon={faPencilAlt} className="action-icon" />} />

      <Button
        onClick={() => history.push(`/${cname}/apps/${row.id}/current`)}
        target="_blank"
        icon={<FontAwesomeIcon icon={faEye} />}
      />

      <Button className="btn-icon" onClick={() => setShowClone(true)}>
        <FontAwesomeIcon icon={faCopy} size="lg" />
      </Button>

      <SidePane
        isVisible={showClone}
        title="Clone App"
        width="30vw"
        onCloseClicked={() => setShowClone(false)}
      >
        <ApiForm
          mutation={CLONE_APP}
          afterSubmit={() => {
            setShowClone(false)
          }}
          initialState={initial}
          onCompleted={refetch}
        >
          <Input name="name" />
        </ApiForm>
      </SidePane>

      <DeleteButton
        onDelete={handleDelete}
        afterDelete={refetch}
        confirmDelete={true}
      />
    </Button.Group>
  )
}

const AppsTable = () => {
  const { loading, data, error } = useSelector(store => store.apps)
  const tags = useTagColumn(tagColumnOptions)
  const [{ normalizedName: cname }] = useCurrentCustomer()

  if (error) return <ErrorMessage error={error} />

  const refetchVariables = { cname }

  return (
    <TableWithFolders
      columns={[tags]}
      dataSource={data}
      loading={loading}
      folderType="apps"
      Actions={AppActions}
      refetchQuery={GET_APPS}
      columnOrder={columnOrder}
      emptyText="No Apps Found"
      refetchVariables={refetchVariables}
      pagination={{ hideOnSinglePage: true }}
      rowLink={row => `/${cname}/apps/${row.id}`}
    />
  )
}

export default AppsTable
