import React from "react"
import { Route, Switch } from "react-router-dom"

import { AuthBoundary } from "@dbai/ui-staples"

import { useNavItems } from "./ContentWithStackedNav"

const AppsIndex = () => {
  const navItems = useNavItems()
  return (
    <AuthBoundary subject="apps" action="LIST">
      <Switch>
        {navItems.map(navItem => {
          const { Component, path, exact } = navItem
          return (
            <Route key={path} path={path} exact={exact}>
              <Component />
            </Route>
          )
        })}
      </Switch>
    </AuthBoundary>
  )
}

export default AppsIndex
