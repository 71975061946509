import React from "react"
import styled from "styled-components"
import { alterColor } from "@dbai/tool-box"

const shadedBackground = amount => props =>
  alterColor(props.theme.background, amount)

const CardContainer = styled.div`
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid ${shadedBackground(-25)};
  border-radius: 3px;
  > {
    hr {
      margin-right: 0;
      margin-left: 0;
    }
    .list-group {
      &:first-child .list-group-item:first-child {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }
      &:last-child .list-group-item:last-child {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }
    }
  }

  .card-body.dbai-nopad {
    padding: 0;
  }
`

const CardHeader = styled.div`
  background: ${shadedBackground(-10)};
  border-bottom: 1px solid ${props => props.theme.background};
  padding: 0.5rem 1rem;
  font-weight: bold;
  width: 100%;
  float: left;
  .left {
    width: 50%;
    float: left;
  }
  .right {
    width: 50%;
    float: right;
    text-align: right;
    > span,
    button {
      margin-right: 10px;
      cursor: pointer;
    }
  }
`
const CardBody = styled.div`
  padding: 1rem 1rem 1.5rem;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;

  .dbai-nopad {
    padding: 0;
  }
`

const CardFooter = styled.div`
  padding: 1.5rem 1.5rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 40, 100, 0.12);
  &:last-child {
    border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
  }
`

const Card = React.forwardRef((props, ref) => {
  const { title, icon, className, nopad = false, ...rest } = props

  const { headers, bodies, actions, footers } = React.Children.toArray(
    props.children
  ).reduce(
    (prev, current) => {
      switch (current.type) {
        case Action:
          prev.actions = [...prev.actions, current]
          break
        case Header:
          prev.headers = [...prev.headers, current]
          break
        case Body:
          prev.bodies = [...prev.bodies, current]
          break
        case Footer:
          prev.footers = [...prev.footers, current]
          break
        default:
          prev.bodies = [...prev.bodies, current]
      }
      return prev
    },
    {
      headers: [],
      actions: [],
      bodies: [],
      footers: [],
    }
  )

  const cardBodyClass = `card-body ${nopad ? "dbai-nopad" : ""}`

  const hasHeaders = headers.length > 0
  const shouldShowTitle = !hasHeaders && (icon || title || actions.length > 0)

  return (
    <CardContainer ref={ref} className={className} {...rest}>
      {hasHeaders && (
        <CardHeader className="db-card-header">{headers}</CardHeader>
      )}
      {shouldShowTitle && (
        <CardHeader className="db-card-header">
          <div className="left">
            {icon} {title}
          </div>
          <div className="right">{actions}</div>
        </CardHeader>
      )}
      <CardBody className={cardBodyClass}>{bodies}</CardBody>
      {footers.length > 0 && <CardFooter>{footers}</CardFooter>}
    </CardContainer>
  )
})

const Header = props => props.children
const Action = props => props.children
const Body = props => props.children
const Footer = props => props.children

Card.Header = Header
Card.Action = Action
Card.Body = Body
Card.Footer = Footer

export default Card
