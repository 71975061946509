import React from "react"
import JSONSchemaField from "../JSONSchemaField"

const EditableTableCell = props => {
  const {
    path,
    title,
    record,
    schema,
    editable,
    children,
    dataIndex,
    schemaKey,
    handleSave,
    formFieldProps = {},
    ...restProps
  } = props
  if (editable) {
    return (
      <td {...restProps}>
        <JSONSchemaField
          noStyle
          path={path}
          schema={schema}
          schemaKey={schemaKey}
          {...formFieldProps}
        />
      </td>
    )
  }
  return <td {...restProps}>{children}</td>
}
export default EditableTableCell
