import React from "react"

import useCurrentUser from "../../hooks/useCurrentUser"

// withUsers a Higher-Order-Component (HOC) that takes a component and
// wraps it with new component while injecting the current user.
const withUser = Comp => props => {
  // eslint-disable-line
  const [user] = useCurrentUser()

  return <Comp user={user} {...props} />
}

export default withUser
