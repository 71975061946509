const appFiltersSchema = {
  default: {},
  type: "object",
  metadata: {
    order: ["filters"],
  },
  properties: {
    filters: {
      minItems: 0,
      title: "Filters List",
      type: "array",
      metadata: {
        itemType: "default",
        hideLabel: true,
        addButtonProps: {
          block: true,
          type: "default",
        },
        addButtonText: "ADD FILTER",
      },
      items: {
        type: "object",
        metadata: {
          order: ["type", "datasetId", "column", "op", "value"],
          hideLabel: true,
          layout: { gutter: [16] },
        },
        dependencies: {
          type: {
            allOf: [
              {
                if: {
                  properties: {
                    type: {
                      enum: ["int", "number", "float", "integer"],
                    },
                  },
                },
                then: {
                  dependencies: {
                    op: {
                      if: {
                        properties: {
                          op: {
                            enum: ["=", "!="],
                          },
                        },
                      },
                      then: {
                        properties: {
                          value: {
                            type: "array",
                            title: `Number Value`,
                            items: {
                              type: "string",
                            },
                            metadata: {
                              colon: true,
                              hideLabel: true,
                              allowInput: true,
                              excludeWidgetFilters: true,
                              excludeGlobalFilters: true,
                              component: "ColumnValueInput",
                              errorMessage: { required: "Required" },
                              fieldProps: {
                                placeholder: "value",
                                style: {
                                  width: "200px",
                                },
                              },
                            },
                          },
                        },
                      },
                      else: {
                        properties: {
                          value: {
                            title: "Number Value",
                            type: "string",
                            metadata: {
                              colon: true,
                              hideLabel: true,
                              allowInput: true,
                              excludeWidgetFilters: true,
                              excludeGlobalFilters: true,
                              component: "ColumnValueInput",
                              errorMessage: { required: "Required" },
                              fieldProps: {
                                placeholder: "value",
                                style: {
                                  width: "200px",
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  properties: {
                    op: {
                      title: "Number Operators",
                      type: "string",
                      enum: [">", "<", ">=", "<=", "=", "!="],
                      metadata: {
                        colon: true,
                        hideLabel: true,
                        errorMessage: { required: "Required" },
                        fieldProps: {
                          placeholder: "is",
                          popupMatchSelectWidth: false,
                        },
                        labels: [
                          "is greater than",
                          "is less than",
                          "is greater than or equal to",
                          "is less than or equal to",
                          "is equal to",
                          "is not equal to",
                        ],
                      },
                    },
                  },
                },
              },
              {
                if: {
                  properties: {
                    type: {
                      enum: ["datetime"],
                    },
                  },
                },
                then: {
                  properties: {
                    op: {
                      title: "Datetime Operators",
                      nullable: false,
                      type: "string",
                      enum: ["=", "!=", "<", ">", "<=", ">="],
                      metadata: {
                        colon: true,
                        hideLabel: true,
                        errorMessage: { required: "Required" },
                        fieldProps: {
                          placeholder: "is",
                          popupMatchSelectWidth: false,
                        },
                        labels: [
                          "is equal to",
                          "is not equal to",
                          "is before",
                          "is after",
                          "is before or equal to",
                          "is after or equal to",
                        ],
                      },
                    },
                    value: {
                      title: "Date Value",
                      nullable: false,
                      type: "string",
                      metadata: {
                        colon: true,
                        showTime: true,
                        hideLabel: true,
                        component: "DatePicker",
                        dateFormat: "MM/DD/YYYY",
                        showPresets: true,
                        errorMessage: { required: "Required" },
                        presetsAreRelative: true,
                        timeOptions: {
                          placeholder: "Select a time",
                          format: "HH:mm:ss",
                        },
                        fieldProps: {
                          allowClear: true,
                          placeholder: "value",
                          showTime: {
                            format: "HH:mm:ss",
                            placeholder: "Select a time",
                          },
                        },
                      },
                    },
                  },
                },
              },
              {
                if: {
                  properties: {
                    type: {
                      enum: ["string", "categorical"],
                    },
                  },
                },
                then: {
                  properties: {
                    op: {
                      title: "String Operators",
                      nullable: false,
                      type: "string",
                      enum: ["=", "!="],
                      metadata: {
                        colon: true,
                        hideLabel: true,
                        labels: ["is in", "is not in"],
                        errorMessage: { required: "Required" },
                        fieldProps: {
                          placeholder: "is",
                          popupMatchSelectWidth: false,
                        },
                      },
                    },
                  },
                },
              },
              {
                if: {
                  properties: {
                    type: {
                      enum: ["categorical", "string"],
                    },
                  },
                },
                then: {
                  properties: {
                    value: {
                      title: "Categorical Value",
                      nullable: false,
                      type: "array",
                      items: { type: "string" },
                      metadata: {
                        hideLabel: true,
                        allowInput: true,
                        component: "ColumnValueInput",
                        columnType: "categorical",
                        valuePresets: "columnValues",
                        errorMessage: { required: "Required" },
                        datasetIdName: "datasetId",
                        excludeGlobalFilters: true,
                        excludeWidgetFilters: true,
                        fieldProps: {
                          placeholder: "value",
                          popupMatchSelectWidth: false,
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
        },
        properties: {
          id: {
            type: "string",
            metadata: {
              hidden: true,
              defaultValueSource: "uid",
            },
          },
          datasetId: {
            title: "From",
            nullable: false,
            type: "number",
            metadata: {
              colon: false,
              component: "Dataset",
              errorMessage: { required: "From is required" },
              fieldProps: {
                placeholder: "dataset",
                popupMatchSelectWidth: false,
              },
            },
          },
          column: {
            title: "Show",
            nullable: false,
            type: "string",
            metadata: {
              colon: false,
              component: "ColumnSelect",
              errorMessage: { required: "Show is required" },
              fieldProps: {
                placeholder: "column",
                popupMatchSelectWidth: false,
              },
            },
          },
          type: {
            title: "Column Type",
            nullable: false,
            type: "string",
            metadata: {
              hidden: true,
            },
          },
        },
      },
    },
  },
}

export default appFiltersSchema
