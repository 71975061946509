import React, { useState, useCallback } from "react"
import { debounce } from "lodash"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { Input, Row, Col, Button, Typography } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { uuidv4 } from "@dbai/tool-box"

import { useFormData } from "../../hooks"
import { useWidgetContext } from "../../../hooks"
import RemoveButton from "../../fields/RemoveButton"

const { Text } = Typography

const valueSchema = {
  type: "object",
  properties: {
    value: {
      title: "Value",
      nullable: true,
      type: ["string", "null"],
      metadata: {
        hideLabel: true,
      },
      anyOf: [
        {
          type: ["string", "null"],
          nullable: true,
          metadata: {
            hideLabel: true,
            component: "AppVariableSelect",
          },
        },
        {
          type: "string",
          metadata: {
            hideLabel: true,
          },
        },
        {
          type: "number",
          metadata: {
            hideLabel: true,
          },
        },
        {
          type: "boolean",
          metadata: {
            hideLabel: true,
          },
        },
        {
          type: "object",
          metadata: {
            hideLabel: true,
          },
        },
      ],
    },
  },
}

const convertPairsToArray = (pairs, schemaKey) => {
  if (!pairs) return []
  return Object.entries(pairs).map(([key, value]) => {
    const keyWithoutSchemaKey = key.replace(`${schemaKey}/`, "")
    return { key: keyWithoutSchemaKey, value, domKey: uuidv4() }
  })
}

const convertPairsToObject = (pairs, schemaKey) => {
  if (!pairs) return {}
  return pairs.reduce((acc, { key, value }) => {
    const keyWithSchemaKey = `${schemaKey}/${key}`
    return { ...acc, [keyWithSchemaKey]: value }
  }, {})
}

const getPairStatus = (pairs, index) => {
  const { key } = pairs[index]
  const previousKeys = pairs.slice(0, index).map(({ key }) => key)
  const duplicateKey = previousKeys.includes(key)
  return duplicateKey ? "error" : undefined
}

const KeyValue = props => {
  const { onChange, name, schema, schemaKey } = props
  const value = useFormData(name)
  const { metadata = {} } = schema
  const { JSONSchemaForm } = useWidgetContext()
  const [pairs, setPairs] = useState(convertPairsToArray(value))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(
    debounce(pairs => {
      onChange(convertPairsToObject(pairs, schemaKey))
    }, 200),
    [schemaKey]
  )

  const handleAdd = useCallback(() => {
    const newPairs = [...pairs, { key: "", value: null, domKey: uuidv4() }]
    setPairs(newPairs)
    handleChange(newPairs)
  }, [pairs, handleChange])

  const handleKeyChange = useCallback(
    index => {
      return e => {
        const value = e.target.value
        const pairsCopy = [...pairs]
        pairsCopy[index] = { ...pairsCopy[index], key: value }
        setPairs(pairsCopy)
        handleChange(pairsCopy)
      }
    },
    [pairs, handleChange]
  )

  const handleValueChange = useCallback(
    index => {
      return ({ value }) => {
        const pairsCopy = [...pairs]
        pairsCopy[index] = { ...pairsCopy[index], value }
        setPairs(pairsCopy)
        handleChange(pairsCopy)
      }
    },

    [pairs, handleChange]
  )

  const handleRemove = useCallback(
    index => {
      return () => {
        const pairsCopy = [...pairs]
        pairsCopy.splice(index, 1)
        setPairs(pairsCopy)
        handleChange(pairsCopy)
      }
    },
    [pairs, handleChange]
  )

  return (
    <Row gutter={[16, 8]}>
      <Col span={24}>
        <Row gutter={[0, 16]}>
          {pairs.map(({ key, value, domKey }, index) => {
            const status = getPairStatus(pairs, index)
            return (
              <Col span={24} key={domKey}>
                <Row gutter={[8, 8]} wrap={false}>
                  <Col>
                    <RemoveButton onRemove={handleRemove(index)} />
                  </Col>
                  <Col flex={1}>
                    <Input
                      status={status}
                      defaultValue={key}
                      onChange={handleKeyChange(index)}
                    />
                  </Col>
                  <Col>
                    <Text>{metadata.separator ? metadata.separator : ":"}</Text>
                  </Col>
                  <Col flex={1}>
                    <JSONSchemaForm
                      hideSave
                      destroyOnUnmount
                      schema={valueSchema}
                      initialState={{ value }}
                      onFormChange={handleValueChange(index)}
                    />
                  </Col>
                </Row>
              </Col>
            )
          })}
        </Row>
      </Col>
      <Col span={24}>
        <Button
          type="dashed"
          onClick={handleAdd}
          icon={<FontAwesomeIcon icon={faPlus} />}
        >
          ADD KEY/VALUE PAIR
        </Button>
      </Col>
    </Row>
  )
}

export default KeyValue
