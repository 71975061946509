import React, { useMemo } from "react"
import { Tabs } from "antd"
import styled from "styled-components"

import LayoutWrapper from "../../LayoutWrapper"
import JSONSchemaField from "../../JSONSchemaField"
import { isSchemaHidden, getResolvedSchema } from "../../utils"

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
    z-index: 1;
  }
  .ant-tabs-content-holder {
    height: 100%;
    overflow: hidden;
    .ant-tabs-content {
      height: 100%;
    }
  }
`

const ObjectFields = props => {
  const {
    path,
    name,
    value,
    layout,
    fields,
    parentSchema,
    collapseHeaderLevel = 0,
  } = props

  const nonHiddenFields = fields.reduce((acc, [key, schema]) => {
    const resolvedSchema = getResolvedSchema(schema, parentSchema, value?.[key])

    if (isSchemaHidden(resolvedSchema, value?.[key], path)) {
      return acc
    }

    return [...acc, [key, schema]]
  }, [])

  if (!nonHiddenFields.length) return null
  return (
    <LayoutWrapper container path={path} layout={layout} schema={parentSchema}>
      {nonHiddenFields.map(([key, schema], idx) => {
        return (
          <LayoutWrapper
            key={key}
            path={path}
            schema={schema}
            layout={layout}
            parentSchema={parentSchema}
          >
            <JSONSchemaField
              path={path}
              name={name}
              schemaKey={key}
              schema={schema}
              parentSchema={parentSchema}
              collapseHeaderLevel={collapseHeaderLevel + 1}
            />
          </LayoutWrapper>
        )
      })}
    </LayoutWrapper>
  )
}

const TabSections = props => {
  const { sectionedFields, schema: parentSchema } = props

  const { metadata = {} } = parentSchema
  const { sections = {}, sectionProps = {} } = metadata
  const defaultActiveKey =
    sectionProps.defaultActiveKey?.[0] || sectionedFields[0][0]

  const items = useMemo(() => {
    return sectionedFields.map(([section, fields]) => {
      const {
        title,
        containerStyle = {
          padding: "16px",
          overflowY: "auto",
          minHeight: "100px",
          height: "100%",
        },
      } = sections[section]
      return {
        key: section,
        label: title,
        style: containerStyle,
        children: <ObjectFields {...props} fields={fields} section={section} />,
      }
    })
  }, [sectionedFields, sections, props])

  return (
    <StyledTabs type="card" items={items} defaultActiveKey={defaultActiveKey} />
  )
}

export default TabSections
