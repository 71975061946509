import React from "react"
import { Tag } from "antd"

import { scheduleSpecToSentence } from "lib/cron"

const FormatToD = ({ value }) => {
  return (
    <>
      <div>{scheduleSpecToSentence(value)}</div>
      <Tag>{value.timezone}</Tag>
    </>
  )
}

export default FormatToD
