import { actions } from "../../reducers/appReducer"
import { getChangedYMapValue, observeYjsMapDeep } from "./utils"

const handleExternalChange =
  (dispatch, onStatusChange) =>
  ({ key, yMap, yMapEvent }) => {
    const path = yMapEvent.path

    // since 'selections' is a Y.Map, changes to it will contain a path
    if (key === "selections" || path?.[0] === "selections") {
      const selections = yMap.get("selections").toJSON()
      dispatch(actions.setSelectedWidgets(selections))
    }

    if (key === "saveStatus") {
      const value = getChangedYMapValue(yMap, path, key)
      onStatusChange(value)
    }
  }

const handleLocalChange =
  dispatch =>
  ({ key, yMap, yMapEvent }) => {
    const path = yMapEvent.path

    // since 'selections' is a Y.Map, changes to it will contain a path
    if (key === "selections" || path?.[0] === "selections") {
      const selections = yMap.get("selections").toJSON()
      dispatch(actions.setSelectedWidgets(selections))
    }
  }

const metadataObserver = (yDoc, dispatch, onStatusChange) => {
  const metadataMap = yDoc.getMap("metadata")
  observeYjsMapDeep({
    yMap: metadataMap,
    onLocalChange: handleLocalChange(dispatch),
    onExternalChange: handleExternalChange(dispatch, onStatusChange),
  })
}

export default metadataObserver
