import React, { useContext } from "react"
import { Redirect } from "react-router-dom"

import { RedirectContext } from "./RedirectContextProvider"

const LoginRedirect = props => {
  const { to = "/login" } = props

  const { redirectTarget } = useContext(RedirectContext)

  if (redirectTarget === to) return null
  return <Redirect to={to} />
}

export default LoginRedirect
