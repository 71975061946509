import withUser from "./withUser"

const Authenticated = props => {
  const { noAuth, exclude, user } = props

  //take the current url and split on / to get the first part of the path after /
  //compare against the exclude list
  const path = window.location.pathname.split("/")[1]
  if (exclude && path.length > 1 && exclude.includes(path)) {
    return null
  }

  // Render if currentUser || noAuth is true AND they are not both true
  const shouldRender = (user || noAuth) && !(user && noAuth)

  if (shouldRender) {
    return props.children
  }
  return null
}

export default withUser(Authenticated)
