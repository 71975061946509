import React, { useMemo, useState, useCallback } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { Dropdown } from "antd"
import { faMarkdown } from "@fortawesome/free-brands-svg-icons"
import {
  faPlay,
  faComment,
  faTerminal,
  faChevronUp,
  faExpandAlt,
  faEllipsisV,
  faCompressAlt,
  faChevronDown,
  faFastForward,
  faFastBackward,
  faCommentSlash,
} from "@fortawesome/pro-solid-svg-icons"

import { FAMemo } from "@dbai/ui-staples"

import { actions } from "reducers/notebookReducer"
import {
  useMuteCell,
  useExecuteAfterCell,
  useExecuteBeforeCell,
} from "components/pages/Workflows/Edit/shared/util"

const StyledSpan = styled.span`
  padding-right: 5px;
  cursor: pointer;
`

const getCellTypeDetails = cellType => {
  if (cellType === "code") {
    return {
      cellTogglePrompt: "Convert To Markdown",
      cellTypeIcon: faMarkdown,
    }
  }

  return {
    cellTogglePrompt: "Convert To Code",
    cellTypeIcon: faTerminal,
  }
}

const getMuteDetails = muted => {
  if (muted) {
    return {
      mutedIcon: faComment,
      mutedPrompt: "Unmute Cell",
    }
  }

  return {
    mutedIcon: faCommentSlash,
    mutedPrompt: "Mute Cell",
  }
}

const getCollapseDetails = showCode => {
  if (showCode) {
    return {
      collapseIcon: faCompressAlt,
      collapsePrompt: "Collapse Cell",
    }
  }

  return {
    collapseIcon: faExpandAlt,
    collapsePrompt: "Expand Cell",
  }
}

const useMenuItems = (
  runCell,
  insertCell,
  closeMenu,
  appendCell,
  cell,
  toggleCellType
) => {
  const dispatch = useDispatch()
  const { cellType, uuid, metadata = {} } = cell
  const executeBeforeCell = useExecuteBeforeCell()
  const executeAfterCell = useExecuteAfterCell()
  const muteCell = useMuteCell(cell.uuid)
  const toggleCode = useCallback(() => {
    dispatch(
      actions.updateCellCodeVisibility({
        cellId: uuid,
        value: !metadata.showCode,
      })
    )
  }, [uuid, dispatch, metadata.showCode])
  const { mutedIcon, mutedPrompt } = getMuteDetails(metadata.muted)
  const { cellTogglePrompt, cellTypeIcon } = getCellTypeDetails(cellType)
  const { collapsePrompt, collapseIcon } = getCollapseDetails(metadata.showCode)

  const closeAnd = useCallback(
    (fn, ...args) => {
      return e => {
        switch (true) {
          case Boolean(args?.length):
            fn(...args)
            break
          default:
            fn(e.domEvent)
        }
        closeMenu()
      }
    },
    [closeMenu]
  )

  const items = useMemo(() => {
    return [
      {
        key: "run",
        label: "Run Cell",
        onClick: closeAnd(runCell),
        icon: <FAMemo icon={faPlay} />,
      },
      {
        key: "run-above",
        label: "Run Cells Above",
        onClick: closeAnd(executeBeforeCell, cell),
        icon: <FAMemo icon={faFastBackward} />,
      },
      {
        key: "run-below",
        label: "Run Cells Below",
        onClick: closeAnd(executeAfterCell, cell),
        icon: <FAMemo icon={faFastForward} />,
      },
      {
        key: "append",
        label: "Insert Cell Above",
        onClick: closeAnd(appendCell),
        icon: <FAMemo icon={faChevronUp} />,
      },
      {
        key: "insert",
        label: "Insert Cell Below",
        onClick: closeAnd(insertCell),
        icon: <FAMemo icon={faChevronDown} />,
      },
      {
        key: "toggle-cell-type",
        label: cellTogglePrompt,
        onClick: closeAnd(toggleCellType),
        icon: <FAMemo icon={cellTypeIcon} />,
      },
      {
        key: "collapse",
        label: collapsePrompt,
        onClick: closeAnd(toggleCode),
        icon: <FAMemo icon={collapseIcon} />,
      },
      {
        key: "mute",
        label: mutedPrompt,
        onClick: closeAnd(muteCell),
        icon: <FAMemo icon={mutedIcon} />,
      },
    ]
  }, [
    cell,
    runCell,
    muteCell,
    closeAnd,
    mutedIcon,
    appendCell,
    insertCell,
    toggleCode,
    mutedPrompt,
    collapseIcon,
    cellTypeIcon,
    collapsePrompt,
    toggleCellType,
    cellTogglePrompt,
    executeAfterCell,
    executeBeforeCell,
  ])

  return items
}

const CellMenu = props => {
  const [open, setOpen] = useState(false)
  const toggleMenu = () => setOpen(!open)
  const closeMenu = () => setOpen(false)
  const { runCell, insertCell, appendCell, cell, toggleCellType } = props
  const menuItems = useMenuItems(
    runCell,
    insertCell,
    closeMenu,
    appendCell,
    cell,
    toggleCellType
  )

  return (
    <Dropdown
      trigger={["hover"]}
      menu={{
        open,
        mode: "vertical",
        items: menuItems,
        selectable: false,
        triggerSubMenuAction: "click",
      }}
      open={open}
      onOpenChange={toggleMenu}
    >
      <StyledSpan>
        <FAMemo icon={faEllipsisV} />
      </StyledSpan>
    </Dropdown>
  )
}

export default CellMenu
