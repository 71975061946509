import { jwtDecode } from "jwt-decode"

const jwtKey = "user/jwt"

const checkTokenExpired = jwt => {
  const token = jwt ? jwtDecode(jwt) : null
  return token && Date.now() >= token.exp * 1000
}

const checkJwtExpired = () => {
  const jwt = localStorage.getItem(jwtKey)
  return checkTokenExpired(jwt)
}

const getJwt = () => localStorage.getItem(jwtKey)
const setJwt = jwt => localStorage.setItem(jwtKey, jwt)
const deleteJwt = () => localStorage.removeItem(jwtKey)

export { checkJwtExpired, checkTokenExpired, getJwt, setJwt, deleteJwt }
