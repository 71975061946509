import { toast } from "react-toastify"

const toastAndRethrow =
  (msg, priority = "error") =>
  e => {
    toast[priority](msg)
    throw e
  }

export default toastAndRethrow
