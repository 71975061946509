import { ApolloClient } from "@apollo/client"

import { apolloClient, signout } from "@dbai/ui-staples"

import config from "config"

const { cache, resolvers, initialState, constructAppLink } = apolloClient
const { graphql } = config

const invalidTokenError = "Invalid token specified"
const handleAuthorizationError = opts => {
  const { networkError } = opts

  if (networkError?.message === invalidTokenError) {
    signout(client)
  }
}

const appLink = constructAppLink(graphql, handleAuthorizationError)

const client = new ApolloClient({
  link: appLink,
  resolvers,
  uri: graphql,
  cache: cache,
})

export { appLink, resolvers, cache, initialState }
export default client
