import { useQuery, useMutation } from "@apollo/client"

import { signout } from "../lib/signout"
import { SET_CURRENT_USER, GET_CURRENT_USER } from "../queries/users"

const setter = mutation => jwt => mutation({ variables: { jwt } })

const useCurrentUser = () => {
  const { data } = useQuery(GET_CURRENT_USER)
  const [setUserMutation] = useMutation(SET_CURRENT_USER, {
    refetchQueries: [GET_CURRENT_USER],
  })

  const setUser = setter(setUserMutation)

  if (data) {
    return [data.currentUser, setUser, signout]
  }
  return [
    null,
    setUser,
    () => {
      return Promise.resolve()
    },
  ]
}

export default useCurrentUser
