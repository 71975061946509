import React, { useState, useRef, useCallback, useEffect } from "react"
import { debounce } from "lodash"
import styled from "styled-components"
import { Editor } from "@tinymce/tinymce-react"
import { useDispatch, useSelector } from "react-redux"

import { useWidgetContext } from "../../hooks"
import JSONSchemaForm from "../../JSONSchemaForm"
import { getWidgetFormId } from "../../lib/widgetEditor"
import { selectEditingWidgetId } from "../../selectors/app"
import FormFieldWrapper from "../../JSONSchemaForm/FormFieldWrapper"
import { actions } from "../../reducers/appReducer"

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  p {
    margin-bottom: unset;
    img {
      width: 100%;
      height: ${props => (props.height ? `${props.height}px` : "auto")};
    }
  }
`

const init = {
  // auto_focus: true,
  content_css: false,
  height: "100%",
  width: "100%",
  menubar: false,

  object_resizing: false,
  image_dimensions: false,

  plugins: "image editimage media mediaembed",
  toolbar: "",
  // resize_img_proportional: false,
  // convert this to "top" if you want to fix the toolbar location
  // toolbar_location: "top",
  contextmenu: false,
  paste_block_drop: true,
  file_picker_types: "image",
  file_picker_callback: (cb, value, meta) => {
    const input = document.createElement("input")
    input.setAttribute("type", "file")
    input.setAttribute("accept", "image/*")

    input.addEventListener("change", e => {
      const file = e.target.files[0]

      const reader = new FileReader()
      reader.addEventListener("load", () => {
        /*
          Note: Now we need to register the blob in TinyMCEs image blob
          registry. In the next release this part hopefully won't be
          necessary, as we are looking to handle it internally.
        */
        const id = "blobid" + new Date().getTime()
        const blobCache = tinymce.activeEditor.editorUpload.blobCache
        const base64 = reader.result.split(",")[1]
        const blobInfo = blobCache.create(id, file, base64)
        blobCache.add(blobInfo)

        /* call the callback and populate the Title field with the file name */
        cb(blobInfo.blobUri(), { title: file.name })
      })
      reader.readAsDataURL(file)
    })

    input.click()
  },
  // your configuration options
  setup: function (editor) {
    editor.on("keydown keypress paste", function (e) {
      // Prevent text input
      e.preventDefault()
      return false
    })
  },
}

const TinyMCEImageEditor = React.memo(props => {
  const { onChange, widgetId, value } = props
  const editorRef = useRef()
  const { appConfig } = useWidgetContext()
  const editingWidgetId = useSelector(selectEditingWidgetId)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChange = useCallback(debounce(onChange, 1000), [onChange])

  useEffect(() => {
    if (editingWidgetId === widgetId) {
      setTimeout(() => {
        const editorContent = tinymce.activeEditor.getContent()
        if (editorContent === "") {
          tinymce.activeEditor.execCommand("mceImage")
        }
      }, 500)
    }
  }, [editingWidgetId, widgetId])

  if (!appConfig?.tinyMCEApiKey) return null
  return (
    <Editor
      inline
      id={`widget-${widgetId}`}
      ref={editorRef}
      init={init}
      apiKey={appConfig.tinyMCEApiKey}
      initialValue={value}
      onEditorChange={debouncedChange}
    />
  )
})

// TODO: rather than updating the image through the form, we should just update the widget directly
const ImageEditor = React.memo(props => {
  const { widget, widgetId, layoutHeight } = props
  const editorRef = useRef()
  const dispatch = useDispatch()
  const formId = getWidgetFormId(widgetId)
  const { widgetSchema } = useWidgetContext()
  const editingWidgetId = useSelector(selectEditingWidgetId)
  const { maintainAspectRatio } = widget.options

  const handleFormChange = useCallback(
    ({ options, id }) => {
      return dispatch(
        actions.setWidgetOptionsWithSync({ value: options, widgetId: id })
      )
    },
    [dispatch]
  )
  const openEditor = useCallback(
    e => {
      if (editingWidgetId !== widgetId) {
        dispatch(actions.editWidget({ widgetId }))
      }
    },
    [dispatch, widgetId, editingWidgetId]
  )
  return (
    <StyledContainer
      ref={editorRef}
      onClick={openEditor}
      height={!maintainAspectRatio ? layoutHeight : null}
    >
      <JSONSchemaForm.Provider
        value={widget}
        formId={formId}
        schema={widgetSchema}
        onFormChange={handleFormChange}
      >
        <FormFieldWrapper noStyle schemaKey="image" path="./options">
          <TinyMCEImageEditor {...props} />
        </FormFieldWrapper>
      </JSONSchemaForm.Provider>
    </StyledContainer>
  )
})

const Image = props => {
  const { widget, registerOnResize, widgetId } = props
  const { editable } = useWidgetContext()
  const [layoutHeight, setLayoutHeight] = useState()
  const { maintainAspectRatio } = widget.options

  const onResize = useCallback(rect => {
    setLayoutHeight(rect.height)
  }, [])

  useEffect(() => {
    registerOnResize(() => onResize)
  }, [registerOnResize, onResize])

  if (editable) {
    return (
      <ImageEditor
        widget={widget}
        widgetId={widgetId}
        layoutHeight={layoutHeight}
      />
    )
  }

  return (
    <StyledContainer height={!maintainAspectRatio ? layoutHeight : null}>
      <div dangerouslySetInnerHTML={{ __html: widget.options.image }} />
    </StyledContainer>
  )
}

export default React.memo(Image)
