import React from "react"
import PropTypes from "prop-types"

const BreakView = props => {
  const { breakLabel, onClick } = props

  return (
    <li className="break">
      <div onClick={onClick} role="button" tabIndex="0" onKeyPress={onClick}>
        {breakLabel}
      </div>
    </li>
  )
}

BreakView.propTypes = {
  breakLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func.isRequired,
}

export default BreakView
