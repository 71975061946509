import { scalarFieldOptions } from "../../lib/fieldOptionsSchemas"

// TODO: add valueType property and add additional properties based on the value type
export default {
  type: "object",
  default: {},
  metadata: {
    layout: { gutter: [16] },
    sections: {
      ...scalarFieldOptions.metadata.sections,
      appVariableOptions: {
        title: "App Variable Options",
        properties: ["appVariableId"],
      },
    },
  },
  properties: {
    appVariableId: {
      type: "string",
      title: "Global Variable",
      metadata: {
        variableTypeName: "valueType",
        component: "AppVariableSelect",
      },
    },
    ...scalarFieldOptions.properties,
    title: {
      type: "string",
      title: "Label",
      metadata: {
        fieldProps: {
          placeholder: "Enter a label",
        },
      },
    },
  },
}
