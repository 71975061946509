import { isEqual } from "lodash"
// Selected columns may come with more attributes than whats necessary for the query api.
// This function removes those extra attributes.
const getColumnsForWidgetQuery = selectedColumns => {
  const reducedColumns = selectedColumns.map(
    ({
      type,
      column,
      format,
      aggregate,
      columnType,
      computation,
      formatOptions,
      arguments: _arguments,
    }) => ({
      type,
      column,
      format,
      aggregate,
      columnType,
      computation,
      formatOptions,
      arguments: _arguments,
    })
  )
  const uniqueColumns = reducedColumns.filter((value, index) => {
    const { column, aggregate, arguments: _arguments } = value
    return (
      index ===
      reducedColumns.findIndex(obj => {
        return (
          column === obj.column &&
          aggregate === obj.aggregate &&
          isEqual(_arguments, obj.arguments)
        )
      })
    )
  })
  return uniqueColumns
}

export default getColumnsForWidgetQuery
