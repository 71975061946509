import React from "react"
import styled from "styled-components"
import { Row, Col } from "antd"
import { PropTypes } from "prop-types"

import { useFormData, useResolvedSchema } from "./hooks"

const DEFAULT_FORM_LAYOUT = { gutter: [], span: 24 }

const StyledRow = styled(Row)``

const StyledCol = styled(Col)``

const getLayoutProps = ({ span, flex }) => {
  if (flex) return { flex }
  return { span: span || 24 }
}

/** Layout Wrapper is meant to wrap over form fields and provide a consistent layout
 * based on the schema.
 */
const LayoutWrapper = props => {
  const {
    name,
    children,
    container,
    className,
    containerStyle,
    parentSchema = {},
    schema: _schema = {},
  } = props
  const value = useFormData(name)
  const schema = useResolvedSchema(_schema, value)
  const style = containerStyle || parentSchema.metadata?.fieldProps?.style || {}

  const localLayout =
    props.layout ||
    schema.metadata?.layout ||
    parentSchema.metadata?.layout ||
    DEFAULT_FORM_LAYOUT

  const { gutter, span, wrap, flex } =
    typeof localLayout === "object" ? localLayout || {} : {}
  const GridComp = container ? StyledRow : StyledCol
  const gridProps = container
    ? { gutter: gutter ?? [], wrap }
    : getLayoutProps({ span, flex })
  return (
    <GridComp className={className} style={style} {...gridProps}>
      {children}
    </GridComp>
  )
}

LayoutWrapper.propTypes = {
  schema: PropTypes.object,
  layout: PropTypes.string,
}
export default LayoutWrapper
