import { toast } from "react-toastify"

import { GET_DATASET_CSV } from "../queries/data"

// Thunk used to export a dataset as a CSV file.
export const exportDataset =
  (datasetId, queryOptions) =>
  (dispatch, getState, { client }) => {
    const cname = getState().currentCustomer.customer.normalizedName
    const variables = {
      cname,
      datasetId,
      queryOptions,
    }

    return client
      .query({ query: GET_DATASET_CSV, variables })
      .then(({ data, error }) => {
        if (error) {
          return toast["error"](error.message)
        }
        const { csv, name } = data.customer.dataset
        if (!csv) return toast["error"]("No Data to Export")

        const numCsv = csv.length
        csv.forEach((csvData, idx) => {
          const exportableData = new Blob([csvData], {
            type: "application/csv",
          })

          const url = window.URL.createObjectURL(exportableData)

          const a = document.createElement("a")
          if (numCsv === 1) {
            a.download = `${name}.csv`
          } else {
            a.download = `${name}-${idx}.csv`
          }
          a.rel = "noopener" // tabnabbing
          a.href = url
          a.dispatchEvent(new MouseEvent("click"))
        })
      })
      .catch(error => {
        toast["error"](error.message)
      })
  }
