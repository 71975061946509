import {
  faBrowser,
  faChartMixed,
  faFilter,
  faGearComplexCode,
  faMagnifyingGlassChart,
  faImage,
  faPage,
  faSquareList,
  faTable,
  faTablePivot,
  faVectorSquare,
  faDatabase,
  faRectangle,
} from "@fortawesome/pro-solid-svg-icons"

import Form from "./Widget/Form"
import formSchema from "./Widget/Form/schema"
import Image from "./Widget/Image"
import Tabs from "./Widget/Tabs"
import Chart from "./Widget/Chart"
import Table from "./Widget/Table"
import Canvas from "./Widget/Canvas"
import FilterWidget from "./Widget/Filter"
import PageContainer from "./Widget/Container"
import pageContainerSchema from "./schemas/pageContainerSchema"
import RichText from "./Widget/RichText"
import DataEditor from "./Widget/DataEditor"
import dataEditorSchema from "./Widget/DataEditor/schema"
import PivotTable from "./Widget/PivotTable"
import tabsSchema from "./schemas/tabsSchema"
import richTextSchema from "./schemas/richTextSchema"
import imageSchema from "./schemas/imageSchema"
import filterWidgetSchema from "./schemas/filterWidgetSchema"
import chartSchema from "./schemas/chartSchema"
import customWidgetSchema from "./schemas/customWidgetSchema"
import canvasSchema from "./schemas/canvasSchema"
import tableWidgetSchema from "./schemas/tableSchema"
import pivotTableWidgetSchema from "./schemas/pivotTableSchema"
import QueryWrapper from "./Widget/WidgetWrapper/QueryWrapper"
import chartQueryResolver from "./Widget/Chart/queryResolver"
import tableQueryResolver from "./Widget/Table/queryResolver"
import pivotTableQueryResolver from "./Widget/PivotTable/queryResolver"
import FormEditor from "./WidgetEditor/FormEditor"
import CustomWidget from "./Widget/CustomWidget"

import KPI from "./Widget/KPI"
import KPISchema from "./schemas/KPISchema"
import KPIQueryResolver from "./Widget/KPI/queryResolver"

import Button from "./Widget/Button"
import buttonSchema from "./Widget/Button/schema"

import { generateFullBreakpointLayout } from "./lib/layout"

const defaultLayout = generateFullBreakpointLayout({ w: 40, h: 9 })
const widgetRegistry = {
  ChartWidget: {
    label: "Chart",
    Component: Chart,
    icon: faChartMixed,
    schema: chartSchema,
    allowFullEditor: true,
    Decorators: [QueryWrapper],
    initialLayout: defaultLayout,
    queryResolver: chartQueryResolver,
  },
  TableWidget: {
    icon: faTable,
    label: "Table",
    Component: Table,
    allowFullEditor: true,
    schema: tableWidgetSchema,
    Decorators: [QueryWrapper],
    initialLayout: defaultLayout,
    queryResolver: tableQueryResolver,
  },
  PivotTableWidget: {
    icon: faTablePivot,
    label: "Pivot Table",
    Component: PivotTable,
    allowFullEditor: true,
    Decorators: [QueryWrapper],
    initialLayout: defaultLayout,
    schema: pivotTableWidgetSchema,
    queryResolver: pivotTableQueryResolver,
  },
  DataEditor: {
    label: "Data Editor",
    icon: faDatabase,
    Component: DataEditor,
    allowFullEditor: false,
    schema: dataEditorSchema,
    initialLayout: defaultLayout,
    description:
      "Enables easy and efficient editing of warehouse table data directly within your application.",
  },
  TabWidget: {
    label: "Tabs",
    icon: faBrowser,
    container: true,
    Component: Tabs,
    schema: tabsSchema,
    allowFullEditor: false,
    initialLayout: defaultLayout,
  },
  FormWidget: {
    label: "Form",
    Component: Form,
    icon: faSquareList,
    Editor: FormEditor,
    schema: formSchema,
    allowFullEditor: true,
    initialLayout: defaultLayout,
  },
  FilterWidget: {
    label: "Filter",
    icon: faFilter,
    allowFullEditor: false,
    Component: FilterWidget,
    schema: filterWidgetSchema,
    initialLayout: defaultLayout,
  },
  CanvasWidget: {
    label: "Canvas",
    adminOnly: true,
    container: true,
    Component: Canvas,
    icon: faVectorSquare,
    schema: canvasSchema,
    allowFullEditor: false,
    initialLayout: defaultLayout,
  },
  CustomWidget: {
    label: "Custom",
    adminOnly: true,
    icon: faGearComplexCode,
    Component: CustomWidget,
    schema: customWidgetSchema,
    initialLayout: defaultLayout,
  },
  Page: {
    label: "Page",
    icon: faPage,
    container: true,
    allowFullEditor: false,
    Component: PageContainer,
    schema: pageContainerSchema,
    description: "A container for other widgets",
    initialLayout: generateFullBreakpointLayout({ w: 120, h: 10 }),
  },
  RichText: {
    label: "Text",
    icon: faPage,
    Component: RichText,
    schema: richTextSchema,
    allowFullEditor: false,
    initialLayout: generateFullBreakpointLayout({ w: 60, h: 10 }),
  },
  KPI: {
    label: "KPI",
    Component: KPI,
    schema: KPISchema,
    allowFullEditor: false,
    Decorators: [QueryWrapper],
    icon: faMagnifyingGlassChart,
    initialLayout: defaultLayout,
    queryResolver: KPIQueryResolver,
  },
  Image: {
    label: "Image",
    icon: faImage,
    Component: Image,
    schema: imageSchema,
    allowFullEditor: false,
    initialLayout: generateFullBreakpointLayout({ w: 60, h: 10 }),
  },
  Button: {
    label: "Button",
    icon: faRectangle,
    Component: Button,
    schema: buttonSchema,
    allowFullEditor: true,
    initialLayout: defaultLayout,
  },
}

// export const useWidgetRegistry = cname => {
//   const [customerWidgetTypes, setCustomerWidgetTypes] = useState([])
//   useQuery(GET_WIDGET_TYPES, {
//     skip: !cname,
//     variables: { cname },
//     onCompleted: data => {
//       setCustomerWidgetTypes(data?.customer?.widgetTypes)
//     },
//   })
//   return customerWidgetTypes.reduce((acc, widgetType) => {
//     const { source, schema, name, id } = widgetType
//     const { properties, schema: schemaOpts, form = {} } = schema
//     return {
//       ...acc,
//       [`custom-widget-${id}`]: {
//         label: name,
//         custom: true,
//         Component: CustomWidgetType,
//         // schema must be converted to JSON schema
//         schema: Form.buildJSONSchema({
//           default: {},
//           type: "object",
//           styles: { padding: "16px" },
//           title: name,
//           properties,
//           ...schemaOpts,
//         }),
//         srcDoc: source.core,
//         initialLayout: defaultLayout,
//       },
//     }
//   }, widgetRegistry)
// }

export default widgetRegistry
