import React, { useCallback, useMemo, useState, useEffect } from "react"
import styled, { css } from "styled-components"
import { Table as AntDTable, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { isArray } from "lodash"

import { alterColor } from "@dbai/tool-box"

import TableCell from "../shared/TableCell"
import queryResolver from "./queryResolver"
import { actions } from "../../reducers/formsReducer"
import useDatasetData from "../../hooks/useDatasetData"
import { getWidgetFormId } from "../../lib/widgetEditor"
import useSelectPoints from "../../hooks/useSelectPoints"
import { formatColumnValue } from "../../lib/datasetColumns"
import { getConditionalFormatQueryParams } from "../../lib/conditionalFormat"
import {
  getTableColumns,
  constructDataSource,
  checkColumnsAreAggregated,
} from "./utils"
import tableSchema, {
  tableWidgetSchemaAggregateRequired,
} from "../../schemas/tableSchema"
import { selectCrossFiltersOnPage } from "../../selectors/app"

const alternateRows = css`
  &:nth-child(odd) {
    background: ${props => alterColor(props.theme.background, -30)};
  }

  &:nth-child(even) {
    background: ${props => props.theme.background};
  }
`

const { Text } = Typography

const StyledTable = styled(AntDTable)`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;

  table thead th:first-of-type {
    border-top-left-radius: 7px;
  }
  .ant-table-selection-col,
  .ant-table-selection-column {
    padding: 0 !important;
  }
  /*
  table tr {
    ${_ => alternateRows}
  }
  */
`

const getSafeTableProps = table => {
  if (!table) return {}
  const { title, scroll, ...rest } = table
  let tableOptions = { ...rest, scroll: {} }
  if (Boolean(title)) {
    tableOptions.title = () => title
  }
  if (![null, undefined, false].includes(scroll.x)) {
    tableOptions.scroll.x = scroll.x
  }
  if (![null, undefined].includes(scroll.y)) {
    tableOptions.scroll.y = scroll.y
  }
  return tableOptions
}

const getTablePaginationProps = table => {
  if (!table) return {}
  const { pagination = {} } = table
  if (pagination.pageSize > 0) {
    return {
      ...pagination,
      showSizeChanger: false,
    }
  }
  return pagination
}

const getPosition = (verticalPosition, alignment) => {
  if (verticalPosition === "both") {
    return [`top${alignment}`, `bottom${alignment}`]
  }
  return [`${verticalPosition}${alignment}`]
}

const tableComponents = {
  body: {
    cell: TableCell,
  },
}

const getOrderBy = (sort, widgetOrderBy) => {
  const sortColumns = isArray(sort) ? sort : [sort]
  const validSortColumns = sortColumns.filter(({ column: columnOpts }) => {
    return !!columnOpts?.column && columnOpts?.columnType === "datasetColumn"
  })

  let orderBy = []
  if (validSortColumns.length) {
    orderBy = validSortColumns.map(({ column: columnOpts, order }) => {
      return `${columnOpts.column} ${order === "ascend" ? "ASC" : "DESC"}`
    })
  } else {
    orderBy = widgetOrderBy?.map(({ column, direction }) => {
      return `${column} ${direction}`
    })
  }

  return orderBy
}

const requireAggregate = (columnsAreAggregated, formId) => {
  return (dispatch, getState) => {
    if (columnsAreAggregated) {
      dispatch(
        actions.onSchemaChange({
          id: formId,
          widgetType: "TableWidget",
          value: tableWidgetSchemaAggregateRequired,
        })
      )
      return
    }

    dispatch(
      actions.onSchemaChange({
        id: formId,
        value: tableSchema,
        widgetType: "TableWidget",
      })
    )
  }
}

const useSideEffects = (columnsAreAggregated, widgetId, loading) => {
  const dispatch = useDispatch()
  const formId = useMemo(() => getWidgetFormId(widgetId), [widgetId])
  const formExists = useSelector(state =>
    Boolean(state.forms[getWidgetFormId(widgetId)])
  )
  useEffect(() => {
    if (formExists) {
      dispatch(requireAggregate(columnsAreAggregated, formId))
    }
  }, [widgetId, formExists, formId, dispatch, columnsAreAggregated])
}

const usePercentileData = (options, aggregationRequired, widgetId, pageId) => {
  const { columns, datasetId, where } = options
  // find percentiles from widget data
  const select = useMemo(() => {
    if (!columns) return []
    return columns.reduce((acc, tableColumn) => {
      // get columns that have percentile aggregate functions
      const selection = getConditionalFormatQueryParams(
        tableColumn,
        aggregationRequired
      ).filter(select => select.aggregate === "PERCENTILE_CONT")
      return [...acc, ...selection]
    }, [])
  }, [columns, aggregationRequired])
  const query = useMemo(() => {
    return { datasetId, where, select }
  }, [datasetId, where, select])

  return useDatasetData({ query, widgetId, pageId })
}

const useTotals = (options, widgetId, pageId) => {
  const { showTotals } = options?.table || {}
  const select = useMemo(() => {
    if (!options.columns || !showTotals) return []
    return options.columns.reduce(
      (acc, tableColumn) => {
        if (["int", "float"].includes(tableColumn.type.toLowerCase())) {
          return [
            ...acc,
            {
              ...tableColumn,
              aggregate: "sum",
            },
          ]
        }
        return acc
      },
      [options.columns]
    )
  }, [options.columns, showTotals])

  const query = useMemo(() => {
    return { datasetId: options.datasetId, where: options.where, select }
  }, [options.datasetId, options.where, select])

  return useDatasetData({ query, widgetId, pageId })
}

const mergeDatasets = datasets => {
  const mergedDataset = { rows: [], columns: [] }
  const columnMap = {}

  datasets.forEach(dataset => {
    if (!dataset?.columns?.length || !dataset?.rows?.length) return

    // merge the columns and update the column mapping
    dataset.columns.forEach(column => {
      if (!columnMap.hasOwnProperty(column.name)) {
        // add the new column to the result and update the column mapping
        columnMap[column.name] = mergedDataset.columns.length
        mergedDataset.columns.push(column)

        // update all existing rows with a null value for the new column
        mergedDataset.rows.forEach(row => {
          row.push(null)
        })
      }
    })

    // merge the rows
    dataset.rows.forEach((row, rowIndex) => {
      // If the merged dataset has less rows, copy the last row and add it to the merged dataset
      while (mergedDataset.rows.length <= rowIndex) {
        const newRow =
          mergedDataset.rows.length > 0
            ? [...mergedDataset.rows[mergedDataset.rows.length - 1]]
            : new Array(mergedDataset.columns.length).fill(null)
        mergedDataset.rows.push(newRow)
      }

      // copy the row values according to the column mapping
      row.forEach((value, colIndex) => {
        const mappedColIndex = columnMap[dataset.columns[colIndex].name]
        mergedDataset.rows[rowIndex][mappedColIndex] = value
      })
    })
  })

  return mergedDataset
}

const getDatasetColumnOptions = (filters, columns) => {
  if (!filters) return []
  return Object.entries(filters).reduce((acc, [dataIndex, value]) => {
    const column = columns.find(column => column.dataIndex === dataIndex)
    if (!column || [null, undefined].includes(value)) return acc
    const safeValue = isArray(value) ? value[0] : value
    return [
      ...acc,
      {
        op: "like",
        cast: "text",
        value: `%${safeValue}%`,
        column: column.name || column.column,
        aggregate: column.aggregate,
      },
    ]
  }, [])
}

const Table = props => {
  const { widget, pageId, dataset, skipped, fetchMore } = props
  const { options: widgetOptions, id } = widget
  const { showTotals } = widgetOptions?.table || {}
  const [current, setCurrent] = useState(1)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const selectPoints = useSelectPoints(
    pageId,
    widget.id,
    widgetOptions.datasetId
  )
  const selectedPoints = useSelector(state =>
    selectCrossFiltersOnPage(state, { pageId })
  )

  const columnsAreAggregated = useMemo(
    () => checkColumnsAreAggregated(widgetOptions.columns),
    [widgetOptions.columns]
  )

  const { dataset: percentileDataset, loading: percentileDataLoading } =
    usePercentileData(widgetOptions, columnsAreAggregated, id, pageId)

  const { dataset: totalsDataset, loading: totalsDataLoading } = useTotals(
    widgetOptions,
    id,
    pageId
  )

  const loading = props.loading || percentileDataLoading || totalsDataLoading

  const mergedData = useMemo(() => {
    if (!dataset?.data?.length && !percentileDataset?.data?.length) return {}
    if (!percentileDataset?.data?.length) return dataset.data[0]
    return mergeDatasets([dataset.data[0], percentileDataset.data[0]])
  }, [dataset, percentileDataset])

  const columns = useMemo(() => {
    return (
      getTableColumns(
        widgetOptions,
        dataset?.data?.[0],
        columnsAreAggregated
      ) || []
    )
  }, [widgetOptions, dataset, columnsAreAggregated])

  const dataSource = useMemo(() => {
    return constructDataSource(mergedData, columns)
  }, [mergedData, columns])

  const memoizedTableProps = useMemo(() => {
    const { pagination, ...tableProps } = getSafeTableProps(
      widgetOptions?.table
    )
    return tableProps
  }, [widgetOptions])

  const paginationOptions = useMemo(() => {
    const pagination = getTablePaginationProps(widgetOptions?.table)

    return {
      ...pagination,
      current,
      total: dataset?.meta?.count,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      position: getPosition(pagination.verticalPosition, pagination.alignment),
    }
  }, [widgetOptions, dataset, current])

  const handleChange = useCallback(
    (pagination, filters, sort) => {
      const { orderBy: widgetOrderBy } = widgetOptions
      const orderBy = getOrderBy(sort, widgetOrderBy)
      setCurrent(pagination.current)
      const filteredColumns = getDatasetColumnOptions(filters, columns)
      fetchMore({
        where: filteredColumns,
        limit: pagination.pageSize,
        offset: pagination.pageSize * (pagination.current - 1),
        orderBy,
      })
    },
    [fetchMore, columns, widgetOptions]
  )

  const handleRow = useCallback(
    record => {
      return {
        onClick: e => {
          e.preventDefault()
          e.stopPropagation()
          if (selectedRowKeys.includes(record.key)) {
            setSelectedRowKeys([])
            selectPoints([], e)
            return
          }
          setSelectedRowKeys([record.key])
          const selectedColumns = getDatasetColumnOptions(record, columns)
          selectPoints(selectedColumns, e)
        },
      }
    },
    [columns, selectPoints, selectedRowKeys]
  )

  const rowSelection = useMemo(() => {
    return { columnWidth: "0px", hideSelectAll: true, selectedRowKeys }
  }, [selectedRowKeys])

  const summary = useCallback(() => {
    if (!showTotals || !totalsDataset) return <></>
    const { rows: totalsRows, columns: totalsColumns } = totalsDataset
      ?.data[0] || { rows: [], columns: [] }
    return (
      <>
        <AntDTable.Summary.Row>
          <AntDTable.Summary.Cell />
          <AntDTable.Summary.Cell>
            <Text strong>Total</Text>
          </AntDTable.Summary.Cell>
          {columns.map(column => {
            const { dataIndex, type } = column
            const columnIndex = totalsColumns.findIndex(
              c => c.column === column.column
            )
            if (dataIndex === "Total") return <></>
            if (!["int", "float"].includes(type.toLowerCase())) {
              return (
                <AntDTable.Summary.Cell key={dataIndex}>
                  -
                </AntDTable.Summary.Cell>
              )
            }
            if (columnIndex === -1) {
              return (
                <AntDTable.Summary.Cell key={dataIndex}>
                  -
                </AntDTable.Summary.Cell>
              )
            } else {
              const total = totalsRows.reduce((acc, row) => {
                const value = row[columnIndex]
                if (value === null) return acc
                return acc + value
              }, 0)
              return (
                <AntDTable.Summary.Cell key={dataIndex}>
                  <Text strong>{formatColumnValue(column, total)}</Text>
                </AntDTable.Summary.Cell>
              )
            }
          })}
        </AntDTable.Summary.Row>
      </>
    )
  }, [showTotals, totalsDataset, columns])

  useSideEffects(columnsAreAggregated, widget.id, loading)

  useEffect(() => {
    setCurrent(1)
    if (!skipped && widgetOptions.offset > 0) {
      fetchMore && fetchMore({ offset: 0 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset?.meta?.count])

  useEffect(() => {
    const selectedPointsFromWidget = selectedPoints.filter(
      p => p.widgetId === widget.id
    )

    // ignore if the selected points are from the current widget
    if (selectedPointsFromWidget.length && selectedRowKeys.length) return

    // if other points are selected, clear the selected rows
    if (!selectedPointsFromWidget.length && selectedRowKeys.length) {
      setSelectedRowKeys([])
    }
  }, [selectedPoints, selectedRowKeys, widget.id])

  return (
    <StyledTable
      onRow={handleRow}
      columns={columns}
      loading={loading}
      summary={summary}
      onChange={handleChange}
      dataSource={dataSource}
      rowSelection={rowSelection}
      components={tableComponents}
      pagination={paginationOptions}
      {...memoizedTableProps}
    />
  )
}

const MemoizedTable = React.memo(Table)
MemoizedTable.queryResolver = queryResolver
export default MemoizedTable
