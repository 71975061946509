import genericWidgetTheme from "./genericWidgetTheme"
import formatOptionsSchema from "./formatOptionsSchema"
import getConditionalFormatSchema from "./shared/getConditionalFormatSchema"
import {
  numericalColumnSchema,
  categoricalColumnSchema,
} from "./datasetColumnSchema"

const KPISchema = {
  type: "object",
  default: {},
  title: "Widget",
  required: ["datasetId"],
  properties: {
    datasetId: {
      $ref: "#/$defs/datasetId",
      metadata: {
        validateFirst: true,
        component: "Dataset",
        wrapperStyle: {
          padding: "0 16px",
        },
        fieldProps: {
          placeholder: "Select dataset",
          popupMatchSelectWidth: false,
        },
        errorMessage: {
          required: "Dataset is required",
        },
      },
    },
  },
  dependencies: {
    datasetId: {
      metadata: {
        sectionProps: {
          defaultActiveKey: ["data"],
        },
        sections: {
          data: {
            title: "Data",
            properties: ["datasetId", "/datasetColumn", "where"],
            containerStyle: {
              padding: 0,
            },
          },
          appearance: {
            title: "Appearance",
            properties: ["showTitle", "vPosition", "hPosition", "theme"],
          },
          conditionalFormatting: {
            title: "Conditional Formatting",
            properties: ["conditionalFormat"],
          },
        },
      },
      properties: {
        datasetId: {
          metadata: {
            wrapperStyle: {
              padding: "0",
            },
          },
        },
        showTitle: {
          type: "boolean",
          default: true,
          title: "Show Title",
        },
        theme: genericWidgetTheme,
        vPosition: {
          type: "string",
          default: "flex-start",
          title: "Vertical Position",
          enum: ["flex-start", "center", "flex-end"],
          metadata: {
            component: "Segmented",
            labels: ["Top", "Middle", "Bottom"],
          },
        },
        hPosition: {
          type: "string",
          default: "flex-start",
          title: "Horizontal Position",
          enum: ["flex-start", "center", "flex-end"],
          metadata: {
            component: "Segmented",
            labels: ["Left", "Middle", "Right"],
          },
        },
        "/datasetColumn": {
          required: ["column", "aggregate"],
          metadata: {
            layout: { gutter: [16] },
          },
          type: "object",
          dependencies: {
            type: {
              allOf: [
                {
                  if: {
                    properties: {
                      type: {
                        enum: ["datetime"],
                      },
                    },
                  },
                  then: {
                    type: "object",
                    dependencies: formatOptionsSchema.dependencies,
                    properties: {
                      aggregate: {
                        type: ["string", "null"],
                        title: "Aggregate",
                        enum: ["min", "max"],
                        nullable: true,
                        metadata: {
                          layout: { span: 24 },
                          validateFirst: true,
                          labels: ["Minimum", "Maximum"],
                          fieldProps: {
                            placeholder: "(All)",
                            popupMatchSelectWidth: false,
                          },
                        },
                      },
                      format: {
                        type: "string",
                        title: "Format",
                        default: "",
                        enum: ["", "number", "string", "datetime"],
                        metadata: {
                          layout: { span: 24 },
                          labels: ["Default", "Number", "Text", "Datetime"],
                          fieldProps: {
                            placeholder: "Select a format",
                          },
                        },
                      },
                    },
                  },
                },
                {
                  if: {
                    properties: {
                      type: {
                        enum: ["numerical", "float", "int"],
                      },
                    },
                  },
                  then: numericalColumnSchema,
                },
                {
                  if: {
                    properties: {
                      type: {
                        enum: ["string", "categorical"],
                      },
                    },
                  },
                  then: categoricalColumnSchema,
                },
              ],
            },
          },
          properties: {
            column: {
              title: "Column",
              type: "string",
              metadata: {
                validateFirst: true,
                component: "ColumnSelect",
                fieldProps: {
                  popupMatchSelectWidth: false,
                },
              },
            },
            type: {
              $ref: "#/$defs/dataColumnType",
              metadata: { hidden: true },
            },
          },
        },
        where: {
          title: "Filters",
          default: [],
          type: "array",
          metadata: {
            layout: { gutter: [] },
            component: "DnDDropzone",
            fieldProps: {
              style: { minHeight: "100px" },
            },
          },
          items: {
            type: "object",
            required: ["column", "type"],
            properties: {
              column: { $ref: "#/$defs/dataColumn" },
              type: { $ref: "#/$defs/dataColumnType" },
              op: { $ref: "#/$defs/scalarOperators" },
              conj: { $ref: "#/$defs/conjunction" },
              value: { $ref: "#/$defs/scalarValue" },
              conditions: {
                type: "array",
                items: {
                  type: "object",
                  required: ["column", "type"],
                  properties: {
                    column: { $ref: "#/$defs/dataColumn" },
                    type: { $ref: "#/$defs/dataColumnType" },
                    op: { $ref: "#/$defs/scalarOperators" },
                    conj: { $ref: "#/$defs/conjunction" },
                    value: { $ref: "#/$defs/scalarValue" },
                    conditions: {
                      $ref: "#/$defs/queryWhere",
                      type: ["array", "null"],
                    },
                  },
                },
              },
            },
          },
        },
        conditionalFormat: getConditionalFormatSchema(true, true),
      },
    },
  },
}

export default KPISchema
