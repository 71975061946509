import React, { useMemo, useState } from "react"
import styled from "styled-components"
import { Tabs as AntTabs } from "antd"

import AppPage from "../../shared/AppPage"
import NavItemTitle from "../shared/NavItemTitle"
import useNavItemsSideEffect from "../../hooks/useNavItemsSideEffect"

const StyledTabs = styled(AntTabs)`
  width: 100%;
  height: 100%;
  .ant-tabs-content {
    height: 100%;
    .ant-tabs-tabpane {
      height: 100%;
    }
  }
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-content-holder {
    overflow: auto;
  }
`

const Tabs = props => {
  const { editing, widget, widgetId } = props
  const {
    tabType,
    centered,
    tabPosition,
    tabBarGutter,
    navItems = [],
    defaultActiveKey,
    destroyInactiveTabPane,
  } = widget.options
  useNavItemsSideEffect(widgetId, editing)
  const [activeKey, setActiveKey] = useState()

  const safeNavItems = useMemo(() => {
    return navItems?.map(({ pageId, title }, idx) => {
      return {
        title,
        key: `${pageId}`,
        label: <NavItemTitle title={title} pageId={pageId} />,
        children: pageId ? (
          <AppPage name={title} pageId={pageId} widgetId={widgetId} />
        ) : null,
      }
    })
  }, [navItems, widgetId])

  if (!safeNavItems?.length) return null
  return (
    <StyledTabs
      type={tabType}
      items={safeNavItems}
      centered={centered}
      onTabClick={setActiveKey}
      tabPosition={tabPosition}
      tabBarGutter={tabBarGutter}
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey || defaultActiveKey}
      destroyInactiveTabPane={destroyInactiveTabPane}
    />
  )
}

export default React.memo(Tabs)
