import React, { useMemo, useCallback } from "react"
import { Tag } from "antd"
import { useDispatch, useSelector } from "react-redux"

import { TableWithFolders, DeleteButton } from "@dbai/ui-staples"

import { actions } from "reducers/scheduleReducer"
import { selectScheduleEmailNotifications } from "selectors"

const Addresses = props => {
  const { value: addresses = [] } = props
  return (
    <div>
      {addresses.map(address => (
        <Tag key={address}>{address}</Tag>
      ))}
    </div>
  )
}

const Actions = props => {
  const { row } = props
  const dispatch = useDispatch()
  const handleDelete = useCallback(() => {
    dispatch(actions.deleteNotification(row.id))
  }, [dispatch, row.id])

  return <DeleteButton confirmDelete={true} onDelete={handleDelete} />
}

const columns = [
  {
    title: "Event",
    dataIndex: "eventType",
    key: "eventType",
  },
  {
    title: "Addresses",
    dataIndex: "addresses",
    key: "addresses",
    render: props => <Addresses {...props} />,
  },
]

const columnOrder = ["eventType", "addresses", "actions"]

const NotificationsTable = () => {
  const notifications = useSelector(selectScheduleEmailNotifications)
  const data = useMemo(
    () =>
      notifications.map(notification => {
        return {
          id: notification.id,
          eventType: notification.eventType,
          addresses: notification.details.addresses,
        }
      }),
    [notifications]
  )

  return (
    <TableWithFolders
      rowKey="id"
      columns={columns}
      dataSource={data}
      Actions={Actions}
      folderType="notifications"
      columnOrder={columnOrder}
    />
  )
}

export default NotificationsTable
