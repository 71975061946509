import React, { useState, useRef, useCallback } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"

import { useResizeObserver } from "@dbai/ui-staples"

import { actions } from "./reducers/appReducer"
import useWidgetContext from "./hooks/useWidgetContext"
import { defaultBreakpointThresholds } from "./lib/layout"
import { selectAppBreakpoint, selectAppSpec } from "./selectors/app"

const BreakpointIndicator = styled.div`
  ${({ shouldDisplay }) => {
    if (!shouldDisplay) {
      return `
    color: transparent;
    background: transparent;
    `
    }
    return `
  color: white;
  background: rgba(0, 0, 0, 0.7);
  `
  }}
  right: 0;
  bottom: 0;
  padding: 0.5em;
  position: absolute;
  pointer-events: none;
  transition: all 0.5s ease;
`

const StyledBreakpointObserver = styled.div`
  position: relative;
`

const breakpointNameMap = {
  xs: "Extra Small",
  sm: "Small",
  md: "Medium",
  lg: "Large",
}

const getBreakpointThresholds = breakpointConfigs => {
  if (!breakpointConfigs) return defaultBreakpointThresholds
  return Object.entries(breakpointConfigs).reduce((acc, [key, value]) => {
    acc[key] = value.pxVal
    return acc
  }, {})
}

export const getCurrentBreakpoint = (breakpoints, elWidth) => {
  const bpThresholds = getBreakpointThresholds(breakpoints)
  const bps = Object.entries(bpThresholds)
  return bps.reduce((acc, [nextKey, nextValue]) => {
    const current = bpThresholds[acc]
    if (elWidth < nextValue && current > nextValue) return nextKey
    return acc
  }, "lg")
}

const BreakpointObserver = props => {
  const { children } = props
  const ref = useRef()
  const timeoutId = useRef()
  const dispatch = useDispatch()
  const { editable } = useWidgetContext()
  const [resolution, setResolution] = useState({})
  const { breakpoints } = useSelector(selectAppSpec)
  const currentBreakpoint = useSelector(selectAppBreakpoint)
  const [displayBreakpoint, setDisplayBreakpoint] = useState(false)

  const updateBreakpoint = useCallback(() => {
    const width = ref.current?.innerWidth || ref.current?.clientWidth
    const breakpoint = getCurrentBreakpoint(breakpoints, width)
    if (currentBreakpoint !== breakpoint) {
      dispatch(actions.setBreakpoint(breakpoint))
      const height = ref.current.innerHeight || ref.current.clientHeight
      setResolution({
        width,
        height,
      })
      if (timeoutId.current) clearTimeout(timeoutId.current)
      setDisplayBreakpoint(true)
      const id = setTimeout(() => {
        setDisplayBreakpoint(false)
      }, 3000)
      timeoutId.current = id
    }
  }, [dispatch, currentBreakpoint, breakpoints])

  useResizeObserver(ref, updateBreakpoint)

  return (
    <StyledBreakpointObserver ref={ref}>
      {children}
      {editable ? (
        <BreakpointIndicator shouldDisplay={displayBreakpoint}>
          {resolution.width} x {resolution.height} | Current Breakpoint:{" "}
          {breakpointNameMap[currentBreakpoint] || currentBreakpoint}
        </BreakpointIndicator>
      ) : null}
    </StyledBreakpointObserver>
  )
}

export default BreakpointObserver
