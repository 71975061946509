import React, { useCallback, useState } from "react"
import { useQuery } from "@apollo/client"
import { useParams, Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUpload, faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons"

import { SidePane } from "@dbai/ui-staples"

import PublishForm from "./PublishForm"
import HeaderButton from "../HeaderButton"
import PublishHistory from "./PublishHistory"
import SidePaneSection from "../SidePaneSection"
import { GET_APP_SNAPSHOTS } from "../../queries/app"

const SidePaneContent = () => {
  const { id: appId, cname } = useParams()
  const [appSnapshots, setAppSnapshots] = useState([])

  const { loading, refetch } = useQuery(GET_APP_SNAPSHOTS, {
    skip: !appId || !cname,
    variables: { appId, cname },
    onCompleted: results => {
      setAppSnapshots(results?.customer?.appSnapshots)
    },
  })

  const handlePublish = useCallback(() => {
    refetch()
  }, [refetch])

  return (
    <SidePaneSection>
      {appSnapshots.length ? (
        <Link to={`/${cname}/apps/${appId}/current`}>
          <FontAwesomeIcon icon={faUpRightFromSquare} /> View Published App
        </Link>
      ) : null}
      <SidePaneSection title="Publish a New Version">
        <PublishForm onPublish={handlePublish} />
      </SidePaneSection>
      <SidePaneSection title="Publish History">
        <PublishHistory loading={loading} appSnapshots={appSnapshots} />
      </SidePaneSection>
    </SidePaneSection>
  )
}

const PublishApp = () => {
  const [open, setOpen] = useState(false)
  const closePanel = () => setOpen(false)
  const openPanel = () => setOpen(true)
  return (
    <>
      <HeaderButton
        onClick={openPanel}
        title="Publish"
        icon={faUpload}
        data-testid="app-header-publish-button"
      />
      <SidePane
        width="60vw"
        closeOnClickAway
        isVisible={open}
        title="App Snapshots"
        onCloseClicked={closePanel}
      >
        <SidePaneContent />
      </SidePane>
    </>
  )
}

export default PublishApp
