import { gql } from "@apollo/client"

const APP_WIDGET_FRAGMENT = gql`
  fragment AppWidgetFragment on AppWidget {
    type
    name
    options
  }
`

export const GET_APP_WIDGET = gql`
  ${APP_WIDGET_FRAGMENT}
  query GetAppWidget($id: Int!, $cname: String!) {
    customer(name: $cname) {
      id
      appWidget(id: $id) {
        id
        ...AppWidgetFragment
      }
    }
  }
`
