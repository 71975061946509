import React, { memo } from "react"

import ArrayList from "./ArrayList"
import ObjectList from "./ObjectList"
import TableList from "./TableList"

const FormList = props => {
  const { schema: parentSchema } = props
  const { metadata = {} } = parentSchema
  const { listType } = metadata

  // Item lists are arrays of schemas, while object lists are arrays of object keys, where
  // key is a single schema
  const isArrayList = Boolean(parentSchema.items)

  if (
    isArrayList &&
    listType === "table" &&
    parentSchema?.items?.type === "object"
  ) {
    return <TableList {...props} />
  }

  return isArrayList ? <ArrayList {...props} /> : <ObjectList {...props} />
}

export default memo(FormList)
