import { createSlice } from "@reduxjs/toolkit"

import { createCommonActions } from "@dbai/tool-box"

import client from "apolloClient"
import { GET_PACKAGES } from "queries"

const initialState = {
  packages: [],
}

const packagesSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    ...createCommonActions(initialState),
  },
})

const { actions } = packagesSlice

const loadPackages = variables => (dispatch, getState) => {
  const query = GET_PACKAGES
  return client.query({ query, variables }).then(({ data }) => {
    dispatch(
      actions.set({
        name: "packages",
        value: data.customer.packages,
      })
    )
  })
}

const allActions = {
  loadPackages,
  ...actions,
}

export { allActions as actions }
export default packagesSlice
