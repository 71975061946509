import React, { useState } from "react"
import styled from "styled-components"
import { Tag, AutoComplete } from "antd"
import { useQuery } from "@apollo/client"

import {
  useFormState,
  useFormActions,
  GET_CUSTOMER_USER_EMAILS,
} from "@dbai/ui-staples"

const emailsToOptions = data => {
  if (data?.customer?.users) {
    return data.customer.users.map(user => ({ value: user.email }))
  }
  return []
}

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
`

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const TagWrapper = styled.div`
  margin-top: 5px;
`

const AddressTag = props => {
  const { address, addresses, formSet } = props
  const handleClose = () => {
    formSet({
      name: "addresses",
      value: addresses.filter(a => a !== address),
    })
  }
  return (
    <Tag key={address} onClose={handleClose} closable>
      {address}
    </Tag>
  )
}

const CurrentAddresses = props => {
  const { addresses, formSet } = props
  if (addresses.length === 0) {
    return (
      <TagWrapper>
        <Tag>None</Tag>
      </TagWrapper>
    )
  }

  return (
    <TagWrapper>
      {addresses.map(address => {
        return (
          <AddressTag
            key={address}
            address={address}
            addresses={addresses}
            formSet={formSet}
          />
        )
      })}
    </TagWrapper>
  )
}

const EmailAutoComplete = props => {
  const { cname } = props
  const addresses = useFormState("addresses")
  const { formSet } = useFormActions()
  const [value, setValue] = useState("")
  const { data } = useQuery(GET_CUSTOMER_USER_EMAILS, {
    variables: { cname },
  })
  const emails = emailsToOptions(data)
  const handleChange = val => {
    setValue(val)
  }

  const handleSelect = val => {
    if (val) {
      const newAddresses = Array.from(new Set([...addresses, val]))
      formSet({ name: "addresses", value: newAddresses })
      setValue("")
    }
  }

  const handleEnter = e => {
    if (e?.key === "Enter") {
      e.stopPropagation()
      e.preventDefault()
      handleSelect(e.target.value)
    }
  }

  return (
    <Wrapper>
      <StyledAutoComplete
        filterOption
        value={value}
        options={emails}
        onSelect={handleSelect}
        onChange={handleChange}
        onKeyDown={handleEnter}
        placeholder="Enter an email address"
      />
      <CurrentAddresses addresses={addresses} formSet={formSet} />
    </Wrapper>
  )
}

export default EmailAutoComplete
