import { curry } from "lodash"

const constructTitle = curry(options => {
  const { title = {}, highchart = {}, name: widgetName } = options || {}
  if (!Object.keys(title).length) {
    return {
      ...options,
      highchart: { ...highchart, title: { text: undefined } },
    }
  }

  const { showTitle, verticalAlign, ...titleOptions } = title
  return {
    ...options,
    highchart: {
      ...highchart,
      title: {
        ...titleOptions,
        text: showTitle ? widgetName : undefined,
        verticalAlign: verticalAlign?.length ? verticalAlign : undefined,
      },
    },
  }
})

export default constructTitle
