import React, { useCallback } from "react"
import { capitalize } from "lodash"
import styled from "styled-components"
import { Button, Tooltip } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faText, faHashtag } from "@fortawesome/pro-solid-svg-icons"

import { useFormContext } from "../hooks"
import FormFieldWrapper from "../FormFieldWrapper"

const StyledIconButton = styled(Button)`
  &&& svg {
    margin-right: 0;
    font-size: 8px;
  }
`

const IconButtons = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  max-height: 32px;
  && .ant-btn {
    width: 16px;
    height: 16px;
    font-size: 8px;
    border-radius: 0;
    border-left: none;
  }
`

const FieldContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
`

const getNonNullTypes = schema => {
  return schema.type.filter(t => t !== "null")
}

const getCurrentFieldType = (value, types) => {
  return [null, undefined].includes(value) ? types[0] : typeof value
}

const FieldIcon = props => {
  const { type } = props
  switch (type) {
    case "string":
      return <FontAwesomeIcon icon={faText} className="db-icon" />
    case "number":
      return <FontAwesomeIcon icon={faHashtag} className="db-icon" />
    default:
      return null
  }
}

const MixedInputCore = props => {
  const { value, schema, onChange, path } = props
  const types = getNonNullTypes(schema)
  const { getFormField } = useFormContext()
  const currentType = getCurrentFieldType(value, types)

  const changeType = useCallback(
    type => () => {
      switch (type) {
        case "string":
          return onChange(`${value}`)
        case "number":
          return onChange(parseFloat(value))
        default:
          return null
      }
    },
    [value, onChange]
  )

  const Component = getFormField({ ...schema, type: currentType }, path)
  return (
    <FieldContainer>
      <Component.Core {...props} />
      <IconButtons>
        {types.map(type => {
          return (
            <Tooltip key={type} title={capitalize(type)}>
              <StyledIconButton
                key={type}
                size="small"
                onClick={changeType(type)}
                icon={<FieldIcon type={type} />}
                type={type === currentType ? "primary" : "ghost"}
              />
            </Tooltip>
          )
        })}
      </IconButtons>
    </FieldContainer>
  )
}

const MixedInput = props => {
  return (
    <FormFieldWrapper {...props}>
      <MixedInputCore />
    </FormFieldWrapper>
  )
}

export default MixedInput
