const formatColumnLabelsSchema = {
  default: {},
  type: "object",
  title: "Format Options",
  metadata: {
    noStyle: true,
    component: "ColumnLabels",
    labelsSchemaKey: "formatOptions/labels",
  },
  properties: {
    "formatOptions/labels": {
      default: [],
      type: "array",
      title: "Labels",
      metadata: {
        hideLabel: true,
        hideAdd: true,
      },
      items: {
        type: "object",
        properties: {
          label: {
            type: "string",
            title: "Label",
          },
          usable: {
            type: "boolean",
            title: "Visible",
          },
          title: {
            type: "string",
            title: "Title",
            metadata: {
              hideLabel: true,
              validateFirst: true,
              fieldProps: {
                placeholder: "<Current Label>",
              },
            },
          },
        },
      },
    },
  },
}

export default formatColumnLabelsSchema
