import React, { useRef, useCallback, useState, useEffect, useMemo } from "react"
import styled from "styled-components"
import { toast } from "react-toastify"
import { Layout } from "antd"
import { useQuery } from "@apollo/client"
import { useDispatch, useSelector } from "react-redux"
import { Panel, PanelGroup } from "react-resizable-panels"

import { ErrorMessage, PageSpinner } from "@dbai/ui-staples"

import Widget from "./Widget"
import Toolbar from "./Toolbar"
import AppPage from "./shared/AppPage"
import AppConfig from "./AppConfig"
import AppHeader from "./AppHeader"
import { useYjs } from "./hooks"
import AppletView from "./AppletView"
import YjsProvider from "./YjsProvider"
import { GET_APP } from "./queries/app"
import ContextMenu from "./shared/ContextMenu"
import { actions } from "./reducers/appReducer"
import ControlPanel from "./ControlPanel"
import widgetRegistry from "./widgetRegistry"
import PanelResizeHandle from "./WidgetEditor/PanelResizeHandle"
import BreakpointObserver from "./BreakpointObserver"
import WidgetEditorProvider from "./WidgetEditor"
import WidgetContextWrapper from "./WidgetContextWrapper"
import { selectFullWidgetEditorOpen } from "./selectors/app"

const StyledLayout = styled.div`
  .widget-editor {
    height: 100%;
  }
`

const FullHeightLayout = styled(Layout)`
  height: calc(100vh - 32px);
`

const FullHeightPanel = styled(Panel)`
  height: 100%;
`

const StyledLayoutSider = styled.div`
  height: 100vh;
  overflow: auto;
  ${({ theme }) => {
    if (theme.themeMode === "light") {
      // use default light mode background
      return
    }
    // we are overriding the dark mode background color here since the
    // default background color is a bit harsh in this context
    return `
    background: ${theme.header}
  `
  }}
`

const AppEditor = props => {
  const { app, appId, cname, appStatus } = props
  const controlPanelRef = useRef(null)
  const [showSider, setShowSider] = useState(true)
  const fullEditorOpen = useSelector(selectFullWidgetEditorOpen)

  const dispatch = useDispatch()

  const toggleShowSider = useCallback(() => {
    if (showSider) {
      controlPanelRef.current.collapse()
      dispatch(actions.closeWidgetEditor())
    } else {
      controlPanelRef.current.expand()
    }
    setShowSider(!showSider)
  }, [dispatch, showSider])

  const handleCollapse = useCallback(collapsed => {
    setShowSider(!collapsed)
  }, [])

  const defaultSize = useMemo(() => {
    const width = window.innerWidth
    if (width < 1920) {
      return 35
    } else {
      return 25
    }
  }, [])

  useEffect(() => {
    return () => {
      dispatch(actions.reset())
    }
  }, [dispatch])

  const { spec } = app

  const handleWidgetEditorSave = useCallback(
    (data, errors, changed) => {
      const { name, value } = changed
      // Do not autosave when full widget editor is open
      if (fullEditorOpen) return
      if (name === "name") {
        dispatch(actions.setWidgetWithSync({ name, value, widgetId: data.id }))
        return
      }
      if (name.startsWith("layout")) {
        dispatch(
          actions.setWidgetLayoutWithSync({
            widgetId: data.id,
            value: data.layout,
          })
        )
        return
      }
      dispatch(
        actions.setWidgetOptionsWithSync({
          widgetId: data.id,
          value: data.options,
        })
      )
    },
    [dispatch, fullEditorOpen]
  )

  return (
    <WidgetContextWrapper
      app={app}
      appId={appId}
      Widget={Widget}
      widgetRegistry={widgetRegistry}
      {...props}
    >
      <WidgetEditorProvider onFormChange={handleWidgetEditorSave}>
        <StyledLayout>
          <PanelGroup direction="horizontal" autoSaveId="widget-editor-panels">
            <Panel order={1} minSize={10}>
              <Toolbar
                logo={app.logo}
                cname={cname}
                pageId={spec.rootPageId}
                appStatus={appStatus}
                showSider={showSider}
                toggleShowSider={toggleShowSider}
              />
              <BreakpointObserver>
                <ContextMenu>
                  <AppConfig>
                    <FullHeightLayout>
                      <AppHeader />
                      <Layout.Content>
                        <AppPage
                          name="Home Page"
                          toggleShowSider={toggleShowSider}
                          showSider={showSider}
                          pageId={spec.rootPageId}
                        />
                      </Layout.Content>
                    </FullHeightLayout>
                  </AppConfig>
                </ContextMenu>
              </BreakpointObserver>
            </Panel>
            {showSider ? <PanelResizeHandle /> : null}
            <FullHeightPanel
              order={2}
              collapsible
              minSize={10}
              defaultSize={defaultSize}
              ref={controlPanelRef}
              onCollapse={handleCollapse}
            >
              <StyledLayoutSider
                collapsible
                width="100%"
                trigger={null}
                collapsedWidth={0}
                collapsed={!showSider}
              >
                <div className="widget-editor">
                  <ControlPanel widgetRegistry={widgetRegistry} />
                </div>
              </StyledLayoutSider>
            </FullHeightPanel>
          </PanelGroup>
        </StyledLayout>
      </WidgetEditorProvider>
    </WidgetContextWrapper>
  )
}

const ProtectedAppEditor = props => {
  const { doc, loading, error } = useYjs()
  if (loading) return <PageSpinner />
  if (error) toast.error("Error loading app editor")
  if (!doc || error) return <AppletView {...props} editable={false} />
  return <AppEditor {...props} />
}

const AppEditorContainer = props => {
  const { cname, appId, appConfig } = props
  const [appStatus, setAppStatus] = useState("idle")

  const dispatch = useDispatch()
  const { data, loading, error } = useQuery(GET_APP, {
    variables: { cname, appId },
    fetchPolicy: "network-only",
    onCompleted: results => {
      const app = results.customer.app
      dispatch(actions.load({ ...app, loading: false }))
    },
  })

  if (loading) return <PageSpinner />
  if (error) return <ErrorMessage error={error} />

  return (
    <YjsProvider
      url={appConfig?.yjs}
      resourceId={appId}
      onStatusChange={setAppStatus}
    >
      <ProtectedAppEditor
        {...props}
        app={data?.customer?.app}
        appStatus={appStatus}
      />
    </YjsProvider>
  )
}

export default AppEditorContainer
