import { v4 as uuidv4 } from "uuid"
import { isEqual } from "lodash"

import { getResolvedSchema } from "./schema"
import { isRelativeDt, computeRelativeDt } from "./datetime"

const valueMethods = {
  uid: () => uuidv4(),
}

const getDefaultDatePickerValue = schema => {
  const defaultValue = schema.default
  // if value is a relative time, compute the datetime value for the date picker
  if (isRelativeDt(defaultValue)) {
    const date = computeRelativeDt(defaultValue)
    return date.toISOString()
  }
  return defaultValue
}

const getDefaultValueForSchema = ({ schema, data, name, rootData }) => {
  const { metadata = {} } = schema
  const { defaultValueSource, getDefaultValue, component, columnType } =
    metadata
  switch (true) {
    case Boolean(getDefaultValue) && typeof getDefaultValue === "function":
      return getDefaultValue({ name, data, rootData })
    case component === "DatePicker" ||
      (component === "ColumnValueInput" && columnType === "datetime"):
      return getDefaultDatePickerValue(schema)
    case schema.default !== undefined:
      return schema.default
    case Boolean(defaultValueSource):
      const getValue = valueMethods[defaultValueSource]
      return getValue()
    default:
      return undefined
  }
}

const getDefaultArrayData = ({ name, data, rootData, schema, rootSchema }) => {
  let existingArray = data || []

  if (!existingArray.length) {
    const defaultValue = getDefaultValueForSchema({
      name,
      data,
      schema,
      rootData,
    })
    if (!defaultValue) return []
    existingArray = defaultValue
  }

  const newArray = existingArray.map((item, index) => {
    const itemData = getDataWithDefaultValues({
      name: name ? [...name, index] : [index],
      data: item,
      schema: schema.items,
      rootData,
      rootSchema,
    })
    if (!itemData || !Object.keys(itemData).length) return item
    return itemData
  })
  return newArray
}

const getSchemaType = schema => {
  if (Array.isArray(schema.type)) {
    return schema.type.filter(t => t !== "null")[0]
  }
  return schema.type
}

const getDefaultObjectData = ({ name, data, rootData, schema, rootSchema }) => {
  return Object.entries(schema?.properties || {}).reduce(
    (acc, [key, subschema]) => {
      const value = getDataWithDefaultValues({
        name: name ? [...name, key] : [key],
        data: data?.[key],
        schema: subschema,
        rootData,
        rootSchema,
      })
      if (value === undefined) return acc
      return { ...acc, [key]: value }
    },
    {}
  )
}

const getDataWithDefaultValues = ({
  name,
  data,
  schema,
  rootData = data,
  rootSchema = schema,
}) => {
  const resolvedSchema = getResolvedSchema(schema, rootSchema, data)
  const schemaType = getSchemaType(resolvedSchema)
  const arrayItemType = resolvedSchema.items
    ? getSchemaType(resolvedSchema.items)
    : null

  let processedData
  switch (true) {
    // if the schema is nullable and the value is null, then maintain that null value here
    case resolvedSchema.nullable && data === null:
      processedData = data
      break
    // if the schema is nullable and it does not exist in the current form data, then compute default value
    case schema.nullable && data === undefined:
      const defaultValue = getDefaultValueForSchema({
        name,
        data,
        schema,
        rootData,
      })
      if (defaultValue === undefined) break

      // if a default value exists, set it here. if the value is removed later, it will be set to null
      processedData = defaultValue
      break
    case schemaType === "array" && arrayItemType === "object":
      processedData = getDefaultArrayData({
        name,
        data,
        schema: resolvedSchema,
        rootData,
        rootSchema,
      })
      break
    case schemaType === "object":
      processedData = getDefaultObjectData({
        name,
        data,
        schema: resolvedSchema,
        rootData,
        rootSchema,
      })
      break
    // if the existing value is anything other than undefined, then do not compute default value
    // Note: if the value is null, a default value will not be computed
    case data !== undefined:
      processedData = data
      break
    // if the value is undefined, then it is not in the form state and so the default value should be computed
    default: {
      const defaultValue = getDefaultValueForSchema({
        name,
        data,
        schema,
        rootData,
      })
      if (defaultValue === undefined) break
      processedData = defaultValue
    }
  }

  const postResolvedSchema = getResolvedSchema(
    schema,
    rootSchema,
    processedData
  )

  // recursively call this method if the schema changes due to newly added default values.
  // the schema can change if there are conditionals that are resolved *after* the default values are added.
  if (!isEqual(postResolvedSchema, resolvedSchema)) {
    return getDataWithDefaultValues({
      name,
      data: processedData,
      schema,
      rootData,
      rootSchema,
    })
  }
  return processedData
}

export default getDataWithDefaultValues
