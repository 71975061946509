import { checkColumnsAreAggregated } from "./utils"
import { getConditionalFormatQueryParams } from "../../lib/conditionalFormat"

const getValidColumns = columns => {
  return columns?.filter(({ column }) => {
    return Boolean(column)
  })
}

const getColumnNames = columns => {
  return getValidColumns(columns)?.map(({ column }) => column) || []
}

const getQueryObjectsFromTable = (columns, groupBy) => {
  const selectColumns = []

  columns?.forEach(column => {
    const {
      type,
      aggregate,
      columnType,
      colorScale = [],
      column: datasetColumn,
      ...localColumnConfig
    } = column

    colorScale.forEach(({ column }) => {
      selectColumns.push({ column })
    })

    const aggregationRequired = checkColumnsAreAggregated(columns)

    if (columnType === "computed") {
      selectColumns.push({
        aggregate,
        type: "computed",
        name: localColumnConfig.dataIndex,
        column: localColumnConfig.dataIndex,
        ...localColumnConfig,
      })
      return
    }

    // exclude columns that have percentile aggregate functions
    getConditionalFormatQueryParams(column, aggregationRequired)
      .filter(select => select.aggregate !== "PERCENTILE_CONT")
      .forEach(selection => {
        selectColumns.push(selection)
      })

    selectColumns.push({
      type,
      aggregate,
      column: datasetColumn,
      ...localColumnConfig,
    })
  })

  if (groupBy?.length > 0) {
    groupBy.forEach(groupedColumn => {
      selectColumns.push(groupedColumn)
    })
  }

  return selectColumns
}

const getGroupBy = (groupByColumns, orderByColumnNames, selectColumns) => {
  const datetimeColumns = getColumnNames(
    selectColumns.filter(({ type }) => type === "datetime")
  )
  const gropByColumnNames = getColumnNames(groupByColumns)
  return [
    ...new Set([
      ...datetimeColumns,
      ...gropByColumnNames,
      ...orderByColumnNames,
    ]),
  ]
}

const getOrderBy = (orderByColumns, columns, distinct) => {
  let orderBy = []
  let orderByColumnNames = []
  const selectedOrderBy = orderByColumns || []

  // when there are distinct on colums, they must be added to the beginning of the order by clause
  //TODO: consider not automatically adding distinct on columns to order by clause if there are no order by columns
  if (distinct.length) {
    distinct.forEach(column => {
      if (!orderByColumnNames.includes(column)) {
        orderBy.push(`${column} ASC`)
        orderByColumnNames.push(column)
      }
    })
  }

  selectedOrderBy.forEach(({ column, direction }) => {
    if (column) {
      orderBy.push(`${column} ${direction ?? "ASC"}`)
      orderByColumnNames.push(column)
    }
  })

  columns.forEach(({ column, sortable, defaultSortOrder }) => {
    if (sortable && defaultSortOrder) {
      orderBy.push(
        `${column} ${defaultSortOrder === "ascend" ? "ASC" : "DESC"}`
      )
      orderByColumnNames.push(column)
    }
  })

  return [orderBy, orderByColumnNames]
}

const getDistinctOn = distinctOn => {
  if (typeof distinctOn === "string") {
    return [distinctOn]
  } else if (Array.isArray(distinctOn)) {
    return distinctOn
  } else {
    return []
  }
}

const queryResolver = widget => {
  const {
    table,
    where,
    offset,
    format,
    groupBy,
    datasetId,
    groupByTime,
    pollInterval,
    columns = [],
    distinctOn,
    orderBy: _orderBy,
  } = widget || {}
  const { pagination } = table || {}
  const select = getQueryObjectsFromTable(columns, groupBy)
  const pageSize = pagination?.pageSize || pagination?.defaultPageSize || 1000
  const distinct = getDistinctOn(distinctOn)
  const [orderBy, orderByColumnNames] = getOrderBy(_orderBy, columns, distinct)
  const groups = getGroupBy(groupBy, orderByColumnNames, select)

  return {
    where,
    select,
    offset,
    format,
    orderBy,
    datasetId,
    pollInterval,
    limit: pageSize,
    groupBy: groups,
    distinctOn: distinct,
    groupByTime: groupByTime?.column ? groupByTime : undefined,
  }
}

export default queryResolver
