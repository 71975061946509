import React, { useRef, useState } from "react"
import { noop } from "lodash"
import styled from "styled-components"
import { useResizeObserver } from "@dbai/ui-staples"

import ThemeWrapper from "./ThemeWrapper"
import { useWidgetContext } from "../../hooks"

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  overflow: auto;
  flex-flow: row wrap;
`

const DefaultWrappers = [({ children }) => children]

const Wrapper = props => {
  const { widget, widgetMeta, widgetId, pageId, children } = props
  const { widgetRegistry } = useWidgetContext()
  const Wrappers = widgetRegistry[widget?.type]?.Decorators || DefaultWrappers

  return (
    <ThemeWrapper widget={widget?.options}>
      {Wrappers?.map((WrapperComp, i) => {
        return (
          <WrapperComp
            widget={widget}
            pageId={pageId}
            widgetId={widgetId}
            widgetMeta={widgetMeta}
            key={`${widgetId}-${i}`}
          >
            {children}
          </WrapperComp>
        )
      })}
    </ThemeWrapper>
  )
}

/*
 * WidgetWrapper is an interface that is to be used to supply data to a
 * rendered widget as well as any needed interfaces required to interact
 * with any part of the app not rendered inside of the provided widget. This
 * includes things such as handling resizing operations, retrieving data from
 * the GQL API, and supply state altering callbacks.
 * Widget types will be saved in the widgetDatamapping array and matched to
 * the appropriate wrapper from there.
 * Widgets should not be at all aware of the outside app except via their
 * Wrapper.
 */
const WidgetWrapper = props => {
  const { widget, widgetMeta, pageId, widgetId, children } = props
  const containerRef = useRef(null)
  const [onResize, registerOnResize] = useState(() => noop)
  useResizeObserver(containerRef, onResize)
  return (
    <Container ref={containerRef}>
      <Wrapper
        widget={widget}
        pageId={pageId}
        widgetId={widgetId}
        widgetMeta={widgetMeta}
      >
        {React.cloneElement(children, { registerOnResize })}
      </Wrapper>
    </Container>
  )
}

export default WidgetWrapper
