import { scalarFieldOptions } from "../../lib/fieldOptionsSchemas"

export default {
  type: "object",
  default: {},
  metadata: {
    layout: { gutter: [16] },
    sections: scalarFieldOptions.metadata.sections,
  },
  properties: {
    ...scalarFieldOptions.properties,
    title: {
      type: "string",
      title: "Label",
      default: "Switch Form Field",
      metadata: {
        fieldProps: {
          placeholder: "Enter a label",
        },
      },
    },
    defaultValue: {
      type: "boolean",
      title: "Default Value",
    },
  },
}
