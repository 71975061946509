import { gql } from "@apollo/client"

export const CLONE_APP = gql`
  mutation CloneApp($customerId: Int!, $id: Int!, $name: String!) {
    cloneApp(customerId: $customerId, id: $id, name: $name) {
      id
      name
    }
  }
`
