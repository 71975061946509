import * as Y from "yjs"
import { WebsocketProvider } from "y-websocket"

import config from "config"

const checkIfWsServerIsRunning = () => {
  // If there is no configuration provided, don't attempt to collaborate.
  if (!config.yjs) return Promise.reject()

  const attemptConnection = (resolve, reject) => {
    const ws = new WebSocket(config.yjs)
    ws.onerror = event => {
      ws.close()
      reject(event)
    }

    ws.onopen = event => {
      ws.close()
      resolve(event)
    }
  }
  return new Promise(attemptConnection)
}

/*
 * Through out the react app it's preferable where possible to access these via
 * context/hooks.
 */
export let yDoc = null
export let yDocProvider = null
export const getYDocProvider = () => yDocProvider
export const getYProviderParams = customerId => {
  const auth = `Bearer ${localStorage.getItem("user/jwt")}`
  return { auth, customerId }
}

/*
 * Attempts to establish a connection with the Ko-op server and, if
 * successful, initializes Yjs. If the server can not be reached, log a
 * warning stating that collaboration is not enabled.
 */
export const initYjs = (workflowId, customerId) => {
  return checkIfWsServerIsRunning()
    .then(() => {
      const params = getYProviderParams(customerId)
      const newYDoc = new Y.Doc()
      newYDoc.name = workflowId

      const wsProvider = new WebsocketProvider(
        config.yjs,
        workflowId,
        newYDoc,
        {
          params,
          connect: false,
          resyncInterval: 3000,
        }
      )

      yDoc = newYDoc
      yDocProvider = wsProvider

      return { wsProvider, yDoc }
    })
    .catch(error => console.warn("Collaboration not enabled"))
}

export const shutDownYjs = () => {
  if (!yDocProvider) return
  yDocProvider.destroy()
  yDocProvider = null
  yDoc = null
}

export * from "./yOperations"
export * from "./yInit"
