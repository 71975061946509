import React, { memo, useCallback } from "react"
import { get } from "lodash"
import { useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { Card, Row, Col } from "antd"
import { SchemaForm } from "@dbai/applet"
import styled from "styled-components"
import { PageSpinner, ErrorMessage, useCurrentCustomer } from "@dbai/ui-staples"
import CellResults from "./CellResults"
import DelayedRender from "./DelayedRender"

import { useSwaggerDoc } from "hooks"
import { actions } from "reducers/notebookReducer"

const StyledForm = styled(Row)`
  width: 100%;
`
const ContainerStyled = styled(Row)`
  padding: 20px;
`
const FormWrappedStyled = styled(Row)`
  margin: 0px !important;
`

const parseInputs = (data, inputName) => {
  if (data === undefined || data === null) {
    return ""
  }
  let code = Object.keys(data)
    .map(item => {
      let value = data[item]
      if (typeof value === "string") {
        value = `"${data[item]}"`
      }
      if (typeof value === "boolean") {
        value = value ? "True" : "False"
      }
      return `${item}=${[null, undefined].includes(value) ? "None" : value}`
    })
    .join("\n")

  let inputLine = `${inputName}(`
  Object.keys(data).forEach((item, index, array) => {
    inputLine += `${item}=${item}`
    if (index < array.length - 1) {
      inputLine += ", "
    }
  })

  code = code + "\ninput = " + inputLine + ")"
  return code
}

const DividerForOutput = styled.div`
  width: 100%;
  height: 2px;
  float: right;
  background-color: ${props =>
    (props.theme.themeMode === "dark" &&
      (props.selected
        ? "rgba(255, 255, 255, 1)"
        : "rgba(255, 255, 255, 0.4)")) ||
    (props.theme.themeMode === "light" &&
      (props.selected ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.4)"))};
  margin-bottom: 5px;
  margin-top: 15px;
`
const OutputContainer = styled(Card)`
  display: flex;
  flex-direction: row;
  width: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
`

const ComponentForm = ({
  componentPath,
  nodeIdx,
  node,
  showFlow = true,
  showResults = true,
}) => {
  const showOutput = node.cells[3].metadata.showOutput
  const dispatch = useDispatch()
  const { cname } = useParams()
  const { schema, loading, error } = useSwaggerDoc(cname, componentPath)
  const [{ id: customerId }] = useCurrentCustomer()

  const handleFormChange = useCallback(
    data => {
      dispatch(
        actions.updateNodeField({
          nodeIdx,
          field: "metadata.componentInput",
          value: data,
        })
      )
      if (!schema?.title) return
      // Reserialize componentInput to code in cell 1
      const cellCode = parseInputs(data, schema.title)
      dispatch(
        actions.setAndSyncCell({
          node,
          nodeIdx,
          cellIdx: 1,
          sourceValue: cellCode,
        })
      )
    },
    [node, schema, dispatch, nodeIdx]
  )
  const cell = node.cells[3]

  if (error) return <ErrorMessage error={error} />
  if (loading) return <PageSpinner />

  return (
    <ContainerStyled>
      <Col>
        <FormWrappedStyled gutter={[16, 16]}>
          <StyledForm>
            <SchemaForm
              schema={schema}
              hideSave
              value={node.metadata.componentInput || {}}
              onFormChange={(data, errors) => handleFormChange(data, errors)}
              customerId={customerId}
              cname={cname}
            />
          </StyledForm>
        </FormWrappedStyled>
      </Col>
      <DividerForOutput />
      <OutputContainer showOutput={showOutput}>
        <DelayedRender>
          <CellResults cell={cell} />
        </DelayedRender>
      </OutputContainer>
    </ContainerStyled>
  )
}
export default memo(ComponentForm)
