import { createSelector } from "reselect"
import { get } from "lodash"

const selectForms = state => state.forms

export const selectForm = createSelector(
  selectForms,
  (_, { formId }) => formId,
  (forms, formId) => forms[formId]
)

export const selectFormData = createSelector(selectForm, form => form?.data)
export const selectFormSchema = createSelector(selectForm, form => form?.schema)

export const selectFormValue = createSelector(
  selectFormData,
  (_, { name }) => name,
  (_, { def }) => def,
  (data, name, def) => {
    if (!data) return null
    return name.length ? get(data, name, def) : data
  }
)
