import styled from "styled-components"
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"

import { FAMemo } from "@dbai/ui-staples"

import { getStatusIndicator } from "./StatusIndicator"
import {
  nodeIconMap,
  iconMap,
} from "components/pages/Workflows/shared/IconPicker"

const GraphNode = styled.div`
  border-radius: 5px;
  box-shadow: 1.338px 1.486px 3.88px 0.12px rgba(0, 0, 0, 0.26);
  width: 240px;
  height: 45px;
  margin-left: 3px;
  margin-top: 3px;

  cursor: pointer;

  &:hover {
    box-shadow: 1.338px 1.486px 4.88px 0.62px rgba(0, 0, 0, 0.26);
  }
`

const NodeTitle = styled.div`
  float: left;
  span {
    height: 35px;
    float: left;
    margin-top: 10px;
  }
`
const NodeType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  width: 40px;
  border-radius: 10px 10px 10px 10px;
  height: 40px;
  background-color: ${props => props.color};
  color: white;
`

const IconContainer = styled.div`
  cursor: pointer;
  color: white;
  padding: 3px 6px 4px;
  border-radius: 3px;
  background-color: ${({ color }) => color};

  position: relative;
  display: inline-block;
`

const getIcon = (type, isAction) => {
  if (isAction) return iconMap.action
  return iconMap[type]
}

const altErrorColors = ["#aa2323", "#db3e00"]

const ErrorIcon = styled(FAMemo)`
  color: #aa2323;
`

const getErrorComponent = parentColor => {
  if (altErrorColors.includes(parentColor)) {
    return props => <FAMemo icon={faExclamationCircle} {...props} />
  }
  return props => <ErrorIcon icon={faExclamationCircle} {...props} />
}

const NodeIcon = props => {
  const { type, color, status, isAction, customIcon } = props
  const icon = customIcon || getIcon(type, isAction) || "Expand"
  const NodeStatus = getStatusIndicator(status, {
    ErrorComponent: getErrorComponent(color),
    size: "lg",
  })
  const className = `type ${type} ${status}`
  return (
    <NodeType className={className} color={color || "#aaa"}>
      {NodeStatus || <FAMemo icon={nodeIconMap[icon]} size="lg" />}
    </NodeType>
  )
}

const NodeText = props => {
  const { color, runningStatus, type, title, onClick, isAction, customIcon } =
    props
  return (
    <foreignObject x="-123" y="-26" width="250" height="53">
      <GraphNode onClick={onClick} className="graph-node">
        <NodeIcon
          color={color}
          status={runningStatus}
          type={type}
          isAction={isAction}
          customIcon={customIcon}
        />
        <NodeTitle className={`title ${type}`}>
          <span>{title}</span>
        </NodeTitle>
      </GraphNode>
    </foreignObject>
  )
}

export { iconMap, getIcon, IconContainer, GraphNode }
export default NodeText
