import React, { useMemo } from "react"
import { InputNumber } from "antd"

import SliderInput from "./SliderInput"
import { getFormatter } from "../../../lib/format"
import { withFormFieldWrapper } from "../../FormFieldWrapper"
import { useLocalFormFieldControls } from "../../hooks"

const NumberInputCore = React.memo(props => {
  const { schema = {}, onChange, value: _value, ...rest } = props
  const { minimum: min, maximum: max, metadata = {} } = schema
  const { showSlider, formatter = { format: "number" } } = metadata
  const [value, handleChange] = useLocalFormFieldControls(_value, onChange)

  const formatterFn = useMemo(() => {
    if ([null, undefined].includes(value)) return val => val
    return getFormatter(formatter)
  }, [value, formatter])

  if (showSlider) {
    return <SliderInput.Core minimum={min} maximum={max} {...props} />
  }

  return (
    <InputNumber
      min={min}
      max={max}
      changeOnWheel={false}
      onChange={handleChange}
      formatter={formatterFn}
      {...([null, undefined].includes(value) ? {} : { value })}
      {...rest}
    />
  )
})

const NumberInput = withFormFieldWrapper(NumberInputCore)
NumberInput.Core = NumberInputCore
export default NumberInput
