import { useContext } from "react"

import { ErrorLoggerContext } from "../components/ErrorLoggerProvider"

const useErrorLoggerContext = () => {
  const ctx = useContext(ErrorLoggerContext)
  if (!ctx) {
    console.error(
      "useErrorLoggerContext must be used within an ErrorLoggerProvider"
    )
  }
  return ctx
}

export default useErrorLoggerContext
