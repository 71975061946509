export const formatJsonResults = jsonString =>
  JSON.stringify(jsonString, null, 2)

export const getColumns = signature => {
  if (signature.inputs[0]?.type === "tensor") {
    const spec = signature.inputs[0]["tensor-spec"]
    const startIdx = Math.floor(0, spec.shape[0])
    const endIdx = spec.shape[1]

    return [...Array(endIdx - startIdx).keys()].map(n => n.toString())
  }
  return signature.inputs.map(input => input.name)
}

export const formStateToPayload = (signature, state) => {
  const columns = getColumns(signature)

  const data = columns.map(column => state[column])

  return {
    columns: columns,
    index: [0],
    data: [data],
  }
}

export const payloadToFormState = payload => {
  const { columns, data } = payload
  const jsonData = data[0]

  return columns.reduce((formState, column, idx) => {
    if (jsonData[idx] !== null && jsonData[idx] !== undefined) {
      return { ...formState, ...{ [column]: jsonData[idx] } }
    } else {
      return { ...formState }
    }
  }, {})
}

export const columnsMissingData = payload => {
  const data = payload.data[0]
  return payload.columns
    .map((c, idx) => {
      return data[idx] === null || data[idx] === undefined ? c : null
    })
    .filter(c => c)
}

export const defaultJsonPayload = `{
  "columns": [],
  "index": [
    0
  ],
  "data": [
    []
  ]
}`
