import React, { useCallback } from "react"
import styled from "styled-components"
import { Space } from "antd"

import ListItem from "./ListItem"
import FieldTypeIcon from "../../../shared/FieldTypeIcon"
import { useSelectedListItems, beginDrag, endDrag } from "./utils"

const ListSpace = styled(Space)`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1 1 auto;
  overflow-y: auto;
`

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  height: 100%;
`

const DisplayName = props => {
  const { field } = props
  const { label, type } = field.content
  return (
    <Space>
      <FieldTypeIcon type={type} />
      {label}
    </Space>
  )
}

const DnDList = props => {
  const { initialValue = [], onChange, list, header, clickOnly } = props
  const [selectedItems, clearSelection, addItem] = useSelectedListItems(
    initialValue,
    list,
    clickOnly
  )

  const selected = useCallback(
    item => {
      return Boolean(selectedItems.find(s => item.id === s.id))
    },
    [selectedItems]
  )

  const handleClick = useCallback(
    index => {
      return evt => {
        const selected = addItem(index, evt)
        onChange && onChange(selected)
      }
    },
    [addItem, onChange]
  )

  return (
    <Container>
      {header}
      <ListSpace size={0} direction="vertical">
        {list.map(item => (
          <ListItem
            key={item.index}
            field={item}
            selected={selected(item)}
            onClick={handleClick(item.index)}
            item={{ fields: selectedItems, type: "field" }}
            beginDrag={beginDrag(item, selectedItems)}
            endDrag={endDrag(clearSelection)}
            dataTip={item.name}
          >
            <DisplayName minWidth="100%" field={item} />
          </ListItem>
        ))}
      </ListSpace>
    </Container>
  )
}

export default DnDList
