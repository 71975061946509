const properties = {
  id: {
    type: "string",
    metadata: {
      hidden: true,
      defaultValueSource: "uid",
    },
  },
  defaultStringValue: {
    type: "string",
    title: "Value",
    metadata: {
      fieldProps: {
        "data-testid": "variable-value",
      },
    },
  },
  defaultNumberValue: {
    type: "number",
    title: "Value",
    metadata: {
      fieldProps: {
        "data-testid": "variable-value",
      },
    },
  },
  defaultDatetimeValue: {
    type: "string",
    title: "Value",
    metadata: {
      component: "DatePicker",
      fieldProps: {
        "data-testid": "variable-value",
      },
    },
  },
  name: {
    type: "string",
    title: "Name",
    metadata: {
      placeholder: "Enter a unique name",
      fieldProps: {
        "data-testid": "variable-name",
      },
    },
  },
  type: {
    type: "string",
    title: "Type",
    default: "any",
    enum: ["any", "string", "number", "datetime"],
    metadata: {
      labels: ["Any", "String", "Number", "Datetime"],
      placeholder: "Select a type",
      fieldProps: {
        "data-testid": "variable-type",
      },
    },
  },
}

const appVariablesSchema = {
  type: "object",
  default: {},
  properties: {
    variables: {
      type: "array",
      default: [],
      metadata: {
        hideLabel: true,
        addButtonText: "ADD VARIABLE",
        addButtonProps: { block: true, "data-testid": "add-variable-button" },
      },
      items: {
        type: "object",
        default: {},
        metadata: {
          sideEffects: [
            {
              resetValue: null,
              type: "resetField",
              sourceName: "options.type",
              targetName: "options.value",
            },
          ],
        },
        dependencies: {
          type: {
            allOf: [
              {
                if: {
                  properties: {
                    type: {
                      enum: ["any", "string"],
                    },
                  },
                },
                then: {
                  properties: {
                    value: properties.defaultStringValue,
                  },
                },
              },
              {
                if: {
                  properties: {
                    type: {
                      enum: ["number"],
                    },
                  },
                },
                then: {
                  properties: {
                    value: properties.defaultNumberValue,
                  },
                },
              },
              {
                if: {
                  properties: {
                    type: {
                      enum: ["datetime"],
                    },
                  },
                },
                then: {
                  properties: {
                    value: properties.defaultDatetimeValue,
                  },
                },
              },
            ],
          },
        },
        properties: {
          id: properties.id,
          type: properties.type,
          name: properties.name,
        },
      },
    },
  },
}

export default appVariablesSchema
