import React from "react"

import {
  Card,
  Input,
  Select,
  ApiForm,
  AuthBoundary,
  Content,
  GET_CUSTOMERS,
  SIGNUP_CUSTOMER,
} from "@dbai/ui-staples"

const REFETCH_QUERIES = [{ query: GET_CUSTOMERS }]

// NOTE: These options must match the seed data created on the backend in
// `services/signupsvc/seed.go`.
const roleOpts = [
  { label: "Admin", value: "Admin" },
  { label: "Datalake", value: "Datalake" },
  { label: "Workflows", value: "Workflows" },
  { label: "Datasets", value: "Datasets" },
  { label: "Apps", value: "Apps" },
]

const NewCustomer = props => {
  return (
    <AuthBoundary subject="customers" action="CREATE">
      <Content>
        <Card title="CREATE A NEW CUSTOMER" style={{ width: "30%" }}>
          <ApiForm
            mutation={SIGNUP_CUSTOMER}
            afterRoute="/admin/customers"
            refetchQueries={REFETCH_QUERIES}
          >
            <Input name="name" />
            <Input name="email" type="email" />
            <Select label="Roles" name="roles" options={roleOpts} isMulti />
          </ApiForm>
        </Card>
      </Content>
    </AuthBoundary>
  )
}

export default NewCustomer
