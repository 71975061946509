import React from "react"

import AppPage from "../../shared/AppPage"
import useNavItemsSideEffect from "../../hooks/useNavItemsSideEffect"

const ContainerCore = props => {
  const { widget, widgetId, actionsPositon: currentActionsPosition } = props
  const { navItems, imageSpec = {}, ...pageOptions } = widget.options
  const actionsPosition = currentActionsPosition === "left" ? "right" : "left"

  return (
    <AppPage
      {...navItems[0]}
      {...pageOptions}
      name={widget.name}
      widgetId={widgetId}
      imageSpec={imageSpec}
      actionsPosition={actionsPosition}
    />
  )
}

const Container = props => {
  const { widgetId, editing } = props
  useNavItemsSideEffect(widgetId, editing)
  return <ContainerCore {...props} />
}

export default React.memo(Container)
