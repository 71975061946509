import { gql } from "@apollo/client"

export const GET_FOLDER = gql`
  query GetFolder($cname: String!, $folderType: String!) {
    folder(cname: $cname, folderType: $folderType) {
      id
      createdAt
      updatedAt
      folderType
      folderTree
    }
  }
`

export const SAVE_FOLDER = gql`
  mutation SaveFolder(
    $cname: String!
    $folderType: String!
    $folderTree: JsonInput!
    $id: Int
  ) {
    saveFolder(
      cname: $cname
      folderType: $folderType
      folderTree: $folderTree
      id: $id
    ) {
      id
      createdAt
      updatedAt
      folderType
      folderTree
    }
  }
`
