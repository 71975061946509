import React, { useState, useMemo } from "react"
import { Space } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCopy,
  faAngleUp,
  faTrashAlt,
  faAngleDown,
  faPencilAlt,
  faTrafficCone,
  faAngleDoubleUp,
  faAngleDoubleDown,
} from "@fortawesome/pro-solid-svg-icons"

import { useWidgetContext } from "../../hooks"
import { actions } from "../../reducers/appReducer"
import { selectPageWidgetIds } from "../../selectors/app"

const useLayoutMenuItems = ({
  widget,
  handleClick,
  handleClose,
  widgetPageId,
  additionalItems,
}) => {
  const widgetId = widget?.id
  const dispatch = useDispatch()
  const { widgetRegistry } = useWidgetContext()
  const [confirmRemove, setConfirmRemove] = useState(false)
  const { allowFullEditor, icon } = widgetRegistry[widget?.type] || {}
  const widgetOrder = useSelector(state =>
    selectPageWidgetIds(state, { pageId: widgetPageId })
  )

  const items = useMemo(() => {
    if (!widgetPageId) return []

    const currentIndex = widgetOrder.findIndex(l => l === widgetId)
    const isFirst = currentIndex === 0
    const isLast = currentIndex === widgetOrder.length - 1

    const handleEdit = () => {
      dispatch(actions.editWidget({ widgetId, allowFullEditor }))
    }

    const handleRemove = () => {
      if (!confirmRemove) {
        setConfirmRemove(true)
        return
      }
      dispatch(actions.removeWidgetWithSync({ widgetId, pageId: widgetPageId }))
      setConfirmRemove(false)
      handleClose()
    }

    const handleMovePageWidget = swapIndex => () => {
      dispatch(
        actions.swapPageOrderWithSync({
          to: swapIndex,
          from: currentIndex,
          pageId: widgetPageId,
        })
      )
    }

    const handleCopy = () => {
      dispatch(actions.copyWidget({ widgetId }))
    }

    return [
      {
        disabled: isLast,
        key: `move-forward-${widgetId}`,
        label: "Bring Item Forward",
        icon: <FontAwesomeIcon icon={faAngleUp} />,
        onClick: handleClick(handleMovePageWidget(currentIndex + 1)),
      },
      {
        key: `move-to-front-${widgetId}`,
        label: "Bring to Front",
        icon: <FontAwesomeIcon icon={faAngleDoubleUp} />,
        disabled: isLast,
        onClick: handleClick(handleMovePageWidget(widgetOrder.length - 1)),
      },
      {
        key: `move-to-back-${widgetId}`,
        label: "Send to Back",
        icon: <FontAwesomeIcon icon={faAngleDoubleDown} />,
        disabled: isFirst,
        onClick: handleClick(handleMovePageWidget(0)),
      },
      {
        key: `move-back-${widgetId}`,
        label: "Send Item Backward",
        icon: <FontAwesomeIcon icon={faAngleDown} />,
        disabled: isFirst,
        onClick: handleClick(handleMovePageWidget(currentIndex - 1)),
      },
      {
        type: "divider",
      },
      {
        key: `edit-${widgetId}`,
        label: "Edit",
        onClick: handleClick(handleEdit),
        icon: <FontAwesomeIcon icon={faPencilAlt} />,
      },
      {
        key: `copy-${widgetId}`,
        label: "Copy",
        onClick: handleClick(handleCopy),
        icon: <FontAwesomeIcon icon={faCopy} />,
      },
      {
        key: `remove-${widgetId}`,
        label: confirmRemove ? "Confirm Remove" : "Remove",
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        danger: confirmRemove,
        onClick: handleClick(handleRemove, true),
      },
      ...(additionalItems.length
        ? [{ type: "divider" }, ...additionalItems]
        : []),
    ]
  }, [
    widgetId,
    dispatch,
    handleClose,
    widgetOrder,
    handleClick,
    widgetPageId,
    confirmRemove,
    additionalItems,
    allowFullEditor,
  ])

  return items.length
    ? [
        {
          type: "group",
          children: items,
          label: (
            <Space>
              <FontAwesomeIcon icon={icon || faTrafficCone} /> {widget.name}
            </Space>
          ),
        },
      ]
    : []
}

export default useLayoutMenuItems
