import React from "react"

import { Input, useFormActions } from "@dbai/ui-staples"

const RadioFormatter = ({ value, row }) => {
  const { status, isDev = false } = row
  const disabled = !["DEV", "AVAILABLE"].includes(status)

  const { formSet } = useFormActions()

  const handleChange = evt => {
    formSet({ name: "id", value })
    formSet({ name: "isDev", value: isDev })
  }

  return (
    <Input
      type="radio"
      name="id"
      onChange={handleChange}
      disabled={disabled}
      className="form-check"
      value={value}
      noWrapper
    />
  )
}

export default RadioFormatter
