import React from "react"
import dayjs from "dayjs"
import PropTypes from "prop-types"

const DateFormatter = props => {
  const { format = "MM/DD/YYYY", value, row, dataIndex } = props

  //assuming if value is a number, its in nanosecond's
  //TODO: use numeral to format number value to always be a specific length
  //instead of assuming the value will always be in nanoseconds
  const formatValue = val => value / 1000000
  let dateValue = typeof value === "number" ? formatValue(value) : value

  //antd implementation won't have value, so we need to set it to row[dataIndex]
  if (dataIndex) {
    dateValue = row[dataIndex]
  }

  return <div>{dayjs(dateValue).format(format)}</div>
}

DateFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.string,
}

export default DateFormatter
