import { useCallback } from "react"
import { useDispatch } from "react-redux"

import { actions } from "../reducers/appReducer"

const useSelectPoints = (pageId, widgetId, datasetId) => {
  const dispatch = useDispatch()
  return useCallback(
    (points, evt) => {
      if (evt?.ctrlKey) {
        dispatch(
          actions.appendSelectedPoints({ points, widgetId, pageId, datasetId })
        )
      } else {
        dispatch(actions.selectPoints({ points, widgetId, pageId, datasetId }))
      }
    },
    [dispatch, pageId, datasetId, widgetId]
  )
}

export default useSelectPoints
