import { curry } from "lodash"

const getStrFromColumn = (row, column) => {
  const candidate = row && row[column]
  switch (typeof candidate) {
    case "string":
      return candidate
    case "number":
      return String(candidate)
    default:
      return ""
  }
}

const stringSorter = curry((column, a, b) => {
  const aStr = getStrFromColumn(a, column)
  const bStr = getStrFromColumn(b, column)
  return aStr.localeCompare(bStr)
})

export default stringSorter
