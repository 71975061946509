import { gql } from "@apollo/client"

const SCHEDULE_FRAGMENT = gql`
  fragment ScheduleFragment on Schedule {
    id
    createdAt
    updatedAt

    notifications {
      id
      eventType
      notificationType
      ... on EmailScheduleNotification {
        details {
          addresses
          content
        }
      }
    }

    package {
      id
      image
      workflow {
        id
        name
      }
    }
    spec {
      timezone
      minute {
        value
        step
      }
      hour {
        value
        step
      }
      dow {
        value
        step
      }
      month {
        value
        step
      }
      dom {
        value
        step
      }
    }
  }
`

export const GET_SCHEDULES = gql`
  ${SCHEDULE_FRAGMENT}
  query GetSchedules($cname: String!) {
    customer(name: $cname) {
      id
      schedules {
        ...ScheduleFragment
      }
    }
  }
`

export const GET_SCHEDULE = gql`
  ${SCHEDULE_FRAGMENT}
  query GetSchedule($cname: String!, $id: Int!) {
    customer(name: $cname) {
      id
      schedule(id: $id) {
        ...ScheduleFragment
      }
    }
  }
`

export const CREATE_SCHEDULE = gql`
  mutation CreateSchedule($customerId: Int!, $input: ScheduleInput!) {
    createSchedule(customerId: $customerId, input: $input) {
      id
      createdAt
    }
  }
`

export const DELETE_SCHEDULE = gql`
  mutation DeleteSchedule($cname: String!, $id: Int!) {
    deleteSchedule(cname: $cname, id: $id)
  }
`

export const CREATE_SCHEDULE_EMAIL_NOTIFICATION = gql`
  mutation CreateScheduleEmailNotification(
    $cname: String!
    $scheduleId: Int!
    $eventType: ArgoEventType!
    $addresses: [String]!
    $content: String
  ) {
    createEmailScheduleNotification(
      cname: $cname
      input: {
        scheduleId: $scheduleId
        eventType: $eventType
        notificationType: email
        details: { addresses: $addresses, content: $content }
      }
    ) {
      id
      scheduleId
      details {
        addresses
      }
    }
  }
`

export const DELETE_SCHEDULE_EMAIL_NOTIFICATION = gql`
  mutation DeleteScheduleEmailNotification($id: Int!, $cname: String!) {
    deleteEmailScheduleNotification(id: $id, cname: $cname) {
      id
    }
  }
`
