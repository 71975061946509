import React from "react"
import styled from "styled-components"
import { Tag } from "antd"

const PolicyMatrix = styled.div`
  display: inline-grid;
  grid-template-columns: 4rem 1fr 2rem auto;
  align-items: center;

  .ant-tag.ant-tag.ant-tag.ant-tag.ant-tag:last-of-type {
    margin-right: 0;
  }
`

export const EditablePolicyMatrix = styled(PolicyMatrix)`
  display: grid;
  grid-template-columns: 4rem 1fr 2rem 1fr auto;
  grid-gap: 0.5rem;
`

export const RuleEffect = styled.span`
  position: relative;
  padding-left: 9px;
  margin-right: 0.3rem;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border: 2px solid
      ${({ effect, theme }) => theme[effect === "ALLOW" ? "success" : "danger"]};
    border-radius: 2px;
  }
`

export const OnCol = styled.div`
  display: flex;
  justify-content: space-around;

  &:before {
    content: "ON";
    text-align: center;
  }
`

const TagList = ({ list, className }) => {
  return (
    <div className={className}>
      {list.map(item => (
        <Tag key={item}>{item}</Tag>
      ))}
    </div>
  )
}

export const MatrixRow = ({ policy }) => {
  return (
    <>
      <div>
        <RuleEffect effect={policy.rule.effect}>
          {policy.rule.effect}
        </RuleEffect>
      </div>
      <TagList list={policy.rule.actions} />
      <OnCol />
      <TagList list={policy.rule.subjects} />
    </>
  )
}

export default PolicyMatrix
