import backgroundColor from "./shared/backgroundColor"

const imageSchema = {
  title: "Image",
  type: "object",
  default: {},
  metadata: {
    fieldProps: {
      style: {
        padding: "0 16px",
      },
    },
  },
  properties: {
    image: {
      title: "Image",
      type: "string",
      metadata: {
        hidden: true,
      },
    },
    backgroundColor: backgroundColor,
    editImage: {
      type: "null",
      nullable: true,
      metadata: {
        component: "EditImageButton",
        hideLabel: true,
      },
    },
    maintainAspectRatio: {
      type: "boolean",
      title: "Maintain Aspect Ratio",
    },
  },
}

export default imageSchema
