import React, { memo, useEffect, useState, useMemo, useCallback } from "react"
import { Form } from "antd"
import { isEqual } from "lodash"
import styled from "styled-components"
import { PropTypes } from "prop-types"
import { useApolloClient } from "@apollo/client"

import { useWidgetContext } from "../hooks"
import useFormFieldActions from "./hooks/useFormFieldActions"
import {
  useFormData,
  useValidation,
  useFormSchema,
  usePlaceholder,
} from "./hooks"
import {
  isPathArray,
  isSchemaHidden,
  checkIsRequired,
  getCurrentPaths,
  getSelectOptionsForSchema,
} from "./utils"

const StyledFormItem = memo(styled(Form.Item)`
  // This will move the form field to the next line below the label

  ${({ compact }) => {
    if (Boolean(compact)) {
      const marginBottom = String(compact) === "true" ? "0px" : `${compact}px`
      return `
        margin-bottom: ${marginBottom};
      `
    }
    return `
      margin-bottom: 10px;
    `
  }}

  label {
    margin-bottom: 0;
  }
`)

const getSafeLabel = (schema, { label, schemaKey, hideLabel }) => {
  const { metadata = {} } = schema
  switch (true) {
    case Boolean(metadata.hideLabel) || hideLabel:
      return null
    case isPathArray(schemaKey):
      return null
    case Boolean(schema.title):
      return schema.title
    case Boolean(label):
      return label
    default:
      return schemaKey
  }
}

const pathToName = path => {
  // TODO: does path need to start with a dot?
  const pathWithoutLeadingDot = path.replace(/^\./, "")
  // split path by dots and remove brackets
  return pathWithoutLeadingDot
    .split(".")
    .map(p => p.replace("[", "").replace("]", ""))
}

const useOnLoad = ({ metadata, path, value, onChange, setLoading }) => {
  const client = useApolloClient()
  const rootData = useFormData()
  const { cname } = useWidgetContext()
  useEffect(() => {
    const execOnLoad = async () => {
      if (
        metadata.events?.onLoad &&
        typeof metadata.events.onLoad === "function"
      ) {
        return metadata.events.onLoad({
          name: pathToName(path),
          cname,
          value,
          client,
          onChange,
          rootData,
          setLoading,
        })
      }
    }
    execOnLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

const isContainerSchema = schema => {
  return (
    ["object", "array"].includes(schema.type) &&
    !Boolean(schema.items?.enum) &&
    !Boolean(schema.enums) &&
    !schema.metadata?.component
  )
}
/** This component is meant to wrap over a form input field. You can also
 * use this wrapper to inject a form fields state into a component that is reliant on it.
 * This component also expects to be wrapped over a single element.
 *
 * To use validation with this component, you must consume it within a SchemaContext.
 * Given the context and the input fields path and schemaKey, this component will
 * determine which part of the schema coorelates to this field and observe the validation
 * state.
 */
const FormFieldWrapper = props => {
  const {
    name: _parentName,
    path: _parentPath,
    schema = {},
    noStyle,
    tooltip,
    children,
    readonly,
    schemaKey,
    hideLabel,
    className,
    afterAppend,
    parentSchema = schema,
    wrapperStyles,
    validateFirst,
    hideValidationMessage,
    ..._rest
  } = props

  const {
    path,
    instancePath,
    dataPath: name,
  } = getCurrentPaths(_parentPath, schemaKey)

  if ([null, undefined].includes(schemaKey)) {
    console.error("Schema key is undefined for form field")
  }

  const value = useFormData(name)
  const rootSchema = useFormSchema()

  const { onAppend, onChange, onRemove } = useFormFieldActions(name)

  const placeholder = usePlaceholder(schema, path)
  const { metadata = {} } = schema
  const compact =
    metadata.layout?.compact || rootSchema?.metadata?.layout?.compact
  const isRequired = checkIsRequired(parentSchema, schemaKey)
  const label = getSafeLabel(schema, props)
  const [status, message, setTouched] = useValidation({
    value,
    readonly,
    instancePath,
    validateFirst: metadata.validateFirst || validateFirst,
  })

  const [rest, setRest] = useState(_rest)
  const [loading, setLoading] = useState(false)
  const [childProps, setChildProps] = useState(children.props)

  useEffect(() => {
    if (!isEqual(rest, _rest)) {
      setRest(rest)
    }
  }, [rest, _rest])

  // TODO: This is a hack.
  // For some reason children.props is not memoized and will cause the methods below to reinitialize
  useEffect(() => {
    if (!isEqual(childProps, children.props)) {
      setChildProps(children.props)
    }
  }, [children.props, childProps])

  useOnLoad({ metadata, path, value, onChange, setLoading })

  const handleBlur = useCallback(
    event => {
      childProps.onBlur && childProps.onBlur(event)
      setTouched(true)
    },
    [setTouched, childProps]
  )

  /**
   *  By default, this wrapper only surfaces validations once the wrapped field
   *  is touched. To prevent this behavior, set 'validateFirst' or 'readonly' to true
   */
  const registerOnBlur = useMemo(() => {
    if (readonly) return {}
    return {
      onBlur: handleBlur,
    }
  }, [handleBlur, readonly])

  /**
   * To ensure the wrapped field is updated on change, the following method
   * is injected into the child component. If a child component already
   * has an onChange method, it will be treated as a callback. To prevent the
   * default behavior here, set this field wrapper to readonly
   */
  const handleChange = useCallback(
    (e, ...rest) => {
      childProps.onChange && childProps.onChange(e, ...rest)
      onChange(e, ...rest)
    },
    [onChange, childProps]
  )

  const registerOnChange = useMemo(() => {
    if (readonly) return {}
    return {
      onChange: handleChange,
    }
  }, [handleChange, readonly])

  const handleAppend = useCallback(
    (value, ...rest) => {
      childProps.onAppend && childProps.onAppend(value, ...rest)
      onAppend(value, ...rest)
      afterAppend && afterAppend(value, ...rest)
    },
    [childProps, afterAppend, onAppend]
  )

  const registerOnAppend = useMemo(() => {
    if (!schema.items) return {}
    return {
      onAppend: handleAppend,
    }
  }, [handleAppend, schema.items])

  const handleRemove = useCallback(
    index => {
      childProps.onRemove && childProps.onRemove(index)
      onRemove(index)
    },
    [childProps, onRemove]
  )

  const registerOnRemove = useMemo(() => {
    if (!schema.items) return {}
    return {
      onRemove: handleRemove,
    }
  }, [handleRemove, schema.items])

  const registerOptions = useMemo(() => {
    const options = getSelectOptionsForSchema(schema)
    if (!options.length || childProps.options) return {}
    return { options }
  }, [schema, childProps.options])

  const registerFieldProps = useMemo(() => {
    if (!metadata?.fieldProps) return { placeholder }
    return { ...metadata.fieldProps, placeholder }
  }, [metadata, placeholder])

  const additionalProps = useMemo(() => {
    return {
      ...registerOnBlur,
      ...registerOptions,
      ...registerOnChange,
      ...registerOnAppend,
      ...registerOnRemove,
      ...registerFieldProps,
      ...rest,
    }
  }, [
    rest,
    registerOnBlur,
    registerOptions,
    registerOnChange,
    registerOnRemove,
    registerOnAppend,
    registerFieldProps,
  ])

  // Only include value if the form field is not a container (object or array).
  // This prevents the container field from rerendering when any part of the nested data changes.
  // The exception is when the schema is rendered using a custom component. In which case, we do want to pass the value
  const memoizedValue = useMemo(() => {
    if (isContainerSchema(schema)) return undefined
    return value
  }, [value, schema])

  /** By default, passing props.children will not issue a rerender if anything in this
   * component changes. However, since we are injecting props into the child component,
   * then the child component will rerender if any of the injected props change. Hence
   * the need to memoize the injected props.
   */
  const memoizedChild = useMemo(() => {
    return React.cloneElement(children, {
      path,
      name,
      value: memoizedValue,
      status,
      schema,
      loading,
      schemaKey,
      parentName: _parentName,
      parentSchema,
      ...additionalProps,
    })
  }, [
    path,
    name,
    status,
    schema,
    loading,
    children,
    schemaKey,
    _parentName,
    parentSchema,
    memoizedValue,
    additionalProps,
  ])

  if (isSchemaHidden(schema, value, path)) return null
  return (
    <StyledFormItem
      label={label}
      compact={compact}
      className={className}
      required={isRequired}
      validateStatus={status}
      tooltip={schema.description}
      colon={metadata.colon !== false}
      style={wrapperStyles || metadata.wrapperStyle}
      help={hideValidationMessage ? undefined : message}
      noStyle={noStyle || readonly || metadata.noStyle || metadata.noWrapper}
      labelCol={
        metadata.layout?.labelCol || rootSchema?.metadata?.layout?.labelCol
      }
      wrapperCol={
        metadata.layout?.wrapperCol || rootSchema?.metadata?.layout?.wrapperCol
      }
    >
      {memoizedChild}
    </StyledFormItem>
  )
}

const MemoizedFormFieldWrapper = React.memo(FormFieldWrapper)
const withFormFieldWrapper = Comp => props => {
  return (
    <MemoizedFormFieldWrapper {...props}>
      <Comp />
    </MemoizedFormFieldWrapper>
  )
}

MemoizedFormFieldWrapper.propTypes = {
  label: PropTypes.string,
  noStyle: PropTypes.bool, // used to hide the label and validation status
  readonly: PropTypes.bool, // use when you dont want this component to handle onChange
  tooltip: PropTypes.object,
  validateFirst: PropTypes.bool, // surface validations for this field on first render
  schema: PropTypes.object,
  path: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  schemaKey: PropTypes.string.isRequired,
}

export { withFormFieldWrapper, StyledFormItem as FormItem }
export default MemoizedFormFieldWrapper
