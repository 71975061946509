import React, { useEffect, useState, useCallback } from "react"
import { Image } from "react-konva"

const NO_IMAGE_URL = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBoZWlnaHQ9IjMwMHB4IiB3aWR0aD0iMzAwcHgiIHZlcnNpb249IjEuMSIgdmlld0JveD0iLTMwMCAtMzAwIDYwMCA2MDAiIGZvbnQtZmFtaWx5PSJCaXRzdHJlYW0gVmVyYSBTYW5zLExpYmVyYXRpb24gU2FucywgQXJpYWwsIHNhbnMtc2VyaWYiIGZvbnQtc2l6ZT0iNzIiIHRleHQtYW5jaG9yPSJtaWRkbGUiPgogIAogIDxjaXJjbGUgc3Ryb2tlPSIjQUFBIiBzdHJva2Utd2lkdGg9IjEwIiByPSIyODAiIGZpbGw9IiNGRkYiLz4KICA8c3dpdGNoIHN0eWxlPSJmaWxsOiM0NDQ7Ij4KICAgIDx0ZXh0IGlkPSJ0cnN2ZzMtYm4iIHN5c3RlbUxhbmd1YWdlPSJibiI+PHRzcGFuIHg9IjAiIHk9Ii04IiBpZD0idHJzdmcxLWJuIj4mI3g5OUI7JiN4OUFDOyYjeDlCRjsgJiN4OTg5OyYjeDlBQTsmI3g5QjI7JiN4OUFEOyYjeDlDRDsmI3g5QUY7PC90c3Bhbj48dHNwYW4geD0iMCIgeT0iODAiIGlkPSJ0cnN2ZzItYm4iPiYjeDlBODsmI3g5REY7PC90c3Bhbj48L3RleHQ+CiAgICA8dGV4dCBpZD0idHJzdmczLWlkIiBzeXN0ZW1MYW5ndWFnZT0iaWQiPjx0c3BhbiB4PSIwIiB5PSItOCIgaWQ9InRyc3ZnMS1pZCI+R0FNQkFSIFRBSzwvdHNwYW4+PHRzcGFuIHg9IjAiIHk9IjgwIiBpZD0idHJzdmcyLWlkIj5URVJTRURJQTwvdHNwYW4+PC90ZXh0PgogICAgPHRleHQgaWQ9InRyc3ZnMy1lbiIgc3lzdGVtTGFuZ3VhZ2U9ImVuIj48dHNwYW4geD0iMCIgeT0iLTgiIGlkPSJ0cnN2ZzEtZW4iPk5PIElNQUdFPC90c3Bhbj48dHNwYW4geD0iMCIgeT0iODAiIGlkPSJ0cnN2ZzItZW4iPkFWQUlMQUJMRTwvdHNwYW4+PC90ZXh0PgogICAgPHRleHQgaWQ9InRyc3ZnMyI+PHRzcGFuIHg9IjAiIHk9Ii04IiBpZD0idHJzdmcxIj5OTyBJTUFHRTwvdHNwYW4+PHRzcGFuIHg9IjAiIHk9IjgwIiBpZD0idHJzdmcyIj5BVkFJTEFCTEU8L3RzcGFuPjwvdGV4dD4KICA8L3N3aXRjaD4KPC9zdmc+Cg==`

// custom component that will handle loading image from url
// you may add more logic here to handle "loading" state
// or if loading is failed
// VERY IMPORTANT NOTES:
// at first we will set image state to null
// and then we will set it to native image instance when it is loaded
const CanvasImage = React.forwardRef((props, ref) => {
  const { imageSpec = {}, x, y, ...rest } = props
  const [image] = useState(new window.Image())

  const handleLoad = useCallback(
    loadedImage => {
      // if you keep same image object during source updates
      // you will have to update layer manually:
      ref?.current?.getLayer().batchDraw()
    },
    [ref]
  )

  useEffect(() => {
    const loadImage = () => {
      image.src = imageSpec?.url || NO_IMAGE_URL
      image.addEventListener("load", handleLoad)
    }
    loadImage()
    return () => {
      image.removeEventListener("load", handleLoad)
    }
  }, [handleLoad, image, imageSpec])

  return <Image x={x} y={y} image={image} ref={ref} {...rest} />
})

export default CanvasImage
