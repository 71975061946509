import React, { useRef, useCallback } from "react"
import { ConfigProvider, Button } from "antd"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowsAlt, faEllipsisV } from "@fortawesome/pro-solid-svg-icons"

import { useWidgetContext } from "../../../hooks"

const createDragHandle = pageId => `layout-drag-handle-${pageId}`
export const getDragHandleClass = pageId => `.${createDragHandle(pageId)}`

const StyledButtonGroup = styled(Button.Group)`
  z-index: 30;
  transition: all 0.3s ease;
  position: fixed;
  border: none;
  top: 4px;

  ${props => props.position ?? "right"}: 4px;
`

const LayoutActionButtons = props => {
  const { pageId, visible, position = "right" } = props
  const buttonRef = useRef()
  const { editable } = useWidgetContext()

  const triggerContextMenu = useCallback(
    e => {
      if (!buttonRef.current) return
      const newEvent = new MouseEvent("contextmenu", {
        bubbles: true,
        cancelable: true,
        clientX: e.clientX,
        clientY: e.clientY,
      })

      buttonRef.current.dispatchEvent(newEvent)
    },
    [buttonRef]
  )

  if (!visible || !editable) return null

  return (
    <ConfigProvider theme={{ token: { fontSize: 14 } }}>
      <StyledButtonGroup position={position}>
        <Button
          cursor="move"
          className={createDragHandle(pageId)}
          type="text"
          icon={
            <FontAwesomeIcon icon={faArrowsAlt} style={{ marginRight: 0 }} />
          }
        />
        <Button
          ref={buttonRef}
          type="text"
          onClick={triggerContextMenu}
          icon={
            <FontAwesomeIcon icon={faEllipsisV} style={{ marginRight: 0 }} />
          }
        />
      </StyledButtonGroup>
    </ConfigProvider>
  )
}

LayoutActionButtons.propTypes = {
  editMode: PropTypes.bool,
  visible: PropTypes.bool,
  pageId: PropTypes.number.isRequired,
  layoutId: PropTypes.number.isRequired,
}

export default React.memo(LayoutActionButtons)
