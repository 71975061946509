const imageShapeSchema = {
  properties: {
    width: {
      title: "Width",
      type: "number",
      default: 50,
    },
    height: {
      title: "Height",
      default: 50,
      type: "number",
    },
    "/imageSpec": {
      $ref: "#/$defs/base64ImageUpload",
    },
  },
}

export default imageShapeSchema
