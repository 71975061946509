import { scalarFieldOptions } from "../../lib/fieldOptionsSchemas"
import numberInputOptionsSchema from "../NumberInput/fieldOptionsSchema"
import datePickerOptionsSchema from "../DatePicker/fieldOptionsSchema"

export default {
  type: "object",
  default: {},
  required: ["metadata/datasetId"],
  metadata: {
    sections: {
      ...scalarFieldOptions.metadata.sections,
      columnValueInputOptions: {
        title: "Input Options",
        properties: [
          "metadata/column",
          "metadata/datasetId",
          "metadata/valuePresets",
          "metadata/presetDatasetId",
          "metadata/presetColumn",
          "metadata/presetColumnType",
          "metadata/popupMatchSelectWidth",
          "metadata/allowInput",
        ],
      },
      numberOptions: numberInputOptionsSchema.metadata.sections.numberOptions,
      dateOptions: datePickerOptionsSchema.metadata.sections.dateOptions,
    },
    order: [
      "metadata/datasetId",
      "metadata/column",
      "metadata/op",
      "defaultValue",
      "metadata/format",
      "metadata/isMulti",
      "metadata/backgroundColor",
    ],
    fieldProps: {
      style: {
        padding: "0 16px",
      },
    },
    // TODO: side effects arent working?
    sideEffects: [
      {
        resetValue: null,
        type: "resetField",
        targetName: "column",
        sourceName: "datasetId",
      },
      {
        resetValue: null,
        type: "resetField",
        targetName: "defaultValue",
        sourceName: "isMultiSelect",
      },
      {
        resetValue: null,
        type: "resetField",
        sourceName: "column",
        targetName: "defaultValue",
      },
      {
        resetValue: null,
        type: "resetField",
        sourceName: "valuePresets",
        targetName: "defaultValue",
      },
      {
        resetValue: null,
        type: "resetField",
        sourceName: "allowInput",
        targetName: "defaultValue",
      },
      ...numberInputOptionsSchema.metadata.sideEffects,
    ],
  },
  dependencies: {
    "metadata/datasetId": {
      dependencies: {
        "metadata/columnType": {
          allOf: [
            {
              if: {
                properties: {
                  "metadata/columnType": {
                    enum: ["numerical", "float", "int"],
                  },
                },
              },
              then: {
                allOf: [
                  {
                    if: {
                      properties: {
                        "metadata/valuePresets": {
                          enum: ["none"],
                        },
                      },
                    },
                    then: {
                      dependencies: numberInputOptionsSchema.dependencies,
                      properties: {
                        showSlider:
                          numberInputOptionsSchema.properties.showSlider,
                        multipleOf:
                          numberInputOptionsSchema.properties.multipleOf,
                        maximum: numberInputOptionsSchema.properties.maximum,
                        minimum: numberInputOptionsSchema.properties.minimum,
                        defaultValue:
                          numberInputOptionsSchema.properties.defaultValue,
                      },
                    },
                  },
                  {
                    if: {
                      properties: {
                        "metadata/valuePresets": {
                          enum: ["columnValues"],
                        },
                      },
                    },
                    then: {
                      dependencies: {
                        "metadata/allowInput": {
                          if: {
                            properties: {
                              "metadata/allowInput": {
                                enum: [true],
                              },
                            },
                          },
                          then: {
                            properties: {
                              defaultValue: {
                                type: ["string", "null"],
                                nullable: true,
                                title: `Default Value`,
                                metadata: {
                                  allowInput: true,
                                  columnType: "numerical",
                                  excludeWidgetFilters: true,
                                  valuePresets: "columnValues",
                                  component: "ColumnValueInput",
                                  columnName: "metadata/column",
                                  datasetIdName: "metadata/datasetId",
                                },
                              },
                            },
                          },
                        },
                      },
                      properties: {
                        "metadata/allowInput": {
                          type: "boolean",
                          default: true,
                          title: "Allow New Value Input",
                        },
                        "metadata/popupMatchSelectWidth": {
                          default: false,
                          type: "boolean",
                          title: "Dropdown Match Select Width",
                        },
                        defaultValue: {
                          type: ["string", "null"],
                          nullable: true,
                          title: `Default Value`,
                          metadata: {
                            columnType: "numerical",
                            excludeWidgetFilters: true,
                            valuePresets: "columnValues",
                            component: "ColumnValueInput",
                            columnName: "metadata/column",
                            datasetIdName: "metadata/datasetId",
                          },
                        },
                      },
                    },
                  },
                  {
                    if: {
                      properties: {
                        "metadata/valuePresets": {
                          enum: ["otherColumnValues"],
                        },
                      },
                    },
                    then: {
                      dependencies: {
                        "metadata/allowInput": {
                          if: {
                            properties: {
                              "metadata/allowInput": {
                                enum: [true],
                              },
                            },
                          },
                          then: {
                            properties: {
                              defaultValue: {
                                type: ["string", "null"],
                                nullable: true,
                                title: `Default Value`,
                                metadata: {
                                  columnType: "numerical",
                                  excludeWidgetFilters: true,
                                  component: "ColumnValueInput",
                                  valuePresets: "otherColumnValues",
                                  columnName: "metadata/presetColumn",
                                  datasetIdName: "metadata/presetDatasetId",
                                },
                              },
                            },
                          },
                        },
                        "metadata/presetDatasetId": {
                          properties: {
                            "metadata/presetColumnType": {
                              title: "Preset Column Type",
                              type: "string",
                              metadata: {
                                hidden: true,
                              },
                            },
                            "metadata/presetColumn": {
                              title: "Preset Column",
                              type: "string",
                              metadata: {
                                validateFirst: true,
                                component: "ColumnSelect",
                                datasetIdName: "metadata/presetDatasetId",
                                columnTypeName: "presetColumnType",
                                fieldProps: {
                                  popupMatchSelectWidth: false,
                                },
                              },
                            },
                          },
                        },
                        "metadata/presetColumn": {
                          properties: {
                            "metadata/allowInput": {
                              type: "boolean",
                              default: true,
                              title: "Allow New Value Input",
                            },
                            defaultValue: {
                              type: ["string", "null"],
                              nullable: true,
                              title: `Default Value`,
                              metadata: {
                                allowInput: true,
                                columnType: "numerical",
                                excludeWidgetFilters: true,
                                component: "ColumnValueInput",
                                valuePresets: "otherColumnValues",
                                columnName: "metadata/presetColumn",
                                datasetIdName: "metadata/presetDatasetId",
                              },
                            },
                          },
                        },
                      },
                      properties: {
                        "metadata/presetDatasetId": {
                          type: "number",
                          title: "Preset Dataset",
                          metadata: {
                            component: "Dataset",
                          },
                        },

                        "metadata/popupMatchSelectWidth": {
                          default: false,
                          type: "boolean",
                          title: "Dropdown Match Select Width",
                        },
                      },
                    },
                  },
                ],
                properties: {
                  "metadata/valuePresets": {
                    type: "string",
                    default: "none",
                    title: "Value Presets",
                    enum: ["none", "columnValues", "otherColumnValues"],
                    metadata: {
                      labels: ["None", "Column Values", "Other Column Values"],
                    },
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  "metadata/columnType": {
                    enum: ["string", "categorical"],
                  },
                },
              },
              then: {
                allOf: [
                  {
                    if: {
                      properties: {
                        "metadata/valuePresets": {
                          enum: ["none"],
                        },
                      },
                    },
                    then: {
                      properties: {
                        defaultValue: {
                          type: ["string", "null"],
                          nullable: true,
                          title: "Default Value",
                        },
                      },
                    },
                  },
                  {
                    if: {
                      properties: {
                        "metadata/valuePresets": {
                          enum: ["columnValues"],
                        },
                      },
                    },
                    then: {
                      dependencies: {
                        "metadata/allowInput": {
                          if: {
                            properties: {
                              "metadata/allowInput": {
                                enum: [true],
                              },
                            },
                          },
                          then: {
                            properties: {
                              defaultValue: {
                                type: ["string", "null"],
                                nullable: true,
                                title: "Default Value",
                                metadata: {
                                  allowInput: true,
                                  columnType: "categorical",
                                  excludeWidgetFilters: true,
                                  valuePresets: "columnValues",
                                  component: "ColumnValueInput",
                                  columnName: "metadata/column",
                                  datasetIdName: "metadata/datasetId",
                                  fieldProps: {
                                    popupMatchSelectWidth: false,
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      properties: {
                        "metadata/allowInput": {
                          type: "boolean",
                          default: true,
                          title: "Allow New Value Input",
                        },
                        defaultValue: {
                          type: ["string", "null"],
                          nullable: true,
                          title: "Default Value",
                          metadata: {
                            columnType: "categorical",
                            excludeWidgetFilters: true,
                            valuePresets: "columnValues",
                            component: "ColumnValueInput",
                            columnName: "metadata/column",
                            datasetIdName: "metadata/datasetId",
                            fieldProps: {
                              popupMatchSelectWidth: false,
                            },
                          },
                        },
                      },
                    },
                  },
                  {
                    if: {
                      properties: {
                        "metadata/valuePresets": {
                          enum: ["otherColumnValues"],
                        },
                      },
                    },
                    then: {
                      dependencies: {
                        "metadata/allowInput": {
                          if: {
                            properties: {
                              "metadata/allowInput": {
                                enum: [true],
                              },
                            },
                          },
                          then: {
                            properties: {
                              defaultValue: {
                                type: ["string", "null"],
                                nullable: true,
                                title: `Default Value`,
                                metadata: {
                                  allowInput: true,
                                  columnType: "categorical",
                                  excludeWidgetFilters: true,
                                  component: "ColumnValueInput",
                                  valuePresets: "otherColumnValues",
                                  columnName: "metadata/presetColumn",
                                  datasetIdName: "metadata/presetDatasetId",
                                  fieldProps: {
                                    popupMatchSelectWidth: false,
                                  },
                                },
                              },
                            },
                          },
                        },
                        "metadata/presetDatasetId": {
                          properties: {
                            "metadata/presetColumnType": {
                              title: "Preset Column Type",
                              type: "string",
                              metadata: {
                                hidden: true,
                              },
                            },
                            "metadata/presetColumn": {
                              title: "Preset Column",
                              type: "string",
                              metadata: {
                                validateFirst: true,
                                component: "ColumnSelect",
                                columnTypeName: "metadata/presetColumnType",
                                datasetIdName: "metadata/presetDatasetId",
                                fieldProps: {
                                  popupMatchSelectWidth: false,
                                },
                              },
                            },
                          },
                        },
                        "metadata/presetColumn": {
                          properties: {
                            "metadata/allowInput": {
                              type: "boolean",
                              default: true,
                              title: "Allow New Value Input",
                            },
                            defaultValue: {
                              type: ["string", "null"],
                              nullable: true,
                              title: `Default Value`,
                              metadata: {
                                columnType: "categorical",
                                excludeWidgetFilters: true,
                                component: "ColumnValueInput",
                                valuePresets: "otherColumnValues",
                                columnName: "metadata/presetColumn",
                                datasetIdName: "metadata/presetDatasetId",
                                fieldProps: {
                                  popupMatchSelectWidth: false,
                                },
                              },
                            },
                          },
                        },
                      },
                      properties: {
                        "metadata/presetDatasetId": {
                          type: "number",
                          title: "Preset Dataset",
                          metadata: {
                            component: "Dataset",
                          },
                        },
                        "metadata/popupMatchSelectWidth": {
                          default: false,
                          type: "boolean",
                          title: "Dropdown Match Select Width",
                        },
                      },
                    },
                  },
                ],
                properties: {
                  "metadata/popupMatchSelectWidth": {
                    default: false,
                    type: "boolean",
                    title: "Dropdown Match Select Width",
                  },
                  "metadata/valuePresets": {
                    type: "string",
                    default: "none",
                    title: "Value Presets",
                    enum: ["none", "columnValues", "otherColumnValues"],
                    metadata: {
                      labels: ["None", "Column Values", "Other Column Values"],
                    },
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  "metadata/columnType": {
                    enum: ["datetime"],
                  },
                },
              },
              then: {
                dependencies: datePickerOptionsSchema.dependencies,
                properties: {
                  "metadata/showPresets":
                    datePickerOptionsSchema.properties["metadata/showPresets"],
                  "metadata/picker":
                    datePickerOptionsSchema.properties["metadata/picker"],
                  defaultValue: datePickerOptionsSchema.properties.defaultValue,
                },
              },
            },
          ],
        },
      },
      properties: {
        "metadata/column": {
          title: "Column",
          type: "string",
          metadata: {
            validateFirst: true,
            component: "ColumnSelect",
            datasetIdName: "metadata/datasetId",
            columnTypeName: "metadata/columnType",
            fieldProps: {
              popupMatchSelectWidth: false,
            },
          },
        },
        "metadata/columnType": {
          title: "Type",
          type: "string",
          enum: [
            "categorical",
            "datetime",
            "string",
            "numerical",
            "float",
            "int",
          ],
          metadata: {
            hidden: true,
          },
        },
        ...scalarFieldOptions.properties,
        nullable: {
          type: "boolean",
          title: "Clearable",
          description: "Allow field to be removed from form state",
        },
        "metadata/fieldProps/placeholder": {
          type: "string",
          title: "Placeholder",
        },
      },
    },
  },
  properties: {
    "metadata/datasetId": {
      type: "number",
      title: "Dataset",
      description:
        "The dataset to use. If none is set, the dataset will be automatically determined based on other form fields.",
      metadata: {
        component: "Dataset",
      },
    },
  },
}
