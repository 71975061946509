import { createSelector } from "reselect"

export const selectCodeSearch = state => state?.codeSearch
export const selectCodeSearchTerm = createSelector(
  selectCodeSearch,
  codeSearch => codeSearch.searchTerm
)
export const selectCodeSearchOpen = createSelector(
  selectCodeSearch,
  codeSearch => codeSearch.searchOpen
)
