import { gql } from "@apollo/client"

export const GET_ENDPOINT_SERVER_STATUS = gql`
  query EndpointServerStatus($cname: String!) {
    endpointServerStatus(cname: $cname) {
      running
      url
    }
  }
`

export const START_ENDPOINT_SERVER = gql`
  mutation StartEndpointServer($cname: String!) {
    startEndpointServer(cname: $cname) {
      running
      url
    }
  }
`

export const STOP_ENDPOINT_SERVER = gql`
  mutation StopEndpointServer($cname: String!) {
    stopEndpointServer(cname: $cname) {
      running
      url
    }
  }
`
