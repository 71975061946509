import React from "react"

import { AuthBoundary } from "@dbai/ui-staples"

import ActionForm from "components/pages/Workflows/shared/ActionForm"

const NewAction = props => {
  const { afterSubmit } = props

  return (
    <AuthBoundary subject="actions" action="CREATE">
      <ActionForm afterSubmit={afterSubmit} />
    </AuthBoundary>
  )
}

export default NewAction
