import React from "react"
import { Button } from "antd"

import { Card } from "@dbai/ui-staples"

import DataLakeModal from "./shared/DataLakeModal"

const DeletionConfirmation = props => {
  const { onDelete, candidates = [], closeModal } = props

  return (
    <DataLakeModal isOpen={(candidates.length > 0)} onRequestClose={closeModal}>
      <Card>
        <Card.Header>Delete {candidates.length} file(s)?</Card.Header>
        <div className="btn-group">
          <Button type="danger" onClick={onDelete}>
            DELETE
          </Button>
          <Button type="primary" onClick={closeModal}>
            CANCEL
          </Button>
        </div>
      </Card>
    </DataLakeModal>
  )
}

export default DeletionConfirmation
