import { useMemo } from "react"

import buildSchema from "../lib/buildSchema"

const opLabelMap = {
  number: {
    "=": "is equal to",
    "!=": "is not equal to",
    "<": "is less than",
    ">": "is greater than",
    "<=": "is less than or equal to",
  },
  string: {
    "=": "is in",
    "!=": "is not in",
  },
  datetime: {
    "=": "is equal to",
    "!=": "is not equal to",
    "<": "is before",
    ">": "is after",
    "<=": "is before or equal to",
    ">=": "is after or equal to",
  },
}

const getOpLabel = (type, op) => {
  switch (type) {
    case "number":
    case "float":
    case "int":
      return opLabelMap.number[op]
    case "string":
    case "categorical":
      return opLabelMap.string[op]
    case "datetime":
      return opLabelMap.datetime[op]
    default:
      return ""
  }
}

const getTitle = (label, column, columnType, op) => {
  if (!label) {
    return `${column} ${getOpLabel(columnType, op)}`
  }
  return label
}

const useFilterWidgetSchema = (options, widgetId) => {
  return useMemo(() => {
    const { defaultValue, label, key, column, columnType, op } = options
    const title = getTitle(label, column, columnType, op)
    return buildSchema({
      properties: [
        {
          title,
          key: key ?? "value",
          defaultValue,
          nullable: true,
          type: "columnValuesSelect",
          minimum: options.minimum,
          maximum: options.maximum,
          metadata: {
            ...options,
            ...(options.metadata || {}),
            excludeWidgetFilters: widgetId ? [widgetId] : true,
          },
        },
      ],
      default: {},
      type: "object",
    })
  }, [options, widgetId])
}

export default useFilterWidgetSchema
