import rectShapeSchema from "./rectShapeSchema"

const datasetColumnShapeSchema = {
  metadata: {
    layout: { gutter: [16] },
    sections: {
      format: {
        title: "Format Options",
        properties: [
          "format",
          "minPrecision",
          "maxPrecision",
          "prefix",
          "suffix",
        ],
      },
      style: {
        title: "Shape Styles",
        properties: [
          "opacity",
          "fill",
          "stroke",
          "strokeWidth",
          "shadowColor",
          "shadowBlur",
          "shadowOpacity",
        ],
      },
    },
  },
  properties: {
    "/datasetColumn": {
      $ref: "#/$defs/datasetAndColumnSelect",
      metadata: {
        component: "DatasetAndColumnSelect",
        allowedTypes: ["number", "int", "float"],
      },
    },
    title: {
      title: "Title",
      type: ["string", "null"],
      nullable: true,
      metadata: {
        fieldProps: {
          placeholder: "None",
        },
      },
    },
    ...rectShapeSchema.properties,
  },
}

export default datasetColumnShapeSchema
