import { gql } from "@apollo/client"

export const GET_CUSTOMERS = gql`
  query Customers {
    customers {
      id
      name
      normalizedName
      datalakeResources
      createdAt
      updatedAt
    }
  }
`

export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($id: Int!) {
    deleteCustomer(id: $id) {
      id
    }
  }
`

// Reads the customer from the API, using the cached ID
export const GET_CURRENT_CUSTOMER = gql`
  query CurrentCustomer($name: String!) {
    customer(name: $name) {
      id
      name
      normalizedName
      datalakeResources
    }
  }
`

export const GET_CUSTOMER = gql`
  query GetCustomer($cname: String!) {
    customer(name: $cname) {
      id
      name
      normalizedName
      datalakeResources
      createdAt
    }
  }
`

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer($name: String!, $id: Int!) {
    updateCustomer(name: $name, id: $id) {
      id
      name
    }
  }
`

export const SIGNUP_CUSTOMER = gql`
  mutation SignUpNewCustomer($name: String!, $email: String!, $roles: [String]) {
    signupCustomer(name: $name, email: $email, roles: $roles) {
      id
      name
    }
  }
`

export const GET_CUSTOMER_POLICIES = gql`
  query GetCustomerPolicies($cname: String!) {
    customer(name: $cname) {
      policies {
        id
        name
        createdAt
        modifiable
        updatedAt
        rule {
          effect
          actions
          subjects
        }
      }
    }
  }
`

export const GET_CUSTOMER_ROLES = gql`
  query GetCustomerRoles($cname: String!) {
    customer(name: $cname) {
      id
      roles {
        id
        name
        roleType
        description
        createdAt
        updatedAt
        policies {
          id
          rule {
            effect
            actions
            subjects
          }
        }
      }
    }
  }
`
