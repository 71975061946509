import { scalarFieldOptions } from "../../lib/fieldOptionsSchemas"

export default {
  type: "object",
  default: {},
  metadata: {
    layout: { gutter: [16] },
    sections: {
      ...scalarFieldOptions.metadata.sections,
      numberOptions: {
        title: "Number Options",
        properties: [
          "multipleOf",
          "maximum",
          "minimum",
          "showSlider",
          "metadata/showNumberInput",
        ],
      },
    },
    sideEffects: [
      {
        resetValue: null,
        type: "resetField",
        sourceName: "showSlider",
        targetName: "minimum",
      },
      {
        resetValue: null,
        type: "resetField",
        sourceName: "showSlider",
        targetName: "maximum",
      },
    ],
  },
  dependencies: {
    showSlider: {
      if: {
        properties: {
          showSlider: {
            enum: [true],
          },
        },
      },
      then: {
        required: ["minimum", "maximum"],
        properties: {
          "metadata/showNumberInput": {
            default: true,
            type: "boolean",
            title: "Show Number Input",
          },
          maximum: {
            nullable: true,
            title: "Maximum",
            type: ["number", "null"],
            default: 100,
          },
          minimum: {
            nullable: true,
            title: "Minimum",
            type: ["number", "null"],
            default: 0,
          },
        },
      },
    },
  },
  properties: {
    ...scalarFieldOptions.properties,
    title: {
      default: "Number Form Field",
      type: "string",
      title: "Label",
      metadata: {
        fieldProps: {
          placeholder: "Enter a label",
        },
      },
    },
    nullable: {
      type: "boolean",
      title: "Clearable",
      description: "Allow field to be removed from form state",
    },
    defaultValue: {
      nullable: true,
      title: "Default Value",
      type: ["number", "null"],
    },
    multipleOf: {
      exclusiveMinimum: 0,
      title: "Multiple Of",
      type: ["number", "null"],
    },
    maximum: {
      nullable: true,
      title: "Maximum",
      type: ["number", "null"],
    },
    minimum: {
      nullable: true,
      title: "Minimum",
      type: ["number", "null"],
    },
    showSlider: {
      type: "boolean",
      title: "Show as Slider",
    },
    "metadata/fieldProps/placeholder": {
      type: "string",
      title: "Placeholder",
    },
  },
}
