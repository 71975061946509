import React from "react"
import { Col, Row } from "antd"

import { useModelFormInput } from "./hooks"

const ColSpecInput = props => {
  const { input = {} } = props
  const Comp = useModelFormInput(input.type)
  const displayName = `${input.name} (${input.type})`

  return <Comp name={input.name} displayName={displayName} type={input.type} />
}

const ColSpecForm = props => {
  const { inputs } = props

  return (
    <Row gutter={16}>
      {inputs.map(input => {
        return (
          <Col key={input.name} xl={12} lg={24}>
            <ColSpecInput input={input} />
          </Col>
        )
      })}
    </Row>
  )
}

export default ColSpecForm
