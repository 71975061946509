import React from "react"

import { RemoteSelect, useCurrentCustomer } from "@dbai/ui-staples"

import { GET_DATASETS } from "queries"

/**
 * Helper component to pull datasets for customers
 * SelectDataset -> RemoteSelect -> Select
 **/

const SelectDataset = props => {
  const [customer] = useCurrentCustomer()
  const variables = {
    customerId: customer.id,
  }

  return (
    <RemoteSelect
      label="DATASET"
      query={GET_DATASETS}
      variables={variables}
      collectionName="datasets"
      {...props}
    />
  )
}

export default SelectDataset
