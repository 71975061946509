import React, { useMemo, useState, useCallback } from "react"
import { Row, Col } from "antd"
import { capitalize } from "lodash"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faX,
  faTag,
  faBallot,
  faHashtag,
  faToggleOn,
  faEmptySet,
  faList,
} from "@fortawesome/pro-solid-svg-icons"

import Select from "../../shared/Select"
import JSONSchemaField from "../../JSONSchemaField"
import FormFieldWrapper from "../../FormFieldWrapper"
import { selectAppVariables } from "../../../selectors/app"

const StyledSelect = styled(Select)`
  width: 100%;
`

const typeIconMap = {
  string: <FontAwesomeIcon icon={faTag} />,
  number: <FontAwesomeIcon icon={faHashtag} />,
  integer: <FontAwesomeIcon icon={faHashtag} />,
  boolean: <FontAwesomeIcon icon={faToggleOn} />,
  object: <FontAwesomeIcon icon={faBallot} />,
  null: <FontAwesomeIcon icon={faEmptySet} />,
  NULL: <FontAwesomeIcon icon={faEmptySet} />,
  array: <FontAwesomeIcon icon={faList} />,
  AppVariableSelect: <FontAwesomeIcon icon={faX} />,
}

const typeBlacklist = ["null", "NULL", "array"]

const getAnyOfTypes = anyOf => {
  return anyOf.map(schema => {
    const { type, metadata = {} } = schema
    const { component } = metadata
    return component || type
  })
}
const getDefaultValueType = (value, appVariables, schema) => {
  switch (true) {
    case Boolean(appVariables?.find(v => v.id === value)):
      return "AppVariableSelect"
    case typeof value === "string":
      return "string"
    case typeof value === "number":
      return "number"
    case typeof value === "object":
      return "object"
    default:
      const firstAllOfType = getAnyOfTypes(schema.anyOf)[0]
      return firstAllOfType
  }
}

const useValueType = (value, schema) => {
  const appVariables = useSelector(selectAppVariables)
  const [valueType, setValueType] = useState(
    getDefaultValueType(value, appVariables, schema)
  )
  return [valueType, setValueType]
}

const AnyOfCore = props => {
  const { schema = {}, onChange } = props
  const [valueType, setValueType] = useValueType(props.value, schema)

  const handleTypeChange = useCallback(
    value => {
      // when value type changes, automatically set the value to undefined
      onChange && onChange(undefined)
      setValueType(value)
    },
    [setValueType, onChange]
  )

  const options = useMemo(() => {
    const anyOfTypes = getAnyOfTypes(schema.anyOf)
    return anyOfTypes
      .filter(type => !typeBlacklist.includes(type))
      .map(type => ({
        value: type,
        label: typeIconMap[type] ?? capitalize(type),
      }))
  }, [schema])

  const schemaWithType = useMemo(() => {
    const selectedSchema =
      schema.anyOf.find(({ type, metadata = {} }) => {
        const compareType = metadata.component || type
        return compareType === valueType
      }) || {}
    return { ...selectedSchema, title: schema.title }
  }, [valueType, schema.title, schema.anyOf])

  return (
    <Row gutter={[8, 8]}>
      <Col flex={1}>
        {valueType ? (
          <JSONSchemaField {...props} schema={schemaWithType} />
        ) : null}
      </Col>
      <Col>
        <StyledSelect
          options={options}
          defaultValue={valueType}
          onChange={handleTypeChange}
        />
      </Col>
    </Row>
  )
}

const AnyOf = props => {
  return (
    <FormFieldWrapper noStyle {...props}>
      <AnyOfCore />
    </FormFieldWrapper>
  )
}

AnyOf.Core = AnyOfCore
export default AnyOf
