import React, { useMemo } from "react"
import { uniqBy } from "lodash"
import styled from "styled-components"
import { toast } from "react-toastify"
import { useSelector } from "react-redux"
import { useMutation, useQuery } from "@apollo/client"
import { Button } from "antd"

import {
  Card,
  Form,
  SidePane,
  DBTable,
  DateFormatter,
  useFormState,
} from "@dbai/ui-staples"

import { stringSorter, numberSorter, dateSorter } from "@dbai/tool-box"

import { selectWorkflow } from "selectors"
import ImageFormatter from "./ImageFormatter"
import RadioFormatter from "./RadioFormatter"
import withCustomer from "components/shared/withCustomer"
import BadgeFormatter from "components/shared/BadgeFormatter"
import { RUN_PACKAGE, GET_PACKAGES, RUN_WORKFLOW } from "queries"

const FlexHeader = styled.h4`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    vertical-align: middle;
  }
`

const precedenceMap = {
  AVAILABLE: "success",
  BUILDING: "secondary",
  DEV: "danger",
}

const NullableDateFormatter = ({ value, format }) => {
  if (!value) return null
  return <DateFormatter value={value} format={format} />
}

const Notice = () => {
  const state = useFormState()
  return (
    <div className="alert alert-secondary" role="alert">
      <FlexHeader className="alert-title">
        <span>Submit For Remote Execution</span>
        <Button type="primary" htmlType="submit" disabled={!state.id}>
          Submit
        </Button>
      </FlexHeader>
      <p className="text-muted">
        Compile and execute your Workflow across your cluster. Nodes will be run
        in isolated Docker containers and scheduled to run in parallel where
        possible.
      </p>
      <p className="text-muted">
        Select the <span className="badge bg-danger">DEV</span> version to run
        the Workflow in it's current state, and test it in a productionized
        environment. For stable executions, select a previously packaged
        version.
      </p>
    </div>
  )
}

const ExecutePackage = props => {
  const { customer, panelOpen, closePanel } = props
  const workflow = useSelector(selectWorkflow)

  const initialState = useMemo(
    () => ({
      cid: customer.id,
      id: null,
      isDev: false,
    }),
    [customer.id]
  )

  const { data } = useQuery(GET_PACKAGES, {
    variables: { cname: customer.normalizedName, workflowId: workflow.id },
    pollInterval: panelOpen ? 5000 : 0,
  })

  const [runWorkflow] = useMutation(RUN_WORKFLOW)
  const [runPackage] = useMutation(RUN_PACKAGE)

  const onSubmit = state => {
    const { cid, id, isDev } = state
    const variables = { cid, id }
    const submit = isDev ? runWorkflow : runPackage

    submit({ variables }).then(() => {
      toast.info("Submitted Workflow")
    })
    closePanel()
  }

  /*
   * If we have multiple images with the same name then only the most recent
   * will ever be reachable. Don't show all the old overwritten images in a
   * repository.
   */
  const runnables = useMemo(() => {
    const sortImages = (a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt)
    }

    const devImage = {
      ...workflow,
      createdAt: null,
      isDev: true,
      status: "DEV",
    }
    const images = [...(data ? data.customer.packages : [])].sort(sortImages)

    return uniqBy([devImage, ...images], "image")
  }, [data, workflow])

  const columns = [
    {
      title: "",
      key: "id",
      dataIndex: "id",
      sorter: numberSorter("id"),
      render: (value, row) => {
        return <RadioFormatter value={value} row={row} />
      },
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      sorter: stringSorter("status"),
      render: (value, row) => {
        return (
          <BadgeFormatter
            precedenceMap={precedenceMap}
            value={value}
            row={row}
          />
        )
      },
    },
    {
      title: "Created",
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: dateSorter("createdAt"),
      render: (value, row) => {
        return (
          <NullableDateFormatter
            format="YYYY-MM-D hh:mm:ss"
            value={value}
            row={row}
          />
        )
      },
    },
    {
      title: "Image",
      key: "image",
      dataIndex: "image",
      sorter: stringSorter("image"),
      render: (value, row) => {
        return <ImageFormatter value={value} row={row} />
      },
    },
  ]

  return (
    <>
      <span id="execute-package"></span>
      <SidePane
        width="50vw"
        isVisible={panelOpen}
        onCloseClicked={closePanel}
        title="Execute"
        mask
      >
        <Form initialState={initialState} onSubmit={onSubmit} hideSubmit>
          <Notice />
          {data && (
            <Card id="executables" title="Versions" nopad>
              <DBTable
                dataSource={runnables}
                columns={columns}
                emptyText="No Versions Found"
              />
            </Card>
          )}
        </Form>
      </SidePane>
    </>
  )
}

export default withCustomer(ExecutePackage)
