import { gql } from "@apollo/client"

export const UPDATE_SERVICE_ACCOUNT_ROLES = gql`
  mutation UpdateServiceAccountRoles($cid: Int!, $id: Int!, $roleIds: [Int]!) {
    updateServiceAccountRoles(id: $id, customerId: $cid, roleIds: $roleIds) {
      id
      uuid
      roles {
        id
        name
      }
    }
  }
`

export const DELETE_SERVICE_ACCOUNT = gql`
  mutation DeleteServiceAccount($cid: Int!, $id: Int!) {
    deleteServiceAccount(id: $id, customerId: $cid) {
      id
    }
  }
`

export const GET_SERVICE_ACCOUNT_NAMES = gql`
  query GetServiceAccountNames($cname: String!) {
    customer(name: $cname) {
      serviceAccounts {
        id
        name
      }
    }
  }
`
