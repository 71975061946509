import merge from "lodash/merge"

//Helper function to generate a Highcharts CSS object
//Ref: https://api.highcharts.com/class-reference/Highcharts.CSSObject
const generateStyles = ({
  background,
  backgroundColor,
  border,
  borderRadius,
  color = "#666666",
  cursor,
  fontFamily = '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
  fontSize = "12px",
  fontWeight = "12px",
  height,
  lineWidth,
  opacity,
  padding,
  pointerEvents,
  position,
  textAlign,
  textDecoration,
  textOutline,
  textOverflow,
  top,
  transition,
  whiteSpace,
  width,
  ...rest
}) => {
  const font = `${fontWeight} ${fontSize} ${fontFamily}`
  return {
    background,
    backgroundColor,
    border,
    borderRadius,
    color,
    cursor,
    fontFamily,
    fontSize,
    fontWeight,
    font,
    height,
    lineWidth,
    opacity,
    padding,
    pointerEvents,
    position,
    textAlign,
    textDecoration,
    textOutline,
    textOverflow,
    top,
    transition,
    whiteSpace,
    width,
    ...rest,
  }
}

const marker = {
  enabledThreshold: 5,
  lineColor: "#ffffff",
  lineWidth: 0,
  radius: 2,
  states: {
    normal: {
      animation: true,
    },
    hover: {
      animation: {
        duration: 50,
      },
      enabled: true,
      radiusPlus: 2,
      lineWidthPlus: 1,
    },
    select: {
      fillColor: "#cccccc",
      lineColor: "#000000",
      lineWidth: 2,
    },
  },
  symbol: "circle",
}

const tooltipFormat = {
  pointFormat: "{point.y:,.4f} | <b>{point.x:%m/%d/%Y %I:%M%p}</b>",
}

const plotOptions = {
  line: {
    marker,
    tooltip: tooltipFormat,
    dataLabels: {},
  },
  area: {
    marker,
    tooltip: tooltipFormat,
    dataLabels: {},
    shadow: false,
    fillOpacity: 0.5,
  },
  column: {
    tooltip: tooltipFormat,
    dataLabels: {},
  },
  bar: {
    tooltip: tooltipFormat,
    dataLabels: {},
  },
  boxplot: {
    dataLabels: {},
    fillColor: "#F7F7F7",
    medianWidth: 2,
    stemWidth: 1,
    whiskerWidth: 2,
  },
  heatmap: {
    tooltip: {
      headerFormat: "",
    },
  },
  pie: {},
  series: {
    shadow: false,
    turboThreshold: 5000,
    boostThreshold: 5000,
    connectNulls: false,
    custom: {
      combineAxes: false,
    },
    tooltip: {},
    dataLabels: {
      format: "{point.y:,.2f}",
      style: generateStyles({
        fontFamily:
          '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
        fontSize: "11px",
        font: '12px "Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
      }),
    },
  },
}

const chartTheme = {
  backgroundColor: "#ffffff",
  borderColor: "#335cad",
  borderRadius: 0,
  borderWidth: 0,

  shadow: false,
  plotShadow: false,
  plotBorderColor: "#cccccc",
  plotBorderWidth: 0,
  plotBackgroundImage: undefined,
  plotBackgroundColor: undefined,
  style: generateStyles({
    fontFamily:
      '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
    fontSize: "12px",
    font: '12px "Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
  }),
}

const legendTheme = {
  align: "center",
  alignColumns: true,
  layout: "horizontal",
  verticalAlign: "bottom",
  borderColor: "#999999",
  backgroundColor: undefined,
  itemStyle: generateStyles({
    color: "#333333",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "bold",
    textOverflow: "ellipsis",
  }),
  itemHiddenStyle: generateStyles({
    color: "#cccccc",
    cursor: "pointer",
    fontSize: "12px",
    fontWeight: "bold",
    textOverflow: "ellipsis",
  }),
  itemHoverStyle: generateStyles({
    color: "#000000",
    cursor: "pointer",
    fontWeight: "bold",
    textOverflow: "ellipsis",
  }),
  itemCheckboxStyle: generateStyles({
    width: "13px",
    height: "13px",
    position: "absolute",
  }),
}

const tooltipTheme = {
  borderRadius: 3,
  pointFormat:
    '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>',
  borderColor: undefined,
  style: generateStyles({
    color: "black",
    fontSize: "12px",
    zIndex: 20,
  }),
}

const xAxisTheme = {
  title: {
    text: undefined,
    align: "middle",
    style: generateStyles({
      fontSize: "12px",
    }),
  },
  labels: {
    style: generateStyles({
      fontSize: "11px",
    }),
  },
  minorGridLineDashStyle: "Solid",
  gridLineDashStyle: "Solid",
  gridLineColor: "#e6e6e6",
  lineColor: "#ccd6eb",
  minorGridLineColor: "#f2f2f2",
  minorTickColor: "#999999",
  tickColor: "#ccd6eb",

  startOnTick: false,
  endOnTick: false,
  minPadding: 0.01,
  maxPadding: 0.01,
  reverse: false,

  gridLineWidth: 0,
  minorGridLineWidth: undefined,
  minorTickInterval: undefined,
  tickLength: undefined,
  tickWidth: undefined,
}

const yAxisTheme = {
  lineColor: "#ccd6eb",
  maxColor: "#003399",
  minColor: "#e6ebf5",
  gridLineColor: "#e6e6e6",
  gridLineDashStyle: "Solid",
  minorGridLineColor: "#f2f2f2",
  minorGridLineDashStyle: "Solid",
  minorTickColor: "#999999",
  tickColor: "#ccd6eb",

  minPadding: 0.05,
  maxPadding: 0.2,
  reverse: false,
  startOnTick: true,
  endOnTick: true,

  tickLength: 10,
  tickWidth: 0,
  gridLineWidth: 0,
  minorTickInterval: 0,
  tickInterval: undefined,
  minorGridLineWidth: undefined,
  title: {
    align: "middle",
    style: generateStyles({
      fontSize: "12px",
    }),
  },
  labels: {
    enabled: true,
    style: generateStyles({
      fontSize: "11px",
    }),
  },
}

const customDefault = {
  plotOptions,
  colors: [
    "#2d8f28",
    "#FB8C00",
    "#0067a9",
    "#e74c3c",
    "#f1c40f",
    "#9b59b6",
    "#333333",
    "#FF3399",
    "#1A237E",
    "#999999",
  ],
  chart: {
    panning: {
      enabled: true,
      type: "xy",
    },
    panKey: "shift",
    defaultSeriesType: "line",
    type: "line",
    animation: true,
    zoomType: "x",
  },
  legend: {
    enabled: true,
  },
  tooltip: {
    animation: false,
    borderWidth: 0,
    backgroundColor: "none",
    headerFormat: "",
    shadow: false,
    style: {
      fontSize: "12px",
      zIndex: 20,
    },
  },
  xAxis: {
    lineWidth: 1,
    crosshair: {
      zIndex: 2,
      width: 2,
      color: "#d7822f",
      dashStyle: "Solid",
    },
  },
  yAxis: {
    lineWidth: 1,
    visible: true,
    title: {
      text: undefined,
    },
    labels: {
      enabled: true,
    },
    plotLines: [
      {
        value: 2,
        color: "red",
        width: 0,
        label: {
          text: "",
          style: {
            fontSize: 10,
          },
        },
      },
    ],
  },
  colorAxis: undefined,
  labels: {
    style: {},
  },
  title: { text: "" },
  subtitle: { title: "" },
  caption: { title: "" },
  credits: {
    enabled: false,
  },
  loading: {
    hideDuration: 1000,
    showDuration: 1000,
  },
}

const defaultOptions = merge({}, customDefault)
const defaultTheme = merge({}, defaultOptions, {
  yAxis: yAxisTheme,
  xAxis: xAxisTheme,
  tooltip: tooltipTheme,
  legend: legendTheme,
  chart: chartTheme,
})
export default defaultTheme
