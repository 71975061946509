import { flow, curry } from "lodash"

import constructTitle from "./constructTitle"
import constructYAxis from "./constructYAxis"
import constructChart from "./constructChart"
import constructPlotOptions from "./constructPlotOptions"
import constructBasicSeries from "./constructSeries/basic"
import constructPieSeries from "./constructSeries/pie"
import constructBoxplotSeries from "./constructSeries/boxplot"
import constructHeatmapSeries from "./constructSeries/heatmap"
import constructGaugeSeries from "./constructSeries/gauge"
import constructLegend from "./constructLegend"
import constructTooltip from "./constructTooltip"
import constructExports from "./constructExports"
import constructTheme from "./constructTheme"

const deconstructChart = options => {
  const { highchart } = options
  return highchart
}

// Helper function that can be used to easily debug the
// chart construction process
// const debugStep = curry((prefix, vars) => {
//   console.log(prefix, vars)
//   return vars
// })

const constructSeries = curry(
  (dataset, selectPoints, selectedPoints, options) => {
    switch (options?.chart?.type) {
      case "heatmap":
        return constructHeatmapSeries(dataset, options)
      case "pie":
        return constructPieSeries(
          dataset,
          options,
          selectPoints,
          selectedPoints
        )
      case "boxplot":
        return constructBoxplotSeries(
          dataset,
          options,
          selectPoints,
          selectedPoints
        )
      case "solidgauge":
        return constructGaugeSeries(dataset, options)
      default:
        return constructBasicSeries(
          dataset,
          options,
          selectPoints,
          selectedPoints
        )
    }
  }
)

const constructChartOptions = (
  dataset,
  theme,
  options,
  selectPoints,
  selectedPoints
) => {
  return flow([
    // order matters here - the chart series must be constructed since the axes depend on it
    constructChart(selectPoints),
    constructSeries(dataset, selectPoints, selectedPoints),
    constructYAxis(dataset),
    constructTooltip(dataset, selectedPoints),
    constructLegend,
    constructExports,
    constructTitle,
    constructPlotOptions(selectPoints, selectedPoints),
    deconstructChart,
    constructTheme(theme),
  ])(options)
}

export default constructChartOptions
