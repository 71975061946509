import React, { useRef } from "react"
import styled from "styled-components"
import { useDrag, useDrop } from "react-dnd"

const type = "dnd-table-row"

const StyedTableRow = styled.tr`
  ${({ isOver, dropDirection, theme }) => {
    if (isOver) {
      return `&&& {
      td {
      background-color: ${theme.neutral};
      }
    }`
    }
    return ""
  }}
`

const DraggableTableRow = ({
  style,
  index,
  moveRow,
  rowName,
  isFolder,
  ...restProps
}) => {
  const ref = useRef(null)
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: type,
    drop: item => moveRow(item.rowName, rowName),
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  })
  const [, drag] = useDrag({
    item: { type, rowName },
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  })
  if (isFolder) {
    drop(ref)
  } else {
    drop(drag(ref))
  }
  return (
    <StyedTableRow
      ref={ref}
      isOver={isOver && canDrop}
      style={{ cursor: isFolder ? "pointer" : "move", ...style }}
      {...restProps}
    />
  )
}

export default DraggableTableRow
