import React from "react"
import styled, { css } from "styled-components"

import useDropout from "../hooks/useDropout"

const downArrow = css`
  border-top: 0.7rem solid white;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
`

const upArrow = css`
  border-bottom: 0.7rem solid white;
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
`

const rightArrow = css`
  border-left: 0.7rem solid white;
  border-bottom: 0.5rem solid transparent;
  border-top: 0.5rem solid transparent;
`

const leftArrow = css`
  border-right: 0.7rem solid white;
  border-top: 0.5rem solid transparent;
  border-bottom: 0.5rem solid transparent;
`

const arrowMap = {
  up: downArrow,
  down: upArrow,
  left: rightArrow,
  right: leftArrow,
}

// dirMap takes a directive from `direction` prop and maps it to the set of
// sides that the dropdown should be positioned relative to. For example,
// `right down` will position the box relative to the `top` and the `left` of
// the parent element.
const dirMap = {
  down: { y: "top", x: "right" },
  "down left": { y: "top", x: "right" },
  "down right": { y: "top", x: "left" },

  up: { y: "bottom", x: "right" },
  "up left": { y: "bottom", x: "right" },
  "up right": { y: "bottom", x: "left" },

  left: { y: "top", x: "right" },
  "left down": { y: "top", x: "right" },
  "left up": { y: "bottom", x: "right" },

  right: { y: "top", x: "left" },
  "right down": { y: "top", x: "left" },
  "right up": { y: "bottom", x: "left" },
}

const withOffset = (b, offset) => (b ? `calc(100% + ${offset})` : offset)

const Container = styled.div.attrs(props => ({
  side: dirMap[props.direction] || dirMap.down,
  arrow: arrowMap[props.direction.split(" ")[0]] || upArrow,

  vertical: ["up", "down"].includes(props.direction.split(" ")[0]),
  horizontal: ["left", "right"].includes(props.direction.split(" ")[0]),
}))`
  position: absolute;
  min-width: 200px;
  color: rgb(73, 80, 87);
  font-size: 1rem;
  font-weight: 400;
  text-align: left;

  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  background-color: #fff;

  box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.19);
  z-index: 100;

  ${({ side, vertical, horizontal, position }) => `
    ${side.y}: ${withOffset(vertical, position.offsetY)};
    ${side.x}: ${withOffset(horizontal, position.offsetX)};
  `}

  &:before {
    content: "";
    position: absolute;
    ${({ arrow, side, vertical, horizontal }) => `
      ${arrow}
      ${side.y}: ${vertical ? "calc(-0.75rem + 1px)" : "calc(0.4rem + 1px)"};
      ${side.x}: ${horizontal ? "calc(-0.75rem + 1px)" : "calc(0.4rem + 1px)"};
    `}
  }

  hr {
    margin: 0;
  }
`

const Dropout = props => {
  const { children, open, ...rest } = props

  if (!open) return null
  return <Container {...rest}>{props.children}</Container>
}

const Item = props => {
  const { children, ...rest } = props
  return <div {...rest}>{props.children}</div>
}

Dropout.Item = styled(Item)`
  width: 100%;
  padding: 0.5rem;
  line-height: 1rem;

  a {
    color: rgb(73, 80, 87);
  }

  &:hover {
    background-color: #f5f7fb;
  }
`

export { useDropout }
export default Dropout
