import { curry } from "lodash"

/*
 * Checks if the given node is a directory or a file.
 */
export const checkIfDir = node => !node?.file?.uuid

/*
 * For use as the argument to `Array.prototype.sort`. Orders alphabetically
 * after ensuring that directories are presented first.
 */
export const sortNodes = (nodeA, nodeB) => {
  const aIsDir = checkIfDir(nodeA)
  const bIsDir = checkIfDir(nodeB)

  switch (true) {
    case aIsDir === bIsDir:
      return nodeA?.name?.localeCompare(nodeB.name)
    case aIsDir:
      return -1
    default:
      return 1
  }
}

export const splitPath = path => (path || "").split("/").filter(Boolean)

export const getName = node => node?.name || ""

export const filterIncompleteUploads = node => {
  if (checkIfDir(node)) return true
  return node?.file?.status === "COMPLETED"
}

/*
 * Given the id of a node (nodeId) and a node (startNode), recursively descend
 * through startNode's child nodes until one with an id of nodeId is found. At
 * that point, terminate the search and return the node that was found as well
 * as an array of node ids leading from the start node to the node that we
 * located.
 * These values are returned as an object with two keys:
 * node: The node that we found. `null` if no node was found.
 * path: The array of ids. Is an empty array if no node was found.
 */
export const findNode = (nodeId, startNode) => {
  // We need to know if we found the node so that we can stop iterating down
  // the tree. Store the desired node outside of the search function to enable
  // this. If `targetNode` exists when an interation begins, abort.
  let targetNode = null
  let targetPath = []
  const searchForNode = curry((nodeId, startPath, ancestorNode) => {
    if (!ancestorNode) return
    const path = [...startPath, ancestorNode.id]
    switch (true) {
      case !!targetNode:
        return
      case !ancestorNode:
        return
      case ancestorNode.id === nodeId:
        targetNode = ancestorNode
        targetPath = path.filter(Boolean)
        return
      case !ancestorNode.nodes?.length:
        return
      default:
        return ancestorNode.nodes.map(searchForNode(nodeId, path))
    }
  })

  searchForNode(nodeId, [], startNode)
  return {
    node: targetNode,
    path: targetPath,
  }
}
