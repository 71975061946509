import pagesObserver from "./observers/pagesObserver"
import pageObserver from "./observers/pageObserver"
import widgetsObserver from "./observers/widgetsObserver"
import widgetObserver from "./observers/widgetObserver"
import endpointsObserver from "./observers/endpointsObserver"
import endpointObserver from "./observers/endpointObserver"
import variablesObserver from "./observers/variablesObserver"
import variableObserver from "./observers/variableObserver"
import filtersObserver from "./observers/filtersObserver"
import filterObserver from "./observers/filterObserver"
import themeObserver from "./observers/themeObserver"

const initObservers = (yDoc, dispatch) => {
  try {
    themeObserver(yDoc, dispatch)
    pagesObserver(yDoc, dispatch)
    pageObserver(yDoc, dispatch)
    endpointsObserver(yDoc, dispatch)
    endpointObserver(yDoc, dispatch)
    widgetsObserver(yDoc, dispatch)
    widgetObserver(yDoc, dispatch)
    variablesObserver(yDoc, dispatch)
    variableObserver(yDoc, dispatch)
    filtersObserver(yDoc, dispatch)
    filterObserver(yDoc, dispatch)
    return true
  } catch (e) {
    console.error("Failed to initialize YJS observers.", e)
    return false
  }
}

export default initObservers
