import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useHotkeys } from "react-hotkeys-hook"

import store from "reducers"
import { actions as notebookActions } from "reducers/notebookReducer"
import { actions as codeSearchActions } from "reducers/codeSearchReducer"
import {
  selectFocusedCell,
  selectFocusedNode,
  selectAutoScroll,
  selectDisableMoveCursor,
} from "selectors"
import {
  checkIfLastCell,
  useExecCellByUuid,
  useExecFocusedCell,
} from "components/pages/Workflows/Edit/shared/util"

// allowHotkeysOnCM will take the input event and determine whether hotkeys
// should be fired By default, hotkeys are disabled on textarea and input
// fields. So, without this option, events from code mirror won't be fired.
const allowHotkeysOnCM = { enableOnTags: ["INPUT", "TEXTAREA"] }

const body = document.querySelector("body")
const checkIfValidFocus = element => {
  return Boolean(element === body || element.closest(".CodeMirror"))
}

const useModeKey = opts => {
  const { targetMode, key, callback } = opts
  const dispatch = useDispatch()

  useHotkeys(
    key,
    e => {
      // If at anypoint before it gets here `hotkeyIgnore` is set to a truthy
      // value on the event, the hotkey will skip it's normal action.
      if (e.hotkeyIgnore) return
      if (!checkIfValidFocus(document.activeElement)) return
      dispatch((_, getState) => {
        const {
          notebook: {
            notebook: { mode },
          },
        } = getState()

        if (targetMode === mode) callback(e)
      })
    },
    allowHotkeysOnCM,
    [dispatch, callback]
  )
}

const useCommandKey = args => useModeKey({ targetMode: "command", ...args })
const useEditKey = args => useModeKey({ targetMode: "edit", ...args })

const useHotKeyMap = () => {
  const dispatch = useDispatch()
  const execCell = useExecCellByUuid()
  const execCurrentCell = useExecFocusedCell()
  const setCommandMode = useCallback(
    e => {
      dispatch(notebookActions.setCommandMode())
    },
    [dispatch]
  )

  const setEditMode = useCallback(
    e => {
      dispatch(notebookActions.setEditMode())
      // Stop this e from being received by the editor
      return e.preventDefault()
    },
    [dispatch]
  )

  const focusNext = useCallback(
    e => {
      dispatch(notebookActions.focusDown())
    },
    [dispatch]
  )

  const focusPrev = useCallback(
    e => {
      dispatch(notebookActions.focusUp())
    },
    [dispatch]
  )

  const insertCell = useCallback(
    e => {
      dispatch(notebookActions.insertCurrentCell())
    },
    [dispatch]
  )

  const appendCell = useCallback(
    e => {
      dispatch(notebookActions.insertAfterCurrentCell())
      dispatch(notebookActions.focusDown())
    },
    [dispatch]
  )

  const deleteCell = useCallback(
    e => {
      const state = store.getState()
      const autoScroll = selectAutoScroll(state)
      const node = selectFocusedNode(state)
      if (!node || node?.cells?.length <= 1) return

      const { source } = selectFocusedCell(state)
      if (source.length === 0 || (source.length === 1 && source[0] === "")) {
        dispatch(notebookActions.deleteCurrentCell())
        if (autoScroll) {
          dispatch(notebookActions.focusUp())
        }
      }
    },
    [dispatch]
  )

  const onExec = useCallback(() => {
    const state = store.getState()
    const disableMoveCursor = selectDisableMoveCursor(state)

    if (checkIfLastCell()) {
      dispatch(notebookActions.appendCurrentCell())
    }
    if (!disableMoveCursor) {
      dispatch(notebookActions.focusDown())
    }
  }, [dispatch])

  const executeNode = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      const node = selectFocusedNode(store.getState())
      node.cells.forEach(cell => {
        execCell(cell.uuid)
      })

      dispatch(notebookActions.focusDown())
    },
    [dispatch, execCell]
  )

  const handleExecute = useCallback(
    e => {
      const node = selectFocusedNode(store.getState())
      if (node?.collapsed) return executeNode(e)
      return execCurrentCell(onExec)
    },
    [execCurrentCell, executeNode, onExec]
  )

  const onExecNodeWithoutMove = useCallback(() => {
    if (checkIfLastCell()) {
      dispatch(notebookActions.appendCurrentCell())
    }
  }, [dispatch])

  const executeNodeWithoutMove = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      const node = selectFocusedNode(store.getState())
      node.cells.forEach(cell => {
        execCell(cell.uuid)
      })

    },
    [execCell])

    const handleExecuteWithoutMove = useCallback(
    e => {
      const node = selectFocusedNode(store.getState())
      if (node?.collapsed) return executeNodeWithoutMove(e)
      return execCurrentCell(onExecNodeWithoutMove)
    },
    [execCurrentCell, executeNodeWithoutMove, onExecNodeWithoutMove]
  )

  const openCodeSearch = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      dispatch(codeSearchActions.setSearchOpen())
    },
    [dispatch]
  )

  const goToNextMatch = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      dispatch(codeSearchActions.goToNextMatch())
    },
    [dispatch]
  )

  const goToPriorMatch = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      dispatch(codeSearchActions.goToPriorMatch())
    },
    [dispatch]
  )

  useCommandKey({ key: "k,up", callback: focusPrev })
  useCommandKey({ key: "j,down", callback: focusNext })
  useCommandKey({ key: "a", callback: insertCell })
  useCommandKey({ key: "b", callback: appendCell })
  useCommandKey({ key: "d", callback: deleteCell })
  useCommandKey({ key: "i", callback: setEditMode })
  useCommandKey({ key: "enter", callback: setEditMode })
  useCommandKey({ key: "f,/", callback: openCodeSearch })
  useCommandKey({ key: "n", callback: goToNextMatch })
  useCommandKey({ key: "shift+n", callback: goToPriorMatch })
  useEditKey({ key: "esc", callback: setCommandMode })
  useEditKey({ key: "shift+enter", callback: handleExecute })
  useEditKey({ key: "ctrl+enter", callback: handleExecuteWithoutMove})
  useEditKey({ key: "shift+backspace", callback: deleteCell })
}

export default useHotKeyMap
