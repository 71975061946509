import React, { useMemo, useState, useCallback } from "react"
import styled from "styled-components"
import { Row, Col, Table, Button } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { Card, Select } from "@dbai/ui-staples"

import { pythonTypes } from "constants/index"
import { selectActionInputs } from "selectors"
import { actions } from "reducers/actionReducer"

const typeOptions = pythonTypes.map(typeName => ({
  value: typeName,
  label: typeName,
}))

const emptyInput = {
  name: "",
  type: "",
}

const ButtonPositioner = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 3px;
`

const StyledFormGroup = styled.div`
  margin-right: 8px;
`

// Not my favorite way of styling this but it's the interface that ReactSelect
// provides.
const menuStyler = {
  menu: base => ({ ...base, zIndex: 100 }),
}

export const NewInput = props => {
  const dispatch = useDispatch()
  const [input, setInput] = useState(emptyInput)
  const handleSubmit = () => {
    if (!input.name) return
    dispatch(actions.addScriptArgument(input))
    setInput(emptyInput)
  }

  const updateName = useCallback(
    e => setInput({ ...input, name: e.target.value }),
    [input]
  )

  const updateType = useCallback(
    option => setInput({ ...input, type: option.value }),
    [input]
  )

  const selectedType = useMemo(
    () => typeOptions.find(opt => opt.value === input.type) || null,
    [input.type]
  )

  return (
    <>
      <Row justify="start">
        <Col span={24}>
          <label className="form-label">New Input</label>
        </Col>
        <Col span={8}>
          <StyledFormGroup className="form-group">
            <input
              className="form-control"
              placeholder="Name"
              name="input-name"
              value={input.name}
              onChange={updateName}
            />
          </StyledFormGroup>
        </Col>
        <Col span={8}>
          <div className="form-group">
            <Select
              placeholder="Type"
              options={typeOptions}
              value={selectedType}
              onChange={updateType}
              styles={menuStyler}
            />
          </div>
        </Col>
        <Col span={8}>
          <ButtonPositioner className="form-group">
            <Button type="primary" onClick={handleSubmit}>
              + ADD INPUT
            </Button>
          </ButtonPositioner>
        </Col>
      </Row>
    </>
  )
}

const DeleteButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
`

const DeleteButton = props => {
  const dispatch = useDispatch()
  const { row } = props
  const handleClick = useCallback(() => {
    dispatch(actions.deleteInputByName(row.name))
  }, [dispatch, row.name])

  return (
    <DeleteButtonWrapper>
      <Button type="danger" onClick={handleClick}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </Button>
    </DeleteButtonWrapper>
  )
}

const ColWithMargin = styled(Col)`
  margin-bottom: 1rem;
`

export const InputTable = props => {
  const inputs = useSelector(selectActionInputs)
  const locale = { emptyText: "No inputs" }

  return (
    <Row>
      <ColWithMargin span={24}>
        <Card title="Inputs" nopad>
          <Table
            size="small"
            locale={locale}
            pagination={false}
            dataSource={inputs}
          >
            <Table.Column dataIndex="name" title="Name" />
            <Table.Column dataIndex="type" title="Type" />
            <Table.Column
              name=""
              dataIndex="actions"
              render={(_, row) => <DeleteButton row={row} />}
            />
          </Table>
        </Card>
      </ColWithMargin>
    </Row>
  )
}

const Inputs = props => {
  return (
    <>
      <NewInput />
      <InputTable />
    </>
  )
}

export default Inputs
