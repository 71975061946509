import React, { useMemo, useState } from "react"
import { theme, ConfigProvider, Layout } from "antd"
import Modal from "react-modal"
import { history } from "sentry"
import client from "apolloClient"
import GraphiQL from "graphiqlWrapper"
import { ToastContainer } from "react-toastify"
import { ApolloProvider } from "@apollo/client"
import styled, { ThemeProvider } from "styled-components"
import { Provider as ReduxProvider } from "react-redux"
import { Route, Switch, BrowserRouter as Router } from "react-router-dom"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"

import {
  Login,
  Footer,
  AuthWatcher,
  LoginRedirect,
  BoundaryRoute,
  ResetPassword,
  Authenticated,
  ForgotPassword,
  CurrentCustomer,
  darkThemeColors,
  getSharableTheme,
  lightThemeColors,
  AcceptInvitation,
  ErrorLoggerProvider,
  RedirectContextProvider,
  useRouteIsBlacklisted,
} from "@dbai/ui-staples"

import store from "reducers"
import { initLogger } from "./config"
import Header from "components/shared/Header"
import SideNav from "components/shared/SideNav"
import DefaultPath from "components/shared/DefaultPath"
import CustomerDefaultPath from "components/shared/CustomerDefaultPath"
import {
  Apps,
  Profile,
  S3Datalake,
  Datasets,
  Settings,
  Customers,
  Workflows,
  AppletView,
  EditComponent,
  NewDataset,
  EditAction,
  EditDataset,
  CustomerNew,
  ThemeEditor,
  AppletEditor,
  EditWorkflow,
  CustomerUploads,
  WidgetTypeEditor,
} from "components/pages"

import "@dbai/ui-staples/styles/App.scss"
import "react-toastify/dist/ReactToastify.css"
import "codemirror/lib/codemirror.css"
import "codemirror/theme/solarized.css"
import "codemirror/theme/material.css"
import "codemirror/theme/material-darker.css"
import "react-quill/dist/quill.snow.css"

const { useToken, defaultAlgorithm, darkAlgorithm } = theme
const { Content } = Layout

const StyledContent = styled(Content)`
  ${({ sider }) => (sider ? `margin-left: 95px;` : null)}
  min-height: calc(100vh - 64px);
`

Modal.setAppElement("#root")

const navBlacklist = [
  "/:cname/apps/:id([0-9]+)",
  "/:cname/apps/:id([0-9]+)/current",
]

const AppRoutes = props => {
  const { themeMode, setThemeMode } = props
  const routeIsBlacklisted = useRouteIsBlacklisted(navBlacklist)
  return (
    <Switch>
      <Route path="*">
        <Layout>
          {!routeIsBlacklisted ? (
            <Header themeMode={themeMode} setThemeMode={setThemeMode} />
          ) : null}
          <BoundaryRoute path="/login">
            <StyledContent>
              <Login />
            </StyledContent>
          </BoundaryRoute>
          <Authenticated noAuth>
            <Switch>
              <BoundaryRoute path="/accept_invite/:token">
                <StyledContent>
                  <AcceptInvitation />
                </StyledContent>
              </BoundaryRoute>
              <BoundaryRoute path="/reset_password/:token/:email">
                <StyledContent>
                  <ResetPassword />
                </StyledContent>
              </BoundaryRoute>
              <BoundaryRoute path="/forgot_password">
                <StyledContent>
                  <ForgotPassword />
                </StyledContent>
              </BoundaryRoute>
              <Route path="/*" render={() => <LoginRedirect />} />
            </Switch>
          </Authenticated>
          <Authenticated>
            <AuthWatcher>
              <Layout className="app" hasSider={!routeIsBlacklisted}>
                <BoundaryRoute path="/:cname">
                  <CurrentCustomer />
                  {!routeIsBlacklisted ? <SideNav /> : null}
                </BoundaryRoute>
                <StyledContent sider={!routeIsBlacklisted}>
                  <Switch>
                    <BoundaryRoute path="/accept_invite/:token">
                      <AcceptInvitation />
                    </BoundaryRoute>
                    <BoundaryRoute path="/dev/graphiql">
                      <GraphiQL />
                    </BoundaryRoute>
                    <BoundaryRoute path="/profile">
                      <Profile />
                    </BoundaryRoute>
                    <BoundaryRoute path="/admin/customers/new">
                      <CustomerNew />
                    </BoundaryRoute>
                    <BoundaryRoute path="/admin/customers">
                      <Customers />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/settings">
                      <Settings />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/uploads">
                      <CustomerUploads />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/datasets/new">
                      <NewDataset />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/datasets/:id">
                      <EditDataset />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/datasets">
                      <Datasets />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/datalake">
                      <S3Datalake themeMode={themeMode} />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/apps/widget-registry/:id([0-9]+)">
                      <WidgetTypeEditor />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/apps/themes/:id([0-9]+)">
                      <ThemeEditor />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/apps/:id([0-9]+)/current">
                      <AppletView />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/apps/:id([0-9]+)">
                      <AppletEditor />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/apps">
                      <Apps />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/workflows/library/:id([0-9]+)">
                      <EditAction />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/workflows/components/:id([0-9]+)">
                      <EditComponent />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/workflows/:id([0-9]+)">
                      <EditWorkflow />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname/workflows/(library|schedules|packages|components)*">
                      <Workflows />
                    </BoundaryRoute>
                    <BoundaryRoute path="/:cname">
                      <CustomerDefaultPath />
                    </BoundaryRoute>
                    <BoundaryRoute path="*">
                      <DefaultPath />
                    </BoundaryRoute>
                  </Switch>
                </StyledContent>
              </Layout>
            </AuthWatcher>
          </Authenticated>
          <Footer />
        </Layout>
      </Route>
    </Switch>
  )
}

const AppRoutesWithTheme = props => {
  const { token } = useToken()
  const sharedTheme = getSharableTheme(token, props.themeMode)
  return (
    <RedirectContextProvider>
      <ThemeProvider theme={sharedTheme}>
        <AppRoutes {...props} />
      </ThemeProvider>
    </RedirectContextProvider>
  )
}

const App = () => {
  const [themeMode, setThemeMode] = useState(
    localStorage.getItem("theme-mode") || "light"
  )

  const theme = useMemo(() => {
    const theme = themeMode === "dark" ? darkThemeColors : lightThemeColors
    return {
      algorithm: [themeMode === "dark" ? darkAlgorithm : defaultAlgorithm],
      token: themeMode === "dark" ? darkThemeColors : lightThemeColors,
      components: {
        Layout: {
          siderBg: theme.colorHeader,
        },
      },
    }
  }, [themeMode])

  const changeThemeMode = themeMode => {
    setThemeMode(themeMode)
    localStorage.setItem("theme-mode", themeMode)
  }

  return (
    <ErrorLoggerProvider initLogger={initLogger}>
      <Router history={history}>
        <ReduxProvider store={store}>
          <ConfigProvider componentSize="medium" theme={theme}>
            <DndProvider backend={HTML5Backend}>
              <ApolloProvider client={client}>
                <ToastContainer
                  position="bottom-right"
                  autoClose={3000}
                  newestOnTop={false}
                  theme="colored"
                  closeOnClick
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
                <AppRoutesWithTheme
                  themeMode={themeMode}
                  setThemeMode={changeThemeMode}
                />
              </ApolloProvider>
            </DndProvider>
          </ConfigProvider>
        </ReduxProvider>
      </Router>
    </ErrorLoggerProvider>
  )
}

export default App
