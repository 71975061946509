import React from "react"
import { useDispatch } from "react-redux"

import { actions } from "reducers/notebookReducer"
import ActionColorPicker from "components/shared/ActionColorPicker"

const ColorPicker = props => {
  const { nodeIdx, afterChange } = props
  const dispatch = useDispatch()

  const changeColor = color => {
    if (!color) return
    dispatch(
      actions.updateNodeField({
        nodeIdx,
        field: "data.color",
        value: color.hex,
      })
    )
    afterChange && afterChange()
  }

  return <ActionColorPicker onChange={changeColor} />
}

export default ColorPicker
