import { get } from "lodash"

const DATASET_COMPONENT_NAME = "Dataset"
const COLUMN_COMPONENT_NAME = "ColumnSelect"

const findPropertyWithComponent = (schemaProperties = {}, component) => {
  const properties = Object.entries(schemaProperties)
  return properties?.find(([name, schema]) => {
    if (schema.metadata?.component === component) {
      return true
    }
    if (schema.properties) {
      return findPropertyWithComponent(schema.properties, component)
    }
    if (schema.items) {
      return findPropertyWithComponent(schema.items.properties, component)
    }
    return false
  })
}

const getLocalDatasetId = (
  schema,
  parentSchema,
  data,
  parentName,
  rootSchema,
  rootData
) => {
  const { metadata } = schema

  // First check: datasetId exists in the schema's metadata
  const { datasetId } = metadata || {}
  if (datasetId) return { datasetId }

  // Second check: datasetIdName exists in schema's metadata
  const { datasetIdName } = metadata || {}
  if (datasetIdName) {
    const { properties = {} } = parentSchema
    const foundProperty = properties[datasetIdName]
    if (foundProperty) {
      return {
        datasetId: get(
          data,
          parentName ? `${parentName}.${datasetIdName}` : datasetIdName,
          null
        ),
      }
    }
  }

  // Third check: schema's parent is an object and contains a property
  // with the 'Dataset' component
  const { properties = {} } = parentSchema
  const foundProperty = findPropertyWithComponent(
    properties,
    DATASET_COMPONENT_NAME
  )

  // Fourth check: try to find the dataset id from the root schema
  if (!foundProperty) {
    if (!rootSchema) {
      console.error(`Missing property ${DATASET_COMPONENT_NAME} in schema`)
      return {}
    }
    return getLocalDatasetId({}, rootSchema, rootData)
  }

  return {
    datasetId: get(
      data,
      parentName ? `${parentName}.${foundProperty[0]}` : foundProperty[0],
      null
    ),
  }
}

const getLocalColumn = (
  schema,
  parentSchema,
  data,
  parentName,
  rootSchema,
  rootData
) => {
  const { metadata } = schema
  // First check: column exists in the schema's metadata
  const { column } = metadata || {}
  if (column) {
    return { column, type: metadata.columnType }
  }

  // Second check: columnName exists in schema's metadata
  const { columnName, columnTypeName } = metadata || {}
  if (columnName) {
    const { properties = {} } = parentSchema
    const foundProperty = properties[columnName]
    if (foundProperty) {
      const typeName = columnTypeName ?? "type"
      return {
        column: get(
          data,
          parentName ? `${parentName}.${columnName}` : columnName,
          null
        ),
        type: get(
          data,
          parentName ? `${parentName}.${typeName}` : typeName,
          null
        ),
      }
    }
  }

  // Third check: schema's parent is an object and contains a property
  // with the 'ColumnSelect' component
  const { properties = {} } = parentSchema
  const foundProperty = findPropertyWithComponent(
    properties,
    COLUMN_COMPONENT_NAME
  )

  // Fourth check: try to find the column from the root schema
  if (!foundProperty) {
    if (!rootSchema) {
      console.error(`Missing property ${COLUMN_COMPONENT_NAME} in schema`)
      return {}
    }
    return getLocalColumn({}, rootSchema, rootData)
  }

  return {
    column: get(
      data,
      parentName ? `${parentName}.${foundProperty[0]}` : foundProperty[0],
      null
    ),
    type: get(data, parentName ? `${parentName}.type` : "type", null),
  }
}

// by default, the schema is used to obtain relevant query parameters from the json data.
// this is done by introspecting the form schema and picking out fields that pertain to
// certain parts of the query. for example, the datasetId is picked out of the form data
// based on which field is set to use the "Dataset" component.
const getQueryFromSchema = (
  schema,
  parentSchema,
  data,
  parentName,
  rootSchema,
  rootData
) => {
  return {
    ...getLocalDatasetId(
      schema,
      parentSchema,
      data,
      parentName,
      rootSchema,
      rootData
    ),
    ...getLocalColumn(
      schema,
      parentSchema,
      data,
      parentName,
      rootSchema,
      rootData
    ),
  }
}

const getQuery = ({
  widget,
  schema = {},
  rootSchema,
  widgetRegistry,
  parentSchemaKey,
  widgetSchemaData,
  parentSchema = {},
}) => {
  if (!widget) return {}
  const { queryResolver } = widgetRegistry?.[widget.type] || {}
  switch (true) {
    case Boolean(queryResolver):
      return queryResolver(widget.options || {}) || {}
    case Boolean(rootSchema):
      return getQueryFromSchema(
        schema,
        parentSchema,
        widgetSchemaData,
        parentSchemaKey,
        rootSchema,
        widget
      )
    default:
      return widget.options?.query || widget.query || {}
  }
}

export default getQuery
