import React from "react"
import { useParams, useHistory } from "react-router-dom"

import Card from "../Card"
import Input from "../Form/Input"
import SoloCard from "../SoloCard"
import ApiForm from "../Form/ApiForm"
import HiddenInput from "../Form/HiddenInput"
import { RESET_PASSWORD } from "../../queries/auth"
import useCurrentUser from "../../hooks/useCurrentUser"

const afterSubmit = (setCurrentUser, history) => {
  return ({ data }) => {
    if (data) {
      setCurrentUser(data.resetPassword)
      history.replace("/")
    }
  }
}

const ResetPassword = props => {
  const { token, email } = useParams()
  const [_, setCurrentUser] = useCurrentUser()
  const history = useHistory()

  return (
    <>
      <SoloCard>
        <Card title="RESET PASSWORD">
          <ApiForm
            mutation={RESET_PASSWORD}
            afterSubmit={afterSubmit(setCurrentUser, history)}
          >
            <Input name="password" displayName="Password" type="password" />
            <Input
              name="passwordConfirm"
              displayName="Confirm Password"
              type="password"
            />
            <HiddenInput name="token" value={token} />
            <HiddenInput name="email" value={email} />
          </ApiForm>
        </Card>
      </SoloCard>
    </>
  )
}

export default ResetPassword
