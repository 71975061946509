import backgroundColor from "./shared/backgroundColor"
import { properties as conditionalFormatProperties } from "./shared/getConditionalFormatSchema"

const properties = {
  conditions: {
    type: "array",
    title: "When",
    default: [],
    metadata: {
      addButtonText: "ADD CONDITION",
      addButtonProps: {
        block: true,
      },
    },
    items: {
      default: {},
      type: "object",
      metadata: {
        order: [
          "sourceType",
          "appVariable",
          "sourceDatasetId",
          "sourceValue",
          "sourceAggregate",
          "percentile",
          "operator",
          "valueType",
          "datasetId",
          "value",
        ],
      },
      dependencies: {
        sourceAggregate: {
          if: {
            properties: {
              sourceAggregate: {
                enum: ["PERCENTILE_CONT"],
              },
            },
          },
          then: {
            properties: {
              sourcePercentile: conditionalFormatProperties.percentile,
            },
          },
        },
        aggregate: {
          if: {
            properties: {
              aggregate: {
                enum: ["PERCENTILE_CONT"],
              },
            },
          },
          then: {
            properties: {
              percentile: conditionalFormatProperties.percentile,
            },
          },
        },
        datasetId: {
          properties: {
            value: {
              type: "string",
              title: "Value",
              metadata: {
                hideLabel: true,
                columnTypeName: "type",
                component: "ColumnSelect",
                datasetIdName: "datasetId",
                fieldProps: {
                  placeholder: "Select Column",
                },
              },
            },
            aggregate: conditionalFormatProperties.aggregate,
          },
        },
        sourceDatasetId: {
          properties: {
            sourceAggregate: conditionalFormatProperties.aggregate,
            sourceValue: {
              type: "string",
              title: "Value",
              metadata: {
                hideLabel: true,
                component: "ColumnSelect",
                datasetIdName: "sourceDatasetId",
                columnTypeName: "sourceValueType",
                fieldProps: {
                  placeholder: "Select Column",
                },
              },
            },
          },
        },
        operator: {
          properties: {
            valueType: {
              type: "string",
              title: "Compare value type",
              enum: ["staticValue", "datasetColumn", "appVariable"],
              metadata: {
                hideLabel: true,
                labels: ["Value", "Other Column", "App Variable"],
              },
            },
          },
        },
        sourceValueType: {
          allOf: [
            {
              if: {
                properties: {
                  sourceValueType: {
                    enum: ["datetime"],
                  },
                },
              },
              then: {
                properties: {
                  operator: conditionalFormatProperties.datetimeColumnOperator,
                },
              },
            },
            {
              if: {
                properties: {
                  sourceValueType: {
                    enum: ["string", "categorical", "any"],
                  },
                },
              },
              then: {
                properties: {
                  operator:
                    conditionalFormatProperties.categoricalColumnOperator,
                },
              },
            },
            {
              if: {
                properties: {
                  sourceValueType: {
                    enum: ["numerical", "float", "int", "number"],
                  },
                },
              },
              then: {
                properties: {
                  operator: conditionalFormatProperties.numericalColumnOperator,
                },
              },
            },
          ],
        },
        valueType: {
          allOf: [
            {
              if: {
                properties: {
                  valueType: {
                    enum: ["datasetColumn"],
                  },
                },
              },
              then: {
                properties: {
                  datasetId: {
                    $ref: "#/$defs/datasetId",
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  valueType: {
                    enum: ["staticValue"],
                  },
                  sourceValueType: {
                    enum: ["datetime"],
                  },
                },
              },
              then: {
                properties: {
                  value: conditionalFormatProperties.datetimeValue,
                },
              },
            },
            {
              if: {
                properties: {
                  valueType: {
                    enum: ["staticValue"],
                  },
                  sourceValueType: {
                    enum: ["string", "categorical", "any"],
                  },
                },
              },
              then: {
                properties: {
                  value: {
                    type: "string",
                    metadata: {
                      component: "ColumnValueInput",
                      hideLabel: true,
                      allowInput: true,
                      columnName: "column",
                      valuePresets: true,
                      excludeWidgetFilters: true,
                    },
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  valueType: {
                    enum: ["staticValue"],
                  },
                  sourceValueType: {
                    enum: ["numerical", "float", "int", "number"],
                  },
                },
              },
              then: {
                properties: {
                  value: {
                    type: "number",
                    metadata: {
                      component: "ColumnValueInput",
                      hideLabel: true,
                      allowInput: true,
                      columnName: "column",
                      valuePresets: true,
                      excludeWidgetFilters: true,
                    },
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  valueType: {
                    enum: ["appVariable"],
                  },
                },
              },
              then: {
                properties: {
                  value: {
                    type: "string",
                    title: "App Variable",
                    metadata: {
                      hideLabel: true,
                      component: "AppVariableSelect",
                    },
                  },
                },
              },
            },
          ],
        },
        sourceType: {
          allOf: [
            {
              if: {
                properties: {
                  sourceType: {
                    enum: ["appVariable"],
                  },
                },
              },
              then: {
                properties: {
                  sourceValue: {
                    type: "string",
                    title: "App Variable",
                    metadata: {
                      hideLabel: true,
                      component: "AppVariableSelect",
                      variableTypeName: "sourceValueType",
                      metadata: {
                        placeholder: "App Variable",
                      },
                    },
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  sourceType: {
                    enum: ["datasetColumn"],
                  },
                },
              },
              then: {
                properties: {
                  sourceDatasetId: { $ref: "#/$defs/datasetId" },
                },
              },
            },
          ],
        },
      },
      properties: {
        conjunction: {
          type: "string",
          enum: ["and", "or"],
          default: "and",
          metadata: {
            labels: ["AND", "OR"],
            hideLabel: true,
            hidden: {
              itemIndex: 0,
            },
          },
        },
        sourceType: {
          type: "string",
          enum: ["datasetColumn", "appVariable"],
          metadata: {
            hideLabel: true,
            labels: ["Dataset Column", "App Variable"],
            fieldProps: {
              placeholder: "Source type",
            },
          },
        },
        sourceValueType: {
          type: "string",
          metadata: {
            hidden: true,
          },
        },
        type: {
          type: "string",
          metadata: {
            hidden: true,
          },
        },
      },
    },
  },
}
const textSchema = {
  title: "Text",
  type: "object",
  default: {},
  metadata: {
    fieldProps: {
      style: {
        padding: "0 16px",
      },
    },
  },
  properties: {
    text: {
      title: "Text",
      type: "string",
      metadata: {
        hidden: true,
      },
    },
    backgroundColor: backgroundColor,
    vPosition: {
      type: "string",
      default: "flex-start",
      title: "Vertical Position",
      enum: ["flex-start", "center", "flex-end"],
      metadata: {
        component: "Segmented",
        labels: ["Top", "Middle", "Bottom"],
      },
    },
    nodes: {
      type: "array",
      default: [],
      metadata: {
        itemType: "collapse",
        draggable: false,
        removable: false,
        component: "TextNodes",
      },
      items: {
        type: "object",
        default: {},
        metadata: {
          order: ["nodeType", "text", "rules", "conditionalFormat"],
        },
        dependencies: {
          nodeType: {
            allOf: [
              {
                if: {
                  properties: {
                    nodeType: {
                      enum: ["appVariable"],
                    },
                  },
                },
                then: {
                  properties: {
                    text: {
                      type: "string",
                      metadata: {
                        hideLabel: true,
                        fieldProps: {
                          disabled: true,
                        },
                      },
                    },
                  },
                },
              },
              {
                if: {
                  properties: {
                    nodeType: {
                      enum: ["conditionalText"],
                    },
                  },
                },
                then: {
                  properties: {
                    text: {
                      type: "string",
                      title: "Default Text",
                      default: "<div>Configure in Editor</div>",
                      metadata: {
                        component: "RichText",
                        height: 200,
                      },
                    },
                    rules: {
                      type: "array",
                      title: "Rules",
                      metadata: {
                        itemType: "collapse",
                        draggable: false,
                        removable: false,
                        addButtonText: "ADD RULE",
                        addButtonProps: {
                          block: true,
                        },
                      },
                      items: {
                        type: "object",
                        title: "Rule",
                        properties: {
                          title: {
                            title: "Rule Config",
                            default: "Rule Config",
                          },
                          conditions: properties.conditions,
                          text: {
                            type: "string",
                            title: "Text",
                            default: "<div>Configure in Editor</div>",
                            descriptions:
                              "This text is shown when the conditions are evaluated to true",
                            metadata: {
                              component: "RichText",
                              height: 126,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
        },
        properties: {
          nodeType: {
            type: "string",
            enum: ["appVariable", "conditionalText"],
            metadata: {
              labels: ["App Variable", "Conditional Text"],
              fieldProps: {
                disabled: true,
              },
            },
          },
          nodeId: {
            title: "Node",
            type: "string",
            metadata: {
              hidden: true,
            },
          },
          conditionalFormat: {
            type: "array",
            title: "Conditional Formatting",
            metadata: {
              itemType: "collapse",
              draggable: false,
              removable: false,
              addButtonText: "ADD SCENARIO",
              addButtonProps: {
                block: true,
              },
            },
            items: {
              type: "object",
              title: "Scenario",
              properties: {
                title: {
                  type: "string",
                  default: "Condition Scenario",
                },
                conditions: properties.conditions,
                effects: {
                  type: "array",
                  title: "Then, apply",
                  default: [],
                  metadata: {
                    draggable: false,
                    addButtonText: "ADD EFFECT",
                    addButtonProps: {
                      block: true,
                    },
                  },
                  items: {
                    type: "object",
                    default: {},
                    metadata: {
                      layout: { gutter: [8] },
                      sideEffects: [
                        {
                          resetValue: null,
                          type: "resetField",
                          targetName: "value",
                          sourceName: "attribute",
                        },
                      ],
                    },
                    dependencies: {
                      attribute: {
                        allOf: [
                          {
                            if: {
                              properties: {
                                attribute: {
                                  enum: ["--conditional-display"],
                                },
                              },
                            },
                            then: {
                              properties: {
                                value: {
                                  type: "string",
                                  title: "Value",
                                  default: "none",
                                  metadata: {
                                    hidden: true,
                                  },
                                },
                              },
                            },
                          },
                          {
                            if: {
                              properties: {
                                attribute: {
                                  enum: [
                                    "--conditional-color",
                                    "--conditional-background-color",
                                  ],
                                },
                              },
                            },
                            then: {
                              properties: {
                                value: {
                                  type: "string",
                                  title: "Value",
                                  metadata: {
                                    layout: { flex: 1 },
                                    hideLabel: true,
                                    component: "ColorPicker",
                                  },
                                },
                              },
                            },
                          },
                        ],
                      },
                    },
                    properties: {
                      attribute: {
                        type: "string",
                        title: "Attribute",
                        enum: [
                          "--conditional-display",
                          "--conditional-color",
                          "--conditional-background-color",
                        ],
                        metadata: {
                          hideLabel: true,
                          labels: [
                            "Hide Text",
                            "Text Color",
                            "Background Color",
                          ],
                          fieldProps: {
                            popupMatchSelectWidth: false,
                            placeholder: "Select Attribute",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}

export default textSchema
