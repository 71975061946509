import React from "react"
import { useSelector, useDispatch } from "react-redux"

import HeaderButton from "./HeaderButton"
import { actions } from "../reducers/appReducer"
import { selectShowAppVariables } from "../selectors/app"
import { faSquareRootVariable } from "@fortawesome/pro-solid-svg-icons"

const AppVariablesButton = () => {
  const dispatch = useDispatch()
  const showAppVariables = useSelector(selectShowAppVariables)

  const toggleShowAppVariables = () => {
    dispatch(actions.toggleShowAppVariables())
  }

  return (
    <HeaderButton
      title="Variables"
      icon={faSquareRootVariable}
      selected={showAppVariables}
      onClick={toggleShowAppVariables}
      data-testid="app-header-variables-button"
    />
  )
}

export default AppVariablesButton
