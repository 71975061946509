import React, { useState, useCallback, useEffect } from "react"
import dayjs from "dayjs"
import Highcharts from "highcharts"
import styled from "styled-components"
import { DatePicker, Button } from "antd"
import { mergeWith, sortBy } from "lodash"
import { useSelector, useDispatch } from "react-redux"
import HighchartsReact from "highcharts-react-official"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRecycle } from "@fortawesome/free-solid-svg-icons"

import {
  Spinner,
  SidePane,
  ErrorMessage,
  useCurrentCustomer,
} from "@dbai/ui-staples"

import chartTheme from "lib/chartTheme"
import { safeAssign } from "lib/lodash-helpers"
import { selectWorkflow, selectWorkflowResourceUsage } from "selectors"
import { actions as workflowResourceUsageActions } from "reducers/workflowResourceUsageReducer"

const { RangePicker } = DatePicker

const Icon = styled(FontAwesomeIcon)`
  margin-right: 0.33rem;
`

const RangePickerWithMargin = styled(RangePicker)`
  margin-right: 15px;
`
const convertUtcToLocal = timestamp => {
  const timestampDate = new Date(timestamp)

  const localTimeString = timestampDate.toLocaleString()

  return dayjs(localTimeString).format("HH:mm:ss")
}

const defaultRanges = [
  { label: "Today", value: [dayjs().subtract(1, "d"), dayjs()] },
  {
    label: "This Week",
    value: [dayjs().startOf("week"), dayjs().endOf("week")],
  },
]

const defaultOptions = mergeWith(
  {},
  chartTheme,
  {
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    xAxis: {
      title: {
        text: "Time",
      },
      type: "datetime",
      labels: {
        formatter: function () {
          return convertUtcToLocal(this.value)
        },
      },
    },
  },
  safeAssign
)

const cpuOptions = cpuData =>
  mergeWith(
    {},
    defaultOptions,
    {
      title: {
        text: "CPU Usage",
      },
      yAxis: {
        title: {
          text: "Percent CPU Used",
        },
      },
      series: [
        {
          type: "area",
          name: "Percent CPU",
          data: sortBy(cpuData, ["timestamp"]).map(cpu => [
            cpu.timestamp * 1000,
            parseFloat(cpu.value) * 100,
          ]),
        },
      ],
    },
    safeAssign
  )

// https://stackoverflow.com/a/18650828/836756
function formatMegaBytes(bytes, decimals = 2) {
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals

  return parseFloat((bytes / Math.pow(k, 2)).toFixed(dm))
}

const memoryOptions = memoryData =>
  mergeWith(
    {},
    defaultOptions,
    {
      title: {
        text: "Memory Usage",
      },
      yAxis: {
        title: {
          text: `Memory Used (MB)`,
        },
      },
      series: [
        {
          type: "area",
          name: "Memory Used (MB)",
          data: sortBy(memoryData, ["timestamp"]).map(memory => [
            memory.timestamp * 1000,
            formatMegaBytes(parseFloat(memory.value)),
          ]),
          color: "#FF0000",
        },
      ],
    },
    safeAssign
  )

const getEndTime = adayjs => {
  const now = dayjs()
  if (adayjs?.isAfter(now)) return now
  return adayjs
}

const ResourceUsagePane = () => {
  const dispatch = useDispatch()
  const [customer] = useCurrentCustomer()
  const workflow = useSelector(selectWorkflow)
  const { data, loading, error } = useSelector(selectWorkflowResourceUsage)

  const [endValue, setEndValue] = useState(dayjs())
  const [startValue, setStartValue] = useState(dayjs().subtract(1, "d"))

  const refreshMetrics = useCallback(() => {
    dispatch(
      workflowResourceUsageActions.loadWorkflowResourceUsage({
        id: workflow.id,
        customerId: customer.id,
        startTime: startValue?.format(),
        endTime: endValue?.format(),
      })
    )
  }, [dispatch, workflow.id, customer.id, endValue, startValue])

  useEffect(() => {
    refreshMetrics()
    return () => dispatch(workflowResourceUsageActions.reset)
  }, [dispatch, refreshMetrics])

  const onRangeChange = dt => {
    setStartValue(dt?.[0])
    setEndValue(getEndTime(dt?.[1]))

    refreshMetrics()
  }

  return (
    <>
      <RangePickerWithMargin
        allowEmpty={[true, true]}
        showTime={{ use12Hours: true }}
        format="MM/DD/YY h:mm a"
        onChange={onRangeChange}
        value={[startValue, endValue]}
        presets={defaultRanges}
      />
      <Button
        type="primary"
        onClick={refreshMetrics}
        icon={<Icon icon={faRecycle} />}
      >
        REFRESH
      </Button>
      {loading && <Spinner />}
      {error && <ErrorMessage error={error} />}
      {data.cpu && (
        <>
          <HighchartsReact
            highcharts={Highcharts}
            options={cpuOptions(data.cpu)}
          />
          <HighchartsReact
            highcharts={Highcharts}
            options={memoryOptions(data.memory)}
          />
        </>
      )}
    </>
  )
}

const ResourceUsageButton = props => {
  const { panelOpen, closePanel } = props

  return (
    <>
      <SidePane
        title="Resource Usage"
        isVisible={panelOpen}
        onCloseClicked={closePanel}
      >
        <ResourceUsagePane {...props} />
      </SidePane>
    </>
  )
}

export default ResourceUsageButton
