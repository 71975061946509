import React, { useEffect, useState } from "react"
import { Button } from "antd"
import { useDispatch } from "react-redux"

import ComponentsTable from "./ComponentsTable"
import { actions } from "reducers/componentReducer"
import IndexHeader from "components/pages/Workflows/shared/IndexHeader"
import WorkflowsContent from "components/pages/Workflows/shared/WorkflowsContent"
import withCustomer from "components/shared/withCustomer"

const Components = props => {
  const dispatch = useDispatch()
  const { customer } = props
  const [showPanel, setShowPanel] = useState(false)
  const openPanel = () => setShowPanel(true)
  const closePanel = () => setShowPanel(false)

  // Clear the form on navigation to this page.
  useEffect(() => {
    dispatch(actions.reset())
  }, [dispatch])

  return (
    <>
      <IndexHeader>
        <Button onClick={openPanel} type="primary">
          + NEW COMPONENT
        </Button>
      </IndexHeader>
      <WorkflowsContent>
        <ComponentsTable
          customer={customer}
          showNew={showPanel}
          onNewClose={closePanel}
        />
      </WorkflowsContent>
    </>
  )
}

export default withCustomer(Components)
