import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"

import { alterColor } from "@dbai/tool-box"

import useSort from "./useSort"
import Pagination from "../Pagination"
import { Column, Columns } from "./Column"
import DateFormatter from "./formatters/DateFormatter"
import BytesFormatter from "./formatters/BytesFormatter"
import NumberFormatter from "./formatters/NumberFormatter"
import ActionsFormatter from "./formatters/ActionsFormatter"

const clickable = css`
  cursor: pointer;
`

const alternateRows = css`
  &:nth-child(odd) {
    background: ${props => alterColor(props.theme.background, -30)};
  }

  &:nth-child(even) {
    background: ${props => props.theme.background};
  }
`

const Container = styled.div`
  height: 100%;
  width: 100%;

  ${props => props.isDataTable && "overflow: hidden;"}
  display: flex;
  flex-flow: row wrap;
`

const StyledTable = styled.table`
  text-align: left;
  position: relative;
  border-collapse: collapse;
  && {
    margin-bottom: 0;
  }
`

const TableContainer = styled.div`
  ${props => props.isDataTable && "overflow: auto;"}
  display: flex;
  flex: 1 0 auto;
  height: ${props => (props.withPagination ? "calc(100% - 40px)" : "100%")};
  width: 100%;
`

const StyledTableRow = styled.tr`
  max-height: 40px;
  width: 100%;
  ${props => props.isDataTable && alternateRows}
  ${props => props.clickable && clickable}
`

const Table = props => {
  const {
    rows,
    onRowClick,
    limit,
    onSortClick,
    pagination,
    noHeader,
    isDataTable,
    className,
  } = props
  const [sort, setSort] = useSort()

  const defaultOnSortClick = ({ field }) => {
    setSort(field)
  }

  // Concat makes a shallow copy so as not to mutate `rows`.
  const sorted = sort ? rows.concat().sort(sort) : rows

  const showPagination = () => {
    if (!pagination) return false
    if (pagination.pageCount === 1) return !!pagination.hideOnSinglePage
    return true
  }

  return (
    <Container className={className} isDataTable={isDataTable}>
      <TableContainer
        withPagination={showPagination()}
        isDataTable={isDataTable}
      >
        <StyledTable className="dbai-table table">
          {!noHeader && (
            <thead className="thead-light">
              <Headers onSortClick={onSortClick || defaultOnSortClick}>
                {props.children}
              </Headers>
            </thead>
          )}
          <tbody>
            <Rows
              isDataTable={isDataTable}
              onRowClick={onRowClick}
              rows={sorted}
              limit={limit}
            >
              {props.children}
            </Rows>
          </tbody>
        </StyledTable>
      </TableContainer>
      {showPagination() && <TablePagination pagination={pagination} />}
    </Container>
  )
}

const TablePagination = props => {
  const { pagination } = props
  const { pageCount, onUpdatePage, forcePage, pageRange } = pagination

  return (
    <Pagination
      pageCount={pageCount}
      pageRangeDisplayed={pageRange < 0 ? 1 : pageRange}
      forcePage={forcePage}
      onPageChange={onUpdatePage}
      disableInitialCallback={true}
    />
  )
}

const Headers = props => {
  const { onSortClick } = props
  const fn = child => {
    return React.cloneElement(child, { isHead: true, onSortClick })
  }

  return (
    <StyledTableRow>{React.Children.map(props.children, fn)}</StyledTableRow>
  )
}

const Rows = props => {
  const { rows, children, onRowClick, limit, isDataTable } = props

  const clickable = !!onRowClick

  return rows.slice(0, limit).map((row, idx) => {
    const onClick = () => clickable && onRowClick(row)
    return (
      <StyledTableRow
        isDataTable={isDataTable}
        onClick={onClick}
        clickable={clickable}
        key={idx}
      >
        {React.Children.map(children, child => {
          return React.cloneElement(child, { idx, row, isDataTable })
        })}
      </StyledTableRow>
    )
  })
}

Table.propTypes = {
  rows: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  onRowClick: PropTypes.func,
  onSortClick: PropTypes.func,
  limit: PropTypes.number,
  noHeader: PropTypes.bool,
  isDataTable: PropTypes.bool,
}

Table.defaultProps = {
  isDataTable: false,
  noHeader: false,
}

Table.Column = Column
Table.Columns = Columns
Table.DateFormatter = DateFormatter
Table.BytesFormatter = BytesFormatter
Table.NumberFormatter = NumberFormatter
Table.ActionsFormatter = ActionsFormatter

export default Table
