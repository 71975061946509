import React from "react"
import { Switch } from "antd"

import FormFieldWrapper from "../../FormFieldWrapper"

const SwitchInputCore = props => {
  const { value, ...rest } = props
  return <Switch checked={Boolean(value)} {...rest} />
}

const SwitchInput = props => {
  return (
    <FormFieldWrapper {...props}>
      <SwitchInputCore />
    </FormFieldWrapper>
  )
}

SwitchInput.Core = SwitchInputCore
export default SwitchInput
