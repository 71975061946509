import React from "react"
import { Checkbox } from "antd"
import { useMutation } from "@apollo/client"

import { useCurrentCustomer } from "@dbai/ui-staples"

import {
  GET_COMPONENTS,
  PUBLISH_COMPONENT,
  DELETE_COMPONENT_PUBLICATION,
} from "queries"

const refetchQueries = [GET_COMPONENTS]

const PublishCheckbox = props => {
  const [{ id: customerId }] = useCurrentCustomer()
  const {
    row: { published, id: componentId },
  } = props
  const variables = {
    componentId,
    customerId,
  }
  const [publish] = useMutation(PUBLISH_COMPONENT, {
    variables,
    refetchQueries,
  })

  const [deletePublication] = useMutation(DELETE_COMPONENT_PUBLICATION, {
    variables,
    refetchQueries,
  })

  const handleChange = () => {
    if (published) {
      return deletePublication()
    }
    return publish()
  }

  return <Checkbox checked={published} onChange={handleChange} />
}

export default PublishCheckbox
