const highchartsProperties = {
  tooltip: {
    "tooltip/useHTML": {
      default: true,
      type: "boolean",
      metadata: {
        hidden: true,
      },
    },
    "tooltip/enabled": {
      description: "Whether to enable the tooltip or not.",
      title: "Enabled",
      type: "boolean",
      default: true,
    },
    "tooltip/animation": {
      default: true,
      type: "boolean",
      title: "Animation",
      description:
        "Whether to apply animation to the tooltip when moving from one point to another.",
    },
    "tooltip/shadow": {
      default: true,
      type: "boolean",
      title: "Shadow",
      description: "Whether to apply a drop shadow to the tooltip/",
    },
    "tooltip/split": {
      default: false,
      title: "Show Multiple Tooltips",
      type: "boolean",
      description:
        "Show a tooltip for each series when you hover over an x-axis section",
    },
    "tooltip/borderRadius": {
      description: "The radius of the rounded corners of the tooltip/",
      title: "Border Radius",
      type: "number",
      default: 3,
      minimum: 0,
      maximum: 24,
      metadata: {
        component: "SliderInput",
        showNumberInput: true,
      },
    },
    "tooltip/borderWidth": {
      default: 1,
      minimum: 0,
      maximum: 24,
      type: "number",
      title: "Border Width",
      description: "The pixel width of the tooltip border.",
      metadata: {
        component: "SliderInput",
        showNumberInput: true,
      },
    },
    "tooltip/borderColor": {
      nullable: true,
      title: "Border Color",
      type: ["string", "null"],
      description: "The border color of the tooltip/",
      metadata: {
        component: "ColorPicker",
      },
    },
    "tooltip/backgroundColor": {
      nullable: true,
      type: ["string", "null"],
      title: "Background Color",
      description: "The background color of the tooltip/",
      metadata: {
        component: "ColorPicker",
      },
    },
    "tooltip/valueDecimals": {
      minimum: 0,
      nullable: true,
      title: "Value Precision",
      type: ["number", "null"],
      description: "The number of decimals to show in the tooltip/",
      metadata: {
        fieldProps: {
          placeholder: "Add precision",
        },
      },
    },
    "tooltip/valuePrefix": {
      nullable: true,
      title: "Prefix",
      type: ["string", "null"],
      description: "A string to prepend to the tooltip/",
      metadata: {
        fieldProps: {
          placeholder: "Add prefix",
        },
      },
    },
    "tooltip/valueSuffix": {
      nullable: true,
      title: "Suffix",
      type: ["string", "null"],
      description: "A string to append to the tooltip/",
      metadata: {
        fieldProps: {
          placeholder: "Add suffix",
        },
      },
    },
  },
  legend: {
    "legend/enabled": {
      default: true,
      type: "boolean",
      title: "Enabled",
      description: "Whether to enable the legend or not.",
    },
    "legend/align": {
      type: "string",
      default: "center",
      title: "Horizontal Alignment",
      enum: ["left", "center", "right"],
      description:
        "The horizontal alignment of the legend box within the chart area.",
      metadata: {
        component: "Segmented",
        labels: ["Left", "Center", "Right"],
      },
    },
    "legend/verticalAlign": {
      type: "string",
      default: "bottom",
      enum: ["top", "middle", "bottom"],
      title: "Vertical Alignment",
      description:
        "The vertical alignment of the legend box within the chart area.",
      metadata: {
        component: "Segmented",
        labels: ["Top", "Middle", "Bottom"],
      },
    },
    "legend/layout": {
      type: "string",
      title: "Layout",
      default: "horizontal",
      enum: ["horizontal", "vertical"],
      description: "The layout of the legend items.",
      metadata: {
        component: "Segmented",
        labels: ["Horizontal", "Vertical"],
      },
    },
    "legend/borderWidth": {
      default: 0,
      minimum: 0,
      maximum: 64,
      type: "number",
      title: "Border Width",
      description: "The width of the border around the legend/",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "legend/borderRadius": {
      default: 0,
      type: "number",
      title: "Border Radius",
      description: "The radius of the rounded corners of the legend border.",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "legend/borderColor": {
      nullable: true,
      type: ["string", "null"],
      title: "Border Color",
      description: "The color of the border around the legend/",
      metadata: {
        component: "ColorPicker",
      },
    },
    "legend/backgroundColor": {
      nullable: true,
      type: ["string", "null"],
      title: "Background Color",
      description: "The background color of the legend/",
      metadata: {
        component: "ColorPicker",
      },
    },
    "legend/itemStyle/color": {
      nullable: true,
      type: ["string", "null"],
      title: "Text Color",
      description: "The color of the legend item text.",
      metadata: {
        component: "ColorPicker",
      },
    },
    "legend/itemStyle/fontWeight": {
      type: "string",
      default: "bold",
      title: "Font Weight",
      description: "The font weight of the legend item text.",
      enum: [
        "normal",
        "bold",
        "bolder",
        "lighter",
        "99",
        "199",
        "299",
        "399",
        "499",
        "599",
        "699",
        "799",
        "899",
      ],
      metadata: {
        labels: [
          "Normal",
          "Bold",
          "Bolder",
          "Lighter",
          "99",
          "199",
          "299",
          "399",
          "499",
          "599",
          "699",
          "799",
          "899",
        ],
      },
    },
    "legend/itemStyle/fontFamily": {
      type: "string",
      title: "Font",
      description: "The font family of the legend item text.",
      default:
        '"Lucida Grande", "Lucida Sans Unicode", Verdana, Arial, Helvetica, sans-serif',
    },
  },
  title: {
    "title/showTitle": {
      default: true,
      type: "boolean",
      title: "Show Title",
    },
    "title/floating": {
      title: "Floating Title",
      default: false,
      type: "boolean",
    },
    "title/align": {
      title: "Align Title",
      default: "center",
      enum: ["left", "center", "right"],
      type: "string",
      metadata: {
        component: "Segmented",
        labels: ["Left", "Center", "Right"],
      },
    },
    "title/verticalAlign": {
      type: "string",
      title: "Vertical Alignment",
      enum: ["", "top", "middle", "bottom"],
      metadata: {
        component: "Segmented",
        labels: ["Default", "Top", "Middle", "Bottom"],
      },
    },
    "title/margin": {
      default: 15,
      title: "Margin",
      type: "number",
      description:
        "The margin between the title and the plot area, or if a subtitle is present, the margin between the subtitle and the plot area. If you are using a floating title, this option will be ignored.",
    },
  },
  chart: {
    "chart/backgroundColor": {
      nullable: true,
      type: ["string", "null"],
      title: "Chart Background Color",
      description: "The background color of the chart area.",
      metadata: {
        component: "ColorPicker",
      },
    },
    "chart/plotBackgroundColor": {
      nullable: true,
      type: ["string", "null"],
      title: "Plot Background Color",
      description: "The background color of the plot area.",
      metadata: {
        component: "ColorPicker",
      },
    },
    "chart/borderWidth": {
      default: 0,
      minimum: 0,
      maximum: 24,
      type: "number",
      title: "Chart Border Width",
      description: "The width of the border around the chart area.",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "chart/borderColor": {
      nullable: true,
      type: ["string", "null"],
      title: "Chart Border Color",
      description: "The color of the border around the chart area.",
      metadata: {
        component: "ColorPicker",
      },
    },
    "chart/plotBorderWidth": {
      default: 0,
      minimum: 0,
      maximum: 24,
      type: "number",
      title: "Plot Border Width",
      description: "The width of the border around the plot area.",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "chart/plotBorderColor": {
      nullable: true,
      type: ["string", "null"],
      title: "Plot Border Color",
      description: "The color of the border around the plot area.",
      metadata: {
        component: "ColorPicker",
      },
    },
    "chart/marginTop": {
      minimum: 0,
      maximum: 150,
      nullable: true,
      title: "Top Margin",
      type: ["number", "null"],
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "chart/marginRight": {
      minimum: 0,
      maximum: 150,
      nullable: true,
      title: "Right Margin",
      type: ["number", "null"],
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "chart/marginBottom": {
      minimum: 0,
      maximum: 150,
      nullable: true,
      type: ["number", "null"],
      title: "Bottom Margin",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "chart/marginLeft": {
      minimum: 0,
      maximum: 150,
      nullable: true,
      title: "Left Margin",
      type: ["number", "null"],
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
  },
  xAxis: {
    "xAxis/labels/useHTML": {
      default: true,
      type: "boolean",
      metadata: {
        hidden: true,
      },
    },
    "xAxis/labels/enabled": {
      default: true,
      type: "boolean",
      title: "Enabled",
    },
    "xAxis/labels/format": {
      nullable: true,
      title: "Format",
      default: "{value}",
      type: ["string", "null"],
      description:
        "A format string for the x-axis labels. This can be any of the formats supported by the JavaScript Date object, or a custom format (e.g. '{value} kg').",
      metadata: {
        fieldProps: {
          placeholder: "e.g. {value} kg",
        },
      },
    },
    "xAxis/labels/align": {
      type: "string",
      title: "Alignment",
      enum: ["left", "center", "right"],
      description:
        "The horizontal alignment of the x-axis labels. Can be one of 'left', 'center', or 'right'.",
      metadata: {
        component: "Segmented",
        labels: ["Left", "Center", "Right"],
      },
    },
    "xAxis/labels/rotation": {
      maximum: 0,
      minimum: -90,
      nullable: true,
      type: ["number", "null"],
      title: "Rotation",
      description:
        "The angle of rotation for the x-axis labels, in degrees. A positive value rotates the labels clockwise, while a negative value rotates them counter-clockwise.",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "xAxis/labels/padding": {
      default: 5,
      type: "number",
      title: "Padding",
    },
    "xAxis/labels/style/fontSize": {
      type: "string",
      default: "10px",
      title: "Font Size",
      description:
        "The font size of the X-Axis labels text, in CSS units (e.g. '15px').",
    },
    "xAxis/labels/style/color": {
      nullable: true,
      type: ["string", "null"],
      title: "Label Text Color",
      description:
        "The color of the x-axis labels text, in CSS format (e.g. '#FF037777777777' for red).",
      metadata: {
        component: "ColorPicker",
      },
    },
    "xAxis/title/text": {
      type: "string",
      title: "Title",
      description: "The title of the x-axis.",
      metadata: {
        fieldProps: {
          placeholder: "Auto",
        },
      },
    },
    "xAxis/title/align": {
      type: "string",
      default: "middle",
      title: "Alignment",
      enum: ["low", "middle", "high"],
      description:
        "The horizontal alignment of the x-axis title. Can be one of 'left', 'center', or 'right'.",
      metadata: {
        component: "Segmented",
        labels: ["Left", "Center", "Right"],
      },
    },
    "xAxis/title/margin": {
      minimum: 0,
      maximum: 64,
      nullable: true,
      title: "Margin",
      type: ["number", "null"],
      description:
        "The margin between the x-axis title and the x-axis labels, in pixels.",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "xAxis/title/style/color": {
      nullable: true,
      type: ["string", "null"],
      title: "Title Text Color",
      description:
        "The color of the x-axis title text, in CSS format (e.g. '#FF037777777777' for red).",
      metadata: {
        component: "ColorPicker",
      },
    },
    "xAxis/title/style/fontSize": {
      nullable: true,
      title: "Title Font Size",
      type: ["string", "null"],
      description:
        "The font size of the x-axis title text, in CSS units (e.g. '15px').",
    },
    "xAxis/crosshair/color": {
      type: "string",
      title: "Crosshair Color",
      default: "#d7821f",
      metadata: {
        component: "ColorPicker",
      },
    },
    "xAxis/crosshair/width": {
      default: 2,
      minimum: 0,
      maximum: 64,
      title: "Crosshair Width",
      type: "number",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "xAxis/crosshair/dashStyle": {
      type: "string",
      title: "Cross Style",
      default: "Solid",
      enum: [
        "Solid",
        "ShortDash",
        "ShortDot",
        "ShortDashDot",
        "ShortDashDotDot",
        "Dot",
        "Dash",
        "LongDash",
        "DashDot",
        "LongDashDot",
        "LongDashDotDot",
      ],
      metadata: {
        labels: [
          "Solid",
          "Short Dash",
          "Short Dot",
          "Short Dash Dot",
          "Short Dash Dot Dot",
          "Dot",
          "Dash",
          "Long Dash",
          "Dash Dot",
          "Long Dash Dot",
          "Long Dash Dot Dot",
        ],
      },
    },
    "xAxis/crosshair/zIndex": {
      default: 2,
      minimum: 0,
      maximum: 100,
      type: "number",
      title: "Crosshair Z-Index",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "xAxis/crosshair/snap": {
      default: true,
      type: "boolean",
      title: "Snap to Data",
    },
    "xAxis/plotLines": {
      type: "array",
      default: [],
      title: "Plot Lines",
      metadata: {
        addButtonProps: { block: true },
      },
      items: {
        type: "object",
        default: {},
        properties: {
          color: {
            type: "string",
            deafult: "#999998",
            title: "Line Color",
            metadata: {
              component: "ColorPicker",
            },
          },
          value: {
            type: "number",
            title: "Value",
          },
          width: {
            minimum: 0,
            default: 2,
            maximum: 64,
            title: "Width",
            type: "number",
            metadata: {
              component: "SliderInput",
              showNumberInput: true,
            },
          },
          zIndex: {
            minimum: 0,
            maximum: 64,
            type: "number",
            title: "Z-Index",
            metadata: {
              component: "SliderInput",
              showNumberInput: true,
            },
          },
        },
      },
    },
    "xAxis/opposite": {
      type: "boolean",
      title: "Opposite side of Chart",
    },
    "xAxis/reversed": {
      type: "boolean",
      title: "Reverse Direction",
    },
    "xAxis/min": {
      title: "Min",
      nullable: true,
      type: ["number", "null"],
      description:
        "The minimum value of the x-axis. Only applicable when the x-axis type is 'linear' or 'logarithmic'.",
    },
    "xAxis/max": {
      title: "Max",
      nullable: true,
      type: ["number", "null"],
      description:
        "The maximum value of the x-axis. Only applicable when the x-axis type is 'linear' or 'logarithmic'.",
    },
    // "xAxis/categories": {
    //   default: [],
    //   type: "array",
    //   title: "Categories",
    //   items: { type: "string" },
    //   metadata: { hideLabel: true },
    // },
  },
  yAxis: {
    "yAxis/labels/enabled": {
      default: true,
      type: "boolean",
      title: "Enabled",
    },
    "yAxis/labels/format": {
      nullable: true,
      title: "Format",
      type: ["string", "null"],
      description:
        "A format string for the Y-Axis labels. This can be any of the formats supported by the JavaScript Date object, or a custom format (e.g. '{value} kg').",
      metadata: {
        fieldProps: {
          placeholder: "e.g. {value} kg",
        },
      },
    },
    "yAxis/labels/align": {
      type: "string",
      title: "Horizontal Alignment",
      enum: ["left", "center", "right"],
      description:
        "The horizontal alignment of the Y-Axis labels. Can be one of 'left', 'center', or 'right'.",
      metadata: {
        component: "Segmented",
        labels: ["Left", "Center", "Right"],
      },
    },
    "yAxis/labels/rotation": {
      default: 0,
      maximum: 360,
      minimum: -360,
      type: "number",
      title: "Rotation",
      description:
        "The angle of rotation for the Y-Axis labels, in degrees. A positive value rotates the labels clockwise, while a negative value rotates them counter-clockwise.",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "yAxis/labels/style/fontSize": {
      type: "string",
      default: "10px",
      title: "Font Size",
      description:
        "The font size of the Y-Axis labels text, in CSS units (e.g. '15px').",
      metadata: {
        layout: { span: 12 },
      },
    },
    "yAxis/labels/style/color": {
      nullable: true,
      type: ["string", "null"],
      title: "Label Text Color",
      description:
        "The color of the Y-Axis labels text, in CSS format (e.g. '#FF037777777777' for red).",
      metadata: {
        component: "ColorPicker",
      },
    },
    "yAxis/title/text": {
      type: "string",
      title: "Title",
      description: "The title of the Y-Axis.",
      metadata: {
        fieldProps: {
          placeholder: "Auto",
        },
      },
    },
    "yAxis/title/align": {
      type: "string",
      default: "middle",
      title: "Title Vertical Alignment",
      enum: ["low", "middle", "high"],
      description:
        "The vertical alignment of the Y-Axis title. Can be one of 'Bottom', 'Middle', or 'Top'.",
      metadata: {
        component: "Segmented",
        labels: ["Bottom", "Middle", "Top"],
      },
    },
    "yAxis/title/margin": {
      minimum: 0,
      maximum: 64,
      nullable: true,
      title: "Margin",
      type: ["number", "null"],
      description:
        "The margin between the Y-Axis title and the x-axis labels, in pixels.",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "yAxis/title/style/color": {
      nullable: true,
      type: ["string", "null"],
      title: "Title Text Color",
      description:
        "The color of the Y-Axis title text, in CSS format (e.g. '#FF037777777777' for red).",
      metadata: {
        component: "ColorPicker",
      },
    },
    "yAxis/title/style/fontSize": {
      nullable: true,
      title: "Title Font Size",
      type: ["string", "null"],
      description:
        "The font size of the Y-Axis title text, in CSS units (e.g. '15px').",
    },
    "yAxis/crosshair/color": {
      type: "string",
      title: "Crosshair Color",
      default: "#d7821f",
      metadata: {
        component: "ColorPicker",
      },
    },
    "yAxis/crosshair/width": {
      minimum: 0,
      default: 0,
      maximum: 64,
      title: "Crosshair Width",
      type: "number",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "yAxis/crosshair/dashStyle": {
      type: "string",
      title: "Crosshair Style",
      default: "Solid",
      enum: [
        "Solid",
        "ShortDash",
        "ShortDot",
        "ShortDashDot",
        "ShortDashDotDot",
        "Dot",
        "Dash",
        "LongDash",
        "DashDot",
        "LongDashDot",
        "LongDashDotDot",
      ],
      metadata: {
        labels: [
          "Solid",
          "Short Dash",
          "Short Dot",
          "Short Dash Dot",
          "Short Dash Dot Dot",
          "Dot",
          "Dash",
          "Long Dash",
          "Dash Dot",
          "Long Dash Dot",
          "Long Dash Dot Dot",
        ],
      },
    },
    "yAxis/crosshair/zIndex": {
      minimum: 0,
      maximum: 100,
      type: "number",
      title: "Crosshair Z-Index",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "yAxis/crosshair/snap": {
      default: true,
      type: "boolean",
      title: "Snap to Data",
    },
    "yAxis/plotLines": {
      type: "array",
      default: [],
      title: "Plot Lines",
      description: "Add a Line at a Constant Value",
      metadata: {
        hideLabel: true,
        addButtonProps: { block: true },
        addButtonText: "Add Constant Line",
      },
      items: {
        type: "object",
        default: {},
        properties: {
          color: {
            type: "string",
            deafult: "#999998",
            title: "Line Color",
            metadata: {
              component: "ColorPicker",
            },
          },
          value: {
            type: "number",
            title: "Value",
          },
          width: {
            minimum: 0,
            default: 2,
            maximum: 64,
            title: "Width",
            type: "number",
            metadata: {
              component: "SliderInput",
              showNumberInput: true,
            },
          },
          zIndex: {
            minimum: 0,
            maximum: 64,
            type: "number",
            title: "Z-Index",
            metadata: {
              component: "SliderInput",
              showNumberInput: true,
            },
          },
        },
      },
    },
    "yAxis/combineAxes": {
      type: "boolean",
      default: true,
      title: "Combine Axes",
    },
    "yAxis/reversed": {
      type: "boolean",
      title: "Reverse Direction",
    },
    "yAxis/min": {
      title: "Min",
      nullable: true,
      type: ["number", "null"],
      description:
        "The minimum value of the x-axis. Only applicable when the x-axis type is 'linear' or 'logarithmic'.",
    },
    "yAxis/max": {
      title: "Max",
      nullable: true,
      type: ["number", "null"],
      description:
        "The maximum value of the x-axis. Only applicable when the x-axis type is 'linear' or 'logarithmic'.",
    },
  },
  exporting: {
    "exporting/menuItems": {
      title: "Allowed Export Types",
      default: [],
      type: "array",
      items: {
        type: "string",
        enum: [
          "downloadCSV",
          "downloadJPEG",
          "downloadPNG",
          "downloadPDF",
          "downloadSVG",
        ],
      },
      metadata: {
        labels: ["CSV", "JPEG", "PNG", "PDF", "SVG"],
      },
    },
    "exporting/filename": {
      title: "Export Filename",
      type: "string",
      metadata: {
        fieldProps: {
          placeholder: "<Widget Name>",
        },
      },
    },
    "exporting/align": {
      title: "Align",
      type: "string",
      default: "left",
      enum: ["left", "center", "right"],
      metadata: {
        component: "Segmented",
        labels: ["Left", "Center", "Right"],
      },
    },
    "exporting/verticalAlign": {
      title: "Vertical Align",
      type: "string",
      default: "top",
      enum: ["top", "middle", "bottom"],
      metadata: {
        component: "Segmented",
        labels: ["Top", "Middle", "Bottom"],
      },
    },
  },
  "chart/zoomType": {
    type: "string",
    default: "x",
    title: "Zoom Type",
    enum: ["x", "y", "xy"],
    description: "Allow the user to zoom in on the data.",
    metadata: {
      component: "Segmented",
      labels: ["X-Axis", "Y-Axis", "Both"],
    },
  },
}

export default highchartsProperties
