// this is a short list of units that are supported by the Javascript Intl.NumberFormat
// reference: https://tc39.es/proposal-unified-intl-numberformat/section6/locales-currencies-tz_proposed_out.html#sec-issanctionedsimpleunitidentifier
// full list: https://github.com/unicode-org/cldr/blob/main/common/validity/unit.xml
const numberUnits = [
  "acre",
  "bit",
  "byte",
  "celsius",
  "centimeter",
  "day",
  "degree",
  "fahrenheit",
  "fluid-ounce",
  "foot",
  "gallon",
  "gigabit",
  "gigabyte",
  "gram",
  "hectare",
  "hour",
  "inch",
  "kilobit",
  "kilobyte",
  "kilogram",
  "kilometer",
  "liter",
  "megabit",
  "megabyte",
  "meter",
  "mile",
  "mile-scandinavian",
  "milliliter",
  "millimeter",
  "millisecond",
  "minute",
  "month",
  "ounce",
  "percent",
  "petabyte",
  "pound",
  "second",
  "stone",
  "terabit",
  "terabyte",
  "week",
  "yard",
  "year",
]

const datetimeFormatOptionsSchema = {
  default: {},
  type: "object",
  title: "Format Options",
  properties: {
    "formatOptions/weekday": {
      type: "string",
      title: "Weekday",
      default: "none",
      enum: ["none", "narrow", "short", "long"],
      description: "The format of the day of the week component.",
      metadata: {
        layout: { span: 24 },
        labels: ["None", "Narrow (T)", "Short (Thu)", "Long (Thursday)"],
      },
    },
    "formatOptions/year": {
      title: "Year",
      type: "string",
      default: "numeric",
      enum: ["none", "numeric", "2-digit"],
      description: "The format of the year component.",
      metadata: {
        layout: { span: 24 },
        component: "Segmented",
        labels: ["None", "Numeric (2012)", "2-Digit (12)"],
      },
    },
    "formatOptions/month": {
      type: "string",
      title: "Month",
      default: "2-digit",
      description: "The format of the month component.",
      enum: ["none", "numeric", "2-digit", "narrow", "short", "long"],
      metadata: {
        layout: { span: 24 },
        labels: [
          "None",
          "Numeric (3)",
          "2-Digit (03)",
          "Narrow (M)",
          "Short (Mar)",
          "Long (March)",
        ],
      },
    },
    "formatOptions/day": {
      title: "Day",
      type: "string",
      default: "2-digit",
      enum: ["none", "numeric", "2-digit"],
      description: "The format of the day of the month component.",
      metadata: {
        layout: { span: 24 },
        component: "Segmented",
        labels: ["None", "Numeric (1)", "2-Digit (01)"],
      },
    },
    "formatOptions/hour": {
      title: "Hour",
      type: "string",
      default: "numeric",
      enum: ["none", "numeric", "2-digit"],
      description: "The format of the hour component.",
      metadata: {
        layout: { span: 24 },
        component: "Segmented",
        labels: ["None", "Numeric (1)", "2-Digit (01)"],
      },
    },
    "formatOptions/minute": {
      type: "string",
      title: "Minute",
      default: "2-digit",
      enum: ["none", "numeric", "2-digit"],
      description: "The format of the minute component.",
      metadata: {
        layout: { span: 24 },
        component: "Segmented",
        labels: ["None", "Numeric (1)", "2-Digit (01)"],
      },
    },
    "formatOptions/second": {
      type: "string",
      title: "Second",
      default: "2-digit",
      enum: ["none", "numeric", "2-digit"],
      description: "The format of the second component.",
      metadata: {
        layout: { span: 24 },
        component: "Segmented",
        labels: ["None", "Numeric (1)", "2-Digit (01)"],
      },
    },
    "formatOptions/timeZone": {
      type: "string",
      title: "Time Zone",
      description:
        "The time zone to use. If not specified, the system's local time zone is used.",
      enum: [
        "UTC",
        "America/New_York", // Eastern Time (ET)
        "America/Chicago", // Central Time (CT)
        "America/Denver", // Mountain Time (MT)
        "America/Los_Angeles", // Pacific Time (PT)
        "America/Anchorage", // Alaska Time (AKT)
        "Pacific/Honolulu", // Hawaii-Aleutian Time (HST)
        "Europe/London", // Greenwich Mean Time (GMT)
        "Europe/Paris", // Central European Time (CET)
        "Asia/Tokyo", // Japan Standard Time (JST)
        "Australia/Sydney", // Australian Eastern Standard Time (AEST)
      ],
      metadata: {
        labels: [
          "UTC (UTC±0)",
          "New York (ET, UTC-4)", // Eastern Time (ET)
          "Chicago (CT, UTC-5)", // Central Time (CT)
          "Denver (MT, UTC-6)", // Mountain Time (MT)
          "Los Angeles (PT, UTC-7)", // Pacific Time (PT)
          "Anchorage (AKT, UTC-8)", // Alaska Time (AKT)
          "Honolulu (HST, UTC-10)", // Hawaii-Aleutian Time (HST)
          "London (BST, UTC+1)", // British Summer Time (BST)
          "Paris (CET, UTC+2)", // Central European Time (CET)
          "Tokyo (JST, UTC+9)", // Japan Standard Time (JST)
          "Sydney (AEDT, UTC+11)", // Australian Eastern Daylight Time (AEDT)
        ],
        layout: { span: 24 },
        fieldProps: {
          placeholder: "System's Local Time Zone",
        },
      },
    },
    "formatOptions/timeZoneName": {
      type: "string",
      default: "none",
      title: "Time Zone Format",
      enum: ["none", "short", "long"],
      description: "The format of the time zone component.",
      metadata: {
        layout: { span: 24 },
        labels: ["None", "Short (PST)", "Long (Pacific Standard Time)"],
      },
    },
    "formatOptions/hour12": {
      default: true,
      type: "boolean",
      title: "12-Hour Cycle",
      description: "Whether to use 12-hour time (with AM/PM) or 24-hour time.",
      metadata: {
        layout: { span: 24 },
      },
    },
  },
}

const formatOptionsSchema = {
  type: "object",
  default: {},
  title: "Format Options",
  metadata: {
    layout: {
      compact: 12,
    },
  },
  dependencies: {
    format: {
      allOf: [
        {
          if: {
            properties: {
              format: {
                enum: ["datetime"],
              },
            },
          },
          then: {
            properties: {
              formatOptions: datetimeFormatOptionsSchema,
            },
          },
        },
        {
          if: {
            properties: {
              format: {
                enum: ["number"],
              },
            },
          },
          then: {
            properties: {
              formatOptions: {
                default: {},
                type: "object",
                title: "Format Options",
                dependencies: {
                  "formatOptions/unit": {
                    if: {
                      properties: {
                        "formatOptions/unit": {
                          enum: numberUnits,
                        },
                      },
                    },
                    then: {
                      properties: {
                        "formatOptions/unitDisplay": {
                          title: "Unit Display",
                          default: "short",
                          type: "string",
                          enum: ["short", "long", "narrow"],
                          metadata: {
                            layout: { span: 24 },
                            labels: [
                              "Short   (16 lb)",
                              "Long    (16 pounds)",
                              "Narrow  (16#)",
                            ],
                          },
                        },
                      },
                    },
                  },
                },
                properties: {
                  "formatOptions/notation": {
                    title: "Notation",
                    default: "standard",
                    type: "string",
                    enum: ["standard", "scientific", "engineering", "compact"],
                    metadata: {
                      layout: { span: 24 },
                      labels: [
                        "Standard",
                        "Scientific",
                        "Engineering",
                        "Compact",
                      ],
                    },
                  },
                  "formatOptions/useGrouping": {
                    type: ["string", "boolean"],
                    default: "auto",
                    title: "Separator",
                    enum: ["auto", "always", false],
                    metadata: {
                      component: "Segmented",
                      layout: { span: 24 },
                      labels: ["Auto", "Always", "Never"],
                      fieldProps: {
                        placeholder: "None",
                      },
                    },
                  },
                  "formatOptions/minPrecision": {
                    title: "Min Precision",
                    min: 0,
                    type: ["number", "null"],
                    nullable: true,
                    metadata: {
                      layout: { span: 24 },
                      fieldProps: {
                        placeholder: "None",
                      },
                    },
                  },
                  "formatOptions/maxPrecision": {
                    title: "Max Precision",
                    min: 0,
                    type: ["number", "null"],
                    nullable: true,
                    metadata: {
                      layout: { span: 24 },
                      fieldProps: {
                        placeholder: "None",
                      },
                    },
                  },
                  "formatOptions/suffix": {
                    title: "Suffix",
                    default: "",
                    type: ["string", "null"],
                    nullable: true,
                    metadata: {
                      layout: { span: 24 },
                      fieldProps: {
                        placeholder: "None",
                      },
                    },
                  },
                  "formatOptions/prefix": {
                    title: "Prefix",
                    default: "",
                    type: ["string", "null"],
                    nullable: true,
                    metadata: {
                      layout: { span: 24 },
                      fieldProps: {
                        placeholder: "None",
                      },
                    },
                  },
                  "formatOptions/unit": {
                    title: "Unit",
                    type: ["string", "null"],
                    nullable: true,
                    enum: numberUnits,
                    metadata: {
                      labels: [
                        "Acre (5 acres)",
                        "Bit (128 bits)",
                        "Byte (64 bytes)",
                        "Celsius (25°C)",
                        "Centimeter (10 cm)",
                        "Day (7 days)",
                        "Degree (45°)",
                        "Fahrenheit (77°F)",
                        "Fluid Ounce (8 fl oz)",
                        "Foot (12 ft)",
                        "Gallon (3 gallons)",
                        "Gigabit (2 Gb)",
                        "Gigabyte (1 GB)",
                        "Gram (500 g)",
                        "Hectare (4 hectares)",
                        "Hour (48 hours)",
                        "Inch (36 in)",
                        "Kilobit (256 Kb)",
                        "Kilobyte (128 KB)",
                        "Kilogram (2 kg)",
                        "Kilometer (10 km)",
                        "Liter (1.5 L)",
                        "Megabit (128 Mb)",
                        "Megabyte (32 MB)",
                        "Meter (100 m)",
                        "Mile (5 miles)",
                        "Mile Scandinavian (3 scandinavian miles)",
                        "Milliliter (250 mL)",
                        "Millimeter (50 mm)",
                        "Millisecond (300 ms)",
                        "Minute (90 minutes)",
                        "Month (6 months)",
                        "Ounce (16 oz)",
                        "Percent (25%)",
                        "Petabyte (1 PB)",
                        "Pound (8 lbs)",
                        "Second (45 seconds)",
                        "Stone (6 st)",
                        "Terabit (1 Tb)",
                        "Terabyte (2 TB)",
                        "Week (3 weeks)",
                        "Yard (25 yards)",
                        "Year (2 years)",
                      ],
                      fieldProps: {
                        placeholder: "None",
                      },
                    },
                  },
                },
              },
            },
          },
        },
        {
          if: {
            properties: {
              format: {
                enum: ["currency"],
              },
            },
          },
          then: {
            properties: {
              formatOptions: {
                default: {},
                type: "object",
                title: "Format Options",
                properties: {
                  "formatOptions/currency": {
                    title: "Currency",
                    default: "USD",
                    enum: ["USD", "EUR"],
                    type: "string",
                    metadata: {
                      component: "Segmented",
                      layout: { span: 24 },
                    },
                  },
                  "formatOptions/currencySign": {
                    type: "string",
                    default: "standard",
                    title: "Sign",
                    enum: ["standard", "accounting"],
                    metadata: {
                      component: "Segmented",
                      labels: ["Standard", "Accounting"],
                      layout: { span: 24 },
                    },
                  },
                  "formatOptions/currencyDisplay": {
                    type: "string",
                    default: "symbol",
                    title: "Display",
                    enum: ["symbol", "name", "code"],
                    metadata: {
                      labels: [
                        "Currency Symbol   -  ($)",
                        "Currency Name     -  (US Dollar)",
                        "ISO Currency Code -  (USD)",
                      ],
                      layout: { span: 24 },
                    },
                  },
                  "formatOptions/notation": {
                    title: "Notation",
                    default: "standard",
                    type: "string",
                    enum: ["standard", "compact"],
                    metadata: {
                      layout: { span: 24 },
                      labels: ["Standard", "Compact"],
                    },
                  },
                  "formatOptions/minPrecision": {
                    title: "Min Precision",
                    min: 0,
                    default: 0,
                    type: "number",
                    metadata: {
                      layout: { span: 24 },
                    },
                  },
                  "formatOptions/maxPrecision": {
                    title: "Max Precision",
                    min: 0,
                    default: 2,
                    type: "number",
                    metadata: {
                      layout: { span: 24 },
                    },
                  },
                },
              },
            },
          },
        },
        {
          if: {
            properties: {
              format: {
                enum: ["percent"],
              },
            },
          },
          then: {
            properties: {
              formatOptions: {
                default: {},
                type: "object",
                title: "Format Options",
                properties: {
                  "formatOptions/minPrecision": {
                    title: "Min Precision",
                    min: 0,
                    type: ["number", "null"],
                    nullable: true,
                    metadata: {
                      layout: { span: 24 },
                      fieldProps: {
                        placeholder: "None",
                      },
                    },
                  },
                  "formatOptions/maxPrecision": {
                    title: "Max Precision",
                    min: 0,
                    default: 0,
                    type: "number",
                    metadata: {
                      layout: { span: 24 },
                    },
                  },
                  "formatOptions/suffix": {
                    title: "Suffix",
                    default: "",
                    type: ["string", "null"],
                    nullable: true,
                    metadata: {
                      layout: { span: 24 },
                      fieldProps: {
                        placeholder: "None",
                      },
                    },
                  },
                  "formatOptions/prefix": {
                    title: "Prefix",
                    default: "",
                    type: ["string", "null"],
                    nullable: true,
                    metadata: {
                      layout: { span: 24 },
                      fieldProps: {
                        placeholder: "None",
                      },
                    },
                  },
                  "formatOptions/notation": {
                    title: "Notation",
                    default: "standard",
                    type: "string",
                    enum: ["standard", "scientific", "engineering", "compact"],
                    metadata: {
                      layout: { span: 24 },
                      labels: [
                        "Standard",
                        "Scientific",
                        "Engineering",
                        "Compact",
                      ],
                    },
                  },
                },
              },
            },
          },
        },
      ],
    },
  },
  properties: {
    format: {
      type: "string",
      title: "Format",
      default: "",
      enum: ["", "number", "string", "datetime", "currency", "percent"],
      metadata: {
        layout: { span: 24 },
        labels: [
          "Default",
          "Number",
          "Text",
          "Datetime",
          "Currency",
          "Percent",
        ],
        fieldProps: {
          placeholder: "Select a format",
        },
      },
    },
  },
}

export { datetimeFormatOptionsSchema }
export default formatOptionsSchema
