import { flow } from "lodash"

import {
  processMetadata,
  processDefaultValue,
  processErrorMessages,
} from "../../lib/generateSchemaHelpers"

const generateSchema = (options, appVariables, buildSchema) =>
  flow([
    processMetadata,
    processErrorMessages,
    options => {
      if (options.type !== "array") return options
      const { items, ...rest } = options
      if (!items || !items.type) {
        return {
          ...options,
          items: {},
        }
      }
      return {
        ...rest,
        items: buildSchema(items, appVariables),
      }
    },
    processDefaultValue,
  ])(options)

export default generateSchema
