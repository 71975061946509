import highchartsProperties from "./highchartsProperties"
import basicSchema from "./basic"

const barSchema = aggregateRequired => ({
  type: "object",
  title: "Data",
  default: {},
  properties: {
    inputZones: basicSchema(aggregateRequired).properties.inputZones,
    seriesOptions: basicSchema(aggregateRequired).properties.seriesOptions,
    highchart: {
      default: {},
      title: "Chart Options",
      type: "object",
      metadata: {
        sections: {
          title: {
            title: "Title",
            properties: [
              "title/align",
              "title/margin",
              "title/floating",
              "title/showTitle",
              "title/verticalAlign",
            ],
          },
          appearance: {
            title: "Appearance",
            properties: [
              "chart/backgroundColor",
              "chart/height",
              "chart/width",
              "chart/showTitle",
              "chart/zoomType",
              "chart/plotBackgroundColor",
              "chart/borderWidth",
              "chart/borderColor",
              "chart/plotBorderWidth",
              "chart/plotBorderColor",
              "chart/marginTop",
              "chart/marginRight",
              "chart/marginBottom",
              "chart/marginLeft",
              "chart/polar",
            ],
          },
          legend: {
            title: "Legend",
            properties: [
              "legend/enabled",
              "legend/align",
              "legend/vertialAlign",
              "legend/layout",
              "legend/borderWidth",
              "legend/borderRadius",
              "legend/borderColor",
              "legend/verticalAlign",
              "legend/backgroundColor",
              "legend/itemStyle/color",
              "legend/itemStyle/fontWeight",
              "legend/itemStyle/fontFamily",
            ],
          },
          tooltip: {
            title: "Tooltip",
            properties: [
              "tooltip/useHTML",
              "tooltip/enabled",
              "tooltip/animation",
              "tooltip/shadow",
              "tooltip/split",
              "tooltip/shared",
              "tooltip/backgroundColor",
              "tooltip/borderColor",
              "tooltip/borderRadius",
              "tooltip/borderWidth",
              "tooltip/style",
              "tooltip/valueDecimals",
              "tooltip/valuePrefix",
              "tooltip/valueSuffix",
            ],
          },
          xAxis: {
            title: "X-Axis",
            properties: [
              "xAxis/labels/useHTML",
              "xAxis/labels/enabled",
              "xAxis/labels/format",
              "xAxis/labels/align",
              "xAxis/labels/rotation",
              "xAxis/labels/padding",
              "xAxis/labels/style/fontSize",
              "xAxis/labels/style/color",
              "xAxis/title/text",
              "xAxis/title/align",
              "xAxis/title/margin",
              "xAxis/title/style/color",
              "xAxis/title/style/fontSize",
              "xAxis/crosshair/color",
              "xAxis/crosshair/width",
              "xAxis/crosshair/dashStyle",
              "xAxis/crosshair/zIndex",
              "xAxis/crosshair/snap",
              "xAxis/plotLines",
              "xAxis/opposite",
              "xAxis/reversed",
              "xAxis/min",
              "xAxis/max",
              "xAxis/categories",
            ],
          },
          yAxis: {
            title: "Y-Axis",
            properties: [
              "yAxis/labels/enabled",
              "yAxis/labels/format",
              "yAxis/labels/align",
              "yAxis/labels/rotation",
              "yAxis/labels/style/fontSize",
              "yAxis/labels/style/color",
              "yAxis/title/text",
              "yAxis/title/align",
              "yAxis/title/margin",
              "yAxis/title/style/color",
              "yAxis/title/style/fontSize",
              "yAxis/crosshair/color",
              "yAxis/crosshair/width",
              "yAxis/crosshair/dashStyle",
              "yAxis/crosshair/zIndex",
              "yAxis/crosshair/snap",
              "yAxis/plotLines",
              "yAxis/combineAxes",
              "yAxis/reversed",
              "yAxis/min",
              "yAxis/max",
            ],
          },
          format: {
            title: "Datetime Format",
            properties: ["datetimeFormatStruct", "datetimeFormatPattern"],
          },
          exporting: {
            title: "Exporting",
            properties: [
              "exporting/menuItems",
              "exporting/filename",
              "exporting/align",
              "exporting/verticalAlign",
            ],
          },
        },
      },
      properties: {
        ...highchartsProperties.title,
        ...highchartsProperties.tooltip,
        ...highchartsProperties.chart,
        ...highchartsProperties.legend,
        ...highchartsProperties.xAxis,
        ...highchartsProperties.yAxis,
        ...highchartsProperties.exporting,
        "chart/zoomType": highchartsProperties["chart/zoomType"],
        "plotOptions/bar/stacking": {
          type: "string",
          enum: [null, "normal", "percent"],
          default: null,
          title: "Bar Stacking",
          description:
            "Determines if data groupings are stacked by value, by percentage or not stacked.  For charts that do not have groups configured, this option will have no effect.",
          metadata: {
            labels: ["None", "Stacked", "100% Stacking"],
            wrapperStyles: {
              padding: "15px 0 5px 15px",
              fontWeight: "bold",
            },
          },
        },
        "yAxis/stackLabels/enabled": {
          type: "boolean",
          default: true,
          title: "Show Stack Totals",
          description:
            "If this is enabled, the total for all values in the stack will be displayed at the top of the stack.",
          metadata: {
            wrapperStyles: {
              padding: "0 0 5px 15px",
            },
          },
        },
      },
    },
  },
})

export default barSchema
