import React from "react"
import { Checkbox } from "antd"
import { useMutation } from "@apollo/client"

import { useCurrentCustomer } from "@dbai/ui-staples"

import { GET_ACTIONS, PUBLISH_ACTION, DELETE_ACTION_PUBLICATION } from "queries"

const refetchQueries = [GET_ACTIONS]

const PublishCheckbox = props => {
  const [{ id: customerId }] = useCurrentCustomer()
  const {
    row: { published, id: actionId },
  } = props
  const variables = {
    actionId,
    customerId,
  }
  const [publish] = useMutation(PUBLISH_ACTION, {
    variables,
    refetchQueries,
  })

  const [deletePublication] = useMutation(DELETE_ACTION_PUBLICATION, {
    variables,
    refetchQueries,
  })

  const handleChange = () => {
    if (published) {
      return deletePublication()
    }
    return publish()
  }

  return <Checkbox checked={published} onChange={handleChange} />
}

export default PublishCheckbox
