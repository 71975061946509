import React, { useEffect, useState } from "react"
import { Button } from "antd"
import { useDispatch } from "react-redux"

import ActionsTable from "./ActionsTable"
import { actions } from "reducers/actionReducer"
import IndexHeader from "components/pages/Workflows/shared/IndexHeader"
import WorkflowsContent from "components/pages/Workflows/shared/WorkflowsContent"

const Actions = props => {
  const dispatch = useDispatch()
  const { customer } = props
  const [showPanel, setShowPanel] = useState(false)
  const openPanel = () => setShowPanel(true)
  const closePanel = () => setShowPanel(false)

  // Clear the form on navigation to this page.
  useEffect(() => {
    dispatch(actions.reset())
  }, [dispatch])

  return (
    <>
      <IndexHeader>
        <Button onClick={openPanel} type="primary">
          + NEW ACTION
        </Button>
      </IndexHeader>
      <WorkflowsContent>
        <ActionsTable
          customer={customer}
          showNew={showPanel}
          onNewClose={closePanel}
        />
      </WorkflowsContent>
    </>
  )
}

export default Actions
