/*
 * As `Editor` components are made they will register the resultant CodeMirror
 * instances here under the coresponding uuid of the containing cell. This
 * allows other parts of the app to interact with the CodeMirror interface
 * programatically.
 * Please keep this file limited to getter/setter type logic and implement any
 * other logic closer to where it's being used.
 */
let codeMirrorInstances = {}

export const addCodeMirrorInstance = (key, cm) => {
  codeMirrorInstances[key] = cm
}

export const removeCodeMirrorInstance = key => {
  codeMirrorInstances[key] = null
}

export const getCodeMirrorInstance = key => codeMirrorInstances[key]

export const resetCodeMirrorInstances = () => {
  codeMirrorInstances = {}
}
