import { useCallback } from "react"
import { toast } from "react-toastify"
import { useMutation } from "@apollo/client"

import { useWidgetContext } from "../../hooks"
import { AUTO_INCREMENT_COLUMN_NAME, getColumnDataIndex } from "./utils"
import { COLLECT_DATA, DELETE_DATA } from "../../queries/collection"

export const useAddRow = (datasetId, columns, refetch) => {
  const { customerId } = useWidgetContext()
  const [collect] = useMutation(COLLECT_DATA)
  return useCallback(
    (data = {}) => {
      const newRow = {
        columns: columns
          .filter(({ name }) => name !== AUTO_INCREMENT_COLUMN_NAME)
          .filter(({ type }) => type !== "computed")
          .map(({ type, name }) => ({
            type,
            name: name,
            value: data[name],
          })),
      }
      return collect({
        variables: {
          customerId,
          id: datasetId,
          input: { rows: [newRow] },
        },
      }).then(refetch)
    },
    [datasetId, customerId, columns, refetch, collect]
  )
}

export const useUpdateRow = (datasetId, columns, record) => {
  const { customerId } = useWidgetContext()
  const [collect] = useMutation(COLLECT_DATA)
  return useCallback(
    data => {
      const updatedRecord = { ...record, ...data }
      const row = {
        columns: columns
          .filter(({ type }) => type !== "computed")
          .map(({ type, name }) => {
            const dataIndex = getColumnDataIndex(name)
            return {
              type,
              name,
              value: updatedRecord[dataIndex],
            }
          }),
      }
      return collect({
        variables: {
          customerId,
          id: datasetId,
          input: { rows: [row] },
        },
      })
    },
    [datasetId, customerId, columns, record, collect]
  )
}

export const useDeleteRow = (uniqueKeys, datasetId, refetch) => {
  const { cname } = useWidgetContext()
  const [deleteRow] = useMutation(DELETE_DATA)
  return useCallback(
    row => {
      if (uniqueKeys.length) {
        deleteRow({
          variables: {
            cname,
            id: datasetId,
            filters: {
              conditions: uniqueKeys.map(key => ({
                column: key,
                value: row[key],
                op: "=",
              })),
            },
          },
        }).then(() => {
          toast.success("Row removed successfully.")
          refetch()
        })
      } else {
        console.error("Cannot remove row. No unique keys found.")
        toast.error("Cannot remove row. No unique keys found.")
      }
    },
    [uniqueKeys, cname, datasetId, refetch, deleteRow]
  )
}
