import React, { useMemo } from "react"
import { Alert, Space } from "antd"

import { useFormErrors, useFormContext } from "./hooks"

const ValidationErrors = props => {
  const errors = useFormErrors()
  const { showErrors } = useFormContext()

  const message = useMemo(() => {
    return (
      <Space direction="vertical">
        {errors.map((error, index) => (
          <span>{error.message}</span>
        ))}
      </Space>
    )
  }, [errors])

  if (!errors.length || !showErrors) return null
  return <Alert type="error" message={message} />
}

export default ValidationErrors
