import React, { useEffect, useRef, useMemo } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import Layout from "./Layout"
import { onResize } from "./utils"
import LayoutGrid from "./LayoutGrid"
import NoPageWidgets from "./NoPageWidgets"
import { DROPPING_ITEM_I } from "../../lib/layout"
import { useCurrentBreakpoint } from "../../hooks"
import ResizeHandle from "./LayoutGrid/ResizeHandle"
import { getDragHandleClass } from "./Layout/LayoutActionButtons"
import {
  selectPageWidgetIds,
  selectResponsiveLayouts,
} from "../../selectors/app"

const StyledGrid = React.memo(LayoutGrid)
const droppingItemLayout = {
  i: DROPPING_ITEM_I,
  w: 40,
  h: 9,
}

export const Page = props => {
  const {
    cols,
    style,
    onDrop,
    pageId,
    editable,
    rowHeight,
    className,
    onDragStop,
    onDragLeave,
    onDragEnter,
    containerId,
    allowOverlap: _allowOverlap = true,
    gridItemMargins,
    actionsPosition,
    compactionMethod = "freeform",
    containerPadding,
    preventCollision: _preventCollision,
    handleLayoutChange,
  } = props
  const gridRef = useRef(null)
  const widgetIds = useSelector(state => selectPageWidgetIds(state, { pageId }))
  const layouts = useSelector(state =>
    selectResponsiveLayouts(state, { widgetIds })
  )
  const breakpoint = useCurrentBreakpoint()
  const dragHandleClass = getDragHandleClass(pageId)

  const items = useMemo(() => {
    return (
      widgetIds
        ?.map(widgetId => {
          return (
            <div key={widgetId} className={containerId}>
              <Layout
                key={widgetId}
                pageId={pageId}
                widgetId={widgetId}
                gridItemMargins={gridItemMargins}
                actionsPosition={actionsPosition}
                containerPadding={containerPadding}
              />
            </div>
          )
        })
        .filter(Boolean) || []
    )
  }, [
    pageId,
    widgetIds,
    containerId,
    actionsPosition,
    gridItemMargins,
    containerPadding,
  ])

  const content = useMemo(() => {
    return items.length > 0 ? items : <NoPageWidgets pageId={pageId} />
  }, [items, pageId])

  const preventCollision = useMemo(() => {
    return compactionMethod === "freeform" ? true : _preventCollision
  }, [_preventCollision, compactionMethod])

  const compactType = useMemo(() => {
    return ["vertical", "horizontal"].includes(compactionMethod)
      ? compactionMethod
      : null
  }, [compactionMethod])

  const margin = useMemo(() => {
    return compactionMethod === "freeform" ? [0, 0] : gridItemMargins || []
  }, [compactionMethod, gridItemMargins])

  const resizeHandle = useMemo(() => {
    return editable ? <ResizeHandle containerId={containerId} /> : null
  }, [editable, containerId])

  const allowOverlap = useMemo(() => {
    return compactionMethod === "freeform" && _allowOverlap
  }, [compactionMethod, _allowOverlap])

  useEffect(() => {
    const computeGridHeight = () => {
      let maxHeight = 0

      const gridItemElements = gridRef.current.querySelectorAll(
        `.${containerId}.react-grid-item`
      )
      // loop through each grid item and calculate the bottommost position
      gridItemElements.forEach(item => {
        const rect = item.getBoundingClientRect()

        // extract the translateY value from the transform property
        const transform = window.getComputedStyle(item).transform
        const matrix = transform.match(/^matrix\((.+)\)$/)
        const translateY = matrix ? parseFloat(matrix[1].split(", ")[5]) : 0
        const bottom = rect.height + translateY

        if (bottom > maxHeight) {
          maxHeight = bottom
        }
        gridRef.current.style.minHeight = `${maxHeight}px`
      })
    }
    if (gridRef.current) {
      computeGridHeight()
    }
  }, [items, containerId])

  if (!breakpoint) {
    console.error("No window detected.")
    return null
  }

  return (
    <StyledGrid
      cols={cols}
      key={pageId}
      style={style}
      onDrop={onDrop}
      autoSize={false}
      isBounded={false}
      layouts={layouts}
      innerRef={gridRef}
      static={!!editable}
      onResize={onResize}
      className={className}
      rowHeight={rowHeight}
      isDraggable={!!editable}
      isDroppable={!!editable}
      onDragStop={onDragStop}
      breakpoint={breakpoint}
      measureBeforeMount={true}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      draggableHandle={dragHandleClass}
      onLayoutChange={handleLayoutChange}
      containerPadding={containerPadding}
      droppingItem={droppingItemLayout}
      resizeHandle={resizeHandle}
      // If true, grid can be placed one over the other.
      // If set, implies `preventCollision`.
      allowOverlap={allowOverlap}
      margin={margin}
      compactType={compactType}
      // If true, grid items won't change position when being
      // dragged over. If `allowOverlap` is still false,
      // this simply won't allow one to drop on an existing object.
      preventCollision={preventCollision}
    >
      {content}
    </StyledGrid>
  )
}

Page.propTypes = {
  style: PropTypes.object,
  pageId: PropTypes.number,
}

export default React.memo(Page)
