import datasetColumnSchema, {
  categoricalColumnSchema,
} from "../datasetColumnSchema"
import highchartsProperties from "./highchartsProperties"

const pieSchema = {
  type: "object",
  title: "Data",
  default: {},
  properties: {
    inputZones: {
      title: "Data",
      metadata: {
        schemaName: "dependencies.datasetId.properties.inputZones",
        component: "ColumnInputZones",
        order: ["yAxisData", "groupByData"],
        style: {
          height: "calc(100vh - 259px)",
        },
      },
      type: "object",
      default: {},
      properties: {
        yAxisData: {
          minItems: 1,
          type: "array",
          maxItems: 500,
          title: "Show",
          uniqueItems: true,
          default: [],
          items: {
            default: {},
            type: "object",
            required: ["column", "aggregate"],
            title: "Dataset Column",
            metadata: {
              order: ["type", "column", "aggregate"],
              layout: { gutter: [16] },
              labelPath: "column",
            },
            dependencies: datasetColumnSchema.dependencies,
            properties: {
              column: {
                title: "Column",
                type: "string",
                metadata: {
                  validateFirst: true,
                  allowedTypes: ["numerical", "float", "int"],
                  component: "ColumnSelect",
                  fieldProps: {
                    popupMatchSelectWidth: false,
                  },
                },
              },
              type: {
                $ref: "#/$defs/dataColumnType",
                metadata: { hidden: true },
              },
              id: {
                title: "ID",
                type: "string",
                metadata: {
                  hidden: true,
                  defaultValueSource: "uid",
                },
              },
            },
          },
          metadata: {
            noStyle: true,
            layout: { gutter: [] },
            component: "DnDDropzone",
            allowedTypes: ["numerical", "float", "int"],
            fieldProps: {
              style: { minHeight: "100px" },
            },
          },
        },
        groupByData: {
          default: [],
          minItems: 1,
          maxItems: 1,
          type: "array",
          title: "Split By",
          uniqueItems: true,
          items: {
            type: "object",
            required: ["column"],
            metadata: {
              labelPath: "column",
              order: ["column", "format"],
            },
            dependencies: {
              type: {
                allOf: [
                  {
                    if: {
                      properties: {
                        type: {
                          enum: ["string", "categorical"],
                        },
                      },
                    },
                    then: categoricalColumnSchema,
                  },
                ],
              },
            },
            properties: {
              column: {
                title: "Column",
                type: "string",
                metadata: {
                  validateFirst: true,
                  allowedTypes: [
                    "string",
                    "categorical",
                    "numerical",
                    "float",
                    "int",
                  ],
                  component: "ColumnSelect",
                  fieldProps: {
                    popupMatchSelectWidth: false,
                  },
                },
              },
              type: {
                $ref: "#/$defs/dataColumnType",
                metadata: { hidden: true },
              },
              id: {
                title: "ID",
                type: "string",
                metadata: {
                  hidden: true,
                  defaultValueSource: "uid",
                },
              },
            },
          },
          metadata: {
            noStyle: true,
            component: "DnDDropzone",
            allowedTypes: [
              "string",
              "categorical",
              "numerical",
              "float",
              "int",
            ],
            fieldProps: {
              style: {
                minHeight: "100px",
              },
            },
          },
        },
      },
    },
    highchart: {
      default: {},
      title: "Chart Options",
      type: "object",
      metadata: {
        sections: {
          title: {
            title: "Title",
            properties: [
              "title/align",
              "title/margin",
              "title/floating",
              "title/showTitle",
              "title/verticalAlign",
            ],
          },
          appearance: {
            title: "Appearance",
            properties: [
              "plotOptions/pie/innerSize",
              "chart/backgroundColor",
              "chart/height",
              "chart/width",
              "chart/showTitle",
              "chart/plotBackgroundColor",
              "chart/borderWidth",
              "chart/borderColor",
              "chart/plotBorderWidth",
              "chart/plotBorderColor",
              "chart/marginTop",
              "chart/marginRight",
              "chart/marginBottom",
              "chart/marginLeft",
            ],
          },
          labels: {
            title: "Labels",
            properties: [
              "plotOptions/pie/dataLabels/enabled",
              "plotOptions/pie/dataLabels/distance",
              "plotOptions/pie/dataLabels/valueLabels",
            ],
          },
          tooltip: {
            title: "Tooltip",
            properties: [
              "tooltip/enabled",
              "custom/tooltip/metricLabel",
              "custom/tooltip/valueFormat",
            ],
          },
          legend: {
            title: "Legend",
            properties: [
              "legend/enabled",
              "legend/align",
              "legend/vertialAlign",
              "legend/layout",
              "legend/borderWidth",
              "legend/borderRadius",
              "legend/borderColor",
              "legend/verticalAlign",
              "legend/backgroundColor",
              "legend/itemStyle/color",
              "legend/itemStyle/fontWeight",
              "legend/itemStyle/fontFamily",
            ],
          },
          exporting: {
            title: "Exporting",
            properties: [
              "exporting/menuItems",
              "exporting/filename",
              "exporting/align",
              "exporting/verticalAlign",
            ],
          },
        },
      },
      properties: {
        ...highchartsProperties.title,
        "plotOptions/pie/innerSize": {
          type: "string",
          title: "Donut Chart Inner Size %",
          default: "70%",
        },
        "plotOptions/pie/showInLegend": {
          type: "boolean",
          title: "Pie Chart Legend Enabled",
          default: true,
          metadata: {
            hidden: true,
          },
        },
        "plotOptions/pie/dataLabels/enabled": {
          type: "boolean",
          title: "Show Labels",
          description: "Display the labels for each pie section in the chart",
          default: false,
        },
        "plotOptions/pie/dataLabels/distance": {
          type: "number",
          minimum: -100,
          maximum: 100,
          default: -15,
          title: "Inner Label Distance",
          description:
            "The distance of the data label from the pie's edge. Negative numbers put the data label on top of the pie slices. Can also be defined as a percentage of pie's radius.",
        },
        "plotOptions/pie/dataLabels/valueLabels": {
          enum: ["", "value", "percent", "value/percent"],
          default: "value",
          title: "Show Value Labels",
          metadata: {
            labels: ["None", "Value", "Percentage", "Value and Percentage"],
          },
        },
        "tooltip/enabled": {
          description: "Whether to enable the tooltip or not.",
          title: "Enabled",
          type: "boolean",
          default: true,
        },
        "custom/tooltip/metricLabel": {
          description:
            "Override the metric label (2nd line of the tooltip). The default label is <metric> - <aggregate> - <group by>",
          title: "Metric Label",
          type: "string",
        },
        "custom/tooltip/valueFormat": {
          description:
            "Determines how the 3rd line of the tooltip is displayed.  The default shows the value and percentage.  Ex: 235.7 (14%)",
          title: "Value Format",
          default: "both",
          type: "string",
          enum: ["both", "value", "percent"],
          metadata: {
            labels: ["Value and Percentage", "Value Only", "Percentage Only"],
          },
        },
        ...highchartsProperties.chart,
        ...highchartsProperties.exporting,
        ...highchartsProperties.legend,
      },
    },
  },
}

export default pieSchema
