import React, { useEffect, useState, useRef, useMemo, useCallback } from "react"
import { isEqual } from "lodash"
import { toast } from "react-toastify"
import styled from "styled-components"
import { Space, Button, Divider, Modal } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { SidePane } from "@dbai/ui-staples"

import { useWidgetContext } from "../hooks"
import StandardEditor from "./StandardEditor"
import JSONSchemaForm from "../JSONSchemaForm"
import { actions } from "../reducers/appReducer"
import useEditingWidget from "../hooks/useEditingWidget"
import JSONSchemaField from "../JSONSchemaForm/JSONSchemaField"
import { NEW_WIDGET_FORM_ID, getWidgetFormId } from "../lib/widgetEditor"
import {
  selectEditingAppWidget,
  selectFullWidgetEditorOpen,
} from "../selectors/app"
import {
  useFormData,
  useSavedFormValue,
  useEditingWidgetSchema,
} from "../JSONSchemaForm/hooks"

const EditorContainer = styled.div`
  label {
    margin-bottom: 0;
  }
  svg {
    vertical-align: text-top;
  }
`

const widgetNameSchema = {
  default: "",
  title: "Name",
  type: "string",
  metadata: {
    noStyle: true,
    fieldProps: {
      placeholder: "Widget Name",
    },
  },
}

const EditorContent = props => {
  const widgetType = useFormData("type")
  const { widgetRegistry } = useWidgetContext()
  const Editor = useMemo(
    () => widgetRegistry[widgetType]?.Editor || StandardEditor,
    [widgetType, widgetRegistry]
  )
  return <Editor {...props} />
}

const SidePaneTitle = () => {
  const { widgetSchema } = useWidgetContext()
  if (!widgetSchema) return null
  return (
    <JSONSchemaField
      path=""
      schemaKey="name"
      parentSchema={widgetSchema}
      schema={widgetNameSchema}
      style={{ minWidth: "50px", maxWidth: "300px", paddingRight: "16px" }}
    />
  )
}

const useWidgetChangedObserver = () => {
  const widget = useSelector(selectEditingAppWidget)
  const formState = useSavedFormValue("")
  const widgetChanged = useRef(false)
  useEffect(() => {
    widgetChanged.current = !isEqual(widget, formState)
  }, [widget, formState])
  return widgetChanged
}

const EditorSidePane = () => {
  const dispatch = useDispatch()
  const [showConfirmLeave, setShowConfirmLeave] = useState(false)
  const { widgetRegistry } = useWidgetContext()
  const open = useSelector(selectFullWidgetEditorOpen)
  const widgetChanged = useWidgetChangedObserver()

  const closeEditor = useCallback(
    promptBeforeClosing => () => {
      if (widgetChanged.current && promptBeforeClosing && !showConfirmLeave) {
        setShowConfirmLeave(true)
        return
      }
      setShowConfirmLeave(false)
      dispatch(actions.closeWidgetEditor())
    },
    [dispatch, widgetChanged, showConfirmLeave]
  )

  const save = useCallback(
    closeOnSave => e => {
      e.stopPropagation()
      e.preventDefault()
      dispatch(
        actions.saveWidgetFromEditor({
          widgetRegistry,
          ignoreErrors: true,
          closeEditor: closeOnSave,
        })
      )
      toast.success("Save Successful", { autoClose: 2000 })
    },
    [dispatch, widgetRegistry]
  )

  return (
    <SidePane
      mask
      width="97vw"
      open={open}
      keyboard={false}
      onClose={closeEditor(true)}
      bodyStyle={{ padding: "0px" }}
      title={<SidePaneTitle />}
      extra={
        <Space>
          <Button type="primary" onClick={save(false)}>
            Save
          </Button>
          <Divider type="vertical" />
          <Button danger onClick={closeEditor(false)}>
            Cancel
          </Button>
          <Button htmlType="submit" type="primary" onClick={save(true)}>
            Save and Close
          </Button>
        </Space>
      }
    >
      <Modal
        zIndex={1002}
        open={showConfirmLeave}
        title="Unsaved changes!"
        onOk={closeEditor(false)}
        onClose={() => setShowConfirmLeave(false)}
        onCancel={() => setShowConfirmLeave(false)}
        icon={<FontAwesomeIcon icon={faExclamationCircle} />}
      >
        <p>
          It looks like you've made changes to the widget. If you leave now,
          these changes will not be saved.
        </p>
      </Modal>
      <EditorContainer>{open ? <EditorContent /> : null}</EditorContainer>
    </SidePane>
  )
}

const WidgetEditorProvider = props => {
  const formRef = useRef()
  const { children, ...rest } = props
  const widgetSchema = useEditingWidgetSchema()
  const [widgetId, widget, ready] = useEditingWidget()
  const formId = useMemo(() => getWidgetFormId(widgetId), [widgetId])

  // we do not want to render the form until the widget's form instance is ready.
  // if the form is a new widget form, then we can render it immediately.
  if (!ready && formId !== NEW_WIDGET_FORM_ID) return children
  return (
    <JSONSchemaForm.Provider
      {...rest}
      ref={formRef}
      value={widget}
      formId={formId}
      schema={widgetSchema}
    >
      <EditorSidePane />
      {children}
    </JSONSchemaForm.Provider>
  )
}

export default WidgetEditorProvider
