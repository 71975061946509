import React, { useCallback } from "react"
import styled from "styled-components"
import { Space, Row, Col, Button } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBackward,
  faMagicWandSparkles,
} from "@fortawesome/pro-solid-svg-icons"

import { actions } from "../reducers/appReducer"
import JSONSchemaField from "../JSONSchemaForm/JSONSchemaField"
import { selectWidgetEditor } from "../selectors/app"
import { useEditingWidgetSchema } from "../JSONSchemaForm/hooks"

const PaddedCol = styled(Col)`
  padding: 8px 16px;
`

const ScrollableCol = styled(Col)`
  height: calc(100vh - 96px);
  padding-top: 8px;
  overflow-y: auto;
`

const widgetNameSchema = {
  default: "",
  title: "Name",
  type: "string",
  metadata: {
    noStyle: true,
    fieldProps: {
      placeholder: "Widget Name",
    },
  },
}

const SidePanelWidgetEditor = () => {
  const widgetSchema = useEditingWidgetSchema()
  return (
    <>
      <PaddedCol span={24}>
        <JSONSchemaField
          path="."
          schemaKey="name"
          parentSchema={widgetSchema}
          schema={widgetNameSchema}
        />
      </PaddedCol>
      <ScrollableCol span={24}>
        <JSONSchemaField path="" schemaKey="." schema={widgetSchema} />
      </ScrollableCol>
    </>
  )
}

const WidgetEditorWrapper = () => {
  const { allowFullEditor, open } = useSelector(selectWidgetEditor)
  const dispatch = useDispatch()
  const closeEditor = useCallback(() => {
    dispatch(actions.closeWidgetEditor())
  }, [dispatch])

  const toggleFullEditor = useCallback(() => {
    if (open) {
      return closeEditor()
    }
    dispatch(actions.openWidgetEditor())
  }, [dispatch, closeEditor, open])

  return (
    <Row>
      <PaddedCol span={24}>
        <Space>
          <Button
            onClick={closeEditor}
            icon={<FontAwesomeIcon icon={faBackward} />}
          >
            Go Back
          </Button>
          {allowFullEditor ? (
            <Button
              onClick={toggleFullEditor}
              icon={<FontAwesomeIcon icon={faMagicWandSparkles} />}
            >
              {open ? "Close" : "Open"} Full Editor
            </Button>
          ) : null}
        </Space>
      </PaddedCol>
      {!open ? <SidePanelWidgetEditor /> : null}
    </Row>
  )
}

export default WidgetEditorWrapper
