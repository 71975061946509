import React from "react"
import { noop } from "lodash"
import styled from "styled-components"
import { useDrag } from "react-dnd"

const ItemContainer = styled.div`
  ${props => (props.err ? "box-shadow: 0px 0px 2px 1px #ff2e2e" : "")};
  opacity: ${props => props.opacity || 1};
  background-color: ${props => {
    const defaultBg = props.theme?.colorBgContainer || "#fff"
    const selectedBg = props.theme?.colorBgTextActive || "#E1EDF7"
    return !!props.selected ? selectedBg : defaultBg
  }};
  cursor: ${props => props.cursor || "default"};
  margin: ${props => props.margin || "0"};
  width: 100%;
  font-size: 13px;
  background-clip: border-box;
  border-bottom: 1px solid
    ${props => {
      return props.theme?.colorBorder || "rgba(0, 40, 100, 0.12)"
    }};
  text-align: left;
  padding: 0px 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: stretch;
  > div {
    margin: 0.25rem;
    padding: 0;
    display: flex;
    align-items: center;
  }
  .db-icon {
    margin-right: 3px;
  }
  .db-remove-btn {
    margin: 0;
    padding: 0.25rem;
  }
`

const ListItem = props => {
  const { beginDrag = noop, endDrag = noop, item, ...rest } = props

  const [{ isDragging }, drag] = useDrag({
    item,
    begin: beginDrag,
    end: endDrag,
    collect: monitor => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      currentOffset: monitor.getSourceClientOffset(),
      isDragging: !!monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  })

  return (
    <ItemContainer
      className="field-card"
      ref={drag}
      cursor="move"
      opacity={isDragging ? 0.9 : 1}
      draggable={!isDragging}
      {...rest}
    >
      {props.children}
    </ItemContainer>
  )
}

export default ListItem
