import React, { useCallback } from "react"
import { useDispatch } from "react-redux"

import { MenuTextWithIcon } from "@dbai/ui-staples"

import { actions } from "reducers/notebookReducer"

export const useLayoutOption = layout => {
  const dispatch = useDispatch()
  const handler = useCallback(() => {
    const layoutToAction = {
      both: actions.layoutBoth,
      code: actions.layoutCode,
      graph: actions.layoutGraph,
    }

    const layoutAction = layoutToAction[layout] || actions.layoutBoth
    dispatch(layoutAction())
  }, [dispatch, layout])

  return handler
}

const LayoutOption = props => {
  const { icon, children } = props

  return <MenuTextWithIcon icon={icon}>{children}</MenuTextWithIcon>
}

export default LayoutOption
