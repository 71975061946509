import React, { useMemo, useCallback, useState, useRef, useEffect } from "react"
import { Statistic } from "antd"
import styled from "styled-components"
import { Html } from "react-konva-utils"
import { Group, Rect } from "react-konva"

import { useDatasetData } from "../../hooks"
import { findColumnConfig, formatColumnValue } from "../../lib/datasetColumns"

const HoistedDiv = styled.div`
  border: none;
  width: 100%;
  height: 100%;
  overflow: auto;
`

const DatasetColum = React.forwardRef((props, ref) => {
  const {
    width,
    title,
    height,
    format,
    stageRef,
    aggregate,
    datasetColumn,
    ...rest
  } = props
  const divRef = useRef()
  const [rawValue, setRawValue] = useState("NaN")
  const [columnConfig, setColumnConfig] = useState({})

  const query = useMemo(() => {
    const { datasetId, ..._columnConfig } = datasetColumn || {}
    const select =
      _columnConfig.column && _columnConfig.type ? [_columnConfig] : []
    return { datasetId, select }
  }, [datasetColumn])

  const onCompleted = useCallback(
    ({ data }) => {
      const value = data[0]?.rows[0]
      const safeColumnConfig = findColumnConfig(datasetColumn, data[0]?.columns)
      setColumnConfig(safeColumnConfig)
      setRawValue(value)
    },
    [datasetColumn]
  )

  const { loading } = useDatasetData({ query, onCompleted })

  useEffect(() => {
    setTimeout(() => {
      if (!divRef.current) return
      const child = divRef.current.parentElement
      const parent = stageRef.current.container()
      parent.removeChild(child)
      stageRef.current.content.prepend(child)
    }, 50)
  }, [stageRef])

  const formatter = useCallback(
    value => {
      return formatColumnValue(columnConfig, value)
    },
    [columnConfig]
  )

  return (
    <Group ref={ref} width={width} height={height} {...rest}>
      <Rect
        fill="red"
        width={width}
        height={height}
        cornerRadius={5}
        preventDefault={false}
        globalCompositeOperation="destination-out"
      />
      <Html
        divProps={{
          style: {
            overflow: "hidden",
            zIndex: 0,
            position: "absolute",
            width: `${width}px`,
            height: `${height}px`,
          },
        }}
      >
        <HoistedDiv ref={divRef}>
          <Statistic
            title={title}
            value={rawValue}
            loading={loading}
            formatter={formatter}
          />
        </HoistedDiv>
      </Html>
    </Group>
  )
})

export default DatasetColum
