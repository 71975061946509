import { toast } from "react-toastify"
import { createSlice } from "@reduxjs/toolkit"

import { loadResource, createResourceActions } from "@dbai/tool-box"

import { serversAPI } from "lib/hub/api/rest"

const initialState = {
  data: {},
  loading: false,
  error: null,
}

const workflowsSlice = createSlice({
  name: "notebooks",
  initialState,
  reducers: {
    ...createResourceActions({ initialState }),
  },
})

const { actions } = workflowsSlice

const loadNotebooks = cname =>
  loadResource({
    fetchFn: () => serversAPI.all(cname).then(r => r.servers),
    actions,
  })

const stopNotebook =
  (cname, serverName, includeMessage) => (dispatch, getState) => {
    const {
      notebooks: { data = {} },
    } = getState()
    const isRunning = data[serverName]
    if (!isRunning) return

    if (includeMessage) {
      toast.info("Stopping Workflow")
    }

    serversAPI
      .stop(cname, serverName)
      .then(() => dispatch(loadNotebooks(cname)))
      .catch(err => {
        if (includeMessage) {
          const content = err.toString().split("\n")[0]
          toast.error(content)
        }
        console.error(err)
      })
  }

const allActions = {
  loadNotebooks,
  stopNotebook,
  ...actions,
}

export { allActions as actions }
export default workflowsSlice
