import React, { useState } from "react"
import { faCopy } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "antd"

import { ApiForm, SidePane, Input } from "@dbai/ui-staples"

import { CLONE_WORKFLOW } from "queries"

const CloneFormatter = props => {
  const { refetchQueries, customerId, row } = props

  const [showClone, setShowClone] = useState(false)
  const initial = {
    name: `${row.name} (clone)`,
    id: row.id,
    customerId: customerId,
  }

  return (
    <>
      <Button className="btn-icon" onClick={() => setShowClone(true)}>
        <FontAwesomeIcon icon={faCopy} size="lg" />
      </Button>

      <SidePane
        isVisible={showClone}
        title="Clone Workflow"
        width="30vw"
        onCloseClicked={() => setShowClone(false)}
      >
        <ApiForm
          refetchQueries={refetchQueries}
          mutation={CLONE_WORKFLOW}
          afterSubmit={() => setShowClone(false)}
          initialState={initial}
        >
          <Input name="name" />
        </ApiForm>
      </SidePane>
    </>
  )
}
export default CloneFormatter
