const rectShapeSchema = {
  metadata: {
    layout: { gutter: [16] },
    sections: {
      style: {
        title: "Shape Styles",
        properties: [
          "opacity",
          "fill",
          "shadowColor",
          "shadowBlur",
          "shadowOpacity",
        ],
      },
    },
  },
  properties: {
    width: {
      title: "Width",
      type: "number",
      default: 50,
    },
    height: {
      title: "Height",
      default: 50,
      type: "number",
    },
    fill: {
      title: "Fill",
      type: ["string", "null"],
      nullable: true,
      default: "black",
      metadata: {
        component: "ColorPicker",
        fieldProps: {
          placeholder: "None",
        },
      },
    },
    opacity: {
      title: "Opacity",
      type: ["number", "null"],
      minimum: 0,
      maximum: 1,
      nullable: true,
      metadata: {
        component: "SliderInput",
        fieldProps: {
          step: 0.1,
          placeholder: "None",
        },
      },
    },
    shadowColor: {
      title: "Shadow Color",
      type: "string",
      nullable: true,
      metadata: {
        component: "ColorPicker",
        fieldProps: {
          placeholder: "None",
        },
      },
    },
    shadowBlur: {
      title: "Shadow Blur",
      type: ["number", "null"],
      minimum: 0,
      maximum: 100,
      nullable: true,
      metadata: {
        fieldProps: {
          placeholder: "None",
        },
      },
    },
    shadowOpacity: {
      title: "Shadow Opacity",
      type: ["number", "null"],
      minimum: 0,
      maximum: 1,
      nullable: true,
      metadata: {
        fieldProps: {
          step: 0.1,
          placeholder: "None",
        },
      },
    },
  },
}

export default rectShapeSchema
