import React, { useState, useMemo } from "react"
import dayjs from "dayjs"
import { toast } from "react-toastify"
import { useSelector, useDispatch } from "react-redux"

import { Form, Input, Stepper } from "@dbai/ui-staples"

import Presets from "./Presets"
import SearchPackages from "./SearchPackages"
import { actions } from "reducers/scheduleReducer"
import CronControls, { StyledSelect } from "./CronControls"
import {
  selectDescription,
  selectCrontab,
  selectHours,
  selectMinutes,
  selectDoM,
  selectDoW,
  selectMonth,
} from "selectors/schedule"

const getTzOptions = () => {
  const now = dayjs()
  return Intl.supportedValuesOf("timeZone").map(timezone => ({
    label: `${timezone} ${now.tz(timezone).format("z")} (${now.format("Z")})`,
    value: timezone,
  }))
}

const hourLabels = [
  "00 AM",
  "01 AM",
  "02 AM",
  "03 AM",
  "04 AM",
  "05 AM",
  "06 AM",
  "07 AM",
  "08 AM",
  "09 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "01 PM",
  "02 PM",
  "03 PM",
  "04 PM",
  "05 PM",
  "06 PM",
  "07 PM",
  "08 PM",
  "09 PM",
  "10 PM",
  "11 PM",
]

const dowLabels = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
]

const monthLabels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const dowOptions = dowLabels.map((label, value) => ({
  label,
  value: { value: String(value), step: null },
}))

const monthOptions = monthLabels.map((label, value) => ({
  label,
  value: { value: String(value + 1), step: null },
}))

const domOptions = [...Array(31).keys()].map((_, idx) => ({
  label: String(idx + 1),
  value: { value: String(idx + 1), step: null },
}))

const CronSelect = props => {
  const { selector, options, ...rest } = props
  const value = useSelector(selector)

  // Hmmm... This really feels like a hack to me, but I couldn't get
  // react-select to pick up on the changing value if the object identities
  // were not the same. This takes the value from redux and finds the values
  // in react-select. Those "real" values then get passed to the select box,
  // which correctly detects and displays the selection.
  //
  // I know I'm not the first person to hit this, but I couldn't figure out
  // the "right" way.
  const realValue = useMemo(() => {
    const selected = value.map(v => v.value)
    return options
      .filter(o => selected.includes(o.value.value))
      .map(o => o.value)
  }, [value, options])

  return <StyledSelect value={realValue} options={options} {...rest} />
}

const NewSchedule = ({ customer, afterCreate }) => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(0)
  const state = useSelector(state => state.schedule)
  const isPkgSelected = useSelector(state => !!state.schedule.packageId)

  const description = useSelector(selectDescription)
  const crontab = useSelector(selectCrontab)

  const next = () => setStep(s => s + 1)
  const back = () => setStep(s => s - 1)

  const handleSubmit = () => {
    const action = actions.createSchedule({
      variables: { customerId: customer.id },
    })

    dispatch(action)
      .then(() => afterCreate && afterCreate())
      .then(() => toast.success("Created Schedule"))
  }

  return (
    <Form
      state={state}
      dispatch={dispatch}
      actions={actions}
      onSubmit={handleSubmit}
      hideSubmit
    >
      <Stepper steps={2} current={step}>
        <Stepper.Back onClick={back} />
        <Stepper.Next onClick={next} disabled={!isPkgSelected} />
      </Stepper>

      <Stepper.Step step={0} current={step}>
        <SearchPackages customer={customer} />
      </Stepper.Step>

      <Stepper.Step step={1} current={step}>
        <Input name="" noLabel value={description} disabled />
        <Input name="" noLabel value={crontab} disabled />

        <Presets />

        <CronControls
          cronField="hour"
          labels={hourLabels}
          label="Hour of the Day"
          selector={selectHours}
        />

        <CronControls
          cronField="minute"
          max={59}
          label="Minute of the Hour"
          selector={selectMinutes}
        />

        <CronControls
          cronField="dom"
          name="Day of the Month"
          addText="Add Day of the Month to Run on"
          min={1}
          max={31}
          options={domOptions}
          selector={selectDoM}
          isMulti
        />

        <CronSelect
          name="spec.month"
          label="Month"
          placeholder="Every Month"
          options={monthOptions}
          selector={selectMonth}
          isMulti
        />

        <CronSelect
          name="spec.dow"
          label="Day of the Week"
          placeholder="Every Day"
          options={dowOptions}
          selector={selectDoW}
          isMulti
        />
        <StyledSelect
          name="spec.timezone"
          label="Timezone"
          options={getTzOptions()}
        />
      </Stepper.Step>
    </Form>
  )
}

export default NewSchedule
