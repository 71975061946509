// Needed for FireFox.
if (!Element.prototype.scrollIntoViewIfNeeded) {
  function scrollIntoViewIfNeeded(optCenter) {
    const { top, bottom } = this.getBoundingClientRect()
    const winHeight =
      window.innerHeight || document.documentElement.clientHeight
    const isInView = top >= 0 && bottom <= winHeight
    const callOpts = optCenter ? { block: "center" } : true

    if (!isInView) this.scrollIntoView(callOpts)
  }

  Element.prototype.scrollIntoViewIfNeeded = scrollIntoViewIfNeeded
}
