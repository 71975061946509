const getConfig = origin => {
  if (window.config) return window.config

  const gqlDevOverride = process.env.REACT_APP_API_URL
    ? `${process.env.REACT_APP_API_URL}graphql`
    : null

  return {
    // Development.
    "http://localhost": {
      graphql: gqlDevOverride || "http://localhost:1092/graphql",
      api: process.env.REACT_APP_API_URL || "http://localhost:1092/",
      yjs: process.env.REACT_APP_YJS_URL || "ws://localhost:1337",
      secure: false,
      docs: "https://docs.deltabravo.ai",
      env: "development",
      datalakeEncryptionKey:
        process.env.REACT_APP_DATALAKE_ENCRYPTION_KEY || "ZH7!**eZzvwgLEMC",
      tinyMCEApiKey:
        process.env.REACT_APP_TINYMCE_API_KEY ||
        "auib9rlmo41zgv6stlz32jpt4n9jjn5rfzcauuy9b6fxvrwo",

      jupyterhub: {
        host: process.env.REACT_APP_JUPYTERHUB_URL || "localhost:8888",
        secure: false,
        includeCredentials: true,
      },
      sentry: {
        dsn:
          process.env.REACT_APP_SENTRY_DSN ||
          "https://xxx@xxx.ingest.sentry.io/xxx",
      },
    },

    "http://localhost:3000": {
      graphql: gqlDevOverride || "http://localhost:1092/graphql",
      api: process.env.REACT_APP_API_URL || "http://localhost:1092/",
      yjs: process.env.REACT_APP_YJS_URL || "ws://localhost:1337",
      secure: false,
      docs: "https://docs.deltabravo.ai",
      env: "development",
      datalakeEncryptionKey:
        process.env.REACT_APP_DATALAKE_ENCRYPTION_KEY || "ZH7!**eZzvwgLEMC",
      tinyMCEApiKey:
        process.env.REACT_APP_TINYMCE_API_KEY ||
        "auib9rlmo41zgv6stlz32jpt4n9jjn5rfzcauuy9b6fxvrwo",

      jupyterhub: {
        host: process.env.REACT_APP_JUPYTERHUB_URL || "localhost:8888",
        secure: false,
        includeCredentials: true,
      },
      sentry: {
        dsn:
          process.env.REACT_APP_SENTRY_DSN ||
          "https://xxx@xxx.ingest.sentry.io/xxx",
      },
    },

    "http://localhost:3010": {
      graphql:
        gqlDevOverride || "https://api.aws-staging.deltabravo.ai/graphql",
      api:
        process.env.REACT_APP_API_URL ||
        "https://api.aws-staging.deltabravo.ai/",
      yjs:
        process.env.REACT_APP_YJS_URL ||
        "wss://ko-op.aws-staging.deltabravo.ai",
      secure: false,
      docs: "https://docs.deltabravo.ai",
      env: "development",
      datalakeEncryptionKey:
        process.env.REACT_APP_DATALAKE_ENCRYPTION_KEY || "w^cr$k%cn39dbo3c",
      tinyMCEApiKey:
        process.env.REACT_APP_TINYMCE_API_KEY ||
        "auib9rlmo41zgv6stlz32jpt4n9jjn5rfzcauuy9b6fxvrwo",

      jupyterhub: {
        host:
          process.env.REACT_APP_JUPYTERHUB_URL ||
          "hub.aws-staging.deltabravo.ai",
        secure: true,
        includeCredentials: false,
      },
      sentry: {
        dsn:
          process.env.REACT_APP_SENTRY_DSN ||
          "https://xxx@xxx.ingest.sentry.io/xxx",
      },
    },
  }[origin]
}

export default getConfig(window.location.origin)
