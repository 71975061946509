import React from "react"
import styled from "styled-components"

import { Card, Form, Input } from "@dbai/ui-staples"

import DataLakeModal from "./shared/DataLakeModal"

const DataLakeModalForm = styled(Form)`
  width: 200px;
`

const DirectoryForm = props => {
  const { handleSubmit, isOpen, closeModal } = props
  // Chonky hotkeys trigger off of keydown, which happens before change
  // events. Stop the propagation so that Chonky doesn't cancel keystrokes
  // when typing in the directory name input field.
  const runInterferenceForChonky = e => e.stopPropagation()

  return (
    <DataLakeModal isOpen={isOpen} onRequestClose={closeModal}>
      <Card>
        <Card.Header>New Directory</Card.Header>
        <DataLakeModalForm
          name="newDirectory"
          submitText="Create"
          onSubmit={handleSubmit}
        >
          <Input onKeyDown={runInterferenceForChonky} name="directoryName" autoFocus />
        </DataLakeModalForm>
      </Card>
    </DataLakeModal>
  )
}

export default DirectoryForm
