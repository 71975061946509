import React from "react"
import styled from "styled-components"

import { Card, SidePane } from "@dbai/ui-staples"

// Reset the SidePane styles to remove styles inherited from the h3.
const StyledSidePane = styled(SidePane)`
  font-weight: initial;
  font-size: initial;
  text-transform: none;
  line-height: initial;
`

const KBD = styled.div`
  display: inline-block;
  padding: 3px 5px;
  font: 11px SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  line-height: 10px;
  color: var(--color-auto-gray-7);
  vertical-align: middle;
  background-color: var(--color-bg-secondary);
  border-bottom-color: var(--color-border-tertiary);
  border: 1px solid var(--color-border-tertiary);
  border-radius: 6px;
  box-shadow: inset 0 -1px 0 var(--color-border-tertiary);
`

const CardStyled = styled(Card)`
  margin-bottom: 1em;
`

const StyledRow = styled.div`
  justify-content: space-between;
`

const WorkflowHelpButton = props => {
  const { panelOpen, closePanel } = props

  return (
    <>
      Controls
      <StyledSidePane
        title="Controls"
        isVisible={panelOpen}
        width="30vw"
        onCloseClicked={closePanel}
        mask
      >
        <WorkflowHelpContent />
      </StyledSidePane>
    </>
  )
}

const GRAPH_HELP = [
  { keyCombo: "left click", description: "Select node/edge" },
  { keyCombo: "left drag", description: "Move node" },
  { keyCombo: "delete", description: "Delete selected node/edge" },
  { keyCombo: "backspace", description: "Delete selected node/edge" },
  { keyCombo: "shift+left click", description: "Add a new node" },
  { keyCombo: "shift+left drag", description: "Drag to connect nodes" },
]

const INSERT_MODE_HELP = [
  { keyCombo: "esc", description: "Switch to Command Mode" },
]

const COMMAND_MODE_HELP = [
  { keyCombo: "enter", description: "Switch to Insert Mode" },
  { keyCombo: "i", description: "Switch to Insert Mode" },
  { keyCombo: "j", description: "Move up a cell" },
  { keyCombo: "k", description: "Move down a cell" },
  { keyCombo: "↑", description: "Move up a cell" },
  { keyCombo: "↓", description: "Move down a cell" },
  { keyCombo: "a", description: "Insert a cell above" },
  { keyCombo: "b", description: "Insert a cell below" },
  { keyCombo: "d", description: "Delete cell" },
  { keyCombo: "f", description: "Open Code Search Modal" },
  { keyCombo: "/", description: "Open Code Search Modal" },
  { keyCombo: "n", description: "Go To Next Search Result" },
  { keyCombo: "shift n", description: "Go To Previous Search Result" },
  { keyCombo: "ctrl /", description: "Comment selection" },
  { keyCombo: "cmd /", description: "Comment selection" },
  { keyCombo: "shift backspace", description: "Delete cell" },
  { keyCombo: "ctrl enter", description: "Execute cell, don't move to next cell" },
]

const WorkflowHelpContent = () => {
  return (
    <div className="help-content">
      <CardStyled title="Graph Actions" nopad>
        <div className="list-group list-group-flush list-group-hoverable">
          {GRAPH_HELP.map(help => (
            <div className="list-group-item" key={help.keyCombo}>
              <StyledRow className="row">
                <div className="col-auto">
                  <KBD>{help.keyCombo}</KBD>
                </div>
                <div className="col-auto">{help.description}</div>
              </StyledRow>
            </div>
          ))}
        </div>
      </CardStyled>
      <CardStyled title="Insert Mode" nopad>
        <div className="list-group list-group-flush list-group-hoverable">
          {INSERT_MODE_HELP.map(help => (
            <div className="list-group-item" key={help.keyCombo}>
              <StyledRow className="row">
                <div className="col-auto">
                  <KBD>{help.keyCombo}</KBD>
                </div>
                <div className="col-auto">{help.description}</div>
              </StyledRow>
            </div>
          ))}
        </div>
      </CardStyled>
      <CardStyled title="Command Mode" nopad>
        <div className="list-group list-group-flush list-group-hoverable">
          {COMMAND_MODE_HELP.map(help => (
            <div className="list-group-item" key={help.keyCombo}>
              <StyledRow className="row">
                <div className="col-auto">
                  <KBD>{help.keyCombo}</KBD>
                </div>
                <div className="col-auto">{help.description}</div>
              </StyledRow>
            </div>
          ))}
        </div>
      </CardStyled>
    </div>
  )
}

export default WorkflowHelpButton
