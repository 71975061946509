import { curry } from "lodash"

import { filterOnLegendItemClickMap } from "./utils"
import { formatColumnValue } from "../../../lib/datasetColumns"

const valueLabelsFormatter = format =>
  function () {
    const datasetColumn = this.series.userOptions?.custom?.datasetColumn || {}
    switch (format) {
      case "percent":
        return `${this.percentage.toFixed(1)}%`
      case "value/percent":
        return `${formatColumnValue(
          datasetColumn,
          this.y
        )} (${this.percentage.toFixed(1)}%)`
      case "value":
      default:
        return formatColumnValue(datasetColumn, this.y)
    }
  }

const getColumnPlotOptions = plotOptions => {
  const { dataLabels = {} } = plotOptions || {}
  const { enabled, format } = dataLabels
  return {
    ...plotOptions,
    dataLabels: {
      enabled,
      formatter: valueLabelsFormatter(format),
    },
  }
}

const getPiePlotOptions = plotOptions => {
  const { dataLabels, ...rest } = plotOptions || {}
  return rest
}

const getPieSeriesPlotOptions = options => {
  const { chart, plotOptions = {} } = options
  if (chart?.type !== "pie") return {}
  const { dataLabels = {} } = plotOptions.pie || {}
  const { enabled, valueLabels = "", distance = -15 } = dataLabels
  const primaryLabels = enabled ? [{ enabled, distance: 30 }] : []
  const additionalLabels =
    valueLabels !== ""
      ? [
          {
            enabled: true,
            distance: `${distance}%`,
            formatter: valueLabelsFormatter(valueLabels),
            filter: {
              property: "percentage",
              operator: ">",
              value: 2,
            },
            style: {
              fontSize: "0.9em",
              textOutline: "none",
            },
          },
        ]
      : []

  return {
    dataLabels: [...primaryLabels, ...additionalLabels],
  }
}

const getCustomChartOptions = options => {
  return {
    pageId: options.pageId,
    widgetId: options.widgetId,
  }
}

const constructPlotOptions = curry((selectPoints, crossFilters, options) => {
  const { plotOptions = {}, highchart } = options || {}
  return {
    ...options,
    highchart: {
      ...highchart,
      plotOptions: {
        ...plotOptions,
        pie: {
          ...getPiePlotOptions(plotOptions.pie),
          point: {
            events: {
              legendItemClick: filterOnLegendItemClickMap.pie(
                selectPoints,
                crossFilters
              ),
            },
          },
        },
        column: getColumnPlotOptions(plotOptions.column),
        series: {
          ...getPieSeriesPlotOptions(options),
          custom: getCustomChartOptions(options),
          events: {
            legendItemClick: filterOnLegendItemClickMap.default(
              selectPoints,
              crossFilters
            ),
          },
        },
      },
    },
  }
})

export default constructPlotOptions
