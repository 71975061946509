import * as Sentry from "@sentry/browser"

import config from "./config"
export default config

const { sentry, env } = config
const localLogger = {
  notify: console.error,
}

let hasLoggedWarning = false
const warnNoSentry = () => {
  if (hasLoggedWarning) return
  console.warn(
    `No sentry config is defined for the current environment; ${env}`
  )
  hasLoggedWarning = true
}

/*
 * Inits our error tracking solution. If we are in development we don't
 * actually want to spam errors to Sentry, so use good old console.error.
 * Otherwise, use Sentry.captureException.
 */
export const initLogger = () => {
  if (env === "development") return localLogger

  if (!sentry) {
    warnNoSentry()
    return localLogger
  }

  return { notify: Sentry.captureException }
}
