import React, { useRef } from "react"
import PropTypes from "prop-types"

import useIsOnScreen from "../hooks/useIsOnScreen"

/*
 * Simple component that renders it's children only if it is located within
 * the viewport currently.
 * Props:
 * `preserve`: Keep the element rendered on the page after it's passed into
 * the viewport for the first time.
 * `placeHolderStyle`: Styles to be applied to the place holder. Primarily
 * intended to allow for sizing and thus reduce page "jump".
 */
const VisibilityGuard = props => {
  const { children, preserve, observerOptions, placeHolderStyle = {} } = props
  const ref = useRef()
  const isOnScreen = useIsOnScreen(ref, { preserve, observerOptions })

  if (isOnScreen) {
    return (
      <>
        <span ref={ref} />
        {children}
      </>
    )
  }

  return <div ref={ref} style={placeHolderStyle} />
}

VisibilityGuard.propTypes = {
  preserve: PropTypes.bool,
  placeHolderStyle: PropTypes.object,
}

export default VisibilityGuard
