import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react"
import PropTypes from "prop-types"

import withCustomer from "components/shared/withCustomer"
import { serversAPI, kernelsAPI, retry } from "lib/hub/api/rest"

import { makeKernel, closeWSCode } from "./kernel"

const KernelContext = createContext()

const useKernel = () => useContext(KernelContext)

const getNamedServer = (cname, name) => {
  return serversAPI.all(cname).then(result => result.servers[name] || null)
}

const checkErrorReason = error => {
  return error.json().then(jsonError => {
    if (jsonError.message?.match("is already running")) return
    throw jsonError
  })
}

const ensureServerExists = (cname, name, spawnParams) => server => {
  if (server && server.pending !== "stop") {
    return server
  }

  const getServer = () => getNamedServer(cname, name)
  return serversAPI
    .create(cname, name, spawnParams)
    .then(getServer)
    .catch(checkErrorReason)
}

const provisionServer = (cname, name, spawnParams) => {
  return getNamedServer(cname, name).then(
    ensureServerExists(cname, name, spawnParams)
  )
}

const ensureKernelExists = (kernelsAPI, cname, serverName) => result => {
  const kernel = result[0]

  if (kernel) return kernel
  return kernelsAPI.create(cname, serverName)
}

const provisionKernel =
  (kernelsAPI, cname) =>
  ({ name: serverName }) => {
    return kernelsAPI
      .all(cname, serverName)
      .then(ensureKernelExists(kernelsAPI, cname, serverName))
      .then(kernel => getSocketAndKernel(kernel, kernelsAPI, cname, serverName))
  }

const getSocketAndKernel = (kernel, kernelsAPI, cname, serverName) => {
  return kernelsAPI.channels(cname, serverName, kernel.id).then(ws => ({
    ws,
    kernel,
  }))
}

const KernelProvider = props => {
  const { customer, name, spawnParams } = props
  const cname = customer.normalizedName

  const sockRef = useRef(null)
  const kernelRef = useRef(null)
  const initialLoadRef = useRef(true)

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const removeLoadingStatus = () => setLoading(false)
  const setNoError = () => setError(null)

  const establishConnection = useCallback(
    force => {
      const updateContext = ({ ws, kernel }) => {
        sockRef.current = ws
        window.sock = ws
        kernelRef.current = makeKernel(
          ws,
          cname,
          name,
          kernel,
          kernelsAPI,
          reconnect
        )
      }

      const retryOp = retry(provisionServer, { max: 180, incremental: false })

      const reconnect = () => {
        sockRef.current = null
        window.sock = null
        setLoading(true)
        // If the hub is slow starting the notebook an incremental backoff can
        // lead to a long wait for the user even after server provisioning is
        // available.

        retryOp
          .execute(cname, name, spawnParams)
          .then(() =>
            retry(provisionKernel(kernelsAPI, cname), {
              max: 180,
              incremental: false,
            }).execute({ name })
          )
          .then(updateContext)
          .then(setNoError)
          .catch(setError)
          .finally(removeLoadingStatus)

        return retryOp.cancel
      }

      if (initialLoadRef.current === true || force === true) {
        initialLoadRef.current = false
        setLoading(true)

        retryOp
          .execute(cname, name, spawnParams)
          .then(
            retry(provisionKernel(kernelsAPI, cname), {
              max: 180,
              incremental: false,
            }).execute
          )
          .then(updateContext)
          .then(setNoError)
          .catch(setError)
          .finally(removeLoadingStatus)

        return retryOp.cancel
      }
    },
    [cname, name, spawnParams]
  )

  useEffect(() => {
    return establishConnection()
  }, [establishConnection])

  useEffect(() => {
    return () => {
      if (sockRef.current?.readyState === 1) sockRef.current.close(closeWSCode)
      kernelRef.current = null
    }
  }, [])

  const restartServer = () => {
    sockRef.current && sockRef.current.close(closeWSCode)
    serversAPI.stop(cname, name).finally(() => {
      establishConnection(true)
    })
  }

  const value = {
    error,
    loading: loading || sockRef?.current?.readyState !== 1,
    kernel: kernelRef.current,
    restartServer,
  }

  return (
    <KernelContext.Provider value={value}>
      {props.children}
    </KernelContext.Provider>
  )
}

KernelProvider.propTypes = {
  name: PropTypes.string.isRequired,
}

export { useKernel }
export default withCustomer(KernelProvider)
