import styled, { css } from "styled-components"

import { DBAI_NODE_CLASSNAME } from "../../lib/tinyMce"

const focusCss = css`
  *[contentEditable="true"]::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none; /* Allows interaction with the content below the box-shadow */
    z-index: 1000; /* Positions the box-shadow above the content */
  }
`

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .${DBAI_NODE_CLASSNAME} {
    color: var(--conditional-color, inherit);
    display: var(--conditional-display, unset);
    background-color: var(--conditional-background-color, inherit);

    :hover {
      ${({ theme }) => {
        return `
          background-color: ${theme.colorFill};
        `
      }}
    }
  }

  ${props => {
    const { vPosition } = props
    return `
    ${vPosition ? `justify-content:  ${vPosition};` : ""}
  `
  }}

  ${props => {
    if (props.theme.colorText) {
      return `
    color: ${props.theme.colorTextBase};
    `
    }
  }}
  p {
    margin-bottom: unset;
  }
  *[contentEditable="true"]:hover,
  *[contentEditable="true"]:focus {
    ::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      pointer-events: none; /* Allows interaction with the content below the box-shadow */
      z-index: 1000; /* Positions the box-shadow above the content */
      height: ${props => (props.height ? `${props.height}px` : "auto")};
    }
  }
  .mce-content-body {
    padding: 5px 7px;
  }

  ${({ editable }) => editable && focusCss}
`

export default TextContainer
