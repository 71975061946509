import React, { useCallback, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
import { Row, Col } from "antd"
import { useDispatch } from "react-redux"

import AddRow from "./AddRow"
import EditableRow from "./EditableRow"
import { actions } from "../../reducers/appReducer"
import { useDeleteRow } from "./hooks"
import EditableTableCell from "./EditableCell"
import useDatasetColumns from "../../hooks/useDatasetColumns"
import { useWidgetContext, useDatasetData } from "../../hooks"
import { getPosition, getTablePaginationProps } from "../../lib/tablePagination"
import {
  getDataSource,
  getTableColumns,
  getSafeTableProps,
  getOperationColumn,
  getDataSourceQuery,
  generateDatasetJSONSchema,
} from "./utils"
import StyledTable from "./StyledTable"

const StyledRow = styled(Row)`
  width: 100%;
  align-content: flex-start;
`

const fullWidthStyle = {
  width: "100%",
}

const tableComponents = {
  body: {
    row: EditableRow,
    cell: EditableTableCell,
  },
}

const columnsChanged = (nextColumns, prevColumns) => {
  return (
    nextColumns.length !== prevColumns.length ||
    nextColumns.reduce((acc, column) => {
      const columnOption = prevColumns.find(
        option => option.column === column.column
      )
      return acc || !columnOption
    }, false)
  )
}

const getColumnOptions = (nextColumns, prevColumns) => {
  // sync dataset columns with column options:
  // 1. remove column options that are not part of the dataset columns
  // 2. add dataset columns that are not part of the column options
  return nextColumns.map(column => {
    const columnOption = prevColumns.find(
      option => option.column === column.name
    )
    if (columnOption) {
      return columnOption
    }
    return {
      hidden: false,
      type: column.type,
      column: column.name,
      dataIndex: column.name,
    }
  })
}

const DataEditor = props => {
  const { widget, pageId } = props
  const { options = {} } = widget
  const {
    table,
    addRow,
    datasetId,
    showRowActions,
    columns: columnOptions = [],
  } = options
  const dispatch = useDispatch()
  const { editable } = useWidgetContext()
  const [data, setData] = useState([])
  const [tableData, setTableData] = useState([])
  const [loadingDataResponse, setLoadingDataResponse] = useState(false)

  const {
    columns,
    uniqueKeys,
    loading: loadingColumns,
  } = useDatasetColumns({ datasetId })

  const [queryParams, setQueryParams] = useState({
    sort: [],
    filters: [],
    pagination: options.table.pagination,
  })

  const query = useMemo(() => {
    return getDataSourceQuery(datasetId, queryParams, columnOptions)
  }, [datasetId, columnOptions, queryParams])

  const handleDataResponse = useCallback(data => {
    setLoadingDataResponse(true)
    setData(data.data)
  }, [])

  const {
    error,
    dataset,
    refetch,
    loading: loadingData,
  } = useDatasetData({
    query,
    pageId,
    widgetId: widget.id,
    widgetType: widget.type,
    fetchPolicy: "cache-and-network",
    onCompleted: handleDataResponse,
  })

  const handleDeleteRow = useDeleteRow(uniqueKeys, datasetId, refetch)

  const tableColumns = useMemo(() => {
    const cols = getTableColumns(uniqueKeys, columnOptions) || []
    const actions = showRowActions ? [getOperationColumn(handleDeleteRow)] : []
    return [...cols, ...actions]
  }, [uniqueKeys, showRowActions, handleDeleteRow, columnOptions])

  // side effect to synchronize widget with the current dataset-level column options
  useEffect(() => {
    if (editable && columns?.length) {
      const prevColumns = options.columns
      const nextColumns = getColumnOptions(columns, prevColumns)
      if (columnsChanged(nextColumns, prevColumns)) {
        dispatch(
          actions.setWidgetOptionsWithSync({
            value: { columns: nextColumns },
            widgetId: widget.id,
          })
        )
      }
    }
  }, [dispatch, editable, columns, widget.id, options.columns])

  useEffect(() => {
    if (!error && !loadingData && !loadingColumns && loadingDataResponse) {
      const dataSource = getDataSource(
        data[0],
        tableColumns,
        queryParams.pagination.current || 1
      )
      setTableData(dataSource)
      setLoadingDataResponse(false)
    }
  }, [
    data,
    error,
    loadingData,
    tableColumns,
    loadingColumns,
    loadingDataResponse,
    queryParams.pagination,
  ])

  const rowSchema = useMemo(() => {
    return generateDatasetJSONSchema(false, datasetId, uniqueKeys, columns)
  }, [uniqueKeys, columns, datasetId])

  const addRowSchema = useMemo(() => {
    return generateDatasetJSONSchema(true, datasetId, uniqueKeys, columns)
  }, [uniqueKeys, columns, datasetId])

  const memoizedTableProps = useMemo(() => {
    const { pagination, ...tableProps } = getSafeTableProps(table)
    return tableProps
  }, [table])

  const paginationOptions = useMemo(() => {
    const pagination = getTablePaginationProps(table)
    return {
      ...pagination,
      total: dataset?.meta?.count,
      current: queryParams.pagination.current,
      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
      position: getPosition(pagination.verticalPosition, pagination.alignment),
    }
  }, [table, queryParams.pagination, dataset])

  const updateQueryParams = useCallback(
    (pagination, filters, sort) => {
      setQueryParams({
        sort,
        filters,
        pagination,
      })
    },
    [setQueryParams]
  )

  const passRowProps = useCallback(
    record => {
      return {
        record,
        columns,
        datasetId,
        rowSchema,
        tableColumns,
        widgetId: widget.id,
      }
    },
    [tableColumns, columns, rowSchema, datasetId, widget.id]
  )

  return (
    <StyledRow gutter={[0, 8]}>
      {addRow ? (
        <Col span={24}>
          <AddRow
            schema={addRowSchema}
            columns={columns}
            refetch={refetch}
            datasetId={datasetId}
          />
        </Col>
      ) : null}
      <Col span={24} style={fullWidthStyle}>
        <StyledTable
          onRow={passRowProps}
          columns={tableColumns}
          loading={loadingColumns || loadingData || loadingDataResponse}
          onChange={updateQueryParams}
          dataSource={tableData}
          components={tableComponents}
          pagination={paginationOptions}
          {...memoizedTableProps}
        />
      </Col>
    </StyledRow>
  )
}

export default React.memo(DataEditor)
