import { formSchema } from "@dbai/applet"

export const widgetTypeSourceSchema = {
  type: "object",
  default: {},
  properties: {
    "source/core": {
      title: "Source",
      type: "string",
      default: `<!DOCTYPE html>

<!-- NOTE: your custom widget will render to this div element -->
<div id="widget"></div>

<script src="https://unpkg.com/react@18/umd/react.development.js" crossorigin></script>
<script src="https://unpkg.com/react-dom@18/umd/react-dom.development.js" crossorigin></script>
<script src="https://unpkg.com/dayjs@1.11.7/dayjs.min.js"></script>
<script src="https://unpkg.com/antd@5.3.1/dist/antd.min.js"></script>
<script src="https://unpkg.com/babel-standalone@6/babel.min.js"></script>

<script type="text/babel">
  const { Typography } = window.antd;
  const { Title } = Typography;
  const MyCustomComponent = (props) => {
    return (
      <Title level={5}>
       Hello World!
      </Title>
    );
  };

  const CustomComponent = window.parent.connectContext(MyCustomComponent);

  const domContainer = document.getElementById("widget");
  const root = ReactDOM.createRoot(domContainer);
  root.render(<CustomComponent />);
</script>
`,
      metadata: {
        component: "CodeEditor",
        noStyle: true,
        showError: true,
        containerStyle: {
          height: "calc(100vh - 214px)",
          overflowY: "auto",
        },
      },
    },
  },
}

export const widgetTypeSchema = {
  title: "Widget Type",
  type: "object",
  default: {},
  properties: {
    name: {
      title: "Name",
      type: "string",
      default: "Custom Widget",
    },
    description: {
      type: "string",
      title: "Description",
      default: "Enter a meaningful description for your widget",
    },
  },
}

// the widget parameters can be described by a form widget schema's properties
export const widgetParametersSchema = {
  ...formSchema,
  metadata: {},
  properties: {
    properties: {
      ...formSchema.properties.properties,
      metadata: {
        hideLabel: true,
        addButtonText: "ADD PARAMETER",
        addButtonProps: {
          block: true,
        },
      },
    },
  },
}
