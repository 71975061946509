import { useEffect, useState, useRef, useCallback } from "react"
import dayjs from "dayjs"

import { useFormState } from "./hooks"

const filterSave = (state, prev) => state === prev

const AutoSave = props => {
  const {
    delay = 2,
    max = 10,
    filter = filterSave,
    save,
    onStatus,
    paused = false,
  } = props

  // Get the current state and track the previous state as well.
  const state = useFormState()
  const [prev, setPrev] = useState(state)

  const [epochStart, setEpochStart] = useState(null)

  // Why useRef? This should always be a *last* resort.
  // The *only* reason for useRef here is so that the timeout can be canceled
  // when the component unmounts.
  const timeout = useRef([])
  const setTimeoutID = useCallback(
    tid => {
      timeout.current.push(tid)
    },
    [timeout]
  )
  const clearTimeoutID = useCallback(() => {
    timeout.current.forEach(id => window.clearTimeout(id))
    timeout.current = []
  }, [timeout])

  useEffect(() => {
    if (filter(state, prev)) {
      return
    }
    if (paused) {
      timeout.current.length && clearTimeoutID()
      return
    }

    if (!epochStart) {
      setEpochStart(dayjs())
      setPrev(state)
      return
    }

    onStatus("notSaved")
    const cutoff = dayjs().subtract(max, "second")

    if (epochStart < cutoff) {
      setEpochStart(dayjs())
    }

    if (epochStart && epochStart > cutoff) {
      timeout.current.length && clearTimeoutID()
    }

    const timeoutFn = () => {
      onStatus("saving")
      save(state)
        .then(() => clearTimeoutID())
        .then(() => {
          onStatus("saved")
        })
        .catch(() => onStatus("errorSaving"))
    }

    setPrev(state)
    setTimeoutID(setTimeout(timeoutFn, delay * 1000))
  }, [state, prev, onStatus, max, epochStart, delay, save, paused, filter])

  // Stop the Timeout *only* when the component unmounts, *not* between
  // renders.
  useEffect(() => {
    return () => {
      clearTimeoutID()
    }
  }, [])

  return null
}

export default AutoSave
