import styled, { css } from "styled-components"

const boxShadowCss = css`
  ::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.3s ease;
    bottom: 0;
    left: 0;
    box-shadow: inset 0 0 0 1px transparent;
    pointer-events: none; /* Allows interaction with the content below the box-shadow */
    z-index: 1000; /* Positions the box-shadow above the content */
  }
`

const outlineCss = css`
  transition: all 0.3s ease;
  ${boxShadowCss}
  :hover {
    ::after {
      box-shadow: inset 0 0 0 1px
        ${props => props.theme.colorTextSecondary || "rgba(0,0,0,0.5)"};
    }
    .${({ containerId }) => containerId}.react-resizable-handle::after,
      .${({ containerId }) => containerId}.react-resizable-handle::before {
      display: block;
    }
  }
  .${({ containerId }) => containerId}.react-resizable-handle::after,
    .${({ containerId }) => containerId}.react-resizable-handle::before {
    display: none;
  }
`

const PageContainer = styled.div`
  min-height: 100%;
  min-width: 100%;

  ${props => {
    const { width } = props
    if (!width) return null
    return `
      width: ${width}px;
    `
  }}

  ${props => {
    const { height } = props
    if (!height) return null
    return css`
      height: ${height}px;
      .react-grid-layout {
        overflow: hidden;
        max-height: ${height}px;
      }
    `
  }}

  ${props => {
    const { backgroundColor, imageSpec } = props
    if (!backgroundColor && !imageSpec?.url) return null
    if (imageSpec?.url)
      return `
        background-size: cover;
        background-repeat: no-repeat;
        box-sizing: border-box;
        background-image: url(${imageSpec.url});   
      `
    if (backgroundColor) {
      //   return `
      //   background-color: ${backgroundColor};
      // `
    }
  }}

  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  .has-drag-over {
    background-color: ${props => {
      return props.theme.colorBgMask || "rgba(0,0,0,0.1)"
    }};
  }
  ${props => {
    const { showGridLines } = props
    if (showGridLines) {
      return `
        background-color: ${props.theme.colorBgBase || "#fff"};
      `
    }
  }}
  .react-grid-layout {
    position: relative;
    transition:
      height 200ms ease,
      background-color 0.3s ease;
    display: flex;
    flex: 1 1 auto;

    ${props => {
      const { showGridLines, backgroundSize } = props
      if (showGridLines && backgroundSize) {
        const gridLineColor = props.theme.colorBorderSecondary || "#eee"
        return `
        background: linear-gradient(to right, ${gridLineColor} 1px, transparent 1px),
          linear-gradient(to bottom, ${gridLineColor} 1px, transparent 1px);
        background-size: ${backgroundSize};
      `
      }
    }}
  }
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
  }
  .react-grid-item.cssTransforms {
    transition-property: transform;
  }
  .react-grid-item.react-draggable {
    ${outlineCss}
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }
  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }
  .react-grid-item.dropping {
    visibility: hidden;
  }

  .react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .react-resizable-handle {
    color: ${props => props.theme.colorText || "#000"};
    background-size: 400px;
  }

  .react-resizable-hide > .react-resizable-handle {
    display: none;
  }
  .react-resizable-hide.react-grid-item.react-draggable {
    transition: all 0.3s ease;
    border: none;
  }
`

export default PageContainer
