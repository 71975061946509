import React from "react"
import { Typography, Empty } from "antd"

const { Title } = Typography

const EmptyPrompt = props => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={<Title level={5}>{props.children}</Title>}
    />
  )
}

export default EmptyPrompt
