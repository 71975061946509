import React from "react"
import { Tooltip, Button } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { faStopCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { ActionsFormatter, useCurrentCustomer } from "@dbai/ui-staples"

import { selectNotebooks } from "selectors"
import CloneFormatter from "./CloneFormatter"
import { actions } from "reducers/notebooksReducer"
import { GET_WORKFLOWS, DELETE_WORKFLOW } from "queries"

const buildWorkflowKey = workflowId => `workflow-${workflowId}`

const NotebookKillSwitch = props => {
  const { row, stopNotebook } = props
  const { data, error } = useSelector(selectNotebooks)
  const workflowKey = buildWorkflowKey(row.id)
  const notebookPending = data[workflowKey]?.pending
  const notebookRunning = data[workflowKey]?.ready && !notebookPending

  if (error || !notebookRunning) {
    return (
      <Tooltip title="Workflow Inactive">
        <Button className="btn-icon">
          <FontAwesomeIcon icon={faStopCircle} size="lg" />
        </Button>
      </Tooltip>
    )
  }

  const handleClick = () => stopNotebook({ row, includeMessage: true })

  return (
    <Tooltip title="Stop Workflow">
      <Button danger onClick={handleClick} className="btn-icon">
        <FontAwesomeIcon icon={faStopCircle} size="lg" />
      </Button>
    </Tooltip>
  )
}

const WorkflowActions = props => {
  const { row } = props
  const dispatch = useDispatch()
  const [{ id: customerId, normalizedName: cname }] = useCurrentCustomer()
  const refetchQueries = [{ query: GET_WORKFLOWS, variables: { cname } }]
  const stopNotebook = ({ row, includeMessage }) => {
    if (!row) return
    const workflowKey = buildWorkflowKey(row.id)
    dispatch(actions.stopNotebook(cname, workflowKey, includeMessage))
  }

  return (
    <ActionsFormatter
      value={row.id}
      row={row}
      editPath={`/${cname}/workflows/`}
      deleteGql={DELETE_WORKFLOW}
      afterDelete={stopNotebook}
      confirmDelete={true}
      variables={{ customerId }}
      refetchQuery={GET_WORKFLOWS}
      refetchVariables={{ cname }}
      leadingActions={
        <CloneFormatter
          refetchQueries={refetchQueries}
          customerId={customerId}
          row={row}
        />
      }
      trailingActions={
        <NotebookKillSwitch row={row} stopNotebook={stopNotebook} />
      }
    />
  )
}

export default WorkflowActions
