import { createSlice } from "@reduxjs/toolkit"
import { createCommonActions, toastAndRethrow } from "@dbai/tool-box"

import { TAG_RESOURCE, UNTAG_RESOURCE } from "../queries/tags"

const initialState = {}

const tagsSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    ...createCommonActions(),
  },
})

const tagResource =
  input =>
  (dispatch, getState, { client }) => {
    const variables = {
      customerId: getState().currentCustomer.customer?.id,
      input,
    }

    return client
      .mutate({ mutation: TAG_RESOURCE, variables })
      .catch(toastAndRethrow("Error tagging resource!"))
  }

const untagResource =
  input =>
  (dispatch, getState, { client }) => {
    const variables = {
      customerId: getState().currentCustomer.customer?.id,
      input,
    }

    return client
      .mutate({ mutation: UNTAG_RESOURCE, variables })
      .catch(toastAndRethrow("Error removing tag!"))
  }

const allActions = {
  tagResource,
  untagResource,
  ...tagsSlice.actions,
}

export { allActions as actions }
tagsSlice.actions = allActions
export default tagsSlice
