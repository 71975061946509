import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Redirect } from "react-router"
import { checkForRole } from "../../lib/userHelpers"
import useCustomerRoles from "../../hooks/useCustomerRoles"

const deny = "DENY"

/*
 * A list of possible different actions to take if a user wanders somewhere
 * that they are unauthorized to be.
 */
const authErrorActions = [
  "hide",
  "warn",
  "redirect",
  PropTypes.shape({
    redirectTo: PropTypes.string,
  }),
]

const AuthErrorHandler = props => {
  const { Warning, onAuthorizationError } = props

  switch (onAuthorizationError) {
    case "warn":
      return <Warning />
    case "hide":
      return null
    case "redirect":
      return <Redirect to="/" />
    default:
      return <Redirect to={`${onAuthorizationError?.redirectTo || "/"}`} />
  }
}

const authErrorPropTypes = PropTypes.oneOf(authErrorActions)

AuthErrorHandler.propTypes = {
  Warning: PropTypes.func,
  onAuthorizationError: authErrorPropTypes,
}

/*
 * Wrap segments of the app with this boundary to ensure that it is only
 * rendered for users who are authorized to acces that segment of the app.
 */
const AuthBoundary = props => {
  const {
    action,
    subject,
    Warning,
    children,
    resourceId,
    onAuthorizationError = "hide",
  } = props
  const customerRoles = useCustomerRoles()
  const hasRole = useMemo(() => {
    const details = { roles: customerRoles, subject, resourceId, action }
    return !checkForRole({ ...details, effect: deny }) && checkForRole(details)
  }, [action, customerRoles, resourceId, subject])

  if (hasRole) {
    return children
  }

  return (
    <AuthErrorHandler
      Warning={Warning}
      onAuthorizationError={onAuthorizationError}
    />
  )
}

/*
 * Eventually we want to allow selection via specific resources.
 */
AuthBoundary.propTypes = {
  // resourceId: PropTypes.string,
  action: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  onAuthorizationError: authErrorPropTypes,
  Warning: (props, propName, componentName) => {
    if (props.onAuthorizationError === "warn" && !props[propName]) {
      return new Error(
        `Invalid props givent to ${componentName}: If "onAuthorizationError" === "warn" then a prop of "${propName}" must be provided`
      )
    }
  },
}

export default AuthBoundary
