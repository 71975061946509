import React from "react"
import { Col, Row } from "antd"
import styled from "styled-components"

import ColumnList from "./ColumnList"
import { orderProperties } from "../utils"
import JSONSchemaField from "../JSONSchemaField"
import FormFieldWrapper from "../FormFieldWrapper"

const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 8px;
  row-gap: 8px;
`

const StyledRow = styled(Row)`
  overflow: hidden;
`

const StyledCol = styled(Col)`
  height: 100%;
  border-right: 1px solid
    ${props => {
      return props.theme?.colorBorder || "#ddd"
    }};
`

const fullHeightStyles = { height: "calc(100vh - 259px)" }
const ColumnInputZonesCore = props => {
  const { schema: _schema, name, path } = props
  const { metadata } = _schema
  const { fieldProps } = metadata || {}
  const { style = fullHeightStyles } = fieldProps || {}

  const orderedProperties = orderProperties(_schema)
  return (
    <StyledRow gutter={[]} style={{ padding: 0 }}>
      <StyledCol xs={24} sm={8} style={style}>
        <ColumnList />
      </StyledCol>
      <Col xs={24} sm={16} style={{ ...style, overflowY: "auto" }}>
        <StyledContainer>
          {orderedProperties.map(([key, subschema]) => {
            return (
              <JSONSchemaField
                noStyle
                key={key}
                path={path}
                name={name}
                validateFirst
                schemaKey={key}
                schema={subschema}
                parentSchema={_schema}
              />
            )
          })}
        </StyledContainer>
      </Col>
    </StyledRow>
  )
}

const ColumnInputZones = props => {
  return (
    <FormFieldWrapper noStyle {...props}>
      <ColumnInputZonesCore />
    </FormFieldWrapper>
  )
}

ColumnInputZones.Core = ColumnInputZonesCore
export default ColumnInputZones
