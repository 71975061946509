import React from "react"
import styled, { useTheme } from "styled-components"

import { alterColor } from "@dbai/tool-box"
import { useCurrentUser } from "@dbai/ui-staples"

const CommentsWrapper = styled.div`
  .bubble-container {
    display: flex;
  }

  .comment-bubble {
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    text-transform: none;
    font-size: 15px;
    margin: 5px;
    padding: 2px 5px;
  }
`

const getMyColor = props => {
  return alterColor(props.theme.background, -10)
}

const MyCommentsWrapper = styled(CommentsWrapper)`
  .user-info {
    text-align: end;
  }

  .bubble-wrapper {
    justify-content: end;
  }

  .bubble-container {
    display: flex;
    justify-content: end;
  }

  .comment-bubble {
    background-color: ${getMyColor};
    border-radius: 10px 0px 10px 10px;
    margin-right: 0px;

    span {
      text-align: right;
      font-size: x-small;
    }
  }

  .right-triangle {
    margin-top: 5px;
    width: 0px;
    height: 0px;
    border-left: 20px solid ${getMyColor};
    border-top: 0px;
    border-bottom: 20px solid transparent;
  }

  .left-triangle {
    width: 0px;
    height: 0px;
    border: 0px;
  }
`

const getTheirColor = props => props.theme.textSecondary
const getTheirTextColor = props => props.theme.background
const TheirCommentsWrapper = styled(CommentsWrapper)`
  .bubble-wrapper {
    justify-content: start;
  }

  .bubble-container {
    display: flex;
    justify-content: start;
  }

  .comment-bubble {
    background-color: ${getTheirColor};
    border-radius: 0px 10px 10px 10px;
    margin-left: 0px;
    color: ${getTheirTextColor};

    span {
      text-align: left;
      font-size: x-small;
    }
  }

  .right-triangle {
    width: 0px;
    height: 0px;
    border: 0px;
  }

  .left-triangle {
    margin-top: 5px;
    width: 0px;
    height: 0px;
    border-right: 20px solid ${getTheirColor};
    border-top: 0px;
    border-bottom: 20px solid transparent;
  }
`

// Now I want pad thai... Dang it.
const padTime = timeInt => String(timeInt).padStart(2, 0)

const TimeStamp = props => {
  const { date, showTime } = props
  if (!showTime) return null

  const timeString = `${padTime(date.getHours())}:${padTime(date.getMinutes())}`
  return <span>{timeString}</span>
}

const BubbleContent = props => {
  const { content, date, showTime } = props
  return (
    <div className="bubble-container">
      <div className="left-triangle" />
      <div className="comment-bubble">
        {content}
        <span>
          <TimeStamp showTime={showTime} date={date} />
        </span>
      </div>
      <div className="right-triangle" />
    </div>
  )
}

const Comment = props => {
  const { userId, userName, content, createdAt, showUser, showTime } = props
  const theme = useTheme()
  const [user] = useCurrentUser()
  const date = new Date(createdAt)
  const isMyComment = userId === user.id
  const CommentsWrapper = isMyComment ? MyCommentsWrapper : TheirCommentsWrapper

  if (showUser) {
    return (
      <CommentsWrapper theme={theme}>
        <div className="user-info">
          {userName}@{date.toLocaleDateString()}
        </div>
        <BubbleContent content={content} showTime={showTime} date={date} />
      </CommentsWrapper>
    )
  }
  return (
    <CommentsWrapper>
      <BubbleContent content={content} showTime={showTime} date={date} />
    </CommentsWrapper>
  )
}

export default Comment
