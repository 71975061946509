import React, { useEffect } from "react"
import { get } from "lodash"

import Input from "./Input"
import { useFormSet, useFormState } from "./hooks"

const HiddenInput = props => {
  const state = useFormState()
  const formSet = useFormSet()
  const { name, value } = props

  const existingVal = get(state, name)

  // Hidden inputs will never fire an onChange event, so set their value when
  // they render.
  useEffect(() => {
    if (existingVal !== value) {
      formSet({ name, value })
    }
  }, [name, value, existingVal, formSet])

  return <Input {...props} type="hidden" noWrapper />
}

export default HiddenInput
