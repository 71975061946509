import { deleteJwt } from "./jwt"
import { SIGN_OUT } from "../queries/auth"

let inProcess = false
export const signout = client => {
  if (inProcess) return

  inProcess = true
  return client
    .mutate({ mutation: SIGN_OUT })
    .finally(client.resetStore)
    .catch(err => {
      if (err?.message !== "Access Denied") {
        console.error(err)
      }
    })
    .finally(() => {
      inProcess = false
      deleteJwt()
    })
}
