import * as Y from "yjs"

import { addPageYDoc, addWidgetYDoc, addYMapToYArray } from "../lib/yjs"

const initDoc = (yDoc, store) => {
  const app = store.getState().app
  const spec = app.spec
  const specMap = yDoc.getMap("spec")

  if (specMap.size > 0) {
    console.log("YJS - Skipping document initialization")
    return true
  }

  try {
    yDoc.transact(() => {
      const appMap = yDoc.getMap("app")
      const specMap = yDoc.getMap("spec")
      const metadataMap = yDoc.getMap("metadata")

      appMap.set("id", app.id)
      appMap.set("name", app.name)
      appMap.set("description", app.desciption)
      specMap.set("rootPageId", spec.rootPageId)

      const initTheme = () => {
        const themeMap = new Y.Map(Object.entries(spec.theme))
        specMap.set("theme", themeMap)
      }

      const initMetadata = () => {
        const selectionsMap = new Y.Map()
        metadataMap.set("selections", selectionsMap)
        metadataMap.set("saveStatus", "")
      }

      const initPages = () => {
        const pagesMap = new Y.Map()

        Object.entries(spec.pages).forEach(([key, value]) => {
          addPageYDoc(pagesMap, key, value)
        })

        specMap.set("pages", pagesMap)
      }

      const initVariables = () => {
        const variablesArray = new Y.Array()
        spec.variables.forEach(variable =>
          addYMapToYArray(variablesArray, variable)
        )

        specMap.set("variables", variablesArray)
      }

      const initFilters = () => {
        const filtersArray = new Y.Array()

        spec.filters.forEach(filter => addYMapToYArray(filtersArray, filter))

        specMap.set("filters", filtersArray)
      }

      const initEndpoints = () => {
        const endpointsArray = new Y.Array()

        spec.endpoints.forEach(endpoint =>
          addYMapToYArray(endpointsArray, endpoint)
        )

        specMap.set("endpoints", endpointsArray)
      }

      const initWidgets = () => {
        const widgetsMap = new Y.Map()

        Object.values(spec.widgets).forEach(widget => {
          addWidgetYDoc(widgetsMap, widget)
        })

        specMap.set("widgets", widgetsMap)
      }

      initWidgets()
      initPages()
      initVariables()
      initEndpoints()
      initFilters()
      initMetadata()
      initTheme()
    })
    return true
  } catch (e) {
    console.error("Failed to initialize YJS document.", e)
    return false
  }
}

export default initDoc
