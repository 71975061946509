import { jwtDecode } from "jwt-decode"

import { getJwt, setJwt } from "../lib/jwt"
import { GET_CURRENT_USER } from "../queries/users"

const storedJwt = getJwt()

const setFromJwt = (cache, jwt) => {
  if (!jwt) {
    return null
  }

  setJwt(jwt)

  const { user } = jwtDecode(jwt)
  const data = {
    jwt,
    currentUser: {
      ...user,
      __typename: "User",
    },
  }

  cache.writeQuery({
    query: GET_CURRENT_USER,
    data,
  })
  return user
}

const setCurrentUser = (_obj, args, { cache }) => {
  const { jwt } = args
  setFromJwt(cache, jwt)
}

// Initializes the currentUser in the Apollo cache based on the JWT in
// localStorage. NOTE: This means we don't acutally need currentUser resolver
// because it just gets pulled from the cache.
const initializeCurrentUserState = data => {
  if (!storedJwt) {
    return data
  }

  const { user } = jwtDecode(storedJwt)
  return {
    ...data,
    jwt: storedJwt,
    currentUser: {
      ...user,
      __typename: "User",
    },
  }
}

export { setCurrentUser, initializeCurrentUserState, setFromJwt }
