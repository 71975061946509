import React from "react"
import { Typography, Space } from "antd"
import styled from "styled-components"

const { Title } = Typography

const SectionSpace = styled(Space)`
  margin: 0 10px;
  display: flex;
`

const SectionDiv = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

const SidePaneSection = props => {
  const { title, children } = props
  return (
    <SectionDiv>
      {title ? <Title level={5}>{title}</Title> : null}
      <SectionSpace direction="vertical">{children}</SectionSpace>
    </SectionDiv>
  )
}

export default SidePaneSection
