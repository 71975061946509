import React, { useCallback } from "react"
import { Button } from "antd"

import { useDispatch } from "react-redux"

import { actions } from "../reducers/formsReducer"
import { normalizeFormData } from "../lib/formData"
import { useFormContext, useFormData } from "./hooks"

const SaveButton = props => {
  const { onSubmit, text = "Submit", block, type = "primary" } = props
  const formData = useFormData()
  const { formId } = useFormContext()

  const dispatch = useDispatch()
  // when submit, trigger validation
  const handleSubmit = useCallback(async () => {
    if (!onSubmit) return
    dispatch(actions.compileForm({ id: formId })).then(errors => {
      const normalizedData = normalizeFormData(formData)
      onSubmit(normalizedData, errors)
    })
  }, [onSubmit, formData, dispatch, formId])

  return (
    <Button type={type} block={block} onClick={handleSubmit}>
      {text}
    </Button>
  )
}
export default React.memo(SaveButton)
