import { first } from "lodash"
import { createSlice } from "@reduxjs/toolkit"

import {
  toastAndRethrow,
  createCommonActions,
  createResourceActions,
} from "@dbai/tool-box"

import client from "apolloClient"
import { splitPath, findNode, checkIfDir } from "lib/utils/datalake"
import {
  GET_NODE,
  GET_NODES,
  MOVE_NODES,
  CREATE_NODE,
  DELETE_NODES,
  GET_DOWNLOAD_URL,
  CREATE_UPLOAD_NODE,
  UPDATE_NODE_STATUS,
} from "queries"

const getParentId = (data = {}) => {
  const { node } = data
  if (node) return node.parentId
  return data.parentId
}
const getCurrentNodeId = path => {
  const id = Number(first(splitPath(path).reverse()))
  if (Number.isNaN(id)) return null
  return id
}
const getCurrentNode = state => {
  const id = getCurrentNodeId(state.datalake.path)
  return findNode(id, state.datalake.root).node?.id
}
const getCname = state => state.currentCustomer.customer.normalizedName

const rootPath = null
const checkIsRoot = currentPath => {
  if (currentPath === "root" || currentPath === "rootPath") return true
  return !currentPath || currentPath === rootPath
}

const initialState = {
  path: "",
  root: {
    // These fields are faux fields to work nicely with Chonky. Could be done
    // in the selector, but here for now.
    id: rootPath,
    name: "root",

    // These are the actual nodes loaded from GraphQL.
    // nodes: [{ id: 1, nodes: [{ id: 2, nodes: [{ id: 3, nodes: [] }] }] }],
    nodes: [],
  },
}

/*
 * If we already have loaded child nodes we need to ensure that they are not
 * just removed by data from a shallower query. This function ensures that we
 * keep deeply nested nodes in such a case.
 */
const findLoadedNodes = (nodes = [], existingNodes = []) => {
  return nodes.map(node => {
    const existingNode = existingNodes.find(en => en.id === node.id)
    if (!existingNode) return node

    if (!node.nodes) {
      return {
        ...node,
        nodes: existingNode.nodes,
      }
    }

    return {
      ...node,
      nodes: node.nodes.map(n => {
        const existingN = existingNode.nodes.find(en => en.id === n.id)
        if (existingN) return existingN
        return n
      }),
    }
  })
}

const datalakeSlice = createSlice({
  name: "datalake",
  initialState,
  reducers: {
    ...createCommonActions(initialState),
    ...createResourceActions({ initialState }),
    addChildren: (draft, { payload }) => {
      const { node } = payload.id
        ? findNode(payload.id, draft.root)
        : { node: draft.root }
      node.nodes = findLoadedNodes(payload.nodes, node.nodes)
    },
    open: (draft, { payload = {} }) => {
      const { id } = payload
      if (checkIsRoot(id)) {
        draft.path = null
        return
      }

      const { path } = findNode(id, draft.root)
      draft.path = path.join("/")
    },
  },
})

const actions = datalakeSlice.actions

const loadNodes =
  (payload = {}) =>
  (dispatch, getState) => {
    const cname = getCname(getState())
    const parentId = payload.parentId || getState().datalake.parentId

    const variables = { maxDepth: 1, cname, ...payload }
    return client
      .query({
        query: GET_NODES,
        fetchPolicy: "network-only",
        variables,
      })
      .then(({ data }) => {
        dispatch(
          actions.addChildren({
            id: parentId,
            nodes: data.customer.nodes,
            uploads: data.customer.uploads,
          })
        )
      })
      .catch(toastAndRethrow)
  }

const loadNode =
  (payload = {}) =>
  (dispatch, getState) => {
    const cname = getCname(getState())
    if (!payload?.id || payload?.id === rootPath) {
      return dispatch(loadNodes())
    }

    const variables = { ...payload, cname }

    return client
      .query({ query: GET_NODE, fetchPolicy: "network-only", variables })
      .then(({ data }) => {
        const { node } = data.customer
        dispatch(
          actions.addChildren({
            parentId: node.parentId,
            nodes: node.nodes,
            uploads: node.uploads,
            id: node.id,
          })
        )
      })
  }

const navigate = variables => (dispatch, getState) => {
  const { id } = variables
  dispatch(actions.open({ id }))
  return dispatch(loadNode({ id }))
}

const uploadFile =
  (options, { file, meta }) =>
  (dispatch, getState) => {
    const defaultParent = getCurrentNode(getState()) || null
    const { cname, parentId = defaultParent } = options
    const { name, size } = meta

    const file = {
      status: "ready",
      size,
    }

    return client.mutate({
      mutation: CREATE_UPLOAD_NODE,
      variables: { cname, name, file, parentId },
    })
  }

const openNodeUrl = ({ data }) => {
  const url = data?.customer?.node?.downloadUrl
  window.open(url, "_blank")
}

const downloadFile = node => (dispatch, getState) => {
  const cname = getCname(getState())

  if (!checkIfDir(node)) {
    return client
      .query({
        query: GET_DOWNLOAD_URL,
        variables: { cname, id: node.id },
      })
      .then(openNodeUrl)
      .catch(toastAndRethrow("Error downloading file"))
  }
}

const selectNode = node => (dispatch, getState) => {
  if (checkIfDir(node)) {
    return dispatch(
      navigate({
        id: node.id,
      })
    )
  }

  return dispatch(downloadFile(node))
}

const updateNodeStatus = variables => dispatch => {
  return client
    .mutate({
      mutation: UPDATE_NODE_STATUS,
      variables,
    })
    .then(({ data }) => {
      const node = data.updateNodeStatus
      return dispatch(
        loadNode({
          cname: variables.cname,
          id: node.parentId,
          maxDepth: 1,
        })
      )
    })
}

const createFolder = payload => (dispatch, getState) => {
  const parentId =
    payload?.parentId || getCurrentNodeId(getState().datalake.path)
  const variables = {
    ...payload,
    parentId: parentId || 0,
  }

  return client
    .mutate({
      mutation: CREATE_NODE,
      variables,
    })
    .then(() => {
      dispatch(
        loadNode({
          cname: variables.cname,
          id: parentId,
        })
      )
    })
}

const moveFiles =
  ({ destination = {}, selectedFiles = [], draggedFile = {} }) =>
  (dispatch, getState) => {
    const files = selectedFiles.length > 0 ? selectedFiles : [draggedFile]
    const sourceId = getParentId(files[0])
    const targetDirectory = checkIsRoot(destination.id) ? 0 : destination.id
    const cname = getCname(getState())
    const variables = {
      nodeIds: files.map(n => n.id),
      parentId: targetDirectory,
      cname,
    }

    client
      .mutate({
        mutation: MOVE_NODES,
        variables,
      })
      .then(data => {
        dispatch(loadNode({ cname, id: targetDirectory }))
        dispatch(loadNode({ cname, id: sourceId }))
      })
  }

const deleteNodes =
  (nodes = []) =>
  (dispatch, getState) => {
    const cname = getCname(getState())
    const variables = {
      cname,
      ids: nodes.map(node => node.id),
    }

    client
      .mutate({
        mutation: DELETE_NODES,
        variables,
      })
      .finally(() => dispatch(loadNode({ path: nodes[0].path, cname })))
  }

const allActions = {
  ...actions,
  loadNodes,
  loadNode,
  navigate,
  moveFiles,
  deleteNodes,
  uploadFile,
  selectNode,
  createFolder,
  downloadFile,
  updateNodeStatus,
}

export { allActions as actions }
export default datalakeSlice
