import { curry } from "lodash"

import { getAxisLabelOptions } from "./utils"
import { formatColumnValue } from "../../../lib/datasetColumns"

const getPlotBands = ({
  value,
  plotBand,
  direction,
  seriesIdx,
  upperLimit,
}) => {
  const { color, borderColor, borderWidth } = plotBand
  const plotBandOptions = {
    color,
    borderColor,
    borderWidth,
    zIndex: seriesIdx + 1,
  }
  switch (true) {
    case direction === "between":
      return [
        {
          from: value,
          to: upperLimit,
          ...plotBandOptions,
        },
      ]
    case direction === "outside":
      return [
        {
          to: value,
          from: -Infinity,
          ...plotBandOptions,
        },
        {
          to: Infinity,
          from: upperLimit,
          ...plotBandOptions,
        },
      ]
    case direction === "above":
      return [
        {
          from: value,
          to: Infinity,
          ...plotBandOptions,
        },
      ]
    case direction === "below":
      return [
        {
          from: -Infinity,
          to: value,
          ...plotBandOptions,
        },
      ]
    default:
      return []
  }
}

const getPlotLines = ({
  value,
  plotLine,
  direction,
  seriesIdx,
  upperLimit,
}) => {
  const { color, dashStyle, width = 2 } = plotLine
  const plotLineOptions = { color, dashStyle, width, zIndex: seriesIdx + 2 }
  if (["between", "outside"].includes(direction)) {
    return [
      { ...plotLineOptions, value },
      { ...plotLineOptions, value: upperLimit },
    ]
  }
  return [{ ...plotLineOptions, value }]
}

const getPlotLinesFromThresholds = (thresholds, series, seriesIdx) => {
  return thresholds.reduce(
    (acc, { plotBand = {}, plotLine = {}, value, upperLimit, direction }) => {
      let plotLines = acc.plotLines
      let plotBands = acc.plotBands
      if (plotLine.showLine) {
        plotLines = [
          ...plotLines,
          ...getPlotLines({
            value,
            plotLine,
            upperLimit,
            direction,
            seriesIdx,
          }),
        ]
      }
      if (plotBand.showBand) {
        plotBands = [
          ...plotBands,
          ...getPlotBands({
            value,
            plotBand,
            upperLimit,
            direction,
            seriesIdx,
          }),
        ]
      }
      return { plotBands, plotLines }
    },
    { plotLines: series?.plotLines || [], plotBands: series?.plotBands || [] }
  )
}

const getYAxisTitle = (yAxis, series) => {
  const { custom, color, name } = series
  const { combineAxes } = custom || {}
  const { title } = yAxis || {}

  const getText = () => {
    switch (true) {
      // case for when the user defines a y axis title
      case Boolean(title?.text) && title?.text !== "" && Boolean(combineAxes):
        return title.text
      case Boolean(combineAxes):
        return null
      default:
        if (!color) return name
        return `<span style="color:${color}">●</span> ${name}`
    }
  }

  return {
    ...title,
    enabled: true,
    text: getText(),
  }
}

const getYAxisForSeries = (options, series, seriesIdx, existingYAxis) => {
  const { yAxis = {} } = options
  const { stackLabels = {} } = yAxis
  const { yAxis: yAxisIndex, custom } = series
  const { thresholds = [], datasetColumn } = custom || {}
  const { plotLines, plotBands } = getPlotLinesFromThresholds(
    thresholds,
    existingYAxis,
    seriesIdx
  )

  const formatter = function () {
    return formatColumnValue(datasetColumn, this.total)
  }

  return {
    ...yAxis,
    plotBands,
    useHTML: true,
    opposite: yAxisIndex >= 0 ? yAxisIndex % 2 !== 0 : false,
    labels: getAxisLabelOptions(options.yAxis, datasetColumn),
    plotLines: [...(yAxis?.plotLines || []), ...plotLines],
    title: existingYAxis?.title ?? getYAxisTitle(yAxis, series),
    stackLabels: { ...stackLabels, formatter },
  }
}

const getYAxisOptions = options => {
  const { highchart } = options
  const { series = [] } = highchart
  const yAxes = series.reduce((acc, s, seriesIdx) => {
    const { yAxis: yAxisIndex } = s
    if (yAxisIndex >= 0) {
      const existing = acc[yAxisIndex] || {}
      return {
        ...acc,
        [yAxisIndex]: getYAxisForSeries(options, s, seriesIdx, existing),
      }
    }
    return {
      ...acc,
      [seriesIdx]: getYAxisForSeries(options, s, seriesIdx, {}),
    }
  }, {})
  const computedYAxis = Object.values(yAxes)

  return computedYAxis.length
    ? computedYAxis
    : {
        title: {
          text: "",
          enabled: true,
        },
      }
}

const constructYAxis = curry((dataset, options) => {
  const { highchart } = options || {}
  return {
    ...options,
    highchart: {
      ...highchart,
      yAxis: getYAxisOptions(options),
    },
  }
})

export default constructYAxis
