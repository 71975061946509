import React, { useMemo, useRef, useEffect, useState, useCallback } from "react"
import { omit } from "lodash"
import { Row, Col, Space } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import ListItemIcon from "./ListItemIcon"
import { Collapse } from "../CollapsePanel"
import JSONSchemaField from "../../JSONSchemaField"
import { isSchemaHidden, getCurrentPaths, getResolvedSchema } from "../../utils"

const containerTypes = ["array", "object"]

// getFirstSubSchema takes in the rooot form schema and local schema and value to find
// the first nested schema that is not hidden. It returns the subschema, the subschema's name,
// and the local schema without the subschema
const getFirstSubSchema = (rootSchema, previousSchema, data, path) => {
  const resolvedSchema = getResolvedSchema(previousSchema, rootSchema, data)
  let schema = { ...resolvedSchema }
  const { items, properties } = schema
  switch (true) {
    case Boolean(properties):
      let schemaKey, subSchema, nextSchema
      Object.keys(properties).forEach(key => {
        if (schema && schemaKey) return
        const property = properties[key]
        const resolvedSubSchema = getResolvedSchema(property, rootSchema, data)
        if (isSchemaHidden(resolvedSubSchema, data?.[key], path)) return
        subSchema = { ...resolvedSubSchema }
        nextSchema = {
          ...schema,
          properties: omit(schema.properties, [key]),
        }
        schemaKey = key
      })
      return [schemaKey, subSchema, nextSchema]
    case containerTypes.includes(items.type):
      return getFirstSubSchema(rootSchema, items, data, path)
    default:
      return [null, items]
  }
}

const schemaHasFields = schema => {
  const { items = {}, properties = {} } = schema
  if (schema.type === "array" && Object.keys(items)) {
    return schemaHasFields(items)
  }
  return Boolean(Object.keys(properties).length)
}

const DefaultPanelHeader = props => {
  const { idx } = props
  const [stachedIndex] = useState(idx + 1)
  return (
    <Space>
      <span>Array Item {stachedIndex}</span>
    </Space>
  )
}

const getAllArrayItemKeys = (
  arr,
  prefix = "",
  currentCount,
  currentActiveKey
) => {
  // when currentCount exists, then newly add items should be opened, but existing items should stay closed if they were closed
  if (currentCount > 0) {
    const newItems = arr.slice(currentCount)
    return [
      ...currentActiveKey,
      ...newItems.map((_, i) => [`${prefix}.[${i + currentCount}]`]),
    ]
  }
  return arr.map((_, i) => [`${prefix}.[${i}]`])
}

const CollapsedListItems = props => {
  const {
    path,
    value,
    name,
    onRemove,
    schema = {},
    nodeClassName,
    handleClassName,
    collapseHeaderLevel = 0,
  } = props
  const { metadata = {} } = schema
  const { sectionProps = {}, draggable = true, removable = true } = metadata
  const itemCount = useRef(value?.length)
  const [activeKey, setActiveKey] = useState(getAllArrayItemKeys(value, path))

  useEffect(() => {
    if (itemCount.current !== value.length) {
      setActiveKey(current =>
        getAllArrayItemKeys(value, path, itemCount.current, current)
      )
    }
  }, [value, path])

  const handleActiveKeyChange = useCallback(activeKey => {
    setActiveKey(activeKey)
  }, [])

  const items = useMemo(() => {
    return value?.map((v, idx) => {
      const { path: subSchemaPath, dataPath: subSchemaName } = getCurrentPaths(
        path,
        `.[${idx}]`
      )
      const resolvedSubschema = getResolvedSchema(schema, schema, v)
      const [firstSubSchemaKey, firstSubSchema, nextSchema] = getFirstSubSchema(
        schema,
        resolvedSubschema,
        v,
        path
      )
      const subschemaHasFields = schemaHasFields(nextSchema)

      const { sectionProps = {}, containerStyle = {} } =
        schema.items.metadata || {}

      return {
        key: `${path}.[${idx}]`,
        className: nodeClassName,
        showArrow: !sectionProps.hideArrow && subschemaHasFields,
        label: (
          <Row wrap={false} gutter={[8]}>
            <Col>
              {draggable ? (
                <ListItemIcon
                  idx={idx}
                  onRemove={onRemove}
                  removable={removable}
                  handleClassName={handleClassName}
                />
              ) : null}
            </Col>
            <Col flex={1}>
              {nextSchema?.metadata?.icon ? (
                <FontAwesomeIcon icon={nextSchema.metadata.icon} />
              ) : null}
              {firstSubSchema ? (
                <JSONSchemaField
                  hideLabel
                  validateFirst
                  hideValidationMessage
                  path={subSchemaPath}
                  name={subSchemaName}
                  parentSchema={schema}
                  schema={firstSubSchema}
                  schemaKey={firstSubSchemaKey}
                  wrapperStyles={{ margin: "0 1em 0 0" }}
                />
              ) : (
                <DefaultPanelHeader idx={idx} />
              )}
            </Col>
          </Row>
        ),
        children: subschemaHasFields ? (
          <div style={containerStyle}>
            <JSONSchemaField
              path={path}
              name={name}
              schema={nextSchema}
              parentSchema={schema}
              schemaKey={`.[${idx}]`}
              collapseHeaderLevel={collapseHeaderLevel + 1}
            />
          </div>
        ) : null,
      }
    })
  }, [
    name,
    path,
    value,
    schema,
    onRemove,
    draggable,
    removable,
    nodeClassName,
    handleClassName,
    collapseHeaderLevel,
  ])

  return (
    <Collapse
      items={items}
      activeKey={activeKey}
      ghost={sectionProps.ghost}
      expandIconPosition="right"
      onChange={handleActiveKeyChange}
      bordered={sectionProps.bordered}
      accordion={sectionProps.accordion}
      collapsible={sectionProps.collapsible || "header"}
    />
  )
}

export default CollapsedListItems
