import React, { useCallback } from "react"
import { Dropdown } from "antd"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGripVertical } from "@fortawesome/pro-solid-svg-icons"

const ItemIconContainer = styled.div`
  padding-top: 5px;
`

const itemMenuItems = [
  {
    key: "remove",
    label: "Remove",
  },
]

const ListItemIcon = props => {
  const { onRemove, removable, idx, handleClassName } = props

  const handleMenuItemClick = useCallback(
    ({ key }) => {
      if (key === "remove") {
        onRemove && onRemove(idx)
      }
    },
    [onRemove, idx]
  )

  return (
    <ItemIconContainer className={handleClassName}>
      <Dropdown
        trigger="click"
        disabled={!removable}
        placement="bottomLeft"
        menu={{ onClick: handleMenuItemClick, items: itemMenuItems }}
      >
        <FontAwesomeIcon icon={faGripVertical} style={{ marginRight: 0 }} />
      </Dropdown>
    </ItemIconContainer>
  )
}

export default ListItemIcon
