import { flow } from "lodash"

import {
  processEnumOptions,
  processMultiSelect,
  processDatePickerOptions,
  processScalarFieldOptions,
} from "../../lib/generateSchemaHelpers"

const columnTypeMap = {
  int: "number",
  float: "number",
  integer: "number",
  numerical: "number",

  datetime: "string",
  categorical: "string",
}

const addSchemaType = options => {
  const { metadata = {} } = options
  const { columnType } = metadata
  const type = columnTypeMap[columnType] ?? columnType
  return { ...options, type: type ?? "string" }
}

const addColumnValueInput = options => {
  const { title, metadata = {} } = options
  return {
    ...options,
    title: title || metadata.column || "",
    metadata: {
      ...metadata,
      component: "ColumnValueInput",
    },
  }
}

const addDatePickerOptions = options => {
  const { metadata } = options
  const { columnType } = metadata || {}
  switch (columnType) {
    case "datetime":
      return processDatePickerOptions(options)
    default:
      return options
  }
}

const addValuePresets = options => {
  if (options.metadata.valuePresets === "otherColumnValues") {
    return {
      ...options,
      metadata: {
        ...options.metadata,
        presetColumnName: "presetColumn",
        presetDatasetIdName: "presetDatasetId",
      },
    }
  }
  return options
}

export default flow([
  addSchemaType,
  addColumnValueInput,
  addDatePickerOptions,
  addValuePresets,
  processScalarFieldOptions,
  processEnumOptions,
  processMultiSelect,
])
