import { get } from "lodash"

const layoutDefaults = {
  minW: 1,
  minH: 1,
  maxW: 120,
  maxH: 60,
  isResizable: true,
  resizeHandles: ["nw", "n", "ne", "e", "se", "s", "sw", "w"],
}

const layoutKeys = [
  "i",
  "x",
  "y",
  "w",
  "h",
  "static",
  "interpolate",
  ...Object.keys(layoutDefaults),
]

export const rowHeight = 30

export const breakpointSpecs = {
  lg: {
    pxVal: 1200,
    cols: 120,
    label: "Large",
  },
  md: {
    pxVal: 996,
    cols: 99,
    label: "Medium",
  },
  sm: {
    pxVal: 768,
    cols: 76,
    label: "Small",
  },
  xs: {
    pxVal: 375,
    cols: 37,
    label: "Extra Small",
  },
  // xxs: {
  //   pxVal: 90,
  //   cols: 9,
  //   label: "Tiny",
  // },
}

export const layoutOptions = Object.entries(breakpointSpecs)
  .map(([key, value]) => {
    return {
      value: key,
      label: value.label,
    }
  })
  .reverse()

export const responsiveBreakpointNames = Object.keys(breakpointSpecs)

const randomInt = max => {
  const to = max + 1
  return Math.floor(Math.random() * to) || 1
}

export const withBreakpoints = layout => {
  return responsiveBreakpointNames.reduce((acc, bp) => {
    return { ...acc, [bp]: layout }
  }, {})
}

export const getDefaultLayout = () => {
  return withBreakpoints({
    x: randomInt(10),
    y: randomInt(5),
    w: 40,
    h: 9,
    ...layoutDefaults,
  })
}

const makeSafeLayout = layout => {
  return layoutKeys.reduce((acc, key) => {
    const layoutValue = layout[key]
    const layoutHasKey = layout.hasOwnProperty(key)
    const val = layoutHasKey ? layoutValue : layoutDefaults[key]
    return { ...acc, [key]: val }
  }, {})
}

// helper function that takes in a single breakpoint layout and generates
// all breakpoint layouts from it
export const generateFullBreakpointLayout = layout => {
  const safeLayout = makeSafeLayout(layout || {})
  return Object.keys(breakpointSpecs).reduce((acc, breakpoint) => {
    return {
      ...acc,
      [breakpoint]: safeLayout,
    }
  }, {})
}

export const generateFullLayout = (layout, layoutI) => {
  return Object.keys(breakpointSpecs).reduce((acc, breakpoint) => {
    const layoutForBreakpoint = { ...(layout?.[breakpoint] || {}), i: layoutI }
    const fullLayout = makeSafeLayout(layoutForBreakpoint)
    return {
      ...acc,
      [breakpoint]: fullLayout,
    }
  }, {})
}

const deconstructBreakpointSpecs = at =>
  responsiveBreakpointNames.reduce((final, bpKey) => {
    return { ...final, [bpKey]: get(breakpointSpecs[bpKey], at) }
  }, {})

export const defaultBreakpointThresholds = deconstructBreakpointSpecs("pxVal")
export const breakpointCols = deconstructBreakpointSpecs("cols")

export const DROPPING_ITEM_I = "drop-from-outside"
