import { setCurrentUser, initializeCurrentUserState } from "./currentUser"

const customerKey = "admin/currentCustomer"
const storedId = JSON.parse(localStorage.getItem(customerKey) || "null")

const setCurrentCustomerId = (_obj, args, { cache }) => {
  const { id } = args
  localStorage.setItem(customerKey, String(id))

  const data = { currentCustomerId: id }
  cache.writeData({ data })
}

const currentCustomerId = (_obj, _args, { cache }) => {
  return storedId
}

const mutations = {
  setCurrentUser,
  setCurrentCustomerId,
}

const queries = {
  currentCustomerId,
}

const initializers = [initializeCurrentUserState]

export { initializers, mutations, queries }
