import React, { useRef, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

import { useCheckForDBAdmin } from "@dbai/ui-staples"

import WidgetContext from "./WidgetContext"
import widgetRegistry from "./widgetRegistry"
import JSONSchemaForm from "./JSONSchemaForm"
import { generateWidgetSchema } from "./widgetSchema"
import { selectCrossFilters } from "./selectors/app"
import { selectPointInAllCharts } from "./lib/selectPoints"

const adminWidgetSchema = generateWidgetSchema(true, widgetRegistry)
const nonAdminWidgetSchema = generateWidgetSchema(false, widgetRegistry)

const WidgetContextWrapper = props => {
  const {
    cname,
    theme,
    Widget,
    children,
    appConfig,
    customerId,
    exportDataset,
    editable = false,
  } = props

  const isAdmin = useCheckForDBAdmin()
  const widgetSchema = isAdmin ? adminWidgetSchema : nonAdminWidgetSchema
  const [refetchCounter, setRefetchCounter] = useState(0)
  const [refetchQueries, setRefetchQueries] = useState(false)
  const checkRefetchTimeout = useRef(null)
  const selectedPoints = useSelector(selectCrossFilters)

  useEffect(() => {
    selectPointInAllCharts(selectedPoints)
  }, [selectedPoints])

  // this effect is used to reset the refetchQueries flag when the refetchCounter
  // is 0 after a delay.
  useEffect(() => {
    if (refetchQueries) {
      // clear any existing timeout
      if (checkRefetchTimeout.current) {
        clearTimeout(checkRefetchTimeout.current)
      }

      // set a timeout to check the refetchCounter after a delay
      checkRefetchTimeout.current = setTimeout(() => {
        if (refetchCounter === 0) {
          setRefetchQueries(false)
        }
      }, 100)
    }

    return () => {
      if (checkRefetchTimeout.current) {
        clearTimeout(checkRefetchTimeout.current)
      }
    }
  }, [refetchCounter, refetchQueries])

  const context = useMemo(
    () => ({
      cname,
      theme,
      editable,
      appConfig,
      customerId,
      widgetSchema,
      exportDataset,
      refetchQueries,
      setRefetchQueries,
      setRefetchCounter,

      // the following props are used recursively by widgets.
      // to prevent circular imports, we define them here.
      Widget,
      widgetRegistry,
      JSONSchemaForm,
    }),
    [
      cname,
      theme,
      Widget,
      editable,
      appConfig,
      customerId,
      widgetSchema,
      exportDataset,
      refetchQueries,
    ]
  )

  return (
    <WidgetContext.Provider value={context}>{children}</WidgetContext.Provider>
  )
}

WidgetContextWrapper.propTypes = {
  theme: PropTypes.object,
  editable: PropTypes.bool,
  cname: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  exportDataset: PropTypes.func.isRequired,
}

export default WidgetContextWrapper
