import React from "react"
import { Button } from "antd"
import { toast } from "react-toastify"
import { useHistory } from "react-router"
import { useQuery, useMutation } from "@apollo/client"

import {
  ErrorMessage,
  ActionsFormatter,
  TableWithFolders,
  useCurrentCustomer,
} from "@dbai/ui-staples"
import { GET_THEMES, CREATE_THEME, DELETE_THEME } from "@dbai/applet"

import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"
import ContentWithStackedNav from "components/pages/Apps/ContentWithStackedNav"

const columnOrder = ["name", "createdAt", "updatedAt", "actions"]

const AddThemeButton = props => {
  const { cname } = props
  const history = useHistory()

  // on add, create and save new theme type then redirect to editor for theme type
  const [addTheme] = useMutation(CREATE_THEME, {
    refetchQueries: [{ query: GET_THEMES, variables: { cname } }],
    variables: {
      cname: cname,
      input: {
        name: "Theme",
        description: "A theme for your app",
        spec: {},
      },
    },
  })

  const handleAddTheme = e => {
    addTheme().then(({ data }) => {
      return history.push(`/${cname}/apps/themes/${data.createTheme.id}`)
    })
  }

  return <Button onClick={handleAddTheme}>Add New Theme</Button>
}

const Actions = props => {
  const { row } = props
  const [{ normalizedName: cname }] = useCurrentCustomer()
  const variables = { cname }
  const afterDelete = () => {
    toast.success("Theme Deleted")
  }
  const handleError = err => {
    console.error(err)
    toast.error("Error Deleting Theme")
  }

  return (
    <ActionsFormatter
      confirmDelete
      value={row.id}
      onError={handleError}
      variables={variables}
      afterDelete={afterDelete}
      refetchVariables={variables}
      deleteGql={DELETE_THEME}
      editPath={`/${cname}/apps/themes/`}
      refetchQuery={GET_THEMES}
    />
  )
}

const ThemeRegistry = props => {
  const { customer } = props
  const { normalizedName: cname } = customer
  const { loading, data, error } = useQuery(GET_THEMES, {
    variables: { cname },
  })

  if (error) return <ErrorMessage error={error} />

  const themes = data?.customer?.themes
  const routes = [
    { breadcrumbName: customer.name },
    { breadcrumbName: "Themes" },
  ]

  return (
    <>
      <PageHeader routes={routes} />
      <ContentWithStackedNav>
        <TableWithFolders
          loading={loading}
          dataSource={themes}
          Actions={Actions}
          columnOrder={columnOrder}
          folderType="appThemes"
          refetchVariables={{ cname }}
          refetchQuery={GET_THEMES}
          emptyText="No Registered Themes Found"
          pagination={{ hideOnSinglePage: true }}
          extra={<AddThemeButton cname={cname} />}
          rowLink={row => `/${cname}/apps/themes/${row.id}`}
        />
      </ContentWithStackedNav>
    </>
  )
}

export default withCustomer(ThemeRegistry)
