const colorScaleSchema = {
  type: "array",
  title: "Color Scale",
  metadata: {
    addButtonText: "ADD SCALE",
    addButtonProps: {
      block: true,
    },
  },
  items: {
    type: "object",
    default: {},
    properties: {
      column: {
        type: "string",
        title: "Column",
        metadata: {
          layout: { span: 24 },
          component: "ColumnSelect",
          allowedTypes: ["number", "integer", "int", "float"],
          fieldProps: {
            placeholder: "Select Column",
          },
        },
      },
      colors: {
        type: "array",
        default: [],
        title: "Colors",
        metadata: {
          component: "ColorScale",
          addButtonText: "ADD COLOR",
          addButtonProps: {
            block: true,
          },
        },
        items: {
          type: "object",
          default: {},
          required: ["value", "color"],
          metadata: {
            layout: { wrap: true },
          },
          dependencies: {
            valueType: {
              if: {
                properties: {
                  valueType: {
                    enum: ["percentile"],
                  },
                },
              },
              then: {
                properties: {
                  value: {
                    type: "number",
                    title: "Value",
                    default: 0,
                    min: 0,
                    max: 1,
                    metadata: {
                      hideLabel: true,
                      component: "SliderInput",
                      showNumberInput: true,
                      layout: { flex: 1 },
                      formatter: { format: "percent" },
                    },
                  },
                },
              },
              else: {
                properties: {
                  value: {
                    type: "number",
                    title: "Value",
                    default: 0,
                    metadata: {
                      hideLabel: true,
                      layout: { flex: 1 },
                    },
                  },
                },
              },
            },
          },
          properties: {
            valueType: {
              type: "string",
              title: "Compare value type",
              enum: ["staticValue", "percentile"],
              default: "staticValue",
              metadata: {
                hideLabel: true,
                labels: ["Value", "Percentile"],
                layout: { span: 24 },
              },
            },
            // value: {
            //   type: "number",
            //   default: 0,
            //   metadata: {
            //     hideLabel: true,
            //     layout: { flex: 1 },
            //   },
            // },
            color: {
              type: "string",
              metadata: {
                hideLabel: true,
                component: "ColorPicker",
              },
            },
          },
        },
      },
    },
  },
}

export default colorScaleSchema
