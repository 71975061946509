import { gql } from "@apollo/client"

const ACTIONS_FRAGMENT = gql`
  fragment ActionFragment on Action {
    id
    description
    name
    tags
    published
    createdAt
    updatedAt
    currentRevision {
      actionId
      createdAt
      description
      id
      spec {
        type
        color
        icon
        cells {
          source
          metadata
          cellType
        }
        arguments {
          name
          type
        }
        artifacts {
          title
          path
        }
      }
    }
  }
`

const REVISIONS_FRAGMENT = gql`
  fragment RevisionsFragment on Action {
    actionRevisions {
      id
      createdAt
      actionId
      spec {
        arguments {
          type
          name
        }
        artifacts {
          id
          path
          title
          type
        }
        cells {
          source
          uuid
          outputs
          metadata
          cellType
        }
        type
        color
        icon
      }
    }
    currentRevision {
      actionId
      createdAt
      description
      id
      spec {
        type
        color
        icon
        cells {
          cellType
          executionCount
          metadata
          outputs
          source
          uuid
        }
        artifacts {
          id
          path
          title
          type
        }
        arguments {
          name
          type
        }
      }
    }
  }
`

export const GET_ACTIONS = gql`
  ${ACTIONS_FRAGMENT}
  query Actions($cname: String!) {
    customer(name: $cname) {
      id
      actions {
        ...ActionFragment
      }
    }
  }
`

export const GET_PUBLISHED_ACTIONS = gql`
  ${ACTIONS_FRAGMENT}
  query {
    actionPublications {
      id
      action {
        ...ActionFragment
      }
    }
  }
`

export const DELETE_ACTION = gql`
  mutation DeleteAction($customerId: Int!, $id: Int!) {
    deleteAction(customerId: $customerId, id: $id) {
      id
    }
  }
`

export const CREATE_ACTION = gql`
  mutation CreateAction(
    $cid: Int!
    $input: ActionInput!
    $actionRevisionInput: ActionRevisionInput
  ) {
    createAction(
      customerId: $cid
      input: $input
      actionRevisionInput: $actionRevisionInput
    ) {
      id
      description
      name
      createdAt
      updatedAt
      currentRevision {
        id
        actionId
        createdAt
        description
        spec {
          type
          color
          icon
          cells {
            cellType
            executionCount
            metadata
            outputs
            source
            uuid
          }
          artifacts {
            id
            path
            title
            type
          }
          arguments {
            name
            type
          }
        }
      }
    }
  }
`

export const UPDATE_ACTION = gql`
  ${REVISIONS_FRAGMENT}
  mutation UpdateAction(
    $cname: String!
    $actionId: Int!
    $revision: ActionRevisionInput!
    $action: ActionInput!
  ) {
    updateAction(
      cname: $cname
      input: $action
      actionId: $actionId
      actionRevisionInput: $revision
    ) {
      ...RevisionsFragment
      description
      name
      id
    }
  }
`

export const SET_CURRENT_REVISION = gql`
  ${REVISIONS_FRAGMENT}
  mutation SetCurrentRevision(
    $cname: String!
    $actionId: Int!
    $input: ActionInput!
  ) {
    updateAction(cname: $cname, actionId: $actionId, input: $input) {
      ...RevisionsFragment
      description
      name
      id
    }
  }
`

export const GET_ACTION = gql`
  ${REVISIONS_FRAGMENT}
  query GetAction($cname: String!, $id: Int!) {
    customer(name: $cname) {
      action(id: $id) {
        ...RevisionsFragment
        id
        name
        updatedAt
        description
      }
    }
  }
`

export const GET_REVISIONS = gql`
  ${REVISIONS_FRAGMENT}
  query getRevisions($cname: String!, $actionId: Int!) {
    customer(name: $cname) {
      action(id: $actionId) {
        ...RevisionsFragment
        name
        id
      }
    }
  }
`

export const PUBLISH_ACTION = gql`
  mutation PublishAction($actionId: Int!, $customerId: Int!) {
    publishAction(actionId: $actionId, customerId: $customerId) {
      id
    }
  }
`

export const DELETE_ACTION_PUBLICATION = gql`
  mutation DeleteAction($actionId: Int!, $customerId: Int!) {
    deleteActionPublication(actionId: $actionId, customerId: $customerId)
  }
`
