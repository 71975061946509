export const findFilterIndex = (filters, filterId) => {
  if (!filters?.length || !filterId) return -1
  return filters.findIndex(filter => {
    return filter.id === filterId
  })
}

export const findFilter = (filters, filterId) => {
  const filterIndex = findFilterIndex(filters, filterId)
  if (filterIndex === -1) return null
  return filters[filterIndex]
}
