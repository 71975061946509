import React, { useState, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"

import { alphanumid } from "@dbai/tool-box"
import { useResizeObserver } from "@dbai/ui-staples"

import Page from "./Page"
import EditablePage from "./EditablePage"
import PageContainer from "./PageContainer"
import { actions } from "../../reducers/appReducer"
import { getPageCols, convertToGridX, convertToGridY } from "./utils"
import { rowHeight, breakpointCols } from "../../lib/layout"
import {
  useCurrentBreakpoint,
  useContextMenu,
  useWidgetContext,
} from "../../hooks"
import { selectAppTheme } from "../../selectors/app"

const AppPageContent = React.memo(props => {
  const { editable } = useWidgetContext()
  const PageComp = editable ? EditablePage : Page
  return <PageComp {...props} />
})

const useGridConversions = (gridOptions, gridWidth) => {
  const { gridItemMargins, rowHeight } = gridOptions
  const breakpoint = useCurrentBreakpoint()
  const cols = breakpointCols[breakpoint]
  const [xMargin, yMargin] = gridItemMargins

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const convertX = useCallback(convertToGridX(gridWidth, cols, xMargin), [
    gridWidth,
    cols,
    xMargin,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const convertY = useCallback(convertToGridY(rowHeight, yMargin), [
    rowHeight,
    yMargin,
  ])

  return {
    convertX,
    convertY,
  }
}

const usePageOptions = props => {
  const appTheme = useSelector(selectAppTheme)
  return useMemo(() => {
    const compactionMethod =
      props.compactionMethod || appTheme.compactionMethod || "freeform"
    const cols = [
      props.horizontalMargin || appTheme.horizontalMargin || 0,
      props.verticalMargin || appTheme.verticalMargin || 0,
    ]
    const gridItemMargins = compactionMethod === "freeform" ? [0, 0] : cols
    return {
      gridItemMargins,
      allowOverlap: props.allowOverlap || appTheme.allowOverlap,
      preventCollision: props.preventCollision || appTheme.preventCollision,
      compactionMethod: props.compactionMethod || compactionMethod,
      containerPadding: [
        props.horizontalPadding || appTheme.horizontalPadding || 0,
        props.verticalPadding || appTheme.verticalPadding || 0,
      ],
      cols: getPageCols(appTheme.breakpoints),
      rowHeight: appTheme.rowHeight || rowHeight,
    }
  }, [appTheme, props])
}

const AppPage = props => {
  const { pageId, className, width, height, backgroundColor, name, imageSpec } =
    props
  const containerId = useMemo(() => alphanumid(), [])
  const [containerWidth, setContainerWidth] = useState(0)
  const breakpoint = useCurrentBreakpoint()
  const pageOptions = usePageOptions(props)
  const { convertX, convertY } = useGridConversions(pageOptions, containerWidth)
  const dispatch = useDispatch()
  const showGridLines = useSelector(state => state?.app?.showPageGridLines)

  const handleContextMenu = useCallback(
    e => {
      const { offsetX, offsetY } = e
      dispatch(
        actions.addPageToContextMenu({
          name,
          pageId,
          x: convertX(offsetX),
          y: convertY(offsetY),
        })
      )
    },
    [dispatch, convertX, convertY, name, pageId]
  )

  const ref = useContextMenu(handleContextMenu)

  const onResize = useCallback(() => {
    if (ref.current && ref.current?.children?.[0]) {
      setContainerWidth(ref.current.children[0].offsetWidth)
    }
  }, [ref])

  useResizeObserver(ref, onResize)

  const computedBackgroundSize = useMemo(() => {
    const cols = pageOptions.cols[breakpoint]
    const currentWidth = containerWidth / cols
    return `${currentWidth}px ${pageOptions.rowHeight}px`
  }, [containerWidth, pageOptions, breakpoint])

  return (
    <PageContainer
      ref={ref}
      width={width}
      height={height}
      imageSpec={imageSpec}
      className={className}
      containerId={containerId}
      showGridLines={showGridLines}
      backgroundColor={backgroundColor}
      backgroundSize={computedBackgroundSize}
    >
      <AppPageContent {...props} {...pageOptions} containerId={containerId} />
    </PageContainer>
  )
}

AppPage.propTypes = {
  pageId: PropTypes.number,
  widgetId: PropTypes.number,
  style: PropTypes.object,
}

export default React.memo(AppPage)
