import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Checkbox } from "antd"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { getJwt } from "lib/jwt"
import config from "config"

import { Spinner } from "@dbai/ui-staples"

import { actions } from "reducers/componentReducer"

const SpinnerWrapper = styled.div`
  display: flex;
  align-content: start;
`

export const reloadComponents = (cname) => {
  const reloadURL = `${config.api}endpoints/${cname}/reload_components`

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${getJwt()}`,
  }
  fetch(reloadURL, {
    method: "POST",
    headers,
  })
    .then(res => {
      return res.json()
    })
    .catch(err => {
      toast.error(`Error reloading components: ${err}`)
    })
}


const SelfUpdatingCheckbox = props => {
  const { checked, changed, setChanged, onChange } = props
  useEffect(() => {
    if (changed) setChanged(false)
  }, [changed, setChanged])

  return <Checkbox checked={checked} onChange={onChange} />
}

const CurrentCheckbox = props => {
  const dispatch = useDispatch()
  const { cname } = useParams()
  const [changed, setChanged] = useState(false)
  const { currentRevisionId, loading, value } = props
  const checked = currentRevisionId === value
  const handleChange = () => {
    dispatch(actions.setCurrentRevision(value))
    setChanged(true)
    setTimeout(() => {
      reloadComponents(cname)
    }, 1000)
  }

  if (changed && loading) {
    const gridSpinnerProps = { height: 16, width: 16 }
    const spinnerContainerProps = { width: "initial", margin: "initial" }
    return (
      <SpinnerWrapper>
        <Spinner
          gridSpinnerProps={gridSpinnerProps}
          spinnerContainerProps={spinnerContainerProps}
        />
      </SpinnerWrapper>
    )
  }

  return (
    <SelfUpdatingCheckbox
      checked={checked}
      changed={changed}
      onChange={handleChange}
      setChanged={setChanged}
    />
  )
}

export default CurrentCheckbox
