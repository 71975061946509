import React, { useEffect } from "react"

import { getJwt } from "../../lib/jwt"
import { signout } from "../../lib/signout"
import { useApolloClient } from "@apollo/client"

const AuthWatcher = props => {
  const client = useApolloClient()
  useEffect(() => {
    const interval = window.setInterval(() => {
      if (getJwt() === null) {
        /*
         * This is required to prevent false logouts during Cypress tests.
         * Without this there is a race condition due to the fact that we
         * can't actually maintain localStorage items between page refreshes
         * in cypress. Instead there is a manual step where we rehydrate the
         * JWT stored there. Unfortunately that is what leads to this race
         * condition. Adding this second slightly delayed check should have
         * zero effective impact on the app but ensures that Cypress wins the
         * race. Not me favorite solution, but it works and isn't very
         * intrusive or complex.
         */
        setTimeout(() => {
          if (getJwt() === null) {
            signout(client)
          }
        }, 500)
      }
    }, 1000)
    return () => window.clearInterval(interval)
  }, [client])
  return <>{props.children}</>
}

export default AuthWatcher
