import { curry } from "lodash"

const constructLegend = curry(options => {
  const { legend = {}, highchart } = options || {}
  if (!Object.keys(legend).length) return options

  return {
    ...options,
    highchart: {
      ...highchart,
      legend,
    },
  }
})

export default constructLegend
