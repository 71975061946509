import styled from "styled-components"
import { toast } from "react-toastify"
import { useMutation } from "@apollo/client"
import { useDispatch, useSelector } from "react-redux"

import { Form, Input, SidePane } from "@dbai/ui-staples"

import { useLogger } from "hooks"
import { TAKE_SNAPSHOT } from "queries"
import { selectPackage } from "selectors"
import { actions as packageActions } from "reducers/packageReducer"

const StyledInput = styled(Input)`
  && {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;

    &:focus {
      box-shadow: none;
      border-bottom-width: 2px;
    }
  }
`

const PackageWorkflowButton = props => {
  const { panelOpen, closePanel, customerId, workflowId } = props
  const [snapshot] = useMutation(TAKE_SNAPSHOT)

  const logger = useLogger()
  const dispatch = useDispatch()
  const state = useSelector(selectPackage)

  const handleSubmit = () => {
    const variables = {
      workflowId,
      cid: customerId,
    }

    dispatch(packageActions.createPackage(variables))
      .then(result => {
        closePanel()
        toast.success(
          "We're packaging your Workflow! It will be available shortly."
        )
        return result
      })
      .then(result => {
        const description = result?.data?.createPackage?.image
        return snapshot({ variables: { customerId, workflowId, description } })
      })
      .catch(e => {
        toast.error("There was an error packaging your Workflow!")
        logger.notify(e)
      })
  }

  return (
    <>
      <SidePane
        title="Package Workflow"
        isVisible={panelOpen}
        width="30vw"
        onCloseClicked={closePanel}
        mask
      >
        <Form
          submitText="PACKAGE"
          onSubmit={handleSubmit}
          dispatch={dispatch}
          state={state}
          actions={packageActions}
        >
          <StyledInput className="form-control" name="tag" noLabel />
        </Form>
      </SidePane>
    </>
  )
}

export default PackageWorkflowButton
