import { actions } from "../../reducers/appReducer"
import { addWidgetObserver } from "./widgetObserver"
import { observeYjsMap } from "./utils"

// syncs new widget from yjs
const syncAddWidget = (dispatch, widgetsMap, widgetId) => {
  addWidgetObserver(dispatch, widgetsMap, widgetId)
  const widgetMap = widgetsMap.get(widgetId)
  const widget = widgetMap.toJSON()
  dispatch(actions.addWidget({ widget, widgetId }))
}

const handleLocalChange =
  dispatch =>
  ({ yMap, change, key }) => {
    if (change.action === "add") {
      addWidgetObserver(dispatch, yMap, key)
    }
  }

const handleExternalChange =
  dispatch =>
  ({ yMap, change, key }) => {
    if (change.action === "add") {
      syncAddWidget(dispatch, yMap, key)
    }

    if (change.action === "delete") {
      dispatch(actions.removeWidget({ widgetId: key }))
    }
  }

const widgetsObserver = (yDoc, dispatch) => {
  const specMap = yDoc.getMap("spec")
  const widgetsMap = specMap.get("widgets")
  observeYjsMap({
    yMap: widgetsMap,
    onLocalChange: handleLocalChange(dispatch),
    onExternalChange: handleExternalChange(dispatch),
  })
}

export default widgetsObserver
