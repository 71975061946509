import React from "react"
import { toast } from "react-toastify"
import { useParams } from "react-router"

import {
  Form,
  Input,
  SidePane,
  useFormMutation,
  useCurrentCustomer,
} from "@dbai/ui-staples"

import { TAKE_SNAPSHOT, GET_WORKFLOW_SNAPSHOTS } from "queries"

const snapshotFormInitialState = ({ customerId, id }) => ({
  customerId,
  description: "",
  workflowId: Number(id),
})

const SnapshotDescriptionForm = props => {
  const { id, closePanel } = props
  const [customer] = useCurrentCustomer()
  const refetchQueries = [
    {
      query: GET_WORKFLOW_SNAPSHOTS,
      variables: { customerId: customer.id, id: Number(id) },
    },
  ]
  const { handleSubmit } = useFormMutation(TAKE_SNAPSHOT, {
    afterSubmit: () => {
      closePanel()
      toast.success("Snapshot Created")
    },
    onError: error => {
      toast.error(error.message)
    },
    refetchQueries,
  })

  return (
    <Form
      onSubmit={handleSubmit}
      initialState={snapshotFormInitialState({ customerId: customer.id, id })}
    >
      <Input name="description" />
    </Form>
  )
}

const SnapshotButton = props => {
  const { panelOpen, closePanel } = props
  const { id } = useParams()

  return (
    <>
      <SidePane
        isVisible={panelOpen}
        title="Annotate Snapshot"
        onCloseClicked={closePanel}
      >
        <SnapshotDescriptionForm id={id} closePanel={closePanel} />
      </SidePane>
    </>
  )
}

export default SnapshotButton
