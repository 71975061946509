import { times } from "lodash"

const { getRandomValues, randomFillSync } = crypto

const genRandom =
  (getRandomValues && getRandomValues.bind(crypto)) || randomFillSync
const randInt = () => genRandom(new Uint8Array(1))[0]

// Copied/modified from the SO answer: https://stackoverflow.com/a/2117523/1415470
const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (randInt() & (15 >> (c / 4)))).toString(16)
  )
}

const alphaSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
const numSet = "0123456789"
const alphanumSet = alphaSet + numSet

const alphanumid = (l = 8) =>
  [
    alphaSet[randInt()], // Make sure it starts with a character for Argo.
    ...times(l - 1, () => alphanumSet[randInt() % alphaSet.length]),
  ].join("")

export { uuidv4, alphanumid }
