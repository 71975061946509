import React, { createContext } from "react"

import { useOutputManager, useCommChannel, useHydrateInputs } from "hooks"

export const CommsChannelContext = createContext({})

const CommsChannelsProvider = ({ children }) => {
  useOutputManager()
  const addInputChannel = useCommChannel("add_input")
  const assignInputsChannel = useCommChannel("assign_inputs")
  const rehydrateInputs = useHydrateInputs(assignInputsChannel)
  const completeRequestChannel = useCommChannel(
    "complete_request",
    "complete_request"
  )

  const restartChannels = () => {
    addInputChannel.restart()
    assignInputsChannel.restart()
  }

  const contextValue = {
    addInputChannel,
    restartChannels,
    rehydrateInputs,
    assignInputsChannel,
    completeRequestChannel,
  }

  return (
    <CommsChannelContext.Provider value={contextValue}>
      {children}
    </CommsChannelContext.Provider>
  )
}

export default CommsChannelsProvider
