import formFieldRegistry from "../JSONSchemaForm/formFieldRegistry"

function buildSchema(options, appVariables) {
  const registeredField = formFieldRegistry[options.type]

  if (!registeredField) {
    throw new Error(`Unsupported schema type: ${options.type}`)
  }

  const { generateSchema } = registeredField

  if (!generateSchema) {
    throw new Error(
      `Schema type ${options.type} does not have a generateSchema method`
    )
  }

  return generateSchema(options, appVariables, buildSchema)
}

export default buildSchema
