import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { SidePane } from "@dbai/ui-staples"

import { actions } from "reducers/notebookReducer"
import { selectHistoryPanelOpen } from "selectors"
import Snapshots from "components/pages/Workflows/Edit/Snapshots"

const HistoryPanel = props => {
  const { historyOpen } = props
  if (historyOpen) return <Snapshots />
  return null
}

export default () => {
  const historyOpen = useSelector(selectHistoryPanelOpen)
  const dispatch = useDispatch()
  const closeHistory = e => {
    e.stopPropagation()
    dispatch(actions.setHistoryPanelClosed())
  }

  return (
    <>
      View Snapshots
      <SidePane
        title="SNAPSHOTS"
        isVisible={historyOpen}
        width="60vw"
        onCloseClicked={closeHistory}
      >
        <HistoryPanel historyOpen={historyOpen} />
      </SidePane>
    </>
  )
}
