import React from "react"
import styled from "styled-components"

import FormFieldWrapper from "../FormFieldWrapper"
import SchemaArray from "../containers/SchemaArray"

const GradientBar = styled.div`
  width: 100%;
  height: 20px;
  background: linear-gradient(to right, ${props => props.gradientStops});
`

const ColorScaleCore = props => {
  const { value } = props
  const validColors = value?.filter(({ color }) => color)
  if (!(validColors?.length >= 2)) {
    return <GradientBar gradientStops={"#ffff"} />
  }

  // Sort thresholds by value
  const colors = [...validColors].sort((a, b) => a.value - b.value)

  // Calculate total range
  let totalRange = colors[colors.length - 1].value - colors[0].value

  // Generate gradient stops
  let gradientStops = colors
    .map((threshold, index) => {
      const location = ((threshold.value - colors[0].value) / totalRange) * 100
      return `${threshold.color} ${location}%`
    })
    .join(", ")

  return <GradientBar gradientStops={gradientStops} />
}

const ColorScale = props => {
  return (
    <>
      <FormFieldWrapper noStyle {...props}>
        <ColorScaleCore />
      </FormFieldWrapper>
      <SchemaArray {...props} />
    </>
  )
}
ColorScale.Core = ColorScaleCore
export default ColorScale
