import React from "react"
import { get } from "lodash"
import { Row, Space, Col } from "antd"

import FormList from "../FormList"
import FormFieldWrapper from "../FormFieldWrapper"
import { datetimeFormatter } from "../../lib/format"
import { normalizeFormData } from "../../lib/formData"

const localStyle = { padding: "16px 0" }
const DatetimeFormatOptionsInputCore = props => {
  const { value, schema } = props
  const { metadata } = schema
  const { savePath } = metadata
  const formattedDate = datetimeFormatter(
    savePath ? get(normalizeFormData(value), savePath) : value
  )(new Date())
  return (
    <Row gutter={[0, 16]} style={localStyle}>
      <Col span={24}>
        <Space style={{ fontSize: "16px" }}>
          <span>Format Preview:</span>
          <b>{formattedDate}</b>
        </Space>
      </Col>
      <Col span={24}>
        <FormList {...props} />
      </Col>
    </Row>
  )
}

const DatetimeFormatOptionsInput = props => {
  return (
    <FormFieldWrapper readonly {...props}>
      <DatetimeFormatOptionsInputCore />
    </FormFieldWrapper>
  )
}

DatetimeFormatOptionsInput.Core = DatetimeFormatOptionsInput
export default DatetimeFormatOptionsInput
