import React, { useState, useCallback } from "react"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { Row, Col, Button, Input } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons"

import { actions } from "../../reducers/appReducer"

const { TextArea } = Input

const PublishForm = props => {
  const { onPublish } = props
  const dispatch = useDispatch()
  const [name, setName] = useState("")
  const [description, setDescription] = useState("")

  const handleNameChange = useCallback(
    e => {
      setName(e.target.value)
    },
    [setName]
  )

  const handleDescriptionChange = useCallback(
    e => {
      setDescription(e.target.value)
    },
    [setDescription]
  )

  const publishApp = useCallback(() => {
    dispatch(actions.publishApp({ name, description })).then(() => {
      onPublish()
      toast.success("App published successfully!")
    })
  }, [dispatch, onPublish, name, description])

  return (
    <Row gutter={[0, 16]}>
      <Col span={4}>
        <label>Name:</label>
      </Col>
      <Col span={20}>
        <Input
          showCount
          value={name}
          maxLength={30}
          onChange={handleNameChange}
        />
      </Col>
      <Col span={4}>
        <label>Description:</label>
      </Col>
      <Col span={20}>
        <TextArea value={description} onChange={handleDescriptionChange} />
      </Col>
      <Col span={24}>
        <Button
          type="primary"
          onClick={publishApp}
          icon={<FontAwesomeIcon icon={faCloudDownloadAlt} />}
        >
          Publish
        </Button>
      </Col>
    </Row>
  )
}

export default PublishForm
