import React, { useMemo } from "react"
import { Tabs } from "antd"
import { useQuery } from "@apollo/client"

import { Card, Spinner, ErrorMessage } from "@dbai/ui-staples"

import TestForm from "./TestForm"
import SampleRequests from "./SampleRequests"
import Predictions from "./Predictions"
import { GET_MODEL_REVISION } from "queries"

const Details = props => {
  const { cname, revisionId, model } = props
  const revisionNumber = revisionId ? Number(revisionId) : null
  const variables = { cname, id: revisionNumber }
  const { error, loading, data, refetch } = useQuery(GET_MODEL_REVISION, {
    fetchPolicy: "network-only",
    variables,
  })

  const items = useMemo(() => {
    if (loading || error) return []

    const revision = data.modelRevision

    const isRunning = revision?.isRunning || false
    const signature = revision?.schema || {
      inputs: "[]",
      outputs: "[]",
    }
    const predictions = revision?.predictions || []

    const parsedSignature = {
      inputs: JSON.parse(signature?.inputs || "[]"),
      outputs: JSON.parse(signature?.outputs || "[]"),
    }
    return [
      {
        key: "testModel",
        label: "Test Model",
        children: (
          <TestForm
            model={model}
            signature={parsedSignature}
            revisionId={revisionNumber}
            isRunning={isRunning}
            refetchRevision={refetch}
          />
        ),
      },
      {
        key: "sampleRequest",
        label: "Sample Request",
        children: (
          <SampleRequests
            model={model}
            signature={parsedSignature}
            revisionId={revisionNumber}
            isRunning={isRunning}
          />
        ),
      },
      {
        key: "history",
        label: "Prediction History",
        children: (
          <Predictions
            model={model}
            predictions={predictions}
            revisionId={revisionNumber}
          />
        ),
      },
    ]
  }, [model, revisionNumber, refetch, data, loading, error])

  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  return (
    <Card>
      <Tabs items={items} />
    </Card>
  )
}

export default Details
