import getColumnsForWidgetQuery from "../../lib/getColumnsForWidgetQuery"

const getQueryObjectsFromTable = (columnLabels, values, rowLabels) => {
  const selectColumns = []
  const groups = []
  const filters = []

  const labelColumns = [...columnLabels, ...rowLabels]
  labelColumns.forEach(column => {
    const { labels } = column

    if (labels?.length) {
      const filteredLabels = labels
        .filter(({ usable }) => !usable)
        .map(l => l.label)
      if (filteredLabels.length) {
        filters.push({ column: column.column, op: "!=", value: filteredLabels })
      }
    }

    groups.push(column.column)
  })

  values?.forEach(value => {
    if (value.columnType === "computed") {
      value.computation?.forEach(expression => {
        const { propertyType, type, aggregate, column } = expression
        if (propertyType === "datasetColumn") {
          selectColumns.push({
            column,
            type,
            aggregate,
            // use formatting options for the computation rather than for the individual column
            format: value.format,
            formatOptions: value.formatOptions,
          })
        }
      })
      return
    }

    if (value.columnType === "datasetColumn") {
      selectColumns.push({
        type: value.type,
        column: value.column,
        aggregate: value.aggregate,
        format: value.format,
        formatOptions: value.formatOptions,
      })
    }
  })

  return [
    getColumnsForWidgetQuery(selectColumns),
    Array.from(new Set([...groups])),
    filters,
  ]
}

const queryResolver = widget => {
  const {
    limit,
    offset,
    format,
    datasetId,
    where = [],
    values = [],
    pollInterval,
    rowLabels = [],
    columnLabels = [],
  } = widget || {}
  const [select, groupBy, labelWhere] = getQueryObjectsFromTable(
    columnLabels,
    values,
    rowLabels
  )
  return {
    limit,
    offset,
    format,
    select,
    groupBy,
    datasetId,
    pollInterval,
    where: [...labelWhere, ...where],
  }
}

export default queryResolver
