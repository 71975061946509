import { get } from "lodash"

export const DBAI_SET = "dbai/set"
export const DBAI_APPEND = "dbai/append"
export const DBAI_CLEAR = "dbai/clear"

/*
 * Please access formSet, formAppend, and formRemove via their respective
 * hooks to maintain flexibility. Leaving these here for backwards
 * compatability for now.
 */
export const formSet = (dispatch, { name, value }) => {
  return dispatch({
    type: DBAI_SET,
    name: name,
    value: value,
  })
}

export const formAppend = (dispatch, { name, value }) => {
  return dispatch({
    type: DBAI_APPEND,
    name: name,
    value: value,
  })
}

export const formRemove = (dispatch, { name, filter }) => {
  return dispatch((dispatch, getState) => {
    const value = get(getState(), name, []).filter(filter)
    formSet(dispatch, { name, value })
  })
}

export const formClear = dispatch => {
  return dispatch(dispatch => dispatch({ type: DBAI_CLEAR }))
}
