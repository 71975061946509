import { curry } from "lodash"

const getNumFromColumn = (row, column) => {
  const candidate = row && row[column]
  switch (typeof candidate) {
    case "string":
      return Number(candidate)
    case "number":
      return candidate
    default:
      return 0
  }
}

const numberSorter = curry((column, a, b) => {
  const aNum = getNumFromColumn(a, column)
  const bNum = getNumFromColumn(b, column)
  return aNum - bNum
})

export default numberSorter
