import React, { useEffect, useState, useCallback } from "react"
import { Alert } from "antd"
import styled from "styled-components"

import { CodeEditor } from "@dbai/ui-staples"

import { useLocalFormFieldControls } from "../hooks"
import { withFormFieldWrapper } from "../FormFieldWrapper"

const StyledAlert = styled(Alert)`
  z-index: 1000;
  position: sticky;
  bottom: 0;
`

const JavascriptCodeEditor = props => {
  const { value: _value = {}, schema, onChange } = props
  const { metadata } = schema
  const {
    width,
    height,
    options,
    showError,
    containerStyle,
    mode = "javascript",
  } = metadata
  const [error, setError] = useState()
  const [codeMirror, setCodeMirror] = useState()

  const handleChange = useCallback(
    val => {
      try {
        const jsonData = val.getValue()

        if (onChange) {
          // TODO: add auto formatting using prettier.
          // const formatted = prettier.format(jsonData, {
          //   parser: "html",
          //   plugins: [htmlParser.parsers.html],
          // })
          // onChange(await formatted)
          onChange(jsonData)
        }
        if (showError) {
          // new Function(jsonData) // Test if it's valid JavaScript
          setError(null) // Reset error if it's valid
        }
      } catch (err) {
        console.error(err)
        setError(`Error: ${err}`)
      }
    },
    [onChange, showError]
  )

  const [value, updateValue] = useLocalFormFieldControls(
    _value,
    handleChange,
    2000
  )

  /**
   * Its possible for the code editor to be lazy loaded. When it is lazy loaded, Code Mirror
   * will have an initial height of 10px. To prevent that from happening, this
   * effect hook will force the width/height of the editor once it's rendered to the DOM.
   */
  useEffect(() => {
    if (codeMirror) {
      codeMirror.setSize(width || "100%", height || "300px")
      codeMirror.refresh()
    }
  }, [codeMirror, width, height])

  const initCodeMirror = useCallback(
    codeMirror => {
      codeMirror.on("change", updateValue)
      setCodeMirror(codeMirror)
    },
    [updateValue]
  )

  return (
    <div style={containerStyle}>
      <CodeEditor
        mode={mode}
        options={options}
        initialValue={value}
        initCodeMirror={initCodeMirror}
      />
      {error && showError ? (
        <StyledAlert
          type="error"
          description={error}
          message="Invalid Javascript"
        />
      ) : null}
    </div>
  )
}

JavascriptCodeEditor.Core = JavascriptCodeEditor
export default withFormFieldWrapper(JavascriptCodeEditor)
