import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import { Html } from "react-konva-utils"
import { Group, Rect } from "react-konva"

const HoistedDiv = styled.div`
  border: none;
  width: 100%;
  height: 100%;
  overflow: auto;
`

const CanvasRichText = React.forwardRef((props, ref) => {
  const { text, width, height, stageRef, ...rest } = props
  const divRef = useRef()
  useEffect(() => {
    setTimeout(() => {
      if (!divRef.current) return
      const child = divRef.current.parentElement
      const parent = stageRef.current?.container()
      if (!parent) return
      parent.removeChild(child)
      stageRef.current.content.prepend(child)
    }, 50)
  }, [stageRef])
  return (
    <Group ref={ref} width={width} height={height} {...rest}>
      <Rect
        fill="red"
        width={width}
        height={height}
        cornerRadius={5}
        preventDefault={false}
        globalCompositeOperation="destination-out"
      />
      <Html
        divProps={{
          style: {
            overflow: "hidden",
            zIndex: 0,
            position: "absolute",
            width: `${width}px`,
            height: `${height}px`,
          },
        }}
      >
        <HoistedDiv ref={divRef}>
          <div dangerouslySetInnerHTML={{ __html: text }}></div>
        </HoistedDiv>
      </Html>
    </Group>
  )
})

export default CanvasRichText
