import React from "react"
import VersionWatcher from "./VersionWatcher"

const Footer = props => {
  return (
    <footer>
      <VersionWatcher />
      {props.children}
    </footer>
  )
}

export default Footer
