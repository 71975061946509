import React from "react"
import styled from "styled-components"
import { ConfigProvider, theme } from "antd"
import { ThemeProvider } from "styled-components"

import { getSharableTheme } from "@dbai/ui-staples"

import useTheme from "./hooks/useTheme"

const { useToken } = theme
const FontFamilyProvider = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${props => props.primary || "industry"};
  }
  .ant-table-thead {
    font-family: ${props => props.primary || "industry"};
  }
  .ant-tabs-tab {
    font-family: ${props => props.primary || "industry"};
  }
  .highcharts-title {
    font-family: ${props => props.primary || "industry"};
  }
`

const StyledComponentsThemeProvider = props => {
  const { children, themeMode } = props
  const { token } = useToken()
  const theme = getSharableTheme(token, themeMode)
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

const AppConfig = props => {
  const { children } = props
  const { token, algorithm, themeMode, headerFontFamily } = useTheme()
  return (
    <ConfigProvider theme={{ token, algorithm }}>
      <FontFamilyProvider primary={headerFontFamily}>
        <StyledComponentsThemeProvider themeMode={themeMode}>
          {children}
        </StyledComponentsThemeProvider>
      </FontFamilyProvider>
    </ConfigProvider>
  )
}

export { StyledComponentsThemeProvider }
export default AppConfig
