import React, { useState, forwardRef, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import numeral from "numeral"

import PageView from "./PageView"
import BreakView from "./BreakView"

const PageList = styled.ul`
  position: sticky;
  background-color: ${props => props.theme.neutral};
  text-align: center;
  height: 40px;
  bottom: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  list-style: none;
  a {
    color: black;
    float: left;
    padding: 8px 0;
    text-decoration: none;
    transition: background-color 0.3s;
    width: 100%;
    height: 100%;
    &:hover {
      background-color: ${props => props.theme.background};
    }
  }
  li {
    width: 50px;
    height: 40px;
    cursor: pointer;
  }
  li.active {
    a {
      background-color: ${props => props.theme.linkActive};
      color: white;
    }
  }
  li.disabled {
    cursor: not-allowed;
    a {
      background-color: ${props => props.theme.text};
      color: white;
      &:hover {
        background-color: ${props => props.theme.background};
      }
    }
  }
`

const Pagination = forwardRef((props, ref) => {
  const {
    initialPage,
    hideOnSinglePage,
    disableInitialCallback,
    forcePage,
    onPageChange,
    pageCount,
    previousLabel,
    nextLabel,
    ...rest
  } = props

  const initialSelected = () => {
    if (initialPage) {
      return initialPage
    } else if (forcePage) {
      return forcePage
    }
    return 1
  }

  const [selected, setSelected] = useState(initialSelected())

  useEffect(() => {
    // Call the callback with the initialPage item:
    if (initialPage && !disableInitialCallback) {
      callCallback(initialPage)
    }
  })

  useEffect(() => {
    if (forcePage && forcePage !== selected) {
      setSelected(forcePage)
    }
  }, [forcePage, selected])

  const onPreviousPage = evt => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false)
    if (selected > 1) {
      onPageSelected(selected - 1, evt)
    }
  }

  const onNextPage = evt => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false)
    if (selected < pageCount) {
      onPageSelected(selected + 1, evt)
    }
  }

  const onPageSelected = (newlySelected, evt) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false)

    if (selected === newlySelected) return

    setSelected(newlySelected)

    // Call the callback with the new selected item:
    callCallback(newlySelected)
  }

  const callCallback = selectedItem => {
    if (onPageChange && typeof onPageChange === "function") {
      onPageChange(selectedItem)
    }
  }

  const hrefBuilder = pageIndex => {
    if (pageIndex !== selected && pageIndex >= 1 && pageIndex < pageCount) {
      return numeral(pageIndex).format("0,0")
    }
  }

  const previousClasses = () => {
    return "previous" + (selected === 1 ? " disabled" : "")
  }

  const nextClasses = () => {
    const lastPage = selected === pageCount
    return "next" + (lastPage ? " disabled" : "")
  }

  if (hideOnSinglePage && pageCount === 1) {
    return null
  }

  return (
    <PageList ref={ref} className="pagination">
      <li className={previousClasses()}>
        <a
          onClick={onPreviousPage}
          href={hrefBuilder(selected - 1)}
          tabIndex="0"
          role="button"
          onKeyPress={onPreviousPage}
        >
          {previousLabel}
        </a>
      </li>

      <PaginationList
        selected={selected}
        pageCount={pageCount}
        onPageSelected={onPageSelected}
        hrefBuilder={hrefBuilder}
        {...rest}
      />

      <li className={nextClasses()}>
        <a
          onClick={onNextPage}
          href={hrefBuilder(selected + 1)}
          tabIndex="0"
          role="button"
          onKeyPress={onNextPage}
        >
          {nextLabel}
        </a>
      </li>
    </PageList>
  )
})

const getSides = (pageRangeDisplayed, pageCount, selected) => {
  let leftSide = pageRangeDisplayed / 2
  let rightSide = pageRangeDisplayed - leftSide

  if (selected > pageCount - pageRangeDisplayed / 2) {
    rightSide = pageCount - selected
    leftSide = pageRangeDisplayed - rightSide
  } else if (selected < pageRangeDisplayed / 2) {
    leftSide = selected
    rightSide = pageRangeDisplayed - leftSide
  }

  return { left: leftSide, right: rightSide }
}

const PaginationList = props => {
  const {
    pageRangeDisplayed,
    selected,
    breakLabel,
    hrefBuilder,
    onPageSelected,
    pageCount,
    marginPagesDisplayed,
  } = props

  const getForwardJump = () => {
    const forwardJump = selected + pageRangeDisplayed
    return forwardJump >= pageCount ? pageCount - 1 : forwardJump
  }

  const getBackwardJump = () => {
    const backwardJump = selected - pageRangeDisplayed
    return backwardJump < 1 ? 1 : backwardJump
  }

  const getPageElement = index => {
    return (
      <PageView
        key={index}
        onClick={onPageSelected.bind(null, index)}
        selected={selected === index}
        href={hrefBuilder(index)}
        page={index}
      />
    )
  }

  const getBreakElement = index => {
    return (
      <BreakView
        key={index}
        breakLabel={breakLabel}
        onClick={onBreakClick.bind(null, index)}
      />
    )
  }

  const onBreakClick = (index, evt) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false)

    onPageSelected(selected < index ? getForwardJump() : getBackwardJump(), evt)
  }

  if (pageCount <= pageRangeDisplayed) {
    const items = []
    for (let index = 1; index < pageCount + 1; index++) {
      items.push(getPageElement(index))
    }
    return items
  }

  // Range up to count pages. Yield the current index for every page that should be
  // rendered and a single break element on either side if needed
  function* rangePages(count) {
    let current = 1
    const { left, right } = getSides(pageRangeDisplayed, pageCount, selected)
    const isMargin = () => {
      const isLowerMargin = current <= marginPagesDisplayed
      const isUpperMargin = current > pageCount - marginPagesDisplayed
      return isLowerMargin || isUpperMargin
    }

    const isCenter = () => {
      return current >= selected - left && current <= selected + right
    }

    const isBreakFn = () => {
      const isLeft = current === selected - Math.ceil(left)
      const isRight = current === selected + Math.ceil(right)
      const isEnd = current === pageCount
      return isLeft || (isRight && !isEnd)
    }

    while (current <= count) {
      const isBreak = isBreakFn()

      if (isMargin() || isCenter() || isBreak) {
        yield { index: current, isBreak }
      }

      current += 1
    }
  }

  const items = [...rangePages(pageCount)].map(({ index, isBreak }) => {
    if (isBreak) return getBreakElement(index)
    return getPageElement(index)
  })

  return items
}

Pagination.propTypes = {
  pageCount: PropTypes.number.isRequired,
  pageRangeDisplayed: PropTypes.number.isRequired,
  marginPagesDisplayed: PropTypes.number.isRequired,
  previousLabel: PropTypes.node,
  nextLabel: PropTypes.node,
  breakLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hrefBuilder: PropTypes.func,
  onPageChange: PropTypes.func,
  initialPage: PropTypes.number,
  forcePage: PropTypes.number,
  disableInitialCallback: PropTypes.bool,
  hideOnSinglePage: PropTypes.bool,
}

Pagination.defaultProps = {
  pageCount: 10,
  pageRangeDisplayed: 2,
  marginPagesDisplayed: 1,
  breakLabel: "...",
  previousLabel: " < ",
  nextLabel: " > ",
  disableInitialCallback: true,
  hideOnSinglePage: false,
}

export default Pagination
