import React from "react"
import { Col, Button, Space } from "antd"
import { useQuery, useMutation } from "@apollo/client"
import { useParams, useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faStop, faPlay } from "@fortawesome/free-solid-svg-icons"

import {
  Content,
  PageSpinner,
  ErrorMessage,
  TableWithFolders,
  useCurrentCustomer,
} from "@dbai/ui-staples"

import { numberSorter } from "@dbai/tool-box"

import Details from "./Details"
import DBBoundary from "components/shared/DBBoundary"
import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"
import {
  GET_MODEL,
  GET_MODEL_REVISIONS,
  START_MODEL_REVISION_SERVER,
  STOP_MODEL_REVISION_SERVER,
} from "queries"

const TableOptions = props => {
  const [customer] = useCurrentCustomer()
  const cname = customer.normalizedName
  const { row } = props
  const { isRunning } = row
  const [startServer] = useMutation(START_MODEL_REVISION_SERVER, {
    refetchQueries: [GET_MODEL_REVISIONS],
  })
  const [stopServer] = useMutation(STOP_MODEL_REVISION_SERVER, {
    refetchQueries: [GET_MODEL_REVISIONS],
  })

  const variables = { cname, mrId: row.id }

  return (
    <Space>
      <div className="btn-group">
        {isRunning ? (
          <Button danger onClick={() => stopServer({ variables })}>
            <FontAwesomeIcon icon={faStop} className="action-icon" />
          </Button>
        ) : (
          <Button onClick={() => startServer({ variables })}>
            <FontAwesomeIcon icon={faPlay} className="action-icon" />
          </Button>
        )}
      </div>
    </Space>
  )
}

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    sorter: numberSorter("id"),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "",
    key: "actions",
    dataIndex: "actions",
    render: props => <TableOptions {...props} />,
  },
]

const columnOrder = ["id", "type", "createdAt", "updatedAt", "actions"]

const RevisionsTable = props => {
  const { cname, modelId, selectedModelRevisionId, onModelRevisionChange } =
    props
  const variables = { cname, modelId }
  const { error, loading, data } = useQuery(GET_MODEL_REVISIONS, {
    fetchPolicy: "network-only",
    variables,
  })
  const rowSelection = {
    type: "radio",
    onChange: (_, selectedRows) => {
      onModelRevisionChange(selectedRows[0].id)
    },
    ...(Boolean(selectedModelRevisionId) && {
      selectedRowKeys: [parseInt(selectedModelRevisionId)],
    }),
  }

  if (loading) return <PageSpinner />
  if (error) return <ErrorMessage error={error} />

  const { modelRevisions } = data

  return (
    <DBBoundary>
      <TableWithFolders
        columns={columns}
        columnOrder={columnOrder}
        folderType="modelRevisions"
        dataSource={modelRevisions}
        rowSelection={rowSelection}
      />
    </DBBoundary>
  )
}

const Edit = props => {
  const { customer } = props
  const { id: modelId, modelRevisionId } = useParams()
  const history = useHistory()
  const variables = { cname: customer.normalizedName, id: modelId }
  const { data, loading, error } = useQuery(GET_MODEL, {
    variables,
  })

  if (loading) return <PageSpinner />
  if (error) return <ErrorMessage error={error} />

  const model = data.customer.model

  const modelsRoute = `/${customer.normalizedName}/models`

  const routes = [
    { breadcrumbName: customer.name },
    { breadcrumbName: "Models", url: modelsRoute },
    { breadcrumbName: model.name, url: `${modelsRoute}/${model.id}` },
  ]

  if (modelRevisionId) {
    routes.push({
      breadcrumbName: modelRevisionId,
      url: `${modelsRoute}/${model.id}/${modelRevisionId}`,
    })
  }

  const onModelRevisionChange = newModelRevisionId => {
    if (newModelRevisionId) {
      history.replace(`${modelsRoute}/${model.id}/${newModelRevisionId}`)
    } else {
      history.replace(`${modelsRoute}/${model.id}`)
    }
  }

  return (
    <>
      <PageHeader routes={routes} />
      <Content>
        <Col span={8}>
          <RevisionsTable
            model={model}
            cname={customer.normalizedName}
            customerId={customer.id}
            modelId={modelId}
            selectedModelRevisionId={modelRevisionId}
            onModelRevisionChange={onModelRevisionChange}
          />
        </Col>
        <Col span={16}>
          {modelRevisionId && (
            <Details
              model={model}
              revisionId={modelRevisionId}
              cname={customer.normalizedName}
            />
          )}
        </Col>
      </Content>
    </>
  )
}

export default withCustomer(Edit)
