import * as Y from "yjs"
import { isEqual } from "lodash"

import { yDoc, createNewYCell, initNode, initCell } from "."

/*
 * This file contains functions used to update the state of Yjs as it pertains
 * to Notebooks. Most of the functions are short and semantically named, and
 * I've attempted to provide descriptive comments on any that I thought might
 * be less obvious in it's arguments or affects.
 *
 * IMPORTANT: All yDoc operations _must_ start with a guard statement that
 * makes it a no-op if yDoc is not defined. There should be _no_ side effects
 * or operations that modify _any_ values outside of the yDoc.
 */

/*
 * Used prior to performing a a Yjs related action. Prevents errors from
 * pre-initialization requests. Any state changes that happen prior to
 * initialization should still be picked up on during the initial document
 * sync.
 */
const yNotReady = () => {
  if (!yDoc) return true

  const nodes = yDoc.get("nodes")
  return !(nodes instanceof Y.Map)
}

export const inYTransaction = funcs => {
  if (yNotReady()) return

  if (!funcs) return
  yDoc.transact(() => {
    funcs.forEach(func => func())
  })
}

export const getYDocNode = nodeId => {
  if (yNotReady()) return

  const nodes = yDoc.getMap("nodes")
  return nodes.get(nodeId)
}

export const removeYDocCell = ({ nodeId, cellIdx }) => {
  if (yNotReady()) return

  const node = getYDocNode(nodeId)
  const cells = node && node.get("cells")
  cells && cells.delete(cellIdx, 1)
}

export const removeYDocNode = nodeId => {
  if (yNotReady()) return

  const nodes = yDoc.getMap("nodes")
  const node = nodes.get(nodeId)
  if (node) nodes.delete(nodeId)
}

/*
 * criteria is expected to be one of the following shapes:
 * { target: "ab42hvb", }
 * { source: "bg02nNrx" }
 * { source: "bg02nNrx", target: "ab42hvb", }
 * removeYDocEdge will attempt to match on all key/values given prior to
 * removing.
 */
export const removeYDocEdge = criteria => {
  if (yNotReady()) return

  return yDoc.transact(() => {
    const edges = yDoc.getArray("edges")
    const criteriaEntries = Object.entries(criteria)

    // Let's not delete everything if someone calls this with an empty object.
    if (!criteriaEntries.length) return

    edges.forEach((edge, index, yArray) => {
      const matches = criteriaEntries.map(([key, value]) => {
        return edge[key] === value
      })

      if (!matches.includes(false)) {
        // Fun fact: Our index is a moving target if this causes more than one
        // edge deletion!
        const jsonEdges = yArray.toJSON()
        const currentIndex = jsonEdges.findIndex(e => isEqual(e, edge))

        yArray.delete(currentIndex, 1)
      }
    })
  })
}

export const addYDocEdge = edge => {
  if (yNotReady()) return

  const edges = yDoc.getArray("edges")
  edges.push([edge])
}

const getSafeCellIdx = (cells, cellIdx) => {
  if (typeof cellIdx === "number") return cellIdx
  return cells && cells.length
}

export const insertYDocCell = cellData => {
  if (yNotReady()) return
  const { nodeId, newCell, cellIdx } = cellData

  return yDoc.transact(() => {
    const nodes = yDoc.getMap("nodes")

    if (!nodes.get(nodeId)) {
      nodes.set(nodeId, new Y.Map())
    }

    const node = nodes.get(nodeId)

    if (!node.get("cells")) {
      node.set("cells", new Y.Array())
    }

    const cells = node.get("cells")

    // If no cellIdx is given, append.
    const safeCellIdx = getSafeCellIdx(cells, cellIdx)
    const newYCell = createNewYCell(newCell)

    cells.insert(safeCellIdx, [newYCell])
  })
}

export const addYDocNode = (node, dispatch) => {
  if (yNotReady()) return

  initNode(yDoc, dispatch, node)
}

// Items that we sync in some other way or that we don't want to ever modify.
const filteredAttributes = ["cells", "edges", "id"]
const trimKey = key => key.split(/\[|\./)[0]

export const updateYDocNode = (node, updatedKeys) => {
  if (yNotReady()) return

  return yDoc.transact(() => {
    const nodes = yDoc.get("nodes")
    const yNode = nodes.get(node.id)
    if (!yNode) {
      console.warn(`${node.id} not found`)
      return
    }

    const updateAttribute = ([key, value]) => {
      if (filteredAttributes.includes(key)) return

      yNode.set(key, value)
    }

    if (updatedKeys) {
      return updatedKeys.map(trimKey).forEach(key => {
        updateAttribute([key, node[key]])
      })
    }

    return Object.entries(node).forEach(updateAttribute)
  })
}

export const getYCellByIdAndIndex = (nodeId, cellIdx) => {
  if (yNotReady()) return

  const nodes = yDoc.get("nodes")
  const yNode = nodes.get(nodeId)
  const cells = yNode.get("cells")
  return cells.get(cellIdx)
}

export const updateYDocCellOutputs = (nodeId, cellIdx, outputs) => {
  if (yNotReady()) return

  const cell = getYCellByIdAndIndex(nodeId, cellIdx)
  cell.set("outputs", outputs)
}

export const updateYDocCellExecutionCount = (nodeId, cellIdx, count) => {
  if (yNotReady()) return

  const cell = getYCellByIdAndIndex(nodeId, cellIdx)
  cell.set("executionCount", count)
}

export const updateYDocCellMetadata = (nodeId, cellIdx, metadata) => {
  if (yNotReady()) return

  const cell = getYCellByIdAndIndex(nodeId, cellIdx)
  cell.set("metadata", metadata)
}

export const updateYDocCellType = (nodeId, cellIdx, cellType) => {
  if (yNotReady()) return

  const cell = getYCellByIdAndIndex(nodeId, cellIdx)
  cell.set("cellType", cellType)
}

export const syncYCellMetaData = (cell, yCell) => {
  if (yNotReady()) return

  const { source, ...rest } = cell
  Object.entries(rest).forEach(([key, val]) => yCell.set(key, val))
}

export const syncYCell = (cell, yCell) => {
  const { source = [] } = cell
  const yText = yCell.get("source")
  if (yText) {
    yText.delete(0, yText.length)
    yText.insert(0, source.join(""))
  }

  syncYCellMetaData(cell, yCell)
}

export const syncYEdges = spec => {
  if (yNotReady()) return

  const yEdges = yDoc.get("edges")

  if (!yEdges) return

  const { edges = [] } = spec

  inYTransaction([
    () => yEdges.delete(0, yEdges.length),
    () => yEdges.push(edges),
  ])
}

export const ySetWorkflowName = name => {
  if (yNotReady()) return

  yDoc.getMap("metadata").set("name", name)
}

const findYCellByUuid = (yCells, uuid) => {
  let yCell = null
  let idx = null
  yCells &&
    yCells.forEach((yc, i) => {
      if (yc.get("uuid") === uuid) {
        yCell = yc
        idx = i
      }
    })
  return { yCell, idx }
}

/*
 * Given a node and a Y.Map representing a node, updates the cells array of
 * the Y.Map and brings it in line with the cells of the node.
 */
const syncNodeCells = (node, yNode) => {
  const cells = node.cells || []
  const cellUuids = cells.map(c => c.uuid)
  const yCells = yNode.get("cells")

  cells.forEach((cell, idx) => {
    const { yCell } = findYCellByUuid(yCells, cell.uuid)
    if (yCell) {
      syncYCell(cell, yCell)
    } else {
      yCells.insert(idx, [initCell(yNode, cell)])
    }
  })

  if (!yCells) return
  // Now it's time to remove any cells that were not contained in the new
  // spec. We have to lookup the given cell each time we find a hit due to the
  // index changing every time we delete a cell. The procedural nature of Y
  // can sometimes be a little cumbersome.
  yCells.forEach((yCell, _, yArray) => {
    const uuid = yCell.get("uuid")
    if (!cellUuids.includes(uuid)) {
      const { idx } = findYCellByUuid(yArray, uuid)
      idx && yArray.delete(idx, 1)
    }
  })
}

export const syncYNode = dispatch => node => {
  const nodes = yDoc.getMap("nodes")
  const yNode = nodes.get(node.id)
  if (!yNode) {
    initNode(yDoc, dispatch, node)
    return
  }

  updateYDocNode(node)
  syncNodeCells(node, yNode)
}

const syncYNodes = (spec, dispatch) => {
  const nodeIds = spec.nodes.map(node => node.id)
  const yNodes = yDoc.getMap("nodes")
  yNodes.forEach((val, key) => {
    if (!nodeIds.includes(key)) yNodes.delete(key)
  })
  spec.nodes.forEach(syncYNode(dispatch))
}

/*
 * Sync the entire notebook spec.
 */
export const syncSpec = (spec, dispatch) => {
  if (yNotReady()) return
  inYTransaction([() => syncYNodes(spec), () => syncYEdges(spec)])
}
