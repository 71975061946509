import { curry } from "lodash"

import { formatColumnValue } from "../../../lib/datasetColumns"

const pointFormatters = {
  boxplot: options =>
    function (tooltip) {
      const { point } = this
      const { high, median, low, q1, q3 } = point.options
      const yDatasetColumn = point.series.options.custom.datasetColumn
      const tooltipOptions = tooltip.options

      const formattedHigh = formatPointValue(
        tooltipOptions,
        yDatasetColumn,
        high
      )
      const formattedMedian = formatPointValue(
        tooltipOptions,
        yDatasetColumn,
        median
      )
      const formattedLow = formatPointValue(tooltipOptions, yDatasetColumn, low)
      const formattedQ3 = formatPointValue(tooltipOptions, yDatasetColumn, q3)
      const formattedQ1 = formatPointValue(tooltipOptions, yDatasetColumn, q1)
      const category = formatPointCategory(
        point,
        point.category || this.x || point.x
      )
      return `
        ${category}<br>
        <b><span style="color:${point.series.color}">●</span>${point.series.name}</b><br>
        Maximum: ${formattedHigh}<br>
        Upper quartile: ${formattedQ3}<br>
        Median: ${formattedMedian}<br>
        Lower quartile: ${formattedQ1}<br>
        Minimum: ${formattedLow}
      `
    },
  heatmap: options =>
    function (tooltip) {
      const { point } = this
      const { series, x, y, value } = point
      const { options, xAxis, yAxis } = series
      const columns = options.custom.columns
      const yAxisColumn = yAxis.categories[y]
      const yAxisColumnConfig = columns[yAxisColumn]
      const formattedValue = formatPointValue(
        tooltip.options,
        yAxisColumnConfig,
        value
      )
      return xAxis.categories[x] + ", " + yAxisColumn + ": " + formattedValue
    },
  pie: options =>
    function (tooltip) {
      const { name, percentage, y, series } = this.point
      const yDatasetColumn = series.options.custom?.datasetColumn
      const percentPrecision = 1
      const metricLabel = options.custom?.tooltip?.metricLabel || series.name
      const formattedY = formatPointValue(tooltip.options, yDatasetColumn, y)
      const valueFormat = options.custom?.tooltip?.valueFormat
      const percentValue = `${percentage.toFixed(percentPrecision)}%`

      //defaults to 'both'
      let value = `${formattedY} (${percentValue})`

      if (valueFormat === "value") {
        value = formattedY
      } else if (valueFormat === "percent") {
        value = percentValue
      }

      return `<b>${name}</b><br/>${metricLabel}<br/> ${value}`
    },
  defaultPointFormatter: options =>
    function (tooltip) {
      let tooltipContent = ""
      const points = this.points || [this.point]
      const category = formatPointCategory(points[0], this.x)

      points.forEach(point => {
        const seriesName = point.series.name
        const { datasetColumn } = point.series.options.custom || {}
        const yValue = formatPointValue(tooltip.options, datasetColumn, point.y)
        tooltipContent += `<span style="color:${point.series.color}">●</span>${seriesName}: ${yValue}<br>`
      })

      return `<b>${category}</b><br>${tooltipContent}`
    },
}

const formatPointValue = (tooltipOptions, column, value) => {
  const safeColumn = column || {}
  const formatOptions = safeColumn.formatOptions || {}
  const { minPrecision, prefix, suffix, ...rest } = formatOptions
  const { valueDecimals, valuePrefix, valueSuffix } = tooltipOptions || {}
  const columnWithFormatOptions = {
    ...safeColumn,
    format: safeColumn.format || "number",
    formatOptions: {
      minPrecision: minPrecision || valueDecimals,
      prefix: prefix || valuePrefix,
      suffix: suffix || valueSuffix,
      ...rest,
    },
  }

  return formatColumnValue(columnWithFormatOptions, value)
}

const formatPointCategory = (point, category) => {
  const { xAxisColumn } = point.series.options.custom || {}
  if (!xAxisColumn) {
    return category
  }
  return formatColumnValue(xAxisColumn, category)
}

const getFormatterOptions = (options, columns) => {
  const { highchart = {} } = options || {}
  const { chart } = highchart
  const { type } = chart || {}
  const formatter =
    pointFormatters[type] || pointFormatters.defaultPointFormatter
  return {
    formatter: formatter(options, columns),
  }
}

const constructTooltip = curry((dataset, selectedPoints, options) => {
  const { highchart = {}, tooltip = {} } = options || {}

  return {
    ...options,
    highchart: {
      ...highchart,
      tooltip: {
        ...tooltip,
        ...getFormatterOptions(options),
        positioner: function (labelWidth, labelHeight, point) {
          if (selectedPoints?.current?.length) {
            return { x: 10, y: 10 }
          }
          return this.getPosition.apply(this, arguments)
        },
      },
    },
  }
})

export default constructTooltip
