import React from "react"
import { ConfigProvider } from "antd"
import styled from "styled-components"
import { useSelector } from "react-redux"

import useTheme from "../../hooks/useTheme"
import { getThemeColors } from "../../lib/theme"
import { selectAppSpec } from "../../selectors/app"
import { StyledComponentsThemeProvider } from "../../AppConfig"

const LayoutContainer = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${props => props.backgroundColor || "transparent"};
`

const generateWidgetTheme = (widget, appTheme) => {
  const {
    token = {},
    darkModeColors,
    lightModeColors,
    backgroundColor,
  } = widget || {}
  const { mode } = appTheme || {}
  switch (true) {
    case Boolean(backgroundColor):
      return {
        token: {
          ...token,
          colorBgBase: backgroundColor,
        },
      }
    case Boolean(lightModeColors) || Boolean(darkModeColors):
      return {
        token: {
          ...token,
          ...getThemeColors(mode, lightModeColors, darkModeColors),
        },
      }
    case Boolean(Object.keys(token).length):
      return { token }
    default:
      return null
  }
}

const ThemeWrapper = props => {
  const { widget, children } = props
  const appSpec = useSelector(selectAppSpec)
  const { theme } = appSpec
  const widgetTheme = generateWidgetTheme(widget, theme)
  const { themeMode } = useTheme()
  if (widgetTheme) {
    const { colorBgBase } = widgetTheme.token
    return (
      <ConfigProvider theme={widgetTheme}>
        <StyledComponentsThemeProvider themeMode={themeMode}>
          <LayoutContainer backgroundColor={colorBgBase}>
            {children}
          </LayoutContainer>
        </StyledComponentsThemeProvider>
      </ConfigProvider>
    )
  }
  return children
}

export default ThemeWrapper
