import React, { useState, useMemo } from "react"
import { Tabs } from "antd"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import { useQuery, useMutation } from "@apollo/client"

import { AuthBoundary } from "@dbai/ui-staples"

import DatasetPreview from "./DatasetPreview"
import ComputedColumns from "./ComputedColumns"
import PageHeader from "components/shared/PageHeader"
import ColumnMetadataTable from "./ColumnMetadataTable"
import withCustomer from "components/shared/withCustomer"
import { GET_DATASET_OVERVIEW, UPDATE_DATASET_NAME } from "queries"

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding: 0 16px;
    margin-bottom: 0;
    z-index: 1;
  }
`

const DatasetHeader = props => {
  const { customer } = props
  const { id } = useParams()
  const [name, setName] = useState()
  const [updateDataset] = useMutation(UPDATE_DATASET_NAME)
  const { loading, error } = useQuery(GET_DATASET_OVERVIEW, {
    fetchPolicy: "network-only",
    variables: { id, customerId: customer.id },
    onCompleted: data => {
      setName(data?.dataset?.name)
    },
  })

  const routes = useMemo(
    () => [
      { breadcrumbName: customer.name },
      {
        breadcrumbName: "Datasets",
        url: `/${customer.normalizedName}/datasets/`,
      },
      {
        value: name,
        handleSave: (value, e) => {
          updateDataset({
            variables: { id, customerId: customer.id, name: value },
          })
        },
        onChange: (value, e) => {
          setName(value)
        },
      },
    ],
    [name, customer, id, updateDataset]
  )

  if (loading || error) return null
  return <PageHeader routes={routes} />
}

const EditDataset = props => {
  const { customer } = props
  const [selectedKey, setSelectedKey] = useState("preview")

  const items = useMemo(() => {
    return [
      {
        key: "preview",
        label: "Preview",
        children: <DatasetPreview customer={customer} />,
      },
      {
        key: "dataset",
        label: "Column Formatting",
        children: <ColumnMetadataTable customer={customer} />,
      },
      {
        key: "computed",
        label: "Computed Columns",
        children: <ComputedColumns customer={customer} />,
      },
    ]
  }, [customer])

  return (
    <AuthBoundary subject="datasets" action="UPDATE">
      <DatasetHeader customer={customer} />
      <StyledTabs
        items={items}
        destroyInactiveTabPane
        activeKey={selectedKey}
        onChange={setSelectedKey}
      />
    </AuthBoundary>
  )
}

export default withCustomer(EditDataset)
