import backgroundColor from "./shared/backgroundColor"
import numberInputOptionsSchema from "../JSONSchemaForm/fields/NumberInput/fieldOptionsSchema"
import datePickerOptionsSchema from "../JSONSchemaForm/fields/DatePicker/fieldOptionsSchema"

const filterWidgetSchema = {
  type: "object",
  default: {},
  required: ["datasetId"],
  metadata: {
    order: [
      "label",
      "datasetId",
      "column",
      "op",
      "valuePresets",
      "presetDatasetId",
      "presetColumn",
      "defaultValue",
      "isMulti",
      "allowInput",
      "popupMatchSelectWidth",
      "backgroundColor",
    ],
    fieldProps: {
      style: {
        padding: "0 16px",
      },
    },
    sideEffects: [
      {
        resetValue: null,
        type: "resetField",
        sourceName: "options.metadata.isMulti",
        targetName: "options.defaultValue",
      },
      {
        resetValue: null,
        type: "resetField",
        sourceName: "options.column",
        targetName: "options.defaultValue",
      },
      {
        resetValue: null,
        type: "resetField",
        sourceName: "options.valuePresets",
        targetName: "options.defaultValue",
      },
      {
        resetValue: null,
        type: "resetField",
        targetName: "options.op",
        sourceName: "options.column",
      },
      ...datePickerOptionsSchema.metadata.sideEffects,
    ],
  },
  dependencies: {
    datasetId: {
      required: ["column"],
      dependencies: {
        columnType: {
          allOf: [
            {
              if: {
                properties: {
                  op: {
                    enum: ["AND", "=", "!="],
                  },
                },
              },
              then: {
                properties: {
                  isMulti: {
                    type: "boolean",
                    default: false,
                    title: "Allow Multiple Selections",
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  valuePresets: {
                    enum: ["columnValues"],
                  },
                },
              },
              then: {
                properties: {
                  allowInput: {
                    type: "boolean",
                    default: true,
                    title: "Allow New Value Input",
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  valuePresets: {
                    enum: ["otherColumnValues"],
                  },
                },
              },
              then: {
                dependencies: {
                  presetDatasetId: {
                    properties: {
                      presetColumnType: {
                        title: "Preset Column Type",
                        type: "string",
                        metadata: {
                          hidden: true,
                        },
                      },
                      presetColumn: {
                        title: "Preset Column",
                        type: "string",
                        metadata: {
                          validateFirst: true,
                          component: "ColumnSelect",
                          datasetIdName: "presetDatasetId",
                          columnTypeName: "presetColumnType",
                          fieldProps: {
                            popupMatchSelectWidth: false,
                          },
                        },
                      },
                    },
                  },
                  presetColumn: {
                    properties: {
                      allowInput: {
                        type: "boolean",
                        default: true,
                        title: "Allow New Value Input",
                      },
                    },
                  },
                },
                properties: {
                  presetDatasetId: {
                    type: "number",
                    title: "Preset Dataset",
                    metadata: {
                      component: "Dataset",
                    },
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  columnType: {
                    enum: ["numerical", "float", "int"],
                  },
                },
              },
              then: {
                required: ["op"],
                allOf: [
                  {
                    if: {
                      properties: {
                        valuePresets: {
                          enum: ["none"],
                        },
                      },
                    },
                    then: {
                      dependencies: numberInputOptionsSchema.dependencies,
                      properties: {
                        showSlider:
                          numberInputOptionsSchema.properties.showSlider,
                        multipleOf:
                          numberInputOptionsSchema.properties.multipleOf,
                        maximum: numberInputOptionsSchema.properties.maximum,
                        minimum: numberInputOptionsSchema.properties.minimum,
                      },
                    },
                  },
                ],
                properties: {
                  op: {
                    type: "string",
                    title: "Operator",
                    default: "=",
                    enum: ["=", "!=", "<", ">", "<=", ">="],
                    metadata: {
                      labels: [
                        "is equal to",
                        "is not equal to",
                        "is less than",
                        "is greater than",
                        "is less than or equal to",
                        "is greater than or equal to",
                      ],
                    },
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  columnType: {
                    enum: ["string", "categorical"],
                  },
                },
              },
              then: {
                required: ["op"],
                properties: {
                  op: {
                    type: "string",
                    title: "Operator",
                    default: "=",
                    enum: ["=", "!="],
                    metadata: {
                      labels: ["is in", "is not in"],
                    },
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  columnType: {
                    enum: ["datetime"],
                  },
                },
              },
              then: {
                required: ["op"],
                allOf: [
                  {
                    if: {
                      properties: {
                        valuePresets: {
                          enum: ["none"],
                        },
                      },
                    },
                    then: {
                      dependencies: datePickerOptionsSchema.dependencies,
                      properties: {
                        "metadata/picker":
                          datePickerOptionsSchema.properties["metadata/picker"],
                        "metadata/showPresets": {
                          ...datePickerOptionsSchema.properties[
                            "metadata/showPresets"
                          ],
                          default: true,
                          metadata: {
                            hidden: true,
                          },
                        },
                        "metadata/presetsAreRelative": {
                          ...datePickerOptionsSchema.properties[
                            "metadata/presetsAreRelative"
                          ],
                          default: true,
                          metadata: {
                            hidden: true,
                          },
                        },
                      },
                    },
                  },
                ],
                properties: {
                  op: {
                    type: "string",
                    title: "Operator",
                    default: "=",
                    enum: ["=", "!=", "<", ">", "<=", ">="],
                    metadata: {
                      fieldProps: {
                        placeholder: "Select value",
                        popupMatchSelectWidth: false,
                      },
                      labels: [
                        "is equal to",
                        "is not equal to",
                        "is before",
                        "is after",
                        "is before or equal to",
                        "is after or equal to",
                      ],
                    },
                  },
                },
              },
            },
          ],
        },
      },
      properties: {
        column: {
          title: "Column",
          type: "string",
          metadata: {
            validateFirst: true,
            component: "ColumnSelect",
            columnTypeName: "columnType",
            fieldProps: {
              popupMatchSelectWidth: false,
            },
          },
        },
        columnType: { $ref: "#/$defs/dataColumnType" },
        label: {
          title: "Label",
          type: ["string", "null"],
          nullable: true,
        },
        valuePresets: {
          type: "string",
          default: "none",
          title: "Value Presets",
          enum: ["none", "columnValues", "otherColumnValues"],
          metadata: {
            labels: ["None", "Column Values", "Other Column Values"],
          },
        },
        defaultValue: {
          type: "string",
          title: `Default Value`,
          metadata: {
            component: "FilterWidgetInput",
          },
        },
      },
    },
  },
  properties: {
    datasetId: { $ref: "#/$defs/datasetId" },
    backgroundColor: backgroundColor,
  },
}

export default filterWidgetSchema
