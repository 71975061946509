import React, { useContext, useCallback } from "react"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons"

import { RedirectContextProvider, useCurrentUser } from "@dbai/ui-staples"

import client from "apolloClient"

const { Context } = RedirectContextProvider

const Signout = props => {
  const { className } = props
  const [, , clear] = useCurrentUser()
  const { setRedirectTarget } = useContext(Context)
  const handleSignOut = useCallback(() => {
    clear(client).then(() => setRedirectTarget(""))
  }, [clear, setRedirectTarget])

  return (
    <Link to="#" onClick={handleSignOut} className={className}>
      <FontAwesomeIcon icon={faSignOutAlt} /> Sign Out
    </Link>
  )
}

export default Signout
