import React from "react"

import { useKernel } from "components/pages/Workflows/Edit/shared/kernel/KernelProvider"

import {
  WidgetContext,
  useWidgetReducer,
} from "components/pages/Workflows/Edit/shared/kernel/widgets/hooks"
import JupyterModel from "components/pages/Workflows/Edit/shared/kernel/widgets/JupyterModel"

const WidgetProvider = props => {
  const [widgetState, dispatch] = useWidgetReducer()

  const value = [widgetState, dispatch]
  return (
    <WidgetContext.Provider value={value}>
      <WidgetModels widgets={widgetState.widgets} />
      {props.children}
    </WidgetContext.Provider>
  )
}

const WidgetModels = ({ widgets }) => {
  const { kernel } = useKernel()

  if (!kernel) return null

  return Object.keys(widgets).map(commId => {
    const widget = widgets[commId]
    return (
      <JupyterModel
        key={commId}
        commId={commId}
        widget={widget}
        kernel={kernel}
      />
    )
  })
}

export default WidgetProvider
