import React, { useState, useEffect } from "react"
import { Checkbox } from "antd"
import styled from "styled-components"
import { useDispatch } from "react-redux"

import { Spinner } from "@dbai/ui-staples"

import { actions } from "reducers/actionReducer"

const SpinnerWrapper = styled.div`
  display: flex;
  align-content: start;
`

const SelfUpdatingCheckbox = props => {
  const { checked, changed, setChanged, onChange } = props
  useEffect(() => {
    if (changed) setChanged(false)
  }, [changed, setChanged])

  return <Checkbox checked={checked} onChange={onChange} />
}

const CurrentCheckbox = props => {
  const dispatch = useDispatch()
  const [changed, setChanged] = useState(false)
  const { currentRevisionId, loading, value } = props
  const checked = currentRevisionId === value
  const handleChange = () => {
    dispatch(actions.setCurrentRevision(value))
    setChanged(true)
  }

  if (changed && loading) {
    const gridSpinnerProps = { height: 16, width: 16 }
    const spinnerContainerProps = { width: "initial", margin: "initial" }
    return (
      <SpinnerWrapper>
        <Spinner
          gridSpinnerProps={gridSpinnerProps}
          spinnerContainerProps={spinnerContainerProps}
        />
      </SpinnerWrapper>
    )
  }

  return (
    <SelfUpdatingCheckbox
      checked={checked}
      changed={changed}
      onChange={handleChange}
      setChanged={setChanged}
    />
  )
}

export default CurrentCheckbox
