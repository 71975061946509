import React, { useState, useMemo, useEffect, useCallback } from "react"
import { Switch, Alert } from "antd"
import { Col, Row } from "antd"
import styled from "styled-components"
import { useParams } from "react-router"
import { toast } from "react-toastify"

import {
  Form,
  Spinner,
  useFormSet,
  CodeEditor,
  useFormState,
  useFormDispatch,
} from "@dbai/ui-staples"

import config from "config"
import ModelInputForm from "./ModelInputForm"
import {
  formatJsonResults,
  formStateToPayload,
  payloadToFormState,
  columnsMissingData,
  defaultJsonPayload,
} from "../helpers"

const EditorPadding = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  border: solid;
  border-width: 1px;
  border-color: #dddddd;
`

const SwitchContent = styled.div`
  display: flex;
  gap: 16px;
`

const RESET_INITIAL_STATE = "dbai/reset_initial_state"
const JSON_DATA_ATTR = "dbai/model_revision_json"

const JSONEditor = ({ initialValue, onChange }) => {
  const set = useFormSet()
  const initCodeMirror = useCallback(
    codeMirror => {
      const handleChange = val => {
        try {
          const jsonData = JSON.parse(val.getValue())
          set({ name: JSON_DATA_ATTR, value: val.getValue() })
          if (onChange) {
            onChange(jsonData)
          }
        } catch (err) {
          // If the user enters invalid json, just silently fail
        }
      }
      codeMirror.on("change", handleChange)
    },
    [set, onChange]
  )

  return (
    <CodeEditor
      mode="javascript"
      options={{ json: true }}
      initialValue={initialValue}
      initCodeMirror={initCodeMirror}
    />
  )
}

const submitText = "Test Model"

const FormEditor = props => {
  const { signature, useJson, jsonOnChange } = props
  const state = useFormState()
  const formDispatch = useFormDispatch()
  const initialValue = useMemo(
    () => state[JSON_DATA_ATTR] || defaultJsonPayload,
    [state]
  )

  useEffect(() => {
    if (!useJson) {
      formDispatch({ type: RESET_INITIAL_STATE })
    }
    // Leave this here, as this value changing is the scenario that we want to
    // clear on.
    // eslint-disable-next-line
  }, [useJson, formDispatch])

  if (!useJson && signature) {
    return <ModelInputForm signature={signature} />
  }

  return (
    <EditorPadding>
      <JSONEditor initialValue={initialValue} onChange={jsonOnChange} />
    </EditorPadding>
  )
}

const FormSwitcher = props => {
  const formState = useFormState()
  const { signatureExists, onChange } = props

  return (
    <SwitchContent>
      <h3>JSON</h3>
      {signatureExists ? <Switch onChange={() => onChange(formState)} /> : null}
    </SwitchContent>
  )
}

const TestForm = props => {
  const { cname } = useParams()
  const { signature, revisionId, isRunning, refetchRevision } = props
  const hasInputs = signature?.inputs?.length
  const [useJson, setUseJson] = useState(false)
  const [results, setResults] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [initialState, setInitialState] = useState({})
  const handleChange = useCallback(
    state => {
      if (!useJson) {
        setInitialState(state)
      }
      setUseJson(!useJson)
    },
    [useJson, setUseJson, setInitialState]
  )

  const handleJsonChange = useCallback(
    jsonData => {
      try {
        const formState = payloadToFormState(jsonData)
        setInitialState(formState)
      } catch (err) {
        console.error(err)
      }
    },
    [setInitialState]
  )

  const clientReducer = useMemo(
    () => (prevState, action) => {
      if (action.type === RESET_INITIAL_STATE) {
        return {
          ...initialState,
          ...{
            [JSON_DATA_ATTR]: formatJsonResults(
              formStateToPayload(signature, prevState)
            ),
          },
        }
      } else if (action.name !== JSON_DATA_ATTR) {
        return {
          ...prevState,
          ...{
            [JSON_DATA_ATTR]: formatJsonResults(
              formStateToPayload(signature, prevState)
            ),
          },
        }
      } else {
        return {
          ...prevState,
        }
      }
    },
    [initialState, signature]
  )

  const handleSubmit = useCallback(
    state => {
      // Using state.data to store the raw JSON, as storing it directly on the
      // form object is not well supported by our current implemenation of
      // Form.
      const payload = useJson
        ? JSON.parse(state[JSON_DATA_ATTR])
        : formStateToPayload(signature, state)

      const missingData = columnsMissingData(payload)

      if (missingData.length > 0) {
        toast.error(
          <>
            <span>Columns missing data:</span>
            <ul>
              {missingData.map(column => (
                <li key={column}>{column}</li>
              ))}
            </ul>
          </>
        )
        return
      }

      setIsLoading(true)

      const predictionFetch = () => {
        return fetch(`${config.api}models/${cname}/${revisionId}/prediction`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user/jwt")}`,
          },
          body: JSON.stringify(payload),
        }).then(response => {
          return response.json()
        })
      }

      predictionFetch()
        .then(resultSet => {
          setResults(formatJsonResults(resultSet))
        })
        .catch(err => {
          toast.error(`Error fetching prediction: ${err}`)
        })
        .finally(() => {
          setIsLoading(false)
          refetchRevision()
        })
    },
    [revisionId, signature, useJson, setIsLoading, cname, refetchRevision]
  )

  if (revisionId === null) {
    return <div>Please Select A Revision</div>
  }

  return (
    <div>
      <Row gutter={[16, 24]}>
        {!isRunning && (
          <Col span={24}>
            <Alert
              type="info"
              message="Please start the server in order to use the form"
            />
          </Col>
        )}
        <Col span={12}>
          <Form
            submitText={submitText}
            name="Test Model"
            onSubmit={handleSubmit}
            hideSubmit={!isRunning || isLoading}
            clientReducer={clientReducer}
          >
            <FormSwitcher onChange={handleChange} signatureExists={hasInputs} />
            <FormEditor
              {...props}
              useJson={useJson}
              jsonOnChange={handleJsonChange}
            />
          </Form>
        </Col>
        <Col span={12}>
          <h2>Results</h2>
          {(isLoading && <Spinner />) || <pre>{results}</pre>}
        </Col>
      </Row>
    </div>
  )
}

export default TestForm
