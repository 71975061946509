import React from "react"
import { useQuery } from "@apollo/client"

import { ErrorMessage } from "@dbai/ui-staples"

import Select from "../../shared/Select"
import { useWidgetContext } from "../../../hooks"
import FormFieldWrapper from "../../FormFieldWrapper"
import { GET_DATASETS } from "../../../queries/datasets"

const DatasetSelectCore = props => {
  const { customerId } = useWidgetContext()
  const { error, loading, data } = useQuery(GET_DATASETS, {
    variables: { customerId },
    fetchPolicy: "network-only",
  })

  if (error) return <ErrorMessage error={error} />
  return (
    <Select
      loading={loading}
      options={data?.datasets?.map(ds => ({ label: ds.name, value: ds.id }))}
      {...props}
    />
  )
}

const DatasetSelect = props => {
  return (
    <FormFieldWrapper {...props}>
      <DatasetSelectCore />
    </FormFieldWrapper>
  )
}

DatasetSelect.Core = DatasetSelectCore
export default DatasetSelect
