import React, { useRef, useEffect, useMemo, useState, useCallback } from "react"
import { Panel, PanelGroup } from "react-resizable-panels"
import styled from "styled-components"

import { SchemaForm, Widget, PanelTitle, PanelResizeHandle } from "@dbai/applet"

import { widgetTypeSourceSchema } from "./widgetTypeSchema"

const ScrollableDiv = styled.div`
  height: calc(100vh - 214px);
  overflow: auto;
`

const EditWidgetSourceCode = props => {
  const { widget, setWidget, cname } = props

  const updateWidgetPreview = useRef()
  const [mockData, setMockData] = useState({})
  const [widgetPreview, setWidgetPreview] = useState({})

  useEffect(() => {
    updateWidgetPreview.current && clearTimeout(updateWidgetPreview.current)
    updateWidgetPreview.current = setTimeout(() => {
      setWidgetPreview({
        type: "CustomWidget",
        options: {
          width: 500,
          height: 500,
          data: mockData,
          srcDoc: widget?.source?.core,
        },
      })
    }, 3000)
  }, [mockData, widget?.source?.core])

  const customWidgetForm = useMemo(
    () => ({
      type: "FormWidget",
      options: {
        properties: widget?.schema?.properties,
      },
    }),
    [widget?.schema?.properties]
  )

  const handleMockDataChange = useCallback(data => {
    setMockData(data)
  }, [])

  const handleFormChange = useCallback(
    data => {
      setWidget(widget => ({
        ...widget,
        source: { ...(widget.source || {}), core: data.source.core },
      }))
    },
    [setWidget]
  )

  return (
    <PanelGroup direction="horizontal">
      <Panel minSize={10} defaultSize={50} collapsible>
        <PanelTitle level={3}>Source Code</PanelTitle>
        <ScrollableDiv>
          <SchemaForm
            hideSubmit
            initialState={widget}
            onFormChange={handleFormChange}
            schema={widgetTypeSourceSchema}
          />
        </ScrollableDiv>
      </Panel>
      <PanelResizeHandle />
      <Panel minSize={10}>
        <PanelTitle level={3}>Widget Preview</PanelTitle>
        <ScrollableDiv>
          <Widget.Core cname={cname} widget={widgetPreview} />
        </ScrollableDiv>
      </Panel>
      <PanelResizeHandle />
      <Panel minSize={10} defaultSize={25}>
        <PanelTitle level={3}>Mock Data</PanelTitle>
        {widget?.schema?.properties?.length ? (
          <Widget.Core
            hideSubmit
            cname={cname}
            widget={customWidgetForm}
            onFormChange={handleMockDataChange}
          />
        ) : (
          <div>No parameters configured.</div>
        )}
      </Panel>
    </PanelGroup>
  )
}

export default EditWidgetSourceCode
