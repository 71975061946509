import React, { useId, useCallback } from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"

import JSONSchemaForm from "../JSONSchemaForm"
import { actions } from "../reducers/appReducer"
import { selectAllAppFilters } from "../selectors/app"
import appFiltersSchema from "../schemas/appFiltersSchema"
import {
  EVENT_TYPE_MOVE,
  EVENT_TYPE_APPEND,
  EVENT_TYPE_CHANGE,
  EVENT_TYPE_REMOVE,
} from "../JSONSchemaForm/constants"

const PaddedDiv = styled.div`
  padding: 0 16px;
`

const getVariableIndex = name => {
  const indeces = name.match(/\[(\d+)\]/)
  if (!indeces) return null
  return indeces[1]
}

const AppFiltersForm = props => {
  const formId = useId()
  const dispatch = useDispatch()
  const appFilters = useSelector(selectAllAppFilters)

  const handleFormChange = useCallback(
    ({ filters }, errors, changed) => {
      const { name, value, eventType, index, fromIndex, toIndex } = changed
      switch (eventType) {
        case EVENT_TYPE_APPEND:
          dispatch(actions.addAppFilterWithSync(value))
          break
        case EVENT_TYPE_REMOVE:
          if (!value.id) {
            console.warn(
              "Removing a filter without an id. This change will not be synchronized with other clients. Other clients must reload their screen to receive the update."
            )
          }
          // old filters do not contain an id. include index here for backwards compatibility
          dispatch(actions.removeAppFilterWithSync({ index, id: value.id }))
          break
        case EVENT_TYPE_MOVE:
          dispatch(actions.moveAppFilterWithSync({ fromIndex, toIndex }))
          break
        case EVENT_TYPE_CHANGE:
          const filterIndex = getVariableIndex(name)
          const filter = filters[filterIndex]
          // old filters do not contain an id. include index here for backwards compatibility.
          dispatch(actions.setAppFilterWithSync({ filter, index: filterIndex }))
          break
        default:
      }
    },
    [dispatch]
  )

  return (
    <PaddedDiv>
      <JSONSchemaForm
        hideSave
        formId={formId}
        schema={appFiltersSchema}
        value={{ filters: appFilters }}
        onFormChange={handleFormChange}
      />
    </PaddedDiv>
  )
}

export default AppFiltersForm
