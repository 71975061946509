import React, { memo } from "react"

import FormList from "../../FormList"
import FormFieldWrapper from "../../FormFieldWrapper"

const SchemaObject = memo(props => {
  return (
    <FormFieldWrapper noStyle {...props}>
      <FormList />
    </FormFieldWrapper>
  )
})

SchemaObject.Core = FormList
export default SchemaObject
