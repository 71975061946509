import React, { useState, useMemo, useContext } from "react"
import Modal from "react-modal"
import { uniqBy } from "lodash"
import styled from "styled-components"
import { useParams } from "react-router"
import { useDispatch } from "react-redux"
import { useQuery } from "@apollo/client"
import { useSelector } from "react-redux"
import ReactDiffViewer from "react-diff-viewer"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "antd"

import { storeToState } from "@dbai/tool-box"
import { Card, Spinner, ErrorMessage, Accordion } from "@dbai/ui-staples"

import { selectNodes } from "selectors"
import { GET_SNAPSHOT_WITH_SPEC } from "queries"
import { actions } from "reducers/notebookReducer"
import CloneSnapshotForm from "./CloneSnapshotForm"

const HeaderButtons = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const SnapshotTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ActionModal = styled(Modal)`
  position: relative;
  width: 20%;
  height: 20%;
  top: 45%;
  left: 40%;
`

const gatherSources = node => {
  if (!(node?.type === "script" || node?.type === "component")) return ""
  return (
    node?.cells
      .map(cell => {
        return cell.source.join("")
      })
      .join("\n") || ""
  )
}

/*
 * Collects all of the nodes in both the old and new spec and combines them.
 * This is needed so we can display deleted nodes. If a node appears in the
 * current spec that node is favored over it's duplicate in the old spec.
 */
const gatherAllNodes = (nodes = [], oldNodes = []) => {
  const markedNodes = oldNodes.map(node => ({ ...node, deleted: true }))
  return uniqBy([...nodes, ...markedNodes], "id")
}

/*
 * ReactDiffViewer styling options.
 */
const diffStyleOverrides = {
  line: {
    pre: {
      padding: "0rem 1rem",
      backgroundColor: "unset",
    },
  },
  wordDiff: {
    padding: "0px 2px",
  },
}

const ModalText = styled.div`
  margin-bottom: 5px;
  text-align: center;
`

const ModalButtons = styled.div`
  width: 100%;
`

const SnapshotActionModal = props => {
  const { isOpen, closeModal, children } = props
  if (!isOpen) return null

  return (
    <ActionModal
      isOpen={isOpen}
      style={{ overlay: { zIndex: 1001 } }}
      onRequestClose={closeModal}
    >
      {children}
    </ActionModal>
  )
}

const ConfirmationPrompt = props => {
  const { closeModal, handleRestore } = props
  const restoreAndClose = () => {
    handleRestore()
    closeModal()
  }
  return (
    <Card>
      <Card.Header>Confirm Restoration</Card.Header>
      <Card.Body>
        <ModalText>Any work not saved in a snapshot will be lost</ModalText>
        <ModalButtons className="btn-group">
          <button className="btn btn-danger" onClick={closeModal}>
            Cancel
          </button>
          <button className="btn btn-success" onClick={restoreAndClose}>
            Restore
          </button>
        </ModalButtons>
      </Card.Body>
    </Card>
  )
}

const NodeHeader = props => {
  const { handleClick, content: node } = props
  const label = node.deleted ? `${node.data.label} - DELETED` : node.data.label
  const textColor = node.deleted ? " text-danger" : ""
  return (
    <div className="card-header">
      <button
        type="button"
        className={`btn btn-link btn-block text-left${textColor}`}
        onClick={handleClick}
      >
        {label}
      </button>
    </div>
  )
}

const NodeDiff = props => {
  const { node, oldNodes, splitView } = props
  const { open } = useContext(Accordion.Context)
  const isOpen = open?.includes(node.id)
  const oldNode = useMemo(() => {
    return oldNodes?.find(n => n.id === node.id)
  }, [node, oldNodes])

  const newValue = useMemo(() => {
    if (node.deleted) return ""
    return gatherSources(node)
  }, [node])

  const oldValue = useMemo(() => {
    return gatherSources(oldNode)
  }, [oldNode])

  // Track if this node is open so we can prevent any wasted time creating a
  // diff for a node if it's not being actively viewed anyway.
  if (!isOpen) {
    return (
      <Accordion.Content
        Header={NodeHeader}
        headerContent={node}
        sectionId={node.id}
      />
    )
  }

  return (
    <Accordion.Content
      Header={NodeHeader}
      headerContent={node}
      sectionId={node.id}
    >
      <ReactDiffViewer
        extraLinesSurroundingDiff={1}
        newValue={newValue}
        oldValue={oldValue}
        splitView={splitView}
        styles={diffStyleOverrides}
        hideLineNumbers
      />
    </Accordion.Content>
  )
}

const DiffView = props => {
  const { id: workflowId } = useParams()
  const { id, deselect, customerId } = props
  const dispatch = useDispatch()
  const [splitView, setSplitView] = useState(false)
  const [restorationModalIsOpen, setRestorationModalIsOpen] = useState(false)
  const [cloneModalIsOpen, setCloneModalIsOpen] = useState(false)
  const nodes = useSelector(selectNodes)
  const { data, loading, error } = useQuery(GET_SNAPSHOT_WITH_SPEC, {
    fetchPolicy: "no-cache",
    variables: { id, customerId },
  })

  const cleanedSpec = useMemo(() => {
    if (!data?.workflowSnapshot?.spec || !id) {
      return null
    }
    return storeToState(data.workflowSnapshot.spec)
  }, [data, id])

  const allNodes = useMemo(() => {
    return gatherAllNodes(nodes, cleanedSpec?.nodes)
  }, [nodes, cleanedSpec])

  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  const openCloneModal = () => setCloneModalIsOpen(true)
  const closeCloneModal = () => setCloneModalIsOpen(false)
  const openRestorationModal = () => setRestorationModalIsOpen(true)
  const closeRestorationModal = () => setRestorationModalIsOpen(false)
  const afterClone = () => {
    closeCloneModal()
    dispatch(actions.setHistoryPanelClosed())
  }
  const handleRestore = () => {
    dispatch(
      actions.restoreWorkflow({
        customerId,
        snapshotId: id,
        id: Number(workflowId),
      })
    )
  }

  return (
    <>
      <div className="card">
        <div className="card-header">
          <HeaderButtons>
            <div className="btn-group">
              <Button
                type="primary"
                data-tip
                style={{ marginRight: "5px" }}
                data-tooltip-id="workflow-history"
                tabIndex="0"
                onClick={deselect}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
              <Button
                data-tip
                data-tooltip-id="workflow-history"
                style={{ marginRight: "5px" }}
                tabIndex="0"
                onClick={openRestorationModal}
              >
                Restore
              </Button>
              <Button
                data-tip
                data-tooltip-id="workflow-history"
                style={{ marginRight: "5px" }}
                tabIndex="0"
                onClick={openCloneModal}
              >
                Clone
              </Button>
            </div>
            <SnapshotTitle>
              <span>{data?.workflowSnapshot?.description}</span>
            </SnapshotTitle>
            <div className="btn-group">
              <Button
                type={splitView ? "default" : "primary"}
                style={{ marginRight: "5px" }}
                onClick={() => setSplitView(false)}
              >
                Unified
              </Button>
              <Button
                type={splitView ? "primary" : "default"}
                onClick={() => setSplitView(true)}
              >
                Split
              </Button>
            </div>
          </HeaderButtons>
        </div>
        <Accordion>
          {allNodes.map(node => {
            return (
              <NodeDiff
                key={node.id}
                node={node}
                splitView={splitView}
                oldNodes={cleanedSpec?.nodes}
              />
            )
          })}
        </Accordion>
        <SnapshotActionModal
          isOpen={restorationModalIsOpen}
          closeModal={closeRestorationModal}
        >
          <ConfirmationPrompt
            handleRestore={handleRestore}
            closeModal={closeRestorationModal}
          />
        </SnapshotActionModal>
        <SnapshotActionModal
          isOpen={cloneModalIsOpen}
          closeModal={closeCloneModal}
        >
          <CloneSnapshotForm
            snapshotId={id}
            customerId={customerId}
            onCancel={closeCloneModal}
            afterSubmit={afterClone}
          />
        </SnapshotActionModal>
      </div>
    </>
  )
}

export default DiffView
