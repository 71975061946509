import React from "react"

import * as Entrypoint from "components/pages/Workflows/Edit/shared/kernel/widgets/Entrypoint"

// Jupyter widget maps the model name to a React components and passes all
// other props through.
const JupyterModel = props => {
  const { widget } = props
  const Comp = mapModelToComp(widget.data.state._model_name)

  if (!Comp) return null
  return <Comp {...props} />
}

const mapModelToComp = modelName => {
  switch (modelName) {
    case "Entrypoint":
      return Entrypoint.Model
    default:
      return null
  }
}

export default JupyterModel
