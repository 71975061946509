import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Button } from "antd"

import { AuthBoundary } from "@dbai/ui-staples"

import AppsTable from "./AppsTable"
import NewAppDrawer from "./NewAppDrawer"
import { actions } from "reducers/appsReducer"
import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"
import ContentWithStackedNav from "./ContentWithStackedNav"

const AppsLandingPage = props => {
  const { customer } = props
  const [drawerVisible, setDrawerVisible] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(actions.loadApps())
  }, [dispatch])

  const onDrawerOpen = () => setDrawerVisible(true)
  const handleDrawerClose = () => {
    setDrawerVisible(false)
  }

  const routes = [{ breadcrumbName: customer.name }, { breadcrumbName: "Apps" }]

  return (
    <>
      <PageHeader routes={routes}>
        <AuthBoundary subject="apps" action="CREATE">
          <Button type="primary" onClick={onDrawerOpen}>
            + NEW APP
          </Button>
        </AuthBoundary>
      </PageHeader>
      <ContentWithStackedNav>
        <AppsTable />
      </ContentWithStackedNav>
      <NewAppDrawer onClose={handleDrawerClose} visible={drawerVisible} />
    </>
  )
}

export default withCustomer(AppsLandingPage)
