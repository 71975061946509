import React, { useState } from "react"
import { Button } from "antd"
import PropTypes from "prop-types"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const DeleteButton = props => {
  const { onDelete, onError, afterDelete, confirmDelete = false } = props

  const [showConfirmation, setShowConfirmation] = useState(false)
  const promptConfirmation = () => setShowConfirmation(true)
  const closeConfirmation = () => setShowConfirmation(false)

  const handleDelete = () => {
    const deletion = onDelete()
    closeConfirmation()

    // The only for sure standard that we can check on an object to determine
    // if it's a promise is that it has a `then` function.
    if (deletion?.then) {
      return deletion
        .then(response => afterDelete && afterDelete(response))
        .catch(err => {
          onError && onError(err)
          throw err
        })
    }
    afterDelete && afterDelete()
  }

  const handleDeleteClicked = confirmDelete ? promptConfirmation : handleDelete

  return (
    <>
      {!showConfirmation && (
        <Button onClick={handleDeleteClicked} danger data-testid="delete-btn">
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      )}
      {showConfirmation && (
        <>
          <Button onClick={handleDelete}>Confirm</Button>
          <Button onClick={closeConfirmation}>Cancel</Button>
        </>
      )}
    </>
  )
}

DeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
  afterDelete: PropTypes.func,
  confirmDelete: PropTypes.bool,
}

export default DeleteButton
