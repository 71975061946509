import React from "react"
import styled from "styled-components"

import { getColorScaleStyles } from "../../lib/colors"
import { formatColumnValue } from "../../lib/datasetColumns"
import { getConditionalStyles } from "../../lib/conditionalFormat"

const StyledTableCell = styled.td`
  ${({ textAlign }) => `text-align: ${textAlign};`}
`

const TableCell = props => {
  const {
    style,
    record = {},
    column = {},
    columnsAreAggregated,
    ...rest
  } = props
  const value = record[column.dataIndex]

  const conditionalStyles = getConditionalStyles(
    column.conditionalFormat,
    record,
    columnsAreAggregated
  )

  const colorScaleStyles = getColorScaleStyles(column.colorScale, record)

  if (typeof value === "object") {
    return (
      <StyledTableCell
        style={{ ...style, ...conditionalStyles, ...colorScaleStyles }}
        {...rest}
      />
    )
  }

  return (
    <StyledTableCell
      style={{ ...style, ...conditionalStyles, ...colorScaleStyles }}
      textAlign={column.textAlign || "left"}
      {...rest}
    >
      {formatColumnValue(column, value)}
    </StyledTableCell>
  )
}

export default TableCell
