import { gql } from "@apollo/client"

export const GET_DATASET = gql`
  query getDataset(
    $datasetId: Int!
    $cname: String!
    $queryOptions: JsonInput!
  ) {
    customer(name: $cname) {
      id
      dataset(id: $datasetId) {
        id
        data(queryOptions: $queryOptions) {
          rows
          columns {
            name
            type
          }
          label {
            group
            name
          }
          count
        }
      }
    }
  }
`

export const GET_DATASET_CSV = gql`
  query getDataset(
    $datasetId: Int!
    $cname: String!
    $queryOptions: JsonInput!
  ) {
    customer(name: $cname) {
      id
      dataset(id: $datasetId) {
        id
        name
        csv(queryOptions: $queryOptions)
      }
    }
  }
`

export const GET_LABELS_FOR_COLUMN = gql`
  query getLabelsForColumn($id: Int!, $column: String!, $cname: String!) {
    customer(name: $cname) {
      id
      dataset(id: $id) {
        id
        name
        meta {
          labels(column: $column)
          datasetColumns {
            label
            format
            formatOptions
            description
            name
            type
          }
        }
      }
    }
  }
`

export const GET_DATASETS = gql`
  query getDatasets($customerId: Int!) {
    datasets(customerId: $customerId) {
      id
      name
      tags
      createdAt
      updatedAt
    }
  }
`

export const GET_DATASET_METADATA = gql`
  query getDatasetMetadata($id: Int!, $customerId: Int!) {
    dataset(id: $id, customerId: $customerId) {
      id
      tags
    }
  }
`

export const GET_CORRELATION = gql`
  query DatasetCorrelations(
    $datasetId: Int!
    $cname: String!
    $queryOptions: JsonInput!
  ) {
    customer(name: $cname) {
      id
      dataset(id: $datasetId) {
        id
        meta {
          datasetColumns {
            label
            format
            formatOptions
            description
            name
            type
          }
        }
        stats {
          correlation(queryOptions: $queryOptions)
        }
      }
    }
  }
`

export const GET_FIVE_NUMBER_SUMMARY = gql`
  query DatasetFiveNumberSummary(
    $datasetId: Int!
    $cname: String!
    $queryOptions: JsonInput!
  ) {
    customer(name: $cname) {
      id
      dataset(id: $datasetId) {
        id
        meta {
          datasetColumns {
            label
            format
            formatOptions
            description
            name
            type
          }
        }
        stats {
          fiveNumberSummary(queryOptions: $queryOptions) {
            group
            min
            max
            lowerQuartile
            median
            upperQuartile
          }
        }
      }
    }
  }
`

export const GET_DATASET_COUNT = gql`
  query GetDatasetCount(
    $datasetId: Int!
    $cname: String!
    $queryOptions: JsonInput!
  ) {
    customer(name: $cname) {
      id
      dataset(id: $datasetId) {
        id
        meta(queryOptions: $queryOptions) {
          count
        }
      }
    }
  }
`

export const GET_DATASET_AND_COUNT = gql`
  query GetDatasetAndCount(
    $datasetId: Int!
    $cname: String!
    $queryOptions: JsonInput!
  ) {
    customer(name: $cname) {
      id
      dataset(id: $datasetId) {
        id
        tableMeta {
          uniqueKeys
        }
        meta(queryOptions: $queryOptions) {
          count
          query
          datasetColumns {
            name
            column
            type
            label
            format
            aggregate
            arguments
            description
            formatOptions
          }
          columns {
            name
            column
            type
            label
            format
            aggregate
            arguments
            description
            formatOptions
          }
        }
        data(queryOptions: $queryOptions) {
          rows
          label {
            group
            name
          }
          count
        }
      }
    }
  }
`

export const GET_TIME_RANGE = gql`
  query DatasetTimeRange($datasetId: Int!, $cname: String!, $column: String!) {
    customer(name: $cname) {
      id
      dataset(id: $datasetId) {
        id
        stats {
          timeRange(column: $column) {
            min
            max
          }
        }
      }
    }
  }
`

export const GET_DATASET_COLUMNS = gql`
  query DatasetColumns($id: Int!, $cname: String!) {
    customer(name: $cname) {
      id
      dataset(id: $id) {
        id
        name
        createdAt
        updatedAt
        tableMeta {
          uniqueKeys
          computedColumns {
            name
            label
            format
            description
            computation
            formatOptions
          }
        }
        meta {
          datasetColumns {
            name
            type
            label
            format
            description
            formatOptions
          }
        }
      }
    }
  }
`

export const GET_DATASET_LABELS = gql`
  query DatasetLabels($id: Int!, $column: String!, $cname: String!) {
    customer(name: $cname) {
      id
      dataset(id: $id) {
        id
        name
        meta {
          labels(column: $column)
        }
      }
    }
  }
`

export const GET_DATASET_INFO = gql`
  query getDatasetInfo($id: Int!, $customerId: Int!) {
    dataset(id: $id, customerId: $customerId) {
      id
      name
      createdAt
      updatedAt
    }
  }
`

export const GET_DATASET_OVERVIEW = gql`
  query DatsetOverview($id: Int!, $customerId: Int!) {
    dataset(id: $id, customerId: $customerId) {
      id
      name
      createdAt
      updatedAt
      meta {
        datasetColumns {
          label
          format
          formatOptions
          description
          name
          type
        }
      }
    }
  }
`

export const GET_FIVE_NUMBER_SUMMARY_FOR_COLUMN = gql`
  query ColumnFiveNumberSummary(
    $id: Int!
    $customerId: Int!
    $columnId: String!
  ) {
    dataset(id: $id, customerId: $customerId) {
      stats {
        fiveNumberSummary(
          options: { groupBy: [], select: [{ column: $columnId }] }
        ) {
          group
          min
          max
          lowerQuartile
          median
          upperQuartile
        }
      }
    }
  }
`

export const DELETE_DATASET = gql`
  mutation deleteDataset($id: Int!, $customerId: Int!) {
    deleteDataset(id: $id, customerId: $customerId) {
      id
    }
  }
`

export const UPDATE_DATASET = gql`
  mutation UpdateDataset($name: String!, $id: Int!, $customerId: Int!) {
    updateDataset(name: $name, id: $id, customerId: $customerId) {
      name
    }
  }
`

export const CREATE_DATASET = gql`
  mutation CreateDataset(
    $customerId: Int!
    $name: String!
    $tableMeta: TableMetaInput
  ) {
    createDataset(customerId: $customerId, name: $name, tableMeta: $tableMeta) {
      id
      name
    }
  }
`
