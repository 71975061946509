import { useCallback } from "react"
import { useLazyQuery } from "@apollo/client"

import { useCurrentCustomer } from "@dbai/ui-staples"

const defaultFormatter = ({ row, customer }) => ({
  id: row.id,
  cname: customer?.normalizedName,
})

export const useTagBuster = (query, formatter = defaultFormatter) => {
  const [customer] = useCurrentCustomer()
  const [updateWorkflowMetadata] = useLazyQuery(query, {
    fetchPolicy: "network-only",
  })

  const handleTagUpdate = useCallback(
    row => {
      updateWorkflowMetadata({
        variables: formatter({ row, customer }),
      })
    },
    [customer, formatter, updateWorkflowMetadata]
  )

  return handleTagUpdate
}
