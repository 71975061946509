import rectShapeSchema from "./rectShapeSchema"

const richTextShapeSchema = {
  metadata: {
    layout: { gutter: [16] },
    order: ["type"],
  },
  properties: {
    text: {
      title: "Text",
      type: "string",
      metadata: {
        hideLabel: true,
        component: "RichText",
        treeViewHidden: true,
      },
    },
    ...rectShapeSchema.properties,
    width: {
      title: "Width",
      type: "number",
      default: 300,
    },
    height: {
      title: "Height",
      default: 100,
      type: "number",
    },
  },
}

export default richTextShapeSchema
