import React from "react"

import Select from "../shared/Select"
import { getSelectMode } from "../utils"
import FormFieldWrapper from "../FormFieldWrapper"
import { useFormFieldDropdownOptions } from "../hooks"

const FormPropertiesCore = props => {
  const { schema, parentName } = props
  const { nullable } = schema
  const mode = getSelectMode(schema)
  const options = useFormFieldDropdownOptions(schema, parentName)
  //the issue here is that the root properties name is schema/properties, while the properties
  // within an object field is properties. ideally, we shuold compute the nearest 'properties' or 'schema/properties'
  return (
    <Select {...props} allowClear={nullable} mode={mode} options={options} />
  )
}

const FormProperties = props => {
  return (
    <FormFieldWrapper {...props}>
      <FormPropertiesCore />
    </FormFieldWrapper>
  )
}
FormProperties.Core = FormPropertiesCore
export default FormProperties
