import client from "apolloClient"
import { createSlice } from "@reduxjs/toolkit"

import {
  storeToState,
  toastAndRethrow,
  createCommonActions,
  createResourceActions,
} from "@dbai/tool-box"

import * as selectors from "selectors/action"
import { findNodeById } from "lib/utils/specHelpers"
import {
  GET_ACTION,
  GET_ACTIONS,
  UPDATE_ACTION,
  CREATE_ACTION,
  GET_REVISIONS,
  SET_CURRENT_REVISION,
} from "queries"

const cleanCell = {
  cellType: "code",
  metadata: {},
  outputs: [],
  source: [""],
  uuid: null,
}

const initialState = {
  loading: false,
  error: null,
  data: {
    action: {
      id: null,
      description: "",
      name: "",
      actionRevisions: [],
      currentRevision: {},
      revisionId: null,
      spec: {
        arguments: [],
        artifacts: [],
        cells: [cleanCell],
        color: "#0c2963",
        type: "script",
      },
      updatedAt: null,
    },
  },
}

const actionSlice = createSlice({
  name: "action",
  initialState,
  reducers: {
    ...createCommonActions(initialState),
    ...createResourceActions({
      // resourceName: "action",
      initialState,
    }),
    setName: (draft, { payload }) => {
      draft.data.action.name = payload
    },
    setDescription: (draft, { payload }) => {
      draft.data.action.description = payload
    },
    setColor: (draft, { payload }) => {
      draft.data.action.spec.color = payload.hex
    },
    setArguments: (draft, { payload = [] }) => {
      draft.data.action.spec.arguments = payload
    },
    setCellSource: (draft, { payload }) => {
      const cell = draft.data.action.spec.cells[payload.cellIdx]
      cell.source = [payload.value]
    },
    addScriptArgument: (draft, { payload }) => {
      const identifyMatch = input => input.name === payload.name
      if (-1 === draft.data.action.spec.arguments.findIndex(identifyMatch)) {
        draft.data.action.spec.arguments.push(payload)
      }
    },
    updateRevisions: (draft, { payload }) => {
      draft.data.action.actionRevisions = payload
    },
    loadRevision: (draft, { payload }) => {
      const action = draft.data.action
      const spec = (
        action.actionRevisions.find(revision => revision.id === payload) || {}
      ).spec
      action.spec = spec
      action.revisionId = payload
    },
    deleteInputByName: (draft, { payload }) => {
      const inputs = draft.data.action.spec.arguments
      draft.data.action.spec.arguments = inputs.reduce((acc, next) => {
        if (next.name === payload) return acc
        return [...acc, next]
      }, [])
    },
  },
})

const { actions } = actionSlice

const formatActionWithRevision = (action, revision) => {
  const initialAction = initialState.data.action
  return {
    ...(action || initialAction),
    revisionId: revision.id,
    spec: revision?.spec || initialAction.spec,
  }
}

const formatActionWithCurrentRevision = data => {
  const action = data?.customer?.action || data?.updateAction
  return formatActionWithRevision(action, action?.currentRevision)
}

/*
 * `variables` is expected to be of the following form:
 * { name, id } where `id` is the action id and `name` is the customer
 * normalized name.
 */
const loadAction = variables => dispatch => {
  dispatch(actions.setLoading(true))
  return client
    .query({ query: GET_ACTION, variables })
    .then(({ data, error }) => {
      if (error) {
        dispatch(actions.setError(error.message))
      }

      dispatch(
        actions.set({
          name: "data.action",
          value: formatActionWithCurrentRevision(data),
        })
      )
    })
    .catch(error => {
      dispatch(actions.setError(error.message))
      toastAndRethrow(error.message)()
    })
    .finally(() => {
      dispatch(actions.setLoading(false))
    })
}

const createAction = () => (dispatch, getState) => {
  const state = getState()
  const action = selectors.selectAction(state)
  const { customer } = state.currentCustomer
  const variables = {
    cid: customer.id,
    input: {
      description: action.description,
      name: action.name,
    },
    actionRevisionInput: { spec: action.spec },
  }

  const refetchQueries = [GET_ACTIONS]

  return client
    .mutate({ mutation: CREATE_ACTION, variables, refetchQueries })
    .then(() => dispatch(actions.reset()))
    .catch(error => {
      dispatch(actions.setError(error.message))
      toastAndRethrow(error.message)()
    })
}

const updateAction = () => (dispatch, getState) => {
  const state = getState()
  const action = selectors.selectAction(state)
  const { customer } = state.currentCustomer
  const variables = {
    cname: customer.normalizedName,
    actionId: action.id,
    revision: { spec: storeToState(action.spec) },
    action: {
      description: action.description,
      name: action.name,
    },
  }

  const refetchQueries = [GET_REVISIONS]

  return client
    .mutate({ mutation: UPDATE_ACTION, variables, refetchQueries })
    .then(({ data, errors }) => {
      const updatedRevisions = data?.updateAction?.actionRevisions
      if (updatedRevisions) dispatch(actions.updateRevisions(updatedRevisions))
    })
    .catch(error => {
      dispatch(actions.setError(error.message))
      toastAndRethrow(error.message)()
    })
}

const setCurrentRevision = revisionId => (dispatch, getState) => {
  const state = getState()
  const action = selectors.selectAction(state)
  const { customer } = state.currentCustomer
  const variables = {
    cname: customer.normalizedName,
    actionId: action.id,
    input: {
      description: action.description,
      name: action.name,
      currentRevisionId: revisionId,
    },
  }

  const refetchQueries = [GET_REVISIONS]

  return client
    .mutate({ mutation: SET_CURRENT_REVISION, variables, refetchQueries })
    .catch(error => {
      dispatch(actions.setError(error.message))
      toastAndRethrow(error.message)()
    })
}

const saveAction = () => (dispatch, getState) => {
  const action = selectors.selectAction(getState())
  if (action.id) {
    return dispatch(updateAction())
  }
  return dispatch(createAction())
}

const joinCells = node =>
  node.cells
    .filter(c => c.cellType === "code")
    .map(c => c.source.join(""))
    .join("\n\n")

const cellIdx = 0
const createActionFromNode = nodeId => (dispatch, getState) => {
  const node = findNodeById(nodeId, getState())
  const code = joinCells(node)

  dispatch(actions.setName(node.data.label))
  dispatch(actions.setColor({ hex: node.data.color }))
  dispatch(actions.setArguments(node.arguments))
  dispatch(
    actions.setCellSource({
      value: code,
      cellIdx,
    })
  )
}

const allActions = {
  ...actions,
  loadAction,
  createAction,
  updateAction,
  saveAction,
  setCurrentRevision,
  createActionFromNode,
}
export { allActions as actions }
export default actionSlice
