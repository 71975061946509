import dayjs from "dayjs"

import { alphanumid } from "@dbai/tool-box"

import {
  findColumnConfig,
  formatColumnValue,
  getDefaultColumnName,
} from "../../../lib/datasetColumns"

const getTimestamp = iso8601_timestamp => {
  const dt = new Date(Date.parse(iso8601_timestamp))
  return Math.floor(dt.getTime())
}

const getSeriesId = (yInputZone, data) => {
  const { label } = data
  const suffix = label?.name ? "-" + label.group + "-" + label.name : ""
  return `${yInputZone.id}${suffix}`
}

const getAxisFormattingOptions = datasetColumn => {
  return {
    formatter: function () {
      return formatColumnValue(datasetColumn, this.value)
    },
  }
}

const getAxisLabelOptions = (axisOptions, datasetColumn) => {
  const { labels: labelOptions = {} } = axisOptions || {}
  const formattingOptions = datasetColumn
    ? getAxisFormattingOptions(datasetColumn)
    : {}
  return {
    enabled: true,
    ...labelOptions,
    ...formattingOptions,
  }
}

const getSelectedPointOptions = (crossFilters, crossFilter) => {
  const selected = crossFilters?.current?.reduce((acc, point) => {
    if (acc) return acc
    return (
      point.column === crossFilter.group &&
      point.value === crossFilter.label &&
      point.pointId === crossFilter.pointId &&
      point.linkId === crossFilter.linkId
    )
  }, false)

  // if the point is already selected, then deselect it
  if (selected) {
    return []
  } else {
    return [crossFilter]
  }
}

const getSelectedPoints = (
  crossFilters,
  { pointId, group, label, value, datasetColumn }
) => {
  const linkId = alphanumid()
  const crossFilterOptions = {
    value,
    linkId,
    pointId,
    groupByTime: datasetColumn?.groupByTime,
  }

  if (group && label) {
    const labelFilters = getSelectedPointOptions(crossFilters, {
      ...crossFilterOptions,
      value: label,
      column: group,
    })
    const categoryFilters = getSelectedPointOptions(crossFilters, {
      ...crossFilterOptions,
      column: datasetColumn?.column,
      aggregate: datasetColumn?.aggregate,
    })
    return [...labelFilters, ...categoryFilters]
  }

  return getSelectedPointOptions(crossFilters, {
    ...crossFilterOptions,
    column: datasetColumn?.column,
    aggregate: datasetColumn?.aggregate,
  })
}

const filterOnPointClick = (pointDataName, selectPoints, crossFilters) => {
  return function (e) {
    e.preventDefault()
    e.stopPropagation()
    const { group, label, xAxisColumn } = this.series.userOptions.custom || {}
    let selectedValue = this[pointDataName]

    if (xAxisColumn.type === "datetime") {
      selectedValue = dayjs(selectedValue).toISOString()
    }

    const points = getSelectedPoints(crossFilters, {
      group,
      label,
      pointId: this.id,
      value: selectedValue,
      datasetColumn: xAxisColumn,
    })
    selectPoints(points, e)
  }
}

const filterOnCategoryLabelClick = (
  datasetColumn,
  selectPoints,
  crossFilters
) => {
  return function (e) {
    e.preventDefault()
    e.stopPropagation()

    const label = this.axis.categories[this.pos]
    const group = datasetColumn.name

    const points = getSelectedPoints(crossFilters, {
      column: group,
      value: label,
      datasetColumn,
    })
    selectPoints(points, e)
  }
}

const selectSeries = (series, selectPoints) => {
  const chart = series.chart
  const seriesIndex = series.index

  const allHidden = chart.series.reduce((acc, s) => {
    if (s.index === seriesIndex) return acc
    return acc && !s.visible
  }, true)

  chart.series.forEach(function (s) {
    if (s.index !== seriesIndex) {
      if (allHidden) {
        s.setVisible(true, false)
      } else {
        s.setVisible(false, false)
      }
    } else {
    }
  })

  // show clicked series, redraw chart
  series.setVisible(true, false)

  // batch redraw to avoid flickering
  chart.redraw()
}

const filterOnLegendItemClickMap = {
  default: (selectPoints, crossFilters) => {
    return function (e) {
      // Prevent the default action (toggle visibility of the series)
      e.preventDefault()
      e.browserEvent.stopPropagation()
      const { group, label } = this.userOptions?.custom || {}

      if (group && label) {
        const points = getSelectedPoints(crossFilters, {
          group,
          label,
        })
        selectPoints(points, e.browserEvent)
      } else {
        selectSeries(this, selectPoints, crossFilters)
        // reload cross filters so that the hidden series are not included in this chart's tooltip
        selectPoints(crossFilters.current, e.browserEvent)
      }
      return false
    }
  },
  pie: (selectPoints, crossFilters) => {
    return function (e) {
      // Prevent the default action (toggle visibility of the series)
      e.preventDefault()
      e.browserEvent.stopPropagation()
      const { group, label } = this.custom || {}
      if (group && label) {
        const points = getSelectedPoints(crossFilters, {
          group,
          label,
        })
        selectPoints(points, e.browserEvent)
      }
    }
  },
}

const persistTooltip = selectedPoints => {
  return function () {
    if (selectedPoints?.current?.length) {
      // prevent tooltip from being removed from selected point
      return false
    }
  }
}

const getXAxisTitle = (options, datasetColumn) => {
  const { title: titleOptions = {} } = options.xAxis || {}
  const columnLabel = getDefaultColumnName(datasetColumn)
  return {
    enabled: true,
    text: columnLabel,
    ...titleOptions,
  }
}

const getCustomSeriesOptions = (
  data,
  yAxisColumn,
  xAxisColumn,
  originalColor
) => {
  return {
    datasetColumn: findColumnConfig(yAxisColumn, data.columns),
    xAxisColumn: findColumnConfig(xAxisColumn, data.columns),
    originalColor,
    group: data.label?.group,
    label: data.label?.name,
  }
}

export {
  getSeriesId,
  getTimestamp,
  getXAxisTitle,
  persistTooltip,
  filterOnPointClick,
  getAxisLabelOptions,
  getCustomSeriesOptions,
  filterOnCategoryLabelClick,
  filterOnLegendItemClickMap,
}
