import { curry } from "lodash"

const getChartMargin = chart => {
  const { marginTop, marginRight, marginBottom, marginLeft } = chart
  return [marginTop, marginRight, marginBottom, marginLeft].reduce(
    (acc, margin, pos) => {
      if (margin >= 0) {
        return acc.splice(pos, 0, margin)
      }
      return acc
    },
    []
  )
}

const constructChart = curry((selectPoints, options) => {
  const { chart = {}, highchart = {} } = options || {}
  if (!Object.keys(chart).length) {
    return {
      ...options,
      highchart: { ...highchart, chart: {} },
    }
  }

  return {
    ...options,
    highchart: {
      ...highchart,
      chart: {
        ...chart,
        margin: getChartMargin(chart),
        events: {
          click: function (e) {
            // prevent other click events from being triggered when the chart's graph area is selected
            e.preventDefault()
            e.stopPropagation()
            // reset selected points when the graph is selected
            selectPoints([], e)
          },
        },
      },
    },
  }
})

export default constructChart
