import React, { memo, useMemo, useCallback } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import { faExpand } from "@fortawesome/pro-solid-svg-icons"

import { FAMemo } from "@dbai/ui-staples"

import { selectFocused } from "selectors"
import { actions } from "reducers/notebookReducer"

const StyledEmpty = styled.div`
  visibility: "visible";
  transition: all 0.3s ease;
  border: 2px solid
    ${props =>
      props.selected ? props.theme.nodeBorderSelected : props.theme.nodeBorder};
  border-radius: 20px;
  height: 58px;
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: ${props =>
    props.selected ? "0px 0px 10px 10px rgba(255,255,255,0.3)" : "none"};
  label {
    color: ${props =>
      props.selected ? props.theme.nodeBorderSelected : props.theme.nodeBorder};
    font-size: 20px;
    font-family: "Industry";
    align-items: center;
    cursor: pointer;
    padding-top: 6px;
    padding-bottom: 5px;
    padding-right: 10px;
  }
`

const IconContainer = styled.div`
  cursor: pointer;
  padding-right: 3px
  border-radius: 3px;
  background-color: ${({ color }) => color};

  position: relative;
  display: inline-block;
`

const NodeType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  border-radius: 10px 10px 10px 10px;
  height: 40px;
  background-color: ${props => props.color};
  color: white;
  padding-right: 3px;
  padding-bottom: 5px;
`

const NodeTitle = styled.div`
  padding-right: 10px;
  float: left;
  span {
    height: 35px;
    float: left;
    margin-top: 10px;
  }
`

const EmptyNode = ({ id, data }) => {
  const dispatch = useDispatch()
  const { node: focusedNode } = useSelector(selectFocused)

  const selected = useMemo(() => {
    return focusedNode === id
  }, [focusedNode, id])

  const handleClick = useCallback(() => {
    dispatch(
      actions.focusCell({
        cellIdx: 0,
        node: id,
      })
    )
  }, [dispatch, id])

  return (
    <StyledEmpty selected={selected} onClick={handleClick}>
      <NodeTitle>
        <IconContainer>
          <NodeType>
            <FAMemo icon={faExpand} color={data.color} size="2x" />
          </NodeType>
        </IconContainer>
      </NodeTitle>
      <label>EMPTY NODE</label>
    </StyledEmpty>
  )
}

export default memo(EmptyNode)
