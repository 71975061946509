import { gql } from "@apollo/client"

const LAYOUT_SPEC_FRAGMENT = gql`
  fragment LayoutSpecFragment on LayoutSpec {
    x
    y
    h
    w
    i
    minW
    minH
    maxW
    maxH
    isResizable
    resizeHandles
    static
  }
`

const LAYOUT_FRAGMENT = gql`
  ${LAYOUT_SPEC_FRAGMENT}
  fragment LayoutFragment on Layout {
    lg {
      ...LayoutSpecFragment
    }
    md {
      ...LayoutSpecFragment
    }
    sm {
      ...LayoutSpecFragment
    }
    xs {
      ...LayoutSpecFragment
    }
    xxs {
      ...LayoutSpecFragment
    }
  }
`

export const GET_PAGE = gql`
  ${LAYOUT_FRAGMENT}
  query GetPage($id: Int!, $cname: String!) {
    customer(name: $cname) {
      id
      page(id: $id) {
        id
        name
        layers
        createdAt
        updatedAt
        layouts {
          id
          createdAt
          updatedAt
          pageId
          widgetId
          layout {
            ...LayoutFragment
          }
        }
      }
    }
  }
`

export const UPDATE_PAGE = gql`
  mutation UpdatePage(
    $id: Int!
    $cname: String!
    $name: String!
    $layers: [Int]!
  ) {
    updatePage(id: $id, cname: $cname, name: $name, layers: $layers) {
      id
      name
      layers
      createdAt
      updatedAt
    }
  }
`
