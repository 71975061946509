import { useMemo } from "react"
import { pathToRegexp } from "path-to-regexp"
import { useLocation } from "react-router-dom"

const useRouteIsBlacklisted = routes => {
  const { pathname } = useLocation()
  const regExes = useMemo(() => routes.map(r => pathToRegexp(r)), [routes])
  return regExes.some(regEx => regEx.exec(pathname))
}

export default useRouteIsBlacklisted
