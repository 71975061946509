import React, { memo, useMemo, useCallback } from "react"
import { Tooltip } from "antd"

import { getCurrentPaths } from "./utils"
import { useFormContext, useResolvedSchema, useFormData } from "./hooks"

const useFieldValue = (path, schemaKey) => {
  const { dataPath: name } = getCurrentPaths(path, schemaKey)
  return useFormData(name)
}

const JSONSchemaField = props => {
  const { getFormField } = useFormContext()
  const { schema: _schema, onClick, path, schemaKey, children, ...rest } = props
  const value = useFieldValue(path, schemaKey)
  const schema = useResolvedSchema(_schema, value)

  const Component = useMemo(() => {
    return getFormField(schema, path)
  }, [getFormField, schema, path])

  const onFieldClick = useCallback(
    e => {
      e?.stopPropagation && e.stopPropagation()
      onClick && onClick(e)
    },
    [onClick]
  )

  if (!Component) {
    return null
  }

  if (schema.metadata?.editable === false) {
    return <Tooltip title={value}>{value}</Tooltip>
  }

  return (
    <Component
      {...rest}
      path={path}
      schema={schema}
      onClick={onFieldClick}
      schemaKey={schemaKey}
    />
  )
}

export default memo(JSONSchemaField)
