import { useMemo } from "react"
import { useSelector } from "react-redux"

import { selectEditingAppWidget, selectEditingWidgetId } from "../selectors/app"

const useEditingWidget = () => {
  const widget = useSelector(selectEditingAppWidget)
  const widgetId = useSelector(selectEditingWidgetId)
  const initialState = useMemo(() => {
    if (!widget) return {}
    return widget
  }, [widget])
  const widgetIsMounted = useMemo(() => {
    return Boolean(widgetId) && widget?.id === widgetId
  }, [widgetId, widget])
  return [widgetId, initialState, widgetIsMounted]
}

export default useEditingWidget
