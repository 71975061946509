import React, { useRef, useCallback } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"

import { actions } from "../reducers/appReducer"
import JSONSchemaForm from "../JSONSchemaForm"
import appVariablesSchema from "../schemas/appVariablesSchema"
import { selectAppVariables } from "../selectors/app"
import {
  EVENT_TYPE_APPEND,
  EVENT_TYPE_REMOVE,
  EVENT_TYPE_CHANGE,
  EVENT_TYPE_MOVE,
} from "../JSONSchemaForm/constants"

const PaddedDiv = styled.div`
  padding: 0 16px;
`

const getVariableIndex = name => {
  const indeces = name.match(/\[(\d+)\]/)
  if (!indeces) return null
  return indeces[1]
}

const AppVariables = props => {
  const formRef = useRef()
  const dispatch = useDispatch()
  const appVariables = useSelector(selectAppVariables)

  const save = useCallback(
    ({ variables }, errors, changed) => {
      const { name, value, eventType, index, fromIndex, toIndex } = changed

      switch (eventType) {
        case EVENT_TYPE_APPEND:
          dispatch(actions.addAppVariableWithSync(value))
          break
        case EVENT_TYPE_REMOVE:
          dispatch(actions.removeAppVariableWithSync({ index, id: value.id }))
          break
        case EVENT_TYPE_MOVE:
          dispatch(actions.moveAppVariablesWithSync({ fromIndex, toIndex }))
          break
        case EVENT_TYPE_CHANGE:
          const variableIndex = getVariableIndex(name)
          const variable = variables[variableIndex]
          dispatch(actions.setAppVariableWithSync(variable))
          break
        default:
          return
      }
    },
    [dispatch]
  )

  return (
    <PaddedDiv>
      <JSONSchemaForm
        hideSave
        ref={formRef}
        value={{ variables: appVariables }}
        schema={appVariablesSchema}
        onFormChange={save}
      />
    </PaddedDiv>
  )
}

export default AppVariables
