import React from "react"
import { Link, useHistory } from "react-router-dom"
import { Space, Button } from "antd"
import { useMutation } from "@apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencilAlt, faEye } from "@fortawesome/pro-solid-svg-icons"

import DeleteButton from "../../DeleteButton"

const ActionsFormatter = props => {
  const {
    row,
    value,
    onError,
    editPath,
    viewPath,
    deleteGql,
    variables,
    afterDelete,
    refetchQuery,
    refetchVariables,
    confirmDelete = true,
    leadingActions = null,
    trailingActions = null,
  } = props
  const history = useHistory()
  return (
    <Space>
      <Button.Group>
        {leadingActions}
        {editPath && (
          <Button onClick={() => history.push(editPath + value)}>
            <FontAwesomeIcon icon={faPencilAlt} />
          </Button>
        )}
        {viewPath && (
          <Button onClick={() => history.push(viewPath)}>
            <FontAwesomeIcon icon={faEye} />
          </Button>
        )}

        {deleteGql && (
          <DeleteGqlResourceButton
            row={row}
            value={value}
            onError={onError}
            afterDelete={afterDelete}
            confirmDelete={confirmDelete}
            variables={variables}
            deleteGql={deleteGql}
            refetchQuery={refetchQuery}
            refetchVariables={refetchVariables}
          />
        )}
        {trailingActions}
      </Button.Group>
    </Space>
  )
}

const DeleteGqlResourceButton = props => {
  const {
    row,
    value,
    onError,
    afterDelete,
    deleteGql,
    confirmDelete,
    variables,
    refetchQuery,
    refetchVariables,
  } = props

  const [deleteMutation] = useMutation(deleteGql, {
    variables: { id: value, ...variables },
    refetchQueries: [{ query: refetchQuery, variables: refetchVariables }],
  })

  const handleAfterDelete = () => {
    afterDelete && afterDelete({ row, value })
  }
  return (
    <DeleteButton
      onError={onError}
      onDelete={deleteMutation}
      afterDelete={handleAfterDelete}
      confirmDelete={confirmDelete}
    />
  )
}

export default ActionsFormatter
