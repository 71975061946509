import React from "react"

import { AuthBoundary } from "@dbai/ui-staples"

import ActionForm from "components/pages/Workflows/shared/ActionForm"

const EditAction = props => {
  const { afterSubmit } = props

  return (
    <AuthBoundary subject="actions" action="UPDATE">
      <ActionForm afterSubmit={afterSubmit} saveText="CREATE NEW REVISION" />
    </AuthBoundary>
  )
}

export default EditAction
