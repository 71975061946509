import { useRef, useEffect, useMemo, useState, useCallback } from "react"
import dayjs from "dayjs"
import { isArray, isEqual } from "lodash"
import { useQuery } from "@apollo/client"
import { useSelector } from "react-redux"

import { GET_DATASET_COLUMNS, GET_DATASET_AND_COUNT } from "@dbai/ui-staples"

import useWidgetContext from "./useWidgetContext"
import { getDatasetColumns } from "./useDatasetColumns"
import { findColumnConfig } from "../lib/datasetColumns"
import { getComputedColumnValue } from "../lib/tableValues"
import getColumnsForWidgetQuery from "../lib/getColumnsForWidgetQuery"
import { computeRelativeDt, getDateRange, isRelativeDt } from "../lib/datetime"
import {
  selectAppFilters,
  selectAppVariables,
  selectSessionFilters,
  selectCrossFiltersOnPage,
} from "../selectors/app"

// This function alters the columns for each queried dataset to include the formatting options
// defined by the selected columns. By default, each queried datast includes the columns included in that
// dataset, along with the formatting options defined for each column. However, if a column is selected
// in the UI, additional formatting options can be included to override the dataset-level formatting options.
const getDataWithColumnOptions = (data, meta, columns) => {
  return data?.map(dataset => {
    return {
      ...dataset,
      columns: meta.columns.map(dsColumn => {
        const selectedColumn = findColumnConfig(dsColumn, columns)

        // This is a hack to inject the aggregate function into queried column data.
        // TODO: this should be done in the backend
        const alteredColumn = {
          ...dsColumn,
          aggregate: selectedColumn.aggregate,
        }

        // If the column has a format, add it to the column data
        if (!["", null, undefined].includes(selectedColumn?.format)) {
          return {
            ...alteredColumn,
            format: selectedColumn.format,
            formatOptions: selectedColumn.formatOptions,
          }
        }

        return alteredColumn
      }),
    }
  })
}

const getSafeWhereValue = (op, value) => {
  const valueIsArray = isArray(value)
  if (["=", "!="].includes(op) && !valueIsArray) return [value]
  if (!["=", "!="].includes(op) && valueIsArray) return value[0]
  return value
}

const getSafeWhere = where => {
  return where.map(where => {
    const { op, value } = where
    return { ...where, value: getSafeWhereValue(op, value) }
  })
}

const getSafeQueryOptions = (queryOptions, filters) => {
  return {
    ...queryOptions,
    where: getSafeWhere(queryOptions?.where || []),
  }
}

const validateFilterValue = filter => {
  if (Array.isArray(filter.value)) {
    return filter.value.length > 0
  }
  return ![null, undefined, ""].includes(filter.value)
}

const validateFilterConditions = conditions => {
  return conditions.every(cond => {
    const valueIsValid = validateFilterValue(cond)
    return valueIsValid && ["op", "column"].every(key => Boolean(cond[key]))
  })
}

const validateRequiredKeys = filter => {
  return ["op", "column", "value"].every(k => Boolean(filter[k]))
}

export const validateFilter = filter => {
  if (filter?.conditions?.length) {
    if (!filter.datasetId) return false
    return validateFilterConditions(filter.conditions)
  }
  if (!validateRequiredKeys(filter)) return false
  return validateFilterValue(filter)
}

const getDatetimeFilterOption = (filterOption, value) => {
  const { op, picker } = filterOption
  switch (op) {
    case "=": {
      const first = dayjs.utc(value).startOf(picker).toISOString()
      const end = dayjs.utc(value).endOf(picker).toISOString()
      return {
        ...filterOption,
        op: "AND",
        conditions: [
          { ...filterOption, op: ">=", value: first },
          { ...filterOption, op: "<=", value: end },
        ],
      }
    }
    case ">": {
      const first = dayjs.utc(value).endOf(picker).toISOString()
      return { ...filterOption, op: ">", value: first }
    }
    case ">=": {
      const end = dayjs.utc(value).startOf(picker).toISOString()
      return { ...filterOption, op: ">=", value: end }
    }
    case "<": {
      const first = dayjs.utc(value).startOf(picker).toISOString()
      return { ...filterOption, op: "<", value: first }
    }
    case "<=": {
      const end = dayjs.utc(value).endOf(picker).toISOString()
      return { ...filterOption, op: "<=", value: end }
    }
    default:
      return filterOption
  }
}

const getFilterOption = filter => {
  const dataType = filter.columnType || filter.type
  if (dataType !== "datetime") return filter

  const { picker } = filter
  const filterValue = filter.value ?? filter.defaultValue
  const relativeDatetime = isRelativeDt(filterValue)
  const value = relativeDatetime ? computeRelativeDt(filterValue) : filterValue

  // TODO: add support for year, week and quarter
  switch (picker) {
    case "month":
      return getDatetimeFilterOption(filter, value, "month")
    default:
      return {
        ...filter,
        // at this point, value is an iso string, which includes the timezone offset for the users current location.
        // to ensure that the date is not offset by the timezone, we need to convert it to an iso string without the timezone offset
        value: relativeDatetime
          ? value.toISOString()
          : dayjs.utc(value).toISOString(),
      }
  }
}

const getValidFilters = (filters, exclusionList) => {
  return filters.reduce((final, filter) => {
    if (exclusionList?.includes(filter.id)) return final
    const filterOption = getFilterOption(filter)
    return validateFilter(filterOption) ? [...final, filterOption] : final
  }, [])
}

// this method merges the computed columns and computed column data into
// the queried dataset data. This is necessary because computed columns are
// not stored in the database, so they need to be computed on the frontend.
const getComputedColumnData = (selectedColumns, data, appVariables) => {
  // get the computed columns based on selected columns
  const computedColumns = getComputedColumns(selectedColumns)

  if (!computedColumns.length) return data

  return data.map(dataset => {
    // combine dataset columns and computed columns
    const columns = [...dataset.columns, ...computedColumns]

    const rows = dataset.rows.map(row => {
      // calculate computed values for each computed column in the row
      const computedValues = computedColumns.map(column => {
        return getComputedColumnValue(
          dataset.columns,
          column.computation,
          row,
          appVariables
        )
      })

      // combine original row values and computed values
      return [...row, ...computedValues]
    })
    return { ...dataset, columns, rows }
  })
}

const handleDataResponse = (
  results,
  selectedColumns,
  appVariables,
  onCompleted
) => {
  const { stats, data, meta, tableMeta, id } = results?.customer?.dataset || {}

  // get the data with column options based on selected columns
  const dataWithColumnOptions = getDataWithColumnOptions(
    data,
    meta,
    selectedColumns
  )

  // get the computed column data based on selected columns and column options
  const dataWithComputedColumns = getComputedColumnData(
    selectedColumns,
    dataWithColumnOptions,
    appVariables
  )

  onCompleted({ data: dataWithComputedColumns, meta, tableMeta, stats, id })
}

const fetchMoreData = (fetchMore, variables, filters) => {
  return additionalQueryOpts => {
    if (!additionalQueryOpts || !Object.keys(additionalQueryOpts).length) {
      return
    }
    return fetchMore({
      variables: {
        ...variables,
        queryOptions: getSafeQueryOptions({
          ...variables.queryOptions,
          ...additionalQueryOpts,
          where: [
            ...variables.queryOptions.where,
            ...(additionalQueryOpts?.where || []),
            ...filters,
          ],
        }),
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev
        // override previous cache with new data.
        // TODO: this can be improved by merging the previous data with the new data
        return fetchMoreResult
      },
    })
  }
}

// this method takes the selected columns and returns only the computed columns
const getComputedColumns = selectedColumns => {
  return selectedColumns.reduce((final, column) => {
    return column.computation ? [...final, column] : final
  }, [])
}

const getDatasetColumnsFromComputation = (column, tableColumns) => {
  // get the computation expression for the column
  const computation =
    column.computation || findColumnConfig(column, tableColumns)?.computation

  if (!computation?.length) return []

  // iterate over each expression in the computation
  return computation?.reduce((acc, exp) => {
    const { propertyType, type, column: columnName } = exp

    // if the expression is of type "datasetColumn"
    if (propertyType === "datasetColumn") {
      // add the column to the final array
      acc.push({ column: columnName, type, aggregate: column.aggregate })
    } else if (propertyType === "subExpression") {
      // if the expression is of type "subExpression", recursively call this method
      // to get the columns for the subExpression
      const subExpressionColumns = getDatasetColumnsFromComputation(
        { ...exp, aggregate: column.aggregate },
        tableColumns
      )
      acc = [...acc, ...subExpressionColumns]
    }
    return acc
  }, [])
}

// this method gets the primary columns from the selected columns and table columns
const getPrimaryColumns = (selectedColumns, tableColumns) => {
  const allSelectedColumns = selectedColumns.reduce((final, column) => {
    if (column.type === "computed") {
      const columns = getDatasetColumnsFromComputation(column, tableColumns)
      return [...final, ...columns]
    }

    // If the column does not have a "column" property, skip it
    if (!column.column) return final

    return [...final, column]
  }, [])
  return getColumnsForWidgetQuery(allSelectedColumns)
}

const useRefetchQueries = (skip, refetch) => {
  const handledRefetch = useRef(false)
  const { refetchQueries, setRefetchCounter } = useWidgetContext()
  useEffect(() => {
    // if refreshQueries is enabled
    if (refetchQueries && !skip) {
      setRefetchCounter(c => c + 1)

      // perform refetch
      refetch().then(() => {
        setRefetchCounter(c => c - 1)
      })

      handledRefetch.current = true
      return
    }

    // if refreshQueries is disabled, reset the handledRefetch flag
    if (!refetchQueries && handledRefetch.current) {
      handledRefetch.current = false
    }
  }, [refetchQueries, setRefetchCounter, refetch, skip])
}

const excludeFiltersFromSelectedPoints = (
  pageId,
  widgetId,
  widgetType,
  chartType,
  selectedPoints
) => {
  return (
    !pageId ||
    !widgetId ||
    !widgetType ||
    !selectedPoints ||
    (widgetType === "ChartWidget" &&
      !["heatmap", "solidgauge"].includes(chartType))
  )
}

const getPointFilter = point => {
  if (point.groupByTime?.interval >= 1) {
    const { groupByTime } = point

    let conditions = []
    const [start, end] = getDateRange(groupByTime, point.value)

    conditions = [
      { op: ">=", column: point.column, value: start },
      { op: "<=", column: point.column, value: end },
    ]

    return { op: "AND", conditions }
  }

  return {
    op: "=",
    value: [point?.value],
    column: point?.column,
  }
}

const getQueryParamsFromCrossFilters = (
  crossFilters,
  pageId,
  widgetId,
  datasetId
) => {
  return crossFilters.reduce((acc, point) => {
    // only use selected points if the point is on the same page and if the point is derived from the same dataset
    if (
      point?.pageId === pageId &&
      point?.widgetId !== widgetId &&
      point?.datasetId === datasetId
    ) {
      const pointFilter = getPointFilter(point)

      if (point.linkId) {
        const linkedFilterIdx = acc.findIndex(a => a.linkId === point.linkId)

        if (linkedFilterIdx < 0) {
          return [
            ...acc,
            {
              op: "AND",
              linkId: point.linkId,
              conditions: [pointFilter],
            },
          ]
        }

        acc[linkedFilterIdx] = {
          ...acc[linkedFilterIdx],
          conditions: [...acc[linkedFilterIdx].conditions, pointFilter],
        }
        return acc
      }

      return [...acc, pointFilter]
    }
    return acc
  }, [])
}

export const useDatasetData = ({
  query,
  pageId,
  widgetId,
  chartType,
  widgetType,
  onCompleted,
  excludeWidgetFilters,
  excludeGlobalFilters,
  fetchPolicy = "cache-first",
}) => {
  const dataResults = useRef()
  const { cname } = useWidgetContext()
  const [columns, setColumns] = useState([])
  const [dataset, setDataset] = useState(null)
  const appVariables = useSelector(selectAppVariables)
  const [processingLoadedData, setProcessingLoadedData] = useState(false)
  const selectedPoints = useSelector(state =>
    selectCrossFiltersOnPage(state, { pageId })
  )

  const appFilters = useSelector(
    state => selectAppFilters(state, query),
    isEqual
  )

  const selectedPointFilters = useMemo(() => {
    const excludeFilter = excludeFiltersFromSelectedPoints(
      pageId,
      widgetId,
      widgetType,
      chartType,
      selectedPoints
    )
    if (excludeFilter) return []

    const filters = getQueryParamsFromCrossFilters(
      selectedPoints,
      pageId,
      widgetId,
      query?.datasetId
    )

    if (!filters.length) return []
    return [
      {
        op: "OR",
        conditions: filters,
      },
    ]
  }, [selectedPoints, pageId, query, widgetId, chartType, widgetType])

  const sessionFilters = useSelector(
    state => selectSessionFilters(state, { ...query, pageId }) || []
  )

  if (!cname && process.env.NODE_ENV !== "production") {
    console.warn("useDatasetData: cname is not defined")
  }

  const widgetFilters = useMemo(() => {
    if (!isArray(excludeWidgetFilters) && excludeWidgetFilters) return []
    const exclusionList = isArray(excludeWidgetFilters)
      ? excludeWidgetFilters
      : []
    return getValidFilters(sessionFilters, exclusionList)
  }, [sessionFilters, excludeWidgetFilters])

  const validAppFilters = useMemo(() => {
    if (excludeGlobalFilters) return []
    return getValidFilters(appFilters)
  }, [excludeGlobalFilters, appFilters])

  const selectedColumns = useMemo(() => {
    return getPrimaryColumns(query?.select, columns)
  }, [query?.select, columns])

  const safeQueryOptions = useMemo(() => {
    const {
      skip,
      where = [],
      groupByTime,
      query: GQL_QUERY,
      ...rest
    } = query || {}
    const groupByTimeObj = groupByTime?.column ? { groupByTime } : {}
    return getSafeQueryOptions({
      ...(rest || {}),
      ...groupByTimeObj,
      select: selectedColumns,
      where: [
        ...where,
        ...validAppFilters,
        ...widgetFilters,
        ...selectedPointFilters,
      ],
    })
  }, [
    query,
    selectedColumns,
    validAppFilters,
    widgetFilters,
    selectedPointFilters,
  ])

  const dataResponseCallback = useCallback(
    response => {
      setDataset(response)
      onCompleted && onCompleted(response)
      setProcessingLoadedData(false)
    },
    [onCompleted]
  )

  const selectWithComputations = useMemo(() => {
    return query?.select?.map(column => {
      if (column.type === "computed") {
        // when selecting a computed column, only the unique column name is provided.
        // to get the computation and label for that computed column, we need to find the computed column
        // in the list of table columns
        const { computation, label } = findColumnConfig(column, columns) || {}
        return { ...column, computation, label }
      }
      return column
    })
  }, [query, columns])

  const variables = useMemo(() => {
    return {
      cname,
      queryOptions: safeQueryOptions,
      datasetId: safeQueryOptions?.datasetId,
    }
  }, [cname, safeQueryOptions])

  const { loading: loadingColumns } = useQuery(GET_DATASET_COLUMNS, {
    skip: !safeQueryOptions?.datasetId,
    variables: { cname, id: safeQueryOptions?.datasetId },
    onCompleted: results => {
      setColumns(getDatasetColumns(results, []))
    },
  })

  const skip = useMemo(() => {
    return (
      loadingColumns ||
      query.skip ||
      !safeQueryOptions?.datasetId ||
      !safeQueryOptions?.select?.length
    )
  }, [query, loadingColumns, safeQueryOptions])

  const {
    error,
    called,
    loading,
    refetch,
    fetchMore,
    stopPolling,
    startPolling,
  } = useQuery(query.query ?? GET_DATASET_AND_COUNT, {
    skip,
    variables,
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
    onCompleted: results => {
      dataResults.current = results
      handleDataResponse(
        results,
        selectWithComputations,
        appVariables,
        dataResponseCallback
      )
    },
  })

  useEffect(() => {
    if (loading) {
      setProcessingLoadedData(true)
    }
    if (!loading && error) {
      setProcessingLoadedData(false)
    }
  }, [loading, error])

  // recompute data if data includes computed columns and app variables change
  useEffect(() => {
    if (
      dataResults.current &&
      getComputedColumns(selectWithComputations)?.length
    ) {
      handleDataResponse(
        dataResults.current,
        selectWithComputations,
        appVariables,
        dataResponseCallback
      )
    }
  }, [selectWithComputations, appVariables, dataResponseCallback])

  useRefetchQueries(skip, refetch)

  const onFetchMore = fetchMoreData(fetchMore, variables, [
    ...widgetFilters,
    ...validAppFilters,
  ])

  return {
    skipped: skip,
    loading: loading || loadingColumns || processingLoadedData,
    startPolling,
    dataset,
    refetch,
    stopPolling,
    error,
    called,
    fetchMore: onFetchMore,
  }
}

export default useDatasetData
