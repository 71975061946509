import React from "react"
import { useParams } from "react-router-dom"

import {
  AuthBoundary,
  exportDataset,
  useCurrentCustomer,
} from "@dbai/ui-staples"
import { Applet } from "@dbai/applet"

import config from "config"

const AppletView = () => {
  const { id: appId, cname } = useParams()
  const [customer = {}] = useCurrentCustomer()
  return (
    <AuthBoundary subject="apps" resourceId={parseInt(appId)} action="GET">
      <Applet
        cname={cname}
        appConfig={config}
        appId={parseInt(appId)}
        exportDataset={exportDataset}
        customerId={String(customer?.id)}
      />
    </AuthBoundary>
  )
}

export default AppletView
