import React, { useMemo, useCallback } from "react"
import { ColorPicker as AntColorPicker } from "antd"

import useTheme from "../../../hooks/useTheme"
import { withFormFieldWrapper } from "../../FormFieldWrapper"

const ColorPickerCore = props => {
  const { schema, onChange, presets: _presets, ...rest } = props
  const { default: defaultValue, nullable, metadata = {} } = schema
  const { showThemeColors = true } = metadata
  const { token } = useTheme()

  const handleChange = useCallback(
    color => {
      const hex = color.toHexString()
      onChange(hex)
    },
    [onChange]
  )

  const presets = useMemo(() => {
    // If presets are provided, they should be used instead of the theme colors. To prevent an error
    // from being thrown, we need to clone the presets.
    if (_presets?.length) return _presets.map(p => ({ ...p }))
    if (!showThemeColors) return []
    if (!token) return []
    const {
      colorPrimary,
      colorTextBase,
      colorSuccess,
      colorError,
      colorWarning,
      colorBgBase,
      chart: chartTheme,
    } = token
    const {
      seriesColor1,
      seriesColor2,
      seriesColor3,
      seriesColor4,
      seriesColor5,
      seriesColor6,
      seriesColor7,
      seriesColor8,
      seriesColor9,
      seriesColor10,
    } = chartTheme || {}

    return [
      {
        label: "Theme",
        colors: [
          colorPrimary,
          colorTextBase,
          colorSuccess,
          colorError,
          colorWarning,
          colorBgBase,
        ].filter(Boolean),
      },
      {
        label: "Series Colors",
        colors: [
          seriesColor1,
          seriesColor2,
          seriesColor3,
          seriesColor4,
          seriesColor5,
          seriesColor6,
          seriesColor7,
          seriesColor8,
          seriesColor9,
          seriesColor10,
        ].filter(Boolean),
      },
    ]
  }, [showThemeColors, _presets, token])

  const handleClear = useCallback(() => {
    // by default, antd's onClear will set the current color to have 0 transparency.
    // ideally, we want to set the color to undefined.
    onChange(undefined)
  }, [onChange])

  return (
    <AntColorPicker
      onClear={handleClear}
      allowClear={nullable}
      onChange={handleChange}
      defaultValue={defaultValue}
      {...(presets.length ? { presets } : {})}
      {...rest}
    />
  )
}

const ColorPicker = withFormFieldWrapper(ColorPickerCore)
ColorPicker.Core = ColorPickerCore
export default ColorPicker
