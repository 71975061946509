import { gql } from "@apollo/client"

const APP_WIDGET_TYPE_FRAGMENT = gql`
  fragment AppWidgetTypeFragment on AppWidgetType {
    name
    description
    source
    schema
  }
`

export const CREATE_WIDGET_TYPE = gql`
  ${APP_WIDGET_TYPE_FRAGMENT}
  mutation CreateWidgetType($cname: String!, $input: AppWidgetTypeInput!) {
    createAppWidgetType(cname: $cname, input: $input) {
      id
      ...AppWidgetTypeFragment
    }
  }
`

export const UPDATE_WIDGET_TYPE = gql`
  ${APP_WIDGET_TYPE_FRAGMENT}
  mutation UpdateWidgetType(
    $id: Int!
    $cname: String!
    $input: AppWidgetTypeInput!
  ) {
    updateAppWidgetType(id: $id, cname: $cname, input: $input) {
      id
      ...AppWidgetTypeFragment
    }
  }
`

export const DELETE_WIDGET_TYPE = gql`
  ${APP_WIDGET_TYPE_FRAGMENT}
  mutation DeleteWidgetType($id: Int!, $cname: String!) {
    deleteAppWidgetType(id: $id, cname: $cname) {
      id
      ...AppWidgetTypeFragment
    }
  }
`

export const GET_WIDGET_TYPE = gql`
  ${APP_WIDGET_TYPE_FRAGMENT}
  query WidgetType($id: Int!, $cname: String!) {
    customer(name: $cname) {
      id
      widgetType(id: $id) {
        id
        ...AppWidgetTypeFragment
      }
    }
  }
`

export const GET_WIDGET_TYPES = gql`
  ${APP_WIDGET_TYPE_FRAGMENT}
  query WidgetTypes($cname: String!) {
    customer(name: $cname) {
      id
      widgetTypes {
        id
        ...AppWidgetTypeFragment
      }
    }
  }
`
