import React, { memo, useRef, useMemo, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Handle, Position } from "reactflow"
import { actions } from "reducers/notebookReducer"
import { selectNodes, selectFocused, selectNodeStatus } from "selectors"
import NodeIcon from "./NodeIcon"
import { StyledNode, NodeTitle } from "./StyledNode"

const ScriptNode = ({ id, data, type, selected, isConnectable }) => {
  const nodeStatus = useSelector(state =>
    selectNodeStatus(state, { nodeId: id })
  )
  const nodes = useSelector(selectNodes)
  const node = nodes.find(n => n.id === id)

  const dispatch = useDispatch()
  const previousSelected = useRef(selected)
  const { node: focusedNode } = useSelector(selectFocused)
  const focused = useMemo(() => {
    return focusedNode === id
  }, [focusedNode, id])

  const focusCell = useCallback(() => {
    // transition from not selected to selected
    if (selected && !previousSelected.current) {
      previousSelected.current = true
      dispatch(
        actions.focusCell({
          cellIdx: 0,
          node: id,
        })
      )
      return
    }

    // transition from selected to not selected
    if (!selected && previousSelected.current) {
      previousSelected.current = false
      dispatch(actions.clearFocus())
    }

    // transition from selected and not focused to selected and focused
    if (selected && previousSelected.current && !focused) {
      previousSelected.current = true
      dispatch(
        actions.focusCell({
          cellIdx: 0,
          node: id,
        })
      )
    }
  }, [dispatch, selected, focused, id])

  if (!node) return null
  return (
    <StyledNode
      onClick={focusCell}
      selected={selected}
      focused={focused}
      status={nodeStatus}
    >
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={isConnectable}
        selected={focused || selected}
      />
      <div>
        <NodeTitle>
          <NodeIcon
            id={id}
            color={data.color}
            status={nodeStatus}
            type={type}
            isAction={Boolean(data.actionId)}
            selected={selected}
          />
        </NodeTitle>
        <label status={data.runningStatus}>{data.label}</label>
      </div>

      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </StyledNode>
  )
}

export default memo(ScriptNode)
