import React from "react"
import { Row } from "antd"
import styled from "styled-components"

const ContentContainer = styled.div`
  padding: 16px;
  height: 90%;
`

const StyledRow = styled(Row)`
  height: 100%;
`

const Content = props => {
  const { children } = props

  return (
    <ContentContainer {...props}>
      <StyledRow gutter={[16, 16]}>{children}</StyledRow>
    </ContentContainer>
  )
}

export default Content
