import { merge, isArray, mergeWith, curry } from "lodash"

import defaultTheme from "./defaultTheme"

const constructTheme = curry((theme, chartOptions) => {
  const { colorText, colorBorder, chart: chartTheme, fontFamily } = theme || {}
  if (!chartTheme) {
    return merge({}, defaultTheme, chartOptions)
  }
  const {
    background,
    plotBackground,
    seriesColor1,
    seriesColor2,
    seriesColor3,
    seriesColor4,
    seriesColor5,
    seriesColor6,
    seriesColor7,
    seriesColor8,
    seriesColor9,
    seriesColor10,
  } = chartTheme
  const themeOptions = {
    chart: {
      style: {
        color: colorText,
        fontFamily,
      },
      plotBorderColor: colorBorder,
      backgroundColor: background,
      plotBackgroundColor: plotBackground,
    },
    colors: [
      seriesColor1,
      seriesColor2,
      seriesColor3,
      seriesColor4,
      seriesColor5,
      seriesColor6,
      seriesColor7,
      seriesColor8,
      seriesColor9,
      seriesColor10,
    ],
    title: {
      style: {
        color: colorText,
      },
    },
    legend: {
      itemStyle: {
        color: colorText,
      },
    },
    xAxis: {
      title: {
        style: {
          color: colorText,
        },
      },
      labels: {
        style: {
          color: colorText,
        },
      },
    },
    yAxis: {
      title: {
        style: {
          color: colorText,
        },
      },
      labels: {
        style: {
          color: colorText,
        },
      },
    },
    credits: {
      enabled: false,
    },
  }
  return mergeWith({}, themeOptions, chartOptions, (objValue, srcValue) => {
    if (objValue && isArray(srcValue) && !isArray(objValue)) {
      return srcValue.map(item => merge({}, objValue, item))
    }
  })
})

export default constructTheme
