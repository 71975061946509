export const enumOptionsSchema = {
  default: [],
  minItems: 1,
  type: "array",
  title: "Options",
  uniqueItems: true,
  metadata: {
    addButtonProps: { block: true },
  },
  items: {
    default: {},
    type: "object",
    title: "Selection",
    metadata: { layout: { gutter: [16] } },
    required: ["label", "value"],
    properties: {
      label: {
        type: "string",
        title: "Label",
        metadata: {
          fieldProps: {
            placeholder: "Enter a label",
          },
        },
      },
      value: {
        title: "Value",
        type: "string",
      },
    },
  },
}

export const scalarFieldOptions = {
  metadata: {
    sections: {
      general: {
        title: "General Options",
        properties: [
          "key",
          "required",
          "defaultValue",
          "metadata/hideLabel",
          "metadata/fieldProps/placeholder",
          "metadata/layout/span",
          "metadata/hidden",
          "nullable",
          "metadata/colon",
          "metadata/appVariable",
        ],
      },
    },
  },
  properties: {
    key: {
      type: "string",
      metadata: {
        hidden: true,
        defaultValueSource: "uid",
      },
    },
    title: {
      type: "string",
      title: "Label",
      metadata: {
        fieldProps: {
          placeholder: "Enter a label",
        },
      },
    },
    required: {
      type: "boolean",
      title: "Required",
    },
    "metadata/layout/span": {
      type: "number",
      title: "Width",
      description:
        "Width of the field in grid units/columns. The grid has 24 columns, so a span of 12 would take up half the width of the form.",
      minimum: 1,
      maximum: 24,
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "metadata/colon": {
      default: true,
      type: "boolean",
      title: "Show Colon",
    },
    "metadata/hidden": {
      type: "boolean",
      title: "Hidden",
    },
    "metadata/hideLabel": {
      default: false,
      type: "boolean",
      title: "Hide Label",
    },
    "metadata/appVariable": {
      type: "string",
      title: "Map to App Variable",
      metadata: {
        component: "AppVariableSelect",
        saveVariableType: false,
      },
    },
  },
}
