import React, { useMemo } from "react"

import Select from "../shared/Select"
import { useFormData } from "../hooks"
import { getSelectMode } from "../utils"
import { withFormFieldWrapper } from "../FormFieldWrapper"

const getNearestParentSchemaName = name => {
  const reversedName = name.split(".").reverse()
  const metadataIdx = reversedName.indexOf("metadata")
  return reversedName.slice(metadataIdx).reverse().join(".")
}

const SectionListCore = props => {
  const { path, schema } = props
  const parentSchemaName = getNearestParentSchemaName(path)
  const parentData = useFormData(parentSchemaName)
  const mode = getSelectMode(schema)

  const options = useMemo(() => {
    if (!parentData?.sections?.length) return []
    return parentData.sections.map(section => {
      const { title, id } = section
      return { label: title, value: id }
    })
  }, [parentData])

  return <Select mode={mode} options={options} {...props} />
}

const SectionList = withFormFieldWrapper(SectionListCore)

SectionList.Core = SectionListCore
export default SectionList
