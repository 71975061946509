import React from "react"
import styled from "styled-components"

import { AUTO_INCREMENT_COLUMN_NAME } from "./utils"
import { getColorScaleStyles } from "../../lib/colors"
import { getConditionalStyles } from "../../lib/conditionalFormat"
import JSONSchemaField from "../../JSONSchemaForm/JSONSchemaField"

const StyledTableCell = styled.td`
  ${({ textAlign }) => `text-align: ${textAlign};`}
`

const EditableTableCell = props => {
  const {
    style,
    dataIndex,
    record = {},
    column = {},
    children,
    uniqueKeys = [],
    ...rest
  } = props

  if (
    !column.editable ||
    column.type === "computed" ||
    [...uniqueKeys, AUTO_INCREMENT_COLUMN_NAME, "operation"].includes(dataIndex)
  ) {
    return (
      <StyledTableCell style={style} {...rest}>
        {children}
      </StyledTableCell>
    )
  }

  const conditionalStyles = getConditionalStyles(
    column.conditionalFormat,
    record,
    false
  )

  const colorScaleStyles = getColorScaleStyles(column.colorScale, record)

  if (!dataIndex)
    return (
      <StyledTableCell
        style={{ ...style, ...conditionalStyles, ...colorScaleStyles }}
        {...rest}
      />
    )

  const schema = {
    type: ["number", "string", "boolean"],
    metadata: {
      columnType: column.type,
      component: "ColumnValueInput",
    },
  }

  return (
    <StyledTableCell
      style={{ ...style, ...conditionalStyles, ...colorScaleStyles }}
      textAlign={column.textAlign || "left"}
      {...rest}
    >
      <JSONSchemaField
        noStyle
        path="."
        schema={schema}
        schemaKey={column.dataIndex}
      />
    </StyledTableCell>
  )
}

export default React.memo(EditableTableCell)
