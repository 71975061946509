const defaultBlacklist = ["__typename"]

// storeToState recursively iterates `data` and strips out any GraphQL cruft.
// The returned object should be suitable for use as the initialState for a
// Form and also safe to be sent back to the GraphQL API.
const storeToState = (data, blacklist = defaultBlacklist) => {
  if (!hasProps(data)) return data

  return Object.keys(data)
    .filter(isAllowed(blacklist))
    .reduce((prev, key) => {
      const current = data[key]

      if (Array.isArray(current)) {
        prev[key] = current.map(c => storeToState(c))
      } else if (hasProps(current)) {
        prev[key] = storeToState(current)
      } else {
        prev[key] = current
      }
      return prev
    }, {})
}

const isAllowed = blacklist => key => {
  return blacklist.indexOf(key) === -1
}

const hasProps = obj => {
  return !!obj && typeof obj === "object" && Object.keys(obj).length > 0
}

export default storeToState
