import React, { useRef, useState, useCallback, useMemo, useEffect } from "react"
import dayjs from "dayjs"
import { isEqual } from "lodash"
import styled from "styled-components"
import { toast } from "react-toastify"
import { Tabs, Row, Col, Space } from "antd"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import EditComponent from "./EditComponent"
import { selectComponent } from "selectors"
import { actions } from "reducers/componentReducer"
import ComponentSettings from "./ComponentSettings"
import ComponentTesting from "./ComponentTesting"
import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"
import SaveComponentButton from "../SaveComponentButton"
import { reloadComponents } from "./CurrentCheckbox"

const StyledTabs = styled(Tabs)`
  padding: 0 16px;
`

const ToastMessage = props => {
  const { setActiveTab } = props
  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>COMPONENT SAVED. </Col>
      <Col span={24}>
        <Space
          align="baseline"
          onClick={() => {
            setActiveTab("settings")
          }}
        >
          VIEW REVISIONS <FontAwesomeIcon icon={faArrowRight} />
        </Space>
      </Col>
    </Row>
  )
}

const Edit = props => {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState("code")
  const { customer } = props
  const { id: componentId } = useParams()
  const componentCache = useRef()
  const component = useSelector(selectComponent)
  const [componentChanged, setComponentChanged] = useState(false)
  const [loadLatestRevision, setLoadLatestRevision] = useState(false)

  const loadRevision = () => {
    dispatch(
      actions.loadComponent({ cname: customer.normalizedName, id: componentId })
    )
  }

  useEffect(loadRevision, [dispatch, componentId, customer.normalizedName])

  const workflowsRoute = `/${customer.normalizedName}/workflows`
  const componentsRoute = `${workflowsRoute}/components`

  const routes = [
    { breadcrumbName: customer.name },
    { breadcrumbName: "Workflows", url: workflowsRoute },
    { breadcrumbName: "Components", url: componentsRoute },
    { breadcrumbName: `${component.name}`, url: componentsRoute },
  ]

  const items = useMemo(() => {
    return [
      {
        key: "code",
        label: "Code",
        children: (
          <EditComponent
            activeTab={activeTab}
            cname={customer.normalizedName}
            componentId={componentId}
          />
        ),
      },
      {
        key: "settings",
        label: "Settings",
        children: <ComponentSettings />,
      },
      {
        key: "testing",
        label: "Testing",
        children: <ComponentTesting />,
      },
    ]
  }, [customer.normalizedName, componentId, activeTab])

  const cacheComponent = useCallback(
    c => {
      const { spec, name, description, id } = c || component
      componentCache.current = { spec, name, description, id }
    },
    [component]
  )

  const afterSubmit = useCallback(e => {
    setComponentChanged(false)
    toast.success(<ToastMessage setActiveTab={setActiveTab} />)
  }, [])

  const publishRevision = useCallback(
    e => {
      afterSubmit(e)
      setLoadLatestRevision(true)
      setTimeout(() => {
        // Give it a second - the new revision may not be available immediately
        reloadComponents(customer.normalizedName)
      }, 1000)
    },
    [afterSubmit, customer.normalizedName]
  )

  useEffect(() => {
    if (loadLatestRevision) {
      const latestRevision = component.componentRevisions.reduce((acc, rev) => {
        if (!acc.createdAt) return rev
        return dayjs(rev.createdAt).isAfter(acc.createdAt) ? rev : acc
      }, {})
      if (latestRevision?.id && latestRevision.id !== component.revisionId) {
        dispatch(actions.loadRevision(latestRevision.id))
        dispatch(actions.setCurrentRevision(latestRevision.id))
      }
      setLoadLatestRevision(false)
    }
  }, [dispatch, loadLatestRevision, component])

  useEffect(() => {
    if (!component?.id) return
    if (!componentCache.current?.id) {
      cacheComponent(component)
      return
    }
    const { spec, name, description, id } = component
    if (!isEqual({ spec, name, description, id }, componentCache.current)) {
      setComponentChanged(true)
    } else {
      setComponentChanged(false)
    }
  }, [component, cacheComponent])

  return (
    <>
      <PageHeader routes={routes} />
      <StyledTabs
        items={items}
        activeKey={activeTab}
        onTabClick={setActiveTab}
        tabBarExtraContent={
          componentChanged ? (
            <Space>
              <SaveComponentButton
                beforeSubmit={cacheComponent}
                afterSubmit={afterSubmit}
                saveText="CREATE NEW REVISION"
              />
              <SaveComponentButton
                beforeSubmit={cacheComponent}
                afterSubmit={publishRevision}
                saveText="CREATE AND PUBLISH NEW REVISION"
              />
            </Space>
          ) : null
        }
      />
    </>
  )
}

export default withCustomer(Edit)
