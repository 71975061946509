import ResizeObserver from "resize-observer-polyfill"
const resizeObserverFactory = callback => {
  return new ResizeObserver(entries => {
    const entry = entries[0]
    if (entry.contentBoxSize) {
      // Firefox implements `contentBoxSize` as a single
      // content rect, rather than an array
      const { inlineSize, blockSize } = Array.isArray(entry.contentBoxSize)
        ? entry.contentBoxSize[0]
        : entry.contentBoxSize
      callback({ width: inlineSize, height: blockSize })
    }
    callback(entry.contentRect)
  })
}
export default resizeObserverFactory
