import { createSlice } from "@reduxjs/toolkit"

import { createCommonActions } from "@dbai/tool-box"

import client from "apolloClient"
import { CREATE_PACKAGE, GET_PACKAGES } from "queries"

const initialState = {
  id: null,
  workflowId: null,
  tag: "",
  createdAt: "",
  updatedAt: "",
}

const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    ...createCommonActions(initialState),
  },
})

const { actions } = packageSlice

const createPackage =
  (extraVars = {}) =>
  (dispatch, getState) => {
    const { id, createdAt, updatedAt, workflowId, ...input } =
      getState().package
    const variables = { workflowId, ...extraVars }

    return client.mutate({
      variables: { input, ...variables },
      mutation: CREATE_PACKAGE,
      refetchQueries: [GET_PACKAGES.name],
    })
  }

const allActions = {
  ...actions,
  createPackage,
}

export { allActions as actions }
export const { name, reducer, caseReducer } = packageSlice
export default packageSlice
