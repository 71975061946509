import React from "react"
import { components } from "react-select"

const compactOverrides = {
  DropdownIndicator: props => {
    if (props.isDisabled) {
      return null
    }

    const value = props.selectProps.value

    if (value === null || value.value === "") {
      return (
        <div className="db-select__indicators">
          <components.DownChevron />
        </div>
      )
    }

    return <></>
  },
  ClearIndicator: props => {
    return (
      <div className="db-select__indicators" {...props.innerProps}>
        <components.CrossIcon />
      </div>
    )
  },
  IndicatorSeparator: () => <></>,
}

const getOverrides = type => {
  if (type === "compact") {
    return compactOverrides
  }

  return {}
}

export { getOverrides }
