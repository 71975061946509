/*
 * Take the item contained in array `a` at index `from` and swap it with the
 * item located at `to`.
 */
const arraySwap = (a, from, to) => {
  return a.reduce((acc, next, idx) => {
    if (idx === from) return [...acc, a[to]]
    if (idx === to) return [...acc, a[from]]
    return [...acc, next]
  }, [])
}

export default arraySwap
