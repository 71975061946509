import React, { useEffect, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"

import { SidePane } from "@dbai/ui-staples"

import NotificationForm from "./NotificationForm"
import { actions } from "reducers/scheduleReducer"
import NotificationsTable from "./NotificationsTable"
import { selectCurrentCustomerNormalizedName } from "selectors"
import { ScheduleTableContext } from "components/pages/Workflows/Schedules/SchedulesTable"

const PanelContent = props => {
  const { cname, scheduleId, closeAlertsPanel } = props
  const dispatch = useDispatch()

  useEffect(() => {
    if (!scheduleId) return
    dispatch(actions.loadSchedule({ cname, id: scheduleId }))
  }, [cname, dispatch, scheduleId])

  return (
    <>
      <NotificationForm
        cname={cname}
        scheduleId={scheduleId}
        closeAlertsPanel={closeAlertsPanel}
      />
      <NotificationsTable />
    </>
  )
}

const AlertManagementPanel = () => {
  const cname = useSelector(selectCurrentCustomerNormalizedName)
  const { scheduleId, closeAlertsPanel } = useContext(ScheduleTableContext)

  return (
    <SidePane
      mask
      width="50vw"
      title="Manage Notifications"
      isVisible={Boolean(scheduleId)}
      onCloseClicked={closeAlertsPanel}
    >
      <PanelContent
        cname={cname}
        scheduleId={scheduleId}
        closeAlertsPanel={closeAlertsPanel}
      />
    </SidePane>
  )
}

export default AlertManagementPanel
