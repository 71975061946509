import React, { useRef, useMemo, useState, useCallback } from "react"
import { merge } from "lodash"
import { useDispatch } from "react-redux"

import Page from "./Page"

import { useWidgetContext } from "../../hooks"
import { actions } from "../../reducers/appReducer"
import { generateFullBreakpointLayout } from "../../lib/layout"

const getDropDimensions = item => {
  const { x, y } = item
  return {
    ...item,
    x: Math.max(0, x - 1),
    y: Math.max(0, y - 1),
  }
}

const withPageData = Comp => props => {
  const dispatch = useDispatch()
  const { pageId, containerId, compactionMethod } = props
  const { editable, widgetSchema } = useWidgetContext()
  const [hasDragOver, setHasDragOver] = useState(false)
  const gridRendered = useRef(false)
  const updateInFlight = useRef(false)

  const saveBreakpointLayouts = useCallback(
    layoutUpdates => {
      // this is to prevent the layout from being saved on the first render
      if (!gridRendered.current) {
        gridRendered.current = true
        return
      }
      if (updateInFlight.current) return

      updateInFlight.current = true
      dispatch(
        actions.saveLayouts({
          editable,
          widgetSchema,
          layouts: layoutUpdates,
        })
      )
      updateInFlight.current = false
    },
    [dispatch, editable, widgetSchema]
  )

  const onDragStop = useCallback(
    (breakpointLayouts, oldLayout, newLayout) => {
      if (compactionMethod !== "freeform") return
      if (updateInFlight.current) return
      // when the compaction method is freeform, then the 'allowOverlap' option is set to true.
      // this prevents the onLayoutChange handler from running when an item is dragged. because
      // of this, we handle saving after drag on the freeform compaction method here.
      updateInFlight.current = true
      dispatch(
        actions.saveLayout({
          layout: newLayout,
          editable,
          widgetSchema,
        })
      )
      updateInFlight.current = false
    },
    [dispatch, editable, widgetSchema, compactionMethod]
  )

  const { widgetRegistry } = useWidgetContext()
  const handleDrop = useCallback(
    (layout, layoutItem, _event) => {
      // Get the data from the dragged item
      setHasDragOver(false)
      const data = _event.dataTransfer.getData("text/plain")
      if (!data) return

      try {
        const { name: widgetType } = JSON.parse(data)
        const {
          label,
          initialLayout = {},
          allowFullEditor,
        } = widgetRegistry[widgetType]
        const safeDimensions = getDropDimensions(layoutItem)
        if (widgetType) {
          // dropped widget layout will contain just the x/y coordinates
          const droppedWidgetLayout = generateFullBreakpointLayout({
            x: safeDimensions.x,
            y: safeDimensions.y,
          })

          // the initialLayout contains the initial w/h for the widget based on its type.
          // merging the two layouts will give us the initial layout for the dragged in widget
          const mergedLayout = merge({}, initialLayout, droppedWidgetLayout)

          const widget = {
            type: widgetType,
            name: label,
            layout: mergedLayout,
          }

          dispatch(
            actions.spawnNewWidget({
              widget,
              pageId,
              widgetSchema,
              allowFullEditor,
              editWidget: true,
            })
          )
        }
      } catch (e) {
        // ignore error
      }
    },
    [widgetRegistry, widgetSchema, pageId, dispatch]
  )

  const onDragEnter = useCallback(() => {
    setHasDragOver(true)
  }, [])

  const onDragLeave = useCallback(() => {
    setHasDragOver(false)
  }, [])

  const className = useMemo(() => {
    return `${containerId} ${props.className || ""} ${
      hasDragOver ? "has-drag-over" : ""
    }`
  }, [props.className, containerId, hasDragOver])

  return (
    <Comp
      onDrop={handleDrop}
      editable={editable}
      className={className}
      onDragStop={onDragStop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      handleLayoutChange={saveBreakpointLayouts}
      {...props}
    />
  )
}

export default withPageData(Page)
