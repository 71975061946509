import getColumnsForWidgetQuery from "../../lib/getColumnsForWidgetQuery"
import { getConditionalFormatQueryParams } from "../../lib/conditionalFormat"

const getOrderBy = widget => {
  const { datasetColumn = {} } = widget
  const { column, type, aggregate } = datasetColumn
  if (type === "datetime" && aggregate === "max") {
    return [`${column} DESC`]
  }
  if (type === "datetime" && aggregate === "min") {
    return [`${column} ASC`]
  }
  return []
}

const getSelect = widget => {
  const { datasetColumn = {} } = widget

  const safeSelect = getColumnsForWidgetQuery([
    datasetColumn,
    ...getConditionalFormatQueryParams(widget, true),
  ])

  return safeSelect.reduce((acc, columnConfig) => {
    // do not include datetime aggregation
    if (columnConfig.type === "datetime" && columnConfig.aggregate) {
      return [...acc, { column: columnConfig.column, type: columnConfig.type }]
    }
    return [...acc, columnConfig]
  }, [])
}

const queryResolver = widget => {
  const {
    where,
    offset,
    datasetId,
    pollInterval,
    datasetColumn = {},
  } = widget || {}

  const select = getSelect(widget)
  const orderBy = getOrderBy(widget)
  const skip = !datasetColumn?.column || !datasetColumn?.aggregate

  return {
    skip,
    where,
    offset,
    select,
    orderBy,
    limit: 1,
    datasetId,
    pollInterval,
  }
}

export default queryResolver
