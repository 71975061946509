import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import CodeMirror from "codemirror"
import styled from "styled-components"
import { useQuery } from "@apollo/client"

import Spinner from "./Spinner"
import ErrorMessage from "./ErrorMessage"
import { GET_ME } from "../queries/users"

import "codemirror/mode/python/python"
import "codemirror/mode/javascript/javascript"

// html/css support
import "codemirror/mode/xml/xml"
import "codemirror/mode/css/css"

// code folding support
import "codemirror/addon/fold/foldgutter.css"
import "codemirror/addon/fold/brace-fold"
import "codemirror/addon/fold/comment-fold"
import "codemirror/addon/fold/foldcode"
import "codemirror/addon/fold/foldgutter"
import "codemirror/addon/fold/indent-fold"
import "codemirror/addon/fold/markdown-fold"
import "codemirror/addon/fold/xml-fold"

const fontSizes = {
  small: "9px",
  medium: "12px",
  large: "18",
}

const Wrapper = styled.div`
  overflow-x: auto;
  .CodeMirror {
    height: auto;
    font-size: ${props => props.fontSize};
  }
`

const createCM = ({ node, mode, ...cmOptions }) => {
  const cm = CodeMirror.fromTextArea(node, {
    lineNumbers: true,
    keyMap: "sublime",
    viewportMargin: Infinity,
    ...cmOptions,
    mode,
    lineWrapping: true,
    autoCloseTags: true,
    autoCloseBrackets: true,
    matchBrackets: true,
    showCursorWhenSelecting: true,
    foldGutter: true,
    gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
    styleActiveLine: true,
  })

  if (!node.value) cm.setValue("")
  cm.setOption("comment", true)
  return cm
}

const CodeEditor = props => {
  const {
    mode,
    initialValue,
    initCodeMirror,
    options,
    watch = null,
    onChange = () => {},
  } = props
  const { error, data, loading } = useQuery(GET_ME)
  const [codeMirror, setCodeMirror] = useState(null)
  const userPreferences = data?.me?.preferences || {}
  const theme = userPreferences.theme || "default"
  const [currentWatchValue, setCurrentWatchValue] = useState(watch)
  const watchValueChanged = currentWatchValue !== watch

  const fontSize = fontSizes[userPreferences.fontSize || "medium"]

  const textAreaCallback = useCallback(
    node => {
      if (!node || codeMirror) return null

      const cm = createCM({ node, theme, ...options, mode })

      setCodeMirror(cm)
    },
    [codeMirror, mode, options, theme]
  )

  useEffect(() => {
    if (!initCodeMirror || !codeMirror) return

    codeMirror && initCodeMirror(codeMirror)
  }, [codeMirror, initCodeMirror])

  useEffect(() => {
    if (watchValueChanged) {
      codeMirror.setValue(initialValue)
      setCurrentWatchValue(watch)
    }
  }, [codeMirror, initialValue, watch, watchValueChanged])

  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  return (
    <Wrapper fontSize={fontSize}>
      <textarea
        value={initialValue}
        ref={textAreaCallback}
        onChange={onChange}
      />
    </Wrapper>
  )
}

/*
 * NOTE: If `watch` is provided it should be a stable identifier. By default
 * it is null, so if not provided CodeMirror will not load new data.
 */
CodeEditor.propTypes = {
  mode: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  initCodeMirror: PropTypes.func,
  options: PropTypes.object,
  watch: PropTypes.any,
}

export default CodeEditor
