const stringToColor = str => {
  let hash = 0
  str.split("").forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash)
  })
  let color = "#"
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff
    color += value.toString(16).padStart(2, "0")
  }

  // Check for all white or all black and adjust slightly
  if (color === "#000000") {
    color = "#010101"
  } else if (color === "#FFFFFF") {
    color = "#FEFEFE"
  }

  return color
}

export default stringToColor
