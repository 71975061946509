import React from "react"
import { Segmented } from "antd"

import { withFormFieldWrapper } from "../../FormFieldWrapper"

const BaseSegmentedCore = props => {
  return <Segmented {...props} defaultValue={props.value} />
}

const BaseSegmented = withFormFieldWrapper(BaseSegmentedCore)
BaseSegmented.Core = BaseSegmentedCore
export default BaseSegmented
