import React, { useState, useCallback, useRef, useMemo, useEffect } from "react"
import { match } from "path-to-regexp"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Typography, Row, Col, Layout, Divider, Breadcrumb, Input } from "antd"
import { faPenToSquare } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const { Title } = Typography

const StyledPageHeader = styled(Layout.Header)`
  background: transparent;

  && {
    // offset divider
    ${({ divider }) => (divider ? "margin-bottom: -10px;" : "")}

    padding: 0 1rem;

    .ant-page-header-back-button {
      vertical-align: middle;
    }
  }
  .ant-typography ol li {
    margin-inline: 3px;
  }
  .ant-breadcrumb ol {
    list-style: none !important;
  }
`

const StyledDivider = styled(Divider)`
  && {
    margin: 0.66rem 0 0.66rem 0;
  }
`

const StyledInput = styled(Input)`
  && {
    .ant-input-group-addon {
      background-color: unset;
      border: none;
      padding: 0;
      line-height: 1.5715;
    }
    .ant-input {
      padding: 0 0.5rem;
    }
  }
`

const EditableCrumb = props => {
  const { name, value, handleSave, onChange, ...rest } = props
  const [title, setTitle] = useState(value)
  const changed = useRef(false)
  const inputRef = useRef()

  useEffect(() => {
    setTitle(t => t ?? value)
  }, [value])

  const onSave = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      changed.current && handleSave && handleSave(title, e)
      changed.current = false
    },
    [title, handleSave]
  )

  const handleEnter = e => {
    if (e.key === "Enter") {
      document.activeElement.blur()
    }
  }

  const handleChange = e => {
    changed.current = true
    setTitle(e.target.value)
    onChange && onChange(e.target.value)
  }

  return (
    <StyledInput
      name={name}
      value={title}
      ref={inputRef}
      variant="borderless"
      onBlur={onSave}
      onChange={handleChange}
      onKeyDown={handleEnter}
      addonBefore={<FontAwesomeIcon icon={faPenToSquare} />}
      {...rest}
    />
  )
}

const BreadcrumbContent = props => {
  const { route, isLast } = props
  const { url, breadcrumbName, onChange, handleSave } = route

  if (onChange || handleSave) {
    return <EditableCrumb {...route} />
  }

  if (isLast || !url) return breadcrumbName || ""

  return <Link to={url}>{breadcrumbName}</Link>
}

// NOTE: `path-to-regexp` is the package used internally by `react-router` so
// we should get consistent results using it directly or via `react-router`.
const filterRouteForUrl = currentUrl => route => {
  const { path } = route
  // No path provided. Always render.
  if (!path) return true

  const checkRoute = match(path, { decode: decodeURIComponent })
  return checkRoute(currentUrl)
}

const PageHeader = props => {
  const { routes, children, divider, className, ...rest } = props

  const title = useMemo(() => {
    const filteredRoutes = routes.filter(
      filterRouteForUrl(window.location.pathname)
    )
    const items = filteredRoutes.map((route, idx) => {
      return {
        title: (
          <BreadcrumbContent route={route} isLast={idx === routes.length - 1} />
        ),
      }
    })
    return <Breadcrumb items={items} />
  }, [routes])

  return (
    <>
      <StyledPageHeader className={className} divider={divider} {...rest}>
        <Row wrap={false} align="middle" style={{ height: "100%" }}>
          <Col flex={1}>
            <Title style={{ margin: 0 }}>{title}</Title>
          </Col>
          <Col>{children}</Col>
        </Row>
      </StyledPageHeader>
      {divider ? <StyledDivider /> : null}
    </>
  )
}

export default React.memo(PageHeader)
