import React from "react"
import { Select as AntSelect } from "antd"

import { filterOption } from "../../lib/selectInputField"

const Select = props => {
  const {
    mode,
    value,
    onBlur,
    loading,
    options,
    onClick,
    onChange,
    showSearch = true,
    placeholder,
    popupMatchSelectWidth,
  } = props
  return (
    <AntSelect
      mode={mode}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      loading={loading}
      options={options}
      onChange={onChange}
      showSearch={showSearch}
      placeholder={placeholder}
      filterOption={filterOption}
      popupMatchSelectWidth={popupMatchSelectWidth}
    />
  )
}

export default Select
