import React, { useMemo, useRef, useEffect } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"

import { SidePane, Spinner } from "@dbai/ui-staples"

import Comment from "./Comment"
import CommentInput from "./CommentInput"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const CommentsContainer = styled.div`
  flex-grow: 4;
  overflow: auto;
`

// Currently five minutes
const timeInterval = 1000 * 60 * 5
const intervalHasPassed = (prior, current) => {
  if (!prior || !current) return true
  const interval = new Date(current.createdAt) - new Date(prior.createdAt)
  return interval > timeInterval
}

const checkSameDay = (prior, current) => {
  if (!prior) return false
  const priorDate = new Date(prior.createdAt)
  const currentDate = new Date(current.createdAt)
  const sameDay = currentDate.getDay() === priorDate.getDay()
  const sameYear = currentDate.getYear() === priorDate.getYear()
  return sameDay && sameYear
}

const mapToComment = (comment, index, comments) => {
  const priorComment = comments[index - 1] || null
  const userChanged = !priorComment || priorComment.userId !== comment.userId
  const showTime = intervalHasPassed(priorComment, comment)
  const showUser = userChanged || !checkSameDay(priorComment, comment)

  return (
    <Comment
      key={comment.id}
      showUser={showUser}
      showTime={showTime}
      {...comment}
    />
  )
}

const CommentsContent = ({ commentFloor, query, variables }) => {
  const hasScrolled = useRef(false)
  const { data, loading } = useQuery(query, {
    variables,
    fetchPolicy: "cache-and-network",
  })

  // When we first render, scroll to the bottom.
  useEffect(() => {
    if (hasScrolled.current) return
    if (commentFloor.current) {
      hasScrolled.current = true
      commentFloor.current.scrollIntoView()
    }
  })
  const comments = data?.customer?.workflow?.comments
  const sortedComments = useMemo(() => {
    const safeComments = comments || []
    return [...safeComments].sort((a, b) => {
      if (a.createdAt > b.createdAt) return 1
      if (a.createdAt < b.createdAt) return -1
      return 0
    })
  }, [comments])

  if (loading) return <Spinner />

  return (
    <CommentsContainer>
      {sortedComments.map(mapToComment)}
      <div ref={commentFloor} />
    </CommentsContainer>
  )
}

const Comments = props => {
  const { open, closePanel, query, variables } = props
  const commentFloor = useRef()

  return (
    <>
      <SidePane
        title="Comments"
        isVisible={open}
        width="30vw"
        onCloseClicked={closePanel}
      >
        <Wrapper>
          <CommentsContent
            query={query}
            variables={variables}
            commentFloor={commentFloor}
          />
          <CommentInput commentFloor={commentFloor} />
        </Wrapper>
      </SidePane>
    </>
  )
}

export default Comments
