import { createSlice } from "@reduxjs/toolkit"

import { createCommonActions } from "@dbai/tool-box"

import client from "apolloClient"
import {
  CREATE_INSTANCE,
  DELETE_INSTANCE,
  START_INSTANCE,
  STOP_INSTANCE,
  GET_INSTANCES,
} from "queries"

const initialState = {
  instance: {
    name: "",
    type: "",
  },
}

const instanceSlice = createSlice({
  name: "instance",
  initialState,
  reducers: {
    ...createCommonActions(initialState),
  },
})

const { actions } = instanceSlice

const createInstance =
  (extraVars = {}) =>
  (dispatch, getState) => {
    const input = getState().instance.instance
    const variables = { input, ...extraVars }

    return client.mutate({
      variables,
      mutation: CREATE_INSTANCE,
      refetchQueries: [GET_INSTANCES.name],
    })
  }

const stopInstance =
  (variables = {}) =>
  (dispatch, getState) => {
    return client.mutate({
      variables,
      mutation: STOP_INSTANCE,
      refetchQueries: [GET_INSTANCES.name],
    })
  }

const startInstance =
  (variables = {}) =>
  (dispatch, getState) => {
    return client.mutate({
      variables,
      mutation: START_INSTANCE,
      refetchQueries: [GET_INSTANCES.name],
    })
  }

const deleteInstance =
  (variables = {}) =>
  (dispatch, getState) => {
    return client.mutate({
      variables,
      mutation: DELETE_INSTANCE,
      refetchQueries: [GET_INSTANCES.name],
    })
  }

const allActions = {
  ...actions,
  createInstance,
  deleteInstance,
  startInstance,
  stopInstance,
}

export { allActions as actions }
export const { name, reducer, caseReducer } = instanceSlice
export default instanceSlice
