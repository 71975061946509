import React from "react"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import PropTypes from "prop-types"
import styled from "styled-components"

import AppletView from "./AppletView"
import AppletEditor from "./AppletEditor"

dayjs.extend(utc)

const StyledContainer = styled.div`
  label {
    margin-bottom: 0;
  }
`

const Applet = props => {
  const { editable } = props
  const AppletComp = editable ? AppletEditor : AppletView

  return (
    <StyledContainer>
      <AppletComp {...props} />
    </StyledContainer>
  )
}

Applet.propTypes = {
  editable: PropTypes.bool,
  cname: PropTypes.string.isRequired,
  appId: PropTypes.number.isRequired,
  customerId: PropTypes.string.isRequired,
  exportDataset: PropTypes.func.isRequired,
}

export default Applet
