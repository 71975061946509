import React, { useRef, useEffect, useCallback, useState } from "react"
import { theme } from "antd"
import { get, debounce } from "lodash"
import PropTypes from "prop-types"
import CodeMirror from "codemirror"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { useSelector } from "react-redux"
import { CodemirrorBinding } from "y-codemirror"

import {
  GET_ME,
  TextArea,
  ErrorMessage,
  useFormMeta,
  useFormState,
  useFormActions,
} from "@dbai/ui-staples"

import {
  addCodeMirrorInstance,
  removeCodeMirrorInstance,
} from "lib/codeMirrorStore"
import { useYjs } from "hooks"
import "codemirror/keymap/vim"
import "codemirror/keymap/sublime"
import "codemirror/mode/python/python"
import "codemirror/addon/comment/comment"
import "codemirror/mode/markdown/markdown"

const Wrapper = styled.div`
  overflow-x: auto;
  .CodeMirror {
    height: auto;
    font-size: ${props => props.fontSize};
  }
`

const { useToken } = theme

const noop = () => {}

const fontSizes = {
  small: "9px",
  medium: "12px",
  large: "18",
}

const cellTypeModes = {
  code: "python",
  markdown: "markdown",
}

const initCM = ({ node, theme, cellType, callBack = noop }) => {
  const cm = CodeMirror.fromTextArea(node, {
    theme,
    lineNumbers: true,
    keyMap: "sublime",
    viewportMargin: Infinity,
    mode: cellTypeModes[cellType] || "python",
  })

  if (!node.value) cm.setValue("")
  cm.setOption("comment", true)
  callBack(cm)
  return cm
}

const Editor = props => {
  const {
    name,
    path,
    nodeId,
    cellIdx,
    cellType,
    className,
    isFocused,
    collapsed,
    afterInit,
    extraKeys = {},
    onFocus = noop,
  } = props

  const isCollapsed = useRef(collapsed)
  const { provider: wsProvider, doc: yDoc } = useYjs()
  const { error, data } = useQuery(GET_ME)
  const { formSet } = useFormActions()
  const { token } = useToken()
  const userPreferences = data?.me?.preferences || {}

  const uuid = useSelector(state => get(state, `notebook.${path}.uuid`))
  const formState = useFormState()
  const formMeta = useFormMeta()
  const formattedCode = (get(formState, name, [""]) || [""]).join("")
  const [codeMirror, setCodeMirror] = useState(null)

  useEffect(() => {
    if (codeMirror) addCodeMirrorInstance(uuid, codeMirror)
    return () => removeCodeMirrorInstance(uuid)
  }, [uuid, codeMirror])

  const commit = useCallback(
    doc => {
      const unsplitCode = doc.getValue()
      let source = unsplitCode.split("\n")
      if (unsplitCode === formattedCode) {
        return
      }

      // Do not append a new line to the last line
      source = source.map((line, idx) =>
        idx === source.length - 1 ? line : `${line}\n`
      )
      formSet({ name, value: source })
    },
    [formSet, formattedCode, name]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(debounce(commit, 200), [commit])

  const textAreaCallback = useCallback(
    node => {
      if (!node) return null

      const cm = initCM({
        node,
        cellType,
        callBack: afterInit,
        theme: token.textEditorTheme,
      })

      setCodeMirror(cm)
    },
    [afterInit, cellType, token]
  )

  useEffect(() => {
    codeMirror && codeMirror.setOption("mode", cellTypeModes[cellType])
  }, [cellType, codeMirror])

  useEffect(() => {
    if (isCollapsed.current !== collapsed && !collapsed) {
      codeMirror && codeMirror.refresh()
    }
    isCollapsed.current = collapsed
  }, [codeMirror, collapsed])

  useEffect(() => {
    /*
     * If we do not have a yjs server in the given env, work without
     * collaborative editing.
     */
    if (codeMirror && !codeMirror.yBound && yDoc) {
      const initYBinding = () => {
        const yNodes = yDoc.get("nodes")

        if (!yNodes || !yNodes.get) return

        const yNode = yNodes.get(nodeId)
        const cells = yNode && yNode.get("cells")
        const yCell = cells && cells.get(cellIdx)

        /*
         * Safety check as we are binding based on index now. If the workflow
         * is still getting updated this will get run again very shortly after
         * it aborts and it will create the binding we want.
         */
        if (yCell && yCell.get("uuid") !== uuid) return

        const src = yCell && yCell.get("source")
        const shouldSync = src && !codeMirror.yBound

        if (shouldSync) {
          new CodemirrorBinding(src, codeMirror, wsProvider.awareness)

          codeMirror.yBound = true
        }
      }

      initYBinding()
    }
  })

  // Remove CodeMirror when the component unmounts.
  useEffect(() => {
    if (!codeMirror) return

    const ta = codeMirror.getWrapperElement()
    return () => {
      ta && ta.parentNode && ta.parentNode.removeChild(ta)
    }
  }, [codeMirror])

  useEffect(() => {
    const cm = codeMirror
    if (!cm) return

    const focusHandler = doc => onFocus(doc)
    /*
     * Due to the desire to use collaboration and have multiple users typing
     * in varius CodeMirror instances we can not use the default CodeMirror
     * scroll handling as this triggers off of any text changes (including
     * ones that were remotely triggered). So instead we are going to trigger
     * scrolling on keystroke manually. Unfortunately, disabling the default
     * scrollHandler breaks autoscrolling on selection, thus the need for the
     * selectionHandler function.
     */
    const selectionHandler = (doc, updates) => {
      // `origin` will be undefined on page load and `*mouse` if we are
      // dragging something.
      if (updates.origin === "*mouse") {
        const head = updates.ranges[0].head
        const targetLine = doc.display.lineDiv.children[head.line]
        const targetLineNumber =
          targetLine && targetLine.querySelector(".CodeMirror-linenumber")

        targetLineNumber && targetLineNumber.scrollIntoViewIfNeeded()
      }
    }

    const keydownHandler = (doc, evt) => {
      const cd = doc.display.cursorDiv.querySelector(".CodeMirror-cursor")
      cd && cd.scrollIntoViewIfNeeded(true)
      evt.doc = doc
    }

    const scrollHandler = (_, evt) => {
      evt.preventDefault()
    }

    cm.on("change", onChange)
    cm.on("focus", focusHandler)
    cm.on("keydown", keydownHandler)
    cm.on("scrollCursorIntoView", scrollHandler)
    cm.on("beforeSelectionChange", selectionHandler)

    return () => {
      // Must cleanup handlers if and when they change.
      cm.off("change", onChange)
      cm.off("focus", focusHandler)
      cm.off("keydown", keydownHandler)
      cm.off("beforeSelectionChange", selectionHandler)
      cm.off("scrollCursorIntoView", scrollHandler)
    }
  }, [codeMirror, onChange, onFocus])

  useEffect(() => {
    const cm = codeMirror
    if (!cm) return

    cm.setOption("extraKeys", {
      ...extraKeys,
      "Shift-Enter": commit,
    })
  }, [codeMirror, commit, extraKeys])

  useEffect(() => {
    const cm = codeMirror
    if (!cm) return

    const shouldFocus = isFocused && !cm.hasFocus()
    const shouldBlur = !isFocused && cm.hasFocus()

    shouldFocus && cm.focus()
    shouldBlur && cm.getInputField().blur()
  }, [codeMirror, isFocused])

  if (error) return <ErrorMessage error={error} />

  const fontSize = fontSizes[userPreferences.fontSize || "medium"]

  return (
    <Wrapper className={className} fontSize={fontSize}>
      <TextArea
        form={formMeta.name}
        name={name}
        ref={textAreaCallback}
        value={formattedCode}
        noLabel
      />
    </Wrapper>
  )
}

Editor.propTypes = {
  name: PropTypes.string.isRequired,
  isFocused: PropTypes.bool,
  extraKeys: PropTypes.objectOf(PropTypes.func),
  onFocus: PropTypes.func,
  className: PropTypes.string,
}
export default Editor
