import React, { useContext } from "react"
import { useQuery } from "@apollo/client"
import { Card } from "antd"
import { Link, Redirect } from "react-router-dom"

import Input from "../Form/Input"
import SoloCard from "../SoloCard"
import ApiForm from "../Form/ApiForm"
import { SIGNIN } from "../../queries/auth"
import { GET_ME } from "../../queries/users"
import useCurrentUser from "../../hooks/useCurrentUser"
import { RedirectContext } from "../RedirectContextProvider"

const afterSubmit =
  setCurrentUser =>
  ({ data }) => {
    if (data) {
      setCurrentUser(data.signIn)
    }
  }

const PathAwareRedirect = () => {
  const { redirectTarget } = useContext(RedirectContext)

  if (redirectTarget) {
    return <Redirect to={redirectTarget} />
  }

  // If no target has been explicitly set, use default redirect logic.
  return <Redirect to="/" />
}

const Login = () => {
  const [user, setCurrentUser] = useCurrentUser()
  const { loading } = useQuery(GET_ME)

  if (!loading && user) {
    return <PathAwareRedirect />
  }

  return (
    <SoloCard>
      <Card title="SIGN IN">
        <ApiForm
          mutation={SIGNIN}
          submitText="SIGN IN"
          afterSubmit={afterSubmit(setCurrentUser)}
        >
          <Input name="email" />
          <Input name="password" type="password" />
        </ApiForm>
      </Card>
      <Link to={`/forgot_password`}>Forgot your password?</Link>
    </SoloCard>
  )
}

export default Login
