import React from "react"
import { Button } from "antd"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"

const StyledButton = styled(Button)`
  &&& {
    svg {
      margin-right: 0;
    }

    color: #fff;
    background: #ff4d4f;
    border-color: #ff4d4f;
    :hover {
      background: #ff7875;
      border-color: #ff7875;
    }
  }
`

const RemoveButton = props => {
  const { onRemove, ...rest } = props
  return (
    <StyledButton
      danger
      type="primary"
      onClick={onRemove}
      icon={<FontAwesomeIcon icon={faTrashAlt} />}
      {...rest}
    />
  )
}

export default RemoveButton
