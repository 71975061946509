import { processScalarFieldOptions } from "../../lib/generateSchemaHelpers"

const generateSchema = options => {
  const { showSlider, metadata = {}, ...rest } = options
  if (!showSlider) return processScalarFieldOptions(options)
  return processScalarFieldOptions({
    ...rest,
    metadata: {
      ...metadata,
      component: "SliderInput",
    },
  })
}

export default generateSchema
