import React, { useRef, useMemo, useCallback, useEffect, useState } from "react"
import { debounce } from "lodash"
import { Editor } from "@tinymce/tinymce-react"
import { useDispatch, useSelector } from "react-redux"

import { useWidgetContext } from "../../hooks"
import { actions } from "../../reducers/appReducer"
import TextContainer from "../../JSONSchemaForm/fields/TextContainer"
import JSONSchemaForm from "../../JSONSchemaForm"
import { getWidgetFormId } from "../../lib/widgetEditor"
import { registerAppVariables, registerConditionalText } from "./plugins"
import { defaultInitOptions, DBAI_NODE_CLASSNAME } from "../../lib/tinyMce"
import FormFieldWrapper from "../../JSONSchemaForm/FormFieldWrapper"
import { selectEditingWidgetId, selectAppVariables } from "../../selectors/app"
import {
  useConditionalText,
  useAppVariableValues,
  useConditionalFormatting,
} from "./hooks"
import {
  handleRemoveNode,
  preventBreakingNode,
  handleNodeSelection,
  updateVariableValues,
} from "./utils"

const TinyMCEEditor = React.memo(props => {
  const { onChange, onMount, widgetId, value: formValue } = props
  const editorRef = useRef()
  const saveInFlight = useRef(false)
  const initialValue = useRef(formValue || "")
  const formValueRef = useRef(formValue)
  const { appConfig } = useWidgetContext()
  const editingWidgetId = useSelector(selectEditingWidgetId)
  const [value, setValue] = useState(formValue)
  const [editor, setEditor] = useState(null)
  const [isActive, setIsActive] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const save = useCallback(
    debounce(v => {
      onChange(v)
      saveInFlight.current = false
    }, 200),
    [onChange]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEditorChange = useCallback(
    value => {
      // Prevent saving if the value hasn't changed. This can happen if the value is changed externally
      if (value === formValueRef.current) return
      saveInFlight.current = true
      setValue(value)
      save(value)
    },
    [save]
  )

  useEffect(() => {
    formValueRef.current = formValue
  }, [formValue])

  useEffect(() => {
    // update the form value if it changes externally
    if (!saveInFlight.current && formValue !== value) {
      setValue(formValue)
    }
  }, [formValue, value])

  const appVariables = useSelector(selectAppVariables)
  const appVariablesRef = useRef(appVariables)

  useEffect(() => {
    appVariablesRef.current = appVariables
  }, [appVariables])

  useEffect(() => {
    if (editingWidgetId === widgetId && !isActive) {
      setIsActive(true)
      setTimeout(() => {
        if (editor && editor.selection) {
          editor.selection.select(editor.getBody(), true)
          editor.selection.collapse(false)
          editor.selection.scrollIntoView()
          editor.focus()
        }
      }, 200)
    }
    if (editingWidgetId !== widgetId) {
      setIsActive(false)
    }
  }, [editingWidgetId, widgetId, editor, isActive, setIsActive])

  const addAppVariableValues = useCallback(
    doc => {
      updateVariableValues(doc, appVariables)
    },
    [appVariables]
  )

  const editorInit = useMemo(() => {
    return {
      ...defaultInitOptions,
      setup: editor => {
        registerAppVariables(editor, appVariablesRef, addAppVariableValues)
        registerConditionalText(editor, appVariablesRef)

        setEditor(editor)

        editor.on(
          "NodeChange",
          handleNodeSelection(editor, DBAI_NODE_CLASSNAME)
        )
        editor.on("keydown", preventBreakingNode(editor, DBAI_NODE_CLASSNAME))
        editor.on("keydown", handleRemoveNode(editor, DBAI_NODE_CLASSNAME))
      },
      init_instance_callback: () => {
        onMount && onMount()
      },
    }
  }, [addAppVariableValues, onMount])

  if (!appConfig?.tinyMCEApiKey) return null
  return (
    <Editor
      inline
      id={widgetId}
      ref={editorRef}
      init={editorInit}
      value={value}
      apiKey={appConfig.tinyMCEApiKey}
      disabled={!isActive}
      initialValue={initialValue.current}
      onEditorChange={handleEditorChange}
    />
  )
})

const RichTextEditor = React.memo(props => {
  const { widget, mounted, widgetId, ...rest } = props
  const dispatch = useDispatch()
  const formId = getWidgetFormId(widgetId)
  const { widgetSchema } = useWidgetContext()
  const editingWidgetId = useSelector(selectEditingWidgetId)

  const handleFormChange = useCallback(
    ({ options, id }) => {
      return dispatch(
        actions.setWidgetOptionsWithSync({ value: options, widgetId: id })
      )
    },
    [dispatch]
  )

  const openEditor = useCallback(
    e => {
      if (editingWidgetId !== widgetId) {
        dispatch(actions.editWidget({ widgetId }))
      }
    },
    [dispatch, widgetId, editingWidgetId]
  )

  return (
    <TextArea
      editable={true}
      widget={widget}
      mounted={mounted}
      onClick={openEditor}
      vPosition={widget.options.vPosition}
    >
      <JSONSchemaForm.Provider
        value={widget}
        formId={formId}
        schema={widgetSchema}
        onFormChange={handleFormChange}
      >
        <FormFieldWrapper noStyle schemaKey="text" path="./options">
          <TinyMCEEditor {...rest} widgetId={widgetId} />
        </FormFieldWrapper>
      </JSONSchemaForm.Provider>
    </TextArea>
  )
})

const TextArea = React.memo(props => {
  const { widget, onClick, editable, children, vPosition, mounted } = props
  const editorRef = useRef()

  useAppVariableValues(editorRef, mounted)
  useConditionalText(editorRef, widget, mounted)
  useConditionalFormatting(editorRef, widget, mounted)

  return (
    <TextContainer
      ref={editorRef}
      onClick={onClick}
      editable={editable}
      vPosition={vPosition}
    >
      {children}
    </TextContainer>
  )
})

const NonEditableText = React.memo(props => {
  const { widget, onMount } = props
  useEffect(() => {
    onMount()
  }, [onMount])

  return <div dangerouslySetInnerHTML={{ __html: widget.options.text }} />
})

const RichText = props => {
  const { widget, registerOnResize, widgetId } = props
  const { editable } = useWidgetContext()
  const [layoutHeight, setLayoutHeight] = useState()
  const [mounted, setMounted] = useState(false)

  const onResize = useCallback(rect => {
    setLayoutHeight(rect.height)
  }, [])

  useEffect(() => {
    registerOnResize(() => onResize)
  }, [registerOnResize, onResize])

  const onMount = useCallback(() => {
    setMounted(true)
  }, [])

  if (editable) {
    return (
      <RichTextEditor
        widget={widget}
        mounted={mounted}
        onMount={onMount}
        widgetId={widgetId}
        layoutHeight={layoutHeight}
      />
    )
  }

  return (
    <TextArea
      widget={widget}
      editable={false}
      mounted={mounted}
      vPosition={widget.options.vPosition}
    >
      <NonEditableText widget={widget} onMount={onMount} />
    </TextArea>
  )
}

export default React.memo(RichText)
