import React from "react"
import { useSelector } from "react-redux"

import { selectDisableMoveCursor } from "selectors"

const DisableMoveCursor = () => {
  const disableMoveCursorEnabled = useSelector(selectDisableMoveCursor)

  const text = disableMoveCursorEnabled ? "Disable cursor move on execute" : "Enable cursor move on execute"

  return <>{text}</>
}

export default DisableMoveCursor 
