import { curry } from "lodash"
import {
  findColumnConfig,
  findLabelConfig,
} from "../../../../lib/datasetColumns"
import {
  getXAxisTitle,
  persistTooltip,
  filterOnPointClick,
  getAxisLabelOptions,
  getCustomSeriesOptions,
} from "../utils"

const getXAxis = (dataset, options) => {
  const { groupByData, xAxis = {} } = options || {}
  if (!groupByData?.length) return options.xAxis
  const groupedColumn = groupByData[0]
  const datasetColumn = findColumnConfig(
    groupedColumn,
    dataset.meta.datasetColumns
  )
  const labels = getAxisLabelOptions(options.xAxis, datasetColumn)
  const title = getXAxisTitle(options, datasetColumn)
  return {
    ...xAxis,
    title,
    labels,
  }
}

const constructBoxplotSeries = curry(
  (dataset, options, selectPoints, crossFilters) => {
    const { yAxisData, groupByData, highchart = {} } = options || {}
    if (!yAxisData?.length || !dataset.stats) {
      return { ...options, highchart: { ...highchart, series: [] } }
    }

    const categories = []
    const seriesData = []
    const { formatOptions } = findColumnConfig(
      groupByData[0] || {},
      dataset.meta.datasetColumns
    )
    const { labels } = formatOptions || {}
    const { column } = yAxisData[0]

    dataset.stats.fiveNumberSummary.forEach(summary => {
      const label = summary.group
      const labelConfig = findLabelConfig(label, labels)
      const category = labelConfig
        ? labelConfig.title || labelConfig.label
        : label
      categories.push(category)
      seriesData.push([
        summary.min,
        summary.lowerQuartile,
        summary.median,
        summary.upperQuartile,
        summary.max,
      ])
    })

    const custom = getCustomSeriesOptions(
      {
        columns: dataset.meta.datasetColumns,
        label: {
          group: groupByData,
        },
      },
      yAxisData[0],
      groupByData[0]
    )

    const xAxis = getXAxis(dataset, options)
    return {
      ...options,
      highchart: {
        ...highchart,
        xAxis: {
          ...xAxis,
          categories,
        },
        series: [
          {
            custom,
            yAxis: 0,
            name: column,
            type: "boxplot",
            data: seriesData,
            point: {
              events: {
                // This function is triggered when a point is clicked.
                // 'this' refers to the point that was clicked.
                click: filterOnPointClick(
                  "category",
                  selectPoints,
                  crossFilters
                ),
                mouseOver: persistTooltip(crossFilters),
              },
            },
          },
        ],
      },
    }
  }
)

export default constructBoxplotSeries
