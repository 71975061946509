import React, { useEffect, useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const SpinnerContainer = styled.div`
  margin: ${props => props.margin || "0 auto"};
  width: ${props => props.width || "100%"};
`

const GridSpinner = props => (
  <svg
    style={{ width: "100%" }}
    width={props.width}
    height={props.height}
    viewBox="0 0 105 105"
    fill={props.color}
    aria-label={props.label}
  >
    <circle cx="12.5" cy="12.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="0s"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="12.5" cy="52.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="100ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="52.5" cy="12.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="300ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="52.5" cy="52.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="600ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="92.5" cy="12.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="800ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="92.5" cy="52.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="400ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="12.5" cy="92.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="700ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="52.5" cy="92.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="500ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
    <circle cx="92.5" cy="92.5" r="12.5">
      <animate
        attributeName="fill-opacity"
        begin="200ms"
        dur="1s"
        values="1;.2;1"
        calcMode="linear"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
)

GridSpinner.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  color: PropTypes.string,
  label: PropTypes.string,
}

GridSpinner.defaultProps = {
  height: 80,
  width: 80,
  color: "green",
  label: "loading",
}

// Use the PageSpinner when you want to display a loading spinner for the
// entire page. Use Spinner when you just when you're loading a small part of
// the page.
const Spinner = props => {
  const [minTimeMet, setMinTimeMet] = useState(false)
  useEffect(() => {
    const timer = setTimeout(() => {
      setMinTimeMet(true)
    }, 500)

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [setMinTimeMet])

  return minTimeMet || props.ignoreMinTime ? (
    <SpinnerContainer {...props.spinnerContainerProps}>
      <GridSpinner {...props.gridSpinnerProps} />
    </SpinnerContainer>
  ) : null
}

export default Spinner
