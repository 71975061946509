import { actions } from "../../reducers/appReducer"
import { observeYjsMap } from "./utils"

const themeObserver = (yDoc, dispatch) => {
  const specMap = yDoc.getMap("spec")
  const themeMap = specMap.get("theme")
  observeYjsMap({
    yMap: themeMap,
    onExternalChange: ({ yMap }) => {
      dispatch(actions.setAppTheme(yMap.toJSON()))
    },
  })
}

export default themeObserver
