import { breakpointCols } from "../../lib/layout"

// onResize restricts layout items from exceeding their max limits
export const onResize = (layout, oldLayoutItem, layoutItem, placeholder) => {
  if (layoutItem.w >= layoutItem.maxW) {
    layoutItem.w = layoutItem.maxW
    placeholder.w = layoutItem.maxW
  }
  if (layoutItem.h >= layoutItem.maxH) {
    layoutItem.h = layoutItem.maxH
    placeholder.h = layoutItem.maxH
  }
}

export const convertToGridY = (rowHeight, margin) => {
  return pixelY => {
    return Math.floor(pixelY / (rowHeight + margin))
  }
}

export const convertToGridX = (gridWidth, cols, margin) => {
  return pixelX => {
    const columnWidth = (gridWidth - margin * (cols - 1)) / cols
    return Math.floor(pixelX / (columnWidth + margin))
  }
}

export const getPageCols = breakpointConfigs => {
  if (!breakpointConfigs) {
    return breakpointCols
  }
  return Object.entries(breakpointConfigs).reduce((acc, [key, value]) => {
    acc[key] = value.cols
    return acc
  }, {})
}
