import React from "react"
import { Row, Col, Table } from "antd"

import { stringSorter, numberSorter, dateSorter } from "@dbai/tool-box"

import DateFormatter from "./formatters/DateFormatter"

const getDefaultColumns = includeID => {
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: stringSorter("name"),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, row) => {
        return <DateFormatter value={row.createdAt} />
      },
      type: "date",
      sorter: dateSorter("createdAt"),
    },
    {
      title: "Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (_, row) => {
        return <DateFormatter value={row.updatedAt} />
      },
      type: "date",
      sorter: dateSorter("updatedAt"),
    },
  ]

  if (includeID) {
    columns.unshift({
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: numberSorter("id"),
    })
  }

  return columns
}

const DBTable = props => {
  const {
    dataSource,
    columns = getDefaultColumns(props.includeID || false),
    actions,
    extraColumns,
    extraColumnsPosition = 1,
    pagination = false,
    rowKey = "id",
    size = "middle",
    emptyText = "No Records Found",
    title,
    extra,
    ...childProps
  } = props

  if (extraColumns) {
    columns.splice(extraColumnsPosition, 0, ...extraColumns)
  }

  if (actions) {
    const ActionsComp = actions
    const actionsColumn = {
      title: "",
      key: "actions",
      width: 1,
      render: (_, row) => {
        return <ActionsComp row={row} {...props} />
      },
    }
    columns.push(actionsColumn)
  }

  return (
    <Table
      dataSource={dataSource}
      pagination={pagination}
      rowKey={rowKey}
      columns={columns}
      size={size}
      locale={{ emptyText: emptyText }}
      bordered
      title={
        title || extra
          ? () => (
              <Row justify="space-between" align="middle">
                <Col flex={1}>
                  <h5 style={{ marginBottom: 0 }}>{title}</h5>
                </Col>
                <Col>{extra}</Col>
              </Row>
            )
          : undefined
      }
      {...childProps}
    />
  )
}

export default DBTable
export { getDefaultColumns }
