import { tableSchemaDefinitions } from "./tableSchema"
import formatOptionsSchema from "./formatOptionsSchema"
import formFieldDefinitons from "../JSONSchemaForm/schemaDictionary"

const dictionary = {
  nonNegativeInteger: {
    type: "integer",
    minimum: 0,
  },
  nonNegativeIntegerDefault0: {
    $ref: "#/$defs/nonNegativeInteger",
    default: 0,
  },
  simpleTypes: {
    enum: ["array", "boolean", "integer", "null", "number", "object", "string"],
  },
  stringArray: {
    type: "array",
    items: { type: "string" },
    uniqueItems: true,
    default: [],
  },
  dataColumn: {
    title: "Column",
    type: "string",
    metadata: {
      validateFirst: true,
      component: "ColumnSelect",
      fieldProps: {
        popupMatchSelectWidth: false,
      },
    },
  },
  dataColumnType: {
    title: "Type",
    type: "string",
    enum: ["categorical", "datetime", "string", "numerical", "float", "int"],
    metadata: { hidden: true },
  },
  dataColumnTypeCategoricalOnly: {
    title: "Type",
    type: "string",
    enum: ["categorical", "string"],
    metadata: {
      hidden: true,
    },
  },
  aggregate: {
    type: ["string", "null"],
    title: "Aggregate",
    enum: ["avg", "sum", "min", "max", "count"],
    nullable: true,
    metadata: {
      validateFirst: true,
      labels: ["Average", "Sum", "Minimum", "Maximum", "Count"],
      fieldProps: {
        placeholder: "(All)",
        popupMatchSelectWidth: false,
      },
    },
  },
  conjunction: {
    title: "Conjunction",
    type: "string",
    default: "AND",
    enum: ["OR", "AND"],
    metadata: {
      fieldProps: {
        placeholder: "AND",
        popupMatchSelectWidth: false,
      },
    },
  },
  string: {
    title: "Value",
    type: "string",
    metadata: {
      fieldProps: {
        placeholder: "Enter value",
      },
    },
  },
  scalarValue: {
    title: "Value",
    type: ["string", "number", "integer", "boolean"],
    metadata: {
      fieldProps: {
        placeholder: "Enter value",
      },
    },
  },
  datetimeOperators: {
    title: "Operator",
    type: "string",
    default: "=",
    enum: ["=", "!=", ">", "<", ">=", "<="],
    metadata: {
      fieldProps: {
        placeholder: "Select value",
        popupMatchSelectWidth: false,
      },
      labels: [
        "is equal to",
        "is not equal to",
        "is before",
        "is after",
        "is before or equal to",
        "is after or equal to",
      ],
    },
  },
  scalarOperators: {
    title: "Operator",
    type: "string",
    default: "=",
    enum: ["=", "!=", "<", ">", "<=", ">="],
    metadata: {
      fieldProps: {
        placeholder: "Select value",
        popupMatchSelectWidth: false,
      },
      labels: [
        "is equal to",
        "is not equal to",
        "is less than",
        "is greater than",
        "is less than or equal to",
        "is greater than or equal to",
      ],
    },
  },
  stringOperators: {
    title: "Operator",
    type: "string",
    default: "=",
    enum: ["=", "!="],
    metadata: {
      labels: ["is in", "is not in"],
      fieldProps: {
        placeholder: "Select value",
        popupMatchSelectWidth: false,
      },
    },
  },
  querySelect: {
    title: "SQL Select statements",
    type: "array",
    items: {
      type: "object",
      title: "SQL Selection",
      properties: {
        column: { $ref: "#/$defs/dataColumn" },
        type: { $ref: "#/$defs/dataColumnType" },
        aggregate: { $ref: "#/$defs/aggregate" },
        arguments: { $ref: "#/$defs/stringArray" },
      },
    },
  },
  queryWhere: {
    title: "Filters",
    type: "array",
    items: {
      type: "object",
      required: ["column", "type"],
      properties: {
        column: { $ref: "#/$defs/dataColumn" },
        type: { $ref: "#/$defs/dataColumnType" },
        op: { $ref: "#/$defs/scalarOperators" },
        conj: { $ref: "#/$defs/conjunction" },
        value: { $ref: "#/$defs/scalarValue" },
        conditions: {
          type: "array",
          items: {
            type: "object",
            required: ["column", "type"],
            properties: {
              column: { $ref: "#/$defs/dataColumn" },
              type: { $ref: "#/$defs/dataColumnType" },
              op: { $ref: "#/$defs/scalarOperators" },
              conj: { $ref: "#/$defs/conjunction" },
              value: { $ref: "#/$defs/scalarValue" },
              conditions: {
                $ref: "#/$defs/queryWhere",
                type: ["array", "null"],
              },
            },
          },
        },
      },
    },
  },
  queryGroupBy: {
    title: "SQL Group By Statements",
    type: "array",
    items: {
      type: "object",
      required: ["column"],
      properties: {
        column: {
          $ref: "#/$defs/dataColumn",
          title: "Group By",
          metadata: {
            // allowedTypes: ["string", "categorical"],
            component: "ColumnSelect",
            hideLabel: true,
            fieldProps: {
              popupMatchSelectWidth: false,
            },
          },
        },
        type: { $ref: "#/$defs/dataColumnType" },
      },
    },
  },
  queryGroupByTime: {
    title: "Group By Time",
    type: ["object", "null"],
    if: {
      not: {
        properties: {
          column: { enum: ["null"] },
        },
      },
    },
    then: {
      required: ["interval", "column"],
    },
    metadata: {
      hideLabel: true,
      sections: {
        groupByTime: {
          title: "Group By Time",
          properties: ["column", "interval", "unit", "startDate"],
        },
      },
    },
    properties: {
      column: { $ref: "#/$defs/dataColumn" },
      interval: {
        title: "Interval",
        type: "number",
        default: 1,
        minimum: 1,
      },
      unit: {
        title: "Unit",
        type: "string",
        default: "DAY",
        enum: ["SECOND", "MINUTE", "HOUR", "DAY", "WEEK", "MONTH", "YEAR"],
      },
      startDate: {
        title: "Start Date",
        type: ["string", "null"],
      },
    },
  },
  datasetId: {
    type: "number",
    title: "Dataset",
    metadata: {
      validateFirst: true,
      component: "Dataset",
      fieldProps: {
        placeholder: "Select dataset",
        popupMatchSelectWidth: false,
      },
    },
  },
  datasetAndColumnSelect: {
    default: {},
    type: "object",
    title: "Dataset Column",
    dependencies: {
      type: {
        allOf: [
          {
            if: {
              properties: {
                type: {
                  enum: ["numerical", "float", "int"],
                },
              },
            },
            then: {
              dependencies: formatOptionsSchema.dependencies,
              properties: {
                aggregate: {
                  $ref: "#/$defs/aggregate",
                  metadata: {
                    layout: { span: 12 },
                    validateFirst: true,
                    labels: [
                      "Average",
                      "Sum",
                      "Minimum",
                      "Maximum",
                      "Count",
                      "None",
                    ],
                    fieldProps: {
                      placeholder: "(All)",
                      popupMatchSelectWidth: false,
                    },
                  },
                },
                format: {
                  type: "string",
                  title: "Format",
                  default: "",
                  enum: ["", "number", "string", "currency", "percent"],
                  metadata: {
                    layout: { span: 24 },
                    labels: [
                      "Default",
                      "Number",
                      "Text",
                      "Currency",
                      "Percent",
                    ],
                    fieldprops: {
                      placeholder: "select a format",
                    },
                  },
                },
              },
            },
          },
          {
            if: {
              properties: {
                type: {
                  enum: ["string", "categorical"],
                },
              },
            },
            then: {
              properties: {
                format: {
                  type: "string",
                  default: "string",
                  metadata: {
                    hidden: true,
                  },
                },
              },
            },
          },
        ],
      },
    },
    properties: {
      column: { $ref: "#/$defs/dataColumn" },
      type: { $ref: "#/$defs/dataColumnType" },
      datasetId: { $ref: "#/$defs/datasetId" },
    },
    metadata: { component: "DatasetAndColumnSelect" },
  },
  pollInterval: {
    type: ["number", "null"],
    title: "Refresh Interval (minutes)",
    minimum: 1,
    nullable: true,
    description:
      "Determines how often the page automatically refreshes to show new data.",
    metadata: {
      fieldProps: {
        step: 1,
        precision: 2,
        placeholder: "Refresh interval",
      },
      formatter: {
        format: "number",
        suffix: "min",
      },
    },
  },
  base64ImageUpload: {
    default: {},
    type: "object",
    title: "Upload Image",
    metadata: {
      component: "Base64ImageUpload",
    },
    properties: {
      lastModified: {
        type: "number",
        title: "Last Modified",
        metadata: {
          hidden: true,
        },
      },
      name: {
        type: "string",
        title: "File Name",
        metadata: {
          hidden: true,
        },
      },
      type: {
        type: "string",
        title: "File Type",
        metadata: {
          hidden: true,
        },
      },
      size: {
        type: "number",
        title: "File Size",
        metadata: {
          hidden: true,
        },
      },
      url: {
        type: "string",
        title: "File URL",
        metadata: {
          hidden: true,
        },
      },
    },
  },
  computation: {
    title: "Computation",
    type: "array",
    minItems: 2,
    maxItems: 10,
    default: [],
    metadata: {
      hideLabel: true,
      itemType: "collapse",
      validateFirst: true,
      addButtonText: "ADD VALUE",
      addButtonProps: { block: true },
      fieldProps: {
        style: {
          minHeight: "100px",
        },
      },
    },
    items: {
      title: "Computation",
      type: "object",
      required: ["propertyType", "op"],
      metadata: {
        itemType: "collapse",
        sectionProps: {
          collapsible: false,
        },
      },
      dependencies: {
        propertyType: {
          allOf: [
            {
              if: {
                properties: {
                  propertyType: {
                    enum: ["datasetColumn"],
                  },
                },
              },
              then: {
                required: ["column"],
                properties: {
                  type: {
                    title: "Type",
                    type: "string",
                    enum: ["numerical", "float", "int"],
                    metadata: { hidden: true },
                  },
                  column: {
                    title: "Column",
                    type: "string",
                    metadata: {
                      validateFirst: true,
                      component: "ColumnSelect",
                      allowedTypes: ["numerical", "float", "int"],
                      fieldProps: {
                        popupMatchSelectWidth: false,
                      },
                    },
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  propertyType: {
                    enum: ["staticValue"],
                  },
                },
              },
              then: {
                required: ["value"],
                properties: {
                  value: {
                    title: "Value",
                    default: 0,
                    type: "number",
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  propertyType: {
                    enum: ["subExpression"],
                  },
                },
              },
              then: {
                properties: {
                  computation: { $ref: "#/$defs/computation" },
                },
              },
            },
          ],
          properties: {
            op: {
              title: "Operation",
              type: "string",
              default: "+",
              enum: ["+", "-", "*", "/", "%"],
              metadata: {
                hidden: {
                  itemIndex: 0,
                },
              },
            },
          },
        },
      },
      properties: {
        propertyType: {
          type: "string",
          title: "Property Type",
          enum: ["datasetColumn", "staticValue", "subExpression"],
          metadata: {
            labels: ["Data Value", "Static Value", "Expression"],
          },
        },
      },
    },
  },
  ...tableSchemaDefinitions,
  ...formFieldDefinitons,
}

export default dictionary
