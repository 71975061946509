import React, { useEffect, useState, useCallback } from "react"
import { Panel, PanelGroup } from "react-resizable-panels"
import styled from "styled-components"

import {
  Widget,
  TreeView,
  SchemaForm,
  PanelTitle,
  PanelResizeHandle,
} from "@dbai/applet"

import { widgetParametersSchema } from "./widgetTypeSchema"

const ScrollableDiv = styled.div`
  height: calc(100vh - 214px);
  overflow: auto;
`

const EditWidgetParameters = props => {
  const { widget, setWidget, cname } = props
  const [selected, setSelected] = useState()
  const [widgetTypeFormWidget, setWidgetTypeFormWidget] = useState({
    type: "FormWidget",
    options: {
      properties: widget?.schema?.properties,
    },
  })

  useEffect(() => {
    setWidgetTypeFormWidget({
      type: "FormWidget",
      options: {
        properties: widget?.schema?.properties,
      },
    })
  }, [widget?.schema?.properties])

  const handleFormChange = useCallback(
    (data, ...rest) => {
      setWidgetTypeFormWidget({ type: "FormWidget", options: data })
      setWidget(widget => ({ ...widget, schema: data }))
    },
    [setWidget]
  )

  return (
    <SchemaForm.Provider
      hideSubmit
      schema={widgetParametersSchema}
      onFormChange={handleFormChange}
      initialState={widget.schema}
    >
      <PanelGroup direction="horizontal" key="controls">
        <Panel minSize={10} defaultSize={25} collapsible>
          <ScrollableDiv style={{ height: "calc(100vh - 161px)" }}>
            <TreeView path="" schemaKey="." onSelect={setSelected} />
          </ScrollableDiv>
        </Panel>
        <PanelResizeHandle />
        <Panel minSize={10} collapsible>
          <TreeView.Form
            selectedTreeNode={selected}
            containerStyles={{ height: "calc(100vh - 214px)", padding: "16px" }}
          />
        </Panel>
        <PanelResizeHandle />
        <Panel minSize={10} defaultSize={25}>
          <PanelTitle level={3}>Form Preview</PanelTitle>
          {widget?.schema?.properties?.length ? (
            <Widget.Core
              hideSubmit
              cname={cname}
              widget={widgetTypeFormWidget}
            />
          ) : (
            <div>No parameters configured.</div>
          )}
        </Panel>
      </PanelGroup>
    </SchemaForm.Provider>
  )
}

export default EditWidgetParameters
