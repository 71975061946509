import React, { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Row, Col, Collapse, List, Tooltip, Typography } from "antd"

import { useWidgetContext } from "../hooks"
import { actions } from "../reducers/appReducer"
import { selectRootPageId } from "../selectors/app"

const { Text } = Typography

const ListContainer = props => {
  const { widgetRegistry, dataSource } = props
  const pageId = useSelector(selectRootPageId)
  const dispatch = useDispatch()
  const { widgetSchema } = useWidgetContext()
  const handleAddWidget = (name, label) => () => {
    const widget = {
      type: name,
      name: label,
      layout: widgetRegistry[name].initialLayout,
    }

    const allowFullEditor = widgetRegistry[name].allowFullEditor

    dispatch(
      actions.spawnNewWidget({
        pageId,
        widget,
        widgetSchema,
        editWidget: true,
        allowFullEditor,
      })
    )
  }

  return (
    <List
      style={{ width: "100%" }}
      itemLayout="horizontal"
      dataSource={dataSource}
      renderItem={(item, index) => (
        <Tooltip title="Drag or Double Click to Add" placement="left">
          <List.Item
            style={{ cursor: "grab" }}
            wrap={false}
            draggable={true}
            data-testid={`widget-list-item-${item.name}`}
            unselectable="on"
            onDoubleClick={handleAddWidget(item.name, item.label)}
            // this is a hack for firefox
            // Firefox requires some kind of initialization
            // which we can do by adding this attribute
            // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
            onDragStart={e => {
              e.dataTransfer.setData(
                "text/plain",
                JSON.stringify({ name: item.name })
              )
            }}
            onDragEnd={e => {
              // Prevent default drag-and-drop behavior outside the application
              e.preventDefault()
            }}
          >
            <Row style={{ width: "100%" }} gutter={[16]}>
              <Col>
                {item.icon ? (
                  <FontAwesomeIcon icon={item.icon} size="lg" />
                ) : null}
              </Col>
              <Col flex={1}>{item.label}</Col>
              <Col flex={2}>
                <Text>{item.description}</Text>
              </Col>
            </Row>
          </List.Item>
        </Tooltip>
      )}
    />
  )
}

const getWidgetsByGroup = widgetRegistry => {
  return Object.entries(widgetRegistry).reduce(
    (
      acc,
      [
        name,
        { label, container, custom, icon, disallowNew = false, description },
      ]
    ) => {
      if (disallowNew) return acc

      switch (true) {
        case container:
          return {
            ...acc,
            containers: [...acc.containers, { label, icon, name, description }],
          }
        case custom:
          return {
            ...acc,
            customWidgets: [
              ...acc.customWidgets,
              { label, icon, name, description },
            ],
          }
        default:
          return {
            ...acc,
            coreWidgets: [
              ...acc.coreWidgets,
              { label, icon, name, description },
            ],
          }
      }
    },
    { containers: [], coreWidgets: [], customWidgets: [] }
  )
}

const DraggableWidgetList = props => {
  const { widgetRegistry } = props
  const widgetsByGroup = useMemo(
    () => getWidgetsByGroup(widgetRegistry),
    [widgetRegistry]
  )

  const collapseItems = useMemo(() => {
    const { containers, coreWidgets, customWidgets } = widgetsByGroup
    const items = [
      {
        key: "core-widgets",
        label: "Core Widgets",
        children: (
          <ListContainer
            dataSource={coreWidgets}
            widgetRegistry={widgetRegistry}
          />
        ),
      },
      {
        key: "containers",
        label: "Containers",
        children: (
          <ListContainer
            dataSource={containers}
            widgetRegistry={widgetRegistry}
          />
        ),
      },
    ]
    if (customWidgets.length) {
      items.push({
        key: "custom-widgets",
        label: "Custom Widgets",
        children: (
          <ListContainer
            dataSource={customWidgets}
            widgetRegistry={widgetRegistry}
          />
        ),
      })
    }
    return items
  }, [widgetsByGroup, widgetRegistry])

  return (
    <Collapse
      bordered={false}
      items={collapseItems}
      style={{ width: "100%" }}
      defaultActiveKey={["containers", "core-widgets"]}
    />
  )
}

export default DraggableWidgetList
