import React from "react"
import { curry } from "lodash"
import { Input, Button, Space } from "antd"
import { faSearch } from "@fortawesome/pro-solid-svg-icons"

import { FAMemo } from "@dbai/ui-staples"

const FilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) => (
  <div style={{ padding: 8, width: "15rem" }}>
    <Input
      placeholder={`Search value`}
      value={selectedKeys[0]}
      onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={confirm}
      style={{ marginBottom: 8, display: "block" }}
    />
    <Space align="right">
      <Button
        type="primary"
        onClick={confirm}
        icon={<FAMemo icon={faSearch} />}
        size="small"
        style={{ width: 90 }}
      >
        Search
      </Button>
      <Button
        onClick={() => {
          clearFilters()
          confirm()
        }}
        size="small"
        style={{ width: 90 }}
      >
        Reset
      </Button>
    </Space>
  </div>
)

const getOnCell = (column, columnsAreAggregated) => {
  return {
    onCell: (record, index) => ({
      record,
      column,
      columnsAreAggregated,
    }),
  }
}

const getHeaderCellProps = index => column => {
  return {
    ...column,
    index,
    editable: true,
    dataIndex: column.dataIndex,
  }
}

const getSafeTitle = columnConfig => {
  switch (true) {
    case Boolean(columnConfig.title):
      return columnConfig.title
    case Boolean(columnConfig.label):
      return columnConfig.label
    case Boolean(columnConfig.name):
      return columnConfig.name
    case Boolean(columnConfig.column):
      return columnConfig.column
    case Boolean(columnConfig.computation?.length):
      return "Computed Column"
    default:
      return "Unnamed Column"
  }
}

const getColumnSort = column => {
  const { sortPriority, sortable, dataIndex } = column
  if (!sortable) return {}
  return {
    sorter: {
      compare: (a, b) => a[dataIndex] - b[dataIndex],
      multiple: sortPriority || 1,
    },
  }
}

const handleFilter = curry((dataIndex, search, row) => {
  const cell = row[dataIndex]
  if (row.children) {
    return (
      row.children.some(child => filterRow(child, search)) ||
      filterRow(cell, search)
    )
  }
  return filterRow(cell, search)
})

const FilterIcon = filtered => (
  <FAMemo icon={faSearch} style={{ color: filtered ? "#1890ff" : undefined }} />
)

const getColumnSearch = column => {
  if (!column.searchable) return {}
  return {
    filterIcon: FilterIcon,
    filterDropdown: FilterDropdown,
    onFilter: handleFilter(column.dataIndex),
  }
}

const filterRow = (cell, search) => {
  const check = v => {
    return v ? v.toString().toLowerCase().includes(search.toLowerCase()) : false
  }

  // If obtained cell is an object, assume the objects value is at "value"
  if (typeof cell === "object") {
    if (!cell?.value) return false
    return check(cell.value)
  }

  return check(cell)
}

export {
  getColumnSearch,
  getColumnSort,
  getHeaderCellProps,
  getSafeTitle,
  getOnCell,
}
