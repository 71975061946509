import React, { useEffect, useCallback } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import { useFormActions, useFormState } from "@dbai/ui-staples"

import { useDefaultPatchEffect, useWidgetDispatch } from "./hooks"
import {
  onlyType,
  onlyComm,
} from "components/pages/Workflows/Edit/shared/kernel"

// nodePrefix returns the path for the attribute named `attr` on the node at
// index `idx`. Suitable for use with _.get.
const nodePrefix = (idx, attr) => `spec.nodes[${idx}].${attr}`

const Model = props => {
  const { commId, widget, kernel } = props
  const dispatch = useWidgetDispatch()
  const { formSet } = useFormActions()
  const nodes = useFormState("workflow.spec.nodes")

  const { nodeId } = widget
  const nodeIdx = nodes.findIndex(n => n.id === nodeId)

  const applyPatch = useCallback(
    (patch, idx) => {
      // iterates all keys in `patch` and updates corresponding
      // attributes on the node at index `idx`.
      Object.keys(patch)
        .filter(key => key[0] !== "_")
        .forEach(key => {
          const name = nodePrefix(idx, key)
          const value = patch[key]

          console.log(`applying patch for ${name}, to`, value)
          formSet({ name, value })
        })
    },
    [formSet]
  )

  useDefaultPatchEffect(kernel)
  useEffect(() => {
    const [iopub] = kernel.channel("iopub")
    const sub = iopub
      .filter(onlyType("comm_msg"))
      .filter(onlyComm(commId))
      .subscribe(({ content }) => {
        applyPatch(content.data.state, nodeIdx)
      })

    return sub.unsubscribe
  }, [kernel, applyPatch, commId, formSet, nodeIdx])

  // When the model is mounted update the form with the state of the
  // Entrypoint.
  useEffect(() => {
    dispatch((_, getWidgetState) => {
      const state = getWidgetState()
      applyPatch(state.widgets[commId].data.state, nodeIdx)
    })
  }, [applyPatch, nodeIdx, dispatch, commId])

  return null
}

const View = props => {
  const {
    node,
    setSelected = () => console.warn("setSelected has been removed"),
  } = props
  const handleClick = e => {
    e.preventDefault()
    setSelected && setSelected(node)
  }

  return (
    <button
      type="button"
      className="btn btn-outline-primary"
      onClick={handleClick}
    >
      Open Inputs and Artifacts <FontAwesomeIcon icon={faArrowRight} />
    </button>
  )
}

export { Model, View }
