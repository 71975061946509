import { useContext, useCallback } from "react"
import { useDispatch } from "react-redux"

import { getValueFromEvent } from "../utils"
import SchemaFormContext from "../SchemaFormContext"
import { actions } from "../../reducers/formsReducer"

const useFormContext = () => {
  return useContext(SchemaFormContext)
}

const useFormFieldActions = name => {
  const dispatch = useDispatch()
  const { afterFormChange, showErrors, formId: id } = useFormContext()

  /** Each time the subsequent field value changes, we update the form state here.
   * Once the form state is updated in redux, we then call the onFormChange callback
   * to then use the new form state to update the resolved schema and issue any further
   * form change callback sent from the context wrapping the form.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChange = useCallback(
    (event, eventType, fromIndex, toIndex) => {
      const value = getValueFromEvent(event)
      dispatch(
        actions.onFormChange({
          id,
          name,
          value,
          toIndex,
          fromIndex,
          validate: showErrors,
          eventType: typeof eventType === "string" ? eventType : "change",
          afterFormChange: afterFormChange,
        })
      )
    },
    [dispatch, showErrors, afterFormChange, id, name]
  )

  /** Each time the subsequent field value changes, we update the form state here.
   * Once the form state is updated in redux, we then call the onFormChange callback
   * to then use the new form state to update the resolved schema and issue any further
   * form change callback sent from the context wrapping the form.
   */
  const onAppend = useCallback(
    event => {
      const value = getValueFromEvent(event)
      dispatch(
        actions.onFormAppend({
          id,
          name,
          value,
          validate: showErrors,
          eventType: "append",
          afterFormChange: afterFormChange,
        })
      )
    },
    [dispatch, showErrors, afterFormChange, id, name]
  )

  const onRemove = useCallback(
    index => {
      dispatch(
        actions.onFormRemove({
          id,
          name,
          index,
          validate: showErrors,
          eventType: "remove",
          afterFormChange: afterFormChange,
        })
      )
    },
    [dispatch, showErrors, afterFormChange, id, name]
  )

  return {
    onRemove,
    onChange,
    onAppend,
  }
}

export default useFormFieldActions
