const navItemsSchema = {
  type: "array",
  minItems: 1,
  items: {
    type: "object",
    required: ["title"],
    properties: {
      pageId: {
        title: "Page ID",
        type: "number",
        metadata: {
          hidden: true,
          defaultValueSource: "uid",
        },
      },
      title: {
        title: "Title",
        type: "string",
      },
    },
  },
}

export default navItemsSchema
