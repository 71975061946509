import React from "react"
import styled from "styled-components"
import { Row, Col, Button } from "antd"

import RemoveButton from "./RemoveButton"
import { useFormContext } from "../hooks"
import FormFieldWrapper from "../FormFieldWrapper"

const StyledCol = styled(Col)`
  height: 100%;
  align-self: center;
`

const StyledButton = styled(Button)`
  margin-bottom: 10px;
`

const defaultValueMapping = {
  string: "",
  number: 0,
  object: {},
  array: [],
}

const getNonNullType = schema => {
  return schema.type.filter(t => t !== "null")[0]
}

const NullableFieldCore = props => {
  const { value, schema, onChange, path } = props
  const { title } = schema
  const { getFormField } = useFormContext()

  const removeField = e => {
    e.preventDefault()
    e.stopPropagation()
    onChange(null)
  }

  if (![null, undefined].includes(value)) {
    const type = getNonNullType(schema)
    const Component = getFormField({ ...schema, type }, path)
    if (!Component?.Core) return null
    return (
      <Row wrap={false}>
        <Col flex={1}>
          <Component.Core {...props} />
        </Col>
        <StyledCol>
          <RemoveButton onRemove={removeField} />
        </StyledCol>
      </Row>
    )
  }

  const addField = e => {
    e.preventDefault()
    e.stopPropagation()
    const type = getNonNullType(schema)
    onChange(defaultValueMapping[type])
  }

  return (
    <Row wrap={false}>
      <Col flex={1}>
        <StyledButton type="primary" onClick={addField}>
          Add {title ?? "Property"}
        </StyledButton>
      </Col>
    </Row>
  )
}

const NullableField = props => {
  const { schema, path } = props
  const { getFormField } = useFormContext()

  const type = getNonNullType(schema)
  if (schema.enum?.length > 0 || ["string", "number"].includes(type)) {
    const Component = getFormField({ ...schema, type }, path)
    return <Component {...props} />
  }

  return (
    <FormFieldWrapper {...props}>
      <NullableFieldCore />
    </FormFieldWrapper>
  )
}

export default NullableField
