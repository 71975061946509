import React, { useMemo, useEffect, useCallback } from "react"
import dayjs from "dayjs"
import { Col, Table, Button } from "antd"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { stringSorter } from "@dbai/tool-box"
import { Card, Content, ErrorMessage, AuthBoundary } from "@dbai/ui-staples"

import EditAction from "./EditAction"
import { GET_REVISIONS } from "queries"
import CurrentCheckbox from "./CurrentCheckbox"
import { actions } from "reducers/actionReducer"
import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"
import { selectAction, selectLoadedRevision } from "selectors"

const LoadButton = props => {
  const { value } = props
  const loadedRevision = useSelector(selectLoadedRevision)
  const dispatch = useDispatch()
  const handleClick = useCallback(() => {
    dispatch(actions.loadRevision(value))
  }, [dispatch, value])

  if (loadedRevision === value) {
    return <Button type="disabled">LOADED</Button>
  }

  return (
    <Button type="primary" onClick={handleClick}>
      LOAD
    </Button>
  )
}

const RevisionsTable = props => {
  const { cname, actionId } = props
  const variables = { cname, actionId }
  const { error, loading, data } = useQuery(GET_REVISIONS, {
    fetchPolicy: "network-only",
    variables,
  })

  const action = data?.customer?.action || {}
  const currentRevisionId = action.currentRevision?.id || null
  const revisions = useMemo(
    () => action.actionRevisions || [],
    [action.actionRevisions]
  )
  const tableData = useMemo(() => {
    return revisions.map(rev => ({ ...rev, loadKey: `load-${rev.id}` }))
  }, [revisions])

  if (error) return <ErrorMessage error={error} />

  return (
    <AuthBoundary subject="actions" action="LIST">
      <div id="actions">
        <Card title="Revision History" nopad>
          <Table size="small" dataSource={tableData} pagination={false}>
            <Table.Column
              dataIndex="id"
              title="Current"
              render={id => (
                <CurrentCheckbox
                  value={id}
                  loading={loading}
                  currentRevisionId={currentRevisionId}
                />
              )}
            />
            <Table.Column
              dataIndex="id"
              title="ID"
              render={id => <span>{id}</span>}
            />
            <Table.Column
              dataIndex="createdAt"
              title="Created At"
              defaultSortOrder="desc"
              sorter={stringSorter("createdAt")}
              render={createdAt =>
                dayjs(createdAt).format("MM/DD/YYYY h:mm:ss a")
              }
            />
            <Table.Column
              dataIndex="loadKey"
              title=""
              render={(_, rev) => <LoadButton value={rev.id} />}
            />
          </Table>
        </Card>
      </div>
    </AuthBoundary>
  )
}

const Edit = props => {
  const dispatch = useDispatch()
  const { customer } = props
  const { id: actionId } = useParams()
  const { id, name } = useSelector(selectAction)
  const loadRevision = () => {
    dispatch(
      actions.loadAction({ cname: customer.normalizedName, id: actionId })
    )
  }

  useEffect(loadRevision, [dispatch, actionId, customer.normalizedName])

  const closePanel = () => dispatch(actions.reset())

  const workflowsRoute = `/${customer.normalizedName}/workflows`
  const libraryRoute = `${workflowsRoute}/library`

  const routes = [
    { breadcrumbName: customer.name },
    { breadcrumbName: "Workflows", url: workflowsRoute },
    { breadcrumbName: "Library", url: libraryRoute },
    { breadcrumbName: `${name}`, url: libraryRoute },
  ]

  return (
    <>
      <PageHeader routes={routes} />
      <Content>
        <Col span={16}>
          <Card title="New Revision">
            <EditAction cname={customer.normalizedName} actionId={actionId} />
          </Card>
        </Col>
        <Col span={8}>
          <RevisionsTable
            cname={customer.normalizedName}
            customerId={customer.id}
            showNew={id}
            onNewClose={closePanel}
            actionId={actionId}
          />
        </Col>
      </Content>
    </>
  )
}

export default withCustomer(Edit)
