import { gql } from "@apollo/client"

const COMPONENTS_FRAGMENT = gql`
  fragment ComponentFragment on Component {
    id
    description
    name
    isEndpoint
    tags
    published
    createdAt
    updatedAt
    currentRevision {
      componentId
      createdAt
      description
      id
      spec {
        type
        color
        icon
        cells {
          source
          metadata
          cellType
        }
        arguments {
          name
          type
        }
        artifacts {
          title
          path
        }
      }
    }
  }
`

const REVISIONS_FRAGMENT = gql`
  fragment ComponentRevisionsFragment on Component {
    componentRevisions {
      id
      createdAt
      componentId
      spec {
        arguments {
          type
          name
        }
        artifacts {
          id
          path
          title
          type
        }
        cells {
          source
          uuid
          outputs
          executionCount
          metadata
          cellType
        }
        type
        color
        icon
      }
    }
    currentRevision {
      componentId
      createdAt
      description
      id
      spec {
        type
        color
        icon
        cells {
          cellType
          executionCount
          metadata
          outputs
          source
          uuid
        }
        artifacts {
          id
          path
          title
          type
        }
        arguments {
          name
          type
        }
      }
    }
  }
`

export const GET_COMPONENTS = gql`
  ${COMPONENTS_FRAGMENT}
  query Components($cname: String!) {
    customer(name: $cname) {
      id
      components {
        ...ComponentFragment
      }
    }
  }
`

export const GET_PUBLISHED_COMPONENTS = gql`
  ${COMPONENTS_FRAGMENT}
  query {
    componentPublications {
      id
      component {
        ...ComponentFragment
      }
    }
  }
`

export const DELETE_COMPONENT = gql`
  mutation DeleteComponent($customerId: Int!, $id: Int!) {
    deleteComponent(customerId: $customerId, id: $id) {
      id
    }
  }
`

export const CREATE_COMPONENT = gql`
  mutation CreateComponent(
    $cid: Int!
    $input: ComponentInput!
    $componentRevisionInput: ComponentRevisionInput
  ) {
    createComponent(
      customerId: $cid
      input: $input
      componentRevisionInput: $componentRevisionInput
    ) {
      id
      description
      name
      isEndpoint
      createdAt
      updatedAt
      currentRevision {
        id
        componentId
        createdAt
        description
        spec {
          type
          color
          icon
          cells {
            cellType
            executionCount
            metadata
            outputs
            source
            uuid
          }
          artifacts {
            id
            path
            title
            type
          }
          arguments {
            name
            type
          }
        }
      }
    }
  }
`

export const UPDATE_COMPONENT = gql`
  ${REVISIONS_FRAGMENT}
  mutation UpdateComponent(
    $cname: String!
    $componentId: Int!
    $revision: ComponentRevisionInput!
    $component: ComponentInput!
  ) {
    updateComponent(
      cname: $cname
      input: $component
      componentId: $componentId
      componentRevisionInput: $revision
    ) {
      ...ComponentRevisionsFragment
      description
      name
      id
    }
  }
`

export const SET_COMPONENT_CURRENT_REVISION = gql`
  ${REVISIONS_FRAGMENT}
  mutation SetCurrentRevision(
    $cname: String!
    $componentId: Int!
    $input: ComponentInput!
  ) {
    updateComponent(cname: $cname, componentId: $componentId, input: $input) {
      ...ComponentRevisionsFragment
      isEndpoint
      description
      name
      id
    }
  }
`

export const GET_COMPONENT = gql`
  ${REVISIONS_FRAGMENT}
  query GetComponent($cname: String!, $id: Int!) {
    customer(name: $cname) {
      component(id: $id) {
        ...ComponentRevisionsFragment
        id
        name
        updatedAt
        description
        isEndpoint
      }
    }
  }
`

export const GET_COMPONENT_REVISIONS = gql`
  ${REVISIONS_FRAGMENT}
  query getRevisions($cname: String!, $componentId: Int!) {
    customer(name: $cname) {
      component(id: $componentId) {
        ...ComponentRevisionsFragment
        isEndpoint
        name
        id
      }
    }
  }
`

export const PUBLISH_COMPONENT = gql`
  mutation PublishComponent($componentId: Int!, $customerId: Int!) {
    publishComponent(componentId: $componentId, customerId: $customerId) {
      id
    }
  }
`

export const DELETE_COMPONENT_PUBLICATION = gql`
  mutation DeleteComponent($componentId: Int!, $customerId: Int!) {
    deleteComponentPublication(
      componentId: $componentId
      customerId: $customerId
    )
  }
`
