import React, { useMemo, useCallback, useState } from "react"
import styled from "styled-components"
import { Popover } from "antd"
import { useSelector } from "react-redux"
import { useAction } from "hooks"
import { actions } from "reducers/componentReducer"
import { FAMemo } from "@dbai/ui-staples"
import { faCube } from "@fortawesome/pro-solid-svg-icons"
import { selectComponentColor, selectComponentIcon } from "selectors"
import { nodeIconMap } from "components/pages/Workflows/shared/IconPicker"
import ComponentColorPicker from "./ComponentForm/ComponentColorPicker"
import ComponentIconPicker from "./ComponentForm/ComponentIconPicker.js"

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  float: left;
  width: 45px;
  border-radius: 5px;
  height: 45px;
  background-color: ${props => props.color};
  color: white;
`

const ComponentIcon = props => {
  const { color, icon } = props
  return (
    <StyledIcon color={color || "#aaa"}>
      <FAMemo icon={nodeIconMap[icon] || faCube} size="lg" />
    </StyledIcon>
  )
}

const ColorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

const ColorPickerWrapper = styled.div``

const ColorPicker = props => {
  const color = useSelector(selectComponentColor)
  const icon = useSelector(selectComponentIcon)
  const hidePopover = useCallback(() => setPopoverOpen(false), [])
  const [popoverOpen, setPopoverOpen] = useState(false)
  const handlePopoverOpenChange = useCallback(newOpen => {
    setPopoverOpen(newOpen)
  }, [])
  const handleIconChange = useAction(actions.setIcon)
  const handleColorChange = useAction(actions.setColor)
  const PopoverContent = useMemo(
    props => {
      return (
        <ColorPickerWrapper>
          <ComponentColorPicker
            size={"35px"}
            onChange={handleColorChange}
            color={color}
            afterChange={hidePopover}
          />
          <ComponentIconPicker
            size={"35px"}
            onChange={handleIconChange}
            icon={icon}
            afterChange={hidePopover}
          />
        </ColorPickerWrapper>
      )
    },
    [color, icon, handleColorChange, handleIconChange, hidePopover]
  )
  return (
    <ColorWrapper>
      <Popover
        content={PopoverContent}
        trigger="click"
        open={popoverOpen}
        onOpenChange={handlePopoverOpenChange}
      >
        <ColorPickerWrapper>
          <ComponentIcon color={color} icon={icon} />
        </ColorPickerWrapper>
      </Popover>
    </ColorWrapper>
  )
}

export default ColorPicker
