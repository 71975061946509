import React, { useState } from "react"
import { theme, Card, Button, Spin, Input, Form, Tooltip } from "antd"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import {
  faComment,
  faPlay,
  faLink,
  faArchive,
  faExpandAlt,
  faCompressAlt,
  faTerminal,
} from "@fortawesome/free-solid-svg-icons"
import { faSquareList } from "@fortawesome/pro-solid-svg-icons"
import { FAMemo } from "@dbai/ui-staples"
import { alphanumid } from "@dbai/tool-box"

import SaveActionPane from "./SaveActionPane"
import UpdateActionPane from "./UpdateActionPane"
import { actions } from "reducers/notebookReducer"
import DBBoundary from "components/shared/DBBoundary"
import {
  ACTION_ID_LABEL,
  COMPONENT_ID_LABEL,
} from "reducers/notebookReducer/thunks"
import { useExecCell } from "components/pages/Workflows/Edit/shared/util"

//REMOVE - HACK
const initNewCell = () => ({
  uuid: alphanumid(),
  cellType: "code",
  metadata: {
    muted: false,
    showCode: true,
    showOutput: true,
  },
  source: [
    "import pandas as pd\n",
    "\n",
    "def drop_column(df, name):\n",
    "  df.drop(name, axis=1, inplace=True)",
  ],
})

const CollapseExpand = props => {
  const { node, nodeIdx } = props
  const dispatch = useDispatch()
  const { collapsed } = node
  const icon = collapsed ? faExpandAlt : faCompressAlt
  const text = collapsed ? "EXPAND NODE" : "COLLAPSE NODE"
  const toggleNodeCollapsed = () => {
    dispatch(
      actions.updateNodeField({
        nodeIdx,
        field: "collapsed",
        value: !node.collapsed,
      })
    )
  }

  return (
    <Tooltip title={text} placement="bottom">
      <Button
        type="text"
        icon={<FAMemo icon={icon} />}
        onClick={toggleNodeCollapsed}
      />
    </Tooltip>
  )
}

const FormCodeComponent = props => {
  const { nodeIdx, node } = props
  const dispatch = useDispatch()
  const [showCode, setShowCode] = useState(node.metadata.showCode)

  const icon = showCode ? faSquareList : faTerminal
  const tooltip = showCode ? "SHOW FORM" : "SHOW CODE"

  const toggleNodeFormComponent = () => {
    setShowCode(!showCode)
    dispatch(
      actions.updateNodeField({
        nodeIdx,
        field: "metadata.showCode",
        value: !showCode,
      })
    )
  }

  return (
    <Tooltip placement="bottom" title={tooltip}>
      <Button
        type="text"
        onClick={() => toggleNodeFormComponent()}
        icon={<FAMemo icon={icon} />}
      />
    </Tooltip>
  )
}

const ChatWrapper = styled(Card)`
  position: absolute;
  top: 45px;
  right: 0;
  width: 500px;
  height: 500px;
  z-index: 100;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`

const ChatContent = styled.div`
  padding: 10px;
  height: 400px;
`
const ChatInput = styled.div`
  height: 100px;
  padding: 5px 10px;
`

const { useToken } = theme

const ChatMessage = props => {
  const { message } = props
  const { text, type } = message
  const isBot = type === "bot"
  const { token } = useToken()
  const messageStyle = {
    textAlign: isBot ? "left" : "right",
    backgroundColor: isBot ? token.colorInfo : token.colorBgLayout,
    padding: "5px 10px",
    borderRadius: "5px",
    marginBottom: "5px",
  }

  return (
    <div style={messageStyle}>
      <span>{text}</span>
    </div>
  )
}

const ChatBox = props => {
  const [form] = Form.useForm()
  const { chatVisible, handleChatAction } = props
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState([
    { text: "Hi, how can I help you?", type: "bot" },
  ])

  if (!chatVisible) return null

  const handleSubmit = ({ userPrompt }) => {
    const userMessage = { text: userPrompt, type: "user" }
    setMessages([...messages, userMessage])
    form.resetFields()

    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setMessages([
        ...messages,
        userMessage,
        { text: "I generated some code for you.", type: "bot" },
      ])
      handleChatAction()
    }, 5000)
    //Chat GTP Response message appears
    //Code is inserted in codemirror
  }

  return (
    <ChatWrapper title="AI ASSISTANT">
      <ChatContent>
        {messages.map((message, idx) => (
          <ChatMessage key={idx} message={message} />
        ))}
        {loading && <Spin />}
      </ChatContent>
      <ChatInput>
        <Form onFinish={handleSubmit} form={form}>
          <Form.Item name="userPrompt">
            <Input />
          </Form.Item>
        </Form>
      </ChatInput>
    </ChatWrapper>
  )
}

const ActionButton = props => {
  const { node, togglePane, isActionNode } = props

  return (
    <>
      {node.type === "script" ? (
        <>
          {isActionNode ? (
            <Tooltip placement="bottom" title="UPDATE ACTION">
              <Button
                type="text"
                onClick={togglePane}
                icon={<FAMemo icon={faLink} />}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title="CREATE ACTION">
              <Button
                type="text"
                onClick={togglePane}
                icon={<FAMemo icon={faArchive} />}
              />
            </Tooltip>
          )}
        </>
      ) : null}
    </>
  )
}

const ActionMenu = React.memo(props => {
  const { node, prefix, nodeIdx } = props
  const execCell = useExecCell()
  const dispatch = useDispatch()
  const [isPaneOpen, setIsPaneOpen] = useState(false)
  const togglePane = () => setIsPaneOpen(!isPaneOpen)
  const handleClosePane = () => setIsPaneOpen(false)
  const isActionNode = node.metadata && Boolean(node.metadata[ACTION_ID_LABEL])
  const isComponentNode =
    node.metadata && Boolean(node.metadata[COMPONENT_ID_LABEL])
  const [chatVisible, setChatVisible] = useState(false)

  const handleChatAction = () => {
    const newCell = initNewCell()
    const newCells = [...node.cells, newCell]
    const cellName = `workflow.spec.nodes[${nodeIdx}].cells`
    dispatch(actions.set({ name: cellName, value: newCells }))
  }

  const runNode = () => {
    node.cells.forEach((cell, idx) => {
      execCell({
        docValue: cell.source.join(""),
        path: prefix(`cells[${idx}]`),
        cellId: cell.uuid,
        cellType: cell.cellType,
        handleAutosave: false,
        nodeId: node.id,
      })
    })
  }

  const toggleChat = () => {
    setChatVisible(!chatVisible)
  }

  return (
    <>
      <Button.Group>
        {isComponentNode ? (
          <FormCodeComponent node={node} nodeIdx={nodeIdx} />
        ) : null}
        <Tooltip placement="bottom" title="RUN ALL CELLS">
          <Button
            type="text"
            onClick={runNode}
            icon={<FAMemo icon={faPlay} />}
          />
        </Tooltip>
        <DBBoundary>
          <Tooltip placement="bottom" title="AI ASSISTANT">
            <Button
              type="text"
              onClick={toggleChat}
              icon={<FAMemo icon={faComment} />}
            />
          </Tooltip>
        </DBBoundary>
        <ActionButton
          node={node}
          togglePane={togglePane}
          isActionNode={isActionNode}
        />
        <CollapseExpand node={node} nodeIdx={nodeIdx} />
      </Button.Group>
      {isActionNode && isPaneOpen ? (
        <UpdateActionPane
          node={node}
          visible={isPaneOpen}
          onClose={handleClosePane}
        />
      ) : (
        <SaveActionPane
          nodeId={node.id}
          visible={isPaneOpen}
          onClose={handleClosePane}
        />
      )}
      <ChatBox chatVisible={chatVisible} handleChatAction={handleChatAction} />
    </>
  )
})

export default ActionMenu
