import React from "react"
import { Route } from "react-router-dom"

import ErrorBoundary from "./ErrorBoundary"

const BoundaryRoute = props => {
  const { path, initLogger, children } = props
  return (
    <Route path={path}>
      <ErrorBoundary initLogger={initLogger}>{children}</ErrorBoundary>
    </Route>
  )
}

export default BoundaryRoute
