import { useSelector } from "react-redux"

import { getTheme } from "../lib/theme"
import { selectAppSpec } from "../selectors/app"

const useTheme = () => {
  const { theme } = useSelector(selectAppSpec)
  return getTheme(theme)
}

export default useTheme
