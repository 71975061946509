import { useCallback, useEffect, useState } from "react"
import config from "config"
import { getJwt } from "lib/jwt"

const allowedMethods = ["get", "post", "put", "delete", "patch"]

export const useSwaggerDoc = (cname, path) => {
  const [spec, setSpec] = useState({})
  const swaggerURL = `${config.api}endpoints/${cname}/openapi.json`
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [method, setMethod] = useState("GET")
  const [parameters, setParameters] = useState([])
  const [requestBody, setRequestBody] = useState({})
  const [responses, setResponses] = useState({})
  const [schema, setSchema] = useState({})

  const getSchemaName = useCallback(requestBody => {
    const schemaPath = requestBody.content["application/json"].schema["$ref"]
    const schemaName = schemaPath.split("/")
    return schemaName.pop()
  }, [])

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${getJwt()}`,
    }
    fetch(swaggerURL, {
      headers,
    })
      .then(res => {
        return res.json()
      })
      .then(res => {
        setSpec(res)

        const pathItem = res.paths[path]
        allowedMethods.forEach(method => {
          if (pathItem[method]) {
            setMethod(method)
            setParameters(pathItem[method].parameters)
            setRequestBody(pathItem[method].requestBody)
            setResponses(pathItem[method].responses)
            const schemaName = getSchemaName(pathItem[method].requestBody)
            setSchema(res.components.schemas[schemaName])
          }
        })
        setError(false)
        setLoading(false)
      })
      .catch(_err => {
        setSpec({})
        setError(true)
        setLoading(false)
      })
  }, [swaggerURL, setSpec, path, getSchemaName])

  return {
    spec,
    method,
    parameters,
    requestBody,
    responses,
    schema,
    loading,
    error,
  }
}
