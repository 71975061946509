import React from "react"

import { useCurrentCustomer } from "@dbai/ui-staples"

import NoCustomer from "components/shared/NoCustomer"

// withCustomer is a Higher-Order-Component (HOC) that takes a component and
// wraps it with new component while injecting the current customer.
const withCustomer =
  (Comp, opts = {}) =>
  props => {
    const [customer] = useCurrentCustomer()
    const selectedCustomer = props.customer || customer

    if (selectedCustomer) {
      return <Comp customer={selectedCustomer} {...props} />
    }

    if (opts.noAlternate) return null
    return <NoCustomer />
  }

export default withCustomer
