import React, { useCallback, useContext } from "react"
import { Button } from "antd"
import { useDispatch } from "react-redux"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

import { FAMemo, DeleteButton, useCurrentCustomer } from "@dbai/ui-staples"

import { ScheduleTableContext } from "."
import { actions } from "reducers/schedulesReducer"

const NotificationsButton = props => {
  const { onClick } = props
  return (
    <Button type="primary" onClick={onClick}>
      <FAMemo icon={faEnvelope} />
    </Button>
  )
}

const buttonStyle = { marginRight: "5px" }

const TableRowActions = props => {
  const { row } = props
  const dispatch = useDispatch()
  const [customer] = useCurrentCustomer()
  const { openAlertsPanel } = useContext(ScheduleTableContext)
  const openAlertsForId = useCallback(
    () => openAlertsPanel(row.id),
    [openAlertsPanel, row.id]
  )

  const handleDelete = () => {
    return dispatch(
      actions.deleteSchedule({
        cname: customer.normalizedName,
        id: row.id,
      })
    )
  }

  const refetch = () => {
    dispatch(actions.loadSchedules({ cname: customer.normalizedName }))
  }

  return (
    <div className="btn-group">
      <NotificationsButton onClick={openAlertsForId} style={buttonStyle} />
      <DeleteButton
        onDelete={handleDelete}
        afterDelete={refetch}
        confirmDelete={true}
        style={buttonStyle}
      />
    </div>
  )
}

export default TableRowActions
