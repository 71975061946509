import React from "react"
import { useParams } from "react-router-dom"

import {
  AuthBoundary,
  exportDataset,
  useCurrentCustomer,
} from "@dbai/ui-staples"
import { Applet } from "@dbai/applet"

import config from "config"

const AppletEditor = () => {
  const { id: appId, cname } = useParams()
  const [customer = {}] = useCurrentCustomer()
  return (
    <AuthBoundary subject="apps" action="EDIT">
      <Applet
        editable
        cname={cname}
        appConfig={config}
        appId={parseInt(appId)}
        exportDataset={exportDataset}
        customerId={String(customer?.id)}
      />
    </AuthBoundary>
  )
}

export default AppletEditor
