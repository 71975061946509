import { actions } from "../../reducers/appReducer"
import { addFilterObserver } from "./filterObserver"
import { observeYjsArray, removeArrayItem } from "./utils"

const handleInsert = (dispatch, filterMap) => {
  addFilterObserver(dispatch, filterMap)
  const filter = filterMap.toJSON()
  dispatch(actions.addAppFilter(filter))
}

const handleExternalChange =
  dispatch =>
  ({ yArrayEvent, delta, yArray }) => {
    if (delta.insert) {
      delta.insert.forEach(item => handleInsert(dispatch, item))
    }

    if (delta.delete) {
      yArrayEvent.changes.deleted.forEach(item =>
        removeArrayItem(item, id => dispatch(actions.removeAppFilter({ id })))
      )
    }

    if (delta.move) {
      // reset array in redux to ensure it is in the correct order
      dispatch(actions.setAppFilters(yArray.toJSON()))

      // when an array item is moved, it is removed and reinitialized in the moved to position. because of this, we
      // must add an observer to the reinitialized item
      delta.move.forEach(variableMap => {
        addFilterObserver(dispatch, variableMap)
      })
    }
  }

const handleLocalChange =
  dispatch =>
  ({ delta }) => {
    if (delta.insert) {
      delta.insert.forEach(item => addFilterObserver(dispatch, item))
    }

    if (delta.move) {
      // when an array item is moved, it is removed and reinitialized in the moved to position. because of this, we
      // must add an observer to the reinitialized item
      delta.move.forEach(filterMap => {
        addFilterObserver(dispatch, filterMap)
      })
    }
  }

const filtersObserver = (yDoc, dispatch) => {
  const specMap = yDoc.getMap("spec")
  const filtersArray = specMap.get("filters")

  observeYjsArray({
    yArray: filtersArray,
    onLocalChange: handleLocalChange(dispatch),
    onExternalChange: handleExternalChange(dispatch),
  })
}

export default filtersObserver
