import React, { useMemo, useCallback } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"

import { Select } from "@dbai/ui-staples"

import useCronChangeHandlers from "./useCronChangeHandlers"

const labelWidth = "150px"

export const StyledSelect = styled(Select)`
  float: none;
  ${Select.components.Label} {
    width: ${labelWidth};
  }
`

export const Flex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${StyledSelect} {
    margin-left: 10px;
    margin-bottom: 0;
    display: inline-block;
    width: 300px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  ${Select.components.Label} {
    width: auto;
  }
`

export const FlexLabel = styled.label`
  && {
    flex-grow: 0;
    flex-shrink: 0;
    width: ${labelWidth};
    margin-bottom: 0;
    padding-right: 5px;
  }
`

const RemoveButton = styled.div`
  flex-grow: 0;
  margin-left: 7px;
`

const makeRange = (min, max) => [...Array(max + 1).keys()].slice(min)

const makeOptions = ({ name, range, step, labels = [] }) => {
  const startIdx = !!step && range[0] === 0 ? 1 : 0

  return [
    { label: `Every ${name}`, value: "*" },
    ...range.slice(startIdx).map((v, idx) => ({
      label: labels[idx + startIdx] || String(v),
      value: String(v),
    })),
  ]
}

const makeEndOptions = ({ range, labels = [] }) =>
  range.map((v, idx) => ({
    label: labels[idx] || String(v),
    value: String(v),
  }))

const makeRepeatOptions = range =>
  range.slice(1).map((_, idx) => ({
    label: String(idx + 1),
    value: idx + 1,
  }))

// CronControl to Major Tom.
const CronControl = props => {
  const {
    name,
    label = name,
    idx,
    cronField,
    entriesLength,
    labels = [],
    min = 0,
    max = labels.length - 1,
    value = "",
    stepValue = null,
  } = props

  const filterOption = useCallback(
    ({ label, value }, search) => {
      switch (true) {
        // Hide "Every X" if there's more than one entry.
        // Hide "0" if there's a step.
        case entriesLength > 1 && value === "*":
        case !!stepValue && value === "0":
          return false
        default:
      }

      if (!search) {
        return true
      }

      const trimIdx = label[0] === "0" ? 1 : 0
      const normalized = label.slice(trimIdx).toLowerCase().replace(" ", "")

      return (
        normalized.startsWith(search.replace(" ", "").toLowerCase()) ||
        search === value
      )
    },
    [entriesLength, stepValue]
  )

  const { handleStartChange, handleEndChange, handleStepChange, handleRemove } =
    useCronChangeHandlers(idx, cronField)

  const [startVal, endVal] = value.split("-")
  const endDisabled = startVal === "*"

  const range = useMemo(() => makeRange(min, max), [min, max])

  const options = useMemo(
    () => makeOptions({ name, range, labels, step: stepValue }),
    [name, range, stepValue, labels]
  )
  const endOptions = useMemo(
    () => makeEndOptions({ range, labels }),
    [range, labels]
  )
  const repeatOptions = useMemo(() => makeRepeatOptions(range), [range])

  return (
    <Flex className="form-group">
      <FlexLabel className="form-label">{idx !== 0 ? "" : label}</FlexLabel>
      <StyledSelect
        label="Start"
        name="from"
        onChange={handleStartChange}
        options={options}
        filterOption={filterOption}
        value={startVal}
        reset
      />
      <StyledSelect
        label="End"
        name="to"
        onChange={handleEndChange}
        options={endOptions}
        value={endVal}
        isDisabled={endDisabled}
        isClearable
        reset
      />
      <StyledSelect
        label="Repeat"
        name="every"
        options={repeatOptions}
        onChange={handleStepChange}
        value={stepValue}
        isClearable
        reset
      />
      <RemoveButton className="btn-group">
        <button
          type="button"
          className="btn btn-danger"
          onClick={handleRemove}
          disabled={entriesLength === 1}
        >
          <FontAwesomeIcon icon={faTrash} className="action-icon" />
        </button>
      </RemoveButton>
    </Flex>
  )
}

export default CronControl
