import dayjs from "dayjs"

const timeUnitMap = {
  H: "hour",
  D: "day",
  M: "month",
  Y: "year",
}

const relativeDtRegex = /P(-?\d+)(D|M|Y|H)/
export const deconstructRelativeDt = value => {
  const match = value.match(relativeDtRegex)
  if (!match) return [0, null]
  const quantity = parseInt(match[1], 10)
  const timeUnit = timeUnitMap[match[2]]
  return [quantity, timeUnit]
}

export const computeRelativeDt = value => {
  const [quantity, timeUnit] = deconstructRelativeDt(value)
  if (!timeUnit) return dayjs()
  if (quantity < 0) return dayjs().subtract(Math.abs(quantity), timeUnit)
  return dayjs().add(quantity, timeUnit)
}

export const isRelativeDt = value => value?.startsWith("P")

export const getDateRange = (groupByTime, startDate) => {
  const { unit, interval } = groupByTime

  let end
  const start = dayjs.utc(startDate).startOf(unit).toISOString()

  if (interval === 1) {
    end = dayjs.utc(startDate).endOf(unit).toISOString()
  } else {
    end = dayjs.utc(startDate).add(interval, unit).endOf(unit).toISOString()
  }

  return [start, end]
}
