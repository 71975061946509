import { flow } from "lodash"

const checkEnumOptionsRepeat = enumOpts => {
  const enumOptsSet = new Set(enumOpts)
  return enumOpts && enumOpts.length !== enumOptsSet.size
}

const propsDoNotExist = props => {
  return props.every(attr => [undefined, null].includes(attr))
}

const processEnumOptions = options => {
  const { enumOptions, metadata = {}, ...rest } = options
  if (metadata.optionsSource === "datasetColumn") {
    return {
      ...options,
      metadata: {
        ...metadata,
        component: "LocalSelect",
      },
    }
  }

  if (!enumOptions || checkEnumOptionsRepeat(enumOptions)) {
    return options
  }

  const safeOptions = enumOptions.reduce((final, opt) => {
    if ([null, undefined].includes(opt.value)) return final
    return [...final, opt]
  }, [])

  const labels = safeOptions.map(({ label }) => label)
  const values = safeOptions.map(({ value }) => value)
  const newMetadata = labels.length ? { ...metadata, labels: labels } : metadata

  // enum must have at least one item
  if (!values.length) return options
  return {
    ...rest,
    enum: values,
    metadata: newMetadata,
  }
}

const processMultiSelect = options => {
  const { type, metadata = {}, enum: enumOpts, ...rest } = options
  // if (!isMultiSelect) return options
  if (!metadata.isMultiSelect) return options
  if (!metadata.component && checkEnumOptionsRepeat(enumOpts)) return options
  return {
    ...rest,
    type: "array",
    metadata,
    items: {
      type,
      ...(enumOpts ? { enum: enumOpts } : {}),
    },
  }
}

const processNullable = options => {
  const { nullable, type, ...rest } = options
  return {
    ...rest,
    nullable: !!nullable,
    type: nullable ? [type, "null"] : type,
  }
}

const processDefaultValue = options => {
  const { defaultValue, ...rest } = options
  if (propsDoNotExist([defaultValue])) return options
  return {
    ...rest,
    default: defaultValue,
  }
}

const processSpan = options => {
  const { span, metadata = {}, ...rest } = options
  if (propsDoNotExist([span])) return options
  return {
    ...rest,
    metadata: {
      ...metadata,
      layout: { span },
    },
  }
}

const processMetadata = options => {
  const { metadata = {}, styles = {}, ...rest } = options
  return {
    ...rest,
    metadata: {
      ...metadata,
      fieldProps: {
        ...(metadata?.fieldProps || {}),
        style: styles,
      },
    },
  }
}

const processDatePickerOptions = options => {
  const { metadata = {}, ...restOptions } = options

  const getShowTimeProps = () => {
    if (!metadata.showTime) return { showTime: false }
    const { timeFormat, timeFormatPattern } = metadata
    return {
      showTime: {
        format: timeFormat === "custom" ? timeFormatPattern : timeFormat,
      },
    }
  }

  return {
    ...restOptions,
    type: "string",
    metadata: {
      ...options.metadata,
      component: "DatePicker",
      fieldProps: {
        ...(options.metadata?.fieldProps || {}),
        ...getShowTimeProps(),
      },
    },
  }
}

const generateErrorMessagesForSchema = options => {
  const { title, type } = options

  switch (type) {
    // TODO: add default error messages for other types
    case "array": {
      return {
        minItems: `${title} - Must not have fewer than ${options.minItems} items`,
        maxItems: `${title} - Must not have greater than ${options.maxItems} items`,
        required: `${title} is required`,
      }
    }
    case "string": {
      return {
        minLength: `${title} - Must not have fewer than ${options.minLength} characters`,
        maxLength: `${title} - Must not have greater than ${options.minLength} characters`,
        required: `${title} is required`,
      }
    }
    default: {
      return {
        required: `${title} is required`,
      }
    }
  }
}

const processErrorMessages = options => {
  const { errorMessage } = options
  return {
    ...options,
    metadata: {
      ...options.metadata,
      errorMessage: errorMessage ?? generateErrorMessagesForSchema(options),
    },
  }
}

const processScalarFieldOptions = flow([
  processSpan,
  processNullable,
  processMetadata,
  processDefaultValue,
  processErrorMessages,
])

export {
  processSpan,
  processMetadata,
  processNullable,
  processEnumOptions,
  processMultiSelect,
  processDefaultValue,
  processErrorMessages,
  processDatePickerOptions,
  processScalarFieldOptions,
}
