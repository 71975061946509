import datasetColumnSchema from "./datasetColumnSchema"
import getConditionalFormatSchema from "./shared/getConditionalFormatSchema"

const pivotTableWidgetSchema = {
  default: {},
  type: "object",
  title: "Widget",
  required: ["datasetId"],
  metadata: {
    sideEffects: [
      {
        resetValue: [],
        type: "resetField",
        targetName: "options.where",
        sourceName: "options.datasetId",
      },
      {
        resetValue: [],
        type: "resetField",
        targetName: "options.values",
        sourceName: "options.datasetId",
      },
      {
        resetValue: [],
        type: "resetField",
        targetName: "options.groupBy",
        sourceName: "options.datasetId",
      },
      {
        resetValue: [],
        type: "resetField",
        targetName: "options.rowLabels",
        sourceName: "options.datasetId",
      },
      {
        resetValue: [],
        type: "resetField",
        sourceName: "options.datasetId",
        targetName: "options.columnLabels",
      },
      {
        resetValue: {},
        type: "resetField",
        sourceName: "options.datasetId",
        targetName: "options.groupByTime",
      },
    ],
  },
  properties: {
    datasetId: {
      type: "number",
      title: "Dataset",
      metadata: {
        validateFirst: true,
        component: "Dataset",
        wrapperStyle: {
          padding: "0 16px",
        },
        fieldProps: {
          placeholder: "Select dataset",
          popupMatchSelectWidth: false,
        },
        errorMessage: {
          required: "Dataset is required",
        },
      },
    },
  },
  dependencies: {
    datasetId: {
      metadata: {
        order: [
          "rowLabels",
          "columnLabels",
          "values",
          "datasetId",
          "pollInterval",
          "limit",
          "offset",
          "groupByTime",
          "groupBy",
          "where",
        ],
        noStyle: true,
        sectionType: "tabs",
        sections: {
          data: {
            title: "Data",
            properties: ["inputZones"],
            containerStyle: {
              padding: 0,
              overflowY: "auto",
            },
          },
          column: {
            title: "Column Options",
            properties: ["columnOptions"],
            containerStyle: {
              padding: "16px",
              overflowY: "auto",
              height: "calc(100vh - 185px)",
            },
          },
          table: {
            title: "Table Options",
            properties: ["table"],
            containerStyle: {
              padding: 0,
              overflowY: "auto",
              height: "calc(100vh - 185px)",
            },
          },
          dataOptions: {
            title: "Dataset Options",
            properties: ["datasetId", "dataset", "where"],
          },
        },
      },
      properties: {
        datasetId: {
          metadata: {
            wrapperStyle: {
              padding: "0",
            },
          },
        },
        inputZones: {
          type: "object",
          title: "Data",
          default: {},
          metadata: {
            schemaName: "dependencies.datasetId.properties.inputZones",
            component: "ColumnInputZones",
            fieldProps: {
              style: {
                height: "calc(100vh - 185px)",
              },
            },
          },
          properties: {
            rowLabels: {
              type: "array",
              default: [],
              maxItems: 100,
              title: "Row Labels",
              metadata: {
                validateFirst: true,
                component: "DnDDropzone",
                addText: "Add Dataset Column",
                containerStyle: {
                  padding: "0",
                },
                errorMessage: {
                  minItems: "At least one column is required",
                  maxItems: "Maximum number of columns is 100",
                },
                fieldProps: {
                  style: {
                    minHeight: "100px",
                  },
                },
              },
              items: {
                default: {},
                type: "object",
                metadata: {
                  labelPath: "column",
                },
                dependencies: datasetColumnSchema.dependencies,
                properties: {
                  type: { $ref: "#/$defs/dataColumnType" },
                  column: {
                    $ref: "#/$defs/dataColumn",
                    title: "Column",
                    metadata: {
                      allowedTypes: ["string", "categorical", "datetime"],
                      component: "ColumnSelect",
                      hideLabel: true,
                      fieldProps: {
                        popupMatchSelectWidth: false,
                      },
                    },
                  },
                },
              },
            },
            columnLabels: {
              title: "Column Labels",
              type: "array",
              maxItems: 100,
              default: [],
              metadata: {
                validateFirst: true,
                component: "DnDDropzone",
                addText: "Add Dataset Column",
                allowedTypes: ["string", "categorical", "datetime"],
                containerStyle: {
                  padding: "0",
                },
                defaultAddedFieldData: {
                  columnType: "datasetColumn",
                },
                defaultDroppedFieldValue: {
                  columnType: "datasetColumn",
                },
                errorMessage: {
                  minItems: "At least one column is required",
                  maxItems: "Maximum number of columns is 100",
                },
                fieldProps: {
                  style: {
                    minHeight: "100px",
                  },
                },
              },
              items: {
                type: "object",
                default: {},
                dependencies: datasetColumnSchema.dependencies,
                properties: {
                  type: { $ref: "#/$defs/dataColumnType" },
                  column: {
                    $ref: "#/$defs/dataColumn",
                    title: "Column",
                    metadata: {
                      allowedTypes: ["string", "categorical", "datetime"],
                      component: "ColumnSelect",
                      hideLabel: true,
                      fieldProps: {
                        popupMatchSelectWidth: false,
                      },
                    },
                  },
                },
              },
            },
            values: {
              title: "Values",
              type: "array",
              minItems: 1,
              maxItems: 100,
              default: [],
              metadata: {
                validateFirst: true,
                component: "DnDDropzone",
                addText: "Add Computed Values",
                defaultAddedFieldData: {
                  columnType: "computed",
                },
                defaultDroppedFieldValue: {
                  columnType: "datasetColumn",
                },
                errorMessage: {
                  minItems: "At least one column is required",
                  maxItems: "Maximum number of columns is 100",
                },
                fieldProps: {
                  style: {
                    minHeight: "100px",
                  },
                },
              },
              items: {
                type: "object",
                default: {},
                metadata: {
                  labelPath: "column",
                  layout: { gutter: [16] },
                },
                dependencies: {
                  columnType: {
                    if: {
                      properties: {
                        columnType: {
                          enum: ["computed"],
                        },
                      },
                    },
                    then: {
                      properties: {
                        textAlign: { $ref: "#/$defs/textAlign" },
                        searchable: {
                          title: "Show Search",
                          type: "boolean",
                        },
                        sortable: {
                          title: "Sortable",
                          type: "boolean",
                        },
                        fixed: { $ref: "#/$defs/fixed" },
                        conditionalFormat: getConditionalFormatSchema(),
                        computation: { $ref: "#/$defs/tableComputation" },
                      },
                    },
                    else: datasetColumnSchema,
                  },
                },
                properties: {
                  columnType: {
                    type: "string",
                    enum: ["datasetColumn", "computed"],
                    metadata: {
                      hidden: true,
                    },
                  },
                  title: {
                    $ref: "#/$defs/title",
                    metadata: {
                      placeholderSource: "column",
                      validateFirst: true,
                      fieldProps: {
                        placeholder: "<Current Column Name>",
                      },
                    },
                  },
                  textAlign: { $ref: "#/$defs/textAlign" },
                  conditionalFormat: getConditionalFormatSchema(),
                },
              },
            },
          },
        },
        columnOptions: {
          type: "object",
          default: {},
          title: "Column Options",
          metadata: {
            layout: { gutter: [16] },
            sectionProps: {
              defaultActiveKey: ["general"],
            },
            sections: {
              general: {
                title: "General Options",
                properties: [
                  "fixed",
                  "width",
                  "textAlign",
                  "searchable",
                  "sortable",
                ],
              },
              additionalColumns: {
                title: "Additional Columns",
                properties: [
                  "showSumColumn",
                  "showAvgColumn",
                  "showCountColumn",
                ],
              },
            },
          },
          properties: {
            "columnOptions/fixed": { $ref: "#/$defs/fixed" },
            "columnOptions/width": { $ref: "#/$defs/width" },
            "columnOptions/textAlign": { $ref: "#/$defs/textAlign" },
            "columnOptions/searchable": {
              title: "Show Search",
              type: "boolean",
            },
            "columnOptions/sortable": {
              title: "Sortable",
              type: "boolean",
            },
            "columnOptions/showSumColumn": {
              title: "Show Sum Column",
              type: "boolean",
            },
            "columnOptions/showAvgColumn": {
              title: "Show Average Column",
              type: "boolean",
            },
            "columnOptions/showCountColumn": {
              title: "Show Count Column",
              type: "boolean",
            },
          },
        },
        table: {
          default: {},
          type: "object",
          title: "Table Options",
          metadata: {
            sectionProps: {
              defaultActiveKey: ["general"],
            },
            sections: {
              general: {
                title: "General Options",
                properties: [
                  "table/size",
                  "table/bordered",
                  "table/showHeader",
                  "table/showSorterTooltip",
                ],
              },
              pagination: {
                title: "Pagination Options",
                properties: ["pagination"],
              },
              scroll: {
                title: "Scroll Options",
                properties: ["scroll"],
              },
            },
          },
          properties: {
            "table/size": {
              type: "string",
              title: "Table Size",
              default: "small",
              enum: ["default", "middle", "small"],
              metadata: {
                layout: { span: 12 },
                labels: ["Large", "Middle", "Small"],
                fieldProps: {
                  placeholder: "(auto)",
                },
              },
            },
            "table/bordered": {
              default: false,
              type: "boolean",
              title: "Bordered",
            },
            "table/showHeader": {
              default: true,
              type: "boolean",
              title: "Show Header",
            },
            "table/showSorterTooltip": {
              default: true,
              type: "boolean",
              title: "Show Sorter Tooltip",
            },
            scroll: {
              type: "object",
              default: {},
              title: "Scroll Options",
              metadata: {
                layout: { gutter: [16] },
              },
              properties: {
                "table/scroll/scrollToFirstRowOnChange": {
                  title: "Scroll to First Row on Change",
                  type: "boolean",
                  description:
                    "Whether to scroll to the top of the table when paging, sorting, filtering changes",
                },
                "table/scroll/x": {
                  title: "Horizontal Scrolling Enabled",
                  type: "boolean",
                  default: true,
                  description:
                    "If horizontal scrolling is disabled, the table will try to fit all data into the width of the table",
                },
              },
            },
            pagination: {
              default: {},
              type: "object",
              title: "Pagination",
              properties: {
                "table/pagination/position": {
                  maxItems: 1,
                  type: "array",
                  title: "Position",
                  default: ["bottomCenter"],
                  metadata: {
                    hideAdd: true,
                  },
                  items: {
                    type: "string",
                    enum: [
                      "topLeft",
                      "topCenter",
                      "topRight",
                      "bottomLeft",
                      "bottomCenter",
                      "bottomRight",
                    ],
                    metadata: {
                      labels: [
                        "Top Left",
                        "Top Center",
                        "Top Right",
                        "Bottom Left",
                        "Bottom Center",
                        "Bottom Right",
                      ],
                    },
                  },
                },
                "table/pagination/hideOnSinglePage": {
                  default: false,
                  type: "boolean",
                  title: "Hide on Single Page",
                },
                "table/pagination/defaultPageSize": {
                  default: 10,
                  type: "number",
                  title: "Default Rows Per Page",
                },
                "table/pagination/pageSizeOptions": {
                  type: "array",
                  default: [10, 20, 50, 100],
                  title: "Page Sizes",
                  items: {
                    type: "number",
                  },
                },
                "table/pagination/showSizeChanger": {
                  type: "boolean",
                  title: "Show Size Changer",
                  description:
                    "Determine whether to show page size select, it will be true when total > 50",
                },
              },
            },
          },
        },
        dataset: {
          default: {},
          type: "object",
          title: "Dataset Options",
          properties: {
            pollInterval: { $ref: "#/$defs/pollInterval" },
            format: {
              title: "Format",
              default: "table",
              type: "string",
              enum: ["chart", "table"],
              metadata: {
                hidden: true,
              },
            },
            limit: {
              title: "Row Limit",
              description:
                "Determines the number of rows displayed. If you are using an aggregate query (sum, average, etc), the query will limit the rows AFTER you perform the aggregate. For example, if you have 1,000 rows and have a 100 'Row Limit', the query will still perform the aggregate against 1,000 rows.  The number of rows returned would be 100 or less. Increasing the 'Row Limit' may result in slower load times.",
              type: ["number", "null"],
              default: 1000,
              nullable: true,
            },
            offset: {
              title: "Row Offset",
              description:
                "Determines the number of rows to skip. For example, if your data is ordered by ID, and you have an offset of 100, the first 100 rows will be skipped (rows with IDs 1-100)",
              type: ["number", "null"],
              nullable: true,
            },
          },
        },
        where: {
          $ref: "#/$defs/queryWhere",
          default: [],
          metadata: {
            itemType: "collapse",
            validateFirst: true,
            component: "DnDDropzone",
            fieldProps: {
              style: {
                minHeight: "200px",
              },
            },
          },
        },
      },
    },
  },
}

export default pivotTableWidgetSchema
