import React, { useCallback } from "react"

import Select from "../../shared/Select"
import { getSelectMode } from "../../utils"
import { useFormFieldDropdownOptions } from "../../hooks"
import usePresetOptions from "../../hooks/usePresetOptions"
import { withFormFieldWrapper } from "../../FormFieldWrapper"

const OptionSetSelect = props => {
  const { schema, parentName, ...rest } = props
  const options = useFormFieldDropdownOptions(schema, parentName)
  return <Select {...rest} options={options} />
}

const EnumSelect = props => {
  return <Select {...props} />
}

const ColumnValueSelect = React.memo(props => {
  const { schema, parentSchema, parentName, path, ...rest } = props
  const { metadata } = schema
  const { loading, refetch, options } = usePresetOptions({
    schema,
    parentSchema,
    parentName,
    metadata,
    fetchPolicy: "cache-and-network",
  })

  const handleDropdownVisibleChange = useCallback(
    visible => {
      visible && refetch && refetch()
    },
    [refetch]
  )

  return (
    <Select
      {...rest}
      loading={loading}
      options={options}
      onDropdownVisibleChange={handleDropdownVisibleChange}
    />
  )
})

const LocalSelectCore = React.memo(props => {
  const { schema } = props
  const { nullable, metadata = {} } = schema
  const { listPath, optionsSource } = metadata
  const mode = getSelectMode(schema)

  if (optionsSource === "datasetColumn") {
    return <ColumnValueSelect mode={mode} {...props} />
  }

  if (listPath) {
    return <OptionSetSelect allowClear={nullable} mode={mode} {...props} />
  }

  return <EnumSelect allowClear={nullable} mode={mode} {...props} />
})

const LocalSelect = withFormFieldWrapper(LocalSelectCore)
LocalSelect.Core = LocalSelectCore
export default LocalSelect
