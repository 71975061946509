import Modal from "react-modal"
import styled from "styled-components"

export default styled(Modal)`
  position: absolute;
  height: 20%;
  top: 45%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
`
