import React, { useCallback } from "react"
import { Row, Col } from "antd"

import { SchemaForm } from "@dbai/applet"

import { widgetTypeSchema } from "./widgetTypeSchema"

const EditWidgetConfig = props => {
  const { widget, setWidget } = props
  const handleFormChange = useCallback(
    ({ name, description }) => {
      setWidget(widget => ({ ...widget, name, description }))
    },
    [setWidget]
  )
  return (
    <Row>
      <Col span={12}>
        <SchemaForm
          hideSubmit
          initialState={widget}
          schema={widgetTypeSchema}
          onFormChange={handleFormChange}
        />
      </Col>
    </Row>
  )
}

export default EditWidgetConfig
