import { isArray } from "lodash"

const realValues = values =>
  values.filter(v => ![null, undefined, ""].includes(v))

export const filterOption = (input, option) => {
  const label = `${option?.label ?? option?.value}`
  return label.toLowerCase().includes(input.toLowerCase())
}

export const getMode = (isMulti, allowInput, presetsSource) => {
  // when allowInput is true, we want to allow the user to type in or select a value
  if (allowInput) return "tags"
  // when there are no presets, we want to allow the user to type in or select a value
  if (isMulti && presetsSource === "none") return "tags"
  // when there are presets, we want to allow the user to select from the list
  if (isMulti) return "multiple"
  return undefined
}

export const getSelectedValue = (isMulti, value) => {
  if (isArray(value)) {
    const filteredValues = realValues(value)
    if (isMulti) return filteredValues
    return filteredValues.length > 1 ? filteredValues[1] : filteredValues[0]
  }
  return value
}

export const getRenderValue = (mode, value) => {
  if (["tags", "multiple"].includes(mode)) {
    return realValues(isArray(value) ? value : [value])
  }
  return getSelectedValue(false, value)
}
