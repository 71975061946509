import React from "react"
import Select from "react-select"
import { useQuery } from "@apollo/client"
import { useHistory, useParams, useLocation } from "react-router"

import { GET_CUSTOMERS } from "@dbai/ui-staples"

const optFromCustomer = customer => {
  if (!customer) {
    return null
  }

  return {
    label: customer.name,
    value: customer.normalizedName,
  }
}

// Safer to match slashes too. This could easily end up being something to the
// effect of /undefined/inner-path/undefined or the like.
const undefinedPath = /^\/undefined\//
const fetchPolicy = "network-only"

const CustomerSelect = props => {
  const { pathname } = useLocation()
  const history = useHistory()
  const { cname } = useParams()
  const { error, loading, data } = useQuery(GET_CUSTOMERS, { fetchPolicy })

  if (error || loading) {
    return null
  }

  const onChange = ({ value }) => {
    undefinedPath.test(pathname)
      ? history.push(pathname.replace(undefinedPath, `/${value}/`))
      : history.push(`/${value}`)
  }

  const safeCustomers = data.customers || []
  const options = safeCustomers.map(optFromCustomer)
  const selected = options.find(o => o.value === cname) || null

  return (
    <div id="customer-select">
      <Select
        placeholder="Jump To Customer..."
        options={options}
        value={selected}
        onChange={onChange}
      />
    </div>
  )
}

export default CustomerSelect
