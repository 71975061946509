import { gql } from "@apollo/client"

export const GET_THEMES = gql`
  query GetThemes($cname: String!) {
    customer(name: $cname) {
      id
      themes {
        id
        name
        description
        createdAt
        updatedAt
        spec
        logo
      }
    }
  }
`

export const GET_THEME = gql`
  query GetTheme($id: Int!, $cname: String!) {
    customer(name: $cname) {
      id
      theme(id: $id) {
        id
        name
        description
        createdAt
        updatedAt
        spec
        logo
      }
    }
  }
`

export const CREATE_THEME = gql`
  mutation CreateTheme($cname: String!, $input: ThemeInput!) {
    createTheme(cname: $cname, input: $input) {
      id
      name
      description
      createdAt
      updatedAt
      spec
      logo
    }
  }
`

export const UPDATE_THEME = gql`
  mutation UpdateTheme($id: Int!, $cname: String!, $input: ThemeInput!) {
    updateTheme(id: $id, cname: $cname, input: $input) {
      id
      name
      description
      createdAt
      updatedAt
      spec
      logo
    }
  }
`

export const DELETE_THEME = gql`
  mutation DeleteTheme($id: Int!, $cname: String!) {
    deleteTheme(id: $id, cname: $cname) {
      id
    }
  }
`
