import React from "react"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { components } from "react-select"

import { Form, Input, Select } from "@dbai/ui-staples"

import withCustomer from "components/shared/withCustomer"
import { selectInstance } from "selectors"
import { actions } from "reducers/instanceReducer"

const awsSizes = {
  "t2.micro": { cpus: 1, memory: 1 },

  "m4.large": { cpus: 2, memory: 8 },
  "m4.xlarge": { cpus: 4, memory: 16 },
  "m4.2xlarge": { cpus: 8, memory: 32 },
  "m4.3xlarge": { cpus: 16, memory: 64 },

  "m5.large": { cpus: 2, memory: 8 },
  "m5.xlarge": { cpus: 4, memory: 16 },
  "m5.2xlarge": { cpus: 8, memory: 32 },
  "m5.4xlarge": { cpus: 16, memory: 64 },
  "m5.8xlarge": { cpus: 32, memory: 128 },

  "r7g.medium": { cpus: 1, memory: 8 },
  "r7g.large": { cpus: 2, memory: 16 },
  "r7g.xlarge": { cpus: 4, memory: 32 },
  "r7g.2xlarge": { cpus: 8, memory: 64 },
  "r7g.4xlarge": { cpus: 16, memory: 128 },
}

const OptionFlow = styled.div`
  padding: 5px;
  cursor: pointer;
  &:hover {
    background-color: #deebff; // Taken from react-select
  }
`

const OptLabel = props => {
  const { label, size } = props.data

  return (
    <>
      <span>{label}</span> &mdash;{" "}
      <span className="badge badge-secondary">CPU: {size.cpus}</span>{" "}
      <span className="badge badge-secondary">RAM: {size.memory}GB</span>
    </>
  )
}

const Option = props => {
  const { data, selectOption } = props
  return (
    <OptionFlow onClick={() => selectOption(data)}>
      <OptLabel data={data} />
    </OptionFlow>
  )
}

const SingleValue = props => {
  return (
    <components.SingleValue {...props}>
      <OptLabel data={props.data} />
    </components.SingleValue>
  )
}

const NewInstance = props => {
  const { customer, afterSubmit } = props

  const dispatch = useDispatch()
  const state = useSelector(selectInstance)
  const opts = Object.keys(awsSizes).map(size => ({
    label: size,
    value: size,
    size: awsSizes[size],
  }))

  const onSubmit = state => {
    afterSubmit && afterSubmit()
    dispatch(actions.createInstance({ customerId: customer.id }))
      .then(() => {
        toast.info("Creating Instance")
        dispatch(actions.reset())
      })
      .catch(() => toast.error("Error Creating Instance"))
  }

  return (
    <>
      <div className="alert alert-info">
        <h4>Dedicated Compute</h4>
        <p>
          Allocate a new Compute Instance dedicated to running your Workflows.
          The amount of Compute you allocate will determine what is available to
          this account.
        </p>
      </div>
      <Form
        submitText="CREATE"
        dispatch={dispatch}
        state={state}
        actions={actions}
        onSubmit={onSubmit}
      >
        <Input name="instance.name" />
        <Select
          name="instance.type"
          label="Instance Size"
          options={opts}
          components={{ Option, SingleValue }}
        />
      </Form>
    </>
  )
}

export default withCustomer(NewInstance)
