import styled, { css } from "styled-components"

const borderCss = css`
  border: 2px solid
    ${props => {
      const { status, selected, focused } = props
      if (status === "loading") return props.theme.success //"green"
      if (status === "waiting") return props.theme.warning //"yellow"
      if (status === "error") return props.theme.danger //"red"
      if (focused) return "#77e"
      if (selected) return props.theme.nodeBorderSelected //props.theme.nodeBorderSelected
      return props.theme.nodeBorder
    }};
`

const boxShadowCss = css`
  box-shadow: ${props => {
    const { selected, status, focused } = props
    if (status === "error") return "0px 0px 10px 2px rgb(255,0,0,0.3)"
    if (focused) return "0px 0px 10px 4px #77e"
    if (selected) return "0px 0px 4px 4px rgb(255,255,255,0.3)"

    return "none"
  }};
`
const handleBackgroundCss = css`
  background-color: ${props => {
    const { status, selected, focused } = props
    if (status === "loading") return props.theme.success //"green"
    if (status === "waiting") return props.theme.warning //"yellow"
    if (status === "error") return props.theme.danger //"red"
    if (focused) return "#77e"
    if (selected) return props.theme.nodeBorderSelected //props.theme.nodeBorderSelected
    return props.theme.nodeBorder
  }};
`

const StyledNode = styled.div`
  transition: all 0.3s ease;
  visibility: "visible";
  cursor: pointer;
  height: ${props => {
    const { nodeType, dropped } = props
    if (nodeType === "component") return dropped ? "500px" : "75px"
    return "56px"
  }};

  width: ${props => (props.dropped ? "600px" : "auto")};

  ${borderCss};
  ${boxShadowCss};
  animation: ${props =>
    (props.status === "loading" && "glow-grow-running 1s infinite") ||
    (props.status === "waiting" && "glow-grow-waiting 4s infinite") ||
    (props.status === "error" && "glow-grow-error 3s") ||
    "none"};

  border-radius: 15px;
  background-color: ${props => props.theme.nodeBg};

  @keyframes glow-grow-running {
    0% {
      box-shadow: 0px 0px 15px 4px rgba(0, 255, 0, 0.3);
    }
    50% {
      box-shadow: 0px 0px 15px 4px rgba(0, 255, 0, 0.5);
    }
    100% {
      box-shadow: 0px 0px 10px 4px rgba(0, 255, 0, 0.3);
    }
  }
  @keyframes glow-grow-waiting {
    0% {
      box-shadow: 0px 0px 15px 4px rgba(255, 255, 0, 0.1);
    }
    50% {
      box-shadow: 0px 0px 15px 4px rgba(255, 255, 0, 0.15);
    }
    100% {
      box-shadow: 0px 0px 15px 4px rgba(255, 255, 0, 0.1);
    }
  }
  @keyframes glow-grow-error {
    0% {
      box-shadow: 0px 0px 15px 4px rgba(255, 0, 0, 0.1);
    }
    5% {
      box-shadow: 0px 0px 25px 4px rgba(255, 0, 0, 0.5);
    }
    10% {
      box-shadow: 0px 0px 15px 4px rgba(255, 0, 0, 0.1);
    }
    15% {
      box-shadow: 0px 0px 25px 4px rgba(255, 0, 0, 0.5);
    }
    20% {
      box-shadow: 0px 0px 15px 4px rgba(255, 0, 0, 0.1);
    }
    25% {
      box-shadow: 0px 0px 25px 4px rgba(255, 0, 0, 0.5);
    }
    30% {
      box-shadow: 0px 0px 15px 4px rgba(255, 0, 0, 0.1);
    }
    35% {
      box-shadow: 0px 0px 25px 4px rgba(255, 0, 0, 0.5);
    }
    40% {
      box-shadow: 0px 0px 15px 4px rgba(255, 0, 0, 0.1);
    }
    45% {
      box-shadow: 0px 0px 25px 4px rgba(255, 0, 0, 0.5);
    }
    50% {
      box-shadow: 0px 0px 15px 4px rgba(255, 0, 0, 0.1);
    }
    55% {
      box-shadow: 0px 0px 25px 4px rgba(255, 0, 0, 0.5);
    }
    60% {
      box-shadow: 0px 0px 15px 4px rgba(255, 0, 0, 0.1);
    }
    65% {
      box-shadow: 0px 0px 25px 4px rgba(255, 0, 0, 0.5);
    }
    70% {
      box-shadow: 0px 0px 15px 4px rgba(255, 0, 0, 0.1);
    }
    75% {
      box-shadow: 0px 0px 25px 4px rgba(255, 0, 0, 0.5);
    }
    80% {
      box-shadow: 0px 0px 15px 4px rgba(255, 0, 0, 0.1);
    }
    85% {
      box-shadow: 0px 0px 25px 4px rgba(255, 0, 0, 0.5);
    }
    90% {
      box-shadow: 0px 0px 15px 4px rgba(255, 0, 0, 0.1);
    }
    95% {
      box-shadow: 0px 0px 25px 4px rgba(255, 0, 0, 0.5);
    }
    100% {
      box-shadow: 0px 0px 15px 4px rgba(255, 0, 0, 0.1);
    }
  }
  label {
    color: ${props =>
      props.selected ? props.theme.nodeBorderSelected : props.theme.nodeBorder};
    font-size: 20px;
    font-family: "Industry";
    align-items: center;
    cursor: pointer;
    padding-top: 11px;
    padding-bottom: 0px;
    padding-right: 10px;
    margin-right: 30px;
  }
  .react-flow__handle {
    ${handleBackgroundCss}
    border-color: ${props => props.theme.colorBgBase};
    height: 10px;
    width: 20px;
    border-radius: 5px;
    z-index: 3;
  }
`

const NodeTitle = styled.div`
  padding-right: 10px;
  float: left;
  span {
    height: 35px;
    float: left;
    margin-top: 10px;
  }
`
const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${props =>
    props.status === "loading"
      ? props.theme.success
      : props.status === "waiting"
        ? props.theme.warning
        : props.status === "error"
          ? props.theme.danger
          : props.focused
            ? "#77e"
            : (props.theme.themeMode === "dark" &&
                (props.selected
                  ? "rgba(255, 255, 255, 1)"
                  : "rgba(255, 255, 255, 0.4)")) ||
              (props.theme.themeMode === "light" &&
                (props.selected ? "rgba(0, 0, 0, 1)" : "rgba(0, 0, 0, 0.4)"))};
  margin-bottom: 3px;
  margin-top: 3px;
`

const FormContainer = styled.div`
  display: flex;
  margin-top: 12px;
  marging-bottom: 12px;
  overflow: auto;
  height: 390px;
`
export { NodeTitle, Divider, FormContainer, StyledNode }
