import { scalarFieldOptions } from "../../lib/fieldOptionsSchemas"

export default {
  type: "object",
  default: {},
  metadata: {
    layout: { gutter: [16] },
    sections: {
      ...scalarFieldOptions.metadata.sections,
      dateOptions: {
        title: "Date Options",
        properties: [
          "metadata/fieldProps/placement",
          "metadata/fieldProps/allowClear",
          "metadata/fieldProps/bordered",
          "metadata/showPresets",
          "metadata/dateFormat",
          "metadata/showTime",
          "metadata/picker",
          "metadata/presetsAreRelative",
          "metadata/timeFormat",
          "metadata/timeFormatPattern",
          "metadata/dateFormatPattern",
        ],
      },
    },
    sideEffects: [
      {
        resetValue: null,
        type: "resetField",
        sourceName: "options.metadata.picker",
        targetName: "options.metadata.dateFormat",
      },
    ],
  },
  dependencies: {
    "metadata/dateFormat": {
      if: {
        properties: {
          "metadata/dateFormat": {
            enum: ["custom"],
          },
        },
      },
      then: {
        properties: {
          "metadata/dateFormatPattern": {
            type: "string",
            default: "MM/DD/YYYY",
            title: "Date Format Pattern",
          },
        },
      },
    },
    "metadata/showTime": {
      if: {
        properties: {
          "metadata/showTime": {
            const: true,
          },
        },
      },
      then: {
        properties: {
          timeOptions: {
            type: "object",
            default: {},
            title: "Time Options",
            dependencies: {
              "metadata/timeFormat": {
                if: {
                  properties: {
                    "metadata/timeFormat": {
                      enum: ["custom"],
                    },
                  },
                },
                then: {
                  properties: {
                    "metadata/timeFormatPattern": {
                      type: "string",
                      default: "HH:mm:ss",
                      title: "Time Format Pattern",
                    },
                  },
                },
              },
            },
            properties: {
              "metadata/timeFormat": {
                type: "string",
                title: "Time Format",
                default: "HH:mm:ss",
                enum: ["HH:mm:ss", "HH:mm", "HH:mm:ss.SSS", "custom"],
                metadata: {
                  labels: [
                    "HH:mm:ss",
                    "HH:mm",
                    "HH:mm:ss.SSS",
                    "Custom (Pattern)",
                  ],
                },
              },
              "metadata/fieldProps/placeholder": {
                type: "string",
                title: "Placeholder",
                default: "Select a time",
              },
            },
          },
          defaultValue: {
            type: "string",
            title: "Default Value",
            metadata: {
              showPresets: true,
              component: "DatePicker",
              dateFormat: "MM/DD/YYYY",
              presetsAreRelative: true,
              showTime: {
                format: "HH:mm:ss",
              },
            },
          },
        },
      },
    },
    "metadata/showPresets": {
      if: {
        properties: {
          "metadata/showPresets": {
            enum: [true],
          },
        },
      },
      then: {
        properties: {
          "metadata/presetsAreRelative": {
            type: "boolean",
            default: false,
            title: "Treat Presets as Relative Dates",
          },
        },
      },
    },
    "metadata/picker": {
      allOf: [
        {
          if: {
            properties: {
              "metadata/picker": {
                enum: ["date"],
              },
            },
          },
          then: {
            properties: {
              "metadata/showTime": {
                type: "boolean",
                title: "Show Time",
              },
              "metadata/dateFormat": {
                type: "string",
                default: "MM/DD/YYYY",
                title: "Date Format",
                enum: ["MM/DD/YYYY", "DD/MM/YYYY", "YYYY/MM/DD", "custom"],
                metadata: {
                  labels: [
                    "MM/DD/YYYY",
                    "DD/MM/YYYY",
                    "YYYY/MM/DD",
                    "Custom (Pattern)",
                  ],
                },
              },
            },
          },
        },
        {
          if: {
            properties: {
              "metadata/picker": {
                enum: ["month"],
              },
            },
          },
          then: {
            properties: {
              "metadata/dateFormat": {
                type: "string",
                default: "MM/YYYY",
                title: "Date Format",
                enum: ["MM/YYYY", "YYYY-MM", "custom"],
                metadata: {
                  labels: ["MM/YYYY", "YYYY-MM", "Custom (Pattern)"],
                },
              },
            },
          },
        },
      ],
    },
  },
  properties: {
    ...scalarFieldOptions.properties,
    title: {
      type: "string",
      title: "Label",
      default: "Date Form Field",
      metadata: {
        fieldProps: {
          placeholder: "Enter a label",
        },
      },
    },
    nullable: {
      type: "boolean",
      title: "Clearable",
      description: "Allow field to be removed from form state",
    },
    "metadata/fieldProps/placeholder": {
      type: "string",
      title: "Placeholder",
    },
    "metadata/fieldProps/placement": {
      type: "string",
      title: "Placement",
      enum: ["topLeft", "topRight", "bottomLeft", "bottomRight"],
      metadata: {
        labels: ["Top Left", "Top Right", "Bottom Left", "Bottom Right"],
      },
    },
    "metadata/fieldProps/allowClear": {
      type: "boolean",
      title: "Allow Clear",
    },
    "metadata/fieldProps/bordered": {
      type: "boolean",
      title: "Bordered",
    },
    "metadata/showPresets": {
      type: "boolean",
      default: false,
      title: "Show Presets",
    },
    "metadata/picker": {
      type: "string",
      title: "Picker Type",
      enum: ["date", "month"],
      default: "date",
      metadata: {
        labels: ["Date", "Month"],
      },
    },
    defaultValue: {
      type: "string",
      title: "Default Value",
      metadata: {
        showTime: false,
        showPresets: true,
        component: "DatePicker",
        dateFormat: "MM/DD/YYYY",
        presetsAreRelative: true,
      },
    },
  },
}
