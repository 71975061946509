import React, { useState, useEffect, useCallback, useRef } from "react"
import styled from "styled-components"
import { Stage, Layer } from "react-konva"

import CanvasShape from "./CanvasShape"
import CanvasStage from "./CanvasStage"
import canvasSchema from "../../schemas/canvasSchema"
import { getWidgetFormId } from "../../lib/widgetEditor"
import JSONSchemaForm from "../../JSONSchemaForm"
import FormFieldWrapper from "../../JSONSchemaForm/FormFieldWrapper"

const CanvasContainer = styled.div`
  height: 100%;
  ${({ width }) => (width > 0 ? `width: ${width}px` : "width: 100%")};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? "transparent"};
`

const shapesSchema = canvasSchema.properties.shapes

const EditableCanvasLayers = props => {
  const { selectedId, onSelect, stageRef, widgetId, shapes } = props
  const formId = getWidgetFormId(widgetId)
  return (
    <>
      {shapes.map((shape, idx) => {
        if (!shape) return null
        return (
          <JSONSchemaForm.Provider formId={formId}>
            <FormFieldWrapper
              noStyle
              path="options.shapes"
              key={shape.id || idx}
              schemaKey={`.[${idx}]`}
              schema={shapesSchema.items}
              parentSchema={shapesSchema}
            >
              <CanvasShape
                editable
                stageRef={stageRef}
                onSelect={onSelect(shape)}
                isSelected={shape.id === selectedId}
              />
            </FormFieldWrapper>
          </JSONSchemaForm.Provider>
        )
      })}
    </>
  )
}

const Canvas = props => {
  const stageRef = useRef()
  const containerRef = useRef()
  const { widget = {}, registerOnResize, editing, widgetId } = props
  const { shapes = [], stage = {} } = widget.options || {}
  const [stageWidth, setStageWidth] = useState(500)
  const [stageHeight, setStageHeight] = useState(500)
  const { backgroundColor, ...stageProps } = stage

  /**
   * Transform stage container to fit container on container scroll.
   * This way, we leverage the browser's scrollbars and don't have to
   * create custom ones.
   */
  const repositionStage = useCallback(() => {
    if (stageRef.current && containerRef.current) {
      var dx = containerRef.current.scrollLeft
      var dy = containerRef.current.scrollTop
      stageRef.current.style = {
        transform: "translate(" + dx + "px, " + dy + "px)",
      }
      stageRef.current.x(-dx)
      stageRef.current.y(-dy)
    }
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      const { offsetHeight, offsetWidth } = containerRef.current
      setStageWidth(offsetWidth)
      setStageHeight(offsetHeight)
    }
  }, [])

  const onResize = useCallback(
    rect => {
      const { width, height } = rect || {}
      if (!stage.width || stage.width < 0) {
        setStageWidth(width)
      }
      if (!stage.height || stage.height < 0) {
        setStageHeight(height)
      }
    },
    [stage.width, stage.height]
  )

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", repositionStage)
      repositionStage()
    }
  }, [repositionStage])

  useEffect(() => {
    registerOnResize(() => onResize)
  }, [registerOnResize, onResize])

  if (editing) {
    return (
      <CanvasContainer
        editable
        ref={containerRef}
        width={stageWidth}
        backgroundColor={backgroundColor}
      >
        <CanvasStage
          ref={stageRef}
          width={stageWidth}
          height={stageHeight}
          {...stageProps}
        >
          <EditableCanvasLayers
            shapes={shapes}
            stageRef={stageRef}
            widgetId={widgetId}
          />
        </CanvasStage>
      </CanvasContainer>
    )
  }

  return (
    <CanvasContainer
      ref={containerRef}
      width={stageWidth}
      backgroundColor={backgroundColor}
    >
      <Stage
        ref={stageRef}
        width={stageWidth}
        height={stageHeight}
        {...stageProps}
      >
        <Layer>
          {shapes.map((shape, idx) => {
            return (
              <CanvasShape stageRef={stageRef} key={shape.id} value={shape} />
            )
          })}
        </Layer>
      </Stage>
    </CanvasContainer>
  )
}

export default Canvas
