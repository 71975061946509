import React, { useMemo, useRef, useEffect, useCallback } from "react"
import styled from "styled-components"
import { Button, Typography, Row, Col } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { Panel, PanelGroup } from "react-resizable-panels"
import { faBackward } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import AppSettings from "./AppSettingsForm"
import { actions } from "../reducers/appReducer"
import AppFiltersForm from "./AppFiltersForm"
import ControlPanelEditor from "./WidgetEditor"
import AppVariablesForm from "./AppVariablesForm"
import AppEndpointForm from "./AppEndpointForm"
import AppEndpointsList from "./AppEndpointsList"
import DraggableWidgetList from "./DraggableWidgetList"
import useEditingWidget from "../hooks/useEditingWidget"
import PanelResizeHandle from "../WidgetEditor/PanelResizeHandle"
import { selectWidgetEditor } from "../selectors/app"

const { Title } = Typography

const StyledHeader = styled(Title)`
  padding: 8px;
  margin-bottom: 0;
`

const PaddedCol = styled(Col)`
  padding: 16px;
`

const ScrollableCol = styled(Col)`
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
`

const ScrollableArea = styled.div`
  height: 100%;
  overflow-y: auto;
`

const ControlPanel = props => {
  const { widgetRegistry } = props
  const dispatch = useDispatch()
  const appSettingsPanelRef = useRef(null)
  const [, , showWidgetEditor] = useEditingWidget()
  const {
    showAppFilters,
    showAppSettings,
    showAppEndpoint,
    showAppVariables,
    showAppEndpoints,
  } = useSelector(selectWidgetEditor)

  const showingOtherOptions = useMemo(() => {
    return (
      showAppSettings ||
      showAppFilters ||
      showAppVariables ||
      showAppEndpoints ||
      showAppEndpoint
    )
  }, [
    showAppSettings,
    showAppFilters,
    showAppVariables,
    showAppEndpoints,
    showAppEndpoint,
  ])

  useEffect(() => {
    if (showingOtherOptions) {
      appSettingsPanelRef.current.resize(100)
    } else {
      appSettingsPanelRef.current.resize(0)
    }
  }, [showingOtherOptions])

  const close = useCallback(() => {
    dispatch(actions.hideAllSettings())
  }, [dispatch])

  return (
    <PanelGroup direction="horizontal">
      <Panel order={1} minSize={20} collapsible>
        {showWidgetEditor ? (
          <ControlPanelEditor />
        ) : (
          <ScrollableArea>
            <StyledHeader level={5}>
              Click & Drag or Double-Click to Add Widget
            </StyledHeader>
            <DraggableWidgetList widgetRegistry={widgetRegistry} />
          </ScrollableArea>
        )}
      </Panel>
      {showingOtherOptions ? <PanelResizeHandle /> : null}
      <Panel
        order={2}
        minSize={20}
        collapsible
        defaultSize={0}
        ref={appSettingsPanelRef}
      >
        {showingOtherOptions ? (
          <Row>
            <PaddedCol span={24}>
              <Button
                onClick={close}
                icon={<FontAwesomeIcon icon={faBackward} />}
              >
                Go Back
              </Button>
            </PaddedCol>
            <ScrollableCol span={24}>
              {showAppSettings ? <AppSettings /> : null}
              {showAppFilters ? <AppFiltersForm /> : null}
              {showAppVariables ? <AppVariablesForm /> : null}
              {showAppEndpoints ? <AppEndpointsList /> : null}
              {showAppEndpoint ? <AppEndpointForm /> : null}
            </ScrollableCol>
          </Row>
        ) : null}
      </Panel>
    </PanelGroup>
  )
}

export default React.memo(ControlPanel)
