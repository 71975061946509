import { gql } from "@apollo/client"

export const CREATE_PACKAGE = gql`
  mutation CreatePackage($workflowId: Int!, $cid: Int!, $input: PackageInput!) {
    createPackage(workflowId: $workflowId, customerId: $cid, input: $input) {
      id
      image
      createdAt
      updatedAt
    }
  }
`

export const RUN_PACKAGE = gql`
  mutation RunPackage($cid: Int!, $id: Int!) {
    runPackage(customerId: $cid, id: $id) {
      id
      image
      status
      createdAt
      updatedAt
    }
  }
`

const getPackagesName = "GetPackages"
export const GET_PACKAGES = gql`
  query ${getPackagesName}($cname: String!, $workflowId: Int!) {
    customer(name: $cname) {
      id
      packages(workflowId: $workflowId) {
        id
        image
        status
        createdAt
        updatedAt
      }
    }
  }
`
GET_PACKAGES.name = getPackagesName

export const GET_CUSTOMER_PACKAGES = gql`
  query GetCustomerPackages($cname: String!) {
    customer(name: $cname) {
      id
      packages {
        id
        image
        status
        createdAt
        workflow {
          id
          name
        }
      }
    }
  }
`

export const DELETE_PACKAGE = gql`
  mutation DeletePackage($cname: String!, $id: Int!) {
    deletePackage(cname: $cname, id: $id)
  }
`
