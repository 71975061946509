import React, { useEffect, useMemo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import JSONSchemaForm from "../../JSONSchemaForm"
import { actions } from "../../reducers/appReducer"
import { selectSessionFilter } from "../../selectors/app"
import useFilterWidgetSchema from "../../hooks/useFilterWidgetSchema"

const FilterWidgetContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
const Filter = props => {
  const { widget, pageId, widgetId } = props
  const dispatch = useDispatch()
  const {
    op,
    key,
    column,
    metadata = {},
    datasetId,
    columnType,
    defaultValue = null,
  } = widget.options

  const filterSpec = useMemo(() => {
    return {
      datasetId,
      pageId,
      id: widgetId,

      op,
      column,
      columnType,
      ...metadata,
    }
  }, [op, column, datasetId, metadata, columnType, widgetId, pageId])

  const { value } = useSelector(
    state =>
      selectSessionFilter(state, {
        pageId: filterSpec.pageId,
        filterId: filterSpec.id,
        datasetId: filterSpec.datasetId,
      }) || {}
  )

  const filterSchema = useFilterWidgetSchema(widget.options, widgetId)

  const handleFormChange = useCallback(
    data => {
      // remove session filter if value is falsy
      if ([null, undefined].includes(data.value)) {
        return dispatch(actions.removeSessionFilter({ id: filterSpec.id }))
      }

      dispatch(
        actions.saveSessionFilter({
          ...filterSpec,
          value: data.value,
        })
      )
    },
    [dispatch, filterSpec]
  )

  useEffect(() => {
    // if the filter is not in the session, add it
    if (
      ["", undefined, null].includes(value) &&
      !["", undefined, null].includes(defaultValue)
    ) {
      dispatch(
        actions.saveSessionFilter({
          ...filterSpec,
          value: defaultValue,
        })
      )
    }
  }, [dispatch, filterSpec, value, defaultValue])

  useEffect(() => {
    // for simplicity, this filter only exists when its rendered on the page
    return () => {
      dispatch(actions.removeSessionFilter({ id: filterSpec.id }))
    }
  }, [dispatch, filterSpec.id])

  const formValue = useMemo(() => {
    const valueKey = key ?? "value"
    return { [valueKey]: value }
  }, [value, key])

  return (
    <FilterWidgetContainer backgroundColor={widget.options.backgroundColor}>
      <JSONSchemaForm
        hideSave
        value={formValue}
        schema={filterSchema}
        onFormChange={handleFormChange}
      />
    </FilterWidgetContainer>
  )
}

export default React.memo(Filter)
