import gql from "graphql-tag"

export const CREATE_UPLOAD = gql`
  mutation CreateUpload(
    $originalFilename: String!
    $userId: Int!
    $customerId: Int!
    $size: Int!
  ) {
    createUpload(
      originalFilename: $originalFilename
      userId: $userId
      customerId: $customerId
      size: $size
    ) {
      id
      originalFilename
      size
      status
      urls {
        uploadUrl
      }
    }
  }
`

export const UPDATE_UPLOAD_STATUS = gql`
  mutation UpdateUploadStatus($id: Int!, $customerId: Int!, $status: String!) {
    updateUpload(id: $id, customerId: $customerId, status: $status) {
      id
      status
    }
  }
`

export const GET_USER_UPLOADS = gql`
  query UserUploads($name: String!, $userId: Int!) {
    customer(name: $name) {
      id
      name
      normalizedName
      uploads(userId: $userId) {
        id
        originalFilename
        size
        createdAt
        status
        urls {
          downloadUrl
        }
      }
    }
  }
`
