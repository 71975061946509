const errorMessage = {
  "/options/query/datasetId": "Please select a dataset",
}

const getErrorMessageFromSchema = (schema, keyword) => {
  return schema?.metadata?.errorMessage?.[keyword]
}

const getCustomMessage = error => {
  const { instancePath, keyword, parentSchema = {}, params = {} } = error
  switch (true) {
    case keyword === "required":
      const { missingProperty } = params
      return (
        getErrorMessageFromSchema(
          parentSchema.properties?.[missingProperty],
          keyword
        ) ||
        errorMessage[instancePath] ||
        errorMessage[keyword]
      )
    default:
      return (
        getErrorMessageFromSchema(parentSchema, keyword) ||
        errorMessage[instancePath] ||
        errorMessage[keyword]
      )
  }
}

const blacklist = ["if"]
const getSafeMessage = error => {
  const { message } = error
  const ajvMessage = message
  const newMessage = getCustomMessage(error)
  const trueMessage = newMessage || ajvMessage
  return typeof trueMessage === "function" ? trueMessage(error) : trueMessage
}

export const getSafeErrors = errors => {
  if (!errors) return []
  return errors
    .filter(({ keyword }) => !blacklist.includes(keyword))
    .map(error => ({ ...error, message: getSafeMessage(error) }))
}
