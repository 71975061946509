import React, { useRef, useCallback } from "react"
import { Star, Line, Rect, Text, Circle } from "react-konva"

import CanvasImage from "./CanvasImage"
import CanvasRichText from "./CanvasRichText"
import DatasetColumn from "./DatasetColumn"
import { normalizeFormData } from "../../lib/formData"

const shapes = {
  text: Text,
  richText: CanvasRichText,
  star: Star,
  rect: Rect,
  line: Line,
  circle: Circle,
  image: CanvasImage,
  datasetColumn: DatasetColumn,
}

const EditableCanvasShape = props => {
  const { onSelect, stageRef, onChange, value = {} } = props
  const shapeRef = useRef()

  const handleDragStart = useCallback(e => {
    // this works the same as e.evt.stopPropagation()
    // this is necessary to prevent other events from triggering when this shape is being dragged
    e.evt.cancelBubble = true
  }, [])

  const handleDragEnd = useCallback(
    e => {
      const id = e.target.id()
      if (id === value.id) {
        onChange({
          ...value,
          x: e.target.x(),
          y: e.target.y(),
        })
      }
    },
    [onChange, value]
  )

  const handleTransformEnd = useCallback(
    e => {
      // transformer is changing scale of the node
      // and NOT its width or height
      // but in the store we have only width and height
      // to match the data better we will reset scale on transform end
      const node = shapeRef.current
      const scaleX = node.scaleX()
      const scaleY = node.scaleY()

      // we will reset it back
      node.scaleX(1)
      node.scaleY(1)

      if (value.type === "star") {
        onChange({
          ...value,
          x: node.x(),
          y: node.y(),
          // set minimal value
          width: Math.max(5, node.width() * scaleX),
          height: Math.max(node.height() * scaleY),
          innerRadius: node.innerRadius() * scaleY,
          outerRadius: node.outerRadius() * scaleX,
        })
        return
      }

      onChange({
        ...value,
        x: node.x(),
        y: node.y(),
        // set minimal value
        width: Math.max(5, node.width() * scaleX),
        height: Math.max(node.height() * scaleY),
      })
    },
    [onChange, value]
  )

  const handleTransform = useCallback(e => {
    // only scale width and height for shape when shape type is text
    if (e.target.getClassName() === "Text") {
      const node = shapeRef.current
      node.setAttrs({
        width: node.width() * node.scaleX(),
        height: node.height() * node.scaleY(),
        scaleX: 1,
        scaleY: 1,
      })
    }
  }, [])

  const Shape = shapes[value?.type]
  if (!Shape) return null

  const normalizedValue = normalizeFormData(value)
  return (
    <Shape
      {...normalizedValue}
      draggable
      name="object"
      ref={shapeRef}
      stageRef={stageRef}
      onDragEnd={handleDragEnd}
      onTransform={handleTransform}
      onDragStart={handleDragStart}
      onTap={() => onSelect(shapeRef)}
      onClick={() => onSelect(shapeRef)}
      onTransformEnd={handleTransformEnd}
    />
  )
}

const CanvasShape = props => {
  const { editable, value, stageRef } = props

  if (editable) {
    return <EditableCanvasShape {...props} />
  }

  const Shape = shapes[value?.type]
  if (!Shape) return null
  return <Shape {...value} stageRef={stageRef} />
}

export default React.memo(CanvasShape)
