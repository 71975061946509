import { createSlice } from "@reduxjs/toolkit"
import { createCommonActions } from "@dbai/tool-box"

import { GET_CURRENT_CUSTOMER } from "../queries/customers"

const initialState = {
  customer: null,
}

const currentCustomerSlice = createSlice({
  name: "currentCustomer",
  initialState,
  reducers: {
    ...createCommonActions(initialState),
  },
})

// getCurrentCustomer loads the customer with cname=name from GraphQL and
// updates the store. An optional client argument can be passed, which allows
// for using a mocked instance in tests.
const getCurrentCustomer =
  (name, clientOverride) =>
  (dispatch, _, { client }) => {
    const currentClient = clientOverride || client
    return currentClient
      .query({
        query: GET_CURRENT_CUSTOMER,
        variables: { name },
      })
      .then(({ data }) => {
        dispatch(actions.set({ name: "customer", value: data.customer }))
      })
  }

const { actions } = currentCustomerSlice

const allActions = {
  getCurrentCustomer,
  ...actions,
}

export { allActions as actions }
export const { name, reducer, caseReducer } = currentCustomerSlice
export default currentCustomerSlice
