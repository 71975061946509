import { actions } from "../../reducers/appReducer"
import { observeYjsMap } from "./utils"

const addVariableObserver = (dispatch, variableMap) => {
  observeYjsMap({
    yMap: variableMap,
    onExternalChange: ({ yMap }) => {
      const variable = yMap.toJSON()
      dispatch(actions.setAppVariable(variable))
    },
  })
  return variableMap
}

const variableObserver = (yDoc, dispatch) => {
  const specMap = yDoc.getMap("spec")
  const variablesMap = specMap.get("variables")
  const variables = variablesMap.toArray()

  variables.forEach(variableMap => {
    addVariableObserver(dispatch, variableMap)
  })
}

export { addVariableObserver }
export default variableObserver
