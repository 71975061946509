import React, { useState, useEffect } from "react"
import GraphiQL from "graphiql"
import { parse } from "graphql"
import { execute } from "@apollo/client"

import { appLink } from "./apolloClient"

const fetcher = params => {
  const query = parse(params.query)
  return execute(appLink, { ...params, query })
}

const Wrapper = props => {
  const [cssLoaded, setCssLoaded] = useState(false)
  useEffect(() => {
    if (!cssLoaded) {
      import("graphiql/graphiql.css").then(data => {
        setCssLoaded(true)
      })
    }
  }, [cssLoaded])

  if (!cssLoaded) return null

  return (
    <div className="graphiql-wrapper">
      <GraphiQL fetcher={fetcher} />
    </div>
  )
}

export default Wrapper
