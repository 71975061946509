import { actions } from "../../reducers/appReducer"
import { observeYjsMap } from "./utils"
import { addPageObserver } from "./pageObserver"

const syncAddPage = (dispatch, pagesMap, pageId) => {
  addPageObserver(dispatch, pagesMap, pageId)
  const pageMap = pagesMap.get(pageId)
  const pageJson = pageMap.toJSON()
  dispatch(actions.addPage({ pageId, ...pageJson }))
}

const handleExternalChange =
  dispatch =>
  ({ yMap, change, key }) => {
    switch (change.action) {
      case "add":
        syncAddPage(dispatch, yMap, key)
        break
      case "delete":
        dispatch(actions.removePage(key))
        break
      default:
        return
    }
  }

const handleLocalChange =
  dispatch =>
  ({ yMap, change, key }) => {
    switch (change.action) {
      case "add":
        addPageObserver(dispatch, yMap, key)
        break
      default:
        return
    }
  }

const pagesObserver = (yDoc, dispatch) => {
  const spec = yDoc.getMap("spec")
  const pagesMap = spec.get("pages")
  observeYjsMap({
    yMap: pagesMap,
    onLocalChange: handleLocalChange(dispatch),
    onExternalChange: handleExternalChange(dispatch),
  })
}

export default pagesObserver
