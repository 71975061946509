import React, { Component, createContext } from "react"
import please from "pleasejs"
import { isEqual } from "lodash"
import { jwtDecode } from "jwt-decode"
import { connect } from "react-redux"

import { PING_ME } from "@dbai/ui-staples"

import config from "config"
import client from "apolloClient"
import {
  initYjs,
  shutDownYjs,
  initSpec,
  initObservers,
  getYProviderParams,
} from "lib/yjs"

const YjsContext = createContext(null)

/*
 * Neat little lib, pleasejs. Gives the option to create/modify a color scheme
 * based on a few parameters to give us something coordinated but randomized.
 * Eventually I suppose we could allow users to pick a color, but for now this
 * is about the easiest way to set unique colors for users that are not
 * dissonant that I've ever seen.
 */
const color = please.make_color({
  hue: 0.5,
})

const enabled = !!config.yjs

const getWorkflowId = ({ cname, id }) => `${cname}/workflows/${id}`

const initialState = {
  doc: null,
  provider: null,
  authInterval: null,
  observersInitialized: false,
}

const sendAuthUpdate = wsProvider => {
  const updatedAuth = getYProviderParams()
  const authUpdate = JSON.stringify({
    messageType: "authUpdate",
    ...updatedAuth,
  })

  if (wsProvider?.ws && wsProvider.wsconnected) wsProvider.ws.send(authUpdate)
}

const updateAuth = (wsProvider, customerId) => {
  const params = new URLSearchParams(getYProviderParams(customerId))
  const baseUrl = wsProvider.url.split("?")[0]
  const urlWithUpdatedAuth = [baseUrl, params].join("?")
  wsProvider.url = urlWithUpdatedAuth
}

const checkIfExpired = jwt => {
  return jwt && Date.now() >= jwt.exp * 1000
}

const updateProviderUrl = (wsProvider, customerId) => {
  if (!wsProvider || !customerId) return
  const authBearer = new URL(wsProvider?.url).searchParams.get("auth")
  const token = authBearer?.replace("Bearer ", "")
  const jwt = token && jwtDecode(token)

  if (checkIfExpired(jwt)) {
    const currentToken = jwtDecode(localStorage.getItem("user/jwt"))
    if (!checkIfExpired(currentToken)) {
      sendAuthUpdate(wsProvider)
      updateAuth(wsProvider, customerId)
      return
    }

    client
      .query({
        fetchPolicy: "network-only",
        query: PING_ME,
      })
      .then(() => {
        sendAuthUpdate(wsProvider)
        updateAuth(wsProvider, customerId)
      })
  }
}

/*
 * Generally we are not using class components, but this situation is the kind
 * of scenario that I think it makes sense to be an exception. The logic in
 * the return function for the useEffect hooks was getting a little bit...
 * extensive, and I think this cleans it up/makes it easier to think about.
 */
class Provider extends Component {
  constructor(props) {
    super(props)

    this.state = initialState
  }

  resetState = () => {
    this.setState(initialState)
  }

  shutDown = () => {
    const { provider, authInterval } = this.state
    if (provider) shutDownYjs()
    if (authInterval) clearInterval(authInterval)
    this.resetState()
  }

  /*
   * Attempts to connect to ko-op and set up the Y doc. Creates a promise
   * stored in state and on success stores the resultant provider in state as
   * well.
   */
  connectToYjs() {
    if (!enabled) return
    const {
      props: { workflow, dispatch, user, customer },
      state: { shouldNotReconnect },
    } = this

    if (shouldNotReconnect) return

    const setProviderAndDoc = (wsProvider, yDoc) => {
      this.setState(state => ({
        ...state,
        provider: wsProvider,
        doc: yDoc,
      }))
    }

    initYjs(getWorkflowId(this.props), customer.id)
      .then(yProviderAndDoc => {
        if (!yProviderAndDoc) return
        const { wsProvider, yDoc } = yProviderAndDoc

        wsProvider.on("sync", () => {
          wsProvider.awareness.setLocalStateField("user", {
            color,
            name: user && (user.name || user.email),
          })
        })

        wsProvider.on("sync", () => {
          if (this.state.observersInitialized) return

          initObservers(yDoc, dispatch)
          this.setState({ observersInitialized: true })
        })

        wsProvider.on("status", ({ status }) => {
          if (status === "disconnected") {
            /*
             * If we get disconnected at some point we might be operating with
             * some old creds. Refresh them on disconnect.
             */
            updateProviderUrl(wsProvider, customer.id)
          }

          if (status === "connected") {
            /*
             * By extracting the default onmessage handler we can insert our own
             * intermediary logic prior to calling the default allowing us to
             * reuse the current websocket for other purposes.
             */
            const yMessageHandler = wsProvider.ws.onmessage
            wsProvider.ws.onmessage = event => {
              const { data } = event
              if (typeof data !== "string") {
                yMessageHandler(event)
              }

              const isInitRequest = data === "initRequest"

              // We only want one client initializing the ydoc at a time.
              if (isInitRequest && !yDoc.get("nodes").toJSON()) {
                const metadata = yDoc.getMap("metadata")
                metadata.set("name", workflow.name)
                metadata.set("resources", workflow.resources)
                return initSpec({
                  yDoc,
                  dispatch,
                  spec: workflow.spec,
                })
              }
            }
          }
        })

        wsProvider.awareness.on("change", () => {
          const awarenessState = wsProvider.awareness.getStates()
          const users = Array.from(awarenessState).map(([key, value]) => {
            return {
              key,
              ...value.user,
            }
          })
          if (!isEqual(this.state.users, users)) {
            this.setState({ users })
          }
        })
        wsProvider.connect()
        const yOnCloseHandler = wsProvider.ws.onclose
        wsProvider.ws.onclose = event => {
          /*
           * If our socket is closed with a 1008, it's because we got an auth
           * error when connecting to the doc. Don't try to reconnect and shut
           * everything down. Note that `shouldNotReconnect` is intentionally
           * not stored in `initialState` so that it's not part of the reset
           * call in `this.shutDown`. This means to try to connect again will
           * require a page refresh or navigation, but that's probably what we
           * want prior to another attempt.
           */
          if (event.code === 1008) {
            this.setState({ shouldNotReconnect: true })
            this.shutDown()
            return
          }

          yOnCloseHandler(event)
        }

        setProviderAndDoc(wsProvider, yDoc)

        const authInterval = setInterval(() => {
          if (wsProvider && wsProvider.wsconnected) {
            updateProviderUrl(wsProvider, customer.id)
          }
        }, 5000)

        this.setState({ authInterval })
      })
      .catch(err => {
        console.error(err)
        console.warn("Unable to connect to ws server")
      })
  }

  componentDidMount() {
    this.connectToYjs()
  }

  componentWillUnmount() {
    this.shutDown()
  }

  render() {
    const {
      props,
      state: { doc, provider, users },
    } = this

    if (doc && doc.name !== getWorkflowId(props)) {
      this.shutDown()
      throw new Error("Incorrect yDoc")
    }

    return (
      <YjsContext.Provider value={{ provider, doc, enabled, users }}>
        {props.children}
      </YjsContext.Provider>
    )
  }
}

export const YjsProvider = connect()(Provider)

export default YjsContext
