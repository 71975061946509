import React from "react"
import { Transformer } from "react-konva"

const CanvasTransformer = React.forwardRef((props, ref) => {
  return (
    <Transformer
      ref={ref}
      keepRatio={false}
      name="transformer"
      boundBoxFunc={(oldBox, newBox) => {
        // limit resize
        if (newBox.width < 20 || newBox.height < 20) {
          return oldBox
        }
        return newBox
      }}
    />
  )
})

export default React.memo(CanvasTransformer)
