import React, { useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Button } from "antd"

import Card from "../Card"
import Input from "../Form/Input"
import SoloCard from "../SoloCard"
import ApiForm from "../Form/ApiForm"
import { FORGOT_PASSWORD } from "../../queries/auth"

const ConfirmationMessage = styled.div`
  padding: 30px 20px 40px;
  text-align: center;
`

const ForgotPassword = props => {
  const [showConfirmation, setShowConfirmation] = useState(false)

  return (
    <SoloCard>
      <Card title="FORGOT PASSWORD">
        {showConfirmation ? (
          <>
            <ConfirmationMessage>
              Check your email for a link to reset your password. If it doesn't
              appear within a few minutes, check your spam folder.
            </ConfirmationMessage>
            <Link to={`/login`}>
              <Button type="primary">RETURN TO SIGN IN</Button>
            </Link>
          </>
        ) : (
          <ApiForm
            mutation={FORGOT_PASSWORD}
            submitText="SEND LINK"
            afterSubmit={() => setShowConfirmation(true)}
          >
            <Input name="email" />
          </ApiForm>
        )}
      </Card>
    </SoloCard>
  )
}

export default ForgotPassword
