import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import { SchemaForm, appConfigSchema } from "@dbai/applet"
import { SidePane } from "@dbai/ui-staples"

import { actions } from "reducers/appsReducer"

const reducedAppConfigSchema = {
  title: "App Settings",
  type: "object",
  required: ["name"],
  properties: {
    name: appConfigSchema.properties.name,
    "/spec": {
      type: "object",
      default: {},
      properties: {
        "/theme": appConfigSchema.properties.theme,
      },
    },
  },
}

const initialAppConfig = { name: "", description: "", theme: {} }
const NewAppForm = props => {
  const { onSubmit } = props
  return (
    <SchemaForm
      onSubmit={onSubmit}
      schema={reducedAppConfigSchema}
      initialState={initialAppConfig}
    />
  )
}

const NewAppDrawer = props => {
  const { onClose, visible } = props

  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    state => {
      dispatch(actions.createApp(state)).then(onClose)
    },
    [dispatch, onClose]
  )

  return (
    <SidePane
      width="30vw"
      isVisible={visible}
      title="Create a new App"
      onCloseClicked={onClose}
    >
      <NewAppForm onSubmit={handleSubmit} />
    </SidePane>
  )
}

NewAppDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default NewAppDrawer
