import React from "react"
import get from "lodash/get"
import { v4 as uuidv4 } from "uuid"
import styled from "styled-components"

import { useFormState, useFormActions } from "./Form/hooks"

const InputContent = styled.div`
  display: flex;
  padding-left: 1.25rem;
`

const Input = styled.input`
  margin-left: -1.25rem;
  display: flex;
  align-self: center;
  &:disabled ~ label {
    color: #999;
  }
`

const Label = styled.label`
  margin-bottom: 1px;
  align-self: center;
  display: flex;
  margin-left: 0.75em;
`

//TODO: move this to react component library when stable
const Checkbox = props => {
  const state = useFormState()
  const { formSet } = useFormActions()

  const {
    name,
    defaultValue,
    currentValue = get(state, name, defaultValue),
    label,
    onChange,
    key = uuidv4(),
  } = props

  const handleChange = evt => {
    const { checked } = evt.target
    formSet({ name, value: checked })
    onChange && onChange(checked)
  }

  return (
    <InputContent>
      <Input
        type="checkbox"
        id={`db-checkbox-${key}`}
        checked={currentValue}
        onChange={handleChange}
      />
      <Label for={`db-checkbox-${key}`}>{label}</Label>
    </InputContent>
  )
}

export default Checkbox
