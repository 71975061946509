import React, { useCallback } from "react"
import { Tooltip, Button } from "antd"
import { faStepForward } from "@fortawesome/free-solid-svg-icons"
import { useSelector, useDispatch } from "react-redux"
import get from "lodash/get"

import { FAMemo } from "@dbai/ui-staples"

import { actions } from "reducers/notebookReducer"
import { useExecCell } from "components/pages/Workflows/Edit/shared/util"

const RunWorkflowButton = () => {
  const execCell = useExecCell()
  const dispatch = useDispatch()
  const nodes = useSelector(state =>
    get(state?.notebook, "workflow.spec.nodes")
  )

  const onClick = useCallback(() => {
    dispatch(actions.pauseAutosave())
    const indexes = nodes.reduce((pre, n, idx) => ({ [n.id]: idx, ...pre }), {})
    nodes.forEach(node => {
      if (node.type !== "script" && node.type !== "component") return
      node.cells.forEach((cell, idx) => {
        execCell({
          cellType: cell.cellType,
          docValue: cell.source.join(""),
          path: `workflow.spec.nodes[${indexes[node.id]}].cells[${idx}]`,
          cellId: cell.uuid,
          nodeId: node.id,
          handleAutosave: false,
        })
      })
    })
    dispatch(actions.unpauseAutosave())
  }, [dispatch, execCell, nodes])

  return (
    <Tooltip title="Play All Cells">
      <Button
        type="default"
        onClick={onClick}
        icon={<FAMemo icon={faStepForward} />}
      />
    </Tooltip>
  )
}

export default RunWorkflowButton
