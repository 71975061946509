import React from "react"
import { useQuery } from "@apollo/client"
import { Redirect, useParams } from "react-router-dom"

import { GET_CUSTOMER } from "@dbai/ui-staples"

import { useDefaultPath } from "hooks"

const CustomerDefaultPath = props => {
  const { cname } = useParams()
  const { data: customer } = useQuery(GET_CUSTOMER, { variables: { cname } })
  const path = useDefaultPath(customer)

  if (customer) {
    return <Redirect to={`/${cname}/${path}`} />
  }
  return null
}

export default CustomerDefaultPath
