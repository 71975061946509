import React from "react"
import { Space } from "antd"
import { FAMemo } from "@dbai/ui-staples"
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"

import { usePageDetails } from "../utils"

const NO_TITLE_TEXT = "No Title"

const getPrefix = (page, suffix) => {
  if (!Boolean(page) || !suffix) {
    return <FAMemo icon={faExclamationCircle} />
  }
  return null
}

const NavItemTitle = props => {
  const { title, pageId } = props
  const page = usePageDetails({ pageId })
  const suffix = title?.length ? title : page?.name
  const prefix = getPrefix(page, suffix)

  return (
    <Space>
      {prefix}
      {suffix || NO_TITLE_TEXT}
    </Space>
  )
}

export default NavItemTitle
