export default {
  edges: [],
  nodes: [
    {
      x: 400,
      y: 300,
      id: "KMusaZC",
      type: "script",
      cells: [
        {
          uuid: "xdxOYjl",
          source: [
            "# To get started with your notebook you can enter code below.\n",
            '# Execute the code by selecting "Run Cell" or Shift+Enter\n',
            "\n",
            "# To add new nodes in the graph. Hold Shift + Click in the graph.\n",
            "# After adding a node, click on it and select the node type as well as naming the node\n",
            "# To connect nodes, click on the node and drag to the other node\n",
            "\n",
            "# To delete a node in the graph, click on the node and press the backspace/delete key\n",
            "",
          ],
          outputs: [
            {
              output_type: "status",
              execution_state: "idle",
            },
          ],
          metadata: {
            muted: false,
            runTime: 0.003,
            showCode: true,
            showOutput: true,
          },
          cellType: "code",
          executionCount: 2,
        },
        {
          uuid: "ibfmvcX",
          source: [],
          outputs: null,
          metadata: {
            muted: false,
            showCode: true,
            showOutput: true,
          },
          cellType: "code",
          executionCount: 0,
        },
      ],
      color: "#0c2963",
      title: "Start",
      // selectable: true,
      // draggable: true,
      // connectable: true,
      // deletable: true,
      data: {
        label: "Start",
      },
      metadata: {},
      artifacts: [],
      icon: "",
      collapsed: false,
    },
  ],
}
