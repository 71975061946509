import React from "react"
import Dropzone from "react-dropzone-uploader"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons"

const InputContent = () => {
  return (
    <div key="drag-drop" className="upload-input-content">
      Click or Drag/Drop to Upload
      <br />
      <FontAwesomeIcon icon={faCloudUploadAlt} size="3x" />
    </div>
  )
}

const handleSubmit = (_, allFiles) => {
  allFiles.forEach(f => f.remove())
}

const DBDropzone = props => {
  return (
    <Dropzone
      inputWithFilesContent="Add More Files"
      inputContent={InputContent}
      onSubmit={handleSubmit}
      styles={{ dropzone: { minHeight: 250, maxHeight: 250 } }}
      {...props}
    />
  )
}

export default DBDropzone
