import React, { useState, useEffect, useMemo } from "react"
import { get } from "lodash"
import { Button } from "antd"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { alphanumid } from "@dbai/tool-box"
import {
  Card,
  getJwt,
  DBTable,
  RemoteSelect,
  useCurrentCustomer,
} from "@dbai/ui-staples"

import { GET_UPLOADS } from "queries"
import { actions } from "reducers/notebookReducer"
import {
  frames,
  useKernel,
} from "components/pages/Workflows/Edit/shared/kernel"

const SectionCard = styled(Card)`
  margin-bottom: 10px;
`

const StyledRemoteSelect = styled(RemoteSelect)`
  margin-bottom: 0;
`

// downloadsPath is the directory on the Jupyter servers to store any
// downloaded files. Eventually, this will likely be removed as an
// implementation detail of the backend, but is required for the time being.
const downloadsPath = "/home/jovyan/persist/downloads"

const fileToId = f => f.uploadId

const isSetEq = (left, right) => {
  return left.size === right.size && all(isIn(right), left)
}

const isIn = set => k => set.has(k)

const all = (pred, set) => {
  for (let k of set) if (!pred(k)) return false
  return true
}

const DownloadNodeFields = ({ node, prefix, nodeIdx }) => {
  const [customer] = useCurrentCustomer()
  const { kernel } = useKernel()
  const dispatch = useDispatch()

  const files = useSelector(state => get(state?.notebook, prefix("files"), []))
  const fileIDs = useMemo(
    () => new Set(files.map(fileToId).filter(id => !!id)),
    [files]
  )
  const [prevFileIDs, setPrevFileIDs] = useState(fileIDs)
  const [commId, setCommId] = useState(null)

  const cname = customer.normalizedName

  const exec = useMemo(() => {
    if (!kernel) return
    return kernel.channel("shell")[1]
  }, [kernel])

  // Open Comm when the component mounts
  useEffect(() => {
    if (!exec) return

    const openFrame = frames.makeCommOpen({ target: "load_data" })
    exec(openFrame)
    setCommId(openFrame.content.comm_id)

    return () => {
      const closeFrame = frames.makeCommClose({
        commId: openFrame.content.comm_id,
      })
      exec(closeFrame)
    }
  }, [exec, setCommId])

  // Send list of files whenever it changes.
  useEffect(() => {
    if (!commId) return

    if (isSetEq(prevFileIDs, fileIDs)) return
    setPrevFileIDs(fileIDs)

    const data = { files, cname, token: getJwt() }
    exec(frames.makeCommMsg({ commId, data }))
  }, [exec, files, cname, commId, fileIDs, prevFileIDs, setPrevFileIDs])

  const addFile = () => {
    const artifactId = alphanumid()
    dispatch(
      actions.appendAndShare({
        name: prefix("artifacts"),
        value: {
          id: artifactId,
        },
      })
    )

    dispatch(
      actions.appendAndShare({
        name: prefix("files"),
        value: { artifactId, filename: "" },
      })
    )
  }

  const columns = [
    {
      title: "",
      dataIndex: "selectFile",
      render: (_, row, idx) => {
        return (
          <SelectFileFormatter
            idx={idx}
            row={row}
            nodeIdx={nodeIdx}
            cname={cname}
            prefix={prefix}
          />
        )
      },
    },
    {
      title: "",
      key: "deleteFile",
      render: (_, row) => {
        return <DeleteFileFormatter prefix={prefix} row={row} idx={row.id} />
      },
    },
  ]

  return (
    <>
      <SectionCard title="ADD FROM UPLOADS" nopad>
        <Card.Action>
          <Button onClick={addFile} className="btn-icon">
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Card.Action>
        <DBTable
          showHeader={false}
          dataSource={files}
          columns={columns}
          emptyText="Select a file using the + button above"
        />
      </SectionCard>
    </>
  )
}

// updateArtifact will find the Artifact corresponding to the selected Upload
// and update it with an appropriate title and path. Use it like a Redux-Thunk
// by passing it to `dispatch`.
const updateArtifact =
  (id, dispatch, prefix, upload, nodeIdx) => (_, getState) => {
    const artifacts = get(getState(), prefix("artifacts"), [])
    const idx = artifacts.findIndex(a => a.id === id)

    const value = {
      ...artifacts[idx],
      title: upload.originalFilename,
      path: downloadsPath + upload.originalFilename,
    }

    dispatch(
      actions.setAndShare({
        value,
        name: prefix(`artifacts[${Math.max(idx, 0)}]`),
      })
    )
  }

const SelectFileFormatter = props => {
  const { cname, prefix, idx, row, nodeIdx } = props
  const filePrefix = attr => prefix(`files[${idx}].${attr}`)
  const name = filePrefix("uploadId")
  const dispatch = useDispatch()

  const onChange = idx => (value, option) => {
    dispatch(
      actions.setAndShare({
        name: prefix(`files[${idx}].uploadId`),
        value,
      })
    )

    updateArtifact(row.artifactId, dispatch, prefix, option.row, nodeIdx)

    dispatch(
      actions.setAndShare({
        name: filePrefix("filename"),
        value: option.row.originalFilename,
      })
    )
  }

  return (
    <StyledRemoteSelect
      label="FILE"
      name={name}
      collectionName="customer.uploads"
      labelField="originalFilename"
      placeholder="Select an upload..."
      onChange={onChange(idx)}
      query={GET_UPLOADS}
      variables={{ cname }}
    />
  )
}

const DeleteFileFormatter = props => {
  const { prefix, row: file, idx } = props
  const dispatch = useDispatch()

  const removeFile = () => {
    dispatch(actions.removeAndShare({ name: prefix("files"), idx }))
    dispatch(
      actions.removeAndShare({
        name: prefix("artifacts"),
        idx: file.artifactId,
        key: "artifactId",
      })
    )
  }

  return (
    <Button className="btn-icon btn-outline-danger" onClick={removeFile} danger>
      <FontAwesomeIcon icon={faTrashAlt} />
    </Button>
  )
}

export default DownloadNodeFields
