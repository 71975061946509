import React, { useMemo } from "react"
import { useParams } from "react-router"
import { useSelector } from "react-redux"

import { GET_WORKFLOW_COMMENTS } from "queries"
import Comments from "components/shared/Comments"
import { selectCurrentCustomerNormalizedName } from "selectors"

const CommentsPanel = props => {
  const { panelOpen, closePanel } = props
  const cname = useSelector(selectCurrentCustomerNormalizedName)
  const { id } = useParams()
  const variables = useMemo(
    () => ({
      id,
      cname,
    }),
    [cname, id]
  )

  return (
    <>
      <Comments
        open={panelOpen}
        variables={variables}
        closePanel={closePanel}
        query={GET_WORKFLOW_COMMENTS}
      />
    </>
  )
}

export default CommentsPanel
