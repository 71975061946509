import { createSlice } from "@reduxjs/toolkit"

import { createCommonActions } from "@dbai/tool-box"

const initialState = {
  selectedNodeId: null,
  name: "",
  type: "",
}

const inputSlice = createSlice({
  initialState,
  name: "input",
  reducers: {
    setSelectedNodeId: (draft, { payload }) => {
      draft.selectedNodeId = payload
    },
    resetForm: draft => {
      draft.name = initialState.name
      draft.type = initialState.type
    },
    ...createCommonActions(initialState),
  },
})

export const { name, reducer, actions } = inputSlice
export default inputSlice
