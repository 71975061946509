import { flow } from "lodash"

const checkSchemaIsValid = options => {
  return !!options.type
}

/**
 * ideally, we should be able to allow multiple form fields to have value dependenceis
 * with the same form field. However, due to limitation with the json-options-merge-allof
 * library, we can only allow one form field to have value dependencies with another.
 */
const getValueConditions = (
  buildSchema,
  appVariables,
  options,
  schemaKey,
  { dependentField, dependencyValues = [] },
  dependencies
) => {
  if (!dependencyValues.length || !dependentField) return []
  const existingDependencySchema = dependencies[dependentField] || {}
  const safeDependencyValues = dependencyValues.reduce((acc, value) => {
    if (isNaN(value)) {
      acc.push(value)
    } else {
      acc.push(Number(value))
    }
    return acc
  }, [])
  const { required, ...restOptions } = options
  return {
    [dependentField]: {
      allOf: [
        ...(existingDependencySchema.allOf || []),
        {
          if: {
            properties: {
              [dependentField]: {
                enum: safeDependencyValues,
              },
            },
          },
          then: {
            ...(required ? { required: [schemaKey] } : {}),
            properties: {
              [schemaKey]: buildSchema(restOptions, appVariables),
            },
          },
        },
      ],
    },
  }
}

const getDependencyExistsSchema = (
  buildSchema,
  appVariables,
  options,
  schemaKey,
  { dependentField, dependencyValues = [] },
  dependencies
) => {
  if (!dependentField) return {}
  const existingDependencySchema = dependencies[dependentField] || {}
  const { required, ...restOptions } = options
  return {
    [dependentField]: {
      allOf: [
        ...(existingDependencySchema.allOf || []),
        {
          if: {
            not: {
              properties: {
                [dependentField]: {
                  enum: ["", false, [], {}],
                },
              },
            },
          },
          then: {
            ...(required ? { required: [schemaKey] } : {}),
            properties: {
              [schemaKey]: buildSchema(restOptions, appVariables),
            },
          },
        },
      ],
    },
  }
}

const getDependencies = (properties, appVariables, buildSchema) => {
  return properties.reduce((acc, field) => {
    const { dependencies = [], ...restField } = field
    const { key, title } = restField
    if (!checkSchemaIsValid(field) || !dependencies.length) return acc
    dependencies.forEach(dep => {
      if (dep.condition === "hasValue") {
        acc = {
          ...acc,
          ...getValueConditions(
            buildSchema,
            appVariables,
            restField,
            key || title,
            dep,
            acc
          ),
        }
        return
      }
      acc = {
        ...acc,
        ...getDependencyExistsSchema(
          buildSchema,
          appVariables,
          restField,
          key || title,
          dep,
          acc
        ),
      }
    }, {})
    return acc
  }, {})
}

const processOrder = options => {
  if (!options.properties) return options
  const { metadata = {}, ...restSchema } = options
  return {
    ...restSchema,
    metadata: {
      ...metadata,
      order: options.properties.reduce((acc, field) => {
        const { key } = field
        if (!checkSchemaIsValid(field)) return acc
        return [...acc, key]
      }, []),
    },
  }
}

const processRequired = options => {
  if (!options.properties) return options

  const required = options.properties.reduce((acc, property) => {
    if (property.required && !property.dependencies?.length) {
      return [...acc, property.key]
    }
    return acc
  }, [])
  return required.length ? { ...options, required } : options
}

const processSections = options => {
  const { sections } = options.metadata || {}
  if (!sections) return options

  const safeSections = sections.reduce((acc, section) => {
    const { title, key, properties, ...restSection } = section
    if (!key || !title || !properties) return acc
    return {
      ...acc,
      [key]: {
        title,
        properties,
        ...restSection,
      },
    }
  }, {})

  // if there are no safe sections, return the options without sections
  if (!Object.keys(safeSections).length) {
    return options
  }
  return {
    ...options,
    metadata: {
      ...options.metadata,
      sections: safeSections,
    },
  }
}

const processLayout = options => {
  const {
    layout,
    metadata = {},
    verticalGutter,
    horizontalGutter,
    ...rest
  } = options
  if (
    layout !== "grid" ||
    [null, undefined].includes(verticalGutter) ||
    [null, undefined].includes(horizontalGutter)
  ) {
    return options
  }
  const gutter = [horizontalGutter, verticalGutter]
  return {
    ...rest,
    metadata: {
      ...metadata,
      layout: { gutter },
    },
  }
}

const processProperties = (appVariables, buildSchema) => {
  return options => {
    if (!options.properties) return options
    return {
      ...options,
      properties: options.properties.reduce((acc, field) => {
        // do not add field if it has a dependency or if it does not have a valid key or title
        const { dependencies = [], required, key, ...restField } = field
        if (!checkSchemaIsValid(field) || dependencies.length) return acc
        const schemaKey = key || field.title || "Unnamed Field"
        return {
          ...acc,
          [schemaKey]: buildSchema(restField, appVariables),
        }
      }, {}),
    }
  }
}

const processDependencies = (appVariables, buildSchema) => {
  return options => {
    const { dependencies: dependenciesSchema, ...restSchema } = options
    if (!options.properties) return restSchema
    const dependencies = getDependencies(
      options.properties,
      appVariables,
      buildSchema
    )
    return {
      ...restSchema,
      ...(Object.keys(dependencies).length ? { dependencies } : {}),
    }
  }
}

export default (options, appVariables, buildSchema) =>
  flow([
    processOrder,
    processRequired,
    processSections,
    processLayout,
    processDependencies(appVariables, buildSchema),
    processProperties(appVariables, buildSchema),
  ])(options)
