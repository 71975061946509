import { cellStatusTypes } from "constants/index"

export const determineNodeStatus = (
  node,
  cellStatuses,
  erroredNodeIds = {}
) => {
  if (erroredNodeIds[node?.id]) return cellStatusTypes.error

  const cellIds = node?.cells?.map(cell => cell.uuid) || []
  const statuses = cellIds.map(uuid => cellStatuses[uuid])
  if (statuses.includes(cellStatusTypes.error)) return cellStatusTypes.error
  if (statuses.includes(cellStatusTypes.loading)) return cellStatusTypes.loading
  if (statuses.includes(cellStatusTypes.waiting)) return cellStatusTypes.waiting
  return cellStatusTypes.ok
}

// Clean outputs and other artifacts off of a script node to make it suitable
// to copy.
export const cleanScript = ({ x, y, id, title, outputs, ...state }) => ({
  ...state,

  cells: (state.cells || []).map((cell = {}) => {
    const { executionCount, ...rest } = cell
    return {
      ...rest,
      metadata: {
        ...(rest.metadata || {}),
        showCode: true,
        showOutput: true,
      },
      outputs: [],
    }
  }),
})
