import React, { useState } from "react"
import { Tooltip } from "antd"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { toast } from "react-toastify"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"

import { Dropout, Card, Modal, useDropout } from "@dbai/ui-staples"

import { actions } from "reducers/instanceReducer"

const InstanceCard = styled(Card)`
  margin: 10px 0 0 0;
  width: 100%;

  border-left-color: ${props => borderColor[props.state] || "gray"};
  border-left-width: 5px;

  .card-body {
    padding-top: 10px;
  }
`

const InstanceHeaderFlow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const InstanceName = styled.h6`
  text-transform: none;
  margin-bottom: 0;
`

const ResourceFlow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 0 0;

  .progress {
    width: 75%;
  }
`

const Progress = styled.div`
  flex-grow: 1;
  margin: 0 10px;
`

const Segment = styled.div`
  width: ${props => props.width}%;
`

const More = styled.div`
  position: relative;
  cursor: pointer;
  width: 15px;
  text-align: center;
`

const ConfirmationButtons = styled.div`
  width: 100%;
`

const percentToClass = p => {
  if (p > 90) return "bg-danger"
  if (p > 75) return "bg-warning"
  return ""
}

const getPercentage = (allocatable, capacity) =>
  (1 - parseInt(allocatable) / parseInt(capacity)) * 100 || 0

// These colors are setup to match Twitter Bootstrap styles.
const borderColor = {
  pending: "#ffc107", // "warning"
  running: "#5eba00", // "success"
  "shutting-down": "#ffc107", // "warning"
  stopping: "#ffc107", // "warning"
  stopped: "#60686f", // "secondary"
}

const stateToBadgeClass = {
  pending: "badge-warning",
  running: "badge-success",
  stopping: "badge-warning",
  stopped: "badge-secondary",
  terminated: "badge-danger",
  "shutting-down": "badge-danger",
}

const help =
  "This graphic shows the amount of a resource that has been" +
  "dedicated to workloads, but not necessarily what that workload is actively" +
  "consuming"

const ResourceUsage = props => {
  const { percent, name } = props

  return (
    <ResourceFlow>
      <span>{name}</span>
      <Progress className="progress">
        <Tooltip title={`${Math.round(percent)}% ${name} allocated`}>
          <Segment
            className={`progress-bar ${percentToClass(percent)}`}
            width={percent}
          />
        </Tooltip>
      </Progress>
      <Tooltip title={help}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </Tooltip>
    </ResourceFlow>
  )
}

export default props => {
  const dispatch = useDispatch()
  const dropout = useDropout({
    direction: "down left",
    offsetX: "-9px",
    offsetY: "7px",
  })

  const [modalOpen, setModalOpen] = useState(false)
  const { customer, instance } = props
  const { capacity, allocatable } = instance.resources

  const badgeClass = stateToBadgeClass[instance.state] || "badge-secondary"
  const cpuPercent = getPercentage(allocatable.cpu, capacity.cpu)
  const memPercent = getPercentage(allocatable.memory, capacity.memory)
  const variables = { customerId: customer.id, instanceId: instance.id }

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const stop = () => {
    dropout.close()
    dispatch(actions.stopInstance(variables))
      .then(() => toast.info("Stopping Instance"))
      .catch(() => toast.error("Error Stopping Instance"))
  }

  const start = () => {
    dropout.close()
    dispatch(actions.startInstance(variables))
      .then(() => toast.info("Starting Instance"))
      .catch(() => toast.error("Error Starting Instance"))
  }

  const terminate = () => {
    dropout.close()
    closeModal()
    dispatch(actions.deleteInstance(variables))
      .then(() => toast.info("Deleting Instance"))
      .catch(() => toast.error("Error Deleting Instance"))
  }

  return (
    <>
      <InstanceCard key={instance.id} state={instance.state}>
        <InstanceHeaderFlow>
          <InstanceName>{instance.name}</InstanceName>
          <More ref={dropout.trigger} onClick={dropout.toggle}>
            <FontAwesomeIcon icon={faEllipsisV} />
            <Dropout {...dropout.props}>
              <Dropout.Item onClick={start}>Start Instance</Dropout.Item>
              <Dropout.Item onClick={stop}>Stop Instance</Dropout.Item>
              <Dropout.Item onClick={openModal}>Delete Instance</Dropout.Item>
            </Dropout>
          </More>
        </InstanceHeaderFlow>
        <span className="badge bg-secondary">{instance.id}</span>{" "}
        <span className="badge bg-secondary">{instance.type}</span>{" "}
        <span className="badge bg-secondary">{instance.region}</span>{" "}
        <span className={`badge ${badgeClass}`}>{instance.state}</span>
        <ResourceUsage name="CPU" help="CPU Allocation" percent={cpuPercent} />
        <ResourceUsage name="RAM" help="RAM Allocation" percent={memPercent} />
      </InstanceCard>
      <Modal isOpen={modalOpen} onRequestClose={closeModal}>
        <h4>Confirm Delete</h4>
        <p>Are you sure you want to delete Instance ID {instance.id}?</p>
        <ConfirmationButtons className="btn-group">
          <button type="button" className="btn btn-danger" onClick={terminate}>
            Delete
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={closeModal}
          >
            Cancel
          </button>
        </ConfirmationButtons>
      </Modal>
    </>
  )
}
