import React from "react"

import { withFormFieldWrapper } from "../FormFieldWrapper"

const GenericSchemaRenderCore = props => {
  const { schema, value } = props
  const { metadata } = schema
  const { render } = metadata

  return <div>{render(value)}</div>
}

const GenericSchemaRender = withFormFieldWrapper(GenericSchemaRenderCore)
GenericSchemaRender.Core = GenericSchemaRenderCore
export default GenericSchemaRender
