import React, { useCallback } from "react"
import { useMutation } from "@apollo/client"
import { useParams } from "react-router-dom"
import { Button } from "antd"

import { UPDATE_WIDGET_TYPE } from "@dbai/applet"

const SaveButton = props => {
  const { showSave, widget, onSave } = props
  const { id, cname } = useParams()
  const [updateWidgetType] = useMutation(UPDATE_WIDGET_TYPE)
  const handleSave = useCallback(() => {
    const widgetType = widget
    updateWidgetType({
      variables: {
        id,
        cname,
        input: {
          name: widgetType.name,
          source: widgetType.source,
          schema: widgetType.schema,
          description: widgetType.description,
        },
      },
    }).then(() => onSave && onSave())
  }, [widget, id, cname, onSave, updateWidgetType])
  if (!showSave) return null
  return (
    <Button type="primary" onClick={handleSave}>
      Save and Publish Widget
    </Button>
  )
}

export default SaveButton
