import React from "react"
import styled from "styled-components"
import { faTerminal } from "@fortawesome/free-solid-svg-icons"

import { FAMemo } from "@dbai/ui-staples"

import ActionColorPicker from "components/shared/ActionColorPicker"

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  float: left;
  width: 45px;
  border-radius: 5px;
  height: 45px;
  background-color: ${props => props.color};
  color: white;
`

const ActionIcon = props => {
  const { color } = props
  return (
    <StyledIcon color={color || "#aaa"}>
      <FAMemo icon={faTerminal} size="lg" />
    </StyledIcon>
  )
}

const ColorWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

const ColorPickerWrapper = styled.div`
  border: solid 5px #dddddd;
  border-radius: 5px;
`

const ColorPicker = props => {
  const { onChange, color } = props

  return (
    <ColorWrapper>
      <ActionIcon color={color} />
      <ColorPickerWrapper>
        <ActionColorPicker size={"35px"} onChange={onChange} color={color} />
      </ColorPickerWrapper>
    </ColorWrapper>
  )
}

export default ColorPicker
