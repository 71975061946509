import Highcharts from "highcharts"
import boost from "highcharts/modules/boost"
import heatmap from "highcharts/modules/heatmap"
import solidgauge from "highcharts/modules/solid-gauge"
import coloraxis from "highcharts/modules/coloraxis"
import exporting from "highcharts/modules/exporting"
import exportingData from "highcharts/modules/export-data"
import highchartsMore from "highcharts/highcharts-more"
import noDataToDisplay from "highcharts/modules/no-data-to-display"
import customEvents from "highcharts-custom-events"

const DBChart = Highcharts

highchartsMore(DBChart)
coloraxis(DBChart)
noDataToDisplay(DBChart)
boost(DBChart)
heatmap(DBChart)
solidgauge(DBChart)
exporting(DBChart)
exportingData(DBChart)
customEvents(DBChart)

// Workaround for https://github.com/highcharts/highcharts/issues/13710
DBChart.seriesTypes.pie.prototype.drawEmpty = function () {
  var centerX,
    centerY,
    start = this.startAngleRad,
    end = this.endAngleRad,
    options = this.options
  // Draw auxiliary graph if there're no visible points.
  if (this.total === 0) {
    centerX = this.center[0]
    centerY = this.center[1]
    if (!this.graph) {
      this.graph = this.chart.renderer
        .arc(centerX, centerY, this.center[1] / 2, 0, start, end)
        .addClass("highcharts-empty-series")
        .add(this.group)
    }
    this.graph.attr({
      d: DBChart.SVGRenderer.prototype.symbols.arc(
        centerX,
        centerY,
        this.center[2] / 2,
        0,
        {
          start: start,
          end: end,
          innerR: this.center[3] / 2,
        }
      ),
    })
    if (!this.chart.styledMode) {
      this.graph.attr({
        "stroke-width": options.borderWidth,
        fill: options.fillColor || "none",
        stroke: options.color || "#cccccc",
      })
    }
  } else if (this.graph) {
    // Destroy the graph object.
    this.graph = this.graph.destroy()
  }
}

export default DBChart
