import { noop, debounce } from "lodash"
import { useEffect } from "react"
import { resizeObserverFactory } from "@dbai/tool-box"

const useResizeObserver = (element, onResize = noop) => {
  useEffect(() => {
    if (!element.current) return
    const observer = resizeObserverFactory(debounce(onResize))
    observer.observe(element.current)

    return () => {
      observer.disconnect()
    }
  }, [element, onResize])
}

export default useResizeObserver
