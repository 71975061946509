import styled from "styled-components"
import { Collapse } from "antd"

const StyledCollapse = styled(Collapse)`
  width: 100%;
  border-radius: 0;

  && {
    .ant-collapse-item > .ant-collapse-header,
    .ant-collapse-item > .ant-collapse-header[aria-expanded="true"] {
      background: ${props => {
        return props.theme?.colorBorderSecondary
      }};
    }
    .ant-collapse-item {
      border-radius: 0;
    }
    .ant-collapse-header {
      font-weight: bold;
      align-items: center;
      padding: 8px 16px;
    }
    .ant-collapse-header[aria-expanded="true"] {
      top: 0;
      z-index: 100;
      position: sticky;
      overflow: overlay;
      border-radius: 0px;
      ${props => {
        if (props.theme.colorFillAlter) {
          return `background: ${props.theme.colorFillAlter}`
        }
      }};
    }
    .ant-collapse-content-box {
      padding: 16px 16px 8px 16px;
    }
  }
`

export { StyledCollapse as Collapse }
