import { createSlice } from "@reduxjs/toolkit"

import {
  loadResource,
  createCommonActions,
  createResourceActions,
} from "@dbai/tool-box"

import client from "apolloClient"
import { WORKFLOW_RESOURCE_USAGE } from "queries"

const initialState = {
  data: [],
  loading: false,
  error: null,
}

const workflowNotebookResourceUsageSlice = createSlice({
  name: "workflowNotebookResourceUsage",
  initialState,
  reducers: {
    ...createResourceActions({ initialState }),
    ...createCommonActions(initialState),
  },
})

const { actions } = workflowNotebookResourceUsageSlice

const loadWorkflowResourceUsage = variables =>
  loadResource({
    fetchFn: () =>
      client
        .query({
          query: WORKFLOW_RESOURCE_USAGE,
          variables,
          fetchPolicy: "network-only",
        })
        .then(({ data }) => data?.workflowNotebookResourceUsage),
    actions,
  })

const allActions = {
  loadWorkflowResourceUsage,
  ...actions,
}

export { allActions as actions }
export default workflowNotebookResourceUsageSlice
