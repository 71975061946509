import React, { forwardRef } from "react"
import styled from "styled-components"
import { Tooltip } from "antd"

const Letter = styled.span`
  background-color: #9cadb5;
  color: #fff;
  font-size: 2rem;

  display: inline-block;
  line-height: 45px;
  width: 45px;
  height: 45px;
  border-radius: 5px;

  cursor: pointer;
`

const BigLetter = forwardRef((props, ref) => {
  const { word, ...rest } = props

  return (
    <Tooltip title={word.toUpperCase()[0] || ""}>
      <Letter {...rest} ref={ref}>
        {word.toUpperCase()[0] || ""}
      </Letter>
    </Tooltip>
  )
})

export default BigLetter
