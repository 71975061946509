import { theme } from "antd"

const { darkAlgorithm, compactAlgorithm } = theme

const getThemeAlgorithm = (mode, compact) => {
  const algo = []
  if (mode === "dark") {
    algo.push(darkAlgorithm)
  }
  if (compact) {
    algo.push(compactAlgorithm)
  }
  return algo
}

const getThemeColors = (mode, lightModeColors, darkModeColors) => {
  if (mode === "dark") {
    return darkModeColors || {}
  }
  return lightModeColors || {}
}

const getTheme = themeOptions => {
  if (!themeOptions) return {}
  const {
    mode,
    token: _token,
    compact,
    lightModeColors,
    darkModeColors,
    textFontFamily,
    ...rest
  } = themeOptions
  if (!darkModeColors && !lightModeColors && !_token) return {}
  const colors = getThemeColors(mode, lightModeColors, darkModeColors)
  return {
    themeMode: mode,
    algorithm: getThemeAlgorithm(mode, compact),
    token: { ...colors, ..._token, colorBgLayout: colors.colorBgBase },
    ...rest,
  }
}

export { getTheme, getThemeColors }
