import { curry } from "lodash"

import { GET_ME } from "@dbai/ui-staples"

import client from "apolloClient"

/*
 * Estimate the height of the given `source` when rendered.
 */
const fontSizes = {
  small: 13.5,
  medium: 18,
  large: 22.5,
}

const textSourcePadding = 8
const calculateSourceHeight =
  (textHeight = fontSizes.medium) =>
  source => {
    const textSource = Array.isArray(source) ? source[0] : source
    if (!textSource) return textHeight
    return textSource.split("\n").length * textHeight
  }

const reduceHeight = curry((calculator, currentHeight, data) => {
  return currentHeight + calculator(data)
})

const calculateCellPlaceholderHeight = cell => {
  const fontSize =
    client.readQuery({ query: GET_ME })?.me?.preferences?.fontSize || "medium"
  const sourcePadding = cell.source.length * textSourcePadding
  const sourceHeight = cell.source.reduce(
    reduceHeight(calculateSourceHeight(fontSizes[fontSize])),
    sourcePadding
  )

  return sourceHeight
}

export default calculateCellPlaceholderHeight
