import React, { useMemo } from "react"

import { useTagBuster } from "./useTagBuster"
import TagsFormatter from "components/shared/TagsFormatter"

export const useTagColumn = ({ type, tagsFor, query, ...rest }) => {
  const handleTagUpdate = useTagBuster(query)
  return useMemo(() => {
    const tagsForOrDefault = tagsFor || type
    return {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: 250,
      render: (tags, row) => {
        return (
          <TagsFormatter
            row={row}
            type={type}
            value={tags}
            tagsFor={tagsForOrDefault}
            onUpdate={handleTagUpdate}
          />
        )
      },
      ...rest,
    }
  }, [handleTagUpdate, rest, tagsFor, type])
}
