import { useContext, useMemo } from "react"
import { get } from "lodash"

import FormContext from "./FormContext"
import { formSet, formAppend, formRemove, formClear } from "./actions"

export const useFormReducer = () => {
  return useContext(FormContext) || [null, null, {}]
}

export const useFormState = (path, def) => {
  const [state] = useFormReducer()
  if (path) {
    return get(state, path, def)
  }
  return state
}

export const useFormDispatch = () => {
  const [, dispatch] = useFormReducer()
  return dispatch
}

export const useFormMeta = () => {
  const [, , meta] = useFormReducer()
  return meta
}

export const useFormSet = () => {
  const [, dispatch, { actions }] = useFormReducer()
  if (actions && actions.set) {
    return (...args) => {
      dispatch(actions.set(...args))
    }
  }

  return (...args) => formSet(dispatch, ...args)
}

export const useFormClear = () => {
  const [, dispatch, { actions }] = useFormReducer()
  if (actions && actions.clear) {
    return (...args) => {
      dispatch(actions.clear(...args))
    }
  }

  return (...args) => formClear(dispatch, ...args)
}

export const useFormRemove = () => {
  const [, dispatch, { actions }] = useFormReducer()
  if (actions && actions.remove) {
    return (...args) => {
      dispatch(actions.remove(...args))
    }
  }

  return (...args) => formRemove(dispatch, ...args)
}

export const useFormAppend = () => {
  const [, dispatch, { actions }] = useFormReducer()
  if (actions && actions.append) {
    return (...args) => {
      dispatch(actions.append(...args))
    }
  }

  return (...args) => formAppend(dispatch, ...args)
}

export const useFormSubmit = () => {
  const [, , { submit }] = useFormReducer()
  return submit
}

export const useFormActions = () => {
  const formSet = useFormSet()
  const formAppend = useFormAppend()
  const formRemove = useFormRemove()

  return useMemo(
    () => ({ formSet, formAppend, formRemove, formClear }),
    [formSet, formAppend, formRemove]
  )
}
