import datasetColumnSchema from "../datasetColumnSchema"

const gaugeSchema = {
  type: "object",
  title: "Data",
  default: {},
  properties: {
    inputZones: {
      title: "Data",
      metadata: {
        schemaName: "dependencies.datasetId.properties.inputZones",
        component: "ColumnInputZones",
        order: ["yAxisData"],
        style: {
          height: "calc(100vh - 259px)",
        },
      },
      type: "object",
      default: {},
      properties: {
        yAxisData: {
          minItems: 1,
          type: "array",
          maxItems: 500,
          title: "Show",
          uniqueItems: true,
          default: [],
          items: {
            default: {},
            type: "object",
            required: ["column", "aggregate"],
            title: "Dataset Column",
            metadata: {
              order: ["type", "column", "aggregate"],
              layout: { gutter: [16] },
              labelPath: "column",
            },
            dependencies: datasetColumnSchema.dependencies,
            properties: {
              column: {
                title: "Column",
                type: "string",
                metadata: {
                  validateFirst: true,
                  allowedTypes: ["numerical", "float", "int"],
                  component: "ColumnSelect",
                  fieldProps: {
                    popupMatchSelectWidth: false,
                  },
                },
              },
              type: {
                $ref: "#/$defs/dataColumnType",
                metadata: { hidden: true },
              },
              id: {
                title: "ID",
                type: "string",
                metadata: {
                  hidden: true,
                  defaultValueSource: "uid",
                },
              },
            },
          },
          metadata: {
            noStyle: true,
            layout: { gutter: [] },
            component: "DnDDropzone",
            allowedTypes: ["numerical", "float", "int"],
            fieldProps: {
              style: { minHeight: "100px" },
            },
          },
        },
      },
    },
    highchart: {
      default: {},
      title: "Chart Options",
      type: "object",
      metadata: {
        sections: {
          yAxis: {
            title: "Gauge Options",
            properties: [
              "plotOptions/solidgauge/dataLabels/style/fontSize",
              "plotOptions/solidgauge/dataLabels/y",
              "yAxis/title/text",
              "yAxis/title/y",
              "yAxis/title/style/fontSize",
              "yAxis/min",
              "yAxis/max",
            ],
          },
        },
      },
      properties: {
        "plotOptions/solidgauge/dataLabels/style/fontSize": {
          default: 24,
          title: "Metric Font Size",
          type: "number",
        },
        "plotOptions/solidgauge/dataLabels/y": {
          default: -40,
          title: "Metric Y Position",
          type: "number",
        },
        "plotOptions/solidgauge/dataLabels/borderWidth": {
          default: 0,
          type: "number",
          metadata: {
            hidden: true,
          },
        },
        "yAxis/title/text": {
          title: "Title",
          type: "string",
        },
        "yAxis/title/style/fontSize": {
          title: "Title Font Size",
          type: "number",
          default: 16,
        },
        "yAxis/title/y": {
          type: "number",
          default: -100,
          title: "Title Y Position",
        },
        "yAxis/min": {
          title: "Min",
          default: 0,
          type: "number",
          description:
            "The minimum value for the gauge. This will show in the label on the left side",
        },
        "yAxis/max": {
          title: "Max",
          type: "number",
          description:
            "The maximum value for the gauge. This will show in the label on the right side.  If the value exceeds the maximum it will show the gauge as full",
        },
        "yAxis/lineWidth": {
          type: "number",
          default: 0,
          metadata: {
            hidden: true,
          },
        },
        "yAxis/tickWidth": {
          type: "number",
          default: 0,
          metadata: {
            hidden: true,
          },
        },
        "yAxis/minorTickInterval": {
          type: "number",
          default: 0,
          metadata: {
            hidden: true,
          },
        },
        "yAxis/tickAmount": {
          type: "number",
          default: 0,
          metadata: {
            hidden: true,
          },
        },
        "yAxis/endOnTick": {
          type: "boolean",
          default: false,
          metadata: {
            hidden: true,
          },
        },
        "yAxis/labels/y": {
          type: "number",
          default: 16,
          metadata: {
            hidden: true,
          },
        },
        "tooltip/enabled": {
          type: "boolean",
          default: false,
          metadata: {
            hidden: true,
          },
        },
      },
    },
  },
}

export default gaugeSchema
