import { flow } from "lodash"
import dayjs from "dayjs"

import { getDateRange } from "./datetime"

const formatGenerator = formatSpec => {
  return flow([
    numberFormatGenerator(formatSpec),
    stringFormatGenerator(formatSpec),
  ])
}

const numberFormatGenerator = formatSpec => {
  return value => {
    const { label, ...formatOptions } = formatSpec
    return new Intl.NumberFormat("en-US", formatOptions).format(
      parseFloat(value)
    )
  }
}

const stringFormatGenerator = formatSpec => {
  return value => {
    const { suffix, prefix } = formatSpec
    return `${prefix || ""}${value}${suffix || ""}`
  }
}

const getFormattingStyle = formatOptions => {
  const { format, unit } = formatOptions
  if (["percent", "currency"].includes(format)) return format
  if (Boolean(unit)) return "unit"
  return "decimal"
}

const getFormattingOptions = formatOptions => {
  const { suffix, prefix, minPrecision, maxPrecision, ...restOptions } =
    formatOptions
  const style = getFormattingStyle(formatOptions)
  return {
    ...restOptions,
    style,
    suffix,
    prefix,
    minimumFractionDigits:
      !minPrecision || minPrecision > maxPrecision ? 0 : minPrecision,
    maximumFractionDigits: maxPrecision,
  }
}

const requiredDatetimeOptions = {
  timeZone: "UTC",
}
const defaultDatetimeOptions = {
  dateStyle: "short",
  timeStyle: "medium",
  ...requiredDatetimeOptions,
}

export const getSafeDatetimeOptions = options => {
  if (!options) return defaultDatetimeOptions
  return Object.entries(options).reduce((acc, [key, value]) => {
    if (value === "none") return acc
    return { ...acc, [key]: value }
  }, requiredDatetimeOptions)
}

export const datetimeFormatter = opts => value => {
  const { formatRange, groupByTime } = opts
  const formatter = new Intl.DateTimeFormat("en", getSafeDatetimeOptions(opts))
  let formattedValue = value
  try {
    // const date = new Date(value)
    if (formatRange) {
      const [start, end] = getDateRange(groupByTime, value)
      const startDate = new Date(start)
      const endDate = new Date(end)
      formattedValue = formatter.formatRange(startDate, endDate)
      formattedValue = formatter.format(start)
    } else {
      const date = new Date(value)
      formattedValue = formatter.format(date)
    }
  } catch (e) {
    // ignore if the datetime value cannot be properly formatted. this is usually caused by the value not being a valid datetime string
  }
  return stringFormatGenerator(opts)(formattedValue)
}

export const getFormatter = formatOptions => {
  const { format = "string", ...restOptions } = formatOptions
  switch (true) {
    case format === "datetime":
      return datetimeFormatter(restOptions)
    case ["string", "imperial"].includes(format):
      return stringFormatGenerator(formatOptions)
    case ["number", "percent"].includes(format):
      return formatGenerator(getFormattingOptions(formatOptions))
    case format === "currency":
      const currencyFormatOpts = {
        currency: "USD",
        ...getFormattingOptions(formatOptions),
      }
      return formatGenerator(currencyFormatOpts)
    default:
      return val => val
  }
}

export const defaultDtFormatter = (metadata, value) => {
  if (!value) return null
  const {
    showTime,
    timeOptions,
    dateFormatPattern,
    dateFormat = "MM/DD/YYYY",
  } = metadata
  if (showTime && timeOptions?.format) {
    const defaultFormat = `${dateFormat}  ${timeOptions.format}`
    const format = dateFormat === "custom" ? dateFormatPattern : defaultFormat
    return format ? dayjs(value).format(format) : dayjs(value).format()
  }
  const format = dateFormat === "custom" ? dateFormatPattern : dateFormat
  return format ? dayjs(value).format(format) : dayjs(value).format()
}

export const formatValue = (options, value) => {
  let formattedValue = value
  try {
    const formatter = getFormatter(options)
    formattedValue = formatter(value)
  } catch (e) {
    // ignore if the value cannot be properly formatted. this is usually caused by the value not being a valid number string
  }
  return formattedValue
}
