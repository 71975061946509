import React from "react"
import numeral from "numeral"
import PropTypes from "prop-types"

const formatValue = (v, p) => {
  return numeral(v).format(`0,0[.]${"0".repeat(p)}`)
}

const NumberFormatter = props => {
  const { precision = 2, value } = props

  return <div>{formatValue(value, precision)}</div>
}

NumberFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number]),
  precision: PropTypes.number,
}

export default NumberFormatter
