import {
  findColumnConfig,
  findIndexForColumn,
  findColumnLabelName,
} from "./datasetColumns"

const getComputationFunction = {
  "+": (a, b) => a + b,
  "-": (a, b) => a - b,
  "/": (a, b) => a / b,
  "%": (a, b) => a % b,
  "*": (a, b) => a * b,
  default: (_, b) => b,
}

const isCategoricalColumn = columnConfig => columnConfig.type === "string"

const getDatasetColumnValue = (datasetColumns, columnConfig, row) => {
  const { column, aggregate } = columnConfig
  const index = findIndexForColumn(columnConfig, datasetColumns)

  const getTableColumnValue = (column, aggregate) => {
    if (isCategoricalColumn(columnConfig)) {
      const safeColumnConfig = findColumnConfig(columnConfig, datasetColumns)
      const safeLabelName = findColumnLabelName(safeColumnConfig, row[index])
      return safeLabelName
    }
    return index >= 0 ? row[index] : null
  }

  return getTableColumnValue(column, aggregate)
}

// converts computation data into Reverse Polish Notation (RPN)
const getComputationToken = (
  computations,
  appVariables,
  datasetColumns,
  row
) => {
  let tokens = []
  computations.forEach((exp, index) => {
    const { column, propertyType, appVariableId, computation, aggregate } = exp
    // If this is a subExpression, recursively add the tokens
    if (propertyType === "subExpression") {
      const subTokens = getComputationToken(
        computation,
        appVariables,
        datasetColumns,
        row
      )
      index !== 0 && tokens.push(exp.op)
      tokens.push(subTokens)
      return
    }

    const getValue = () => {
      if (propertyType === "datasetColumn" && !column) return null
      if (propertyType === "appVariable" && !appVariableId) return null
      if (propertyType === "appVariable") {
        const appVariable = appVariables?.find(v => v.id === appVariableId)
        return parseFloat(appVariable?.value) || 0
      }
      if (propertyType === "staticValue") {
        return exp.value
      }
      return getDatasetColumnValue(datasetColumns, { column, aggregate }, row)
    }

    const value = getValue()
    index !== 0 && tokens.push(exp.op)
    tokens.push(value)
  })
  return tokens
}

const precedence = {
  "+": 1,
  "-": 1,
  "*": 2,
  "/": 2,
}

const evaluateRPN = queue => {
  const computationStack = []
  // iterate through each token
  for (const token of queue) {
    if (typeof token === "number" || !token) {
      // push numbers onto the stack
      computationStack.push(token)
    } else {
      // for operators, pop two values, compute, and push result back
      const val2 = computationStack.pop()
      const val1 = computationStack.pop()
      if (val1 !== null && val2 !== null) {
        computationStack.push(getComputationFunction[token](val1, val2))
      } else {
        // if any of the computation values are null, then the result is null
        computationStack.push(null)
      }
    }
  }

  // return the result of the computation
  return computationStack[0]
}

// algo that transforms a mathematical expression from infix notation into a reversed polish notation (postfix)
const shuntingYard = tokens => {
  const outputQueue = []
  const operatorStack = []

  for (const token of tokens) {
    if (Array.isArray(token)) {
      // If token is a sub-expression, process it recursively
      const subExpressionResult = shuntingYard(token)
      outputQueue.push(subExpressionResult)
    } else if (typeof token === "number") {
      outputQueue.push(token)
    } else {
      while (
        operatorStack.length &&
        precedence[operatorStack[operatorStack.length - 1]] >= precedence[token]
      ) {
        outputQueue.push(operatorStack.pop())
      }
      operatorStack.push(token)
    }
  }

  // pop all remaining operators from the stack to the queue
  while (operatorStack.length) {
    outputQueue.push(operatorStack.pop())
  }

  return evaluateRPN(outputQueue)
}

const getComputedColumnValue = (
  datasetColumns,
  computations,
  row,
  appVariables
) => {
  const computationToken = getComputationToken(
    computations,
    appVariables,
    datasetColumns,
    row
  )
  return shuntingYard(computationToken)
}

const getRowValue = (datasetColumns, row, tableColumn, parentColumns = []) => {
  if (parentColumns.length) {
    // when parent columns are present, they are treated as filters for the row.
    // if the row does not match the parent columns, then the value should be skipped
    const inScope = parentColumns.every(labelColumn => {
      const parentColumnValue = getDatasetColumnValue(
        datasetColumns,
        labelColumn,
        row
      )
      return parentColumnValue === labelColumn.label
    })
    if (!inScope) return null
    return getDatasetColumnValue(datasetColumns, tableColumn, row)
  }

  return getDatasetColumnValue(datasetColumns, tableColumn, row)
}

export { getRowValue, getComputedColumnValue }
