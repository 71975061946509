import { curry } from "lodash"

const getDateFromColumn = (row, column) => {
  const candidate = row && row[column]
  try {
    return new Date(candidate)
  } catch (e) {
    return new Date()
  }
}

const dateSorter = curry((column, a, b) => {
  const aDate = getDateFromColumn(a, column)
  const bDate = getDateFromColumn(b, column)
  return aDate - bDate
})

export default dateSorter
