import React, { useMemo } from "react"
import styled from "styled-components"
import { Table as AntDTable } from "antd"

import TableCell from "../shared/TableCell"
import queryResolver from "./queryResolver"
import constructColumns from "./constructColumns"
import constructDataSource from "./constructDataSource"

const StyledTable = styled(AntDTable)`
  width: 100%;
  heigth: 100%;
  table thead .thead-light {
    position: sticky;
  }
  table thead th {
    position: sticky;
  }
`

const getSafeTableProps = table => {
  if (!table) return {}
  const { title, ...rest } = table
  if (Boolean(title)) {
    return { title: () => title, ...rest }
  }
  return rest
}

const tableComponents = {
  body: {
    cell: TableCell,
  },
}

const PivotTable = props => {
  const { editable, dataset, widget = {} } = props

  const columns = useMemo(() => {
    return constructColumns(dataset?.data?.[0], widget.options, editable) || []
  }, [widget.options, editable, dataset])

  const dataSource = useMemo(() => {
    return (
      constructDataSource(dataset?.data?.[0], widget.options, columns) || []
    )
  }, [dataset, widget.options, columns])

  const memoizedTableProps = useMemo(() => {
    return getSafeTableProps(widget.options.table)
  }, [widget.options.table])

  return (
    <StyledTable
      columns={columns}
      dataSource={dataSource}
      components={tableComponents}
      {...memoizedTableProps}
    />
  )
}

const MemoizedPivotTable = React.memo(PivotTable)
MemoizedPivotTable.queryResolver = queryResolver
export default MemoizedPivotTable
