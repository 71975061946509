import React from "react"
import styled from "styled-components"

import Select from "../shared/Select"
import { getEnumValues, getSelectMode } from "../utils"
import { withFormFieldWrapper } from "../FormFieldWrapper"

const StyledLabel = styled.div`
  font-family: ${props => props.fontFamily};
`

const getSelectOptions = schema => {
  const { metadata = {} } = schema || {}
  const enumValues = getEnumValues(schema)
  if (!enumValues) return []
  const { labels } = metadata
  return enumValues.map((value, index) => ({
    value,
    label: labels?.length ? (
      <StyledLabel fontFamily={value}>{labels[index]}</StyledLabel>
    ) : (
      value
    ),
  }))
}

const OptionSetSelect = props => {
  const { schema, path, ...rest } = props
  const options = getSelectOptions(schema)
  return <Select {...rest} options={options} />
}

const FontPickerCore = props => {
  const { schema } = props
  const { nullable } = schema
  const mode = getSelectMode(schema)
  return <OptionSetSelect allowClear={nullable} mode={mode} {...props} />
}

const FontPicker = withFormFieldWrapper(FontPickerCore)

FontPicker.Core = FontPickerCore
export default FontPicker
