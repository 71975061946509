import React from "react"
import { get } from "lodash"

import { camelToSentence } from "@dbai/tool-box"
import { useFormSet, useFormState, useFormMeta } from "./hooks"

const label = (hide, text) => {
  if (hide) {
    return null
  }

  return <label className="form-label">{text}</label>
}

const getDisplayName = name => camelToSentence(name.split(".").pop())

const withInput = (Comp, defaults = {}) => {
  return React.forwardRef((props, ref) => {
    const formSet = useFormSet()
    const state = useFormState()
    const formMeta = useFormMeta()
    const handleChange = evt => {
      const { name, value } = evt.target
      if (props.coerce) return formSet({ name, value: props.coerce(value) })
      formSet({ name, value })
    }

    const {
      name,
      type = "text",
      noLabel = false,
      noWrapper = false,
      onChange = handleChange,
      displayName = getDisplayName(name),
      placeholder = getDisplayName(name),
      ...rest
    } = { ...defaults, ...props }

    const value = props.value || get(state, name, "")

    const childProps = {
      ref,
      name,
      type,
      value,
      onChange,
      placeholder,
      form: formMeta.name,
      className: "form-control",
      ...rest,
    }

    if (noWrapper) {
      return <Comp {...childProps} />
    }

    return (
      <div className="form-group">
        {label(noLabel, displayName)}
        <Comp {...childProps} />
      </div>
    )
  })
}

const Input = withInput("input")

export default Input
export { Input, withInput, getDisplayName }
