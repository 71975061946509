import { scalarFieldOptions } from "../../lib/fieldOptionsSchemas"

export default {
  default: {},
  type: "object",
  required: ["properties"],
  metadata: {
    layout: { gutter: [16] },
    sectionProps: {
      defaultActiveKey: ["formFields"],
    },
    sections: {
      formFields: {
        title: "Form Fields",
        properties: ["properties"],
      },
      groupOptions: {
        title: "Group Options",
        properties: [
          "verticalGutter",
          "horizontalGutter",
          "layout",
          "metadata/sectionType",
          "metadata/sectionProps/ghost",
          "metadata/sectionProps/bordered",
          "metadata/sectionProps/accordion",
          "metadata/sectionProps/hideArrow",
          "metadata/sections",
          "metadata/sectionProps/defaultActiveKey",
        ],
      },
    },
  },
  dependencies: {
    layout: {
      if: {
        properties: {
          layout: {
            enum: ["grid"],
          },
        },
      },
      then: {
        properties: {
          verticalGutter: {
            minimum: 0,
            maximum: 48,
            default: 0,
            type: "number",
            title: "Vertical Spacing",
            metadata: {
              showNumberInput: true,
              component: "SliderInput",
            },
          },
          horizontalGutter: {
            default: 16,
            minimum: 0,
            maximum: 48,
            type: "number",
            title: "Horizontal Spacing",
            metadata: {
              showNumberInput: true,
              component: "SliderInput",
            },
          },
        },
      },
    },
    "metadata/sections": {
      if: {
        properties: {
          "metadata/sectionType": {
            enum: ["tabs"],
          },
        },
      },
      then: {
        properties: {
          "metadata/sectionProps/defaultActiveKey": {
            default: [],
            type: "array",
            items: { type: "string" },
            title: "Default Open Sections",
            metadata: {
              valuePath: "key",
              labelPath: "title",
              relativeListPath: true,
              component: "LocalSelect",
              listPath: "metadata/sections",
            },
          },
        },
      },
      else: {
        properties: {
          "metadata/sectionProps/defaultActiveKey": {
            default: [],
            type: "array",
            items: { type: "string" },
            title: "Default Open Sections",
            metadata: {
              valuePath: "key",
              labelPath: "title",
              relativeListPath: true,
              component: "LocalSelect",
              listPath: "metadata/sections",
            },
          },
        },
      },
      properties: {
        "metadata/sectionProps/ghost": {
          type: "boolean",
          title: "Ghost",
          default: false,
        },
        "metadata/sectionProps/bordered": {
          type: "boolean",
          title: "Bordered",
          default: false,
        },
        "metadata/sectionProps/accordion": {
          type: "boolean",
          title: "Accordion",
          default: false,
        },
        "metadata/sectionProps/hideArrow": {
          type: "boolean",
          title: "Hide Arrow",
          default: false,
        },
      },
    },
  },
  properties: {
    key: scalarFieldOptions.properties.key,
    title: {
      default: "Group Field",
      type: "string",
      title: "Label",
      metadata: {
        fieldProps: {
          placeholder: "Enter a label",
        },
      },
    },
    properties: {
      title: "Form Fields",
      type: "array",
      default: [],
      metadata: {
        hideLabel: true,
        layout: { gutter: [] },
        itemType: "collapse",
        addButtonText: "ADD FORM FIELD",
        addButtonProps: { block: true },
      },
      items: {
        $ref: "#/$defs/formFieldListWithDependencies",
      },
    },
    layout: {
      type: "string",
      title: "Layout",
      default: "grid",
      enum: ["inline", "grid"], //TODO: add support for more layout options
      metadata: {
        hidden: true,
        component: "Segmented",
        labels: ["Inline", "Grid"],
      },
    },
    "metadata/hideLabel": {
      default: false,
      type: "boolean",
      title: "Hide Label",
    },
    "metadata/sectionType": {
      type: "string",
      title: "Section Type",
      enum: ["default", "tabs"],
      metadata: {
        component: "Segmented",
        labels: ["Default", "Tabs"],
      },
    },
    "metadata/sections": {
      type: "array",
      default: [],
      title: "Sections",
      metadata: {
        layout: { gutter: [] },
        addButtonProps: { block: true },
        containerStyle: {
          padding: "0",
        },
      },
      items: {
        type: "object",
        default: {},
        metadata: {
          sectionProps: {
            defaultActiveKey: "propertiesSection",
          },
          sections: {
            containerStyles: {
              title: "Display Options",
              properties: ["containerStyle"],
            },
          },
        },
        properties: {
          key: scalarFieldOptions.properties.key,
          title: {
            type: "string",
            title: "Title",
            default: "Section",
          },
          containerStyle: {
            type: "object",
            default: {},
            metadata: {
              layout: { gutter: [16] },
            },
            properties: {
              padding: {
                nullable: true,
                title: "Padding",
                type: ["string", "null"],
                metadata: {
                  fieldProps: {
                    placeholder: "Auto",
                  },
                },
              },
              overflowY: {
                nullable: true,
                type: ["string", "null"],
                title: "Overflow",
                default: "auto",
                enum: ["auto", "hidden", "scroll"],
                metadata: {
                  component: "Segmented",
                  labels: ["Auto", "Hidden", "Scroll"],
                },
              },
              height: {
                nullable: true,
                title: "Height",
                type: ["string", "null"],
                metadata: {
                  fieldProps: {
                    placeholder: "Auto",
                  },
                },
              },
              width: {
                nullable: true,
                title: "Width",
                type: ["string", "null"],
                metadata: {
                  fieldProps: {
                    placeholder: "Auto",
                  },
                },
              },
            },
          },
          properties: {
            type: "array",
            title: "Properties",
            items: { type: "string" },
            metadata: {
              valuePath: "key",
              labelPath: "title",
              listPath: "../../properties",
              relativeListPath: true,
              component: "LocalSelect",
            },
          },
        },
      },
    },
  },
}
