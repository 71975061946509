import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import styled, { css } from "styled-components"
import { camelToSentence } from "@dbai/tool-box"

const sortCaret = css`
  content: "";
  position: absolute;
  top: 50%;
  left: 6px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
`

const sortable = css`
  && {
    cursor: pointer;
    padding-left: 20px;
  }

  &:after {
    ${sortCaret}
    margin-top: -6px;
    box-sizing: border-box;
    border-bottom: 4px solid ${props => props.theme.linkActive};
  }
  &:before {
    ${sortCaret}
    margin-top: 2px;
    box-sizing: border-box;
    border-top: 4px solid ${props => props.theme.linkActive};
  }

  &:hover:after {
    border-bottom-color: ${props => props.theme.linkActive};
  }
  &:hover:before {
    border-top-color: ${props => props.theme.linkActive};
  }
`

const tableCell = css`
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0.5rem;
  white-space: nowrap;
`

const hoverCells = css`
  &:hover {
    box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.28);
    -webkit-box-shadow: 0 1px 9px 1px rgba(0, 0, 0, 0.28);
  }
`

const StyledTableHeader = styled.th`
  && {
    top: 0;
    position: sticky;
    vertical-align: middle;
    ${props => props.isDataTable && tableCell}
    ${props => props.sortable && sortable}
  }
`

const StyledTableCell = styled.td`
  && {
    vertical-align: middle;
    ${props => props.isDataTable && tableCell}
    ${props => props.dataTable && hoverCells}
  }
`

const Columns = props => {
  const { isHead, fields, row, isDataTable } = props

  return fields.map(field => {
    return (
      <Column
        isHead={isHead}
        field={field}
        row={row}
        isDataTable={isDataTable}
      />
    )
  })
}

Columns.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  row: PropTypes.arrayOf(PropTypes.object).isRequired,
  isHead: PropTypes.bool,
  isDataTable: PropTypes.bool,
}

const Column = props => {
  const {
    row,
    idx,
    field,
    width,
    isHead,
    sortable,
    dataTable,
    onSortClick,
    isDataTable,
    className = "",
    name = camelToSentence(field || ""),
  } = props

  if (isHead) {
    const onClick = e => {
      sortable && onSortClick({ field, name })
    }
    return (
      <StyledTableHeader
        width={width}
        sortable={sortable}
        isDataTable={isDataTable}
        className={className}
        onClick={onClick}
      >
        {name}
      </StyledTableHeader>
    )
  }

  const safeRender = renderFromProps(props)
  const value = get(row, field)

  return (
    <StyledTableCell
      className={className}
      dataTable={dataTable}
      width={width}
      isDataTable={isDataTable}
    >
      {safeRender({ value, row, idx })}
    </StyledTableCell>
  )
}

Column.propTypes = {
  field: PropTypes.string.isRequired,
  row: PropTypes.object,
  name: PropTypes.string,
  width: PropTypes.string,
  sortable: PropTypes.bool,
  onSortClick: PropTypes.func,
}

const renderFromProps = props => {
  const { render, children } = props

  if (render) {
    return render
  }

  if (children) {
    return childProps =>
      React.Children.map(children, child => {
        return React.cloneElement(child, childProps)
      })
  }
  return ({ value }) => value
}

export { Column, Columns }
