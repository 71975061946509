import React from "react"
import { Col } from "antd"
import styled from "styled-components"
import { useParams } from "react-router"
import { useQuery } from "@apollo/client"

import { Spinner, Content, ErrorMessage } from "@dbai/ui-staples"

import DBBoundary from "components/shared/DBBoundary"
import { GET_MODELS } from "queries"
import ModelsTable from "./ModelsTable"
import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"

const StyledContent = styled(Content)`
  padding-top: 5px;
`

const Models = props => {
  const { customer } = props
  const { cname } = useParams()
  const variables = { cname }
  const { data, loading, error } = useQuery(GET_MODELS, {
    variables,
  })

  const routes = [
    { breadcrumbName: customer.name },
    { breadcrumbName: "Models" },
  ]

  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  return (
    <DBBoundary subject="models" action="LIST">
      <div id="models">
        <PageHeader routes={routes} />

        <StyledContent>
          <Col span={24}>
            <ModelsTable models={data?.customer?.models || []} />
          </Col>
        </StyledContent>
      </div>
    </DBBoundary>
  )
}

export default withCustomer(Models)
