import React, { useState, useEffect } from "react"
import { get } from "lodash"
import styled from "styled-components"
import { toast } from "react-toastify"
import { useDropzone } from "react-dropzone"

import { alphanumid } from "@dbai/tool-box"
import {
  Input,
  HiddenInput,
  ApiForm,
  AuthBoundary,
  useFormState,
  useFormSet,
} from "@dbai/ui-staples"

import { CREATE_WORKFLOW } from "queries"

import defaultSpec from "./defaultSpec"

const DropzoneContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2;
  border-radius: 2;
  border-color: ${props => {
    if (props.isDragActive) {
      return "#2196f3"
    } else if (props.isDragAccept) {
      return "#00e676"
    } else if (props.isDragReject) {
      return "#ff1744"
    } else {
      return "#eeeeee"
    }
  }};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-bottom: 10px;
`

const StyledDropzone = ({ setFile }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: ".ipynb",
    maxFiles: 1,
    onDrop: ([file]) => {
      if (file) {
        setFile(file)
      }
    },
  })

  return (
    <DropzoneContainer
      {...getRootProps()}
      {...{ isDragAccept, isDragActive, isDragReject }}
    >
      <input {...getInputProps()} data-cy="drag-and-drop" />
      <p>Select a Jupyter notebook (*.ipynb) to import</p>
    </DropzoneContainer>
  )
}

const WorkflowImporter = props => {
  const formSet = useFormSet()

  const setFile = notebookFile => {
    try {
      const nameMatch = notebookFile.name.match(/(.+)\.ipynb/)
      const fileName = nameMatch[1]
      const reader = new FileReader()
      reader.onload = e => {
        const contents = JSON.parse(e.target.result)
        const node = {
          id: alphanumid(),
          title: fileName,
          color: "#aaa",
          collapsed: false,
          type: "script",
          x: 100,
          y: 100,
          cells: contents.cells.map(cell => ({
            uuid: alphanumid(),
            cellType: cell.cell_type,
            executionCount: cell.execution_count,
            metadata: { ...cell.metadata, showCode: true, showOutput: true },
            source: cell.source,
            outputs: [],
          })),
        }
        const spec = {
          nodes: [node],
          edges: [],
        }
        formSet({ name: "input.name", value: fileName })
        formSet({ name: "input.spec", value: spec })
      }
      reader.readAsText(notebookFile)
    } catch (e) {
      toast.error(
        `Error reading notebook file. Please make sure to upload a notebook with an .ipynb extension`
      )
    }
  }

  return <StyledDropzone setFile={setFile} />
}

const WorkflowForm = props => {
  const { customerId, isImport, setHideSubmit } = props
  const formState = useFormState()

  useEffect(() => {
    if (isImport && !get(formState, "input.spec")) {
      setHideSubmit(true)
    } else {
      setHideSubmit(false)
    }
  }, [isImport, setHideSubmit, formState])

  return (
    <>
      {isImport && <WorkflowImporter />}

      {(!isImport || get(formState, "input.spec")) && (
        <>
          <Input name="input.name" />
          <HiddenInput name="customerId" value={customerId} />
        </>
      )}
      {(!isImport) && (
        <HiddenInput name="input.spec" value={defaultSpec} />
      )}
    </>
  )
}

const NewWorkflow = props => {
  const { refetchQueries, afterSubmit } = props
  const [hideSubmit, setHideSubmit] = useState(false)

  return (
    <AuthBoundary subject="workflows" action="CREATE">
      <ApiForm
        refetchQueries={refetchQueries}
        mutation={CREATE_WORKFLOW}
        hideSubmit={hideSubmit}
        afterSubmit={afterSubmit}
      >
        <WorkflowForm {...props} setHideSubmit={setHideSubmit} />
      </ApiForm>
    </AuthBoundary>
  )
}

export default NewWorkflow
