import React, { useRef, useEffect } from "react"
import { Input } from "antd"
import styled from "styled-components"

import fieldOptionsSchema from "./fieldOptionsSchema"
import { useLocalFormFieldControls } from "../../hooks"
import { withFormFieldWrapper } from "../../FormFieldWrapper"

const { TextArea } = Input

const StyledTextArea = styled(TextArea)`
  min-width: 100px;
`

const BaseTextAreaCore = React.memo(props => {
  const { schema = {}, onChange, value: _value, ...rest } = props
  const { metadata = {}, nullable } = schema
  const { focusOnMount, autoSize, rows } = metadata
  const inputRef = useRef()

  useEffect(() => {
    if (focusOnMount && inputRef.current) {
      inputRef.current.focus({
        cursor: "start",
      })
    }
  }, [focusOnMount])

  const [value, handleChange] = useLocalFormFieldControls(_value, onChange, 200)
  return (
    <StyledTextArea
      ref={inputRef}
      rows={rows}
      value={value}
      autoSize={autoSize}
      allowClear={nullable}
      onChange={handleChange}
      {...rest}
    />
  )
})

const BaseTextArea = withFormFieldWrapper(BaseTextAreaCore)

BaseTextArea.fieldOptionsSchema = fieldOptionsSchema
BaseTextArea.Core = BaseTextAreaCore
export default BaseTextArea
