import React, { useEffect, useMemo, useState, useCallback } from "react"
import { Flex, Table, Button } from "antd"

import { useColumnLabels } from "../../hooks"
import JSONSchemaField from "../JSONSchemaField"
import FormFieldWrapper from "../FormFieldWrapper"
import { useWidgetQuery, useFormData } from "../hooks"
import EditableTableCell from "../shared/EditableTableCell"
import DraggableTableRow from "../shared/DraggableTableRow"

const alterSchemaFieldProps = (schema, fieldProps) => {
  return {
    ...schema,
    metadata: {
      ...schema.metadata,
      fieldProps: {
        ...schema.fieldProps,
        ...fieldProps,
      },
    },
  }
}

const labelTableColumns = (schema, path) => [
  {
    title: "Label",
    dataIndex: "label",
  },
  {
    title: schema.items.properties.title.title,
    dataIndex: "title",
    onCell: record => {
      // alter schema to include placeholder in fieldProps for title
      const titleSchema = schema.items.properties.title
      const alteredSchema = alterSchemaFieldProps(titleSchema, {
        placeholder: record.title,
      })
      return {
        record,
        editable: true,
        schemaKey: "title",
        path: `${path}.[${record.index}]`,
        schema: alteredSchema,
      }
    },
  },
  {
    ...(schema.items.properties.color
      ? {
          title: "Color",
          dataIndex: "color",
          width: 100,
          onCell: record => {
            return {
              record,
              editable: true,
              schemaKey: "color",
              path: `${path}.[${record.index}]`,
              schema: schema.items.properties.color,
            }
          },
        }
      : {}),
  },
]

const scroll = { x: true, y: 300 }

const components = {
  body: {
    row: DraggableTableRow,
    cell: EditableTableCell,
  },
}

const getDataSource = value => {
  return value?.map((v, i) => ({ ...v, key: i, index: i }))
}

// given two label configs, this function merges them together based on th label name
const mergeLabels = (labels, existingLabelOptions) => {
  if (!labels?.length) return existingLabelOptions
  if (!existingLabelOptions) return labels
  const existingLabels = existingLabelOptions.map(
    labelConfig => labelConfig.label
  )
  const newLabels = labels.filter(
    label => !existingLabels.includes(label.label)
  )
  return [...existingLabelOptions, ...newLabels]
}

const LabelsTable = props => {
  const { name, schema, column, syncOnRender, path, onChange, allowRemove } =
    props
  const value = useFormData(name)
  const { datasetId } = useWidgetQuery()
  const { loading, error, labels } = useColumnLabels(datasetId, column)

  const mergedLabels = useMemo(() => {
    return mergeLabels(labels, value)
  }, [labels, value])

  const [dataSource, setDataSource] = useState(
    getDataSource(mergedLabels) || []
  )

  useEffect(() => {
    if (loading || error) return
    setDataSource(getDataSource(mergedLabels) || [])
  }, [mergedLabels, loading, error])

  const handleChange = useCallback(
    value => {
      setDataSource(getDataSource(value) || [])
      onChange && onChange(value.map(({ key, ...rest }) => rest))
    },
    [onChange]
  )

  useEffect(() => {
    if (loading || error) return
    if (dataSource.length !== mergedLabels?.length) {
      if (syncOnRender) {
        return handleChange(mergedLabels, dataSource)
      }
      setDataSource(getDataSource(mergedLabels) || [])
    }
  }, [handleChange, dataSource, loading, error, syncOnRender, mergedLabels])

  const handleSelectChange = useCallback(
    (keys, rows) => {
      handleChange(
        dataSource.map(row => {
          return { ...row, usable: keys.includes(row.key) }
        })
      )
    },
    [handleChange, dataSource]
  )

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = dataSource[dragIndex]
      const dsCopy = [...dataSource]
      dsCopy.splice(dragIndex, 1)
      dsCopy.splice(hoverIndex, 0, dragRow)
      handleChange(dsCopy)
    },
    [handleChange, dataSource]
  )

  const rowSelection = useMemo(() => {
    return {
      selectedRowKeys: dataSource
        .filter(({ usable }) => usable)
        .map(({ key }) => key),
      onChange: handleSelectChange,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        Table.SELECTION_NONE,
      ],
    }
  }, [handleSelectChange, dataSource])

  const handleRow = useCallback(
    (_, index) => {
      const attr = {
        index,
        moveRow,
      }
      return attr
    },
    [moveRow]
  )

  const addColumnLabels = useCallback(() => {
    if (!mergedLabels?.length) return
    handleChange(mergedLabels)
  }, [handleChange, mergedLabels])

  const removeColumnLabels = useCallback(() => {
    handleChange([])
  }, [handleChange])

  // when no labels exist, show button to add labels
  if (!value?.length) {
    return <Button onClick={addColumnLabels}>Show Column Labels</Button>
  }

  const titleOptions = allowRemove
    ? {
        title: () => (
          <Button onClick={removeColumnLabels}>Remove Column Labels</Button>
        ),
      }
    : {}

  return (
    <Table
      size="small"
      scroll={scroll}
      onRow={handleRow}
      pagination={false}
      dataSource={dataSource}
      components={components}
      rowSelection={rowSelection}
      columns={labelTableColumns(schema, path)}
      {...titleOptions}
    />
  )
}

const ColumnLabelsCore = props => {
  const { schema, path, parentPath, value, name, parentSchemaKey } = props

  const {
    metadata: {
      component,
      syncOnRender,
      allowRemove = true,
      labelsSchemaKey = "labels",
      ...restMetadata
    },
    properties: { column, type, id, filters, ...restProperties },
  } = schema

  const { otherSchemas, labelSchema } = Object.entries(restProperties).reduce(
    (acc, [key, propertySchema]) => {
      if (key === labelsSchemaKey) {
        return { ...acc, labelSchema: propertySchema }
      }
      return {
        ...acc,
        otherSchemas: { ...acc.otherSchemas, [key]: propertySchema },
      }
    },
    { labelSchema: {}, otherSchemas: {} }
  )

  const subSchema = {
    ...schema,
    metadata: restMetadata,
    properties: otherSchemas,
  }

  return (
    <Flex vertical>
      {Boolean(column) ? (
        <JSONSchemaField
          path={path}
          name={name}
          schema={column}
          schemaKey="column"
          parentSchema={schema}
        />
      ) : null}
      {Boolean(labelSchema) ? (
        <FormFieldWrapper
          path={path}
          schema={labelSchema}
          parentSchema={schema}
          schemaKey={labelsSchemaKey}
        >
          <LabelsTable column={value?.column} allowRemove={allowRemove} />
        </FormFieldWrapper>
      ) : null}
      <JSONSchemaField
        path={parentPath}
        schema={subSchema}
        schemaKey={parentSchemaKey}
      />
    </Flex>
  )
}

const ColumnLabels = props => {
  return (
    <FormFieldWrapper {...props}>
      <ColumnLabelsCore
        parentPath={props.path}
        parentSchemaKey={props.schemaKey}
      />
    </FormFieldWrapper>
  )
}

ColumnLabels.Core = ColumnLabelsCore
export default ColumnLabels
