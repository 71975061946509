import { gql } from "@apollo/client"

export const GET_TIME_RANGE = gql`
  query DatasetTimeRange($datasetId: Int!, $cname: String!, $column: String!) {
    customer(name: $cname) {
      id
      dataset(id: $datasetId) {
        id
        stats {
          timeRange(column: $column) {
            min
            max
          }
        }
      }
    }
  }
`

export const GET_DATASETS = gql`
  query getDatasets($customerId: Int!) {
    datasets(customerId: $customerId) {
      id
      name
      tags
      createdAt
      updatedAt
    }
  }
`

export const GET_DATASET_OVERVIEW = gql`
  query DatsetOverview($id: Int!, $customerId: Int!) {
    dataset(id: $id, customerId: $customerId) {
      id
      name
      createdAt
      updatedAt
      meta {
        datasetColumns {
          name
          type
        }
      }
    }
  }
`
