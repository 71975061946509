import { merge } from "lodash"

import rectShapeSchema from "./rectShapeSchema"
import richTextShapeSchema from "./richTextShapeSchema"
import starShapeSchema from "./starShapeSchema"
import imageShapeSchema from "./imageShapeSchema"
import circleShapeSchema from "./circleShapeSchema"
import genericShapeSchema from "./genericShapeSchema"
import datasetColumnShapeSchema from "./datasetColumnShapeSchema"

const shapeRegistry = {
  rect: rectShapeSchema,
  star: starShapeSchema,
  richText: richTextShapeSchema,
  image: imageShapeSchema,
  circle: circleShapeSchema,
  datasetColumn: datasetColumnShapeSchema,
}

const shapeProperties = Object.entries(shapeRegistry).map(
  ([key, { metadata, properties }]) => ({
    if: {
      properties: {
        type: {
          enum: [key],
        },
      },
    },
    then: {
      metadata: merge({}, metadata || {}, genericShapeSchema.metadata),
      properties: properties,
    },
  })
)

const canvasWidgetSchema = {
  default: {},
  type: "object",
  title: "Widget",
  metadata: {
    noStyle: true,
    sectionType: "tabs",
    order: ["shapes", "stage"],
    sections: {
      shapes: {
        title: "Shapes",
        properties: ["shapes"],
      },
      stage: {
        title: "Canvas Options",
        properties: ["stage"],
      },
    },
  },
  properties: {
    stage: {
      type: "object",
      title: "Canvas",
      default: {},
      metadata: {
        layout: { gutter: [16] },
      },
      properties: {
        "stage/width": {
          title: "Width",
          type: ["number", "null"],
          nullable: true,
        },
        "stage/height": {
          title: "Height",
          type: ["number", "null"],
          nullable: true,
        },
        "stage/opacity": {
          title: "Opacity",
          type: ["number", "null"],
          minimum: 0,
          maximum: 1,
          nullable: true,
          metadata: {
            fieldProps: {
              step: 0.1,
              placeholder: "None",
            },
          },
        },
        "stage/backgroundColor": {
          title: "Background Color",
          type: ["string", "null"],
          default: "transparent",
          nullable: true,
          metadata: {
            component: "ColorPicker",
          },
        },
      },
    },
    shapes: {
      title: "Shapes",
      type: "array",
      default: [],
      metadata: {
        hideLabel: true,
        addButtonProps: { block: true },
      },
      items: {
        type: "object",
        default: {},
        dependencies: {
          type: {
            properties: {
              type: {
                metadata: {
                  fieldProps: {
                    disabled: true,
                  },
                },
              },
            },
            allOf: shapeProperties,
          },
        },
        properties: genericShapeSchema.properties,
      },
    },
  },
}

export default canvasWidgetSchema
