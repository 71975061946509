import React from "react"

import { useCheckForDBUser } from "hooks"
import { AuthBoundary } from "@dbai/ui-staples"

/*
 * Wrap content with this component if you don't want it to show up outside of
 * Delta-Bravo.
 */
const DBBoundary = (props) => {
  const isDbUser = useCheckForDBUser()

  if (isDbUser) {
    if (props.action) {
      return <AuthBoundary {...props}>{props.children}</AuthBoundary>
    } else {
      return <>{props.children}</>
    }
  }

  return null
}

export default DBBoundary
