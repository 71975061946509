const compareEdge = (l, r) => {
  if (l.source < r.source) return -1
  if (l.source > r.source) return 1
  return 0
}

const findAdjacent = (nodes, edges) => id => {
  const findStart = e => e.source === id
  const findEnd = e => e.source !== id

  const start = edges.findIndex(findStart)
  if (start === -1) {
    return []
  }

  const lastIdx = edges.slice(start).findIndex(findEnd)
  const end = lastIdx < 0 ? edges.length : lastIdx + start
  return edges
    .slice(start, end)
    .map(e => nodes[e.target])
    .filter(Boolean)
}

const indexNodes = (pre, node) => ({ [node.id]: node, ...pre })

const topoSort = workflow => {
  const {
    spec: { nodes, edges },
  } = workflow

  const indexedNodes = nodes.reduce(indexNodes, {})
  if (Object.keys(indexedNodes).length < 1) return []
  if (edges.length < 1)
    return Object.keys(indexedNodes).map(k => indexedNodes[k])

  const visited = new Set()
  const adjList = edges.slice().sort(compareEdge)
  const findAdj = findAdjacent(indexedNodes, adjList)

  const sort = (pre, node) => {
    if (visited.has(node.id)) return pre
    visited.add(node.id)

    const adj = findAdj(node.id)
    if (adj.length < 1) {
      return [node, ...pre]
    }
    return [node, ...adj.reduce(sort, pre)]
  }

  return Object.keys(indexedNodes)
    .map(k => indexedNodes[k])
    .reduce(sort, [])
}

export default topoSort
