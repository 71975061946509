import gql from "graphql-tag"

const nodeFragment = gql`
  fragment NodeFragment on Node {
    id
    name
    parentId
    createdAt
    updatedAt
    status
    file {
      uuid
      size
      status
    }
    nodes {
      id
      name
      parentId
      createdAt
      updatedAt
      status
      file {
        uuid
        size
        status
      }
    }
  }
`

export const GET_CUSTOMER_NODES = gql`
  ${nodeFragment}
  query Customer($cname: String!, $parentId: Int, $maxDepth: Int) {
    customer(name: $cname) {
      id
      nodes(parentId: $parentId, maxDepth: $maxDepth) {
        ...NodeFragment
      }
    }
  }
`

export const GET_NODES = GET_CUSTOMER_NODES

export const GET_NODE = gql`
  ${nodeFragment}
  query Customer($cname: String!, $id: Int!) {
    customer(name: $cname) {
      id
      node(id: $id) {
        ...NodeFragment
      }
    }
  }
`

export const UPDATE_NODE = gql`
  mutation UpdateNode($id: Int!, $cname: String!, $node: NodeInput!) {
    updateNode(id: $id, cname: $cname, node: $node) {
      parentId
    }
  }
`

export const CREATE_NODE = gql`
  mutation CreateNode($cname: String!, $name: String!, $parentId: Int) {
    createNode(cname: $cname, node: { name: $name, parentId: $parentId }) {
      id
    }
  }
`

export const CREATE_UPLOAD_NODE = gql`
  mutation CreateNode(
    $cname: String!
    $parentId: Int
    $name: String!
    $file: NodeFileInput
  ) {
    createNode(
      cname: $cname
      node: { parentId: $parentId, name: $name, file: $file }
    ) {
      id
      uploadUrl
    }
  }
`

export const UPDATE_NODE_STATUS = gql`
  ${nodeFragment}
  mutation UdateNodeStatus($cname: String!, $id: Int!, $status: String!) {
    updateNodeStatus(cname: $cname, id: $id, status: $status) {
      ...NodeFragment
    }
  }
`

export const GET_DOWNLOAD_URL = gql`
  query GetDownloadUrl($cname: String!, $id: Int!) {
    customer(name: $cname) {
      node(id: $id) {
        id
        downloadUrl
      }
    }
  }
`

export const MOVE_NODES = gql`
  mutation MoveNodes($cname: String!, $parentId: Int!, $nodeIds: [Int]!) {
    moveNodes(cname: $cname, parentId: $parentId, nodeIds: $nodeIds)
  }
`

export const DELETE_NODES = gql`
  mutation DeleteNodes($cname: String!, $ids: [Int]!) {
    deleteNodes(cname: $cname, ids: $ids)
  }
`
