import { getRowValue } from "../../lib/tableValues"
import { findColumnConfig } from "../../lib/datasetColumns"
import {
  getOnCell,
  getSafeTitle,
  getColumnSort,
  getColumnSearch,
  getHeaderCellProps,
} from "../../lib/tableColumns"

export const constructDataSource = (dataset, tableColumns) => {
  const { rows = [], columns = [] } = dataset || {}

  if (!tableColumns?.length) return []
  if (!rows?.length) {
    const row = tableColumns.reduce((final, tableColumn) => {
      if (!tableColumn.dataIndex) return final
      return {
        ...final,
        [tableColumn.dataIndex]: getRowValue(columns, {}, tableColumn),
      }
    }, {})
    return [row]
  }

  return rows.reduce((validRows, row, rowIndex) => {
    const tableRows = tableColumns.reduce((final, tableColumn) => {
      if (!tableColumn.dataIndex) return final
      return {
        ...final,
        [tableColumn.dataIndex]: getRowValue(columns, row, tableColumn),
      }
    }, {})

    const rawDataRows = columns.reduce((acc, column) => {
      return {
        ...acc,
        [column.name]: getRowValue(columns, row, column),
      }
    }, {})

    if (Object.keys(tableRows).length) {
      validRows.push({
        key: `${rowIndex}`,
        ...tableRows,
        ...rawDataRows,
      })
    }

    return validRows
  }, [])
}

export const getTableColumns = (widget, dataset, columnsAreAggregated) => {
  const { columns = [], groupBy = [] } = widget || {}
  const { showTotals } = widget?.table || {}
  const totalColumn = showTotals
    ? [
        {
          title: "",
          width: 60,
          dataIndex: "Total",
          key: "Total",
          type: "string",
          shouldCellUpdate: () => true,
        },
      ]
    : []
  return totalColumn.concat(
    [...groupBy, ...columns].map((column, index) => {
      const columnConfig = findColumnConfig(column, dataset?.columns)
      return {
        ...columnConfig,
        ...getColumnSort(column),
        ...getColumnSearch(column),
        ...getOnCell(columnConfig, columnsAreAggregated),
        title: getSafeTitle(columnConfig),

        // passes props to cells (relevant when cells are editable)
        onHeaderCell: getHeaderCellProps(index),
      }
    })
  )
}

export const checkColumnsAreAggregated = columns => {
  return columns?.every(column => {
    const { aggregate, columnType } = column
    if (columnType === "computed") {
      const { computation } = column
      return computation?.every(({ aggregate }) => aggregate)
    }
    return aggregate
  })
}
