import { useMemo, useContext } from "react"

import YjsContext from "components/pages/Workflows/Edit/YjsContext"

export const useYjs = () => useContext(YjsContext)
export const useYjsUsers = () => {
  const yjsContext = useYjs()
  return useMemo(() => {
    return yjsContext.users || []
  }, [yjsContext])
}
