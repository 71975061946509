import React, { useMemo } from "react"

import { Collapse } from "../CollapsePanel"
import LayoutWrapper from "../../LayoutWrapper"
import JSONSchemaField from "../../JSONSchemaField"

const stopEventPropagation = e => e.stopPropagation()
const CollapsedSections = props => {
  const {
    name,
    path,
    layout,
    sectionedFields,
    schema: parentSchema,
    collapseHeaderLevel = 0,
  } = props
  const { metadata = {} } = parentSchema
  const { sectionProps = {} } = metadata
  const items = useMemo(() => {
    const { metadata = {} } = parentSchema
    const { sections = {}, sectionProps = {} } = metadata
    return sectionedFields
      .filter(([, fields]) => fields.length)
      .map(([section, fields]) => {
        const { title, containerStyle = {}, headerProperty } = sections[section]
        const headerPropertySchema = headerProperty
          ? fields.find(([key]) => headerProperty === key)?.[1]
          : null

        const sectionFields = fields.filter(([key]) => headerProperty !== key)

        // remove bottom margin if there is only one field in the section
        const wrapperStyles =
          sectionFields.length === 1 ? { margin: "0 1em 0 0" } : {}

        return {
          key: section,
          showArrow: !sectionProps.hideArrow,
          label: (
            <>
              {headerProperty && headerPropertySchema ? (
                <div onClick={stopEventPropagation}>
                  <JSONSchemaField
                    noStyle
                    path={path}
                    name={name}
                    schemaKey={headerProperty}
                    parentSchema={parentSchema}
                    schema={headerPropertySchema}
                    collapseHeaderLevel={collapseHeaderLevel + 1}
                  />
                </div>
              ) : (
                <div>{title}</div>
              )}
            </>
          ),
          children: (
            <LayoutWrapper
              container
              path={path}
              layout={layout}
              schema={parentSchema}
              containerStyle={containerStyle}
            >
              {sectionFields.map(([key, schema], idx) => {
                return (
                  <LayoutWrapper
                    key={key}
                    path={path}
                    schema={schema}
                    layout={layout}
                    parentSchema={parentSchema}
                  >
                    <JSONSchemaField
                      path={path}
                      name={name}
                      schemaKey={key}
                      schema={schema}
                      parentSchema={parentSchema}
                      wrapperStyles={wrapperStyles}
                      collapseHeaderLevel={collapseHeaderLevel + 1}
                    />
                  </LayoutWrapper>
                )
              })}
            </LayoutWrapper>
          ),
        }
      })
  }, [sectionedFields, collapseHeaderLevel, layout, name, parentSchema, path])

  if (!items.length) return null
  return (
    <Collapse
      items={items}
      ghost={sectionProps.ghost}
      bordered={sectionProps.bordered}
      accordion={sectionProps.accordion}
      collapsible={sectionProps.collapsible}
      collapseHeaderLevel={collapseHeaderLevel}
      defaultActiveKey={sectionProps.defaultActiveKey}
      expandIconPosition={sectionProps.expandIconPosition}
    />
  )
}

export default CollapsedSections
