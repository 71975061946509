import React, { useCallback } from "react"
import { useMutation } from "@apollo/client"
import { useParams } from "react-router-dom"
import { Button } from "antd"

import { UPDATE_THEME } from "@dbai/applet"

const SaveButton = props => {
  const { dataRef, onSave } = props
  const { id, cname } = useParams()
  const [update] = useMutation(UPDATE_THEME)
  const handleSave = useCallback(() => {
    const data = dataRef.current
    update({
      variables: {
        id,
        cname,
        input: data,
      },
    }).then(() => onSave && onSave())
  }, [dataRef, id, cname, onSave, update])
  return (
    <Button type="primary" onClick={handleSave}>
      Save and Publish
    </Button>
  )
}

export default SaveButton
