import React, { useCallback } from "react"
import { PropTypes } from "prop-types"
import { Col, Row } from "antd"

import SaveButton from "./SaveButton"
import { useFormContext } from "./hooks"
import JSONSchemaField from "./JSONSchemaField"
import ValidationErrors from "./ValidationErrors"
import SchemaFormProvider from "./SchemaFormProvider"

const trackableErrorKeys = ["required"]
const getTrackableErrors = errors => {
  if (!errors?.length) return []
  return errors.filter(error => trackableErrorKeys.includes(error.keyword))
}

const Form = React.memo(props => {
  const {
    value,
    hideSave,
    onSubmit,
    path = "",
    className,
    schema = {},
    schemaKey = ".",
    showErrorsList,
    saveButton = {},
    resetOnSave = true,
    allowSaveWithErrors,
    ...rest
  } = props
  const { reset, setShowErrors } = useFormContext()

  const handleSubmit = useCallback(
    (data, errors) => {
      const trackableErrors = getTrackableErrors(errors)

      // prevent submission if there are errors
      if (trackableErrors.length && !allowSaveWithErrors) {
        setShowErrors(true)
        return
      }

      setShowErrors(false)
      onSubmit && onSubmit(data, errors)

      resetOnSave && reset()
    },
    [onSubmit, reset, setShowErrors, resetOnSave, allowSaveWithErrors]
  )

  return (
    <Row gutter={[0, 16]}>
      {showErrorsList ? (
        <Col span={24}>
          <ValidationErrors />{" "}
        </Col>
      ) : null}
      <Col span={24}>
        <JSONSchemaField
          path={path}
          schema={schema}
          schemaKey={schemaKey}
          {...rest}
        />
      </Col>
      {!hideSave ? (
        <Col span={24}>
          <SaveButton onSubmit={handleSubmit} {...saveButton} />
        </Col>
      ) : null}
    </Row>
  )
})

const FormProvider = React.memo(
  React.forwardRef((props, ref) => {
    const { children } = props
    return (
      <SchemaFormProvider ref={ref} {...props}>
        {children}
      </SchemaFormProvider>
    )
  })
)

const JSONSchemaForm = React.memo(
  React.forwardRef((props, ref) => {
    return (
      <FormProvider {...props} ref={ref}>
        <Form {...props} />
      </FormProvider>
    )
  })
)

JSONSchemaForm.propTypes = {
  schema: PropTypes.object.isRequired,
  schemaKey: PropTypes.string,
  path: PropTypes.string,
}
JSONSchemaForm.Provider = FormProvider
export default JSONSchemaForm
