import React, { useCallback } from "react"
import { Input } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { Button } from "antd"

import { ErrorMessage, Spinner } from "@dbai/ui-staples"

import Inputs from "./Inputs"
import { useAction } from "hooks"
import CodeEditor from "./CodeEditor"
import ColorPicker from "./ColorPicker"
import { actions } from "reducers/actionReducer"
import {
  selectAction,
  selectActionColor,
  selectActionIcon,
  selectActionError,
  selectActionSource,
  selectActionLoading,
} from "selectors"

const { TextArea } = Input

const NameEditor = props => {
  const { value, onChange } = props
  const handleChange = useCallback(e => onChange(e.target.value, e), [onChange])

  return (
    <div className="form-group">
      <label className="form-label">Name</label>
      <Input id="name" value={value} onChange={handleChange} name="name" />
    </div>
  )
}

const DescriptionEditor = props => {
  const { value, onChange } = props
  const handleChange = useCallback(e => onChange(e.target.value, e), [onChange])

  return (
    <div className="form-group">
      <label className="form-label">Description</label>
      <TextArea
        id="description"
        value={value}
        onChange={handleChange}
        name="description"
      />
    </div>
  )
}

/*
 * NOTE: Currently reusable actions have only one cell, thus the
 * locked value here. I'm doing this like this to make it easier
 * to update in the future when/if we add multi-cell capabilities.
 */
const cellIdx = 0

const ActionForm = props => {
  const { afterSubmit, saveText = "SAVE" } = props
  const dispatch = useDispatch()
  const loading = useSelector(selectActionLoading)
  const error = useSelector(selectActionError)
  const action = useSelector(selectAction)
  const source = useSelector(selectActionSource)
  const color = useSelector(selectActionColor)
  const icon = useSelector(selectActionIcon)
  const handleNameChange = useAction(actions.setName)
  const handleDescriptionChange = useAction(actions.setDescription)
  const handleColorChange = useAction(actions.setColor)
  const mapCmToSource = useCallback(
    cm => ({ value: cm.getValue(), cellIdx }),
    []
  )
  const handleSourceUpdate = useAction(actions.setCellSource, mapCmToSource)

  const handleSubmit = useCallback(
    e => {
      dispatch(actions.saveAction()).then(() => {
        afterSubmit && afterSubmit(e)
      })
    },
    [afterSubmit, dispatch]
  )

  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  return (
    <form>
      <ColorPicker onChange={handleColorChange} color={color} icon={icon} />
      <NameEditor value={action.name} onChange={handleNameChange} />
      <DescriptionEditor
        value={action.description}
        onChange={handleDescriptionChange}
      />
      <CodeEditor
        source={source}
        revisionId={action.revisionId}
        onChange={handleSourceUpdate}
      />
      <Inputs />
      <Button type="primary" onClick={handleSubmit}>
        {saveText}
      </Button>
    </form>
  )
}

export default ActionForm
