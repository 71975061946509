import React, { useState } from "react"
import { Upload } from "antd"
import { toast } from "react-toastify"

import { withFormFieldWrapper } from "../FormFieldWrapper"

const showUploadListOpts = { showPreviewIcon: false }
const acceptedTypes = ["image/jpeg", "image/png", "image/svg+xml"]
const beforeUpload = file => {
  const isAcceptedType = acceptedTypes.includes(file.type)
  if (!isAcceptedType) {
    toast.error("Only jpeg, png, and svg files are accepted")
  }
  return isAcceptedType
}

const getFileConfig = file => {
  const { name, type, size, lastModified } = file
  const fileConfig = {
    name,
    type,
    size,
    lastModified,
    url: file.response,
  }
  return fileConfig
}

// NOTE: this component is expected to work with the base64ImageUpload schema (see schemas/dictionary.js)
const Base64ImageUploadCore = props => {
  const { name, onChange, value } = props
  const [status, setStatus] = useState("idle")
  const [selectedFileList, setSelectedFileList] = useState(value ? [value] : [])

  const handleChange = info => {
    switch (info.file.status) {
      case "uploading":
        setSelectedFileList(info.fileList)
        setStatus("loading")
        break
      case "done":
        const firstFile = getFileConfig(info.fileList[0])
        setSelectedFileList([firstFile])
        onChange(firstFile)
        break
      default:
        // error or removed
        setSelectedFileList([])
        setStatus(info.file.status)
        onChange()
        break
    }
  }

  return (
    <>
      <Upload
        name={name}
        listType="picture-card"
        onChange={handleChange}
        beforeUpload={beforeUpload}
        fileList={selectedFileList}
        showUploadList={showUploadListOpts}
        // Overrides the default xhr behavior
        customRequest={({ file, onSuccess }) => {
          setTimeout(() => {
            const reader = new FileReader()
            reader.addEventListener("load", () => {
              // pass the base64 encoded image to the upload handers response
              onSuccess(reader.result)
            })
            reader.readAsDataURL(file)
          }, 0)
        }}
      >
        {!selectedFileList?.length ? (
          <div>{status === "loading" ? "Loading..." : "Upload"}</div>
        ) : null}
      </Upload>
    </>
  )
}

const Base64ImageUpload = withFormFieldWrapper(Base64ImageUploadCore)
Base64ImageUpload.Core = Base64ImageUploadCore
export default Base64ImageUpload
