import { useRef, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { actions } from "../reducers/appReducer"
import { selectAppPages, selectAppWidget } from "../selectors/app"

const getNewPages = (pages, navItems) => {
  return navItems.reduce((acc, navItem) => {
    const { pageId, title } = navItem
    if (pages[pageId]) return acc
    return [...acc, { pageId, title }]
  }, [])
}

const getRemovedPages = (prevNavItems, navItems) => {
  return prevNavItems.reduce((acc, navItem) => {
    const { pageId } = navItem
    const pageExists = navItems.find(ni => ni.pageId === pageId)
    if (pageExists) return acc
    return [...acc, pageId]
  }, [])
}

const getRenamedPages = (prevNavItems, navItems) => {
  return prevNavItems.reduce((acc, navItem) => {
    const { pageId, title } = navItem
    const existingPage = navItems.find(ni => ni.pageId === pageId)
    if (existingPage && title !== existingPage.title) {
      return [...acc, { pageId, name: title }]
    }
    return acc
  }, [])
}

/**
 * This side effect can be used for widgets that contain the navItemsSchema.
 * TODO: form side effects were introduced after this hook was created. this logic is probably better suited as a side effect for the tab widget
 */
const useNavItemsSideEffect = (widgetId, editing) => {
  const dispatch = useDispatch()
  const navItems = useSelector(state => {
    const widget = selectAppWidget(state, { widgetId })
    return widget.options.navItems
  })
  const navItemsRef = useRef(navItems)
  const pages = useSelector(selectAppPages)

  useEffect(() => {
    if (!editing) return
    const newPages = getNewPages(pages, navItems)
    const removedPages = getRemovedPages(navItemsRef.current, navItems)
    const renamedPages = getRenamedPages(navItemsRef.current, navItems)
    navItemsRef.current = navItems
    newPages.forEach(({ pageId, title }) =>
      dispatch(actions.addPageWithSync({ pageId, name: title }))
    )
    removedPages.forEach(pageId => dispatch(actions.removePageWithSync(pageId)))
    renamedPages.forEach(page => dispatch(actions.renamePageWithSync(page)))
  }, [dispatch, editing, navItems, navItemsRef, pages])
}

export default useNavItemsSideEffect
