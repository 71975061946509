import { merge } from "lodash"

import { formatValue } from "./format"

export const getAliasedColumnName = columnConfig => {
  const { type, column, aggregate, arguments: args } = columnConfig || {}
  if (type === "computed") return column
  if (aggregate === "PERCENTILE_CONT" && args?.length)
    return `${column}_percentile_${args[0]}`
  if (aggregate) return `${column} - ${aggregate}`
  return column
}

export const findIndexForColumn = (column, columns) => {
  const columnName = column.name || getAliasedColumnName(column)
  return columns.findIndex(column => {
    return column.name === columnName || column.column === columnName
  })
}

export const findColumnLabelName = (column, label) => {
  const { labels } = column?.formatOptions || {}
  if (!labels?.length) return label
  const labelSpec = labels.find(l => l.label === label)
  if (!labelSpec) return label
  return labelSpec.title || labelSpec.label
}

export const formatColumnValue = (column, value) => {
  const { format, formatOptions, formatRange, groupByTime } = column || {}
  let formattedValue = value

  if (column.type === "string" && column.formatOptions?.labels?.length) {
    const labelConfig = findLabelConfig(value, column.formatOptions.labels)
    // if there is a config for this label, use the configured label name rather than the raw label name
    if (labelConfig) {
      formattedValue = labelConfig.title || labelConfig.label || value || "NULL"
    }
  }

  if (!format) return formattedValue
  return formatValue(
    { ...formatOptions, format, formatRange, groupByTime },
    formattedValue
  )
}

// column names are unique and can be used to find the column config.
// 'column' and 'name' are used interchangeably
export const findColumnConfigFromName = (columnName, columns) => {
  return columns?.find(({ name, column }) => {
    return (name || column) === columnName
  })
}

const columnHasFormattingOptions = column => {
  const { format, formatOptions } = column || {}
  return format !== "" || Object.keys(formatOptions || {}).length
}

// This function is used to merge the column config set in the dataset with the
// local column config. Local column formatting options take precedence over the
// dataset's column config
export const findColumnConfig = (localColumn, columnConfigs) => {
  const selectedColumnName = getAliasedColumnName(localColumn)
  const columnConfig = findColumnConfigFromName(
    selectedColumnName,
    columnConfigs
  )
  if (!columnHasFormattingOptions(localColumn)) {
    const { format, formatOptions, ...restColumn } = localColumn
    return merge({}, columnConfig, restColumn)
  }
  return merge({}, columnConfig, localColumn)
}

export const getDefaultColumnName = (column, labelSpec) => {
  const { column: _rawColumnName, name, label, aggregate } = column || {}
  if (labelSpec) return labelSpec.title || labelSpec.label
  if (!label && name) return name
  return `${label || _rawColumnName}${aggregate ? " - " + aggregate : ""}`
}

export const orderLabels = (dataLabels, labelOptions) => {
  const labels = [...dataLabels]
  const labelsInOrder = labelOptions.reduce((acc, labelOption) => {
    const foundLabel = labels.find(label => label === labelOption.label)
    // if the label is in the data, remove it from the list of labels
    if (foundLabel) {
      labels.splice(labels.indexOf(foundLabel), 1)
      return [...acc, foundLabel]
    }
    return acc
  }, [])

  // return the labels in the order specified in the label options, followed by
  // any labels that were not specified in the label options
  return [...labelsInOrder, ...labels]
}

export const getUsableColumnLabels = (values, column) => {
  const { formatOptions } = column || {}
  const labelFormats = formatOptions?.labels || []

  const valuesWithNull = [...new Set(values)].map(label => {
    if ([null, undefined].includes(label)) return "NULL"
    return label
  })

  const orderedLabels = orderLabels(valuesWithNull, labelFormats)

  return orderedLabels.reduce((acc, rawLabel) => {
    const labelConfig = findLabelConfig(rawLabel, labelFormats)
    if (!labelConfig || !labelConfig.usable) {
      return [...acc, { value: rawLabel }]
    }
    const label = labelConfig.title || labelConfig.label
    return [...acc, { label: `${label}`, value: rawLabel }]
  }, [])
}

export const findLabelConfig = (label, labelConfigs) => {
  return labelConfigs?.find(labelConfig => labelConfig.label === label)
}

const sortLabels = labels => {
  return [...labels].sort((a, b) => {
    if (!a || !b) return false
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: "base",
    })
  })
}

export const getLabelsConfigForColumn = (column, labels) => {
  const columnConfigLabels = column.formatOptions?.labels || []
  return sortLabels(labels).map(label => {
    const existingLabelConfig = findLabelConfig(label, columnConfigLabels)
    return existingLabelConfig || { label, usable: true, title: label }
  })
}
