export const cellStatusTypes = {
  loading: "loading",
  waiting: "waiting",
  ok: "ok",
  error: "error",
}

export const fontSizes = {
  small: "9px",
  medium: "12px",
  large: "18",
}

export const pythonTypes = [
  "int",
  "str",
  "any",
  "float",
  "complex",
  "list",
  "tuple",
  "range",
  "dict",
  "set",
  "frozenset",
  "bool",
  "bytes",
  "bytearray",
  "memoryview",
]
