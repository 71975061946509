import React from "react"
import styled from "styled-components"
import { Col } from "antd"
import { NavLink } from "react-router-dom"

import { Content, useCurrentCustomer } from "@dbai/ui-staples"

import ThemeRegistry from "./Themes"
// import WidgetRegistry from "./WidgetRegistry"
import AppsLandingPage from "./AppsLandingPage"
import DBBoundary from "components/shared/DBBoundary"

const appsPath = cname => `/${cname}/apps`
export const useNavItems = () => {
  const [customer] = useCurrentCustomer()
  const { normalizedName: cname } = customer || {}
  const path = appsPath(cname)
  return [
    { Component: AppsLandingPage, path, label: "Apps", exact: true },
    {
      Component: ThemeRegistry,
      path: `${path}/themes`,
      label: "Themes",
    },
    // {
    //   Component: WidgetRegistry,
    //   path: `${path}/widget-registry`,
    //   label: "Widget Registry",
    //   adminOnly: true,
    // },
  ]
}

const StackedNav = styled.nav`
  display: flex;
  flex-direction: column;
`

const StyledLink = styled(NavLink)`
  padding: 15px 20px;
  border-radius: 2px;

  &.active {
    font-weight: bold;
    &:hover {
      text-decoration: none;
    }
  }
`

const ContentWithStackedNav = props => {
  const navItems = useNavItems()
  return (
    <Content id="apps">
      <Col span={4}>
        <StackedNav>
          {navItems.map(navItem => {
            const { path, label, exact, adminOnly } = navItem
            if (adminOnly) {
              return (
                <DBBoundary subject="apps" action="LIST">
                  <StyledLink key={path} to={path} exact={exact}>
                    {label}
                  </StyledLink>
                </DBBoundary>
              )
            }
            return (
              <StyledLink key={path} to={path} exact={exact}>
                {label}
              </StyledLink>
            )
          })}
        </StackedNav>
      </Col>
      <Col span={20}>{props.children}</Col>
    </Content>
  )
}

export default ContentWithStackedNav
