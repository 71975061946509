import React, { Component } from "react"

import useErrorLoggerContext from "../hooks/useErrorLoggerContext"

class ErrorCatcher extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, error: null, info: null }
  }

  componentDidUpdate(priorProps) {
    const {
      state: { hasError },
      props,
    } = this
    if (!hasError) return

    if (props.children !== priorProps.children) {
      this.setState({ hasError: false })
    }
  }

  componentDidCatch(error) {
    this.setState({
      error,
      hasError: true,
    })

    this.props.logger.notify(error)
  }

  render() {
    const { hasError, error, info } = this.state
    if (hasError) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {error && error.toString()}
            <br />
            {info && info.componentStack}
          </details>
        </div>
      )
    }

    const { children } = this.props
    return children
  }
}

const ErrorBoundary = ({ children }) => {
  const { logger } = useErrorLoggerContext()
  return <ErrorCatcher logger={logger}>{children}</ErrorCatcher>
}

export default ErrorBoundary
