import React from "react"
import { isArray } from "lodash"
import { Space, Popconfirm } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faKey,
  faTag,
  faClock,
  faHashtag,
} from "@fortawesome/pro-solid-svg-icons"
import { faCalculatorSimple } from "@fortawesome/pro-regular-svg-icons"

import { getRowValue } from "../../lib/tableValues"
import {
  getSafeTitle,
  getColumnSort,
  getColumnSearch,
} from "../../lib/tableColumns"

export const AUTO_INCREMENT_COLUMN_NAME = "_id_"

// TODO: this method is very similar to the table widget and pivot table widget getOrderBy methods.
// consider refactoring
const getOrderBy = (sortedColumns, columnOptions) => {
  let orderBy = []
  let orderByColumnNames = []
  const sortColumns = isArray(sortedColumns) ? sortedColumns : [sortedColumns]
  const validSortColumns = sortColumns.filter(({ column }) => {
    return !!column?.column && column?.columnType === "datasetColumn"
  })

  // if the user has explicitely sorted a column, use that
  if (validSortColumns.length) {
    validSortColumns.forEach(({ column, order }) => {
      orderByColumnNames.push(column.column)
      orderBy.push(`${column.column} ${order === "ascend" ? "ASC" : "DESC"}`)
    })
  } else {
    // case for when the user has not explicitely sorted a column, but columns are sorted by default
    columnOptions?.forEach(({ column, defaultSortOrder, sortable }) => {
      if (sortable && defaultSortOrder) {
        orderByColumnNames.push(column)
        orderBy.push(
          `${column} ${defaultSortOrder === "ascend" ? "ASC" : "DESC"}`
        )
      }
    })
  }

  return [orderBy, orderByColumnNames]
}

const getFilterBy = (filters, columns) => {
  if (!filters) return []
  const filterBy = Object.entries(filters).reduce((acc, [dataIndex, value]) => {
    const column = columns.find(column => column.column === dataIndex)
    if (!column || [null, undefined].includes(value)) return acc
    const safeValue = isArray(value) ? value[0] : value
    return [
      ...acc,
      {
        op: "like",
        cast: "text",
        value: `%${safeValue}%`,
        column: column.name || column.column,
      },
    ]
  }, [])
  return filterBy
}

export const getSafeTableProps = table => {
  if (!table) return {}
  const { title, ...rest } = table
  if (Boolean(title)) {
    return { title: () => title, ...rest }
  }
  return rest
}

export const getDataLimit = pagination => {
  if (!pagination) return 10
  return (
    pagination.pageSize ||
    pagination.defaultPageSize ||
    pagination.pageSizeOptions[0] ||
    10
  )
}

export const getOffset = (pagination, current) => {
  const limit = getDataLimit(pagination)
  return limit * (current - 1)
}

export const generateDatasetJSONSchema = (
  showPresets,
  datasetId,
  uniqueKeys,
  columns,
  row
) => {
  const properties = columns.reduce((acc, { name, type, ...rest }, index) => {
    // do not persist computed columns as they do not exist in the database
    if (type === "computed") return acc

    // do not add auto increment column to form schema
    if (name === AUTO_INCREMENT_COLUMN_NAME)
      return {
        ...acc,
        [name]: {
          type: ["number", "string", "boolean"],
          metadata: {
            hidden: true,
          },
        },
      }

    acc[name] = {
      type: ["number", "string", "boolean"],
      metadata: {
        datasetId,
        showPresets,
        column: name,
        columnType: type,
        allowInput: true,
        focusOnMount: index === 0,
        excludeWidgetFilters: true,
        valuePresets: "columnValues",
        component: "ColumnValueInput",
      },
    }
    return acc
  }, {})

  // do not add auto increment column to required fields
  const requiredFields = uniqueKeys?.filter(key => {
    return key !== AUTO_INCREMENT_COLUMN_NAME
  })

  return {
    type: "object",
    required: requiredFields,
    default: {},
    properties,
  }
}

const iconMap = {
  string: faTag,
  datetime: faClock,
  computed: faCalculatorSimple,

  int: faHashtag,
  float: faHashtag,
  number: faHashtag,
}

const ColumnTitle = props => {
  const { column } = props
  const title = column.title ?? getSafeTitle(column)
  const { type } = column
  const icon = column.isUniqueKey ? faKey : iconMap[type]
  return (
    <Space>
      <FontAwesomeIcon icon={icon} />
      {title}
    </Space>
  )
}

export const getColumnDataIndex = columnName => {
  return columnName === "key" ? "_key" : columnName
}

export const getTableColumns = (uniqueKeys, columnOptions) => {
  return columnOptions
    .filter(column => !column.hidden)
    .map((column, index) => {
      const isUniqueKey = uniqueKeys.includes(column.column)
      const dataIndex = column.dataIndex ?? column.column
      const safeDataIndex = getColumnDataIndex(dataIndex)
      const columnWithDataIndex = {
        ...column,
        // prevents antd from throwing an error when dataIndex is "key"
        dataIndex: safeDataIndex,
      }

      const className =
        dataIndex !== AUTO_INCREMENT_COLUMN_NAME ? "dataset-column" : ""

      return {
        ...columnWithDataIndex,
        ...getColumnSort(columnWithDataIndex),
        ...getColumnSearch(columnWithDataIndex),
        title: (
          <ColumnTitle column={columnWithDataIndex} isUniqueKey={isUniqueKey} />
        ),
        onCell: (record, index) => ({
          record,
          uniqueKeys,
          dataIndex: safeDataIndex,
          column: columnWithDataIndex,
        }),
        className,
        isUniqueKey,
        column: column.column, // used for server-side pagination
        columnType: "datasetColumn",
      }
    })
}

export const getDataSource = (dataset, tableColumns, current) => {
  const { rows = [], columns = [] } = dataset || {}

  if (!tableColumns?.length || !rows?.length) return []
  return rows.reduce((validRows, row, rowIndex) => {
    const tableRows = tableColumns.reduce((final, tableColumn) => {
      if (!tableColumn.dataIndex) return final
      return {
        ...final,
        [tableColumn.dataIndex]: getRowValue(columns, row, tableColumn),
      }
    }, {})

    const rawDataRows = columns.reduce((acc, column) => {
      return {
        ...acc,
        [column.name]: getRowValue(columns, row, column),
      }
    }, {})

    if (Object.keys(tableRows).length) {
      validRows.push({
        key: `${rowIndex}-${current}`,
        ...tableRows,
        ...rawDataRows,
      })
    }

    return validRows
  }, [])
}

export const getDataSourceQuery = (datasetId, queryParams, columnOptions) => {
  const select = columnOptions.map(({ column, type }) => ({ column, type }))

  const limit =
    queryParams.pagination.pageSize ||
    queryParams.pagination.defaultPageSize ||
    10

  const offset =
    queryParams.pagination.pageSize * (queryParams.pagination.current - 1)

  // TODO: consider grouping order by columns. Table widget groups the order by columns, not sure why
  const [orderBy] = getOrderBy(queryParams.sort, columnOptions)

  return {
    limit,
    offset,
    select,
    orderBy,
    datasetId,
    where: getFilterBy(queryParams.filters, columnOptions),
  }
}

export const getOperationColumn = handleDelete => ({
  width: 100,
  fixed: "right",
  title: "Actions",
  dataIndex: "operation",
  onCell: (record, index) => ({
    record,
    dataIndex: "operation",
  }),
  render: (_, record) => {
    const { key, operation, ...restRecord } = record
    return (
      <Popconfirm
        title="Sure to delete?"
        onConfirm={() => handleDelete(restRecord)}
      >
        <a href>Delete</a>
      </Popconfirm>
    )
  },
})
