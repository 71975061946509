import { getAliasedColumnName } from "./datasetColumns"

const stringComparatorMap = {
  "=": (a, b) => b.includes(a),
  "!=": (a, b) => !b.includes(a),
  default: () => false,
}

const numberComparatorMap = {
  ">": (a, b) => a > parseFloat(b),
  "<": (a, b) => a < parseFloat(b),
  "=": (a, b) => a === parseFloat(b),
  "!=": (a, b) => a !== parseFloat(b),
  ">=": (a, b) => a >= parseFloat(b),
  "<=": (a, b) => a <= parseFloat(b),
  default: () => false,
}

const deconstructEffect = effect => {
  const { attribute, value } = effect
  if (!value) return {}
  return { [attribute]: value }
}

const deconstructTextDecoration = effect => {
  const { decorations = [] } = effect
  return decorations.reduce((final, decoration) => {
    switch (decoration) {
      case "bold":
        return { ...final, fontWeight: "bold" }
      case "italic":
        return { ...final, fontStyle: "italic" }
      case "underline":
      case "overline":
      case "line-through":
        return { ...final, textDecoration: decoration }
      default:
        return final
    }
  }, {})
}

const effectHandlers = {
  color: deconstructEffect,
  backgroundColor: deconstructEffect,
  textDecoration: deconstructTextDecoration,
}

export const getStylesFromEffects = effects => {
  return effects.reduce((acc, effect) => {
    const handler = effectHandlers[effect.attribute] || deconstructEffect
    const stylesFromEffect = handler(effect) || {}
    return { ...acc, ...stylesFromEffect }
  }, {})
}

const getPercentileArgument = percentile => {
  return [`${parseFloat(percentile) / 100}`]
}

const getCompareValue = (condition, data) => {
  const {
    valueType,
    value,
    secondaryColumnAggregate,
    secondaryColumnPercentile,
  } = condition
  if (valueType === "datasetColumn") {
    const columnName = getAliasedColumnName({
      column: value,
      aggregate: secondaryColumnAggregate,
      arguments: getPercentileArgument(secondaryColumnPercentile),
    })
    return data[columnName]
  }
  return value
}

const compareConditionValues = (
  { operator, conjunction, columnType },
  a,
  b,
  prev
) => {
  const comparatorMap = ["string", "categorical"].includes(columnType)
    ? stringComparatorMap
    : numberComparatorMap
  const compare = comparatorMap[operator] || comparatorMap.default
  if (conjunction === "and") return prev && compare(a, b)
  return prev || compare(a, b)
}

export const getConditionValues = (condition, data) => {
  const { column, aggregate, percentile } = condition
  const columnName = getAliasedColumnName({
    column,
    aggregate,
    arguments: getPercentileArgument(percentile),
  })
  const compareValue = getCompareValue(condition, data)
  const rowValue = data[columnName]
  return [rowValue, compareValue]
}

const resolveConditions = (conditions, data) => {
  return conditions.reduce(
    (final, condition) => {
      const [a, b] = getConditionValues(condition, data)
      return compareConditionValues(condition, a, b, final)
    },
    [true]
  )
}

const validateConditions = (conditions, aggregationRequired) => {
  return conditions?.filter(condition => {
    const { column, columnType, aggregate, operator, value, percentile } =
      condition
    switch (true) {
      case aggregationRequired &&
        ["int", "float", "numerical", "datetime"].includes(columnType) &&
        !aggregate:
      case !column || !operator || [null, undefined].includes(value):
      case aggregate === "PERCENTILE_CONT" &&
        [null, undefined].includes(percentile):
        return false
      default:
        return true
    }
  })
}

const validateEffects = effects => {
  return effects?.filter(effect => {
    const { attribute } = effect
    return !!attribute
  })
}

const validateConditionalFormats = (
  conditionalFormats,
  aggregationRequired
) => {
  return conditionalFormats?.filter(conditionalFormat => {
    const { conditions, effects } = conditionalFormat
    const validConditions = validateConditions(conditions, aggregationRequired)
    if (!validConditions?.length) return false
    return validateEffects(effects)?.length
  })
}

export const getSelection = ({ column, aggregate, percentile }) => {
  if (aggregate === "PERCENTILE_CONT") {
    return {
      column,
      aggregate,
      arguments: getPercentileArgument(percentile),
    }
  } else {
    return { column, aggregate }
  }
}

export const getConditionalStyles = (
  conditionalFormat,
  data,
  aggregationRequired
) => {
  if (!conditionalFormat?.length) return {}

  // get valid conditional formatting options
  const validatedConditionalFormat = validateConditionalFormats(
    conditionalFormat,
    aggregationRequired
  )
  if (!validatedConditionalFormat?.length) return {}

  // reverse conditions so that the first conditions take precedence over the last conditions
  const reversedConditionalFormat = [...validatedConditionalFormat].reverse()
  return reversedConditionalFormat.reduce((final, condtion) => {
    const { conditions = [], effects = [] } = condtion
    const validConditions = resolveConditions(conditions, data)
    if (validConditions) {
      return { ...final, ...getStylesFromEffects(effects) }
    }
    return final
  }, {})
}

export const getConditionalFormatQueryParams = (
  widget,
  aggregationRequired
) => {
  const { conditionalFormat = [], colorScale = [] } = widget
  const select = []
  validateConditionalFormats(conditionalFormat, aggregationRequired).forEach(
    ({ conditions }) => {
      conditions?.forEach(
        ({
          value,
          column,
          aggregate,
          valueType,
          sourceType,
          percentile,
          secondaryColumnAggregate,
          secondaryColumnPercentile,
          ...rest
        }) => {
          if (sourceType === "datasetColumn") {
            const selection = getSelection({ column, aggregate, percentile })
            select.push(selection)
          }

          if (valueType === "datasetColumn") {
            const secondaryColumnSelection = getSelection({
              column: value,
              aggregate: secondaryColumnAggregate,
              percentile: secondaryColumnPercentile,
            })
            select.push(secondaryColumnSelection)
          }
        }
      )
    }
  )
  colorScale.forEach(({ column, colors, aggregate, value, ...rest }) => {
    colors?.forEach(({ valueType, value }) => {
      const selection = getSelection({ column, aggregate, percentile: value })
      select.push(selection)
    })
  })
  return select
}
