import backgroundColor from "../../schemas/shared/backgroundColor"
import objectSchema from "../../JSONSchemaForm/containers/SchemaObject/fieldOptionsSchema"

const saveButtonSchema = {
  type: "object",
  default: {},
  title: "Save Button Options",
  metadata: {
    hideLabel: false,
  },
  properties: {
    "form/saveButton/text": {
      type: "string",
      default: "Submit",
      title: "Button Text",
    },
    "form/saveButton/type": {
      type: "string",
      title: "Button Type",
      default: "primary",
      enum: ["primary", "ghost", "dashed", "link", "text", "default"],
      metadata: {
        labels: ["Primary", "Ghost", "Dashed", "Link", "Text", "Default"],
      },
    },
    "form/saveButton/block": {
      type: "boolean",
      default: false,
      title: "Block Style",
    },
  },
}

export default {
  default: {},
  type: "object",
  title: "Widget",
  metadata: {
    noStyle: true,

    sectionType: "tabs",
    sections: {
      fields: {
        title: "Form Fields",
        properties: ["properties"],
      },
      display: {
        title: "Display",
        properties: [
          "metadata/hideLabel",
          "backgroundColor",
          "layout",
          "verticalGutter",
          "horizontalGutter",
          "metadata/sectionType",
          "metadata/sections",
          "metadata/sectionProps/defaultActiveKey",
          "metadata/sectionProps/ghost",
          "metadata/sectionProps/bordered",
          "metadata/sectionProps/accordion",
          "metadata/sectionProps/hideArrow",
        ],
      },
      form: {
        title: "Save Settings",
        properties: [
          "form/autoSave",
          "form/resetOnSave",
          "form/onSubmit",
          "form/showErrorsList",
          "form/allowSaveWithErrors",
          "saveButton",
          "form/endpoints",
        ],
      },
    },
  },
  dependencies: {
    "form/onSubmit": {
      if: {
        properties: {
          "form/onSubmit": {
            contains: { enum: ["triggerEndpoint"] },
          },
        },
      },
      then: {
        properties: {
          "form/endpoints": {
            type: "array",
            title: "Trigger Endpoints",
            items: { type: "string" },
            metadata: {
              isMulti: true,
              component: "EndpointSelect",
            },
          },
        },
      },
    },
    "form/autoSave": {
      if: {
        properties: {
          "form/autoSave": {
            const: false,
          },
        },
      },
      then: {
        properties: {
          "form/showErrorsList": {
            default: false,
            type: "boolean",
            title: "Show Error Message List",
          },
          "form/allowSaveWithErrors": {
            type: "boolean",
            default: false,
            title: "Allow Save With Errors",
          },
          "form/resetOnSave": {
            default: true,
            type: "boolean",
            title: "Reset on Save",
          },
          saveButton: saveButtonSchema,
        },
      },
    },
  },
  properties: {
    backgroundColor: backgroundColor,
    ...objectSchema.properties,
    // Title is included but hidden. When rendered, the widget's name is used as the form title
    title: {
      type: "string",
      title: "Title",
      metadata: {
        hidden: true,
      },
    },
    "metadata/hideLabel": {
      default: true,
      type: "boolean",
      title: "Hide Title",
    },

    "form/onSubmit": {
      type: "array",
      title: "Save Actions",
      metadata: {
        labels: [
          "Save Data to File",
          "Save Data to Dataset",
          "Save Data to App Variables",
          "Trigger Endpoint",
        ],
      },
      items: {
        type: "string",
        enum: [
          "saveJSON",
          "collectData",
          "saveAppVariables",
          "triggerEndpoint",
        ],
      },
    },
    "form/autoSave": {
      type: "boolean",
      default: false,
      title: "Autosave",
    },
  },
}
