import React from "react"
import styled from "styled-components"
import ReactSelect from "react-select"
import { Row, Select as AntSelect } from "antd"

import { useFormSet, useFormState } from "./hooks"
import { getOverrides } from "./SelectOverrides"
import { get } from "lodash"

const StyledSelectLabel = styled.label`
  margin-bottom: 0;
  font-weight: bold;
  background: #ffffff;
  float: left;
  border: 1px solid #cccccc;
  height: 32px;
  border-radius: 5px 0 0 5px;
  padding: 7px 10px;
`

const StyledAntSelect = styled(AntSelect)`
  && {
    width: 100%;
    .ant-select-selector {
      border: 1px solid hsl(0, 0%, 80%);
      border-radius: 0 5px 5px 0;
      border-left: none;
    }
  }
`

const StyledSelect = styled.div`
  float: left;
  width: 100%;
  margin-bottom: 10px;

  .db-select__control {
    border-radius: 0 4px 4px 0;
    border-left: none;
    background-color: #ffffff;
    border-color: ${props => props.theme.inputBackground4};
  }
`

const compactLabelStyle = `
  border: none;
  background: transparent;
  height: inherit;
  padding: 0 3px;
  font-weight:400;
`

const Label = styled.div`
  font-weight: bold;
  background: #ffffff;
  float: left;
  border: 1px solid #cccccc;
  height: 38px;
  border-radius: 5px 0 0 5px;
  padding: 7px 10px;
  ${props => props.type === "compact" && compactLabelStyle}
`

const compactStyles = `
  width:100%;
  .db-select__control {
    border-width: 0px;
    font-size: 12px;
    min-height: 100%;
    border-radius: 0;
    flex-direction: row-reverse;
    background-color: #ffffff;
    border-color: ${props => props.theme.inputBackground4};
    &:hover {
      background-color: #ffffff;
    }
    &:focus {
      outline: none;
    }
    &--is-focused {
      border-color: ${props => props.theme.inputBackground4};
      box-shadow: none;
    }
    .db-select__value-container {
      padding: 0px;
      text-align: right;
      div {
        padding: 0px;
        margin: 0px;
      }
      .db-select__single-value {
        width: 100%;
      }
      .db-select__placeholder {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        max-width: calc(100% - 8px);
      }
      .db-select__input {
      }
    }
    .db-select__indicators {
      padding: 0px 2px;
      svg {
        width: 14px;
      }
    }
  }
`

const StyledReactSelect = styled(ReactSelect)`
  ${props => props.type === "compact" && compactStyles}
`

const Select = props => {
  const state = useFormState()

  const {
    className,
    name,
    value = get(state, name, null),
    label,
    type,
    menuStyles,
    selectComponents,
    isMulti = false,
    ...rest
  } = props

  const overrideComponents = getOverrides(type)
  const components = { ...overrideComponents, ...selectComponents }

  const styles = {
    menu: base => ({ ...base, ...menuStyles }),
    menuPortal: base => ({ ...base, zIndex: 1024, marginTop: -60 }),
  }

  const childProps = { name, components, value, type, styles, ...rest }
  const Comp = isMulti ? MultiSelect : SingleSelect

  if (!label) return <Comp className={className} {...childProps} />
  return (
    <StyledSelect className={`labeled-select ${className}`}>
      <Label type={type}>{label}</Label>
      <Comp {...childProps} />
    </StyledSelect>
  )
}

const SingleSelect = props => {
  const formSet = useFormSet()
  const {
    action = formSet,
    name,
    value,
    options,
    reset,
    onChange,
    menuShouldScrollIntoView = true,
    menuPlacement = "auto",
    ...rest
  } = props

  const realValue = value ? options.find(o => o.value === value) : null
  const handleChange = option => {
    const value = option ? option.value : null

    !reset && action({ name, value })
    onChange && onChange(value, option)
  }

  return (
    <StyledReactSelect
      name={name}
      value={realValue}
      options={options}
      onChange={handleChange}
      classNamePrefix="db-select"
      menuShouldScrollIntoView={menuShouldScrollIntoView}
      menuPlacement={menuPlacement}
      {...rest}
    />
  )
}

const MultiSelect = props => {
  const formSet = useFormSet()
  const {
    action = formSet,
    name,
    value,
    options,
    onChange,
    reset,
    menuShouldScrollIntoView = true,
    menuPlacement = "auto",
    ...rest
  } = props

  const realValue = value ? options.filter(o => value.includes(o.value)) : []
  const handleChange = selected => {
    const value = (selected || []).map(o => o.value)

    !reset && action({ name, value })
    onChange && onChange(value)
  }

  return (
    <StyledReactSelect
      name={name}
      value={realValue}
      options={options}
      onChange={handleChange}
      classNamePrefix="db-select"
      menuShouldScrollIntoView={menuShouldScrollIntoView}
      menuPlacement={menuPlacement}
      isMulti
      {...rest}
    />
  )
}

const AntDSelect = props => {
  const state = useFormState()
  const formSet = useFormSet()
  const {
    name,
    label,
    reset,
    options,
    onChange,
    action = formSet,
    value = get(state, name, null),
    ...rest
  } = props

  const handleChange = (value, option) => {
    !reset && action({ name, value })
    onChange && onChange(value, option)
  }

  const childProps = { ...rest, value, onChange: handleChange }

  if (!label) return <StyledAntSelect {...childProps} />
  return (
    <Row wrap={false}>
      <StyledSelectLabel>{label}</StyledSelectLabel>
      <StyledAntSelect {...childProps} />
    </Row>
  )
}

Select.components = {
  Label: Label,
  Wrapper: StyledSelect,
  Select: StyledReactSelect,
}
Select.AntDSelect = AntDSelect
export default Select
