import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"

import { Card, Spinner, Select, ErrorMessage } from "@dbai/ui-staples"

import { selectWorkflows } from "selectors"
import { actions as scheduleActions } from "reducers/scheduleReducer"
import { actions as packagesActions } from "reducers/packagesReducer"
import { actions as workflowsActions } from "reducers/workflowsReducer"
import PackagesTable from "components/pages/Workflows/shared/PackagesTable"

const PackagesCard = styled(Card)`
  margin-bottom: 10px;
`

const workflowToOpt = wf => ({ label: wf.name, value: wf })

const SearchPackages = ({ customer }) => {
  const dispatch = useDispatch()
  const { data, loading, error } = useSelector(selectWorkflows)
  const packages = useSelector(state => state.packages.packages)

  const [selected, setSelected] = useState(null)

  useEffect(() => {
    return () => dispatch(packagesActions.reset())
  }, [dispatch])

  useEffect(() => {
    dispatch(workflowsActions.loadWorkflows({ cname: customer.normalizedName }))
    return () => dispatch(workflowsActions.reset)
  }, [dispatch, customer.normalizedName])

  useEffect(() => {
    if (!selected) return

    dispatch(
      packagesActions.loadPackages({
        cname: customer.normalizedName,
        workflowId: selected.id,
      })
    )

    return () => dispatch(packagesActions.reset)
  }, [dispatch, selected, customer.normalizedName])

  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  const workflowOpts = data.map(workflowToOpt)

  const handlePackageSelect = pkg => {
    dispatch(scheduleActions.set({ name: "packageId", value: pkg.id }))
  }

  return (
    <PackagesCard nopad>
      <Card.Header>
        <Select
          placeholder="Select a Workflow..."
          options={workflowOpts}
          onChange={setSelected}
          value={selected}
          reset
        />
      </Card.Header>
      <PackagesTable packages={packages} onSelect={handlePackageSelect} />
    </PackagesCard>
  )
}

export default SearchPackages
