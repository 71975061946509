const dataEditorSchema = {
  type: "object",
  metadata: {
    sideEffects: [
      {
        resetValue: [],
        type: "resetField",
        sourceName: "options.datasetId",
        targetName: "options.columns",
      },
    ],
  },
  properties: {
    datasetId: {
      type: "string",
      title: "Dataset",
      metadata: {
        component: "Dataset",
        wrapperStyles: {
          padding: "16px",
        },
      },
    },
    table: {
      default: {},
      type: "object",
      title: "Table Options",
      metadata: {
        layout: { gutter: [16] },
        sectionProps: {
          defaultActiveKey: ["general"],
        },
        sections: {
          general: {
            title: "General Options",
            properties: ["general"],
          },
          pagination: {
            title: "Pagination",
            properties: ["pagination"],
          },
          scroll: {
            title: "Scrolling",
            properties: ["scroll"],
          },
          columns: {
            title: "Columns",
            properties: ["columns"],
          },
        },
      },
      properties: {
        general: {
          type: "object",
          default: {},
          title: "General Options",
          metadata: {
            layout: { gutter: [16] },
          },
          properties: {
            "table/size": {
              type: "string",
              title: "Table Size",
              default: "small",
              enum: ["default", "middle", "small"],
              metadata: {
                labels: ["Large", "Medium", "Small"],
                component: "Segmented",
                fieldProps: {
                  placeholder: "(auto)",
                },
              },
            },
            "table/bordered": {
              default: false,
              type: "boolean",
              title: "Bordered",
              description: "Whether to show borders around all table cells",
            },
            "table/showHeader": {
              default: true,
              type: "boolean",
              title: "Show Header",
              description:
                "Whether to show the table header, which includes column names and sorting controls",
            },
            addRow: {
              type: "boolean",
              default: true,
              title: "Show Add Row",
            },
            showRowActions: {
              type: "boolean",
              default: true,
              title: "Show Row Actions",
            },
          },
        },
        scroll: {
          type: "object",
          default: {},
          title: "Scroll Options",
          metadata: {
            layout: { gutter: [16] },
          },
          properties: {
            "table/scroll/scrollToFirstRowOnChange": {
              title: "Scroll to First Row on Change",
              type: "boolean",
              description:
                "Whether to scroll to the top of the table when paging, sorting, filtering changes",
            },
            "table/scroll/x": {
              title: "Horizontal Scrolling Enabled",
              type: "boolean",
              default: true,
              description:
                "If horizontal scrolling is disabled, the table will try to fit all data into the width of the table",
            },
          },
        },
        pagination: {
          type: "object",
          default: {},
          title: "Pagination",
          metadata: {
            layout: { gutter: [16] },
          },
          properties: {
            "table/pagination/verticalPosition": {
              type: "string",
              title: "Position",
              default: "top",
              enum: ["top", "bottom", "both"],
              metadata: {
                labels: ["Top", "Bottom", "Top and Bottom"],
              },
            },
            "table/pagination/alignment": {
              type: "string",
              title: "Alignment",
              default: "left",
              enum: ["left", "right"],
              metadata: {
                labels: ["Left", "Right"],
              },
            },
            "table/pagination/hideOnSinglePage": {
              default: false,
              type: "boolean",
              title: "Hide if there is only one page",
            },
            "table/pagination/defaultPageSize": {
              default: 10,
              type: "number",
              title: "Default Rows Per Page",
              description: "Determines how many rows show up on each page",
            },
            "table/pagination/pageSizeOptions": {
              type: "array",
              default: [10, 20, 50, 100],
              title: "Page Size Options",
              items: {
                type: "number",
              },
            },
            "table/pagination/showSizeChanger": {
              type: "boolean",
              title: "Show Page Size Selector",
              description:
                "Determine whether to show page size selection dropdown",
            },
          },
        },
        columns: {
          title: "Columns",
          type: "array",
          default: [],
          metadata: {
            hideAdd: true,
            hideLabel: true,
          },
          items: {
            type: "object",
            default: {},
            metadata: {
              removable: false,
            },
            dependencies: {
              sortable: {
                if: {
                  properties: {
                    sortable: {
                      enum: [true],
                    },
                  },
                },
                then: {
                  properties: {
                    defaultSortOrder: {
                      type: "string",
                      enum: ["ascend", "descend"],
                      title: "Default Sort Direction",
                      metadata: {
                        labels: ["Ascending", "Descending"],
                        fieldProps: {
                          placeholder: "Select Sort Direction",
                        },
                      },
                    },
                    sortPriority: {
                      title: "Sort Priority",
                      type: "number",
                      default: 0,
                    },
                    showSorterTooltip: {
                      title: "Show Sorter Tooltip",
                      type: "boolean",
                      default: true,
                    },
                  },
                },
              },
            },
            properties: {
              column: {
                title: "Column",
                type: "string",
                metadata: {
                  fieldProps: {
                    disabled: true,
                  },
                },
              },
              type: {
                $ref: "#/$defs/dataColumnType",
                metadata: { hidden: true },
              },
              title: {
                $ref: "#/$defs/title",
                metadata: {
                  validateFirst: true,
                  fieldProps: {
                    placeholder: "<Current Column Name>",
                  },
                },
              },
              fixed: { $ref: "#/$defs/fixed" },
              dataIndex: { $ref: "#/$defs/dataIndex" },
              hidden: {
                title: "Hidden",
                type: "boolean",
              },
              editable: {
                title: "Editable",
                type: "boolean",
                default: true,
              },
              searchable: {
                title: "Show Search",
                type: "boolean",
                default: true,
              },
              sortable: {
                title: "Sortable",
                type: "boolean",
                default: true,
              },
            },
          },
        },
      },
    },
  },
}

export default dataEditorSchema
