import React from "react"
import Select from "./Select"
import ErrorMessage from "../ErrorMessage"
import { useQuery } from "@apollo/client"
import { get } from "lodash"

const RemoteSelect = props => {
  const {
    query,
    variables,
    collectionName,
    isStringCollection = false,
    labelField = "name",
    valueField = "id",
    label,
    filterFn = () => true,
    ...rest
  } = props

  const { error, loading, data } = useQuery(query, {
    variables,
    fetchPolicy: "network-only",
  })

  if (loading) return null
  if (error) return <ErrorMessage error={error} />

  const collection = get(data, collectionName)
  const options = collection.filter(filterFn).map(row => {
    const label = isStringCollection ? row : row[labelField]
    const value = isStringCollection ? row : row[valueField]

    return value ? { label, value, row } : null
  })

  return <Select label={label} options={options} {...rest} />
}

export default RemoteSelect
