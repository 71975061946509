import React from "react"
import { isArray } from "lodash"
import { Space, Row, Col } from "antd"
import styled from "styled-components"

import AddFormFieldButton from "./AddFormFieldButton"
import RemoveFormFieldButton from "./RemoveFormFieldButton"
import { withoutLeadingDot } from "../../JSONSchemaForm/utils"
import JSONSchemaField from "../../JSONSchemaForm/JSONSchemaField"

const ContainerTreeNode = styled.span`
  width: 100%;
  min-width: 100px;
  font-weight: bold;
  .tree-search-value {
    color: #f50;
  }
`

const StyledSpan = styled.span`
  min-width: 100px;
  .tree-search-value {
    color: #f50;
  }
`

const StyledRow = styled(Row)`
  min-width: 200px;
  .ant-btn {
    overflow: hidden;
    white-space: nowrap;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.6);
    vertical-align: text-bottom;
  }
  &&& svg {
    font-size: 12px;
    margin-right: 0;
  }
  .tree-node-buttons {
    .ant-btn {
      padding: 0;
      width: 16px;
      height: 16px;
      font-size: 0;
    }
  }
`
const schemaTypeOptimizationMap = {
  SliderInput: {
    style: {
      width: "100%",
      minWidth: "100px",
    },
  },
  number: {
    style: {
      width: "100%",
      minWidth: "100px",
    },
  },
  string: {
    style: {
      minWidth: "100px",
    },
  },
  dropdown: {
    popupMatchSelectWidth: false,
    style: {
      width: "100%",
      minWidth: "100px",
    },
  },
}

const dropdownLikeComponents = ["Dataset", "ColumnSelect", "LocalSelect"]
const isDropdownSchema = schema => {
  const { type, items, enum: enums } = schema
  return (
    (type === "array" && Boolean(items.enum)) ||
    Boolean(enums) ||
    dropdownLikeComponents.includes(schema.metadata?.component)
  )
}

// TODO: consider using this function in getFormField
const getSchemaFieldType = schema => {
  const schemaType = isArray(schema.type)
    ? schema.type.filter(t => t !== "null")[0]
    : schema.type
  if (schema.metadata?.component === "Segmented") return "segmented"
  return isDropdownSchema({ ...schema, type: schemaType })
    ? "dropdown"
    : schemaType
}

// this function ensures any schema is optimized to show within a tree node
const getSchemaForTreeNode = schema => {
  const { fieldProps = {}, ...restMetadata } = schema.metadata || {}
  const schemaType = getSchemaFieldType(schema)
  const optimizationProps = schemaTypeOptimizationMap[schemaType] || {}
  return {
    ...schema,
    metadata: {
      ...restMetadata,
      fieldProps: {
        ...fieldProps,
        ...optimizationProps,
      },
    },
  }
}

const TreeNodeFormField = props => {
  const { title, path, schema, schemaKey } = props
  const safeSchema = getSchemaForTreeNode(schema)
  return (
    <Row>
      <Col span={24}>
        <StyledSpan>{title}</StyledSpan>
      </Col>
      <Col span={24}>
        <JSONSchemaField
          noStyle
          path={path}
          schema={safeSchema}
          schemaKey={schemaKey}
          name={withoutLeadingDot(path)}
        />
      </Col>
    </Row>
  )
}

const SearchedTreeNode = props => {
  const { title = "", searchValue = "", schema } = props
  const searchIndex = title.indexOf(searchValue)
  const beforeStr = title.substring(0, searchIndex)
  const afterStr = title.slice(searchIndex + searchValue.length)
  const containerTreeNode = ["array", "object"].includes(schema.type)
  const ContainerSpan = containerTreeNode ? ContainerTreeNode : StyledSpan
  return searchIndex > -1 ? (
    <ContainerSpan>
      {beforeStr}
      <span className="tree-search-value">{searchValue}</span>
      {afterStr}
    </ContainerSpan>
  ) : (
    <ContainerSpan>{title}</ContainerSpan>
  )
}

const TreeNodeTitle = props => {
  const { searchValue = "", schema } = props
  if (searchValue || ["array", "object"].includes(schema.type)) {
    return <SearchedTreeNode {...props} />
  }
  return <TreeNodeFormField {...props} />
}

const TreeNode = props => {
  const { schema, removable, className } = props
  const hasButton = schema?.type === "array" || removable
  return (
    <StyledRow gutter={[16]} wrap={false} className={className}>
      <Col flex={1}>
        <TreeNodeTitle {...props} />
      </Col>
      {hasButton ? (
        <Col>
          <Space direction="horizontal" size={4} className="tree-node-buttons">
            {schema?.type === "array" ? (
              <AddFormFieldButton {...props} />
            ) : null}
            {removable ? <RemoveFormFieldButton {...props} /> : null}
          </Space>
        </Col>
      ) : null}
    </StyledRow>
  )
}

export default TreeNode
