const lightThemeColors = {
  colorPrimary: "#242934",
  colorTextBase: "#404242",
  colorInfo: "#009BAE",
  colorWarning: "#F5BF11",
  colorError: "#B61215",
  colorSuccess: "#56B44D",
  colorBgBase: "#FAFAFA",

  // custom color tokens
  colorHeader: "#242833",
  textEditorTheme: "default",
  dropzoneHeader: "#cccccc",

  // non-seed color tokens
  colorBgLayout: "#FAFAFA",
}

const darkThemeColors = {
  colorPrimary: "#646767",
  colorTextBase: "#BFCCD1",
  colorWarning: "#F5BF11",
  colorError: "#B61215",
  colorSuccess: "#56B44D",
  colorInfo: "#009BBA",
  colorBgBase: "#242934",

  // custom color tokens
  colorHeader: "#2c2c2c",
  textEditorTheme: "material-darker",
  dropzoneHeader: "#555555",

  // non-seed color tokens
  colorBgLayout: "#242934",
}

const appThemeSchema = {
  default: {},
  type: "object",
  metadata: {},
  properties: {
    headerFontFamily: {
      nullable: true,
      default: "Industry",
      type: ["string", "null"],
      title: "Header Font",
      description: "Font family used for headers",
      enum: [
        "Industry",
        "Industry-Dark",
        "Industry-Demi",
        // Serif Fonts
        "Georgia",
        "Times New Roman",
        "Palatino Linotype",
        "Book Antiqua",
        "serif", // generic serif
        // Sans-serif Fonts
        "Arial",
        "Calibri",
        "Helvetica",
        "Arial Black",
        "Tahoma",
        "Geneva",
        "Verdana",
        "sans-serif", // generic sans-serif
        // Monospace Fonts
        "Courier New",
        "Courier",
        "Monaco",
        "Lucida Console",
        "monospace", // generic monospace
      ],
      metadata: {
        labels: [
          "Industry",
          "Industry Dark",
          "Industry Demi",
          "Georgia",
          "Times New Roman",
          "Palatino Linotype",
          "Book Antiqua",
          "Generic Serif",
          "Arial",
          "Calibri",
          "Helvetica",
          "Arial Black",
          "Tahoma",
          "Geneva",
          "Verdana",
          "Generic Sans-Serif",
          "Courier New",
          "Courier",
          "Monaco",
          "Lucida Console",
          "Generic Monospace",
        ],
        component: "FontPicker",
      },
    },
    "token/fontFamily": {
      nullable: true,
      default: "Calibri",
      type: ["string", "null"],
      title: "Text Font",
      description: "Font family used for text",
      enum: [
        "Industry",
        "Industry-Dark",
        "Industry-Demi",
        // Serif Fonts
        "Georgia",
        "Times New Roman",
        "Palatino Linotype",
        "Book Antiqua",
        "serif", // generic serif
        // Sans-serif Fonts
        "Arial",
        "Calibri",
        "Helvetica",
        "Arial Black",
        "Tahoma",
        "Geneva",
        "Verdana",
        "sans-serif", // generic sans-serif
        // Monospace Fonts
        "Courier New",
        "Courier",
        "Monaco",
        "Lucida Console",
        "monospace", // generic monospace
      ],
      metadata: {
        labels: [
          "Industry",
          "Industry Dark",
          "Industry Demi",
          "Georgia",
          "Times New Roman",
          "Palatino Linotype",
          "Book Antiqua",
          "Generic Serif",
          "Arial",
          "Calibri",
          "Helvetica",
          "Arial Black",
          "Tahoma",
          "Geneva",
          "Verdana",
          "Generic Sans-Serif",
          "Courier New",
          "Courier",
          "Monaco",
          "Lucida Console",
          "Generic Monospace",
        ],
        component: "FontPicker",
      },
    },
    compact: {
      type: "boolean",
      title: "Compact Mode",
    },
    "token/borderRadius": {
      minimum: 0,
      default: 6,
      maximum: 20,
      type: "number",
      nullable: true,
      title: "Border Radius",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    "token/fontSize": {
      minimum: 2,
      default: 14,
      maximum: 72,
      type: "number",
      title: "Font Size",
      metadata: {
        showNumberInput: true,
        component: "SliderInput",
      },
    },
    mode: {
      type: "string",
      enum: ["light", "dark"],
      default: "light",
      title: "Theme Mode",
      metadata: {
        labels: ["Light", "Dark"],
        component: "Segmented",
      },
    },
    colors: {
      type: "object",
      title: "Color Palette",
      default: {},
      metadata: {
        hideLabel: false,
        sections: {
          LightModeColorPalette: {
            title: "Light Mode Colors",
            properties: ["lightModeColors"],
          },
          DarkModeColorPalette: {
            title: "Dark Mode Colors",
            properties: ["darkModeColors"],
          },
        },
        layout: { gutter: [16, 16] },
      },
      properties: {
        lightModeColors: {
          type: "object",
          default: {},
          title: "Light",
          metadata: {
            hideLabel: true,
            layout: { span: 24 },
          },
          properties: {
            main_colors: {
              type: "object",
              default: {},
              title: "Main Colors",
              metadata: {
                hideLabel: false,
                layout: { span: 12 },
              },
              properties: {
                "lightModeColors/colorPrimary": {
                  type: "string",
                  title: "Primary Color",
                  description: "Primary color of the application",
                  default: lightThemeColors.colorPrimary,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "lightModeColors/colorTextBase": {
                  type: "string",
                  title: "Text Color",
                  description: "Color representing text",
                  default: lightThemeColors.colorTextBase,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "lightModeColors/colorBgBase": {
                  type: "string",
                  title: "Background Color",
                  description: "Primary text color",
                  default: lightThemeColors.colorBgBase,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "token/colorInfo": {
                  type: "string",
                  title: "Info Color",
                  default: lightThemeColors.colorInfo,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "lightModeColors/colorWarning": {
                  type: "string",
                  title: "Warning Color",
                  description: "Color representing warnings",
                  default: lightThemeColors.colorWarning,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "lightModeColors/colorSuccess": {
                  type: "string",
                  title: "Success Color",
                  description: "Color representing successful actions",
                  default: lightThemeColors.colorSuccess,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "lightModeColors/colorError": {
                  type: "string",
                  title: "Error Color",
                  description: "Color representing errors",
                  default: lightThemeColors.colorError,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
              },
            },
            chart: {
              type: "object",
              title: "Chart Colors",
              default: {},
              metadata: {
                hideLabel: false,
                layout: { span: 12 },
              },
              properties: {
                "lightModeColors/chart/background": {
                  type: "string",
                  title: "Background Color",
                  description: "Color of the chart background in light mode",
                  default: "#ffffff",
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "lightModeColors/chart/plotBackground": {
                  type: "string",
                  title: "Plot Background Color",
                  description: "Color of the plot background in light mode",
                  default: "#ffffff",
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
              },
            },
          },
        },
        darkModeColors: {
          type: "object",
          title: "Dark",
          default: {},
          metadata: {
            hideLabel: true,
            layout: { span: 24 },
          },
          properties: {
            main_colors: {
              type: "object",
              default: {},
              title: "Main Colors",
              metadata: {
                hideLabel: false,
                layout: { span: 12 },
              },
              properties: {
                "darkModeColors/colorPrimary": {
                  type: "string",
                  title: "Primary Color",
                  description: "Primary color of the application",
                  default: darkThemeColors.colorPrimary,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "darkModeColors/colorTextBase": {
                  type: "string",
                  title: "Text Color",
                  description: "Color representing text",
                  default: darkThemeColors.colorTextBase,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "darkModeColors/colorBgBase": {
                  type: "string",
                  title: "Background Color",
                  description: "Primary text color",
                  default: darkThemeColors.colorBgBase,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "darkModeColors/colorInfo": {
                  type: "string",
                  title: "Info Color",
                  default: darkThemeColors.colorInfo,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "darkModeColors/colorWarning": {
                  type: "string",
                  title: "Warning Color",
                  description: "Color representing warnings",
                  default: darkThemeColors.colorWarning,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "darkModeColors/colorSuccess": {
                  type: "string",
                  title: "Success Color",
                  description: "Color representing successful actions",
                  default: darkThemeColors.colorSuccess,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "darkModeColors/colorError": {
                  type: "string",
                  title: "Error Color",
                  description: "Color representing errors",
                  default: darkThemeColors.colorError,
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
              },
            },
            chart: {
              default: {},
              type: "object",
              title: "Chart Colors",
              metadata: {
                hideLabel: false,
                layout: { span: 12 },
              },
              properties: {
                "darkModeColors/chart/background": {
                  type: "string",
                  title: "Background Color",
                  description: "Color of the chart background in dark mode",
                  default: "#353C4C",
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
                "darkModeColors/chart/plotBackground": {
                  type: "string",
                  title: "Plot Background Color",
                  description: "Color of the plot background in dark mode",
                  default: "#353C4C",
                  metadata: {
                    layout: { span: 24 },
                    component: "ColorPicker",
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}

export default appThemeSchema
