import { useCallback } from "react"
import { useDispatch } from "react-redux"

const defaultMap = val => val

// NOTE: If a mapper function is provided it should have a stable identity to
// prevent this hook re-memoizing on every render.
export const useAction = (action, mapper = defaultMap) => {
  const dispatch = useDispatch()
  return useCallback(
    val => dispatch(action(mapper(val))),
    [action, dispatch, mapper]
  )
}
