import React, { useRef } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"

import { Modal } from "@dbai/ui-staples"

import { actions } from "reducers/codeSearchReducer"
import { selectCodeSearchTerm, selectCodeSearchOpen } from "selectors"

const StyledModal = styled(Modal)`
  width: 300px;
`

const StyledFormGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const CodeSearch = props => {
  const dispatch = useDispatch()
  const hasFocused = useRef(false)
  const searchOpen = useSelector(selectCodeSearchOpen)
  const searchTerm = useSelector(selectCodeSearchTerm)

  const handleClose = () => {
    dispatch(actions.setSearchClosed())
    hasFocused.current = false
  }

  const focusInput = ref => {
    if (ref && searchOpen && hasFocused.current === false) {
      ref.focus()
      ref.select()
      hasFocused.current = true
    }
  }
  const handleKeyPress = e => {
    e.hotkeyIgnore = true

    // Close the modal and search on enter.
    if (e.key === "Enter") {
      handleClose()
      dispatch(actions.search())
      dispatch(actions.goToMatch())
    }
  }
  const handleChange = e => {
    dispatch(actions.setSearchTerm(e.target.value || ""))
  }

  return (
    <StyledModal
      modalProps={{ onRequestClose: handleClose }}
      isOpen={searchOpen}
      title="Search Code"
    >
      <StyledFormGroup id="code-search" className="form-group">
        <input
          onKeyPress={handleKeyPress}
          ref={focusInput}
          value={searchTerm}
          onChange={handleChange}
        />
      </StyledFormGroup>
    </StyledModal>
  )
}

export default CodeSearch
