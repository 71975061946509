import { createSelector } from "reselect"

const selectSlice = state => state?.action || {}
export const selectActionLoading = createSelector(
  selectSlice,
  action => action.loading
)
export const selectActionError = createSelector(
  selectSlice,
  action => action.error
)
export const selectActionData = createSelector(
  selectSlice,
  action => action.data || {}
)
export const selectAction = createSelector(
  selectActionData,
  data => data.action || {}
)
export const selectActionSpec = createSelector(
  selectAction,
  action => action?.spec || {}
)
export const selectLoadedRevision = createSelector(
  selectAction,
  action => action.revisionId || null
)
export const selectActionColor = createSelector(
  selectActionSpec,
  spec => spec.color
)
export const selectActionIcon = createSelector(
  selectActionSpec,
  spec => spec.icon
)
export const selectActionInputs = createSelector(
  selectActionSpec,
  spec => spec.arguments || []
)
export const selectActionCells = createSelector(
  selectActionSpec,
  spec => spec.cells || []
)
export const selectActionSource = createSelector(
  selectActionCells,
  // Currently actions are single cell. This may change in the future.
  cells => cells[0]?.source || ""
)
