import { createSelector } from "reselect"
import { scheduleSpecToSentence, makeCrontab } from "lib/cron"

export const selectSchedule = state => state.schedule
export const selectScheduleNotifications = createSelector(
  selectSchedule,
  schedule => schedule.notifications
)
// Currently the only notificationType, but potentially not in the future.
export const selectScheduleEmailNotifications = createSelector(
  selectScheduleNotifications,
  notifications => {
    return notifications.filter(
      notification => notification.notificationType === "email"
    )
  }
)

export const selectDescription = createSelector(selectSchedule, schedule =>
  scheduleSpecToSentence(schedule.spec)
)

export const selectCrontab = createSelector(selectSchedule, schedule =>
  makeCrontab(schedule.spec)
)

export const selectHours = createSelector(
  selectSchedule,
  schedule => schedule.spec.hour
)

export const selectMinutes = createSelector(
  selectSchedule,
  schedule => schedule.spec.minute
)

export const selectDoM = createSelector(
  selectSchedule,
  schedule => schedule.spec.dom
)

export const selectDoW = createSelector(
  selectSchedule,
  schedule => schedule.spec.dow
)

export const selectMonth = createSelector(
  selectSchedule,
  schedule => schedule.spec.month
)
