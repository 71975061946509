import React, { useMemo } from "react"
import { Space, Button } from "antd"
import { useMutation } from "@apollo/client"
import { NavLink } from "react-router-dom"
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  DeleteButton,
  TableWithFolders,
  useCurrentCustomer,
} from "@dbai/ui-staples"

import { useTagColumn } from "hooks"
import { GET_MODELS, DELETE_MODEL } from "queries"

const TableOptions = props => {
  const [customer] = useCurrentCustomer()
  const cname = customer.normalizedName
  const {
    row: { id },
  } = props
  const [mutate] = useMutation(DELETE_MODEL, {
    variables: { cname, id },
    refetchQueries: [{ query: GET_MODELS, variables: { cname } }],
  })

  return (
    <Space>
      <div className="btn-group">
        <NavLink to={`/${customer.normalizedName}/models/${id}`}>
          <Button>
            <FontAwesomeIcon icon={faPencilAlt} className="action-icon" />
          </Button>
        </NavLink>

        <DeleteButton onDelete={mutate} confirmDelete />
      </div>
    </Space>
  )
}

const deleteColumn = {
  title: "",
  dataIndex: "delete",
  key: "delete",
  render: props => <TableOptions {...props} />,
}

const columnOrder = ["name", "tags", "createdAt", "updatedAt", "delete"]

const tagColumnOptions = {
  type: "models",
  query: GET_MODELS,
}

const ModelsTable = props => {
  const tags = useTagColumn(tagColumnOptions)
  const allColumns = useMemo(() => [tags, deleteColumn], [tags])
  const { models = [] } = props

  return (
    <TableWithFolders
      folderType="models"
      dataSource={models}
      columns={allColumns}
      columnOrder={columnOrder}
    />
  )
}

export default ModelsTable
