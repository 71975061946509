const required = ["column", "operator", "valueType", "value"]
export const properties = {
  appVariable: {
    type: "string",
    title: "App Variable",
    metadata: {
      component: "AppVariableSelect",
    },
  },
  sourceType: {
    type: "string",
    title: "Source Type",
    default: "datasetColumn",
    enum: ["datasetColumn", "appVariable"],
    metadata: {
      labels: ["Dataset Column", "App Variable"],
    },
  },
  column: {
    type: "string",
    title: "Column",
    metadata: {
      component: "ColumnSelect",
      columnTypeName: "columnType",
      fieldProps: {
        placeholder: "Select Column",
      },
    },
  },
  conjunction: {
    type: "string",
    enum: ["and", "or"],
    default: "and",
    metadata: {
      labels: ["AND", "OR"],
      hideLabel: true,
      hidden: {
        itemIndex: 0,
      },
    },
  },
  attribute: {
    type: "string",
    title: "Attribute",
    enum: ["color", "backgroundColor", "textDecoration"],
    metadata: {
      hideLabel: true,
      labels: ["Text Color", "Background Color", "Style Text"],
      fieldProps: {
        popupMatchSelectWidth: false,
        placeholder: "Select Attribute",
      },
    },
  },
  valueType: {
    type: "string",
    title: "Compare value type",
    enum: ["staticValue", "datasetColumn", "appVariable"],
    default: "staticValue",
    metadata: {
      hideLabel: true,
      labels: ["Value", "Other Column", "App Variable"],
    },
  },
  percentile: {
    type: "number",
    min: 0,
    max: 1,
    title: "Value",
    metadata: {
      hideLabel: true,
      showNumberInput: true,
      component: "SliderInput",
      formatter: { format: "percent" },
      fieldProps: {
        placeholder: "Percentile",
        popupMatchSelectWidth: false,
      },
    },
  },
  aggregate: {
    type: ["string", "null"],
    title: "Aggregate",
    enum: ["avg", "sum", "min", "max", "count", "PERCENTILE_CONT"],
    nullable: true,
    metadata: {
      validateFirst: true,
      labels: ["Average", "Sum", "Minimum", "Maximum", "Count", "Percentile"],
      fieldProps: {
        placeholder: "(All)",
        popupMatchSelectWidth: false,
      },
    },
  },
  numericalColumnOperator: {
    type: "string",
    title: "Operator",
    default: "=",
    enum: ["=", "!=", "<", ">", "<=", ">="],
    metadata: {
      hideLabel: true,
      fieldProps: {
        popupMatchSelectWidth: false,
      },
      labels: [
        "is equal to",
        "is not equal to",
        "is less than",
        "is greater than",
        "is less than or equal to",
        "is greater than or equal to",
      ],
    },
  },
  datetimeColumnOperator: {
    type: "string",
    title: "Operator",
    default: "=",
    enum: ["=", "!=", "<", ">", "<=", ">="],
    metadata: {
      hideLabel: true,
      fieldProps: {
        placeholder: "Select value",
        popupMatchSelectWidth: false,
      },
      labels: [
        "is equal to",
        "is not equal to",
        "is before",
        "is after",
        "is before or equal to",
        "is after or equal to",
      ],
    },
  },
  categoricalColumnOperator: {
    type: "string",
    title: "Operator",
    default: "=",
    enum: ["=", "!="],
    metadata: {
      hideLabel: true,
      fieldProps: {
        placeholder: "Select value",
        popupMatchSelectWidth: false,
      },
      labels: ["is in", "is not in"],
    },
  },
  columnValue: {
    type: "string",
    title: "Value",
    metadata: {
      hideLabel: true,
      component: "ColumnSelect",
      fieldProps: {
        placeholder: "Select Column",
      },
    },
  },
  datetimeValue: {
    type: "string",
    title: `Value`,
    metadata: {
      hideLabel: true,
      component: "DatePicker",
    },
  },
  categoricalValue: {
    type: "array",
    title: "Value",
    items: { type: "string" },
    metadata: {
      hideLabel: true,
      allowInput: true,
      isMulti: true,
      columnName: "column",
      excludeWidgetFilters: true,
      component: "ColumnValueInput",
      fieldProps: {
        placeholder: "Enter or Select value",
        popupMatchSelectWidth: false,
      },
    },
  },
  numericalColumnValue: {
    type: "number",
    title: "Value",
    metadata: {
      hideLabel: true,
      allowInput: true,
      columnName: "column",
      excludeWidgetFilters: true,
      component: "ColumnValueInput",
      fieldProps: {
        placeholder: "Enter or Select value",
        popupMatchSelectWidth: false,
      },
    },
  },
}

const getConditionalFormatSchema = (includeAggregate, requireAggregate) => ({
  type: "array",
  title: "Conditional Formatting",
  metadata: {
    itemType: "collapse",
    addButtonText: "ADD SCENARIO",
    addButtonProps: {
      block: true,
    },
  },
  items: {
    type: "object",
    title: "Scenario",
    properties: {
      title: {
        type: "string",
        default: "Condition Scenario",
      },
      conditions: {
        type: "array",
        title: "When",
        default: [],
        metadata: {
          addButtonText: "ADD CONDITION",
          addButtonProps: {
            block: true,
          },
        },
        items: {
          required,
          default: {},
          type: "object",
          metadata: {
            order: [
              "sourceType",
              "appVariable",
              "column",
              "aggregate",
              "percentile",
              "operator",
              "valueType",
              "value",
            ],
            sideEffects: [
              {
                resetValue: [],
                type: "resetField",
                targetName: "options.value",
                sourceName: "options.valueType",
              },
            ],
          },
          allOf: [
            {
              if: {
                properties: {
                  valueType: {
                    enum: ["staticValue"],
                  },
                  sourceType: {
                    enum: ["appVariable"],
                  },
                },
              },
              then: {
                properties: {
                  operator: properties.categoricalColumnOperator,
                  value: properties.categoricalValue,
                },
              },
            },
          ],
          dependencies: {
            sourceType: {
              allOf: [
                {
                  if: {
                    properties: {
                      sourceType: {
                        enum: ["datasetColumn"],
                      },
                    },
                  },
                  then: {
                    dependencies: {
                      aggregate: {
                        if: {
                          properties: {
                            aggregate: {
                              enum: ["PERCENTILE_CONT"],
                            },
                          },
                        },
                        then: {
                          properties: {
                            percentile: properties.percentile,
                          },
                        },
                      },
                    },
                    properties: {
                      columnType: { $ref: "#/$defs/dataColumnType" },
                      column: properties.column,
                      ...(includeAggregate
                        ? { aggregate: properties.aggregate }
                        : {}),
                    },
                  },
                  else: {
                    properties: {
                      appVariable: properties.appVariable,
                    },
                  },
                },
              ],
            },
            valueType: {
              allOf: [
                {
                  if: {
                    properties: {
                      valueType: {
                        enum: ["datasetColumn"],
                      },
                    },
                  },
                  then: {
                    required: requireAggregate
                      ? [...required, "aggregate", "secondaryColumnAggregate"]
                      : required,
                    dependencies: {
                      secondaryColumnAggregate: {
                        if: {
                          properties: {
                            secondaryColumnAggregate: {
                              enum: ["PERCENTILE_CONT"],
                            },
                          },
                        },
                        then: {
                          properties: {
                            secondaryColumnPercentile: properties.percentile,
                          },
                        },
                      },
                    },
                    properties: {
                      ...(includeAggregate
                        ? { aggregate: properties.aggregate }
                        : {}),
                      operator: properties.numericalColumnOperator,
                      value: properties.columnValue,
                      ...(includeAggregate
                        ? {
                            secondaryColumnAggregate: properties.aggregate,
                          }
                        : {}),
                    },
                  },
                },
                {
                  if: {
                    properties: {
                      valueType: {
                        enum: ["staticValue"],
                      },
                      columnType: {
                        enum: ["datetime"],
                      },
                    },
                  },
                  then: {
                    properties: {
                      operator: properties.datetimeColumnOperator,
                      value: properties.datetimeValue,
                    },
                  },
                },
                {
                  if: {
                    properties: {
                      valueType: {
                        enum: ["staticValue"],
                      },
                      columnType: {
                        enum: ["string", "categorical"],
                      },
                    },
                  },
                  then: {
                    properties: {
                      operator: properties.categoricalColumnOperator,
                      value: properties.categoricalValue,
                    },
                  },
                },
                {
                  if: {
                    properties: {
                      valueType: {
                        enum: ["staticValue"],
                      },
                      columnType: {
                        enum: ["numerical", "float", "int"],
                      },
                    },
                  },
                  then: {
                    required: requireAggregate
                      ? [...required, "aggregate"]
                      : required,
                    dependencies: {
                      aggregate: {
                        if: {
                          properties: {
                            aggregate: {
                              enum: ["PERCENTILE_CONT"],
                            },
                          },
                        },
                        then: {
                          properties: {
                            percentile: properties.percentile,
                          },
                        },
                      },
                    },
                    properties: {
                      ...(includeAggregate
                        ? { aggregate: properties.aggregate }
                        : {}),
                      operator: properties.numericalColumnOperator,
                      value: properties.numericalColumnValue,
                    },
                  },
                },
                {
                  if: {
                    properties: {
                      valueType: {
                        enum: ["appVariable"],
                      },
                    },
                  },
                  then: {
                    properties: {
                      value: properties.appVariable,
                    },
                  },
                },
              ],
            },
          },
          properties: {
            conjunction: properties.conjunction,
            sourceType: properties.sourceType,
            valueType: properties.valueType,
          },
        },
      },
      effects: {
        type: "array",
        title: "Then, apply",
        default: [],
        metadata: {
          draggable: false,
          addButtonText: "ADD EFFECT",
          addButtonProps: {
            block: true,
          },
        },
        items: {
          type: "object",
          default: {},
          metadata: {
            layout: { gutter: [8] },
          },
          dependencies: {
            attribute: {
              allOf: [
                {
                  if: {
                    properties: {
                      attribute: {
                        enum: ["color", "backgroundColor"],
                      },
                    },
                  },
                  then: {
                    properties: {
                      value: {
                        type: "string",
                        title: "Value",
                        metadata: {
                          layout: { flex: 1 },
                          hideLabel: true,
                          component: "ColorPicker",
                        },
                      },
                    },
                  },
                },
                {
                  if: {
                    properties: {
                      attribute: {
                        enum: ["textDecoration"],
                      },
                    },
                  },
                  then: {
                    properties: {
                      decorations: {
                        type: "array",
                        title: "Decorations",
                        items: {
                          type: "string",
                          enum: [
                            "bold",
                            "italic",
                            "line-through",
                            "underline",
                            "overline",
                          ],
                        },
                        metadata: {
                          hideLabel: true,
                          labels: [
                            "Bold",
                            "Italicize",
                            "Line Through",
                            "Underline",
                            "Overline",
                          ],
                          fieldProps: {
                            popupMatchSelectWidth: false,
                            placeholder: "Select a decoration",
                          },
                        },
                      },
                    },
                  },
                },
              ],
            },
          },
          properties: {
            attribute: properties.attribute,
          },
        },
      },
    },
  },
})

export default getConditionalFormatSchema
