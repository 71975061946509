import { createSlice } from "@reduxjs/toolkit"
import { isEqual, get, isArray, set } from "lodash"
import { toast } from "react-toastify"

import { toastAndRethrow, createCommonActions } from "@dbai/tool-box"

import { ajv } from "../lib/ajv"
import { selectForm } from "../selectors/forms"
import getSchemaSideEffects from "../lib/getSchemaSideEffects"
import getDataWithDefaultValues from "../lib/getDataWithDefaultValues"
import {
  normalizeFormData,
  mapFormDataToSchema,
  normalizeFormDataWithSchemaPaths,
} from "../lib/formData"
import {
  EVENT_TYPE_APPEND,
  EVENT_TYPE_CHANGE,
  EVENT_TYPE_MOVE,
  EVENT_TYPE_REMOVE,
} from "../JSONSchemaForm/constants"

const getWidgetSchemaIndex = (schema, widgetType) => {
  return get(schema, "dependencies.type.allOf").findIndex(schemaCondition => {
    return get(schemaCondition, "if.properties.type")?.enum.includes(widgetType)
  })
}

const initialFormState = {
  data: {},
  schema: {},
  errors: [],
  history: [],
  status: "idle",
  initialData: {},
}

const initialState = {}

const formsSlice = createSlice({
  initialState,
  name: "forms",
  reducers: {
    load: (draft, { payload }) => {
      const { id } = payload
      const exisitngForm = draft[id] || {}
      draft[id] = { ...initialFormState, ...exisitngForm, ...payload }
    },
    setForm: (draft, { payload }) => {
      set(draft, payload.id, { ...draft[payload.id], ...payload.value })
    },
    setInitialFormData: (draft, { payload }) => {
      const { initialData, id } = payload
      draft[id].initialData = initialData
    },
    setFormStatus: (draft, { payload }) => {
      const form = draft[payload.id]
      set(form, "status", payload.value)
    },
    setFormField: (draft, { payload }) => {
      const form = draft[payload.id]
      set(form, `data.${payload.name}`, payload.value)
    },
    setFormSchemaForWidget: (draft, { payload }) => {
      const form = draft[payload.id]
      if (payload.widgetType) {
        const widgetSchemaIndex = getWidgetSchemaIndex(
          form.schema,
          payload.widgetType
        )
        const widgetSchemaPath = `dependencies.type.allOf[${widgetSchemaIndex}].then.properties./options`
        if (!payload.name) {
          set(form.schema, widgetSchemaPath, payload.value)
          return
        }
        set(form.schema, `${widgetSchemaPath}.${payload.name}`, payload.value)
        return
      }
      set(form, "schema", payload.value)
    },
    appendFormField: (draft, { payload }) => {
      const form = draft[payload.id]
      const existingArray = get(form, `data.${payload.name}`)
      const valueIsArray = isArray(payload.value)

      if (existingArray) {
        valueIsArray
          ? set(form, `data.${payload.name}`, [
              ...existingArray,
              ...payload.value,
            ])
          : existingArray.push(payload.value)
        return
      }

      set(
        form,
        `data.${payload.name}`,
        valueIsArray ? payload.value : [payload.value]
      )
    },
    removeFormField: (draft, { payload }) => {
      const { idx, key, id, name } = payload
      const form = draft[id]
      let target = get(form, `data.${name}`)

      if (!key) {
        target.splice(idx, 1)
      } else {
        target = target.filter(item => {
          return item[key] !== idx
        })
      }
    },
    resetFormData: (draft, { payload }) => {
      const form = draft[payload.formId]
      const { initialData } = form
      set(form, "data", initialData)
    },
    removeForm: (draft, { payload }) => {
      delete draft[payload.id]
    },
    ...createCommonActions(initialState),
  },
})

const actions = formsSlice.actions

const compileForm = ({ id }) => {
  return async (dispatch, getState) => {
    const form = selectForm(getState(), { formId: id })
    if (!form) return Promise.resolve([])

    return ajv
      .compile(form.schema, form.data)
      .then(results => {
        dispatch(
          actions.setForm({
            id,
            value: {
              errors: results,
            },
          })
        )

        return results
      })
      .catch(e => {
        console.error(e)
        toastAndRethrow("Error Validating Form")(e)
      })
  }
}

const generateFormData = (normalizedData, schema) => {
  const formData = mapFormDataToSchema({ schema, data: normalizedData })
  const data = getDataWithDefaultValues({ schema, data: formData })
  return data
}

const loadFormData = ({ id, value, force, schema, validate, initialState }) => {
  return async (dispatch, getState) => {
    const form = selectForm(getState(), { formId: id })
    let data = value || initialState || {}

    if (
      // prevent form from being loaded through this hook if the form exists and the schema hasnt changed
      form &&
      !force &&
      // prevent form from being loaded if the data and schema haven't changed
      isEqual(data, form.initialNormalizedData) &&
      isEqual(schema, form.schema)
    ) {
      return Promise.resolve()
    }

    const initialNormalizedData = [null, undefined].includes(value)
      ? { initialNormalizedData: initialState }
      : undefined

    try {
      data = generateFormData(data, schema)
    } catch (e) {
      console.error(e)
      toast.error("Error Loading Form")(e)
      dispatch(
        actions.load({
          id,
          data,
          errors: [],
          schema,
          initialNormalizedData,
        })
      )
      return Promise.resolve()
    }

    let errors = form?.errors || []

    if (validate) {
      errors = await ajv.compile(schema, data)
    }

    // only load form if certain form properties have changed
    if (
      !form ||
      !isEqual(data, form.data) ||
      !isEqual(errors, form.errors) ||
      !isEqual(schema, form.schema) ||
      !isEqual(initialNormalizedData, form.initialNormalizedData)
    ) {
      dispatch(
        actions.load({
          id,
          data,
          errors,
          schema,
          initialNormalizedData,
        })
      )
    }
    return Promise.resolve()
  }
}

// Reset a field when another field is updated
const resetField = (
  effect,
  normalizedDataWithSchemaPaths,
  prevNormalizedDataWithSchemaPaths,
  dataCopy
) => {
  const { targetName, sourceName, resetValue } = effect

  const targetPath = get(normalizedDataWithSchemaPaths, `${targetName}.path`)
  const sourceValue = get(normalizedDataWithSchemaPaths, `${sourceName}.value`)
  const prevSourceValue = get(
    prevNormalizedDataWithSchemaPaths,
    `${sourceName}.value`
  )

  if (sourceValue !== prevSourceValue) {
    set(dataCopy, targetPath, resetValue)
  }

  return dataCopy
}

const basicChartTypes = ["line", "area", "column", "bar", "scatter"]
const resetOnChartTypeChange = (
  effect,
  normalizedDataWithSchemaPaths,
  prevNormalizedDataWithSchemaPaths,
  dataCopy
) => {
  const { targetName, chartTypeName, resetValue } = effect

  const targetPath = get(normalizedDataWithSchemaPaths, `${targetName}.path`)
  const chartType = get(normalizedDataWithSchemaPaths, `${chartTypeName}.value`)
  const prevChartType = get(
    prevNormalizedDataWithSchemaPaths,
    `${chartTypeName}.value`
  )

  const isBasicChartType = basicChartTypes.includes(chartType)
  const wasBasicChartType = basicChartTypes.includes(prevChartType)
  const changed =
    (isBasicChartType && !wasBasicChartType) ||
    (!isBasicChartType && wasBasicChartType) ||
    (!isBasicChartType && !wasBasicChartType && chartType !== prevChartType)

  if (changed) {
    set(dataCopy, targetPath, resetValue)
  }

  return dataCopy
}

const sideEffectsMap = {
  resetField,
  resetOnChartTypeChange,
}

const handleFormSideEffects = (formState, prevFormState) => {
  //1. get side effects from schema
  const sideEffects = getSchemaSideEffects(formState.schema, formState.data)

  //2. normalize (with schema paths) both data and prevNormalizedDataWithSchemaPaths
  const normalizedDataWithSchemaPaths = normalizeFormDataWithSchemaPaths(
    formState.data
  )
  const prevNormalizedDataWithSchemaPaths = normalizeFormDataWithSchemaPaths(
    prevFormState.data
  )

  //3. pass normalized data and raw data into side effects, returning the raw data each time (whihc will be fed into the next side effect)
  let dataCopy = structuredClone(formState.data)

  //4. return altered raw data and schema
  const alteredData = sideEffects.reduce((acc, effect) => {
    const sideEffectFn = sideEffectsMap[effect.type]
    if (sideEffectFn) {
      return sideEffectFn(
        effect,
        normalizedDataWithSchemaPaths,
        prevNormalizedDataWithSchemaPaths,
        acc
      )
    }
    return acc
  }, dataCopy)

  return alteredData
}

const handleFormChange =
  action =>
  ({
    id,
    toIndex,
    validate,
    fromIndex,
    eventType,
    afterFormChange,
    ...actionProps
  }) =>
  async (dispatch, getState) => {
    const prevForm = getState().forms[id]
    if (!prevForm) return

    // 1. update form field
    dispatch(action({ id, ...actionProps }))

    const form = getState().forms[id]

    let data = form.data
    let errors = form.errors
    if (eventType !== EVENT_TYPE_MOVE) {
      // 2. handle side effects
      data = handleFormSideEffects(form, prevForm)

      // 3. compute default values
      data = getDataWithDefaultValues({ schema: form.schema, data })
      if (validate) {
        errors = await ajv.compile(form.schema, data)
      }

      // TODO: improve performance with large forms by only updating the fields that have changed
      if (!isEqual(data, form.data) || !isEqual(errors, form.errors)) {
        dispatch(actions.setForm({ id, value: { data, errors } }))
      }
    }

    // 5. trigger 'afterFormChange' callback if available
    if (afterFormChange) {
      // get changed value after defaults and side effects have ran so that it can be passed to onFormChange
      const { index, name } = actionProps
      let changedValue

      switch (eventType) {
        case EVENT_TYPE_APPEND:
          const arr = name ? get(data, name) : data
          changedValue = arr[arr.length - 1]
          break
        case EVENT_TYPE_CHANGE:
          changedValue = name ? get(data, name) : data
          break
        case EVENT_TYPE_REMOVE:
          changedValue = name
            ? get(prevForm.data, `${name}[${index}]`)
            : get(prevForm.data, index)
          break
        default:
      }

      // since validating the form is expensive, we only want to do it when afterFormChange is present or when the form is submitted
      const normalizedData = normalizeFormData(data)
      afterFormChange({
        name,
        index,
        value: changedValue,
        formId: id,
        errors,
        toIndex,
        formData: normalizedData,
        fromIndex,
        eventType,
      })
    }
  }

const onFormAppend = handleFormChange(actions.appendFormField)
const onFormChange = handleFormChange(actions.setFormField)
const onFormRemove = handleFormChange(actions.removeFormField)
const onSchemaChange = handleFormChange(actions.setFormSchemaForWidget)

const actionsAndThunks = {
  ...actions,
  onFormAppend,
  onFormChange,
  onFormRemove,
  loadFormData,
  onSchemaChange,
  compileForm,
}

export { actionsAndThunks as actions }
formsSlice.actions = actionsAndThunks
export default formsSlice
