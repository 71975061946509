import * as Y from "yjs"

export const isLocalTransaction = YEvent => !YEvent.transaction.origin

export const getChangedYMapValue = (yMap, path, key) => {
  let nestedMap = yMap

  if (path.length) {
    path.forEach(name => {
      nestedMap = nestedMap.get(name)
    })
  }

  const updatedData = nestedMap.get(key)

  if (typeof updatedData === Y.Map) {
    return updatedData.toJSON()
  }

  if (typeof updatedData === Y.Array) {
    return updatedData.toArray()
  }

  return updatedData
}

const getYArrayDeltas = deltas => {
  // When a y array event contains multiple deltas, it can signify a more complex event occurred
  if (deltas.length > 1) {
    // Identify the insert and delete deltas
    const insertDelta = deltas.find(d => Boolean(d.insert))
    const deleteDelta = deltas.find(d => Boolean(d.delete))

    // Determine if this is a move event
    if (insertDelta && deleteDelta) {
      // Reduce to a single delta when deltas represent a move event
      return [{ move: insertDelta.insert }]
    }
  }

  return deltas
}

export const observeYjsMap = ({
  yMap,
  onLocalChange = () => {},
  onExternalChange = () => {},
}) => {
  yMap.observe(yMapEvent => {
    if (!yMapEvent.transaction.origin) {
      yMapEvent.changes.keys.forEach((change, key) => {
        onLocalChange({ yMapEvent, change, key, yMap })
      })
    } else {
      yMapEvent.changes.keys.forEach((change, key) => {
        onExternalChange({ yMapEvent, change, key, yMap })
      })
    }
  })
}

export const observeYjsMapDeep = ({
  yMap,
  onLocalChange = () => {},
  onExternalChange = () => {},
}) => {
  yMap.observeDeep(yMapEvents => {
    yMapEvents.forEach(yMapEvent => {
      if (!yMapEvent.transaction.origin) {
        yMapEvent.changes.keys.forEach((change, key) => {
          onLocalChange({ yMapEvent, change, key, yMap })
        })
      } else {
        yMapEvent.changes.keys.forEach((change, key) => {
          onExternalChange({ yMapEvent, change, key, yMap })
        })
      }
    })
  })
}

export const observeYjsArray = ({
  yArray,
  onLocalChange = () => {},
  onExternalChange = () => {},
}) => {
  yArray.observe(yArrayEvent => {
    /**
     * On every change we need to ensure that there are no duplicate ids.
     * This will ensure that content is not duplicated when two or more clients move the same item at the same time.
     * The easiest implementation is to keep the last unique item and delete the rest
     */
    // TODO: determine if this is necessary by testing whether the case above is true.
    // dedupeYArray(yArray, yArrayEvent)

    const deltas = getYArrayDeltas(yArrayEvent.changes.delta)
    if (!yArrayEvent.transaction.origin) {
      deltas.forEach(delta => {
        onLocalChange({ yArrayEvent, delta, yArray })
      })
    } else {
      deltas.forEach(delta => {
        onExternalChange({ yArrayEvent, delta, yArray })
      })
    }
  })
}

export const removeArrayItem = (item, syncRemove) => {
  const deletedItem = item.content.getContent()[item.length - 1]
  if (deletedItem instanceof Y.Map) {
    const id = deletedItem._map.get("id").content.arr[0]
    syncRemove(id)
  }
}
