import { isArray, get, set, merge } from "lodash"
import { getResolvedSchema } from "./schema"

const checkIfObject = value => typeof value === "object" && value !== null
const checkIfArray = value => Array.isArray(value)

// this function takes form data and normalizes it to a single object
export const normalizeArrayData = data => {
  return data.reduce((acc, value) => {
    if (typeof value === "object" && value !== null) {
      return [...acc, normalizeFormData(value)]
    }
    return [...acc, value]
  }, [])
}

export const normalizeObjectData = data => {
  return Object.entries(data || {}).reduce((acc, [key, value]) => {
    switch (true) {
      case checkIfArray(value):
        return set(acc, key.split("/"), normalizeArrayData(value))
      case checkIfObject(value):
        if (key.startsWith("/")) {
          return set(acc, key.split("/")[1], normalizeFormData(value))
        }
        return merge(acc, normalizeFormData(value))
      default:
        return set(acc, key.split("/"), value)
    }
  }, {})
}

// this function takes form data and normalizes it to a single object
export const normalizeFormData = data => {
  switch (true) {
    case checkIfArray(data):
      return normalizeArrayData(data)
    case checkIfObject(data):
      return normalizeObjectData(data)
    default:
      return data
  }
}

// TODO: THIS HAS NOT BEEN TESTED.
export const normalizeArrayDataWithSchemaPath = (data, schemaKey) => {
  return data.reduce((acc, value, idx) => {
    const schemaPath = `${schemaKey}.[${idx}]`

    if (typeof value === "object" && value !== null) {
      return [
        ...acc,
        {
          value: normalizeFormDataWithSchemaPaths(value, schemaPath),
          path: schemaPath,
        },
      ]
    }
    return [...acc, { value, path: schemaPath }]
  }, [])
}

// this is the same as normalizeFormData but it also adds the schema path to each value.
// this is useful if you not only want to obtain a value from the form, but also the path
// it exists in the form (in the case you want to manually interact with that
// specific piece of data).
export const normalizeFormDataWithSchemaPaths = (data, schemaPath) => {
  return Object.entries(data || {}).reduce((acc, [key, value]) => {
    const path = schemaPath ? `${schemaPath}.${key}` : key
    if (Array.isArray(value)) {
      return set(acc, key.split("/"), {
        value: normalizeArrayDataWithSchemaPath(value, path),
        path,
      })
    }
    if (typeof value === "object" && value !== null) {
      if (key.startsWith("/")) {
        return set(
          acc,
          key.split("/")[1],
          normalizeFormDataWithSchemaPaths(value, path)
        )
      }
      return merge(acc, normalizeFormDataWithSchemaPaths(value, path))
    }
    return set(acc, key.split("/"), { value, path })
  }, {})
}

const transformDataToSchema = data => {
  const transformedData = {}

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      if (
        typeof data[key] === "object" &&
        data[key] !== null &&
        !Array.isArray(data[key])
      ) {
        // Recursively flatten the nested objects
        const nestedObject = transformDataToSchema(data[key])
        for (const nestedKey in nestedObject) {
          transformedData[`${key}/${nestedKey}`] = nestedObject[nestedKey]
        }
      } else {
        // Copy non-object properties as-is
        transformedData[key] = data[key]
      }
    }
  }

  return transformedData
}

// Handles mapping of array schema
const handleArraySchema = ({ data, schema, schemaKey, rootSchema }) => {
  const { items } = schema
  const arrayData =
    !schemaKey || isArray(schemaKey) ? data : get(data, schemaKey)

  if (!arrayData) return []

  return arrayData.map(item => {
    const schemaItem = transformDataToSchema(item)
    const safeItemsSchema = getResolvedSchema(items, rootSchema, schemaItem)
    return mapPropertiesToData({
      data: item,
      nullable: false,
      rootSchema,
      properties: safeItemsSchema.properties,
    })
  })
}

// Handles mapping of non-object schema
const handleNonObjectSchema = (data, schemaKey) => {
  if (!schemaKey) {
    throw Error("schemaKey is required for non-object schemas")
  }
  return get(data, schemaKey)
}

const getSafeSchemaKey = schemaKey => {
  if (schemaKey?.startsWith("/")) {
    const adjustedSchemaKey = schemaKey.substring(1)
    return adjustedSchemaKey.split("/")
  }
  if (schemaKey?.includes("/")) {
    return schemaKey.split("/")
  }
  return schemaKey
}

// Handles mapping of object schema
const handleObjectSchema = ({ data, schema, rootSchema }) => {
  return mapPropertiesToData({
    data,
    nullable: schema.nullable,
    properties: schema.properties,
    rootSchema,
  })
}

// Maps properties of schema to data
const mapPropertiesToData = ({ data, properties, nullable, rootSchema }) => {
  if (!properties) return {}

  const objectProperties = Object.entries(properties).reduce(
    (acc, [key, subschema]) => {
      const value = mapFormDataToSchema({
        data,
        schema: subschema,
        schemaKey: key,
        rootSchema,
      })
      return undefined !== value ? { ...acc, [key]: value } : acc
    },
    {}
  )

  if (nullable && !Object.keys(objectProperties).length) return null
  return objectProperties
}

// this function takes normalized data and converts it to a given schema
export const mapFormDataToSchema = ({
  data, // normalized data that needs to be mapped to schema
  schema,
  schemaKey,
  rootSchema = schema,
}) => {
  const safeSchemaKey = getSafeSchemaKey(schemaKey)
  const safeData = schemaKey?.includes("/") ? get(data, safeSchemaKey) : data
  const safeSchema = getResolvedSchema(schema, rootSchema, safeData)
  const { properties, type, items } = safeSchema
  const safeType = isArray(type) ? type.filter(t => t !== "null")[0] : type

  if (safeType === "array" && items?.type === "object") {
    return handleArraySchema({
      data: safeData,
      schema: safeSchema,
      schemaKey: safeSchemaKey,
      rootSchema,
    })
  }

  if (safeType !== "object" || !properties) {
    if (schemaKey?.includes("/")) return safeData
    return handleNonObjectSchema(safeData, safeSchemaKey)
  }

  const objData = handleObjectSchema({
    data: safeData,
    schema: safeSchema,
    rootSchema,
  })

  const resolvedSchema = getResolvedSchema(schema, rootSchema, objData)

  return handleObjectSchema({
    data: safeData,
    rootSchema,
    schema: resolvedSchema,
  })
}
