import React, { useEffect, useRef, useState, useCallback } from "react"
import { theme } from "antd"
import { debounce, merge } from "lodash"
import styled from "styled-components"
import { useDispatch, useSelector } from "react-redux"
import HighchartsReact from "highcharts-react-official"

import DBChart from "./DBChart"
import { actions } from "../../reducers/appReducer"
import queryResolver from "./queryResolver"
import useSelectPoints from "../../hooks/useSelectPoints"
import { useWidgetContext } from "../../hooks"
import constructChartOptions from "./lib/constructChartOptions"
import { selectCrossFiltersOnPage } from "../../selectors/app"

const { useToken } = theme

const ChartContainer = styled.div`
  overflow: auto;
  display: flex;
  flex: 1 0 auto;
  height: ${props => (props.withPagination ? "calc(100% - 40px)" : "100%")};
  width: 100%;
`

const containerProps = {
  style: {
    height: "100%",
    display: "flex",
    flex: "1 1 auto",
    position: "relative",
  },
}

const getChartOptions = (
  dataset,
  widget,
  theme,
  selectPoints,
  selectedPoints
) => {
  if (!dataset) return null
  const chartOptions = constructChartOptions(
    dataset,
    theme,
    widget,
    selectPoints,
    selectedPoints
  )
  return chartOptions
}

const basicChartTypes = ["line", "area", "column", "bar"]

const getNewSeriesOptions = series => {
  // all attributes removed here are attributes we do not want to synchronize with the widget options
  const {
    data,
    type,
    name,
    color,
    custom,
    dataLabels = {},
    ...restSeries
  } = series
  const { formatter, ...restDataLabels } = dataLabels
  return {
    ...restSeries,
    dataLabels: restDataLabels,
    computedSeriesName: name,
  }
}

const mergeSeriesOptions = (seriesOptions, series) => {
  return series.map(nextSeries => {
    const prevSeriesOptions = seriesOptions?.find(prevSeries => {
      return prevSeries.seriesId === nextSeries.seriesId
    })
    const { computedSeriesName, ...prevOptions } = prevSeriesOptions || {}
    const nextSeriesOptions = getNewSeriesOptions(nextSeries)
    return merge({}, nextSeriesOptions, prevOptions)
  })
}

// Series changed only when a series is added or removed
const checkSeriesChanged = (seriesOptions, series) => {
  if (seriesOptions?.length !== series?.length) return true
  const seriesIDMatch = seriesOptions.every((v, i) => {
    return series.find(s => s.seriesId === v.seriesId)
  })
  const seriesNameMatch = seriesOptions.every((v, i) => {
    return series.find(s => v.computedSeriesName === s.name)
  })

  return !seriesIDMatch || !seriesNameMatch
}

const checkAggregateRequired = options => {
  const { xAxisData = [], yAxisData = [], groupByData = [] } = options
  const groupByColumns = groupByData.length > 0
  const aggregatedColumns = yAxisData.filter(column => column.aggregate)
  const categoricalXAxis = xAxisData.filter(column =>
    ["string", "categorical"].includes(column.type)
  )
  return Boolean(
    aggregatedColumns.length || groupByColumns || categoricalXAxis.length
  )
}

const Chart = props => {
  const chartRef = useRef()
  const { pageId, dataset, loading, widget = {}, pagination = {} } = props

  const dispatch = useDispatch()
  const { token } = useToken()
  const { editable } = useWidgetContext()
  const [initialChartOptions, setInitialChartOptions] = useState()

  const chartInitialized = useRef(false)
  const selectedPoints = useSelector(state =>
    selectCrossFiltersOnPage(state, { pageId })
  )
  const selectedPointsRef = useRef(selectedPoints)
  const selectPoints = useSelectPoints(
    pageId,
    widget.id,
    widget.options.datasetId
  )

  useEffect(() => {
    selectedPointsRef.current = selectedPoints
  }, [selectedPoints])

  const handleSideEffects = useCallback(
    (chartOptions, options, widgetId) => {
      let nextWidgetOptions = {}

      // Side effect that handles updating the widget's series options. This is triggered
      // when a series is added or removed from the chart.
      const series = chartOptions.series
      const seriesOptions = options.seriesOptions
      if (
        basicChartTypes.includes(options.chart.type) &&
        checkSeriesChanged(seriesOptions, series)
      ) {
        nextWidgetOptions.seriesOptions = mergeSeriesOptions(
          seriesOptions,
          series
        )
      }

      // Side effect that handles updating the "Aggregates Required" flag
      const aggregateRequired = options.aggregateRequired
      const nextAggregateRequired = checkAggregateRequired(options)
      if (aggregateRequired !== nextAggregateRequired) {
        nextWidgetOptions.aggregateRequired = nextAggregateRequired
      }

      // If any of the side effects are triggered, update the widget options.
      // This should not cause the chart to re-render; rather, it should only update the
      // widget state
      if (Object.entries(nextWidgetOptions).length) {
        dispatch(
          actions.setWidgetOptionsWithSync({
            value: nextWidgetOptions,
            widgetId,
          })
        )
      }
    },
    [dispatch]
  )

  // When the chart is editable, the chart can update many times in a short span of time.
  // to avoid the chart from re-rendering too many times, we debounce the chart update
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const scheduleChartUpdate = useCallback(
    debounce((dataset, options, widgetId) => {
      // first get current chart options based on widget
      const chartOptions = constructChartOptions(
        dataset,
        theme,
        options,
        selectPoints,
        selectedPoints
      )

      // Update the chart with the new chart options
      chartRef.current.chart.update(chartOptions, true, true, true)

      handleSideEffects(chartOptions, options, widgetId)
    }, 100),
    [widget.name, handleSideEffects, selectPoints, token]
  )

  useEffect(() => {
    if (loading) return
    const widgetOptions = {
      ...widget.options,
      pageId,
      name: widget.name,
      widgetId: widget.id,
    }
    if (editable && chartInitialized.current) {
      scheduleChartUpdate(dataset, widgetOptions, widget.id)
      return
    }
    const chartOptions = getChartOptions(
      dataset,
      widgetOptions,
      token,
      selectPoints,
      selectedPointsRef
    )
    setInitialChartOptions(chartOptions)
    chartInitialized.current = true
  }, [
    token,
    pageId,
    loading,
    editable,
    dataset,
    widget.id,
    widget.name,
    selectPoints,
    widget.options,
    scheduleChartUpdate,
  ])

  if ((!dataset?.data && !dataset?.stats) || !initialChartOptions) return null
  return (
    <ChartContainer withPagination={pagination?.showing}>
      <HighchartsReact
        ref={chartRef}
        options={initialChartOptions}
        widgetId={widget.id}
        highcharts={DBChart}
        containerProps={containerProps}
      />
    </ChartContainer>
  )
}

const MemoizedChart = React.memo(Chart)
MemoizedChart.queryResolver = queryResolver
export default MemoizedChart
