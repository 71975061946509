import { combineReducers } from "redux"
import { configureStore } from "@reduxjs/toolkit"

import { appReducer, formsReducer } from "@dbai/applet"
import { tagsReducer, currentCustomerReducer } from "@dbai/ui-staples"

import client from "apolloClient"
import appsReducer from "./appsReducer"
import roleReducer from "./roleReducer"
import actionReducer from "./actionReducer"
import componentReducer from "./componentReducer"
import searchReducer from "./searchReducer"
import packageReducer from "./packageReducer"
import datalakeReducer from "./datalakeReducer"
import instanceReducer from "./instanceReducer"
import notebookReducer from "./notebookReducer"
import packagesReducer from "./packagesReducer"
import scheduleReducer from "./scheduleReducer"
import schedulesReducer from "./schedulesReducer"
import workflowsReducer from "./workflowsReducer"
import notebooksReducer from "./notebooksReducer"
import instancesReducer from "./instancesReducer"
import codeSearchReducer from "./codeSearchReducer"
import workflowInputReducer from "./workflowInputReducer"
import workflowResourceUsageReducer from "./workflowResourceUsageReducer"

export const rootReducer = combineReducers({
  apps: appsReducer.reducer,
  app: appReducer.reducer,
  currentCustomer: currentCustomerReducer.reducer,
  instance: instanceReducer.reducer,
  instances: instancesReducer.reducer,
  notebooks: notebooksReducer.reducer,
  package: packageReducer.reducer,
  packages: packagesReducer.reducer,
  role: roleReducer.reducer,
  schedule: scheduleReducer.reducer,
  schedules: schedulesReducer.reducer,
  workflowInput: workflowInputReducer.reducer,
  workflowResourceUsage: workflowResourceUsageReducer.reducer,
  workflows: workflowsReducer.reducer,
  search: searchReducer.reducer,
  codeSearch: codeSearchReducer.reducer,
  tags: tagsReducer.reducer,
  action: actionReducer.reducer,
  component: componentReducer.reducer,
  notebook: notebookReducer.reducer,
  datalake: datalakeReducer.reducer,
  forms: formsReducer.reducer,
})

export const initStore = () => {
  const devTools = process?.env?.NODE_ENV === "development"
  return configureStore({
    devTools,
    reducer: rootReducer,
    middleware: getDefaultMiddleware => {
      return getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: {
            client,
          },
        },
      })
    },
  })
}

const store = initStore()
export default store
