import React, { useState, useCallback } from "react"
import { debounce } from "lodash"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Input, Popover, Tag, Avatar, Skeleton } from "antd"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBrain,
  faTerminal,
  faDatabase,
  faProjectDiagram,
} from "@fortawesome/free-solid-svg-icons"

import withCustomer from "components/shared/withCustomer"
import { actions } from "reducers/searchReducer"
import { selectSearchTerm, selectSearchResults } from "selectors"

const StyledSearch = styled(Input.Search).attrs(props => ({
  $isActive: !props.$isFocused && !props.value,
}))`
  width: 400px;
`

const StyledContent = styled.div`
  width: 370px;
  min-width: 370px;
  max-height: 500px;
  overflow-y: auto;
`

const ResultIcon = styled(Avatar)`
  margin-right: 10px;
`

const StyledSearchResult = styled(Link)`
  padding: 0.5rem;
  display: flex;
  vertical-align: center;
  color: #495057;

  &:hover {
    background-color: #f5f7fb;
  }
`

const makePathForResult = (cname, result) => {
  switch (result.__typename) {
    case "Workflow":
      return `/${cname}/workflows/${result.id}/edit`
    case "Dataset":
      return `/${cname}/datasets/${result.id}`
    case "Action":
      return `/${cname}/workflows/library/${result.id}`
    case "Component":
      return `/${cname}/workflows/components/${result.id}`
    case "App":
      return `/apps/${cname}/${result.id}`
    default:
      return ""
  }
}

const StyledSkeleton = styled(Skeleton)`
  width: 370px;
`

const mapSearchIcons = {
  Dataset: <FontAwesomeIcon icon={faDatabase} />,
  Workflow: (
    <FontAwesomeIcon transform={{ rotate: 90 }} icon={faProjectDiagram} />
  ),
  Action: <FontAwesomeIcon icon={faTerminal} />,
  Model: <FontAwesomeIcon icon={faBrain} />,
}

const SearchResult = ({ cname, result }) => {
  const icon = mapSearchIcons[result.__typename] || null
  const path = makePathForResult(cname, result)
  const tags = result.tags || []
  return (
    <StyledSearchResult to={path}>
      <ResultIcon shape="square" icon={icon} />
      <div>
        <div>
          <b>{result.__typename}</b> / {result.name}
        </div>
        {tags.map(t => (
          <Tag key={t}>{t}</Tag>
        ))}
      </div>
    </StyledSearchResult>
  )
}

const renderResult = cname => result => {
  const key = `${result.__typename}:${result.id}`
  return <SearchResult key={key} cname={cname} result={result} />
}

// Placeholder to show in the Popover while waiting for results.
const SearchSkeleton = (
  <>
    <StyledSkeleton avatar={{ shape: "square" }} paragraph={{ rows: 1 }} />
    <StyledSkeleton avatar={{ shape: "square" }} paragraph={{ rows: 1 }} />
  </>
)
const Search = ({ customer }) => {
  const cname = customer.normalizedName
  const [isFocused, setIsFocused] = useState(false)
  const dispatch = useDispatch()
  const term = useSelector(selectSearchTerm)
  const results = useSelector(selectSearchResults)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce(() => dispatch(actions.search()), 200),
    [dispatch]
  )

  const onChange = useCallback(
    e => {
      dispatch(actions.set({ name: "term", value: e.target.value }))
      search()
    },
    [dispatch, search]
  )

  const renderResults = useCallback(() => {
    return (
      <StyledContent>
        {results.length === 0
          ? SearchSkeleton
          : results.map(renderResult(cname))}
      </StyledContent>
    )
  }, [cname, results])

  return (
    <Popover trigger="focus" placement="bottomLeft" content={renderResults}>
      <StyledSearch
        placeholder="Search for resources..."
        allowClear
        $isFocused={isFocused}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={onChange}
        value={term}
      />
    </Popover>
  )
}

export default withCustomer(Search, { noAlternate: true })
