import React from "react"
import { Row, Col, InputNumber, Slider } from "antd"

import { useLocalFormFieldControls } from "../../hooks"
import { withFormFieldWrapper } from "../../FormFieldWrapper"

const SliderWithNumberInput = props => {
  const { min, max, value, ...rest } = props
  return (
    <Row gutter={[16, 0]}>
      <Col flex={1}>
        <Slider
          min={min}
          max={max}
          value={typeof value === "number" ? value : 0}
          {...rest}
        />
      </Col>
      <Col>
        <InputNumber min={min} max={max} value={value} {...rest} />
      </Col>
    </Row>
  )
}

const SliderInputCore = props => {
  const { schema = {}, onChange, value: _value, ...rest } = props
  const { minimum: min, maximum: max, metadata } = schema
  const { showNumberInput } = metadata || {}
  const [value, handleChange] = useLocalFormFieldControls(_value, onChange)
  if (showNumberInput) {
    return (
      <SliderWithNumberInput
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        {...rest}
      />
    )
  }
  return (
    <Slider
      min={min}
      max={max}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  )
}

const SliderInput = withFormFieldWrapper(SliderInputCore)
SliderInput.Core = SliderInputCore
export default SliderInput
