import { getResolvedSchema } from "./schema"

const getArraySchemaSideEffects = (key, subschema, existingValue) => {
  let existingArray = existingValue || []
  if (!existingArray.length) return []
  let effects = []

  existingArray.forEach((item, i) => {
    const itemEffects = getSchemaSideEffects(subschema.items, item)

    const currentPath = `${key}.value[${i}]`
    effects = [
      ...effects,
      ...itemEffects.map(effect => {
        const { targetName, sourceName } = effect
        return {
          ...effect,
          targetName: `${currentPath}.value.${targetName}`,
          sourceName: `${currentPath}.value.${sourceName}`,
        }
      }),
    ]
  })

  return effects
}

const getSchemaSideEffects = (_schema, data) => {
  const schema = getResolvedSchema(_schema, _schema, data)
  if (!schema?.properties) return []

  return Object.entries(schema.properties).reduce(
    (effects, [key, _subschema]) => {
      const existingValue = data?.[key]
      const subschema = getResolvedSchema(_subschema, _schema, existingValue)

      switch (true) {
        case subschema.type === "array" && subschema.items.type === "object": {
          return [
            ...effects,
            ...getArraySchemaSideEffects(key, subschema, existingValue),
          ]
        }
        case subschema.type === "object": {
          return [...effects, ...getSchemaSideEffects(subschema, existingValue)]
        }
        default:
          return effects
      }
    },
    schema.metadata?.sideEffects || []
  )
}

export default getSchemaSideEffects
