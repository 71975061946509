import React, { useState } from "react"
import { Button } from "antd"
import styled from "styled-components"
import { useParams } from "react-router"
import { useMutation, useQuery } from "@apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrashAlt, faSearch } from "@fortawesome/free-solid-svg-icons"

import {
  DBTable,
  Spinner,
  ErrorMessage,
  DateFormatter,
  useCurrentCustomer,
} from "@dbai/ui-staples"
import { stringSorter, dateSorter } from "@dbai/tool-box"

import DiffView from "./DiffView"
import { GET_WORKFLOW_SNAPSHOTS, DELETE_SNAPSHOT } from "queries"

const ActionsColumn = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

const DiffLink = props => {
  const { value, onClick } = props
  const handleClick = () => onClick(value)
  return (
    <Button className="btn-icon" onClick={handleClick}>
      <FontAwesomeIcon icon={faSearch} />
    </Button>
  )
}

const DeleteButton = props => {
  const { onClick } = props
  const [confirm, setConfirm] = useState(false)
  const handleClick = () => {
    setConfirm(false)
    onClick()
  }

  if (confirm) {
    return (
      <>
        <Button
          className="btn-icon btn-danger"
          onClick={handleClick}
          style={{ marginRight: "5px" }}
        >
          CONFIRM
        </Button>
        <Button
          className="btn-icon"
          onClick={() => setConfirm(false)}
          style={{ marginRight: "5px" }}
        >
          CANCEL
        </Button>
      </>
    )
  }

  return (
    <Button
      className="btn-icon btn-danger"
      onClick={() => setConfirm(true)}
      style={{ marginRight: "5px" }}
    >
      <FontAwesomeIcon icon={faTrashAlt} />
    </Button>
  )
}

const Actions = props => {
  const { value, customerId, refetchQueries, deleteSnapshot, setSelectedDiff } =
    props

  const handleSelection = () => setSelectedDiff(value)

  const handleDeletion = () => {
    deleteSnapshot({ variables: { customerId, id: value }, refetchQueries })
  }

  return (
    <ActionsColumn>
      <div className="btn-group">
        <DeleteButton onClick={handleDeletion} />
        <DiffLink onClick={handleSelection} />
      </div>
    </ActionsColumn>
  )
}

const Snapshots = () => {
  const [customer] = useCurrentCustomer()
  const { id } = useParams()
  const [selectedDiff, setSelectedDiff] = useState(null)
  const queryVars = { customerId: customer.id, id: Number(id) }
  const { data, loading, error } = useQuery(GET_WORKFLOW_SNAPSHOTS, {
    variables: queryVars,
    fetchPolicy: "cache-and-network",
  })
  const refetchQueries = [
    { query: GET_WORKFLOW_SNAPSHOTS, variables: queryVars },
  ]

  const [deleteSnapshot] = useMutation(DELETE_SNAPSHOT)

  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  if (selectedDiff) {
    const deselect = () => setSelectedDiff(null)
    return (
      <DiffView
        id={selectedDiff}
        customerId={customer.id}
        deselect={deselect}
      />
    )
  }

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: stringSorter("description"),
    },
    {
      title: "Created",
      key: "createdAt",
      dataIndex: "createdAt",
      sorter: dateSorter("createdAt"),
      render: value => {
        return <DateFormatter format="MM/DD/YYYY hh:mm A" value={value} />
      },
    },
    {
      title: "",
      key: "actions",
      dataIndex: "actions",
      render: (_, row) => {
        return (
          <Actions
            value={row.id}
            customerId={customer.id}
            deleteSnapshot={deleteSnapshot}
            refetchQueries={refetchQueries}
            setSelectedDiff={setSelectedDiff}
          />
        )
      },
    },
  ]

  return (
    <DBTable
      dataSource={data?.workflow?.snapshots}
      columns={columns}
      emptyText="No Snapshots Found"
    />
  )
}

export default Snapshots
