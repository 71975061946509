import { ROW_LABEL_DATA_INDEX } from "./utils"
import { getRowValue } from "../../lib/tableValues"

const getRowLabelCell = (columns, row, rowLabel) => {
  if (!rowLabel) return {}
  const rowLabelValue = getRowValue(columns, row, rowLabel)
  if (!rowLabelValue) return {}
  const labelConfig = rowLabel.formatOptions?.labels.find(
    l => l.label === rowLabelValue
  )
  return { [ROW_LABEL_DATA_INDEX]: labelConfig?.title ?? rowLabelValue }
}

const getTableRow = (tableColumns, columns, row, parentColumns = []) => {
  return tableColumns.reduce((rowConfig, tableColumn) => {
    switch (true) {
      case tableColumn.dataIndex === ROW_LABEL_DATA_INDEX:
        return rowConfig
      case tableColumn.children?.length > 0:
        const { column, label, aggregate } = tableColumn
        const restRows = getTableRow(tableColumn.children, columns, row, [
          ...parentColumns,
          { column, label, aggregate },
        ])
        return { ...rowConfig, ...restRows }
      default:
        return {
          ...rowConfig,
          [tableColumn.dataIndex]: getRowValue(
            columns,
            row,
            tableColumn,
            parentColumns
          ),
        }
    }
  }, {})
}

const getTableRows = (data, dataColumns, tableColumns, rowLabel) => {
  return data.reduce((tableRows, dataRow, rowIndex) => {
    const rowCells = getTableRow(tableColumns, dataColumns, dataRow)
    const rowLabelCells = getRowLabelCell(dataColumns, dataRow, rowLabel)
    return [...tableRows, { key: `${rowIndex}`, ...rowCells, ...rowLabelCells }]
  }, [])
}

const pivotDataByRowLabels = (rowLabels, dataset) => {
  const { columns = [], rows = [] } = dataset || {}
  return rows.reduce((acc, row) => {
    let label
    rowLabels.forEach(rowLabel => {
      const rowLabelValue = getRowValue(columns, row, rowLabel)
      if (!rowLabelValue) return acc
      const labelConfig = rowLabel.formatOptions?.labels.find(
        l => l.label === rowLabelValue
      )
      const labelName = labelConfig?.title ?? rowLabelValue
      label = label ? label + "-" + labelName : labelName
    })
    return { ...acc, [label]: acc[label] ? [...acc[label], row] : [row] } //acc[label] ? acc[label] + 1 : 1 }
  }, {})
}

const getTableCellsForRow = computedRow => {
  return Object.entries(computedRow).reduce((final, [key, value]) => {
    if (value) {
      return { ...final, [key]: value }
    }

    return final
  }, {})
}

const getRowCells = (tableColumns, columns, rows) => {
  return rows.reduce((acc, row) => {
    const computedRow = getTableRow(tableColumns, columns, row)
    const cell = getTableCellsForRow(computedRow)
    return { ...acc, ...cell }
  }, {})
}

const getPivottedTableRows = (dataset, tableColumns, pivotData) => {
  const { columns = [] } = dataset || {}
  return Object.entries(pivotData).reduce((acc, [rowKey, rows]) => {
    const rowCells = getRowCells(tableColumns, columns, rows)
    return [
      ...acc,
      { key: `${rowKey}`, [ROW_LABEL_DATA_INDEX]: rowKey, ...rowCells },
    ]
  }, [])
}

const constructDataSource = (dataset, widget, tableColumns) => {
  const { rowLabels = [] } = widget || {}
  const { rows = [], columns = [] } = dataset || {}

  if (rowLabels.length) {
    const pivotData = pivotDataByRowLabels(rowLabels, dataset)
    return getPivottedTableRows(dataset, tableColumns, pivotData)
  }

  return getTableRows(rows, columns, tableColumns)
}

export default constructDataSource
