import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"

export const RedirectContext = React.createContext({})

const getRedirectTarget = defaultTarget => {
  if (defaultTarget === "/login") return "/"
  return defaultTarget || localStorage.getItem("redirectTarget")
}

const setRedirectTarget = target =>
  localStorage.setItem("redirectTarget", target)

const RedirectContextProvider = props => {
  const { children } = props
  const location = useLocation()
  const { pathname } = location
  const [target, setTarget] = useState(() => getRedirectTarget(pathname))

  useEffect(() => {
    if (target === "/login") return setRedirectTarget("/")
    setRedirectTarget(target)
  }, [target])

  const value = {
    redirectTarget: target,
    setRedirectTarget: setTarget,
  }

  return (
    <RedirectContext.Provider value={value}>
      {children}
    </RedirectContext.Provider>
  )
}

RedirectContextProvider.Context = RedirectContext
export default RedirectContextProvider
