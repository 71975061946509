import React, { forwardRef, useCallback, useState } from "react"
import { Layer, Line } from "react-konva"

const getGuideLineName = target => {
  const { name, id } = target.attrs
  return `guid-line-${id ?? name}`
}

const setGuideLinesForShape = (name, prev, next) => {
  const filteredLines = prev.filter(line => line.name !== name)
  return [...filteredLines, ...next]
}

const CanvasLayer = forwardRef((props, ref) => {
  const { children, stageRef } = props
  const [hLines, setHlines] = useState([])
  const [vLines, setVlines] = useState([])

  const onDragEnd = useCallback(e => {
    const name = getGuideLineName(e.target)
    setHlines(hLines => setGuideLinesForShape(name, hLines, []))
    setVlines(vLines => setGuideLinesForShape(name, vLines, []))
  }, [])

  const getGuides = useCallback((lineGuideStops, itemBounds) => {
    var resultV = []
    var resultH = []

    lineGuideStops.vertical.forEach(lineGuide => {
      itemBounds.vertical.forEach(itemBound => {
        var diff = Math.abs(lineGuide - itemBound.guide)
        // if the distance between guild line and object snap point is close we can consider this for snapping
        if (diff < 5) {
          resultV.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          })
        }
      })
    })

    lineGuideStops.horizontal.forEach(lineGuide => {
      itemBounds.horizontal.forEach(itemBound => {
        var diff = Math.abs(lineGuide - itemBound.guide)
        if (diff < 5) {
          resultH.push({
            lineGuide: lineGuide,
            diff: diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          })
        }
      })
    })

    var guides = []

    // find closest snap
    var minV = resultV.sort((a, b) => a.diff - b.diff)[0]
    var minH = resultH.sort((a, b) => a.diff - b.diff)[0]
    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: "V",
        snap: minV.snap,
      })
    }
    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: "H",
        snap: minH.snap,
      })
    }
    return guides
  }, [])

  const drawGuides = useCallback((target, guides) => {
    const name = getGuideLineName(target)
    let verticalLines = []
    let horizontalLines = []
    guides.forEach(lg => {
      if (lg.orientation === "H") {
        let guide = {
          name,
          x: 0,
          dash: [4, 6],
          strokeWidth: 1,
          y: lg.lineGuide,
          stroke: "rgb(0, 161, 255)",
          points: [-6000, 0, 6000, 0],
        }
        horizontalLines = [...horizontalLines, guide]
      } else if (lg.orientation === "V") {
        let guide = {
          name,
          dash: [4, 6],
          strokeWidth: 1,
          x: lg.lineGuide,
          stroke: "rgb(0, 161, 255)",
          points: [0, -6000, 0, 6000],
          y: 0,
        }
        verticalLines = [...verticalLines, guide]
      }
    })
    setHlines(hLines => setGuideLinesForShape(name, hLines, horizontalLines))
    setVlines(vLines => setGuideLinesForShape(name, vLines, verticalLines))
  }, [])

  // where can we snap our objects?
  const getLineGuideStops = useCallback(
    skipShape => {
      if (!stageRef?.current) {
        return {
          vertical: [],
          horizontal: [],
        }
      }
      // we can snap to stage borders and the center of the stage
      var vertical = [0, stageRef.current.width() / 2, stageRef.current.width()]
      var horizontal = [
        0,
        stageRef.current.height() / 2,
        stageRef.current.height(),
      ]

      // and we snap over edges and center of each object on the canvas
      stageRef.current.find(".object").forEach(guideItem => {
        // skipping itself and any transformers within the stage
        if (
          guideItem.attrs.name === "transformer" ||
          guideItem.attrs.id === skipShape.attrs.id
        ) {
          return
        }
        var box = guideItem.getClientRect({ relativeTo: stageRef.current })
        // and we can snap to all edges of shapes
        vertical.push([box.x, box.x + box.width, box.x + box.width / 2])
        horizontal.push([box.y, box.y + box.height, box.y + box.height / 2])
      })
      return {
        vertical: vertical.flat(),
        horizontal: horizontal.flat(),
      }
    },
    [stageRef]
  )

  // what points of the object will trigger to snapping?
  // it can be just center of the object
  // but we will enable all edges and center
  const getObjectSnappingEdges = useCallback(
    node => {
      var box = node.getClientRect({ relativeTo: stageRef.current })
      var absPos = node.absolutePosition()

      return {
        vertical: [
          {
            guide: Math.round(box.x),
            offset: Math.round(absPos.x - box.x),
            snap: "start",
          },
          {
            guide: Math.round(box.x + box.width / 2),
            offset: Math.round(absPos.x - box.x - box.width / 2),
            snap: "center",
          },
          {
            guide: Math.round(box.x + box.width),
            offset: Math.round(absPos.x - box.x - box.width),
            snap: "end",
          },
        ],
        horizontal: [
          {
            guide: Math.round(box.y),
            offset: Math.round(absPos.y - box.y),
            snap: "start",
          },
          {
            guide: Math.round(box.y + box.height / 2),
            offset: Math.round(absPos.y - box.y - box.height / 2),
            snap: "center",
          },
          {
            guide: Math.round(box.y + box.height),
            offset: Math.round(absPos.y - box.y - box.height),
            snap: "end",
          },
        ],
      }
    },
    [stageRef]
  )

  const onDragMove = useCallback(
    e => {
      if (
        e.target.attrs.name === "transformer" &&
        e.target.nodes().length < 2
      ) {
        return
      }

      // find possible snapping lines
      var lineGuideStops = getLineGuideStops(e.target)
      // find snapping points of current object
      var itemBounds = getObjectSnappingEdges(e.target)

      // now find where can we snap current object
      var guides = getGuides(lineGuideStops, itemBounds)

      // do nothing of no snapping
      if (!guides.length) {
        // ensure previous guides are removed
        onDragEnd(e)
        return
      }

      drawGuides(e.target, guides)

      var absPos = e.target.absolutePosition()
      // now force object position
      guides.forEach(lg => {
        switch (lg.snap) {
          case "start": {
            switch (lg.orientation) {
              case "V": {
                absPos.x = lg.lineGuide + lg.offset
                break
              }
              case "H": {
                absPos.y = lg.lineGuide + lg.offset
                break
              }
              default:
                break
            }
            break
          }
          case "center": {
            switch (lg.orientation) {
              case "V": {
                absPos.x = lg.lineGuide + lg.offset
                break
              }
              case "H": {
                absPos.y = lg.lineGuide + lg.offset
                break
              }
              default:
                break
            }
            break
          }
          case "end": {
            switch (lg.orientation) {
              case "V": {
                absPos.x = lg.lineGuide + lg.offset
                break
              }
              case "H": {
                absPos.y = lg.lineGuide + lg.offset
                break
              }
              default:
                break
            }
            break
          }
          default:
            break
        }
      })
      e.target.absolutePosition(absPos)
    },
    [
      onDragEnd,
      drawGuides,
      getGuides,
      getLineGuideStops,
      getObjectSnappingEdges,
    ]
  )

  return (
    <Layer ref={ref} onDragEnd={onDragEnd} onDragMove={onDragMove}>
      {children}
      {hLines.map((item, i) => {
        return <Line key={i} {...item} />
      })}
      {vLines.map((item, i) => {
        return <Line key={i} {...item} />
      })}
    </Layer>
  )
})

export default React.memo(CanvasLayer)
