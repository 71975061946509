import React, { memo, useEffect, useState, useMemo, useCallback } from "react"
import { isEqual } from "lodash"
import styled from "styled-components"
import { Row, Col, Table, Typography } from "antd"

import { alphanumid } from "@dbai/tool-box"

import EditableRow from "./EditableRow"
import JSONSchemaField from "../../JSONSchemaField"
import { useFormSchema, useFormData } from "../../hooks"
import {
  getResolvedSchema,
  orderSchemaProperties,
  isSchemaHidden,
  getCurrentPaths,
} from "../../utils"

const { Title } = Typography

const StyledSplitView = styled.div`
  padding: 16px;
`

const StyledSplitViewRight = styled(Col)`
  width: 100%;
  height: 100%;
  overflowy: auto;
  max-height: ${props => props.maxHeight};
`

const StyledTable = memo(styled(Table)`
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;

  table thead th:first-of-type {
    border-top-left-radius: 7px;
  }

  .ant-table-selection-col,
  .ant-table-selection-column {
    padding: 0 !important;
  }
`)

const StyledTitle = styled(Title)`
  margin: auto;
  width: fit-content;
`

const tableComponents = { body: { row: EditableRow } }

const EditableCell = memo(({ path, record, schemaKey }) => {
  const index = record.__index
  const schema = record.__schema

  const { path: subPath, dataPath: subName } = getCurrentPaths(
    path,
    `.[${index}]`
  )

  const { items } = schema
  const { properties } = items
  const parentSchema = useMemo(() => {
    return items
  }, [items])

  if (!properties[schemaKey]) return null

  return (
    <JSONSchemaField
      noStyle
      path={subPath}
      name={subName}
      schema={properties[schemaKey]}
      schemaKey={schemaKey}
      parentSchema={parentSchema}
    />
  )
})

const createColumns = ({ path, schema }) => {
  const { columns } = schema.metadata.tableListOptions || {}
  return columns?.reduce((acc, column) => {
    const { schemaKey, ...columnOpts } = column
    const subschema = schema.items.properties[schemaKey]
    if (!subschema || isSchemaHidden(subschema)) return acc
    const { title } = subschema
    return [
      ...acc,
      {
        title,
        ellipsis: true,
        render: (_, record) => {
          return handleRenderCell({
            key: schemaKey,
            path,
            record,
          })
        },
        shouldCellUpdate: () => false,
        ...columnOpts,
        dataIndex: schemaKey,
      },
    ]
  }, [])
}

const handleRenderCell = ({ key, path, record }) => (
  <EditableCell path={path} record={record} schemaKey={key} />
)

const expandableConfig = ({
  path,
  schema,
  sections,
  tableListOptions = {},
}) => ({
  ...(tableListOptions.expandable || {}),
  expandedRowRender: record =>
    sections.map(section =>
      handleRenderCell({
        key: section,
        path,
        record,
        schema,
      })
    ),
})

const checkListDataChanged = (data, value, key) => {
  if (key) {
    return !isEqual(
      data?.map(d => d[key]),
      value?.map(v => v[key])
    )
  }
  return JSON.stringify(data) !== JSON.stringify(value)
}

const useListData = (name, rowKeyName) => {
  const data = useFormData(name)
  const [value, setValue] = useState(data)

  useEffect(() => {
    if (checkListDataChanged(data, value, rowKeyName)) {
      setValue(data)
    }
  }, [data, value, rowKeyName])

  return value
}

const TableList = ({ schema, name, onChange, path, schemaKey, loading }) => {
  const [selectedRowKey, setSelectedRowKey] = useState(null)
  const { tableListOptions = {} } = schema.metadata
  const { split, sections, scroll, dnd, rowKeyName, pagination, bordered } =
    tableListOptions

  const value = useListData(name, rowKeyName)
  const rootSchema = useFormSchema()

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (!dnd) return
      const dragRow = value[dragIndex]
      const valueCopy = [...value]
      valueCopy.splice(dragIndex, 1)
      valueCopy.splice(hoverIndex, 0, dragRow)
      onChange(valueCopy)
    },
    [onChange, value, dnd]
  )

  const handleRowClick = useCallback(
    record => e => {
      e.preventDefault()
      e.stopPropagation()
      setSelectedRowKey(selected =>
        selected === record.key ? null : record.key
      )
    },
    []
  )

  const handleRow = useCallback(
    (record, index) => {
      return {
        dnd,
        // TODO: index should be based on value index, not row index
        index,
        moveRow,
        onClick: handleRowClick(record),
      }
    },
    [moveRow, handleRowClick, dnd]
  )

  const dataSource = useMemo(
    () =>
      value.map((item, index) => {
        const resolvedSchema = getResolvedSchema(schema.items, rootSchema, item)
        const orderedItemProperties = orderSchemaProperties(resolvedSchema)
        const fullResolvedSchema = {
          ...schema,
          items: {
            ...resolvedSchema,
            properties: orderedItemProperties,
          },
        }
        return {
          ...item,
          __index: index,
          __schema: fullResolvedSchema,
          key: item[rowKeyName] || alphanumid(),
        }
      }),
    [value, rowKeyName, schema, rootSchema]
  )

  const columns = useMemo(
    () =>
      createColumns({
        path,
        schema,
      }),
    [path, schema]
  )

  const rowSelection = useMemo(
    () => ({
      columnWidth: "0px",
      hideSelectAll: true,
      selectedRowKeys: selectedRowKey ? [selectedRowKey] : [],
      renderCell: () => null,
    }),
    [selectedRowKey]
  )

  const [selectedRow, setSelectedRow] = useState()
  useEffect(() => {
    const currentSelectedRow = dataSource.find(
      row => row.key === selectedRowKey
    )
    if (!isEqual(selectedRow, currentSelectedRow)) {
      setSelectedRow(currentSelectedRow)
    }
  }, [selectedRowKey, selectedRow, dataSource])

  const maxHeight = useMemo(
    () => (scroll.y ? scroll.y + 38 : "100%"),
    [scroll.y]
  )

  if (split && sections?.length) {
    return (
      <StyledSplitView>
        <Row gutter={[16, 0]} wrap={false}>
          <Col span={10}>
            <StyledTable
              size="small"
              onRow={handleRow}
              scroll={scroll}
              columns={columns}
              loading={loading}
              bordered={bordered}
              pagination={pagination}
              dataSource={dataSource}
              components={tableComponents}
              rowSelection={rowSelection}
            />
          </Col>
          <StyledSplitViewRight span={14} maxHeight={maxHeight}>
            {selectedRowKey && selectedRow ? (
              sections.map(section =>
                handleRenderCell({
                  key: section,
                  path,
                  record: selectedRow,
                })
              )
            ) : (
              <StyledTitle level={4} style={{}}>
                Select a row
              </StyledTitle>
            )}
          </StyledSplitViewRight>
        </Row>
      </StyledSplitView>
    )
  }

  return (
    <StyledTable
      {...{
        size: "small",
        onRow: handleRow,
        scroll,
        columns,
        loading,
        bordered,
        dataSource,
        pagination,
        components: tableComponents,
        expandable: sections?.length
          ? expandableConfig({
              path,
              sections,
              tableListOptions: tableListOptions,
            })
          : undefined,
      }}
    />
  )
}

export default React.memo(TableList)
