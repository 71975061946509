import getColumnsForWidgetQuery from "../../lib/getColumnsForWidgetQuery"
import { GET_FIVE_NUMBER_SUMMARY, GET_CORRELATION } from "@dbai/ui-staples"

const getGroupByTime = xAxis => {
  const datetimeColumn = xAxis.find(({ type }) => type === "datetime")
  if (!datetimeColumn || !datetimeColumn.groupByTime) return null
  return {
    column: datetimeColumn?.column,
    ...datetimeColumn.groupByTime,
  }
}
const getOrderBy = sort => {
  if (!sort) return []
  const validSortColumns = sort.filter(({ column }) => {
    return Boolean(column)
  })
  if (!validSortColumns.length) return
  const orderBy = validSortColumns.map(({ column, direction }) => {
    return `${column} ${direction === "asc" ? "ASC" : "DESC"}`
  })

  return orderBy || []
}

const gqlQueryMap = {
  heatmap: GET_CORRELATION,
  boxplot: GET_FIVE_NUMBER_SUMMARY,
}

const queryResolver = widget => {
  const orderBy = getOrderBy(widget.orderBy)
  const {
    where,
    limit,
    offset,
    format,
    datasetId,
    pollInterval,
    groupByData = [],
    xAxisData = [],
    yAxisData = [],
    columns = [],
  } = widget || {}

  let select, groupByTime, groupBy
  switch (widget.chart?.type) {
    case "heatmap":
      select = getColumnsForWidgetQuery(columns)
      break
    case "boxplot":
      select = getColumnsForWidgetQuery(yAxisData)
      groupBy = groupByData.map(({ column }) => column)
      break
    default:
      select = getColumnsForWidgetQuery([...xAxisData, ...yAxisData])
      groupByTime = getGroupByTime(xAxisData)
      groupBy = groupByData.map(({ column }) => column)
  }

  const gqlQuery = gqlQueryMap[widget.chart?.type]
  const hasNumericalGroup = groupByData.some(({ type }) =>
    ["numerical", "int", "float"].includes(type)
  )

  return {
    where,
    select,
    offset,
    format,
    orderBy,
    groupBy,
    datasetId,
    groupByTime,
    pollInterval,
    query: gqlQuery,
    limit: hasNumericalGroup ? 20 : limit,
  }
}

export default queryResolver
