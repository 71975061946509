import React from "react"
import { PanelResizeHandle } from "react-resizable-panels"
import styled from "styled-components"

const ResizeHandleOutter = styled(PanelResizeHandle)`
  width: 10px;
  background-color: #d2d2d2;
`

const ResizeHandle = props => {
  const { className = "", id } = props
  return <ResizeHandleOutter id={id} className={className} />
}

export default ResizeHandle
