import React, { useMemo, useCallback, useState, useRef } from "react"
import { Stage, Rect } from "react-konva"

import CanvasLayer from "./CanvasLayer"
import CanvasTransformer from "./CanvasTransformer"

const CanvasStage = React.forwardRef((props, ref) => {
  const { children, ...rest } = props
  const trRef = useRef()
  const layerRef = useRef()
  const oldPos = useRef(null)
  const selectionRef = useRef()
  const [selectedId, setSelectedId] = useState(null)
  const selection = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0,
  })

  const checkDeselect = useCallback(e => {
    // deselect when clicked on empty area
    const clickedOnEmpty = e.target === e.target.getStage()
    if (clickedOnEmpty) {
      setSelectedId(null)
      trRef.current.nodes([])
    }
  }, [])

  const updateSelectionRect = useCallback(() => {
    const node = selectionRef.current
    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: "rgba(0, 161, 255, 0.3)",
    })
    node.getLayer().batchDraw()
  }, [])

  const onMouseDown = useCallback(
    e => {
      // only draw selection box when click on empty area within a stage
      if (e.target.getType() !== "Stage") {
        return
      }

      const pos = e.target.getStage().getPointerPosition()
      selection.current.visible = true
      selection.current.x1 = pos.x
      selection.current.y1 = pos.y
      selection.current.x2 = pos.x
      selection.current.y2 = pos.y
      updateSelectionRect()
    },
    [updateSelectionRect]
  )

  const onMouseMove = useCallback(
    e => {
      if (!selection.current.visible) {
        return
      }
      const pos = e.target.getStage().getPointerPosition()
      selection.current.x2 = pos.x
      selection.current.y2 = pos.y
      updateSelectionRect()
    },
    [updateSelectionRect]
  )

  const onMouseUp = useCallback(() => {
    const Konva = window.Konva
    oldPos.current = null
    if (!selection.current.visible) {
      return
    }
    const selBox = selectionRef.current.getClientRect()

    const elements = []
    layerRef.current.find(".object").forEach(elementNode => {
      const elBox = elementNode.getClientRect()
      if (Konva.Util.haveIntersection(selBox, elBox)) {
        elements.push(elementNode)
      }
    })
    trRef.current.nodes(elements)
    selection.current.visible = false
    // disable click event
    Konva.listenClickTap = false
    updateSelectionRect()
  }, [updateSelectionRect])

  const onClickTap = useCallback(e => {
    // // if we are selecting with rect, do nothing
    if (selectionRef.current.visible) {
      return
    }
    let stage = e.target.getStage()
    let layer = layerRef.current
    let tr = trRef.current

    // if click on empty area - remove all selections
    if (e.target === stage) {
      setSelectedId(null)
      tr.nodes([])
      layer.draw()
      return
    }

    // do nothing if clicked NOT on our rectangles
    if (!e.target.hasName(".object")) {
      return
    }

    // do we pressed shift or ctrl?
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey
    const isSelected = tr.nodes().indexOf(e.target) >= 0

    if (!metaPressed && !isSelected) {
      // if no key pressed and the node is not selected
      // select just one
      tr.nodes([e.target])
    } else if (metaPressed && isSelected) {
      // if we pressed keys and node was selected
      // we need to remove it from selection:
      const nodes = tr.nodes().slice() // use slice to have new copy of array
      // remove node from array
      nodes.splice(nodes.indexOf(e.target), 1)
      tr.nodes(nodes)
    } else if (metaPressed && !isSelected) {
      // add the node into selection
      const nodes = tr.nodes().concat([e.target])
      tr.nodes(nodes)
    }
    layer.draw()
  }, [])

  const handleSelectShape = useCallback(
    shape => shapeRef => {
      if (shapeRef.current !== undefined) {
        let temp = trRef.current.nodes()
        if (!temp.includes(shapeRef.current)) {
          temp.push(shapeRef.current)
        }
        trRef.current.nodes(temp)
        trRef.current.nodes(temp)
        trRef.current.getLayer().batchDraw()
      }
      setSelectedId(shape.id)
    },
    []
  )

  const memoizedChildren = useMemo(() => {
    return React.cloneElement(children, {
      onSelect: handleSelectShape,
      selectedId,
    })
  }, [handleSelectShape, children, selectedId])

  return (
    <Stage
      {...rest}
      ref={ref}
      onClick={onClickTap}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onTouchStart={checkDeselect}
    >
      <CanvasLayer ref={layerRef} stageRef={ref}>
        {memoizedChildren}
        <CanvasTransformer ref={trRef} />
        <Rect
          ref={selectionRef}
          fill="rgba(0,0,255,0.5)"
          name="transformer-object"
        />
      </CanvasLayer>
    </Stage>
  )
})

export default React.memo(CanvasStage)
