import { scalarFieldOptions } from "../../lib/fieldOptionsSchemas"

export default {
  type: "object",
  default: {},
  metadata: {
    layout: { gutter: [16] },
    sections: {
      ...scalarFieldOptions.metadata.sections,
      stringOptions: {
        title: "Text Options",
        properties: ["minLength", "maxLength"],
      },
    },
  },
  properties: {
    ...scalarFieldOptions.properties,
    title: {
      default: "Text Form Field",
      type: "string",
      title: "Label",
      metadata: {
        fieldProps: {
          placeholder: "Enter a label",
        },
      },
    },
    nullable: {
      type: "boolean",
      title: "Clearable",
      description: "Allow field to be removed from form state",
    },
    maxLength: {
      title: "Max Length",
      type: "integer",
      minimum: 0,
    },
    minLength: {
      title: "Min Length",
      type: "integer",
      minimum: 0,
      default: 0,
    },
    defaultValue: {
      type: "string",
      title: "Default Value",
    },
    "metadata/fieldProps/placeholder": {
      type: "string",
      title: "Placeholder",
    },
  },
}
