import { flow } from "lodash"

import { processScalarFieldOptions } from "../../lib/generateSchemaHelpers"

const generateSchema = flow([
  options => {
    return {
      ...options,
      type: "number",
      metadata: {
        ...options.metadata,
        component: "Dataset",
      },
    }
  },
  processScalarFieldOptions,
])

export default generateSchema
