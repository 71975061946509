import React, { useState } from "react"
import { Col, Button } from "antd"
import { useQuery } from "@apollo/client"

import {
  Content,
  TableWithFolders,
  SidePane,
  PageSpinner,
  ErrorMessage,
  AuthBoundary,
  ActionsFormatter,
  useCurrentCustomer,
} from "@dbai/ui-staples"

import { useTagColumn } from "hooks"
import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"
import NewDataset from "components/pages/Dataset/NewDataset"
import { GET_DATASETS, DELETE_DATASET, GET_DATASET_METADATA } from "queries"

const columnOrder = ["name", "tags", "createdAt", "updatedAt", "actions"]

const tagColumnOptions = {
  type: "datasets",
  query: GET_DATASET_METADATA,
}

const ActionsFormatterComp = props => {
  const { row } = props
  const [{ id: customerId, normalizedName: cname }] = useCurrentCustomer()
  const variables = { customerId }
  return (
    <ActionsFormatter
      value={row.id}
      editPath={`/${cname}/datasets/`}
      variables={variables}
      refetchVariables={variables}
      deleteGql={DELETE_DATASET}
      confirmDelete={true}
      refetchQuery={GET_DATASETS}
    />
  )
}

const Datasets = props => {
  const { customer } = props

  const variables = { customerId: customer.id }
  const [showNew, setShowNew] = useState(false)
  const tags = useTagColumn(tagColumnOptions)
  const { data, loading, error } = useQuery(GET_DATASETS, {
    variables,
    fetchPolicy: "network-only",
  })

  const refetchQuery = [
    {
      query: GET_DATASETS,
      variables,
    },
  ]

  if (loading) return <PageSpinner />
  if (error) return <ErrorMessage error={error} />

  const routes = [
    { breadcrumbName: customer.name },
    { breadcrumbName: "Datasets" },
  ]

  return (
    <AuthBoundary subject="datasets" action="LIST">
      <div id="datasets">
        <PageHeader routes={routes}>
          <Button onClick={() => setShowNew(true)} type="primary">
            + NEW DATASET
          </Button>
        </PageHeader>

        <Content>
          <Col span={24}>
            <TableWithFolders
              columns={[tags]}
              folderType="datasets"
              Actions={ActionsFormatterComp}
              columnOrder={columnOrder}
              dataSource={data.datasets}
              rowLink={row => `/${customer.normalizedName}/datasets/${row.id}`}
            />
          </Col>
        </Content>

        <SidePane
          title="Create Dataset"
          isVisible={showNew}
          width="30vw"
          onCloseClicked={() => setShowNew(false)}
        >
          <NewDataset
            refetchQuery={refetchQuery}
            afterSubmit={() => setShowNew(false)}
            customer={customer}
          />
        </SidePane>
      </div>
    </AuthBoundary>
  )
}

export default withCustomer(Datasets)
