import { useCallback } from "react"
import { useSelector } from "react-redux"

import { selectMenuContent } from "../../selectors/app"
import useWidgetMenuItems from "./useWidgetMenuItems"
import useLayoutMenuItems from "./useLayoutMenuItems"
import usePageMenuItems from "./usePageMenuItems"

const useContextMenuItems = handleClose => {
  const { pages, widget, widgetPageId } = useSelector(selectMenuContent)

  const handleItemClick = useCallback(
    (onClick, remainOpenOnClick) => e => {
      onClick()
      if (!remainOpenOnClick && handleClose) {
        handleClose()
      }
    },
    [handleClose]
  )

  const pageItems = usePageMenuItems(pages, handleItemClick)
  const widgetItems = useWidgetMenuItems(widget, handleItemClick)
  const layoutItems = useLayoutMenuItems({
    widget,
    handleClose,
    widgetPageId,
    additionalItems: widgetItems,
    handleClick: handleItemClick,
  })

  return [...layoutItems, ...pageItems]
}

export default useContextMenuItems
