import React from "react"
import { useSelector } from "react-redux"

import { selectAutoScroll } from "selectors"

const AutoScrollButton = () => {
  const autoScrollEnabled = useSelector(selectAutoScroll)

  const text = autoScrollEnabled ? "Disable Auto Scroll" : "Enable Auto Scroll"

  return <>{text}</>
}

export default AutoScrollButton
