import React from "react"
import PropTypes from "prop-types"
import numeral from "numeral"

const PageView = props => {
  const { onClick, href, page, selected } = props

  const className = selected ? "active" : ""

  return (
    <li className={className}>
      <a
        onClick={onClick}
        role="button"
        href={href}
        tabIndex="0"
        onKeyPress={onClick}
      >
        {numeral(page).format("0,0")}
      </a>
    </li>
  )
}

PageView.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  href: PropTypes.string,
  page: PropTypes.number.isRequired,
}

export default PageView
