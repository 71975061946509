import formatOptionsSchema from "../formatOptionsSchema"
import datasetColumnSchema from "../datasetColumnSchema"
import getConditionalFormatSchema from "../shared/getConditionalFormatSchema"
import colorScaleSchema from "./colorScaleSchema"

const getTableColumnSchema = requireAggregate => ({
  default: {},
  type: "object",
  title: "Column",
  required: ["columnType"],
  metadata: {
    labelPath: "column",
    layout: { gutter: [16] },
    order: [
      "columnType",
      "title",
      "column",
      "aggregate",
      "computation",
      "format",
      "minPrecision",
      "maxPrecision",
      "suffix",
      "textAlign",
      "width",
      "searchable",
      "sortable",
      "defaultSortOrder",
      "sortPriority",
      "showSorterTooltip",
      "formatOptions/labels",
    ],
    sections: {
      column: {
        title: "Column Options",
        properties: [
          "width",
          "defaultSortOrder",
          "sortPriority",
          "showSorterTooltip",
          "searchable",
          "sortable",
          "fixed",
        ],
      },
      options: {
        title: "Cell Options",
        properties: [
          "textAlign",
          "minPrecision",
          "maxPrecision",
          "suffix",
          "conditionalFormat",
          "colorScale",
        ],
      },
    },
  },
  dependencies: {
    sortable: {
      if: {
        properties: {
          sortable: {
            enum: [true],
          },
        },
      },
      then: {
        properties: {
          defaultSortOrder: {
            type: "string",
            enum: ["ascend", "descend"],
            title: "Default Sort Direction",
            metadata: {
              labels: ["Ascending", "Descending"],
              fieldProps: {
                placeholder: "Select Sort Direction",
              },
            },
          },
          sortPriority: {
            title: "Sort Priority",
            type: "number",
            default: 0,
          },
          showSorterTooltip: {
            title: "Show Sorter Tooltip",
            type: "boolean",
            default: true,
          },
        },
      },
    },
    columnType: {
      if: {
        properties: {
          columnType: {
            enum: ["computed"],
          },
        },
      },
      then: {
        dependencies: formatOptionsSchema.dependencies,
        properties: {
          textAlign: { $ref: "#/$defs/textAlign" },
          searchable: {
            title: "Show Search",
            type: "boolean",
          },
          sortable: {
            title: "Sortable",
            type: "boolean",
          },
          fixed: { $ref: "#/$defs/fixed" },
          colorScale: colorScaleSchema,
          conditionalFormat: getConditionalFormatSchema(true, requireAggregate),
          computation: { $ref: "#/$defs/tableComputation" },
          ...formatOptionsSchema.properties,
        },
      },
      else: {
        dependencies: datasetColumnSchema.dependencies,
        properties: {
          textAlign: { $ref: "#/$defs/textAlign" },
          searchable: {
            title: "Show Search",
            type: "boolean",
          },
          sortable: {
            title: "Sortable",
            type: "boolean",
          },
          fixed: { $ref: "#/$defs/fixed" },
          colorScale: colorScaleSchema,
          conditionalFormat: getConditionalFormatSchema(true, requireAggregate),
          ...datasetColumnSchema.properties,
        },
      },
    },
  },
  properties: {
    title: {
      $ref: "#/$defs/title",
      metadata: {
        validateFirst: true,
        fieldProps: {
          placeholder: "<Current Column Name>",
        },
      },
    },
    columnType: {
      title: "Type",
      type: "string",
      enum: ["datasetColumn", "computed"],
      metadata: {
        hideOnSelect: true,
        labels: ["Dataset Column", "Computed Column"],
        fieldProps: {
          popupMatchSelectWidth: false,
        },
      },
    },
    dataIndex: { $ref: "#/$defs/dataIndex" },
    width: { $ref: "#/$defs/width" },
  },
})

export default getTableColumnSchema
