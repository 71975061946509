import React, { useCallback } from "react"
import { useSelector } from "react-redux"

import { ErrorMessage, Spinner } from "@dbai/ui-staples"

import { useAction } from "hooks"
import CodeEditor from "./CodeEditor"
import { actions } from "reducers/componentReducer"
import {
  selectComponent,
  selectComponentError,
  selectComponentSource,
  selectComponentLoading,
} from "selectors"

/*
 * NOTE: Currently reusable components have only one cell, thus the
 * locked value here. I'm doing this like this to make it easier
 * to update in the future when/if we add multi-cell capabilities.
 */
const cellIdx = 0

const ComponentForm = props => {
  const loading = useSelector(selectComponentLoading)
  const error = useSelector(selectComponentError)
  const component = useSelector(selectComponent)
  const source = useSelector(selectComponentSource) || []
  const mapCmToSource = useCallback(cm => {
    return { value: cm.getValue(), cellIdx }
  }, [])
  const handleSourceUpdate = useAction(actions.setCellSource, mapCmToSource)

  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  const defaultCode = [
    `# Welcome to the component editor! Below is a basic component template you can modify to fit your needs.
from dbai.decorators import dbai_component
from pydantic import BaseModel, Field

class Input(BaseModel):
  example_string_field: str
  # supported data types: str, int, float, bool
  # For custom input types, use the following format:
  # example_input: str = Field(..., metadata={"component":"example"})



class Output(BaseModel):
  example_return_field: str

# define the component.  The def name can be anything you choose
@dbai_component(summary="summary here", description="more detailed description here")
def component_example(input: Input):
  output = Output(example_return_field = input.example_string_field)
  return output`,
  ]
  const finalSource =
    source.length === 0 || source[0] === "" ? defaultCode : source
  return (
    <CodeEditor
      source={finalSource}
      revisionId={component.revisionId}
      onChange={handleSourceUpdate}
      {...props}
    />
  )
}

export default ComponentForm
