import React, { useState, useCallback } from "react"

import {
  Input,
  ApiForm,
  HiddenInput,
  AuthBoundary,
  useFormSet,
} from "@dbai/ui-staples"

import { CREATE_DATASET } from "queries"
import withCustomer from "components/shared/withCustomer"

import styled from "styled-components"
import { Tag, Input as AntdInput } from "antd"

const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`

const StyledInput = styled(AntdInput)`
  && {
    position: relative;
    width: 25px;
    border-style: dashed;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  &:focus {
    width: 115px;
    border-style: solid;
  }
`

const StyledTag = styled(Tag)`
  margin-top: 3px;
  margin-bottom: 3px;
`

const UniqueKeyInput = () => {
  const formSet = useFormSet()
  const [value, setValue] = useState("")
  const clearValue = () => setValue("")
  const [allTags, setAllTags] = useState(new Set())

  const handleOnClose = useCallback(
    tag => evt => {
      evt.preventDefault()
      const newSet = new Set(allTags)
      newSet.delete(tag)
      setAllTags(newSet)
      formSet({ name: "tableMeta.uniqueKeys", value: Array.from(newSet) })
    },
    [allTags, setAllTags, formSet]
  )

  const handleOnEnter = useCallback(
    evt => {
      evt.preventDefault()
      const tag = value
      const newSet = new Set(allTags)
      newSet.add(tag)
      setAllTags(newSet)
      formSet({ name: "tableMeta.uniqueKeys", value: Array.from(newSet) })
      clearValue()
    },
    [allTags, setAllTags, formSet, value]
  )

  return (
    <TagsList>
      {[...allTags].map(tag => (
        <StyledTag key={tag} closable onClose={handleOnClose(tag)}>
          {tag}
        </StyledTag>
      ))}
      <StyledInput
        size="small"
        value={value}
        className="tag-input"
        placeholder="+ New Column"
        onBlur={clearValue}
        onChange={e => setValue(e.target.value)}
        onPressEnter={handleOnEnter}
      />
    </TagsList>
  )
}

const NewDataset = props => {
  const { customer, refetchQuery, afterSubmit } = props

  return (
    <AuthBoundary subject="datasets" action="CREATE">
      <ApiForm
        mutation={CREATE_DATASET}
        refetchQueries={refetchQuery}
        afterSubmit={afterSubmit}
      >
        <Input name="name" displayName="Name" />
        <HiddenInput name="customerId" value={customer.id} />

        <label className="form-label">
          Columns that Uniquely Identify Rows in the Dataset
        </label>
        <UniqueKeyInput />
      </ApiForm>
    </AuthBoundary>
  )
}

export default withCustomer(NewDataset)
