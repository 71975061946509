import navItemsSchema from "./shared/navItemsSchema"

const pageContainerSchema = {
  title: "Widget",
  type: "object",
  default: {},
  metadata: {
    hideTree: true,
    hidePreviewer: true,
    validateFirst: true,
    sectionType: "tabs",
    sections: {
      main: {
        title: "Widget Container Options",
        properties: ["navItems", "pageOptions", "/_layoutSpec"],
      },
    },
  },
  properties: {
    navItems: {
      ...navItemsSchema,
      items: {
        ...navItemsSchema.items,
        metadata: {
          draggable: false,
          removable: false,
        },
      },
      default: [{ title: "Widget Container" }],
      maxItems: 1,
      metadata: {
        hideAdd: true,
        hideLabel: true,
      },
    },
    pageOptions: {
      type: "object",
      default: {},
      title: "Page Options",
      dependencies: {
        compactionMethod: {
          allOf: [
            {
              if: {
                properties: {
                  compactionMethod: {
                    enum: ["horizontal", "vertical"],
                  },
                },
              },
              then: {
                properties: {
                  preventCollision: {
                    title: "Keep Widgets Static on Move",
                    type: "boolean",
                    default: false,
                  },
                  horizontalMargin: {
                    title: "Left/Right Widget Padding",
                    type: "number",
                    default: 1,
                    minimum: 0,
                    maximum: 100,
                  },
                  verticalMargin: {
                    title: "Top/Bottom Widget Padding",
                    type: "number",
                    default: 1,
                    minimum: 0,
                    maximum: 100,
                  },
                },
              },
            },
            {
              if: {
                properties: {
                  compactionMethod: {
                    enum: ["freeform"],
                  },
                },
              },
              then: {
                properties: {
                  allowOverlap: {
                    title: "Allow Overlap",
                    type: "boolean",
                    default: true,
                  },
                },
              },
            },
          ],
        },
      },
      properties: {
        compactionMethod: {
          type: "string",
          default: "freeform",
          title: "Compaction Method",
          enum: ["freeform", "horizontal", "vertical"],
          description:
            "The method used to compact the widgets in the container. Freeform allows widgets to overlap and move freely. Horizontal and vertical compact the widgets into a single column or row respectively.",
          metadata: {
            component: "Segmented",
            labels: ["Freeform", "Horizontal", "Vertical"],
          },
        },
        verticalPadding: {
          title: "Top/Bottom Container Padding",
          type: "number",
          minimum: 0,
          maximum: 100,
          metadata: {
            component: "SliderInput",
            showNumberInput: true,
          },
        },
        horizontalPadding: {
          title: "Left/Right Container Padding",
          type: "number",
          minimum: 0,
          maximum: 100,
          metadata: {
            component: "SliderInput",
            showNumberInput: true,
          },
        },
        //TODO: ensure new options work
        backgroundColor: {
          type: ["string", "null"],
          title: "Background Color",
          description: "The background color of the chart area.",
          metadata: {
            component: "ColorPicker",
          },
        },
        "/imageSpec": {
          $ref: "#/$defs/base64ImageUpload",
        },
      },
    },

    // these are options that get loaded before the widget is rendered.
    // whenever the widget is saved or updated, these options get saved
    // to the widget's layout spec; therefore they must be in the same
    // shape of the layout spec
    // "/_layoutSpec": {
    //   title: "Layout Options",
    //   type: "object",
    //   default: {},
    //   properties: {
    //     w: {
    //       title: "Width",
    //       type: ["number", "null"],
    //       minimum: 1,
    //       maximum: 120,
    //       nullable: true,
    //       metadata: {
    //         component: "SliderInput",
    //         layout: { span: 12 },
    //       },
    //     },
    //     h: {
    //       title: "Height",
    //       type: ["number", "null"],
    //       minimum: 1,
    //       maximum: 60,
    //       nullable: true,
    //       metadata: {
    //         component: "SliderInput",
    //         layout: { span: 12 },
    //       },
    //     },
    //   },
    // },
  },
}

export default pageContainerSchema
