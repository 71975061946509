import React from "react"
import { Row, Col, Segmented } from "antd"
import { useSelector } from "react-redux"

import JSONSchemaField from "../JSONSchemaField"
import { layoutOptions } from "../../lib/layout"
import FormFieldWrapper from "../FormFieldWrapper"
import { selectAppBreakpoint } from "../../selectors/app"

const LayoutInputCore = props => {
  const { breakpoint, schema, name, path } = props
  const breakpointSchema = schema.properties[breakpoint]
  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        Current Breakpoint:{" "}
        <Segmented options={layoutOptions} value={breakpoint} disabled />
      </Col>
      <Col span={24}>
        <JSONSchemaField
          path={path}
          name={name}
          parentSchema={schema}
          schemaKey={breakpoint}
          schema={breakpointSchema}
        />
      </Col>
    </Row>
  )
}

const LayoutInput = props => {
  const breakpoint = useSelector(selectAppBreakpoint)
  return (
    <FormFieldWrapper noStyle {...props}>
      <LayoutInputCore breakpoint={breakpoint} />
    </FormFieldWrapper>
  )
}
LayoutInput.Core = LayoutInputCore
export default LayoutInput
