import React, { useMemo, useState, useCallback } from "react"
import { Tabs } from "antd"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"

import { GET_WIDGET_TYPE } from "@dbai/applet"
import { PageSpinner, AuthBoundary, ErrorMessage } from "@dbai/ui-staples"

import SaveButton from "./SaveButton"
import EditConfig from "./EditConfig"
import EditSource from "./EditSource"
import EditParameters from "./EditParameters"
import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"

const StyledTabs = styled(Tabs)`
  padding: 0 16px;
  .ant-tabs-nav {
    margin-bottom: 0;
    z-index: 1;
  }
`

export const EditWidgetType = props => {
  const { customer } = props
  const { id, cname } = useParams()
  const [widget, setWidget] = useState()
  const [showSave, setShowSave] = useState(false)

  const { error, loading } = useQuery(GET_WIDGET_TYPE, {
    variables: { id, cname },
    fetchPolicy: "network-only",
    onCompleted: data => {
      setWidget(data.customer.widgetType || {})
    },
  })

  const handleSave = useCallback(() => {
    setShowSave(false)
  }, [])

  const updateWidget = useCallback(data => {
    setWidget(data)
    setShowSave(true)
  }, [])

  const items = useMemo(() => {
    return [
      {
        key: "config",
        label: "Configuration",
        children: (
          <EditConfig cname={cname} widget={widget} setWidget={updateWidget} />
        ),
      },
      {
        key: "source",
        label: "Build Widget",
        children: (
          <EditSource cname={cname} widget={widget} setWidget={updateWidget} />
        ),
      },
      {
        key: "data",
        label: "Parameters",
        children: (
          <EditParameters
            cname={cname}
            widget={widget}
            setWidget={updateWidget}
          />
        ),
      },
    ]
  }, [cname, updateWidget, widget])

  if (loading) return <PageSpinner />
  if (error) return <ErrorMessage error={error} />
  if (!widget) return null

  const routes = [
    { breadcrumbName: customer.name },
    {
      breadcrumbName: "Widget Registry",
      url: `/${customer.normalizedName}/apps/widget-registry`,
    },
    { breadcrumbName: widget?.name },
  ]

  return (
    <AuthBoundary subject="apps" action="UPDATE">
      <PageHeader routes={routes} />
      <StyledTabs
        tabBarExtraContent={
          <SaveButton onSave={handleSave} showSave={showSave} widget={widget} />
        }
        items={items}
      />
    </AuthBoundary>
  )
}

export default withCustomer(EditWidgetType)
