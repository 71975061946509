import React, { useMemo, useCallback, useState } from "react"
import styled from "styled-components"

import Editor from "components/shared/Editor"
import { useSessionId } from "hooks"
import { useCommsChannels } from "hooks/ipythonComms"
import PythonEditor from "components/shared/PythonEditor"
import AutoComplete, {
  runAutoComplete,
  runAutoCompleteTabKey,
} from "./AutoComplete"

const cmOptions = { readOnly: "nocursor", lineNumbers: false }

const CollapsedNumberChannel = styled.div`
  margin-bottom: 30px;
  width: 30px;
  min-height: 26px;
  border-right: 1px solid ${props => props.theme.background};
`

const Collapsed = styled.div`
  flex-grow: 1;
  display: flex;
  height: 26px;
`

const StyledEditor = styled(Editor)`
  flex-grow: 1;
  &&& pre {
    text-shadow: none;
    background-color: transparent;
  }
`

const CollapsedCodeWrapper = styled.div`
  opacity: 0.3;
`

const CollapsedCode = props => {
  const { source = [] } = props
  const firstLine = useMemo(() => {
    return source[0]?.split("\n")[0]
  }, [source])

  return (
    <Collapsed>
      <CollapsedNumberChannel />
      <CollapsedCodeWrapper>
        <PythonEditor initialValue={firstLine} options={cmOptions} />
      </CollapsedCodeWrapper>
    </Collapsed>
  )
}

const VisibleCode = props => {
  const { extraKeys, cellId } = props
  const sessionId = useSessionId()
  const [autoCompletePosition, setAutoCompletePosition] = useState({})
  const [codeMirror, setCodeMirror] = useState(null)
  const { completeRequestChannel } = useCommsChannels()
  const registerCodeMirror = useCallback(
    args => setCodeMirror(args),
    [setCodeMirror]
  )
  const { top, max, left, bottom } = autoCompletePosition

  const extendedExtraKeys = useMemo(() => {
    return {
      ...extraKeys,
      "Ctrl-Space": runAutoComplete({
        cellId,
        sessionId,
        completeRequestChannel,
        setAutoCompletePosition,
      }),
      Tab: runAutoCompleteTabKey({
        cellId,
        sessionId,
        completeRequestChannel,
        setAutoCompletePosition,
      }),
    }
  }, [cellId, completeRequestChannel, extraKeys, sessionId])

  return (
    <>
      <StyledEditor
        {...props}
        extraKeys={extendedExtraKeys}
        afterInit={registerCodeMirror}
      />
      <AutoComplete
        top={top}
        max={max}
        left={left}
        bottom={bottom}
        cellId={cellId}
        sessionId={sessionId}
        codeMirror={codeMirror}
      />
    </>
  )
}

const CellCode = React.memo(props => {
  const { showCode, source, cellType} = props

  if (showCode) {
    return <VisibleCode {...props} />
  }
  if (cellType === "markdown") {
    return <></>
  }

  return <CollapsedCode source={source} />
})

export default CellCode
