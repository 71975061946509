import { createSlice } from "@reduxjs/toolkit"

import { createCommonActions } from "@dbai/tool-box"

import client from "apolloClient"
import { GET_INSTANCES } from "queries"

const initialState = {
  instances: [],
}

const instancesSlice = createSlice({
  name: "instances",
  initialState,
  reducers: {
    ...createCommonActions(initialState),
  },
})

const { actions } = instancesSlice

const loadInstances = variables => (dispatch, getState) => {
  const query = GET_INSTANCES
  return client
    .query({ query, variables, fetchPolicy: "network-only" })
    .then(({ data }) => {
      dispatch(
        actions.set({
          name: "instances",
          value: data.customer.compute,
        })
      )
    })
}

const allActions = {
  loadInstances,
  ...actions,
}

export { allActions as actions }
export default instancesSlice
