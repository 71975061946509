import React from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { useParams } from "react-router-dom"
import lodash from "lodash"
import Ansi from "ansi-to-react"
import { Button } from "antd"

import { Accordion, FAMemo, Spinner, ErrorMessage } from "@dbai/ui-staples"

import { GET_ARGO_WORKFLOW_DETAILS, GET_WORKFLOW_RUN_DETAILS } from "queries"

const { Content: AccordionContent } = Accordion

const findNodeForTemplate = template => node => node.templateName === template

const BackArrow = props => {
  const { onDeselect } = props
  return (
    <Button
      data-tooltip-id="workflow-history"
      type="primary"
      size="small"
      tabIndex="0"
      onClick={onDeselect}
    >
      <FAMemo icon={faArrowLeft} />
    </Button>
  )
}

const BackArrowSpacer = styled.div`
  margin: 5px;
`

const HeaderContent = styled.div`
  width: 25%;
`

const BodyContent = styled.div`
  min-height: 30px;
  padding: 5px;
  padding-left: 10px;
`

const nonTrimmedNames = ["install-dependencies"]

const removeNodeId = templateName => {
  if (nonTrimmedNames.includes(templateName)) return templateName
  return templateName.replace(/[a-zA-Z0-9]*-/, "")
}

const AccordionHeader = props => {
  const { content, handleClick } = props
  const trimmedName = removeNodeId(content.templateName)

  return (
    <div className="card-header">
      <button
        type="button"
        onClick={handleClick}
        className="btn btn-link btn-block text-left"
      >
        {trimmedName}
      </button>
      <HeaderContent>STATUS: {content.phase}</HeaderContent>
    </div>
  )
}

const getNodeMessage = node => {
  const messageType = typeof node.message
  if (messageType === "string") return node.message
  return "Completed"
}

const NodeResult = styled.p`
  margin-bottom: 10px;
`

const WorkflowLog = styled(Ansi)`
  display: block;
  border: none;
`

const filterLogs = node => log => {
  const name = log.nodeName.split(".")[1]
  const template = node.templateName

  return name === template || name.match(template.split("-")[0])
}

const WorkflowsDetailsAccordion = props => {
  const { tasks, nodes, logs } = props
  return (
    <Accordion>
      {tasks.map(task => {
        const { template } = task
        const node = nodes.find(findNodeForTemplate(template))
        const logsForNode = lodash
          .orderBy(logs, ["date"])
          .filter(filterLogs(node))
          .map(l => l.event)

        return (
          <AccordionContent
            key={template}
            headerContent={node}
            sectionId={template}
            Header={AccordionHeader}
          >
            <BodyContent>
              <NodeResult>Node result: {getNodeMessage(node)}</NodeResult>
              {logsForNode.map(log => (
                <WorkflowLog className="text-monospace">{log}</WorkflowLog>
              ))}
            </BodyContent>
          </AccordionContent>
        )
      })}
    </Accordion>
  )
}

const WorkflowDetails = props => {
  const { workflowRun, customerId, onDeselect } = props
  const { cname } = useParams()
  const { data, error, loading } = useQuery(
    GET_ARGO_WORKFLOW_DETAILS,
    {
      variables: { customerId, uid: workflowRun.argoWorkflow.metadata.uid },
    },
    { fetchPolicy: "cache-and-network" }
  )

  const {
    data: runData,
    error: runError,
    loading: runLoading,
  } = useQuery(
    GET_WORKFLOW_RUN_DETAILS,
    {
      variables: {
        cname,
        id: workflowRun.id,
      },
    },
    { fetchPolicy: "cache-and-network" }
  )

  if (loading || runLoading) return <Spinner />
  if (error || runError) return <ErrorMessage error={error || runError} />
  const argoWorkflow = data?.argoWorkflow
  const nodes = argoWorkflow?.status?.nodes || []
  const tasks = argoWorkflow?.spec?.dag?.tasks || []

  return (
    <>
      <WorkflowsDetailsAccordion
        nodes={nodes}
        tasks={tasks}
        logs={runData.workflowRun?.results?.logs}
      />
      <BackArrowSpacer>
        <BackArrow onDeselect={onDeselect} />
      </BackArrowSpacer>
    </>
  )
}

export default WorkflowDetails
