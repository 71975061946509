import * as Y from "yjs"
import { WebsocketProvider } from "y-websocket"

import {
  yDoc,
  setYDoc,
  yDocProvider,
  setYDocProvider,
  getYProviderParams,
} from "../lib/yjs"

const cleanupYjs = (yDoc, wsProvider) => {
  // remove user selection on disconnect
  const metadataMap = yDoc.getMap("metadata")
  const selectionsMap = metadataMap.get("selections")
  const awareness = wsProvider.awareness
  const clientId = awareness.clientID
  selectionsMap && selectionsMap.delete(`${clientId}`)
}

export const checkIfWsServerIsRunning = url => {
  const attemptConnection = (resolve, reject) => {
    const ws = new WebSocket(url)
    ws.onerror = event => {
      ws.close()
      reject(event)
    }

    ws.onopen = event => {
      ws.close()
      resolve(event)
    }
  }
  return new Promise(attemptConnection)
}

export const shutDownYjs = () => {
  if (!yDocProvider) return
  cleanupYjs(yDoc, yDocProvider)
  yDocProvider.destroy()
  setYDocProvider(null)
  setYDoc(null)
}

/*
 * Attempts to establish a connection with the Ko-op server and, if
 * successful, initializes Yjs. If the server can not be reached, log a
 * warning stating that collaboration is not enabled.
 */
const initYjs = async (url, appBuilderId, customer) => {
  return checkIfWsServerIsRunning(url)
    .then(() => {
      const params = getYProviderParams(customer)
      const newYDoc = new Y.Doc()
      newYDoc.name = appBuilderId

      const wsProvider = new WebsocketProvider(url, appBuilderId, newYDoc, {
        params,
        connect: false,
        resyncInterval: 3000,
      })

      setYDoc(newYDoc)
      setYDocProvider(wsProvider)

      return { wsProvider, yDoc }
    })
    .catch(error => console.warn("Collaboration not enabled"))
}

export default initYjs
