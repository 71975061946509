import { gql } from "@apollo/client"

export const GET_SNAPSHOT = gql`
  query GetSnapshot($customerId: Int!, $id: Int!) {
    workflowSnapshot(customerId: $customerId, id: $id) {
      description
      createdAt
      diff
    }
  }
`

export const GET_SNAPSHOT_WITH_SPEC = gql`
  query GetSnapshot($customerId: Int!, $id: Int!) {
    workflowSnapshot(customerId: $customerId, id: $id) {
      description
      createdAt
      diff
      spec {
        nodes {
          __typename
          id
          type
          collapsed
          position {
            x
            y
          }
          data {
            label
            color
          }
          ... on WorkflowScriptNode {
            arguments {
              name
              type
            }
            cells {
              uuid
              source
              executionCount
              metadata
              cellType
              outputs
            }
            artifacts {
              id
              title
              path
              type
            }
          }
          ... on WorkflowComponentNode {
            arguments {
              name
              type
            }
            cells {
              uuid
              source
              executionCount
              metadata
              cellType
              outputs
            }
            artifacts {
              id
              title
              path
              type
            }
          }
          ... on WorkflowSourceNode {
            datasetId
            artifacts {
              id
              title
            }
          }
          ... on WorkflowDownloadNode {
            files {
              uploadId
              filename
              artifactId
            }
            artifacts {
              id
              title
              path
            }
          }
          ... on WorkflowSinkNode {
            datasetId
          }
        }
        edges {
          type
          source
          target
          parameters {
            artifactId
            argument
            filename
          }
        }
      }
    }
  }
`

export const TAKE_SNAPSHOT = gql`
  mutation TakeSnapshot(
    $customerId: Int!
    $workflowId: Int!
    $description: String
  ) {
    snapshotWorkflow(
      customerId: $customerId
      workflowId: $workflowId
      description: $description
    ) {
      id
    }
  }
`

export const DELETE_SNAPSHOT = gql`
  mutation DeleteSnapshot($customerId: Int!, $id: Int!) {
    deleteSnapshot(customerId: $customerId, id: $id) {
      id
    }
  }
`
