import React, { useRef } from "react"
import styled from "styled-components"
import { useDrag, useDrop } from "react-dnd"

const type = "dnd-table-row"

const StyedTableRow = styled.tr`
  ${({ isOver, dropDirection, theme }) => {
    if (isOver && dropDirection === "downward") {
      return `&&& {
        td {
        border-bottom: 2px dashed ${theme.danger};
      }
    }`
    }

    if (isOver && dropDirection === "upward") {
      return `&&& {
        td {
        border-top: 2px dashed ${theme.danger};
      }
    }`
    }
    return ""
  }}
`

const DraggableTableRow = ({ style, index, moveRow, ...restProps }) => {
  const ref = useRef(null)
  const [{ isOver, dropDirection }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }

      return {
        isOver: monitor.isOver(),
        dropDirection: dragIndex < index ? " downward" : "upward",
      }
    },
    drop: item => {
      moveRow(item.index, index)
    },
  })
  const [, drag] = useDrag({
    item: {
      type,
      index,
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  drop(drag(ref))
  return (
    <StyedTableRow
      ref={ref}
      isOver={isOver}
      dropDirection={dropDirection}
      style={{
        cursor: "move",
        ...style,
      }}
      {...restProps}
    />
  )
}

export default DraggableTableRow
