import React, { useMemo } from "react"
import { Tooltip, Layout } from "antd"
import styled, { css } from "styled-components"
import { NavLink, Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCaretDown,
  faUser,
  faSignInAlt,
  faQuestionCircle,
  faSun,
  faMoon,
} from "@fortawesome/free-solid-svg-icons"

import {
  Dropout,
  useDropout,
  Authenticated,
  useCurrentUser,
} from "@dbai/ui-staples"

import config from "config/config"
import Signout from "components/shared/Signout"
import DBBoundary from "components/shared/DBBoundary"
import Search from "components/shared/Header/Search"

const StyledHeader = styled(Layout.Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  && {
    background: ${props => props.theme.header};
    padding: 0 1rem;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .__react_component_tooltip {
    line-height: 24px;
  }
`

const NavItem = styled.div`
  display: inline-block;
  margin-right: 30px;
  padding: 0;

  &:last-of-type {
    margin-right: 0;
  }

  .caret-icon {
    margin-left: 5px;
  }
  svg {
    &.active {
      color: #f1c40f;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &.moon-button {
    margin-right: 10px;
  }
`

const StyledNavLink = styled(NavLink)`
  color: #fff;
  font-weight: bold;
  &:hover {
    text-decoration: none;
    color: #d1b800;
  }

  svg {
    margin-right: 3px;
  }
`

const linkStyles = css`
  display: flex;
  align-items: center;

  svg {
    margin-right: 3px;
  }
`

const StyledSignout = styled(Signout)`
  ${linkStyles}
`

const StyledLink = styled(Link)`
  ${linkStyles}
`

const StyledDroput = styled(Dropout)`
  width: 150;
`

const CurrentUser = styled(Dropout.Item)`
  font-style: italic;
  text-align: center;
  background-color: #f5f7fb;
  line-height: 0.75rem;
  padding: 0.75rem;
`

const themeButtonOptions = {
  dark: { tip: "Dark Theme", icon: faMoon },
  light: { tip: "Light Theme", icon: faSun },
}

const ThemeButton = ({ current, setThemeMode, name }) => {
  const options = useMemo(() => themeButtonOptions[name], [name])
  const className = current === name ? "active" : ""

  return (
    <Tooltip title={options.tip}>
      <FontAwesomeIcon
        className={className}
        icon={options.icon}
        size="lg"
        color="#fff"
        onClick={() => setThemeMode(name)}
      />
    </Tooltip>
  )
}

const User = () => {
  const user = useCurrentUser()
  return <strong>{user[0].name}</strong>
}

const Header = props => {
  const { themeMode, setThemeMode } = props

  const dropout = useDropout({ direction: "down left", offsetX: "7px" })

  return (
    <StyledHeader>
      <img src="/images/logo.svg" className="logo" alt="logo" />
      <Authenticated>
        <Search />
        <div>
          <DBBoundary>
            <NavItem className="moon-button">
              <ThemeButton
                name="dark"
                current={themeMode}
                setThemeMode={setThemeMode}
              />
            </NavItem>
            <NavItem className="sun-button">
              <ThemeButton
                name="light"
                current={themeMode}
                setThemeMode={setThemeMode}
              />
            </NavItem>
          </DBBoundary>

          <NavItem>
            <a href={config.docs} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faQuestionCircle} size="lg" color="#fff" />
            </a>
          </NavItem>

          <NavItem ref={dropout.trigger} onClick={dropout.toggle} type="button">
            <FontAwesomeIcon icon={faUser} size="lg" color="#fff" />
            <FontAwesomeIcon
              icon={faCaretDown}
              size="lg"
              color="#fff"
              className="caret-icon"
            />

            <StyledDroput {...dropout.props} onClick={dropout.toggle}>
              <CurrentUser>
                Signed in as <User />
              </CurrentUser>
              <hr />
              <Dropout.Item>
                <StyledLink to="/profile">
                  <FontAwesomeIcon icon={faUser} /> <span>Profile</span>
                </StyledLink>
              </Dropout.Item>
              <Dropout.Item>
                <StyledSignout />
              </Dropout.Item>
            </StyledDroput>
          </NavItem>
        </div>
      </Authenticated>

      <Authenticated noAuth>
        <NavItem>
          <StyledNavLink to="/login">
            <FontAwesomeIcon icon={faSignInAlt} /> Sign In
          </StyledNavLink>
        </NavItem>
      </Authenticated>
    </StyledHeader>
  )
}

export default Header
