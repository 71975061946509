const boundInt = num => Math.max(0, Math.min(num, 255))

/*
 * Credit where it's do: https://stackoverflow.com/a/57401891
 * alterColor will shift a basic, six-digit hex color code lighter or darker
 * based on the value of `shift`.
 */
const alterColor = (color, shift) => {
  if (!color) return color
  const num = color.replace(/^#/, "")
  const altered = num.replace(/../g, color =>
    ("0" + boundInt(parseInt(color, 16) + shift).toString(16)).substr(-2)
  )

  return `#${altered}`
}

export default alterColor
