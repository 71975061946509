import React, { useId, useMemo, useState, useEffect, useCallback } from "react"
import { get } from "lodash"
import styled from "styled-components"
import { Row, Input, Col, Select } from "antd"
import { useDispatch, useSelector } from "react-redux"

import { useWidgetContext } from "../hooks"
import JSONSchemaForm from "../JSONSchemaForm"
import { actions } from "../reducers/appReducer"
import { selectEditingAppEndpoint, selectAppEndpoint } from "../selectors/app"

const StyledRow = styled(Row)`
  padding: 0 16px;
  width: 100%;
`

const excludeEndpoints = [
  "/healthcheck",
  "/reload_components",
  "/demo/recommend",
  "/datasets/read_table/{table_identifier}",
  "/datalake/listdir",
]

const INPUT_SCHEMA_REF_PATH = `requestBody.content["application/json"].schema.$ref`
const SUCESSFUL_OUTPUT_SCHEMA_REF_PATH = `responses.200.content["application/json"].schema.$ref`
// const UNSUCESSFUL_OUTPUT_SCHEMA_REF_PATH = `responses.422.content["application/json"].schema.$ref`

const modifyEndpointSchema = schema => {
  if (!schema) return schema
  const { properties, type } = schema
  if (type !== "object") return schema
  if (type === "object" && !properties) {
    return {
      ...schema,
      metadata: {
        ...(schema.metadata || {}),
        hideLabel: false,
      },
    }
  }
  return {
    ...schema,
    properties: Object.entries(properties).reduce((acc, [key, value]) => {
      if (value.type === "object") {
        const subschema = modifyEndpointSchema(value)
        return {
          ...acc,
          [key]: {
            ...subschema,
            metadata: {
              ...(subschema.metadata || {}),
              hideLabel: false,
            },
          },
        }
      }

      return {
        ...acc,
        [key]: {
          ...value,
          anyOf: [
            {
              nullable: true,
              type: ["string", "null"],
              title: "App Variable",
              metadata: {
                component: "AppVariableSelect",
              },
            },
            ...(value?.anyOf || []),
          ],
        },
      }
    }, {}),
  }
}

const getEndpointSchema = (endpoints, endpointId, endpointSchemas, path) => {
  const schemaPath = get(endpoints, `[${endpointId}].post.${path}`, "")
    ?.replace("#/components/", "")
    .split("/")
  return get(endpointSchemas, schemaPath, {})
}

const getEndpointOptions = endpoints => {
  if (!endpoints) return []
  return Object.entries(endpoints).reduce((acc, [key, entry]) => {
    if (excludeEndpoints.includes(key)) return acc
    const {
      post,
      // get
    } = entry
    if (post) {
      acc.push({ label: `${post.summary}`, value: key })
    }
    // if (get) {
    //   acc.push({ label: `${post.summary}`, value: key })
    // }
    return acc
  }, [])
}

const useEndpoints = () => {
  const { cname, appConfig } = useWidgetContext()
  const [spec, setSpec] = useState({})
  const swaggerURL = `${appConfig.api}endpoints/${cname}/openapi.json`
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("user/jwt")}`,
    }
    fetch(swaggerURL, {
      headers,
    })
      .then(res => {
        return res.json()
      })
      .then(res => {
        setSpec(res)
        setError(false)
        setLoading(false)
      })
      .catch(err => {
        console.error(err)
        setSpec({})
        setError(true)
        setLoading(false)
      })
  }, [swaggerURL, setSpec])

  return { ...spec, loading, error }
}

const EndpointForm = props => {
  const { id, spec, name, paths, title, schemaName, endpointSchemas } = props
  const dispatch = useDispatch()
  const formId = useId()
  const initialState = spec?.[name]
  const endpointId = spec?.endpointId

  const schema = useMemo(() => {
    const endpointSchema = getEndpointSchema(
      paths,
      endpointId,
      endpointSchemas,
      schemaName
    )
    const modifiedInputSchema = modifyEndpointSchema(endpointSchema)
    return {
      ...modifiedInputSchema,
      title,
      metadata: {
        hideLabel: false,
      },
    }
  }, [paths, title, schemaName, endpointId, endpointSchemas])

  const updateEndpointConfig = useCallback(
    value => {
      dispatch(actions.updateAppEndpointWithSync({ id, [name]: value }))
    },
    [dispatch, name, id]
  )

  if (!endpointId || !endpointSchemas) return null
  return (
    <JSONSchemaForm
      hideSave
      schema={schema}
      formId={formId}
      initialState={initialState}
      onFormChange={updateEndpointConfig}
    />
  )
}

const AppEndpointForm = props => {
  const dispatch = useDispatch()
  const id = useSelector(selectEditingAppEndpoint)
  const { paths, components, error, loading } = useEndpoints()
  const spec = useSelector(state => selectAppEndpoint(state, { id }))
  const endpointOptions = useMemo(() => getEndpointOptions(paths), [paths])

  const handleNameChange = useCallback(
    e => {
      dispatch(actions.updateAppEndpointWithSync({ id, name: e.target.value }))
    },
    [dispatch, id]
  )

  const selectEndpoint = useCallback(
    endpointId => {
      dispatch(actions.updateAppEndpointWithSync({ id, endpointId }))
    },
    [dispatch, id]
  )

  if (loading) return null
  if (error) return null
  return (
    <StyledRow gutter={[0, 16]}>
      <Col span={24}>
        <Select
          title="Endpoint"
          loading={loading}
          value={spec?.endpointId}
          style={{ width: "100%" }}
          options={endpointOptions}
          onChange={selectEndpoint}
          placeholder="Select an Endpoint"
        />
      </Col>
      <Col span={24}>
        <Input
          title="Name"
          loading={loading}
          value={spec?.name}
          style={{ width: "100%" }}
          onChange={handleNameChange}
        />
      </Col>
      <Col span={24}>
        <EndpointForm
          id={id}
          spec={spec}
          paths={paths}
          name="input"
          title="Input"
          endpointSchemas={components}
          schemaName={INPUT_SCHEMA_REF_PATH}
        />
      </Col>
      <Col span={24}>
        <EndpointForm
          id={id}
          spec={spec}
          paths={paths}
          name="output"
          title="Output"
          endpointSchemas={components}
          schemaName={SUCESSFUL_OUTPUT_SCHEMA_REF_PATH}
        />
      </Col>
    </StyledRow>
  )
}

export default AppEndpointForm
