import React, { useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import { useDrop } from "react-dnd"
import { Space, Button } from "antd"
import styled from "styled-components"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import FormList from "../../FormList"
import FormFieldWrapper from "../../FormFieldWrapper"

export const InputZone = styled.div`
  transition: all 0.3s ease-in-out;
  border-width: 2px 2px 2px 2px;
  border-color: ${props => {
    return props.theme?.colorBorder || "#ddd"
  }};
  border-style: dashed;
  background-color: transparent;
  outline: none;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  .label {
    background-color: none;
    border-radius: 4px;
  }
  padding-bottom: 5px;
  margin-bottom: 10px;
`

export const Header = styled.div`
  color: ${props => props.theme?.text};
  font-weight: bold;
  padding: 5px;
  z-index: 1;
  justify-content: space-between;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  background: ${props => props.theme?.dropzoneHeader};
  margin-bottom: 15px;
`

const DropzoneHeader = styled.span`
  padding-left: 7px;
`

export const Footer = styled.div`
  color: #495057e3;
  padding-top: 2px;
`

export const ColumnsList = styled(Space)`
  overflow-y: auto;
  overflow-x: auto;
  /*
    inner content color = #000 = first linear-gradient
    padding color = red = second linear-gradient
  */
  padding: 0px 10px 10px;
  background-image: linear-gradient(transparent, transparent),
    linear-gradient(
      to right,
      ${props => (props.gradient ? props.gradient[0] : "transparent")} 10px,
      transparent 10px calc(100% - 10px),
      ${props => (props.gradient ? props.gradient[1] : "transparent")} 10px
    );
  background-clip: content-box, padding-box;
`

const getAllAllowedTypes = allowedTypes => {
  if (!allowedTypes) return []
  if (allowedTypes.includes("float") || allowedTypes.includes("numerical")) {
    return [...allowedTypes, "computed"]
  }
  return allowedTypes
}

const checkCanDrop = (schema, fields) => {
  const { metadata } = schema || {}
  const allowedTypes = getAllAllowedTypes(metadata?.allowedTypes)
  if (!allowedTypes.length) return true
  return fields.every(field => allowedTypes?.includes(field.content.type))
}

export const getCurrentGradient = (isOver, canDrop, hasError) => {
  const leftSide = () => {
    switch (true) {
      case isOver && !canDrop:
        return "rgba(255,0,0,0.5)"
      case !isOver && canDrop:
        return "rgba(255,255,0,0.5)"
      case isOver && canDrop:
        return "rgba(0,128,0,0.5)"
      default:
        return "transparent"
    }
  }

  const rightSide = () => {
    return hasError ? "rgb(252, 149, 149)" : "transparent"
  }

  return [leftSide(), rightSide()]
}

const checkLimit = (maxItems, columns, newColumnLength) => {
  if (!maxItems) return true
  return (columns || []).length + newColumnLength <= maxItems
}

const DnDDropzoneCore = props => {
  const {
    name,
    path,
    status,
    onAppend,
    onRemove,
    onChange,
    value = [],
    style = {},
    schema = {},
    ...rest
  } = props

  const { metadata = {} } = schema
  const {
    defaultDroppedFieldValue = {},
    defaultAddedFieldData = {},
    addText = "Add Column",
    fieldProps = {},
    includeAddButton = true,
  } = metadata

  const handleAdd = useCallback(
    fields => {
      const columns = fields.map(field => field.content)
      onAppend(columns)
    },
    [onAppend]
  )

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "field",
    canDrop: ({ fields }) => {
      const withinLimit = checkLimit(schema.maxItems, value, fields.length)
      return withinLimit && checkCanDrop(schema, fields)
    },
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }
      const droppedFields = item.fields.map(field => ({
        content: { ...field.content, ...defaultDroppedFieldValue },
      }))
      handleAdd(droppedFields)
    },
    collect: monitor => ({
      isOver: !!monitor.isOver({ shallow: true }),
      canDrop: !!monitor.canDrop(),
    }),
  })

  const handleClick = useCallback(
    evt => {
      evt.stopPropagation()
      evt.preventDefault()
      handleAdd([{ content: defaultAddedFieldData }])
    },
    [handleAdd, defaultAddedFieldData]
  )

  const gradient = useMemo(() => {
    return getCurrentGradient(isOver, canDrop, status === "error")
  }, [status, isOver, canDrop])

  return (
    <InputZone ref={drop} key={schema.title} {...fieldProps}>
      <Header span={24}>
        <DropzoneHeader>{schema.title}</DropzoneHeader>
        {includeAddButton ? (
          <Button
            type="text"
            size="small"
            onClick={handleClick}
            icon={<FontAwesomeIcon icon={faPlus} />}
            // disabled={!Boolean(datasetId) || !checkLimit(limit, columns, 1)}
          >
            {addText}
          </Button>
        ) : null}
      </Header>
      <ColumnsList direction="vertical" gradient={gradient} style={style}>
        <FormList
          path={path}
          name={name}
          value={value}
          schema={schema}
          onChange={onChange}
          onRemove={onRemove}
          {...rest}
        />
      </ColumnsList>
      <Footer span={24} />
    </InputZone>
  )
}

const DnDDropzone = props => {
  return (
    <FormFieldWrapper hideLabel {...props}>
      <DnDDropzoneCore {...props} />
    </FormFieldWrapper>
  )
}

DnDDropzone.propTypes = {
  name: PropTypes.string,
  path: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
}

DnDDropzone.Core = DnDDropzoneCore
export default DnDDropzone
