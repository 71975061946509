import styled from "styled-components"

const NullishWidget = styled.div`
  backgroundcolor: "rgba(55, 62, 71, 0.3)";
  color: "black";
  fontsize: 16;
  width: "100%";
  height: "100%";
`

export default NullishWidget
