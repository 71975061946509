import React, { useMemo } from "react"
import { useQuery } from "@apollo/client"

import {
  SidePane,
  PageSpinner,
  ErrorMessage,
  AuthBoundary,
  TableWithFolders,
  ActionsFormatter,
  useCurrentCustomer,
  useCheckForDBAdmin,
} from "@dbai/ui-staples"

import NewAction from "./NewAction"
import PublishCheckbox from "./PublishCheckbox"
import { GET_ACTIONS, DELETE_ACTION } from "queries"
import { useTagColumn } from "hooks"

const TableRowActions = props => {
  const [customer] = useCurrentCustomer()
  const { row } = props

  return (
    <ActionsFormatter
      value={row.id}
      confirmDelete={true}
      variables={{ customerId: customer.id }}
      deleteGql={DELETE_ACTION}
      refetchQuery={GET_ACTIONS}
      refetchVariables={{ cname: customer.normalizedName }}
      editPath={`/${customer.normalizedName}/workflows/library/`}
    />
  )
}

const publishedColumn = {
  title: "Published",
  dataIndex: "published",
  key: "published",
  width: 100,
  render: props => <PublishCheckbox {...props} />,
}

const nonDBColumnOrder = ["name", "tags", "createdAt", "updatedAt", "actions"]

// Only difference here is that this includes the "published" row.
const dbColumnOrder = [
  "name",
  "tags",
  "createdAt",
  "updatedAt",
  "published",
  "actions",
]

const tagColumnOptions = {
  type: "actions",
  query: GET_ACTIONS,
}

const ActionsTable = props => {
  const isDBAdmin = useCheckForDBAdmin()
  const { customer, showNew, onNewClose } = props
  const { normalizedName: cname, id: customerId } = customer
  const variables = { cname }
  const tags = useTagColumn(tagColumnOptions)
  const columnOrder = useMemo(
    () => (isDBAdmin ? dbColumnOrder : nonDBColumnOrder),
    [isDBAdmin]
  )
  const allColumns = useMemo(() => {
    return [tags, publishedColumn]
  }, [tags])
  const {
    error,
    loading,
    data,
    refetch: refetchActions,
  } = useQuery(GET_ACTIONS, {
    fetchPolicy: "network-only",
    variables,
  })

  if (loading) return <PageSpinner />
  if (error) return <ErrorMessage error={error} />

  return (
    <AuthBoundary subject="actions" action="LIST">
      <div id="actions">
        <TableWithFolders
          columns={allColumns}
          folderType="actions"
          columnOrder={columnOrder}
          Actions={TableRowActions}
          dataSource={data.customer.actions || []}
          rowLink={row => `/${cname}/workflows/library/${row.id}`}
        />

        <SidePane
          title="Create Action"
          isVisible={showNew}
          onCloseClicked={onNewClose}
          width="50vw"
          mask
        >
          <NewAction
            customerId={customerId}
            afterSubmit={() => {
              onNewClose(false)
              refetchActions()
            }}
          />
        </SidePane>
      </div>
    </AuthBoundary>
  )
}

export default ActionsTable
