import React from "react"
import CustomerSelect from "components/shared/CustomerSelect"

const NoCustomer = props => {
  return (
    <div id="projects">
      <div>
        <b>Please select a client</b>
        <CustomerSelect />
      </div>
    </div>
  )
}

export default NoCustomer
