import React from "react"
import styled from "styled-components"

import FAMemo from "./FAMemo"

const StyledMenuText = styled.span`
  padding-left: 10px;
`

const MenuTextWithIcon = props => {
  const { onClick, icon, children } = props
  return (
    <span onClick={onClick}>
      <FAMemo icon={icon} />
      <StyledMenuText>{children}</StyledMenuText>
    </span>
  )
}

export default MenuTextWithIcon
