import { useMemo } from "react"

import { useDatasetData } from "../../hooks"
import { useWidgetQuery, useFormData } from "./index"
import { getUsableColumnLabels } from "../../lib/datasetColumns"

const getColumn = (metadata, parentValue) => {
  const { column, columnName = "column" } = metadata
  return column ?? parentValue[columnName]
}

const getPresetColumn = metadata => {
  const { presetColumnName, column } = metadata
  if (presetColumnName) return metadata[presetColumnName]
  return column
}

const getPresetDatasetId = metadata => {
  const { presetDatasetIdName, datasetId } = metadata
  if (presetDatasetIdName) return metadata[presetDatasetIdName]
  return datasetId
}

const usePresetData = ({
  schema,
  parentSchema,
  parentName,
  metadata,
  fetchPolicy,
}) => {
  const parentValue = useFormData(parentName)
  const { excludeWidgetFilters = false, excludeGlobalFilters = false } =
    metadata

  const presetMetadata = {
    ...schema.metadata,
    column: getPresetColumn(metadata),
    datasetId: getPresetDatasetId(metadata),
  }

  const { datasetId, column: _foundColumn } = useWidgetQuery(
    { ...schema, metadata: presetMetadata },
    parentSchema,
    parentName
  )

  const dsColumn = getColumn(presetMetadata, parentValue)
  const column = dsColumn ?? _foundColumn

  const query = useMemo(() => {
    return {
      skip: !datasetId || !column,
      datasetId,
      select: [{ column }],
      orderBy: [`${column} ASC`],
      groupBy: [column],
      limit: 100,
    }
  }, [datasetId, column])

  const { loading, refetch, fetchMore, error, dataset } = useDatasetData({
    query,
    fetchPolicy,
    excludeWidgetFilters,
    excludeGlobalFilters,
  })

  return { loading, refetch, fetchMore, error, dataset, column }
}

const usePresetOptions = fetchOptions => {
  const { loading, fetchMore, refetch, error, dataset, column } =
    usePresetData(fetchOptions)
  const options = useMemo(() => {
    if (!dataset?.data?.length) return []
    const { rows, columns } = dataset.data[0]
    const rowValues = rows.map(r => r[0])
    return getUsableColumnLabels(rowValues, columns[0])
  }, [dataset])

  return { options, loading, fetchMore, refetch, error, column }
}

export default usePresetOptions
