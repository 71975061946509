import React, { useCallback } from "react"
import dayjs from "dayjs"
import { get } from "lodash"
import PropTypes from "prop-types"
import styled from "styled-components"
import DatePicker from "react-datepicker"
import { useFormActions, useFormState } from "./Form/hooks"

const StyledDatePicker = styled.div`
  min-height: 100%;

  .date-picker {
    width: min-content;
    max-width: 125px;
    border: none;
    min-height: 100%;
    height: unset;
    font-size: inherit;
    padding: 0 2px;
    font-weight: inherit;
    :hover {
      border-color: hsl(0, 0%, 70%);
      background-color: rgba(0, 0, 0, 0.05);
    }
  }
  .react-datepicker__close-icon {
    display: none;
  }

  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      .react-datepicker {
        .react-datepicker__triangle {
          left: 275px;
        }
      }
    }
  }
`

/**
 * TODO: The following conversion functions are hacks to normalize dates to UTC for the Datetime Picker.
 * This is an ongoing issue with the react-datepicker library, but there seems to be an update coming soon to fix this.
 * Reference: https://github.com/Hacker0x01/react-datepicker/issues/1787
 *
 * All data stored in datasets is assumed to be in UTC format. react-datepicker automatically displays any
 * given date in local time and currently offers no method to offset the datetimes. As a workaround, we offset
 * the datetimes received through our API (min/max and selected date) by the utc offsets of the user's default
 * time zone and the time zone the app is working in. Upon persisting a datetime to the API, we convert the
 * datetime to the time zone the app is working in.
 */
const convertUTCToLocalDate = date => {
  if (!date) return date

  const newDate = new Date(date)
  return new Date(
    newDate.getUTCFullYear(),
    newDate.getUTCMonth(),
    newDate.getUTCDate(),
    newDate.getUTCHours(),
    newDate.getUTCMinutes(),
    newDate.getUTCSeconds()
  )
}

const convertLocalToUTCDate = date => {
  if (!date) return date

  const newDate = new Date(date)
  return new Date(
    Date.UTC(
      newDate.getFullYear(),
      newDate.getMonth(),
      newDate.getDate(),
      newDate.getHours(),
      newDate.getMinutes(),
      newDate.getSeconds()
    )
  )
}

const DateRangePicker = props => {
  const state = useFormState()
  const { formSet } = useFormActions()

  const { action = formSet, name, reset, onChange } = props

  const selected = dayjs(get(state, name)).valueOf()

  const handleChange = useCallback(
    date => {
      const updateForm = date => {
        if (!dayjs(date).isValid()) return
        action({
          name,
          value: dayjs(date).format(),
        })
      }

      onChange && onChange(date)
      !reset && updateForm(date)
    },
    [onChange, reset, action, name]
  )

  return (
    <StyledDatePicker>
      <DatePicker
        onChange={date => handleChange(convertLocalToUTCDate(date))}
        openToDate={convertUTCToLocalDate(selected)}
        selected={convertUTCToLocalDate(selected)}
        className="date-picker"
        timeFormat="h:mm aa"
        timeCaption="time"
        placeholderText="Date"
        dateFormat="M/d/yy h:mm aa"
        shouldCloseOnSelect={false}
        timeIntervals={1}
        isClearable={convertUTCToLocalDate(selected)}
        showTimeSelect
        popperModifiers={{
          offset: {
            enabled: true,
            offset: "-228, -5px",
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: "viewport",
          },
        }}
      />
    </StyledDatePicker>
  )
}

DateRangePicker.propTypes = {
  name: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
}

export default DateRangePicker
