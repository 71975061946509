const hexToRgb = hex => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null
}

const rgbToHex = rgb => {
  return (
    "#" +
    ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1)
  )
}

const getColorForValue = (value, thresholds) => {
  if (!thresholds?.length) return

  // Check if value is less than the smallest threshold
  if (value < thresholds[0].value) return

  // Check if value is greater than the largest threshold
  if (value > thresholds[thresholds.length - 1].value) return

  // Find the two thresholds the value falls between
  let lowerThreshold, upperThreshold
  for (let i = 0; i < thresholds.length - 1; i++) {
    if (value => thresholds[i].value && value <= thresholds[i + 1].value) {
      lowerThreshold = thresholds[i]
      upperThreshold = thresholds[i + 1]
      break
    }
  }

  // Convert the colors to RGB
  let lowerColor = hexToRgb(lowerThreshold?.color)
  let upperColor = hexToRgb(upperThreshold?.color)
  if (!lowerColor && !upperColor) return null
  if (!upperColor) return lowerThreshold.color
  if (!lowerColor) return upperThreshold.color

  // Compute the ratio of where the value falls between the thresholds
  let ratio =
    (value - lowerThreshold.value) /
    (upperThreshold.value - lowerThreshold.value)

  // Calculate the new color based on the ratio
  let resultColor = []
  for (let i = 0; i < 3; i++) {
    resultColor[i] = Math.round(
      lowerColor[i] + ratio * (upperColor[i] - lowerColor[i])
    )
  }

  // Convert the result back to hexadecimal
  return rgbToHex(resultColor)
}

const getColorThresholds = (colors, column, record) => {
  return colors.map(colorSpec => {
    if (colorSpec.valueType === "percentile") {
      return {
        ...colorSpec,
        value: record[column],
      }
    }
    return colorSpec
  })
}

const getColorScaleStyles = (colorScale, record) => {
  if (!colorScale?.length) return {}
  return colorScale.reduce((final, scale) => {
    const { column, colors } = scale
    const rowValue = record[column]
    const colorsWithValues = getColorThresholds(colors, column, record)
    const sortedColors = colorsWithValues.sort((a, b) => a.value - b.value)
    const color = getColorForValue(rowValue, sortedColors)
    return color ? { ...final, backgroundColor: color } : final
  }, {})
}

export { getColorForValue, getColorScaleStyles }
