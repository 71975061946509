import { createSlice } from "@reduxjs/toolkit"

import { alphanumid, createCommonActions } from "@dbai/tool-box"
import { GET_CUSTOMER_ROLES } from "@dbai/ui-staples"

import { storeToState } from "@dbai/tool-box"

import client from "apolloClient"
import { UPDATE_ROLE, CREATE_ROLE, DELETE_ROLE } from "queries"

const pick = (o, ...props) => props.reduce((a, e) => ({ ...a, [e]: o[e] }), {})

/*\
|*| A Role has-many Policies, which have one Rule.
|*|
|*| Role -+-> Policy 1 -> Rule
|*|       +-> Policy 2 -> Rule
|*|       +-> Policy 3 -> Rule
|*|
|*| This was originally setup so that Roles could share common Policies, but
|*| in practice this did not prove useful, and was actually too complex. The
|*| new UI is now structured so that Policies are managed through Roles, rather
|*| than as independent entities.
\*/

const initialState = {
  id: null,
  name: "",
  description: "",
  policies: [],
}

const blankPolicy = {
  id: null,
  rule: {
    effect: "ALLOW",
    actions: [],
    subjects: [],
  },
}

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    load: (draft, { payload }) => {
      const sanitized = pick(
        storeToState(payload),
        ...Object.keys(initialState)
      )
      return {
        ...draft,
        ...sanitized,
      }
    },
    setPolicySubjects: (draft, { payload }) => {
      const { policyId, value } = payload
      const idx = draft.policies.findIndex(p => p.id === policyId)

      draft.policies[idx].rule.subjects = value
    },
    setPolicyActions: (draft, { payload }) => {
      const { policyId, value } = payload
      const idx = draft.policies.findIndex(p => p.id === policyId)

      draft.policies[idx].rule.actions = value
    },
    addPolicy: (draft, { payload }) => {
      return {
        ...draft,
        policies: [...draft.policies, { ...blankPolicy, id: alphanumid() }],
      }
    },
    deletePolicy: (draft, { payload }) => {
      draft.policies = draft.policies.filter(p => p.id !== payload.policyId)
    },
    ...createCommonActions(initialState),
  },
})

const { actions } = roleSlice

const policyIdCheck = policy => ({
  ...policy,
  id: typeof policy.id === "number" ? policy.id : null,
})

const createRole = payload => (dispatch, getState) => {
  const state = getState()
  const { id, ...input } = state.role
  const { id: customerId } = state.currentCustomer.customer

  const mutation = CREATE_ROLE
  const variables = {
    customerId,
    input: {
      ...input,
      policies: input.policies.map(p => policyIdCheck(p)),
    },
  }

  const refetchQueries = [GET_CUSTOMER_ROLES]
  return client.mutate({ mutation, variables, refetchQueries })
}

const updateRole = payload => (dispatch, getState) => {
  const state = getState()
  const { id, ...input } = state.role
  const { id: customerId } = state.currentCustomer.customer

  const variables = {
    customerId,
    id,
    input: {
      ...input,
      policies: input.policies.map(p => policyIdCheck(p)),
    },
  }

  const mutation = UPDATE_ROLE

  const refetchQueries = [GET_CUSTOMER_ROLES]
  return client.mutate({
    mutation,
    variables,
    refetchQueries,
  })
}

const deleteRole = payload => (dispatch, getState) => {
  const state = getState()
  const { id } = payload
  const { id: customerId } = state.currentCustomer.customer

  const variables = {
    customerId,
    id,
  }

  const mutation = DELETE_ROLE

  const refetchQueries = [GET_CUSTOMER_ROLES]
  return client.mutate({
    mutation,
    variables,
    refetchQueries,
  })
}

const allActions = {
  ...actions,
  createRole,
  updateRole,
  deleteRole,
}

export { allActions as actions }
export const { name, reducer, caseReducers } = roleSlice
export default roleSlice
