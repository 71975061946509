import React, { useRef } from "react"
import { useDispatch } from "react-redux"

import { DBDropzone } from "@dbai/ui-staples"

import { withFormFieldWrapper } from "../FormFieldWrapper"

const UploadImageInputCore = props => {
  const passProps = useRef({})
  const dispatch = useDispatch()
  const {
    onChange,
    InputContent,
    dropzoneStyle,
    schema: { metadata },
    ...rest
  } = props

  const getUploadParams = async ({ file, meta }) => {
    const callback = vars => (passProps.current = vars)
    const value = await dispatch(
      metadata.getUploadParams({ callback, file, meta })
    )
    return value
  }

  const onUploadSuccess = async (_, status) => {
    if (status === "done") {
      const value = await dispatch(metadata.onUploadSuccess(passProps.current))
      onChange && onChange(await value)
    }
  }

  return (
    <DBDropzone
      styles={dropzoneStyle}
      inputContent={InputContent}
      accept="image/png,image/jpeg"
      onChangeStatus={onUploadSuccess}
      getUploadParams={getUploadParams}
      {...rest}
    />
  )
}

const UploadImageInput = withFormFieldWrapper(UploadImageInputCore)

UploadImageInput.Core = UploadImageInputCore
export default UploadImageInput
