import React from "react"
import { Col } from "antd"
import { useQuery } from "@apollo/client"

import {
  Card,
  Input,
  Content,
  ApiForm,
  UPDATE_USER,
  PageSpinner,
  ErrorMessage,
  GET_MY_PROFILE_DATA,
} from "@dbai/ui-staples"

import EditPreferences from "./EditPreferences"

const EditProfile = props => {
  const { error, loading, data } = useQuery(GET_MY_PROFILE_DATA)

  if (error) return <ErrorMessage error={error} />
  if (loading) return <PageSpinner />

  return (
    <Content>
      <Col span={12}>
        <Card title="MY PROFILE">
          <ApiForm
            initialState={data.me}
            mutation={UPDATE_USER}
            saveMessage="Profile Updated!"
          >
            <Input name="name" />
            <Input name="email" />
          </ApiForm>
        </Card>
      </Col>

      <Col span={12}>
        <EditPreferences />
      </Col>
    </Content>
  )
}

export default EditProfile
