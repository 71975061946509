import React, { useRef, useMemo, useEffect, useId, useCallback } from "react"
import styled from "styled-components"
import { toast } from "react-toastify"
import { isEqual, isArray } from "lodash"
import { useMutation } from "@apollo/client"
import { useSelector, useDispatch } from "react-redux"

import buildSchema from "../../lib/buildSchema"
import { useWidgetContext } from "../../hooks"
import JSONSchemaForm from "../../JSONSchemaForm"
import { actions } from "../../reducers/appReducer"
import { selectAppSpec } from "../../selectors/app"
import { COLLECT_DATA } from "../../queries/collection"
import { actions as formActions } from "../../reducers/formsReducer"

const FormWidgetContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  background-color: ${({ backgroundColor }) => backgroundColor};
`

const getRowInput = (formFields, data) => {
  return {
    columns: Object.entries(data).reduce((acc, [key, value]) => {
      const field = formFields.find(formField => formField.key === key)
      if (!field) return acc
      return [
        ...acc,
        {
          name: field.column,
          type: field.columnType,
          value: isArray(value) ? value[0] : value,
        },
      ]
    }, []),
  }
}

const getFormFieldsByDataset = formFields => {
  return formFields.reduce((acc, formField) => {
    const { metadata, key } = formField
    const { datasetId, column, columnType } = metadata || {}
    if (!datasetId) return acc
    if (!acc[datasetId]) {
      acc[datasetId] = [{ column, key, columnType }]
      return acc
    }
    acc[datasetId].push({ column, key, columnType })
    return acc
  }, {})
}

const saveJSON = (data, filename, type) => {
  var file = new Blob([data], { type: type })
  var a = document.createElement("a"),
    url = URL.createObjectURL(file)
  a.href = url
  a.download = filename
  document.body.appendChild(a)
  a.click()
  setTimeout(function () {
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }, 0)
}

const collectFormData = (
  collect,
  data,
  widget,
  customerId,
  setRefetchQueries
) => {
  if (!data || !Object.values(data).length) return
  const {
    properties = [],
    showSubmitFeedback = true,
    confirmationMessage,
  } = widget || {}
  const formFieldsByDataset = getFormFieldsByDataset(properties)

  Promise.all(
    Object.entries(formFieldsByDataset).map(([datasetId, formFields]) => {
      const row = getRowInput(formFields, data)
      if (!datasetId || !row.columns.length) return Promise.resolve()
      return collect({
        variables: {
          customerId,
          id: datasetId,
          input: {
            rows: [row],
          },
        },
      })
    })
  ).then(() => {
    showSubmitFeedback && toast.success(confirmationMessage)
    setRefetchQueries(true)
  })
}

const saveAppVariables = (data, properties, dispatch) => {
  const appVariableFields = properties.filter(property => {
    return Boolean(property.metadata?.appVariable)
  })
  if (appVariableFields?.length) {
    const appVariables = appVariableFields.reduce((acc, property) => {
      const { metadata, key } = property
      const id = metadata.appVariable
      const value = data[key]

      // if the app variable is already set, we don't want to override it with an empty value
      return [...acc, { id, value }]
    }, [])

    dispatch(actions.setAppVariablesWithSync(appVariables))
  }
}

const Form = React.forwardRef((props, ref) => {
  const {
    onSubmit,
    noWrapper,
    widget = {},
    onFormChange,
    onStatusChange,
    initialState = {},
  } = props
  const formId = useId()
  const dispatch = useDispatch()
  const [collectData] = useMutation(COLLECT_DATA)
  const { variables } = useSelector(selectAppSpec)
  const { customerId, appConfig, setRefetchQueries } = useWidgetContext()
  const options = widget.options
  const { form = {} } = options
  const hideSave = props.hideSave || form.autoSave

  const schema = useMemo(() => {
    const { layout, properties, metadata } = options
    return buildSchema(
      {
        layout,
        metadata,
        properties,
        default: {},
        type: "object",
        title: widget.name,
      },
      variables
    )
  }, [options, widget.name, variables])
  const prevSchema = useRef(schema)

  const handleSubmit = useCallback(
    data => {
      onSubmit && onSubmit(data)
      const submitActions = form.onSubmit || []

      // find and save app variables
      if (submitActions.includes("saveAppVariables")) {
        const { properties } = options
        saveAppVariables(data, properties, dispatch)
      }

      if (submitActions.includes("collectData")) {
        collectFormData(
          collectData,
          data,
          options,
          customerId,
          setRefetchQueries
        )
      }

      if (submitActions.includes("saveJSON")) {
        saveJSON(JSON.stringify(data), "data.txt", "text/plain")
      }

      if (submitActions.includes("triggerEndpoint")) {
        const { endpoints } = form
        Promise.all(
          endpoints.map(endpointId => {
            return dispatch(
              actions.triggerEndpoint({ id: endpointId, apiUrl: appConfig.api })
            )
          })
        ).then(() => {
          setRefetchQueries(true)
        })
      }
    },
    [
      form,
      options,
      onSubmit,
      dispatch,
      appConfig,
      customerId,
      collectData,
      setRefetchQueries,
    ]
  )

  const autoSaveForm = useCallback(
    data => {
      const autoSave = form.autoSave
      if (autoSave) {
        handleSubmit(data)
      }
      onFormChange && onFormChange(data)
    },
    [handleSubmit, onFormChange, form]
  )

  // reset form when schema changes
  useEffect(() => {
    if (!isEqual(prevSchema.current, schema)) {
      dispatch(
        formActions.loadFormData({
          id: formId,
          force: true,
          value: initialState,
          schema: schema,
          initialState,
        })
      )
      prevSchema.current = schema
    }
  }, [dispatch, schema, formId, initialState])

  const handleFormChange = form.autoSave ? autoSaveForm : onFormChange
  if (noWrapper) {
    return (
      <JSONSchemaForm
        ref={ref}
        formId={formId}
        schema={schema}
        hideSave={hideSave}
        onSubmit={handleSubmit}
        initialState={initialState}
        saveButton={form.saveButton}
        resetOnSave={form.resetOnSave}
        onFormChange={handleFormChange}
        onStatusChange={onStatusChange}
        showErrorsList={form.showErrorsList}
        allowSaveWithErrors={form.allowSaveWithErrors}
      />
    )
  }

  return (
    <FormWidgetContainer backgroundColor={options.backgroundColor}>
      <JSONSchemaForm
        ref={ref}
        formId={formId}
        schema={schema}
        hideSave={hideSave}
        onSubmit={handleSubmit}
        initialState={initialState}
        saveButton={form.saveButton}
        resetOnSave={form.resetOnSave}
        onFormChange={handleFormChange}
        onStatusChange={onStatusChange}
        showErrorsList={form.showErrorsList}
        allowSaveWithErrors={form.allowSaveWithErrors}
      />
    </FormWidgetContainer>
  )
})

export default React.memo(Form)
