import React, { useCallback } from "react"
import styled from "styled-components"

import PythonEditor from "components/shared/PythonEditor"

const BorderedDiv = styled.div`
  border: solid #dddddd 1px;
  border-radius: 1px;
`

/*
 * NOTE: Currently reusable actions have only one cell, thus the
 * locked value here. I'm doing this like this to make it easier
 * to update in the future when/if we add multi-cell capabilities.
 */
const cellIdx = 0

const CodeEditor = props => {
  const { source, onChange, revisionId } = props
  const initCodeMirror = useCallback(
    codeMirror => {
      codeMirror.on("change", onChange)
      codeMirror.setSize("100%", "15rem")
    },
    [onChange]
  )

  return (
    <>
      <label className="form-label">Code</label>
      <BorderedDiv>
        <PythonEditor
          initialValue={source[cellIdx]}
          initCodeMirror={initCodeMirror}
          watch={revisionId}
        />
      </BorderedDiv>
    </>
  )
}

export default CodeEditor
