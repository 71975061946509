import React, {
  useId,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react"
import { Spin } from "antd"
import { isEqual } from "lodash"
import { useDispatch } from "react-redux"

import getFormField from "./getFormField"
import { useFormContext } from "./hooks"
import { mergeSchema } from "../schemas/utils"
import { actions } from "../reducers/formsReducer"
import SchemaFormContext from "./SchemaFormContext"

const getSafeSchema = (schema, layout) => {
  return layout ? mergeSchema(schema, { metadata: { layout } }) : schema
}

const SchemaFormProvider = React.forwardRef((props, ref) => {
  const {
    value,
    children,
    onFormChange,
    initialState,
    destroyOnUnmount,
    showLoading = true,
  } = props

  const id = useId()
  const valueRef = useRef(true)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [resetCounter, setResetCounter] = useState(0)
  const [showErrors, setShowErrors] = useState(Boolean(onFormChange))

  const formId = useMemo(() => props.formId || id, [props.formId, id])

  const safeSchema = useMemo(
    () => getSafeSchema(props.schema, props.layout),
    [props.schema, props.layout]
  )

  const afterFormChange = useCallback(
    ({ formData, errors, ...passProps }) => {
      onFormChange && onFormChange(formData, errors, passProps)
    },
    [onFormChange]
  )

  const load = useCallback(
    async ({ schema, value, initialState }, force) => {
      await dispatch(
        actions.loadFormData({
          force,
          id: formId,
          value,
          initialState,
          schema: schema || safeSchema,
          validate: Boolean(onFormChange),
        })
      ).then(() => {
        setLoading(false)
      })
    },
    [dispatch, safeSchema, onFormChange, formId]
  )

  const resetForm = useCallback(() => {
    setResetCounter(c => c + 1)
    return load({ value, initialState }, true)
  }, [initialState, value, load])

  // automatically reload the form stat when...
  // a) the schema changes;
  // b) or, the formId changes
  useEffect(() => {
    if (!isEqual(valueRef.current, value)) {
      setLoading(true)
      load({ value, initialState })
      valueRef.current = value
    }
    return () => {
      if (destroyOnUnmount) {
        dispatch(actions.removeForm({ id: formId }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load, formId, value, dispatch, destroyOnUnmount])

  useEffect(() => {
    if (ref) {
      ref.current = {
        id: formId,
        reset: resetForm,
      }
    }
  }, [resetForm, formId, ref])

  const contextValue = useMemo(() => {
    return {
      formId,
      showErrors,
      resetCounter,
      getFormField,
      setShowErrors,
      reset: resetForm,
      afterFormChange: onFormChange ? afterFormChange : null,
    }
  }, [
    formId,
    resetForm,
    showErrors,
    resetCounter,
    setShowErrors,
    afterFormChange,
    onFormChange,
  ])

  if (loading) return showLoading ? <Spin /> : null
  return (
    <SchemaFormContext.Provider value={contextValue}>
      {children}
    </SchemaFormContext.Provider>
  )
})

export { useFormContext }
export default SchemaFormProvider
