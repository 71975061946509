import React from "react"

const nodeShape = (
  <symbol
    width="240"
    height="50"
    viewBox="0 0 100 100"
    id="node"
    key="0"
    opacity="0"
  >
    <rect width="240" height="50" rx="0"></rect>
  </symbol>
)

// BuiltinTypes is a list of all possible node types, their physical shape,
// and their basic initialState.
const BuiltinTypes = {
  empty: {
    graph: {
      typeText: "None",
      shapeId: "#node",
      shape: nodeShape,
    },
    initialState: {},
  },
  download: {
    graph: {
      typeText: "None",
      shapeId: "#node",
      shape: nodeShape,
    },
    initialState: {},
  },
  sink: {
    graph: {
      typeText: "None",
      shapeId: "#node",
      shape: nodeShape,
    },
    initialState: {},
  },
  script: {
    graph: {
      typeText: "New Python Script",
      shapeId: "#node",
      shape: nodeShape,
    },
    initialState: {
      color: "#0c2963",
      artifacts: [],
      cells: [],
    },
  },
  source: {
    graph: {
      typeText: "Source",
      shapeId: "#node",
      shape: nodeShape,
    },
    initialState: {
      color: "#aa232a",
      artifacts: [{ id: "result", title: "dataset" }],
    },
  },
}

// NodeTypes is a reduced version of `BuiltinTypes` suitable for use by
// react-digraph.
const NodeTypes = Object.entries(BuiltinTypes).reduce((pre, [name, value]) => {
  return { ...pre, [name]: value.graph }
}, {})

const NodeSubtypes = {}

const EdgeTypes = {
  emptyEdge: {
    // required to show empty edges
    shapeId: "#emptyEdge",
    shape: (
      <symbol viewBox="0 0 50 50" id="emptyEdge" key="0">
        <circle cx="25" cy="25" r="8" fill="currentColor">
          {" "}
        </circle>
      </symbol>
    ),
  },
}

export { NodeTypes, BuiltinTypes, NodeSubtypes, EdgeTypes }
