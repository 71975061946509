import React, { useRef, useMemo, useState, useCallback } from "react"
import { get } from "lodash"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"

import { alterColor } from "@dbai/tool-box"
import { VisibilityGuard } from "@dbai/ui-staples"

import CellMenu from "./CellMenu"
import CellCode from "./CellCode"
import CellResults from "./CellResults"
import DelayedRender from "./DelayedRender"
import PromptContent from "./PromptContent"
import { actions } from "reducers/notebookReducer"
import calculateCellPlaceholderHeight from "./calculatePlaceholderHeight"
import {
  useExecCell,
  useCellSubscription,
} from "components/pages/Workflows/Edit/shared/util"

const gutter = 50
const Gutter = styled.div`
  margin-top: 5px;
  width: ${gutter}px;
  text-align: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  padding: 0 5px;
`

const Flow = styled.div`
  display: flex;
  justify-content: stretch;
  box-sizing: border-box;

  .form-group {
    margin-bottom: 0;
  }
`

const cellBorder = props => {
  if (!props.isFocused) {
    return "transparent"
  } else if (props.mode === "command") {
    return "#77e"
  } else {
    return "#66BB6A"
  }
}

const ExecutionResults = styled.div`
  margin-left: ${gutter}px;
  display: flex;
  justify-content: stretch;
  box-sizing: border-box;

  .form-group {
    margin-bottom: 0;
  }
`

const CellBlock = styled.div`
  &:hover {
    border-bottom-left-radius: 2px;
    border-top: 1px solid ${props => alterColor(props.theme.background, -10)}
      ${ExecutionResults} {
      ${props => !props.showCode && props.showOutput && "margin-top: -13px;"}
    }
  }

  min-height: 1rem;
  border-left: 5px solid ${cellBorder};
  border-top: 1px solid ${props => alterColor(props.theme.background, -15)};

  &:first-child {
    :empty:hover {
      ${ExecutionResults} {
        ${props => !props.showCode && props.showOutput && "margin-top: 0;"}
      }
    }
  }

  &:last-child {
    border-bottom-left-radius: 2px;
  }
`

//TODO: we may need to update the API to treat metadata as a JSON object
const getDisplayOps = cell => {
  const { metadata } = cell
  const meta = typeof metadata === "string" ? JSON.parse(metadata) : metadata
  return {
    showCode: meta?.showCode,
    showOutput: meta?.showOutput,
  }
}

const GutterContent = styled.div`
  display: flex;
  justify-content: flex-start;
  min-width: 40px;
  margin-left: 5px;
  margin-right: 5px;
`

const Cell = props => {
  const {
    doc,
    cell,
    mode,
    nodeId,
    nodeCollapsed,
    path,
    onExec,
    cellIdx,
    nodeIdx,
    isFocused,
    focusNode,
    ...rest
  } = props

  const wasFocused = useRef(false)
  useCellSubscription({
    nodeIdx,
    cellIdx,
    cellUuid: cell.uuid,
    muted: cell.metadata.muted,
  })
  const [optionsOpen, setOptionsOpen] = useState(false)
  const dispatch = useDispatch()
  const execCell = useExecCell()
  const { showOutput, showCode } = useMemo(() => getDisplayOps(cell), [cell])
  const { source, executionCount, outputs, uuid: cellId } = cell
  const status = useSelector(state =>
    get(state?.notebook, `notebook.cellStatuses.${cellId}`)
  )
  const isEditorFocused = isFocused && mode === "edit"
  const notebookPanel = useMemo(
    () => document.querySelector("#notebook-container"),
    []
  )

  const closeOptions = useCallback(() => setOptionsOpen(false), [])
  const openOptions = useCallback(() => setOptionsOpen(true), [])

  const scrollWithCell = element => {
    if (!element || isFocused === wasFocused.current) return
    if (isFocused && !wasFocused.current) {
      // If the node is collapsed don't bother trying to get to the cell.
      if (nodeCollapsed) {
        return focusNode()
      }

      const codeMirror = element.querySelector(".CodeMirror")

      if (codeMirror) {
        const cursor = codeMirror.querySelector(".CodeMirror-cursor")
        if (cursor) {
          cursor.scrollIntoViewIfNeeded(true)
          wasFocused.current = isFocused
          return
        }

        const selected = codeMirror.querySelector(".CodeMirror-selected")
        if (selected) {
          selected.scrollIntoViewIfNeeded(true)
          wasFocused.current = isFocused
          return
        }
      }
      element.scrollIntoViewIfNeeded(true)
    }

    wasFocused.current = isFocused
  }

  const onFocus = useCallback(() => {
    dispatch(
      actions.focusCell({
        cellIdx: cellIdx,
        node: nodeId,
      })
    )

    dispatch(actions.setEditMode())
  }, [dispatch, cellIdx, nodeId])

  const runCell = e => {
    e.preventDefault()
    execCell({
      path,
      cellId,
      onExec,
      nodeId: nodeId,
      cellType: cell.cellType,
      docValue: source.join(""),
    })
  }

  const insertCell = () => {
    dispatch(
      actions.insertCell({
        nodeIdx,
        cellIdx: cellIdx + 1,
      })
    )
  }

  const appendCell = () => {
    dispatch(
      actions.insertCell({
        nodeIdx,
        cellIdx,
      })
    )
  }

  const toggleCellType = () => {
    const value = cell.cellType === "code" ? "markdown" : "code"
    dispatch(
      actions.updateCellType({
        cellId,
        value,
      })
    )
  }

  const promptProps = useMemo(
    () => ({
      status,
      cellType: cell.cellType,
      execCount: executionCount,
      execResults: outputs,
      runTime: cell.metadata?.runTime,
    }),
    [cell.cellType, cell.metadata.runTime, executionCount, outputs, status]
  )

  const cellName = `workflow.spec.nodes[${nodeIdx}].cells[${cellIdx}].source`
  const placeHolderHeight = useMemo(
    () => calculateCellPlaceholderHeight(cell),
    // We should only ever need to run this once.
    // eslint-disable-next-line
    []
  )

  return (
    <CellBlock
      mode={mode}
      showCode={showCode}
      showOutput={showOutput}
      isFocused={isFocused}
      ref={scrollWithCell}
      onMouseEnter={openOptions}
      onMouseLeave={closeOptions}
      className="db-editor"
    >
      <Flow onClick={onFocus} isFocused={isFocused}>
        <Gutter>
          <GutterContent>
            <CellMenu
              cell={cell}
              runCell={runCell}
              optionsOpen={optionsOpen}
              insertCell={insertCell}
              appendCell={appendCell}
              toggleCellType={toggleCellType}
              cellId={cellId}
            />
            <PromptContent {...promptProps} />
          </GutterContent>
        </Gutter>
        <VisibilityGuard
          preserve
          placeHolderStyle={{ height: `${placeHolderHeight}px` }}
          observerOptions={{ root: notebookPanel }}
        >
          <CellCode
            path={path}
            cellIdx={cellIdx}
            cellId={cell.uuid}
            nodeId={nodeId}
            nodeIdx={nodeIdx}
            onFocus={onFocus}
            showCode={showCode}
            cellType={cell.cellType}
            name={cellName}
            isFocused={isEditorFocused}
            collapsed={nodeCollapsed}
            source={cell.source}
            {...rest}
          />
        </VisibilityGuard>
      </Flow>
      <DelayedRender>
        <CellResults cell={cell} />
      </DelayedRender>
    </CellBlock>
  )
}

Cell.propTypes = {
  onExec: PropTypes.func,
}

export default React.memo(Cell)
