import {
  DBAI_NODE_CLASSNAME,
  APP_VARIABLE_ID_ATTR,
  APP_VARIABLE_CLASSNAME,
  CONDITIONAL_TEXT_CLASSNAME,
} from "../../lib/tinyMce"

export function registerAppVariables(editor, appVariables, callback) {
  editor.ui.registry.addMenuItem("embedAppVariable", {
    text: "Insert Variable",
    onAction: function () {
      editor.windowManager.open({
        title: "Insert Variable",
        body: {
          type: "panel",
          items: [
            {
              type: "selectbox",
              name: "appVariable",
              label: "Choose App Variable",
              items: appVariables.current
                .filter(v => v.id && v.name)
                .map(v => ({
                  value: v.id,
                  text: v.name,
                })),
            },
          ],
        },
        buttons: [
          {
            type: "cancel",
            text: "Cancel",
          },
          {
            type: "submit",
            text: "Insert",
            primary: true,
          },
        ],
        onSubmit: function (dialog) {
          var data = dialog.getData()
          editor.insertContent(
            `<span ${APP_VARIABLE_ID_ATTR}="${data.appVariable}" class="${DBAI_NODE_CLASSNAME} ${APP_VARIABLE_CLASSNAME}">\u00A0</span>\u00A0`
          )
          dialog.close()
          callback(editor.getBody())
        },
      })
    },
  })
}

export function registerConditionalText(editor) {
  editor.ui.registry.addMenuItem("conditionalText", {
    text: "Insert Conditional Text",
    onAction: function () {
      editor.insertContent(
        `<div class="${DBAI_NODE_CLASSNAME} ${CONDITIONAL_TEXT_CLASSNAME}" style="display: inline-block;">\u00A0</div>\u00A0`
      )
    },
  })
}
