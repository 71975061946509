import React, { useCallback } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { actions } from "reducers/scheduleReducer"

import { Flex, FlexLabel } from "./CronControls"

const PresetButton = styled.button`
  margin-right: 10px;
`

const Button = props => {
  const { className, ...rest } = props
  const btnClass = `${className} btn btn-outline-primary`

  return (
    <PresetButton type="button" className={btnClass} {...rest}>
      {props.children}
    </PresetButton>
  )
}

const Presets = props => {
  const dispatch = useDispatch()

  const presetHourly = useCallback(() => {
    dispatch(actions.presetHourly())
  }, [dispatch])

  const presetWeekdays = useCallback(() => {
    dispatch(actions.presetWeekdays())
  }, [dispatch])

  const presetEveryOtherDay = useCallback(() => {
    dispatch(actions.presetEveryOtherDay())
  }, [dispatch])

  const presetMonthly = useCallback(() => {
    dispatch(actions.presetMonthly())
  }, [dispatch])

  const presetQuarterly = useCallback(() => {
    dispatch(actions.presetQuarterly())
  }, [dispatch])

  return (
    <Flex className="form-group">
      <FlexLabel className="form-label">Presets</FlexLabel>
      <Button onClick={presetHourly}>Hourly</Button>
      <Button onClick={presetWeekdays}>Every Weekday</Button>
      <Button onClick={presetEveryOtherDay}>Every Other Day</Button>
      <Button onClick={presetMonthly}>Monthly</Button>
      <Button onClick={presetQuarterly}>Quarterly</Button>
    </Flex>
  )
}

export default Presets
