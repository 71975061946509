import React from "react"
import styled from "styled-components"
import { Button } from "antd"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons"

const ProgressBar = styled.div`
  && {
    height: 5px;
    margin-bottom: 10px;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

const StepperWrapper = styled.div`
  margin-bottom: 15px;
`

const Step = styled.div`
  display: ${props => (props.step === props.current ? "block" : "none")};
`

const Stepper = props => {
  const { steps, current, className } = props

  return (
    <StepperWrapper className={className}>
      <Stepper.Bar steps={steps} current={current} />

      <Flex>
        {React.Children.map(props.children, child => {
          const childProps = { steps, current }
          return React.cloneElement(child, childProps)
        })}
      </Flex>
    </StepperWrapper>
  )
}

const Bar = ({ steps, current }) => {
  const width = Math.floor((current / steps) * 100)

  return (
    <ProgressBar className="progress">
      <div
        className={`progress-bar w-${width}`}
        role="progressbar"
        aria-valuenow={width}
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </ProgressBar>
  )
}

const Next = ({ steps, current, onClick, disabled = false }) => {
  if (current === steps - 1) {
    return (
      <Button type="primary" key="submit" htmlType="submit">
        Submit
      </Button>
    )
  }

  return (
    <Button key="key" type="primary" disabled={disabled} onClick={onClick}>
      Next <FontAwesomeIcon icon={faArrowRight} />
    </Button>
  )
}

const Back = ({ current, onClick }) => {
  return (
    <Button type="primary" disabled={current === 0} onClick={onClick}>
      <FontAwesomeIcon icon={faArrowLeft} /> Back
    </Button>
  )
}

Stepper.Bar = Bar
Stepper.Next = Next
Stepper.Back = Back
Stepper.Step = Step

export default Stepper
