import React, { useCallback } from "react"

import JSONSchemaField from "../../JSONSchemaForm/JSONSchemaField"

const getTreeNodeAddButtonSchema = schema => {
  return {
    ...schema,
    metadata: {
      noStyle: true,
      hideList: true,
      hideLabel: true,
      addButtonProps: {
        trigger: ["click"],
        block: true,
        type: "text",
        style: {
          color: "rgba(0, 0, 0, 0.6)",
          padding: 0,
        },
      },
      addButtonText: `Add ${schema.title}`,
    },
  }
}

const AddFormFieldButton = props => {
  const { path, data, nodeKey, onSelect, schema, schemaKey } = props

  const stopPropagation = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const selectNode = useCallback(
    value => {
      const currentNodeCount = data?.length || 0
      onSelect([`${nodeKey}-${currentNodeCount}`])
    },
    [data, nodeKey, onSelect]
  )

  if (!schema || schema?.metadata?.hideAdd) return null
  const safeSchema = getTreeNodeAddButtonSchema(schema)
  return (
    <div onClick={stopPropagation}>
      <JSONSchemaField
        path={path}
        schema={safeSchema}
        schemaKey={schemaKey}
        afterAppend={selectNode}
      />
    </div>
  )
}

export default AddFormFieldButton
