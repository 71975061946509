import React from "react"
import styled from "styled-components"
import { NavLink } from "react-router-dom"
import { Row, Col, Layout } from "antd"

import AppControls from "./AppControls"
import logo from "./assets/short-logo-64.png"

const { Header } = Layout

const StyledHeader = styled(Header)`
  height: 32px;
  color: white;
  padding: 0 1rem;
  line-height: 30px;
  background: ${props => props.theme.header};
`

const Toolbar = props => {
  const { pageId, appStatus, cname, showSider, toggleShowSider } = props
  const path = `/${cname}/apps`
  return (
    <StyledHeader>
      <Row gutter={[8]} wrap={false}>
        <Col>
          <NavLink key={path} to={path} exact>
            <img src={logo} alt="Logo" height={32} />
          </NavLink>
        </Col>
        <Col flex={1}>
          <AppControls
            pageId={pageId}
            appStatus={appStatus}
            showSider={showSider}
            toggleShowSider={toggleShowSider}
          />
        </Col>
      </Row>
    </StyledHeader>
  )
}

export default Toolbar
