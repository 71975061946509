import { createBrowserHistory } from "history"

import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"

import config from "config"

export const history = createBrowserHistory()

const { sentry, env } = config

if (env !== "development") {
  Sentry.init({
    dsn: sentry.dsn,
    ignoreErrors: [/Access Denied/, /Email or password incorrect/],
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    environment: env,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}
