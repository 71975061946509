import { useCallback, useRef, useEffect } from "react"

const useClickAwayHandler = handler => {
  const ref = useRef(null)
  const callback = useCallback(el => {
    if (!el) return
    ref.current = el
  }, [])

  useEffect(() => {
    // Detect click away events and close the dropout when they occur
    const handleClickOutside = evt => {
      ref.current && !ref.current.contains(evt.target) && handler()
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [ref, handler])

  return callback
}

export default useClickAwayHandler
