import { curry } from "lodash"

const constructExports = curry(options => {
  const { exporting = {}, highchart } = options || {}
  if (!Object.keys(exporting).length) {
    return {
      ...options,
      highchart: {
        ...highchart,
        exporting: {
          enabled: false,
        },
      },
    }
  }

  return {
    ...options,
    highchart: {
      ...highchart,
      exporting: {
        enabled: exporting.menuItems?.length > 0,
        filename: exporting.filename || options.name || "Chart",
        menuItemDefinitions: {
          downloadPDF: {
            text: "Export to PDF",
          },
          downloadSVG: {
            text: "Export to SVG",
          },
          downloadCSV: {
            text: "Export to CSV",
          },
          downloadPNG: {
            text: "Export to PNG",
          },
          downloadJPEG: {
            text: "Export to JPEG",
          },
        },
        buttons: {
          contextButton: {
            align: exporting.align,
            menuItems: exporting.menuItems || [],
            verticalAlign: exporting.verticalAlign,
            // menuStyle: {
            //   zIndex: 9999, // Set a high z-index to ensure the menu appears on top of other elements
            //   padding: "10px", // Add some padding to the menu to avoid overlapping with other elements
            //   backgroundColor: "#fff", // Set a background color for the menu
            //   border: "1px solid #ccc", // Add a border to the menu
            // },
          },
        },
      },
    },
  }
})

export default constructExports
