import styled, { css } from "styled-components"

const colorWithHover = css`
  ${props =>
    props.isHovered
      ? props.theme.colorText || "rgba(0,0,0,0.9)"
      : props.theme.colorTextSecondary || "rgba(0,0,0,0.7)"}
`

const border = css`5px solid ${colorWithHover}`
const handleLength = "25px"

const handleCss = css`
  height: 1px;
  width: 1px;
  position: absolute;
  z-index: 10;
`

export const nwHandle = styled.span`
  ${handleCss}
  z-index: 30;
  top: 0;
  left: 0;
  cursor: nw-resize;

  &&::before,
  &&::after {
    content: "";
    position: absolute;
    background: ${colorWithHover};
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  &&::before {
    top: -2px;
    left: -2px;
    width: ${handleLength};
    height: 5px;
  }

  &&::after {
    top: -2px;
    left: -2px;
    width: 5px;
    height: ${handleLength};
  }
`

export const nHandle = styled.span`
  ${handleCss}
  top: 0;
  cursor: n-resize;
  width: 100%;

  &&::after {
    top: -2px;
    left: calc(50% - ${handleLength} / 2);
    content: "";
    position: absolute;
    transition: all 0.3s ease;
    width: ${handleLength};
    height: ${handleLength};
    border-top: ${border};
  }
`

export const eHandle = styled.span`
  ${handleCss}
  right: 0;
  top: 0;
  cursor: e-resize;
  height: 100%;

  &&::after {
    top: calc(50% - ${handleLength} / 2);
    content: "";
    position: absolute;
    transition: all 0.3s ease;
    right: -2px;
    width: ${handleLength};
    height: ${handleLength};
    border-right: ${border};
  }
`

export const neHandle = styled.span`
  ${handleCss}
  z-index: 30;
  top: 0;
  right: 0;
  cursor: ne-resize;

  &&::before,
  &&::after {
    content: "";
    position: absolute;
    background: ${colorWithHover};
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  &&::before {
    top: -2px;
    right: -2px;
    width: ${handleLength};
    height: 5px;
  }

  &&::after {
    top: -2px;
    right: -2px;
    width: 5px;
    height: ${handleLength};
  }
`

export const wHandle = styled.span`
  ${handleCss}
  top: 0;
  left: 0;
  cursor: w-resize;
  height: 100%;

  &&::after {
    left: -2px;
    top: calc(50% - ${handleLength} / 2);
    content: "";
    position: absolute;
    transition: all 0.3s ease;
    width: ${handleLength};
    height: ${handleLength};
    border-left: ${border};
  }
`

export const swHandle = styled.span`
  ${handleCss}
  z-index: 30;
  bottom: 0;
  left: 0;
  cursor: sw-resize;

  &&::before,
  &&::after {
    content: "";
    position: absolute;
    background: ${colorWithHover};
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  &&::before {
    bottom: -2px;
    left: -2px;
    width: ${handleLength};
    height: 5px;
  }

  &&::after {
    bottom: -2px;
    left: -2px;
    width: 5px;
    height: ${handleLength};
  }
`

export const seHandle = styled.span`
  ${handleCss}
  z-index: 30;
  bottom: 0;
  right: 0;
  cursor: se-resize;

  &&::before,
  &&::after {
    content: "";
    position: absolute;
    background: ${colorWithHover};
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  &&::before {
    right: -2px;
    bottom: -2px;
    width: ${handleLength};
    height: 5px;
  }

  &&::after {
    right: -2px;
    bottom: -2px;
    width: 5px;
    height: ${handleLength};
  }
`

export const sHandle = styled.span`
  ${handleCss}
  bottom: 0;
  left: 0;
  cursor: s-resize;
  width: 100%;

  &&::after {
    left: calc(50% - ${handleLength} / 2);
    content: "";
    position: absolute;
    transition: all 0.3s ease;
    bottom: -2px;
    width: ${handleLength};
    height: ${handleLength};
    border-bottom: ${border};
  }
`

const handles = {
  n: nHandle,
  ne: neHandle,
  e: eHandle,
  se: seHandle,
  s: sHandle,
  sw: swHandle,
  w: wHandle,
  nw: nwHandle,
}

export default handles
