import React, { useState, useEffect } from "react"
import { Affix, Alert } from "antd"

const updatePrompt =
  "A new version of the Delta Bravo app is available. Refresh your browser page to get the latest updates!"

const oneHour = 1000 * 60 * 60

const VersionWatcher = props => {
  const appVersion = document.querySelector("meta[name='app-sha']")?.content
  const [shouldPrompt, setShouldPrompt] = useState(false)

  useEffect(() => {
    const versionWatcher = window.setInterval(() => {
      if (shouldPrompt || !appVersion) return
      fetch("/appInfo.json")
        .then(data => {
          return data.json()
        })
        .then(json => {
          if (json.version && json.version !== appVersion) {
            setShouldPrompt(true)
          }
        })
        .catch(console.error)
    }, oneHour)

    return () => window.clearInterval(versionWatcher)
  }, [appVersion, shouldPrompt])

  if (shouldPrompt)
    return (
      <Affix offsetBottom={0}>
        <Alert banner closable message={updatePrompt} />
      </Affix>
    )

  return null
}

export default VersionWatcher
