import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { faFilter } from "@fortawesome/pro-solid-svg-icons"

import HeaderButton from "./HeaderButton"
import { actions } from "../reducers/appReducer"
import { selectShowAppFilters } from "../selectors/app"

const ShowFiltersButton = () => {
  const showAppFilters = useSelector(selectShowAppFilters)
  const dispatch = useDispatch()

  const toggleShowAppFilters = () => {
    dispatch(actions.toggleShowAppFilters())
  }

  return (
    <HeaderButton
      icon={faFilter}
      title="Filters"
      selected={showAppFilters}
      onClick={toggleShowAppFilters}
      data-testid="app-header-filters-button"
    />
  )
}

export default ShowFiltersButton
