import * as ModelInputs from "./ModelInputs"

const floatTypes = [
  "float64",
  "float16",
  "half",
  "single",
  "csingle",
  "cdouble",
  "clongdouble",
]
const numTypes = [
  "integer",
  "long",
  "short",
  "int_",
  "intc",
  "uintc",
  "longlong",
  "ulonglong",
]
const doubleTypes = ["double", "longdouble"]
const boolTypes = ["boolean", "bool_"]
const stringTypes = ["string"]
const binaryTypes = ["binary"]
const dateTypes = ["date"]

export const useModelFormInput = type => {
  switch (true) {
    case stringTypes.includes(type):
      return ModelInputs.StringInput
    case floatTypes.includes(type):
      return ModelInputs.Float64Input
    case numTypes.includes(type):
      return ModelInputs.IntegerInput
    case doubleTypes.includes(type):
      return ModelInputs.DoubleInput
    case dateTypes.includes(type):
      return ModelInputs.DateTimeInput
    case boolTypes.includes(type):
      return ModelInputs.BooleanInput
    case binaryTypes.includes(type):
      return ModelInputs.BinaryInput
    default:
      return ModelInputs.StringInput
  }
}
