import React, { useEffect, useMemo, useCallback, useState } from "react"
import { Col, Tree } from "antd"
import { useApolloClient } from "@apollo/client"
import { useDispatch, useSelector } from "react-redux"
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons"
import { ChonkyIconFA } from "chonky-icon-fontawesome"
import {
  FileList,
  FileNavbar,
  FileBrowser,
  FileToolbar,
  ChonkyActions,
  ChonkyIconName,
  defineFileAction,
  setChonkyDefaults,
} from "chonky"

import { Card, AuthBoundary, Content } from "@dbai/ui-staples"

import DirectoryForm from "./DirectoryForm"
import DatalakeUpload from "./DatalakeUpload"
import { useChonkySearchEventTrap } from "hooks"
import { actions } from "reducers/datalakeReducer"
import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"
import DeletionConfirmation from "./DeletionConfirmation"
import {
  selectDatalakeOpen,
  selectDatalakeRoot,
  selectDatalakeOpenChain,
} from "selectors"
import {
  getName,
  sortNodes,
  checkIfDir,
  filterIncompleteUploads,
} from "lib/utils/datalake"

setChonkyDefaults({ iconComponent: ChonkyIconFA })

const makeTreeForAnt = ({ nodes = [], ...node }) => {
  const key = node.id ? `${node.id}${node.parentId}` : "rootPath"
  return {
    key,
    node,
    isLeaf: !checkIfDir(node),
    title: getName(node),
    children: [...nodes]
      .filter(filterIncompleteUploads)
      .sort(sortNodes)
      .map(makeTreeForAnt),
  }
}

const makeFolderForChonky = node => ({
  node,
  id: node.id,
  modDate: node.createdAt,
  name: getName(node),
  isDir: checkIfDir(node),
  size: node?.file?.size,
})

const createChonkyActions = ({
  cname,
  dispatch,
  apolloClient,
  openUploadPane,
  setDeletionCandidates,
  openDirectoryModal,
}) => {
  return {
    upload: openUploadPane,
    add_new_directory: openDirectoryModal,
    download_selected: ({ state }) => {
      const files = state?.selectedFiles || []
      files.forEach(({ node }) => dispatch(actions.downloadFile(node)))
    },
    delete_selected: ({ state }) => {
      setDeletionCandidates(state.selectedFiles)
    },
    end_drag_n_drop: ({ payload }) => {
      dispatch(actions.moveFiles(payload))
    },
    [ChonkyActions.OpenFiles.id]: data => {
      const { node } = data.payload.targetFile
      dispatch(actions.selectNode(node))
    },
  }
}

const customFileActions = [
  defineFileAction({
    id: "add_new_directory",
    hotkeys: ["n"],
    button: {
      name: "New Folder",
      toolbar: true,
      contextMenu: true,
      icon: ChonkyIconName.folderCreate,
    },
  }),
  defineFileAction({
    id: "upload",
    button: {
      name: "Upload",
      toolbar: true,
      contextMenu: true,
      icon: faCloudUploadAlt,
    },
  }),
  defineFileAction({
    id: "download_selected",
    requiresSelection: true,
    fileFilter: node => !node.isDir,
    button: {
      name: "Download",
      toolbar: true,
      contextMenu: true,
      icon: ChonkyIconName.download,
    },
  }),
  defineFileAction({
    id: "delete_selected",
    hotkeys: ["del"],
    requiresSelection: true,
    button: {
      name: "Delete",
      toolbar: true,
      contextMenu: true,
      icon: ChonkyIconName.trash,
    },
  }),
]

const defaultExpandedKeys = ["rootPath"]

const Datalake = ({ customer }) => {
  const dispatch = useDispatch()
  const apolloClient = useApolloClient()
  const cname = customer.normalizedName
  const tree = useSelector(selectDatalakeOpen)
  const root = useSelector(selectDatalakeRoot)
  const folderChain = useSelector(selectDatalakeOpenChain)

  const [directoryModalOpen, setDirectoryModalOpen] = useState()
  const openDirectoryModal = () => setDirectoryModalOpen(true)
  const closeDirectoryModal = () => setDirectoryModalOpen(false)

  const [uploadPaneOpen, setUploadPaneOpen] = useState(false)
  const openUploadPane = () => setUploadPaneOpen(true)
  const closeUploadPane = () => setUploadPaneOpen(false)

  const [deletionCandidates, setDeletionCandidates] = useState([])
  const closeDeletionModal = () => setDeletionCandidates([])

  const antTree = useMemo(() => {
    return [root].map(makeTreeForAnt)
  }, [root])

  const files = useMemo(
    () => [
      ...(tree?.nodes || [])
        .filter(filterIncompleteUploads)
        .map(makeFolderForChonky),
    ],
    [tree]
  )

  const handleDelete = useCallback(
    data => {
      dispatch(actions.deleteNodes(deletionCandidates))
      closeDeletionModal()
    },
    [deletionCandidates, dispatch]
  )

  const createDirectory = useCallback(
    ({ directoryName }) => {
      closeDirectoryModal()
      dispatch(actions.createFolder({ cname, name: directoryName }))
    },
    [cname, dispatch]
  )

  useChonkySearchEventTrap()

  useEffect(() => {
    dispatch(actions.loadNodes())
  }, [dispatch, cname])

  const handleLoadData = useCallback(
    ({ node }) => {
      return dispatch(actions.loadNode({ id: node.id }))
    },
    [dispatch]
  )

  const handleNodeSelect = useCallback(
    (_, { node: { node } }) => dispatch(actions.selectNode(node)),
    [dispatch]
  )

  const handleDrop = useCallback(
    data => {
      // NOTE: node here is a node in a tree, not one of our nodes.
      const { node, dragNode } = data
      if (checkIfDir(node.node)) {
        dispatch(
          actions.moveFiles({
            destination: node.node,
            draggedFile: dragNode.node,
          })
        )
      }
    },
    [dispatch]
  )

  const fileActions = useMemo(
    () =>
      createChonkyActions({
        cname,
        dispatch,
        apolloClient,
        openUploadPane,
        openDirectoryModal,
        setDeletionCandidates,
      }),
    [apolloClient, cname, dispatch]
  )

  const selectAndRunHandler = useCallback(
    chonkyAction => {
      const handler = fileActions[chonkyAction.id]
      if (handler) handler(chonkyAction)
    },
    [fileActions]
  )

  const routes = [
    { breadcrumbName: customer.name },
    { breadcrumbName: "Data Lake" },
  ]

  return (
    <AuthBoundary subject="datalake" action="LIST">
      <PageHeader routes={routes} />
      <DatalakeUpload
        isVisible={uploadPaneOpen}
        handleClose={closeUploadPane}
      />
      <Content>
        <Col span={6}>
          <Card>
            <Card.Header>Folders</Card.Header>
            <Tree
              draggable
              treeData={antTree}
              onDrop={handleDrop}
              loadData={handleLoadData}
              onSelect={handleNodeSelect}
              defaultExpandedKeys={defaultExpandedKeys}
              rootStyle={{ wordBreak: "break-all", wordWrap: "break-word" }}
            />
          </Card>
        </Col>
        <Col span={18}>
          <FileBrowser
            instanceId={"Datalake"}
            disableDragAndDropProvider={true}
            files={files}
            folderChain={folderChain}
            fileActions={customFileActions}
            onFileAction={selectAndRunHandler}
            defaultFileViewActionId={ChonkyActions.EnableListView.id}
          >
            <FileNavbar />
            <FileToolbar />
            <FileList />
          </FileBrowser>
        </Col>
      </Content>
      <DirectoryForm
        isOpen={directoryModalOpen}
        closeModal={closeDirectoryModal}
        handleSubmit={createDirectory}
      />
      <DeletionConfirmation
        onDelete={handleDelete}
        candidates={deletionCandidates}
        closeModal={closeDeletionModal}
      />
    </AuthBoundary>
  )
}

export default withCustomer(Datalake)
