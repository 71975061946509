import gql from "graphql-tag"

export const SEARCH = gql`
  query Search($cname: String!, $term: String!) {
    customer(name: $cname) {
      id
      search(term: $term) {
        __typename
        ... on Workflow {
          id
          name
          tags
        }
        ... on Dataset {
          id
          name
          tags
        }
        ... on Action {
          id
          name
          tags
        }
        ... on Model {
          id
          name
          tags
        }
        ... on App {
          id
          name
          tags
        }
      }
    }
  }
`
