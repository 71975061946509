import React, { useEffect } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"

import Action from "./Action"
import InputForm from "./InputForm"
import { useOutputManager } from "hooks"
import ActionPicker from "./ActionPicker"
import { actions } from "reducers/notebookReducer"
import { selectSpec, selectFocused } from "selectors"
import useHotKeyMap from "components/pages/Workflows/Edit/Notebook/useHotKeyMap"

const NotebookContainer = styled.div`
  height: 100%;
  overflow-y: hidden;
`

const ActionContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
`

const Notebook = () => {
  useHotKeyMap()
  useOutputManager()
  const dispatch = useDispatch()
  const { nodes, edges } = useSelector(selectSpec)
  const { node: selected } = useSelector(selectFocused)

  useEffect(() => {
    dispatch(actions.sortNodes())
  }, [dispatch, edges])

  const indexes = nodes.reduce((pre, n, idx) => ({ [n.id]: idx, ...pre }), {})

  return (
    <NotebookContainer id="notebook-container">
      <ActionPicker />
      <ActionContainer>
        {nodes.map((node, key) => {
          const idx = indexes[node.id]

          return (
            <Action
              node={node}
              nodeIdx={idx}
              selected={selected}
              key={node.id || key}
              nodeCount={nodes.length}
            />
          )
        })}
      </ActionContainer>
      <InputForm />
    </NotebookContainer>
  )
}

export default Notebook
