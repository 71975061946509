import React, { useMemo } from "react"
import { Space } from "antd"
import { useSelector, useDispatch } from "react-redux"
import { faPlus, faPaste, faPage } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { useWidgetContext } from "../../hooks"
import { actions } from "../../reducers/appReducer"
import { selectCopyWidgetId } from "../../selectors/app"
import widgetRegistry from "../../widgetRegistry"

const usePageMenuItems = (pages, handleClick) => {
  const dispatch = useDispatch()
  const copyWidgetId = useSelector(selectCopyWidgetId)
  const { widgetSchema } = useWidgetContext()

  return useMemo(() => {
    if (!pages?.length) return []
    return pages.map(page => {
      return {
        type: "group",
        key: page.id,
        label: (
          <Space>
            <FontAwesomeIcon icon={faPage} /> {page.name || "Page"}
          </Space>
        ),
        children: [
          {
            key: `add-widget-${page.id}`,
            icon: <FontAwesomeIcon icon={faPlus} />,
            label: "Add Widget",
            onClick: handleClick(() => {
              dispatch(
                actions.editWidget({ pageId: page.id, openFullEditor: true })
              )
            }),
          },
          {
            key: `paste-widget-${page.id}`,
            label: "Paste Widget",
            disabled: !copyWidgetId,
            icon: <FontAwesomeIcon icon={faPaste} />,
            onClick: handleClick(() => {
              dispatch(
                actions.pasteWidget({ page, widgetSchema, widgetRegistry })
              )
            }),
          },
        ],
      }
    })
  }, [pages, handleClick, copyWidgetId, widgetSchema, dispatch])
}

export default usePageMenuItems
