const getPosition = (verticalPosition, alignment) => {
  if (verticalPosition === "both") {
    return [`top${alignment}`, `bottom${alignment}`]
  }
  return [`${verticalPosition}${alignment}`]
}

const getTablePaginationProps = table => {
  if (!table) return {}
  const { pagination = {} } = table
  if (pagination.pageSize > 0) {
    return {
      ...pagination,
      showSizeChanger: false,
    }
  }
  return pagination
}

const getPaginationOptions = (table, current, dataset) => {
  const pagination = getTablePaginationProps(table)

  return {
    ...pagination,
    current,
    total: dataset?.meta?.count,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    position: getPosition(pagination.verticalPosition, pagination.alignment),
  }
}

export { getPosition, getPaginationOptions, getTablePaginationProps }
