import React, { useMemo } from "react"
import styled from "styled-components"

import PanelTitle from "./PanelTitle"
import { useRegisteredWigetSpec } from "../../hooks"
import WidgetWrapper from "../../Widget/WidgetWrapper"
import { normalizeFormData } from "../../lib/formData"
import AppConfig from "../../AppConfig"

const WidgetEditorPreviewContainer = styled.div`
  background: ${({ theme }) => theme.background};
  max-height: calc(100vh - 106px);
  overflow-y: auto;
  height: 100%;
`

const WidgetPreviewContainer = props => {
  const { widget } = props
  if (!widget.options) return null
  return (
    <AppConfig>
      <WidgetEditorPreviewContainer>
        <WidgetWrapper editing widget={widget}>
          {props.children}
        </WidgetWrapper>
      </WidgetEditorPreviewContainer>
    </AppConfig>
  )
}

const WidgetPreview = props => {
  const { value: widget, showTitle = true } = props
  const normalizedWidget = useMemo(() => {
    const widgetState = normalizeFormData(widget)
    return widgetState
  }, [widget])
  const { Component, ...restSpec } = useRegisteredWigetSpec(widget)
  return (
    <>
      {showTitle ? <PanelTitle level={3}>Previewer</PanelTitle> : null}
      <WidgetPreviewContainer widget={normalizedWidget}>
        <Component {...restSpec} widget={normalizedWidget} editing />
      </WidgetPreviewContainer>
    </>
  )
}

export { WidgetPreviewContainer }
export default WidgetPreview
