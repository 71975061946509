import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  createContext,
} from "react"
import please from "pleasejs"
import { isEqual } from "lodash"
import { useApolloClient } from "@apollo/client"
import { useDispatch, useStore } from "react-redux"

import { useCurrentUser, useCurrentCustomer } from "@dbai/ui-staples"

import initDoc from "./initDoc"
import { actions } from "../reducers/appReducer"
import initObservers from "./initObservers"
import metadataObserver from "./observers/metadataObserver"
import initYjs, { shutDownYjs } from "./initYjs"
import {
  getDocId,
  updateProviderUrl,
  removeWidgetSelectionYDoc,
} from "../lib/yjs"

const YJS_RESOURCE_NAME = "apps"

export const color = please.make_color({
  hue: 0.5,
})

const YjsContext = createContext(null)

const initialState = {
  doc: null,
  provider: null,
  // authInterval: null,
  // observersInitialized: false,
  // shouldNotReconnect: false,
  users: [],
}

const handleSync = (wsProvider, user) => () => {
  wsProvider.awareness.setLocalStateField("user", {
    id: user && (user.id || user.email),
    name: user && (user.name || user.email),
    color,
  })
}

const findOutUsers = (users, newUsers) => {
  return users.filter(
    user => !newUsers.find(newUser => newUser.key === user.key)
  )
}

const handleAwarenessChange = (yDoc, wsProvider, setUsers) => () => {
  const awarenessState = wsProvider.awareness.getStates()
  const newUsers = Array.from(awarenessState).map(([key, value]) => ({
    key,
    ...value.user,
  }))

  setUsers(users => {
    // edge case to remove user selections when user leaves the app builder unconventionally
    if (users.length > newUsers.length) {
      const outUsers = findOutUsers(users, newUsers)
      outUsers.forEach(({ key }) => {
        removeWidgetSelectionYDoc(yDoc, key)
      })
    }
    if (!isEqual(users, newUsers)) {
      return newUsers
    }
    return users
  })
}

const setupYjsMetadata = (yDoc, dispatch, onStatusChange) => {
  // observe changes to yjs metadata
  metadataObserver(yDoc, dispatch, onStatusChange)

  // synchronize current yjs metadata with local redux
  const metadataMap = yDoc.get("metadata")
  const metadata = metadataMap.toJSON()
  dispatch(actions.setYMetadata(metadata))
}

const handleFirstSync =
  (yDoc, dispatch, onStatusChange, setError) => isSynced => {
    if (isSynced) {
      // check if yDoc is initialized properly
      if (!yDoc.get("spec") || !yDoc.get("metadata")) {
        setError("Yjs document is not initialized properly")
        return
      }

      // initialize document observers
      const initialized = initObservers(yDoc, dispatch)
      if (!initialized) {
        setError("Failed to initialize observers")
        return
      }

      // setup yjs metadata
      setupYjsMetadata(yDoc, dispatch, onStatusChange)
    }
  }

const handleStatusConnected = (wsProvider, yDoc, store, setError) => () => {
  /*
   * By extracting the default onmessage handler we can insert our own
   * intermediary logic prior to calling the default allowing us to
   * reuse the current websocket for other purposes.
   */
  const yMessageHandler = wsProvider.ws.onmessage
  wsProvider.ws.onmessage = event => {
    const { data } = event
    if (typeof data !== "string") {
      yMessageHandler(event)
    }

    const isInitRequest = data === "initRequest"

    // We only want one client initializing the ydoc at a time.
    if (isInitRequest) {
      const initialized = initDoc(yDoc, store)
      if (!initialized) {
        setError("Failed to initialize yDoc")
      }
    }
  }
}

const handleStatus =
  ({ onConnect, onDisconnect }) =>
  ({ status }) => {
    if (status === "disconnected") {
      onDisconnect()
    }
    if (status === "connected") {
      onConnect()
    }
  }

const handleWsClose = (shutDown, onClose) => event => {
  console.log(
    `WebSocket closed with code: ${event.code}, reason: ${event.reason}`
  )
  if (event.code === 1008) {
    console.log(
      "WebSocket closed due to policy violation or authentication error. Preventing reconnection."
    )
    shutDown()
    return
  }
  onClose(event)
}

const YjsProvider = props => {
  const { url, resourceId, onStatusChange, children } = props
  const store = useStore()
  const docRef = useRef(null)
  const [user] = useCurrentUser()
  const client = useApolloClient()
  const dispatch = useDispatch()
  const [customer] = useCurrentCustomer()
  const providerRef = useRef(null)
  const authInterval = useRef(null)
  const shouldNotReconnect = useRef(false)
  const [doc, setDoc] = useState(null)
  const [users, setUsers] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [provider, setProvider] = useState(null)

  const shutDown = useCallback(() => {
    setLoading(false)
    if (providerRef.current) shutDownYjs()
    if (authInterval.current) clearInterval(authInterval.current)
    setDoc(initialState.doc)
    setUsers(initialState.users)
    setProvider(initialState.provider)
  }, [])

  const docId = useMemo(() => {
    if (!customer?.normalizedName) return null
    return getDocId(customer?.normalizedName, YJS_RESOURCE_NAME, resourceId)
  }, [customer?.normalizedName, resourceId])

  const connectToYjs = useCallback(() => {
    if (!docId || !url || shouldNotReconnect.current) return

    initYjs(url, docId, customer)
      .then(yProviderAndDoc => {
        if (!yProviderAndDoc) return
        const { wsProvider, yDoc } = yProviderAndDoc
        docRef.current = yDoc
        providerRef.current = wsProvider

        // handle awareness changes
        wsProvider.awareness.on(
          "change",
          handleAwarenessChange(yDoc, wsProvider, setUsers)
        )

        // init observers and sync yjs metadata with client on first sync
        wsProvider.once(
          "sync",
          handleFirstSync(yDoc, dispatch, onStatusChange, setError)
        )

        // handle subsequent sync events
        wsProvider.on("sync", handleSync(wsProvider, user))

        // handle connect and disconnect status
        wsProvider.on(
          "status",
          handleStatus({
            onConnect: handleStatusConnected(wsProvider, yDoc, store, setError),
            onDisconnect: () => updateProviderUrl(client, wsProvider, customer),
          })
        )

        wsProvider.connect()

        // override ws close handler
        const yOnCloseHandler = wsProvider.ws.onclose
        wsProvider.ws.onclose = handleWsClose(() => {
          shouldNotReconnect.current = true
          shutDown()
        }, yOnCloseHandler)

        setProvider(wsProvider)
        setDoc(yDoc)
        setLoading(false)

        authInterval.current = setInterval(() => {
          if (wsProvider && wsProvider.wsconnected) {
            updateProviderUrl(client, wsProvider, customer)
          }
        }, 5000)
      })
      .catch(err => {
        setLoading(false)
        console.error(err)
        console.warn("Unable to connect to ws server")
      })
  }, [
    url,
    store,
    user,
    docId,
    client,
    shutDown,
    dispatch,
    customer,
    onStatusChange,
    shouldNotReconnect,
  ])

  useEffect(() => {
    connectToYjs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => {
      shutDown()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (doc && doc.name !== docId) {
      shutDown()
      throw new Error("Incorrect yDoc")
    }
  }, [doc, docId, shutDown])

  return (
    <YjsContext.Provider
      value={{ provider, doc, enabled: !!url, users, loading, error }}
    >
      {children}
    </YjsContext.Provider>
  )
}

export { YjsContext }
export default YjsProvider
