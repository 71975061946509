import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import { Tag, Input } from "antd"

import { tagsReducer } from "@dbai/ui-staples"

const { actions } = tagsReducer

const TagsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -3px 0;
`

const StyledInput = styled(Input)`
  && {
    position: relative;
    width: 25px;
    border-style: dashed;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  &:focus {
    width: 85px;
    border-style: solid;
  }
`

const StyledTag = styled(Tag)`
  margin-top: 3px;
  margin-bottom: 3px;
`

const TagsFormatter = ({ row, type, onUpdate, value: tags }) => {
  const dispatch = useDispatch()
  const [value, setValue] = useState("")
  const clearValue = () => setValue("")
  const [allTags, setAllTags] = useState(new Set())

  useEffect(() => {
    setAllTags(new Set(tags))
  }, [tags])

  const handleOnClose = tag => () => {
    dispatch(actions.untagResource({ tag, type, id: row.id })).then(() => {
      const newSet = new Set(allTags)
      newSet.delete(tag)
      setAllTags(newSet)
      onUpdate && onUpdate(row)
    })
  }

  const handleOnEnter = () => {
    const tag = value
    dispatch(actions.tagResource({ tag, type, id: row.id })).then(() => {
      clearValue()
      setAllTags(new Set(allTags.add(tag)))
      onUpdate && onUpdate(row)
    })
  }

  return (
    <TagsList>
      {[...allTags].map(tag => (
        <StyledTag key={tag} closable onClose={handleOnClose(tag)}>
          {tag}
        </StyledTag>
      ))}
      <StyledInput
        size="small"
        value={value}
        className="tag-input"
        placeholder="+ New Tag"
        onBlur={clearValue}
        onChange={e => setValue(e.target.value)}
        onPressEnter={handleOnEnter}
      />
    </TagsList>
  )
}

export default TagsFormatter
