import { uuidv4 } from "@dbai/tool-box"

/*
 * Generate a unique session id that we can use for such things as determining
 * if a given event originated from this tab.
 */
const sessionId = uuidv4()

const useSessionId = () => sessionId

export default useSessionId
