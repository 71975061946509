import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTag, faHashtag } from "@fortawesome/pro-solid-svg-icons"
import { faClock, faCalculatorSimple } from "@fortawesome/pro-regular-svg-icons"

// Configuration map for icon types
const iconConfig = {
  string: {
    icon: faTag,
    color: "#0077D5",
  },
  datetime: {
    icon: faClock,
    color: "#4C00A2",
  },
  computed: {
    icon: faCalculatorSimple,
    color: "#FFA500",
  },
  default: {
    icon: faHashtag,
    color: "#00A70D",
  },
}

const FieldTypeIcon = ({ type, ...rest }) => {
  const config = iconConfig[type] || iconConfig.default

  return (
    <FontAwesomeIcon
      icon={config.icon}
      color={config.color}
      className="db-icon"
      {...rest}
    />
  )
}

export default FieldTypeIcon
