import { createSlice } from "@reduxjs/toolkit"

import { createCommonActions } from "@dbai/tool-box"

import client from "apolloClient"
import { GET_SCHEDULES, DELETE_SCHEDULE } from "queries"

const initialState = {
  schedules: [],
}

const schedulesSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {
    ...createCommonActions(initialState),
  },
})

const { actions } = schedulesSlice

const loadSchedules = variables => dispatch => {
  const query = GET_SCHEDULES
  const queryOpts = {
    variables,
    query,
    fetchPolicy: "network-only",
  }

  return client.query(queryOpts).then(({ data }) => {
    const value = data.customer.schedules
    dispatch(actions.set({ name: "schedules", value }))
  })
}

const deleteSchedule = variables => () => {
  return client.mutate({
    mutation: DELETE_SCHEDULE,
    variables,
  })
}

const allActions = {
  ...actions,
  loadSchedules,
  deleteSchedule,
}

export { allActions as actions }
export const { name, reducer, caseReducers } = schedulesSlice
export default schedulesSlice
