import React, { useCallback, useMemo } from "react"
import { useQuery } from "@apollo/client"

import Select from "../shared/Select"
import { getSelectMode } from "../utils"
import { useWidgetContext } from "../../hooks"
import { GET_THEMES } from "../../queries/themes"
import { mapFormDataToSchema } from "../../lib/formData"
import MemoizedFormFieldWrapper from "../FormFieldWrapper"
import useFormFieldActions from "../hooks/useFormFieldActions"
import appThemeSchema from "../../schemas/appConfigSchema/appThemeSchema"
import getDataWithDefaultValues from "../../lib/getDataWithDefaultValues"

const defaultThemes = [
  {
    name: "Default",
    id: "default",
  },
]

/**
 * to use this component, add the following to your schema's metadata:
 * {
 *   component: "ThemeSelector",
 *   themeSchemaPath: "path.to.themeSchema",
 * }
 *
 * The themeSchemaPath is optional, and will default to "theme". For this component to work, the
 * schema must have a theme property. Refer to appThemeSchema for the theme schema definition.
 */
const defaultTheme = getDataWithDefaultValues({
  schema: appThemeSchema,
  data: {},
})

const ThemeSelectorCore = props => {
  const { schema, onChange: changeThemeId } = props
  const mode = getSelectMode(schema)
  const { cname } = useWidgetContext()
  const { nullable, metadata } = schema
  const { themeSchemaPath } = metadata || {}
  const themePath = themeSchemaPath || "theme"
  const { onChange: updateTheme } = useFormFieldActions(themePath)

  const { loading, data, error } = useQuery(GET_THEMES, {
    variables: { cname },
  })

  const options = useMemo(() => {
    const themes = data?.customer?.themes
    if (!themes) return defaultThemes
    return [...defaultThemes, ...themes].map(theme => {
      return {
        label: theme.name,
        value: `${theme.id}`,
      }
    })
  }, [data?.customer?.themes])

  const getTheme = useCallback(
    themeId => {
      if (!themeId || themeId === "default") {
        return defaultTheme
      }

      const theme = data?.customer?.themes.find(
        theme => `${theme.id}` === themeId
      )

      const { spec } = theme || {}

      if (spec) {
        const mappedSpec = mapFormDataToSchema({
          data: spec,
          schema: appThemeSchema,
        })
        return { name: theme.name, ...mappedSpec }
      }
      return defaultTheme
    },
    [data?.customer?.themes]
  )

  const handleChange = useCallback(
    value => {
      changeThemeId(value)
      updateTheme(getTheme(value))
    },
    [updateTheme, getTheme, changeThemeId]
  )

  if (error) return null
  return (
    <Select
      {...props}
      mode={mode}
      defaultValue="0"
      loading={loading}
      options={options}
      allowClear={nullable}
      onChange={handleChange}
    />
  )
}

const ThemeSelector = props => {
  return (
    <MemoizedFormFieldWrapper {...props}>
      <ThemeSelectorCore />
    </MemoizedFormFieldWrapper>
  )
}
ThemeSelector.Core = ThemeSelectorCore
export default ThemeSelector
