import { createSelector } from "reselect"

export const selectSearch = state => state.search || {}
export const selectSearchTerm = createSelector(
  selectSearch,
  search => search.term || ""
)
/*
 * Given our search term and an array of server provided results, filter
 * client side what we show.
 */
export const selectSearchResults = createSelector(selectSearch, search => {
  const { results = [] } = search
  const filtered = results.filter(result => {
    const lcTerm = search.term.toLowerCase()
    return (
      result.name.toLowerCase().startsWith(lcTerm) ||
      result.tags.some(tag => tag.toLowerCase().startsWith(lcTerm))
    )
  })
  return filtered
})
