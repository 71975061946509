import { useEffect } from "react"

// Because the search field in Chonky catches all our hotkeys for some
// reason...
export const useChonkySearchEventTrap = () => {
  const searchInput = document.querySelector(".chonky-searchFieldInputInner")

  useEffect(() => {
    if (!searchInput) return
    const handleSearchKey = e => {
      e.stopPropagation()
    }
    searchInput.addEventListener("keydown", handleSearchKey)
    return () => searchInput.removeEventListener("keydown", handleSearchKey)
  }, [searchInput])
}
