import React from "react"
import { Tag, Row } from "antd"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faTag, faHashtag } from "@fortawesome/pro-solid-svg-icons"

const { CheckableTag } = Tag

const CheckableFilter = styled(CheckableTag)`
  width: 100%;
  border-radius: 0;
  margin: 0;
  text-align: center;
  border: 1px solid
    ${props => {
      return props.theme?.colorBorder || "#cccccc"
    }};
`

const filterableColumnTypes = {
  datetime: { icon: faClock },
  numerical: { icon: faHashtag },
  categorical: { icon: faTag },
}

const FilterColumnTypes = props => {
  const { filterTypes, handleChange } = props
  return (
    <Row wrap={false} style={{ width: "100%" }}>
      {Object.keys(filterableColumnTypes).map(columnType => {
        return (
          <CheckableFilter
            key={columnType}
            checked={filterTypes.includes(columnType)}
            onChange={handleChange(columnType)}
          >
            <FontAwesomeIcon icon={filterableColumnTypes[columnType].icon} />
          </CheckableFilter>
        )
      })}
    </Row>
  )
}

export default FilterColumnTypes
