import React, { useMemo, useEffect, useCallback, useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useDispatch, useSelector } from "react-redux"

import { actions } from "../../../reducers/appReducer"
import LayoutActionButtons from "./LayoutActionButtons"
import {
  selectContextMenuWidgetId,
  selectEditingWidgetId,
  selectFullWidgetEditorOpen,
} from "../../../selectors/app"
import {
  useContextMenu,
  useYjsSelections,
  useWidgetContext,
  useCurrentBreakpoint,
} from "../../../hooks"
import {
  selectAppWidgetType,
  selectAppWidgetLayout,
} from "../../../selectors/app"

const otherUserFocusCss = css`
  ${({ userSelections }) => {
    if (!userSelections || userSelections.length === 0) return

    const generateBoxShadow = colors => {
      if (colors.length === 0) return "none"

      const boxShadowParts = colors.map((color, index) => {
        const spread = 3 * (index + 1) // Adjust the spread as needed
        return `inset 0 0 0 ${spread}px ${color}`
      })

      return boxShadowParts.join(", ")
    }

    const userSelectionsCss = generateBoxShadow(
      userSelections.map(({ color }) => color)
    )
    return css`
      &&::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: ${userSelectionsCss};
        pointer-events: none; /* Allows interaction with the content below the box-shadow */
        z-index: 9; /* Positions the box-shadow above the content */
      }
    `
  }}
`

const NameBox = styled.div`
  transition: all 0.3s ease;

  ${({ hovered }) => {
    if (!hovered)
      return `
        color: transparent;
        background-color: transparent;
      `

    return `
      color: white;
      background-color: rgba(0, 0, 0, 0.7);
    `
  }}
  display: inline-block;
  padding: 5px;
  white-space: nowrap;
`

const NameContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  z-index: 10;
`

const StyledLayout = styled.div`
  height: 100%;
  position: relative;
  transition: all 0.3s ease;
  ${otherUserFocusCss}
`

const Layout = props => {
  const {
    pageId,
    widgetId,
    className = "",
    actionsPosition,
    gridItemMargins,
    containerPadding,
  } = props
  const { Widget, editable, widgetRegistry } = useWidgetContext()
  const breakpoint = useCurrentBreakpoint()
  const [hovered, setHovered] = useState(false)
  const [focused, setFocused] = useState(false)
  const dispatch = useDispatch()

  const editingWidgetId = useSelector(selectEditingWidgetId)
  const widgetEditorOpen = useSelector(selectFullWidgetEditorOpen)
  const contextMenuWidgetId = useSelector(selectContextMenuWidgetId)
  const layout = useSelector(state =>
    selectAppWidgetLayout(state, { widgetId })
  )
  const widgetType = useSelector(state =>
    selectAppWidgetType(state, { widgetId })
  )
  const userSelections = useYjsSelections(widgetId)

  // reset focus state if context menu was opened for this widget
  useEffect(() => {
    if (focused && contextMenuWidgetId !== widgetId) {
      setFocused(false)
    }
  }, [contextMenuWidgetId, widgetId, focused])

  const handleContextMenu = useCallback(
    e => {
      // prevent encapsulating widgets from also opening the context menu
      if (!e.called) {
        e.called = true
        setFocused(true)

        // also show page widget options in context menu
        dispatch(actions.addWidgetToContextMenu({ widgetId }))
      }
    },
    [dispatch, widgetId]
  )

  const ref = useContextMenu(handleContextMenu)

  // editing is true when the widget is selected and the full editor is not open.
  // when the full editor is opened, the widget is still selected, but not in editing mode.
  const editing = useMemo(
    () => !widgetEditorOpen && editingWidgetId === widgetId,
    [editingWidgetId, widgetEditorOpen, widgetId]
  )

  const handleWidgetClick = useCallback(
    e => {
      e.stopPropagation()
      // prevent selecting the widget if its already selected
      if (!editable) return
      const { allowFullEditor } = widgetRegistry[widgetType] || {}
      dispatch(
        actions.selectWidgetWithSync({ pageId, widgetId, allowFullEditor })
      )
    },
    [pageId, dispatch, editable, widgetId, widgetType, widgetRegistry]
  )

  const handleMouseEnter = useCallback(() => setHovered(true), [])
  const handleMouseLeave = useCallback(() => setHovered(false), [])

  useEffect(() => {
    if (editing && ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [editing, ref])

  if (!layout) return null

  const selectedClassName = editing ? "selected" : ""

  return (
    <StyledLayout
      ref={ref}
      onClick={handleWidgetClick}
      editable={editable}
      isFocused={focused || editing}
      className={`${className} ${selectedClassName}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      userSelections={userSelections}
    >
      {userSelections?.length ? (
        <NameContainer>
          {userSelections.map(({ name }, index) => (
            <NameBox key={index} hovered={hovered}>
              {name}
            </NameBox>
          ))}
        </NameContainer>
      ) : null}
      <LayoutActionButtons
        pageId={pageId}
        position={actionsPosition}
        visible={focused || hovered}
      />
      <Widget
        pageId={pageId}
        editing={editing}
        widgetId={widgetId}
        layout={layout[breakpoint]}
        gridItemMargins={gridItemMargins}
        actionsPosition={actionsPosition}
        containerPadding={containerPadding}
      />
    </StyledLayout>
  )
}

Layout.propTypes = {
  movable: PropTypes.bool,
  currentBreakpoint: PropTypes.string.isRequired,
  pageId: PropTypes.number,
  pageWidgetId: PropTypes.number,
}

export default React.memo(Layout)
