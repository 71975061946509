import React, { useCallback } from "react"
import { debounce } from "lodash"

import { useUpdateRow } from "./hooks"
import JSONSchemaForm from "../../JSONSchemaForm"

const getRowFormId = (record, tableColumns, widgetId) => {
  if (!tableColumns?.length) return

  const uniqueValues = tableColumns
    .filter(({ isUniqueKey }) => isUniqueKey)
    .map(({ dataIndex }) => record[dataIndex])

  return uniqueValues.length
    ? uniqueValues.join("-")
    : `${widgetId}-${record.key}`
}

const EditableRow = ({
  record,
  columns,
  widgetId,
  datasetId,
  tableColumns,
  rowSchema: schema,
  ...props
}) => {
  const formId = getRowFormId(record, tableColumns, widgetId)
  const updateRow = useUpdateRow(datasetId, columns, record)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateRow = useCallback(
    debounce((...args) => updateRow(...args), 300),
    [updateRow]
  )

  if (!schema) return <tr {...props} />
  return (
    <JSONSchemaForm.Provider
      value={record}
      formId={formId}
      schema={schema}
      showLoading={false}
      onFormChange={debouncedUpdateRow}
    >
      <tr {...props} />
    </JSONSchemaForm.Provider>
  )
}

export default React.memo(EditableRow)
