import React, { useMemo, useCallback } from "react"
import { Select } from "antd"
import styled from "styled-components"
import { useQuery } from "@apollo/client"

import { PageSpinner, ErrorMessage } from "@dbai/ui-staples"

import { GET_POLICY_ACTIONS, GET_SUBJECTS } from "queries"

const StyledSelect = styled(Select)`
  min-width: 20rem;
`

const useMemoizedData = (key, data, sort) => {
  return useMemo(() => {
    if (!data || !data[key]) return []
    if (sort) {
      return [...data[key]].sort(sort).map(sub => ({ value: sub, label: sub }))
    }
    return data[key].map(sub => ({ value: sub, label: sub }))
  }, [data, key, sort])
}

const sortSubjects = (a, b) => a.localeCompare(b)

export const SubjectSelect = props => {
  const { value, onChange } = props
  const { data, loading, error } = useQuery(GET_SUBJECTS)
  const options = useMemoizedData("subjects", data, sortSubjects)

  const handleChange = useCallback(
    value => {
      onChange({ value })
    },
    [onChange]
  )

  if (error) return <ErrorMessage error={error} />
  if (!data || loading) return <PageSpinner />
  return (
    <StyledSelect
      mode="tags"
      value={value}
      options={options}
      onChange={handleChange}
    />
  )
}

export const ActionsSelect = props => {
  const { value, onChange } = props
  const { data, loading, error } = useQuery(GET_POLICY_ACTIONS)
  const options = useMemoizedData("policyActions", data)

  const handleChange = useCallback(
    value => {
      onChange({ value })
    },
    [onChange]
  )

  if (error) return <ErrorMessage error={error} />
  if (!data || loading) return <PageSpinner />
  return (
    <StyledSelect
      value={value}
      mode="multiple"
      options={options}
      onChange={handleChange}
    />
  )
}
