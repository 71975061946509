import React, { useRef, useCallback, useState, useMemo } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { Input, Tooltip, Card, Popover } from "antd"
import { faCode } from "@fortawesome/free-solid-svg-icons"
import { useHotkeys } from "react-hotkeys-hook"

import { FAMemo, Dropout, useDropout, useFormState } from "@dbai/ui-staples"
import NotebookComponentForm from "./Cell/NotebookComponentForm"
import ActionMenu from "./ActionMenu"
import ActionHeaderButton from "./ActionHeaderButton"
import { useNodeStatus } from "hooks"
import ColorPicker from "./ColorPicker"
import ScriptCells from "./ScriptCells"
import SourceNodeFields from "./SourceNodeFields"
import { cellStatusTypes } from "constants/index"
import { actions } from "reducers/notebookReducer"
import DownloadNodeFields from "./DownloadNodeFields"
import StatusIndicator from "components/pages/Workflows/Edit/shared/StatusIndicator"
import {
  getIcon,
  IconContainer,
} from "components/pages/Workflows/Edit/shared/NodeText"
import IconPicker, {
  nodeIconMap,
} from "components/pages/Workflows/shared/IconPicker"
import { get } from "lodash"

const selectedShadow = `
  -webkit-box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.19);
  box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.19);
  border-right: 5px solid #77e;
`

/*
 * Calculates a z-index for a Component that ensures that it's higher than the
 * next component. This makes it so that things like the color picker don't
 * render under the next node. The index is a range from 101 to 100 + nodeCount.
 */
// const calculateZIndex = ({ nodeCount, nodeIdx }) => 100 + nodeCount - nodeIdx
const calculateZIndex = ({ nodeCount, nodeIdx }) => 100

const ActionWrapper = styled(Card)`
  ${props => props.isSelected && selectedShadow}

  .ant-card-body {
    padding: 0;
  }

  .ant-card-head {
    top: 0;
    z-index: 99;
    position: sticky;
    background: inherit;
  }

  .db-select__menu {
    // Make sure dropdowns show up above Component headers.
    z-index: ${calculateZIndex};
  }

  ${({ collapsed }) =>
    collapsed &&
    `
    .ant-card-body {
      padding: 0;
      display: none;
    }
  `}

  margin: 5px 5px 10px;
`

const Flow = styled.div`
  display: flex;
  justify-content: space-between;
`

const InlineFields = styled.div`
  display: flex;
  flex-grow: 2;
  align-items: baseline;

  & > div,
  & > input {
    margin-right: 10px;
  }

  .form-group {
    margin-bottom: 0;
  }
`

const EmptyActions = props => {
  const dispatch = useDispatch()
  const { node } = props
  const handleClick = useCallback(() => {
    dispatch(
      actions.focusCell({
        cellIdx: 0,
        node: node.id,
      })
    )
  }, [dispatch, node.id])

  return (
    <Tooltip title="SELECT NODE TYPE">
      <ActionHeaderButton type="button" onClick={handleClick}>
        <FAMemo icon={faCode} />
      </ActionHeaderButton>
    </Tooltip>
  )
}

const HeaderActions = props => {
  const { node } = props
  switch (node.type) {
    case "empty":
      return (
        <div>
          <EmptyActions {...props} />
        </div>
      )
    default:
      return (
        <div>
          <ActionMenu {...props} />
        </div>
      )
  }
}

const getStatusFilters = collapsed => {
  if (collapsed) return []
  return [cellStatusTypes.ok, cellStatusTypes.loading, cellStatusTypes.waiting]
}

const DynamiclySizedInput = props => {
  const { name, onChange } = props
  const inputValue = useFormState(name)
  return (
    <Input
      type="text"
      bordered={false}
      value={inputValue}
      onChange={onChange}
    />
  )
}

const NodeHeaderFields = props => {
  const { node, nodeIdx, prefix, toggleInputPane } = props
  const { collapsed } = node
  const dispatch = useDispatch()
  const nodeStatus = useNodeStatus(node)

  const handleChange = useCallback(
    evt => {
      const newValue = evt.target.value
      dispatch(
        actions.updateNodeField({
          nodeIdx,
          field: "data.label",
          value: newValue,
        })
      )
    },
    [dispatch, nodeIdx]
  )

  const filteredStatuses = getStatusFilters(collapsed)

  const currentIcon =
    node.icon || getIcon(node.type, Boolean(node.metadata?.actionId))
  const icon = nodeIconMap[currentIcon]

  const hidePopover = useCallback(() => setPopoverOpen(false), [])
  const [popoverOpen, setPopoverOpen] = useState(false)
  const handlePopoverOpenChange = useCallback(newOpen => {
    setPopoverOpen(newOpen)
  }, [])

  const PopoverContent = useMemo(
    props => {
      return (
        <>
          <ColorPicker nodeIdx={nodeIdx} afterChange={hidePopover} />
          <IconPicker nodeIdx={nodeIdx} selectedIcon={currentIcon} />
        </>
      )
    },
    [nodeIdx, currentIcon, hidePopover]
  )

  return (
    <Flow>
      <InlineFields>
        <Popover
          placement="right"
          trigger="click"
          content={PopoverContent}
          open={popoverOpen}
          onOpenChange={handlePopoverOpenChange}
        >
          <IconContainer color={node?.data?.color || "#0c2963"}>
            <FAMemo icon={icon} fixedWidth />
          </IconContainer>
        </Popover>
        <DynamiclySizedInput
          onChange={handleChange}
          name={prefix("data.label")}
        />
        <StatusIndicator
          status={nodeStatus}
          filteredStatuses={filteredStatuses}
        />
      </InlineFields>
      <HeaderActions
        node={node}
        prefix={prefix}
        nodeIdx={nodeIdx}
        dispatch={dispatch}
        toggleInputPane={toggleInputPane}
      />
    </Flow>
  )
}

const Action = props => {
  const { node, nodeIdx, selected, nodeCount } = props
  const path = `workflow.spec.nodes[${nodeIdx}]`
  const prefix = useCallback(name => `${path}.${name}`, [path])
  const isSelected = selected === node.id

  /*
   * This is sooooo stupid... All the hotkeys work fine except the "esc"
   * hotkey in cypress tests. Works fine in a normal browser session, but not
   * in cypress. Adding the following nonsense magically makes it so that it
   * works. Keys don't matter. Just so there is something defined here...
   * This is really dumb...
   */
  useHotkeys("shift+meta+esc+enter", () => {}, {}, [])

  const ref = useRef()
  const focusNode = useCallback(() => {
    ref.current && ref.current.scrollIntoViewIfNeeded(true)
  }, [])

  const render = Comp => {
    const childProps = {
      path,
      node,
      prefix,
      nodeIdx,
      focusNode,
      componentPath: `/components/${get(node, "metadata.componentId", "")}`,
    }
    const { collapsed, type } = node

    return (
      <ActionWrapper
        ref={ref}
        nodeCount={nodeCount}
        nodeIdx={nodeIdx}
        collapsed={collapsed || type === "empty"}
        isSelected={isSelected}
        nopad={node.type === "script"}
        title={
          <NodeHeaderFields
            node={node}
            path={path}
            prefix={prefix}
            nodeIdx={nodeIdx}
          />
        }
      >
        <Comp {...childProps} />
      </ActionWrapper>
    )
  }

  switch (node.type) {
    case "source":
    case "sink":
      return render(SourceNodeFields)
    case "download":
      return render(DownloadNodeFields)
    case "empty":
      return render(() => <></>)
    case "script":
      return render(ScriptCells)
    case "component":
      if (node.metadata.showCode) {
        return render(ScriptCells)
      } else {
        return render(NotebookComponentForm)
      }
    default:
      return null
  }
}

export default React.memo(Action)
