import React from "react"
import { Button, Tooltip } from "antd"
import styled, { css } from "styled-components"

import { useCurrentBreakpoint } from "../hooks"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const selectedStyles = css`
  color: #eee;
  background: #323744;
`

const StyledButton = styled(Button)`
  vertical-align: middle;
  border-radius: 0;
  color: #cccc;
  :hover {
    && {
      ${selectedStyles}
    }
  }

  ${props => props.selected && selectedStyles}
`

const checkCompactMode = breakpoint => {
  return ["xxs", "xs", "sm", "md"].includes(breakpoint)
}

const HeaderButton = props => {
  const { children, title, icon, ...rest } = props
  const breakpoint = useCurrentBreakpoint()
  const compactMode = checkCompactMode(breakpoint)

  if (compactMode && icon) {
    return (
      <Tooltip title={title}>
        <StyledButton
          type="text"
          icon={<FontAwesomeIcon icon={icon} />}
          {...rest}
        />
      </Tooltip>
    )
  }

  return (
    <StyledButton type="text" icon={<FontAwesomeIcon icon={icon} />} {...rest}>
      {title}
    </StyledButton>
  )
}

export default HeaderButton
