import React from "react"
import { Tag } from "antd"
import styled from "styled-components"
import { Link } from "react-router-dom"

import { useCurrentCustomer } from "@dbai/ui-staples"

const StyledLink = styled(Link)`
  display: block;
`

const FormatWorkflow = ({ value }) => {
  const [customer] = useCurrentCustomer()
  const { workflow, ...pkg } = value
  const workflowPath = `/${customer.normalizedName}/workflows/${workflow.id}`

  return (
    <>
      <StyledLink to={workflowPath}>{workflow.name}</StyledLink>
      <Tag>{pkg.image}</Tag>
    </>
  )
}

export default FormatWorkflow
