import React from "react"

import { AuthBoundary } from "@dbai/ui-staples"

import ComponentForm from "components/pages/Components/ComponentForm"

const EditComponent = props => {
  const { afterSubmit, activeTab } = props

  return (
    <AuthBoundary subject="components" action="UPDATE">
      <ComponentForm
        afterSubmit={afterSubmit}
        activeTab={activeTab}
        saveText="CREATE NEW REVISION" />
    </AuthBoundary>
  )
}

export default EditComponent
