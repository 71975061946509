import React from "react"
import { Tooltip, Row, Col, Space } from "antd"
import { useSelector, useDispatch } from "react-redux"
import {
  faGrid,
  faSquare,
  faChevronDoubleLeft,
  faChevronDoubleRight,
} from "@fortawesome/pro-solid-svg-icons"

import HeaderButton from "./HeaderButton"
import PublishSettings from "./PublishSettings"
import { actions } from "../reducers/appReducer"
import AppFiltersButton from "./AppFiltersButton"
import AppSettingsButton from "./AppSettingsButton"
import AppVariablesButton from "./AppVariablesButton"
import AppStatusIndicator from "./AppStatusIndicator"
import AppEndpointsButton from "./AppEndpointsButton"
import { useCurrentBreakpoint } from "../hooks"

const ToggleGridLines = () => {
  const dispatch = useDispatch()
  const showGridLines = useSelector(state => state.app.showPageGridLines)
  const toggleGridLines = () => {
    dispatch(actions.toggleShowPageGridLines())
  }
  const icon = showGridLines ? faSquare : faGrid
  return (
    <HeaderButton
      icon={icon}
      onClick={toggleGridLines}
      title={showGridLines ? "Hide Grid Lines" : "Show Grid Lines"}
    />
  )
}

const checkCompactMode = breakpoint => {
  return ["xxs", "xs", "sm", "md"].includes(breakpoint)
}

const AppControls = props => {
  const { showSider, appStatus, toggleShowSider } = props
  const breakpoint = useCurrentBreakpoint()
  const compactMode = checkCompactMode(breakpoint)
  return (
    <Row gutter={0} justify="space-between">
      <Col>
        <Space size={0}>
          <AppSettingsButton compact={compactMode} />
          <AppFiltersButton compact={compactMode} />
          <AppVariablesButton compact={compactMode} />
          <AppEndpointsButton compact={compactMode} />
          <PublishSettings compact={compactMode} />
          <ToggleGridLines />
        </Space>
      </Col>
      <Col>
        <AppStatusIndicator appStatus={appStatus} />
      </Col>
      <Col>
        <Tooltip
          title={showSider ? "Close Widget Drawer" : "Open Widget Drawer"}
        >
          <HeaderButton
            icon={showSider ? faChevronDoubleRight : faChevronDoubleLeft}
            onClick={toggleShowSider}
          />
        </Tooltip>
      </Col>
    </Row>
  )
}

export default AppControls
