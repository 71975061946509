import React from "react"
import styled from "styled-components"
import { Route, Switch, NavLink } from "react-router-dom"
import { Card, Col } from "antd"

import {
  Input,
  Content,
  ApiForm,
  GET_CUSTOMER,
  UPDATE_CUSTOMER,
} from "@dbai/ui-staples"

import Roles from "./Roles"
import Compute from "./Compute"
import DBSwagger from "./DBSwagger"
import { useCheckForDBUser } from "hooks"
import AccessControl from "./AccessControl"
import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"

const StackedNav = styled.nav`
  display: flex;
  flex-direction: column;
`

const StyledLink = styled(NavLink)`
  padding: 15px 20px;
  border-radius: 2px;

  &.active {
    font-weight: bold;
    &:hover {
      text-decoration: none;
    }
  }
`

const Overview = ({ customer }) => {
  return (
    <Card>
      <ApiForm
        initialState={customer}
        mutation={UPDATE_CUSTOMER}
        refetchQueries={GET_CUSTOMER}
      >
        <Input name="name" displayName="Organization Name" />
        <Input name="normalizedName" disabled noLabel />
      </ApiForm>
    </Card>
  )
}

const subRoutes = [
  { linkText: "Overview", route: "", Component: Overview },
  { linkText: "Compute", route: "/compute", Component: Compute, dbOnly: true },
  {
    linkText: "Access Control",
    route: "/access-control",
    Component: AccessControl,
  },
  { linkText: "Roles", route: "/roles", Component: Roles },
  {
    linkText: "API Server",
    route: "/api-server",
    Component: DBSwagger,
    dbOnly: true,
  },
]

const EditCustomer = ({ customer }) => {
  const isDbUser = useCheckForDBUser()
  const settingsPath = `/${customer.normalizedName}/settings`
  const baseRoute = `/:cname/settings`

  const routes = [
    { breadcrumbName: customer.name },
    { breadcrumbName: "Settings" },
  ]

  return (
    <>
      <PageHeader routes={routes} />

      <Content>
        <Col span={4}>
          <StackedNav>
            {subRoutes.map(({ linkText, route, dbOnly }) => {
              if (dbOnly && !isDbUser) return null
              return (
                <StyledLink key={route} to={`${settingsPath}${route}`} exact>
                  {linkText}
                </StyledLink>
              )
            })}
          </StackedNav>
        </Col>

        <Col span={20}>
          <Switch>
            {subRoutes.map(({ route, Component }) => {
              return (
                <Route key={route} path={`${baseRoute}${route}`} exact>
                  <Component customer={customer} />
                </Route>
              )
            })}
          </Switch>
        </Col>
      </Content>
    </>
  )
}

export default withCustomer(EditCustomer)
