import { gql } from "@apollo/client"

const getInstancesName = "GetInstances"
export const GET_INSTANCES = gql`
  query ${getInstancesName}($cname: String!) {
    customer(name: $cname) {
      id
      compute {
        id
        name
        type
        state
        region
        launchTime
        resources {
          capacity {
            cpu
            memory
          }
          allocatable {
            cpu
            memory
          }
        }
      }
    }
  }
`
GET_INSTANCES.name = getInstancesName

const createInstanceName = "CreateInstanceName"
export const CREATE_INSTANCE = gql`
  mutation ${createInstanceName}($customerId: Int!, $input: InstanceInput!) {
    createInstance(customerId: $customerId, input: $input) {
      id
      name
      type
      state
      region
      launchTime
    }
  }
`
CREATE_INSTANCE.name = createInstanceName

const stopInstanceName = "StopInstance"
export const STOP_INSTANCE = gql`
  mutation ${stopInstanceName}($customerId: Int!, $instanceId: String!) {
    stopInstance(customerId: $customerId, instanceId: $instanceId) {
      id
    }
  }
`
STOP_INSTANCE.name = stopInstanceName

const startInstanceName = "StartInstance"
export const START_INSTANCE = gql`
  mutation ${startInstanceName}($customerId: Int!, $instanceId: String!) {
    startInstance(customerId: $customerId, instanceId: $instanceId) {
      id
    }
  }
`
START_INSTANCE.name = stopInstanceName

const deleteInstanceName = "DeleteInstance"
export const DELETE_INSTANCE = gql`
  mutation ${deleteInstanceName}($customerId: Int!, $instanceId: String!) {
    deleteInstance(customerId: $customerId, instanceId: $instanceId) {
      id
    }
  }
`
DELETE_INSTANCE.name = deleteInstanceName
