import React, { useRef, useCallback, useState } from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { useParams } from "react-router-dom"

import { SchemaForm, GET_THEME, themeSchema } from "@dbai/applet"
import { PageSpinner, AuthBoundary, ErrorMessage } from "@dbai/ui-staples"

import SaveButton from "./SaveButton"
import PageHeader from "components/shared/PageHeader"
import withCustomer from "components/shared/withCustomer"

const StyledContainer = styled.div`
  padding: 16px;
  height: calc(100vh - 128px);
  overflow: auto;
`

const schema = {
  type: "object",
  default: {},
  properties: {
    name: {
      type: "string",
      title: "Name",
      metadata: {
        hideLabel: false,
      },
    },
    description: {
      type: "string",
      title: "Description",
    },
    "/spec": themeSchema,
  },
}

export const EditTheme = props => {
  const { customer } = props
  const { id, cname } = useParams()
  const saveData = useRef()
  const [themeName, setThemeName] = useState("")
  const [showSave, setShowSave] = useState(false)
  const [initialState, setInitialState] = useState({})

  const { error, loading } = useQuery(GET_THEME, {
    variables: { id, cname },
    fetchPolicy: "network-only",
    onCompleted: data => {
      setInitialState(data.customer.theme)
      setThemeName(data.customer.theme.name)
    },
  })

  const onFormChange = useCallback((data, errors) => {
    if (!errors.length) {
      saveData.current = data
      setShowSave(true)
    }
  }, [])

  const handleSave = useCallback(() => {
    setShowSave(false)
  }, [])

  if (loading) return <PageSpinner />
  if (error) return <ErrorMessage error={error} />

  const routes = [
    { breadcrumbName: customer.name },
    {
      breadcrumbName: "Themes",
      url: `/${customer.normalizedName}/apps/themes`,
    },
    { breadcrumbName: themeName },
  ]

  return (
    <AuthBoundary subject="apps" action="UPDATE">
      <PageHeader routes={routes} />
      <StyledContainer>
        {showSave ? (
          <SaveButton onSave={handleSave} dataRef={saveData} />
        ) : null}
        <SchemaForm
          hideSubmit
          schema={schema}
          initialState={initialState}
          onFormChange={onFormChange}
        />
      </StyledContainer>
    </AuthBoundary>
  )
}

export default withCustomer(EditTheme)
