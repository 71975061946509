import React, { useState, useEffect, useCallback } from "react"
import { Button } from "antd"
import { useParams } from "react-router"
import { useQuery, useMutation } from "@apollo/client"
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import config from "config"
import { Spinner, ErrorMessage } from "@dbai/ui-staples"
import {
  GET_ENDPOINT_SERVER_STATUS,
  START_ENDPOINT_SERVER,
  STOP_ENDPOINT_SERVER,
} from "queries"

const DBSwagger = props => {
  const { cname } = useParams()
  const [spec, setSpec] = useState({})
  const [running, setRunning] = useState(false)
  const { data, error, loading } = useQuery(GET_ENDPOINT_SERVER_STATUS, {
    variables: { cname },
  })
  const [startEndpointServer] = useMutation(START_ENDPOINT_SERVER)
  const [stopEndpointServer] = useMutation(STOP_ENDPOINT_SERVER)

  useEffect(() => {
    if (data?.endpointServerStatus?.running) {
      setRunning(true)
    } else {
      setRunning(false)
    }
  }, [data])

  const swaggerURL = `${config.api}endpoints/${cname}/openapi.json`
  const baseEndpointsOrigin = `${config.api}endpoints/${cname}`

  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("user/jwt")}`,
    }
    fetch(swaggerURL, {
      headers,
    })
      .then(res => {
        return res.json()
      })
      .then(setSpec)
      .catch(err => {
        console.error(err)
        setSpec({})
      })
  }, [swaggerURL, setSpec, running])

  const requestInterceptor = useCallback(
    req => {
      const url = new URL(req.url)
      req.url = `${baseEndpointsOrigin}${url.pathname}${url.search}`
      req.headers.Authorization = `Bearer ${localStorage.getItem("user/jwt")}`
      return req
    },
    [baseEndpointsOrigin]
  )

  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  return (
    <div>
      {running ? (
        <Button
          type="primary"
          danger
          onClick={() =>
            stopEndpointServer({ variables: { cname } }).then(() => {
              setTimeout(() => {
                setRunning(false)
              }, 2000)
            })
          }
        >
          Stop Server
        </Button>
      ) : (
        <Button
          type="primary"
          onClick={() =>
            startEndpointServer({ variables: { cname } }).then(() => {
              setTimeout(() => {
                // Give server a chance to start before pinging for spec
                setRunning(true)
              }, 2000)
            })
          }
        >
          Start Server
        </Button>
      )}
      <SwaggerUI spec={spec} requestInterceptor={requestInterceptor} />
    </div>
  )
}

export default DBSwagger
