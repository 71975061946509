import React, { useCallback, useState, useEffect } from "react"
import styled from "styled-components"

import PythonEditor from "components/shared/PythonEditor"

const BorderedDiv = styled.div`
  border: solid #dddddd 1px;
  border-radius: 1px;
`

/*
 * NOTE: Currently reusable components have only one cell, thus the
 * locked value here. I'm doing this like this to make it easier
 * to update in the future when/if we add multi-cell capabilities.
 */
const cellIdx = 0

const CodeEditor = props => {
  const { source, onChange, revisionId, activeTab } = props
  const [codeMirror, setCodeMirror] = useState(null)
  const initCodeMirror = useCallback(
    cm => {
      setCodeMirror(cm)
      cm.on("change", onChange)
      cm.setSize("100%", "calc(100vh - 206px)")
    },
    [onChange]
  )

  useEffect(() => {
    if (!codeMirror) return
    if (activeTab === "code") {
      setTimeout(() => codeMirror.refresh(), 50)
    }
  }, [activeTab, codeMirror])

  return (
    <BorderedDiv>
      <PythonEditor
        initialValue={source[cellIdx]}
        initCodeMirror={initCodeMirror}
        watch={revisionId}
      />
    </BorderedDiv>
  )
}

export default CodeEditor
