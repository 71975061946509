import React, { useState, useEffect } from "react"
import { Space } from "antd"
import { toast } from "react-toastify"
import { useMutation } from "@apollo/client"

import {
  DeleteButton,
  TableWithFolders,
  useCurrentCustomer,
} from "@dbai/ui-staples"
import { stringSorter } from "@dbai/tool-box"

import BadgeFormatter from "components/shared/BadgeFormatter"
import { GET_CUSTOMER_PACKAGES, DELETE_PACKAGE } from "queries"

const precedenceMap = {
  AVAILABLE: "success",
  BUILDING: "secondary",
  DEV: "danger",
}

const StatusBadge = props => {
  const { value } = props
  return <BadgeFormatter value={value} precedenceMap={precedenceMap} />
}

const WorkflowName = props => {
  const { value = {} } = props

  return value.name || ""
}

const sortByName = (a, b) => {
  return stringSorter("name", a.workflow, b.workflow)
}

const Delete = props => {
  const [priorError, setPriorError] = useState(null)
  const [customer] = useCurrentCustomer()
  const { row } = props
  const cname = customer.normalizedName

  const [mutate, { error }] = useMutation(DELETE_PACKAGE, {
    variables: { id: row.id, cname },
    refetchQueries: [{ query: GET_CUSTOMER_PACKAGES, variables: { cname } }],
  })

  useEffect(() => {
    if (error && !priorError) {
      toast.error(error.message)
      setPriorError(error)
    }
  }, [error, priorError, setPriorError])

  const handleDelete = () => {
    setPriorError(null)
    mutate()
  }

  return (
    <Space>
      <div className="btn-group">
        <DeleteButton onDelete={handleDelete} confirmDelete />
      </div>
    </Space>
  )
}

const columns = [
  {
    title: "Image",
    dataIndex: "image",
    key: "image",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: props => <StatusBadge {...props} />,
  },
  {
    title: "Workflow",
    dataIndex: "workflow",
    key: "workflow",
    sorter: sortByName,
    render: props => <WorkflowName {...props} />,
  },
  {
    title: "",
    dataIndex: "delete",
    key: "delete",
    render: props => <Delete {...props} />,
  },
]

const columnOrder = ["workflow", "image", "status", "createdAt", "delete"]

const PackagesTable = props => {
  const { packages = [] } = props

  return (
    <TableWithFolders
      columns={columns}
      folderType="packages"
      dataSource={packages}
      columnOrder={columnOrder}
    />
  )
}

export default PackagesTable
