import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"

import { ErrorBoundary } from "@dbai/ui-staples"

import WidgetWrapper from "./WidgetWrapper"
import widgetRegistry from "../widgetRegistry"
import { selectForm } from "../selectors/forms"
import { actions } from "../reducers/appReducer"
import { selectAppWidget } from "../selectors/app"
import { getWidgetFormId } from "../lib/widgetEditor"
import WidgetContextWrapper from "../WidgetContextWrapper"
import { useWidgetContext, useRegisteredWigetSpec } from "../hooks"

const WidgetWithContext = props => {
  const { cname, ...rest } = props
  return (
    <WidgetContextWrapper
      cname={cname}
      Widget={Widget}
      widgetRegistry={widgetRegistry}
    >
      <Widget {...rest} />
    </WidgetContextWrapper>
  )
}

const Widget = props => {
  const { pageId, widget, widgetMeta, widgetId, layout, ...rest } = props
  const { Component, ...restSpec } = useRegisteredWigetSpec(widget)
  return (
    <WidgetWrapper
      widget={widget}
      pageId={pageId}
      widgetId={widgetId}
      widgetMeta={widgetMeta}
    >
      <Component
        {...rest}
        {...restSpec}
        layout={layout}
        widget={widget}
        pageId={pageId}
        widgetId={widgetId}
        widgetMeta={widgetMeta}
      />
    </WidgetWrapper>
  )
}

/**
 * This hook ensures a form state is readily available for a given widget.
 * Forms should be automatically generated for a widget upon it being created.
 * When a widget is first loaded into an editbale app, the form instance will be
 * created through this hook.
 */
const useWidgetForm = widgetId => {
  const dispatch = useDispatch()
  const formId = getWidgetFormId(widgetId)
  const [loading, setLoading] = useState(false)
  const { editable, widgetSchema } = useWidgetContext()

  const form = useSelector(state => selectForm(state, { formId }))
  const formLoaded = useRef(Boolean(form))

  useEffect(() => {
    if (editable && !formLoaded.current) {
      setLoading(true)
      dispatch(actions.loadWidgetForm({ schema: widgetSchema, widgetId })).then(
        () => {
          setLoading(false)
          formLoaded.current = true
        }
      )
    }
  }, [widgetId, dispatch, form, editable, widgetSchema])

  return loading
}

const AppWidget = props => {
  const { widgetId, layout, editing, ...rest } = props

  useWidgetForm(widgetId)
  const widget = useSelector(state => selectAppWidget(state, { widgetId }))

  if (!widget || !widget.type) return null

  return (
    <ErrorBoundary>
      <Widget
        {...rest}
        layout={layout}
        widget={widget}
        editing={editing}
        widgetId={widget.id}
        widgetMeta={widget.meta}
      />
    </ErrorBoundary>
  )
}

AppWidget.propTypes = {
  widgetId: PropTypes.number.isRequired,
  layout: PropTypes.object.isRequired,
  movable: PropTypes.bool,
}

AppWidget.Core = React.memo(WidgetWithContext)
export default AppWidget
