import styled from "styled-components"

const SoloCard = styled.div`
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  a {
    text-align: center;
  }
`

export default SoloCard
