import React from "react"

import TensorForm from "./TensorForm"
import ColSpecForm from "./ColSpecForm"

const ModelInputForm = props => {
  const {
    signature: { inputs = [] },
  } = props

  if (inputs[0]?.type === "tensor") {
    return <TensorForm inputs={inputs} />
  }

  return <ColSpecForm inputs={inputs} />
}

export default ModelInputForm
