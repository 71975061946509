import { useState } from "react"
import { useQuery } from "@apollo/client"
import { GET_DATASET_NAME } from "queries"

import { useDatasetColumns } from "@dbai/applet"

const useDatasetOverview = (datasetId, customer, filteredColumnTypes) => {
  const variables = { id: parseInt(datasetId), customerId: customer.id }
  const [datasetName, setDatasetName] = useState("")
  const { loading: loadingName } = useQuery(GET_DATASET_NAME, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: results => {
      setDatasetName(results?.dataset?.name)
    },
  })

  const {
    columns,
    refetch,
    loading: loadingColumns,
  } = useDatasetColumns({
    cname: customer.normalizedName,
    datasetId,
    allowedTypes: filteredColumnTypes || [],
    includeLabels: true,
  })

  return {
    // error,
    refetch,
    columns,
    datasetName,
    loading: loadingColumns || loadingName,
  }
}

export default useDatasetOverview
