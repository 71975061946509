import datasetColumnSchema from "../datasetColumnSchema"
import highchartsProperties from "./highchartsProperties"

const heatmapSchema = {
  type: "object",
  title: "Data",
  default: {},
  properties: {
    inputZones: {
      title: "Data",
      type: "object",
      default: {},
      properties: {
        columns: {
          title: "Columns",
          type: "array",
          default: [],
          items: datasetColumnSchema,
          metadata: {
            component: "ColumnListInput",
            allowedTypes: ["numerical", "float", "int"],
            style: { height: "calc(100vh - 259px)" },
          },
        },
      },
    },
    highchart: {
      default: {},
      title: "Chart Options",
      type: "object",
      metadata: {
        sections: {
          title: {
            title: "Title",
            properties: [
              "title/align",
              "title/margin",
              "title/floating",
              "title/showTitle",
              "title/verticalAlign",
            ],
          },
          colorAxis: {
            title: "Color Axis",
            properties: ["colorAxis/min", "colorAxis/max", "colorAxis/stops"],
          },
          appearance: {
            title: "Appearance",
            properties: [
              "plotOptions/heatmap/borderRadius",
              "plotOptions/heatmap/borderColor",
              "plotOptions/heatmap/dataLabels/enabled",
              "plotOptions/heatmap/dataLabels/color",
              "chart/backgroundColor",
              "chart/height",
              "chart/width",
              "chart/showTitle",
              "chart/plotBackgroundColor",
              "chart/borderWidth",
              "chart/borderColor",
              "chart/plotBorderWidth",
              "chart/plotBorderColor",
              "chart/marginTop",
              "chart/marginRight",
              "chart/marginBottom",
              "chart/marginLeft",
            ],
          },
          legend: {
            title: "Legend",
            properties: [
              "legend/enabled",
              "legend/align",
              "legend/vertialAlign",
              "legend/layout",
              "legend/borderWidth",
              "legend/borderRadius",
              "legend/borderColor",
              "legend/verticalAlign",
              "legend/backgroundColor",
              "legend/itemStyle/color",
              "legend/itemStyle/fontWeight",
              "legend/itemStyle/fontFamily",
            ],
          },
          exporting: {
            title: "Exporting",
            properties: [
              "exporting/menuItems",
              "exporting/filename",
              "exporting/align",
              "exporting/verticalAlign",
            ],
          },
        },
      },
      properties: {
        ...highchartsProperties.title,
        ...highchartsProperties.chart,
        ...highchartsProperties.legend,
        ...highchartsProperties.exporting,
        "plotOptions/heatmap/borderRadius": {
          type: "number",
          minimum: 0,
          maximum: 10,
          default: 1,
          title: "Heatmap Cell Border Radius",
          metadata: {
            component: "SliderInput",
            showNumberInput: true,
          },
        },
        // TODO: check if this option is actually being used.
        // highcharts doesnt seem to have a series.borderColor or plotOptions.heatmap.borderColor option
        "plotOptions/heatmap/borderColor": {
          type: "string",
          default: "#ffffff",
          title: "Border Color",
          metadata: {
            component: "ColorPicker",
          },
        },
        "plotOptions/heatmap/dataLabels/enabled": {
          type: "boolean",
          default: true,
          title: "Show Data Labels",
        },
        "plotOptions/heatmap/dataLabels/color": {
          type: "string",
          default: "#000000",
          title: "Data Label Color",
          metadata: {
            component: "ColorPicker",
          },
        },
        "colorAxis/min": {
          type: "number",
          default: -1,
          title: "Min",
        },
        "colorAxis/max": {
          type: "number",
          default: 1,
          title: "Max",
        },
        "colorAxis/stops": {
          type: "array",
          title: "Stops",
          default: [
            {
              value: 0,
              color: "#9CD3F1",
            },
            {
              value: 0.5,
              color: "#ffffff",
            },
            {
              value: 1,
              color: "#F19C9C",
            },
          ],
          items: {
            type: "object",
            default: {},
            properties: {
              value: {
                type: "number",
                title: "Value",
                default: 0,
              },
              color: {
                type: "string",
                metadata: {
                  hideLabel: true,
                  component: "ColorPicker",
                },
              },
            },
          },
        },
        "legend/align": {
          type: "string",
          default: "right",
          title: "Horizontal Alignment",
          enum: ["left", "center", "right"],
          description:
            "The horizontal alignment of the legend box within the chart area.",
          metadata: {
            component: "Segmented",
            labels: ["Left", "Center", "Right"],
          },
        },
        "legend/verticalAlign": {
          type: "string",
          default: "top",
          enum: ["top", "middle", "bottom"],
          title: "Vertical Alignment",
          description:
            "The vertical alignment of the legend box within the chart area.",
          metadata: {
            component: "Segmented",
            labels: ["Top", "Middle", "Bottom"],
          },
        },
        "legend/layout": {
          type: "string",
          default: "vertical",
          title: "Layout",
          enum: ["horizontal", "vertical"],
          description: "The layout of the legend items.",
          metadata: {
            component: "Segmented",
            labels: ["Horizontal", "Vertical"],
          },
        },
      },
    },
  },
}

export default heatmapSchema
