import React from "react"
import { Row, Col } from "antd"
import { useParams } from "react-router"

import { CodeEditor } from "@dbai/ui-staples"

import config from "config"
import { getColumns } from "../helpers"

const sampleRequestCode = (cname, signature, revisionId) => {
  const columns = getColumns(signature)

  return `import requests
import json
import pandas as pd

data = {
  ${columns.map((column, idx) => `"${column}": [${idx}],`).join(" ")}
}

df = pd.DataFrame(data)

payload = df.to_json(orient='split')
headers = {"Authorization": f"Bearer ${localStorage.getItem(
    "user/jwt"
  )}", "content-type": "application/json"}
r = requests.post("${config.api}models/${cname}/${revisionId}/prediction",
      headers=headers, data=payload)

pd.Series(json.loads(r.content))
`
}

const SampleRequests = props => {
  const { cname } = useParams()
  const { signature, revisionId } = props

  if (revisionId === null) {
    return <div>Please Select A Revision</div>
  }

  return (
    <div>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <CodeEditor
            mode="python"
            options={{ readOnly: true }}
            initialValue={sampleRequestCode(cname, signature, revisionId)}
          />
        </Col>
      </Row>
    </div>
  )
}

export default SampleRequests
