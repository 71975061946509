import React, { useState } from "react"
import { Button } from "antd"
import { toast } from "react-toastify"
import { useParams } from "react-router"
import { useSelector } from "react-redux"
import { useMutation } from "@apollo/client"

import { TextArea } from "@dbai/ui-staples"

import { CREATE_WORKFLOW_COMMENT, GET_WORKFLOW_COMMENTS } from "queries"
import { selectCurrentCustomerNormalizedName } from "selectors/currentCustomer"

const refetchQueries = [GET_WORKFLOW_COMMENTS]
const CommentInput = props => {
  const { commentFloor } = props
  const { id } = useParams()
  const [content, setContent] = useState("")
  const cname = useSelector(selectCurrentCustomerNormalizedName)
  const variables = {
    cname,
    id,
  }

  const [mutation] = useMutation(CREATE_WORKFLOW_COMMENT, {
    refetchQueries,
    awaitRefetchQueries: true,
  })

  const handleSubmit = e => {
    e.stopPropagation()
    if (!content) return
    mutation({ variables: { ...variables, content } })
      .then(result => {
        setContent("")
        commentFloor.current && commentFloor.current.scrollIntoViewIfNeeded()
        return result
      })
      .catch(err => {
        console.error(err)
        toast.error("Failed to submit comment")
      })
  }

  const handleEnter = e => {
    if (e.keyCode === 13 && !e.shiftKey) {
      handleSubmit(e)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <TextArea
        noLabel
        name="content"
        value={content}
        onKeyUp={handleEnter}
        autoComplete="off"
        onChange={e => setContent(e.target.value)}
      />
      <Button type="primary" onClick={handleSubmit}>
        Comment
      </Button>
    </form>
  )
}

export default CommentInput
