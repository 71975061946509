import React from "react"
import { isArray } from "lodash"

import TreeNode from "./TreeNode"
import {
  getCurrentPaths,
  deconstructObjectProperties,
  getResolvedSchema,
} from "../../JSONSchemaForm/utils"

const ROOT_TREE_KEY = "0-0"

const BASE_TREE_NODE_PROPS = {
  removable: false,
  draggable: false,
}

const ARRAY_TREE_NODE_PROPS = {
  draggable: true,
  removable: true,
}

const SECTION_TREE_NODE_PROPS = {
  selectable: false,
}

const SCALAR_TREE_NODE_PROPS = {
  selectable: false,
}

const getNodeKey = (idx, prevKey) => {
  return idx >= 0 ? `${prevKey}-${idx}` : prevKey
}

const getSchemaTitle = (schema, parentSchema, { data, idx }) => {
  switch (true) {
    case Boolean(schema.metadata?.labelPath):
      return data?.[schema.metadata.labelPath]
    case parentSchema?.type === "array":
      return schema.title
        ? `${schema.title} ${parseInt(idx) + 1}`
        : `${parseInt(idx) + 1}`
    default:
      return schema.title ?? "___"
  }
}

const getTreeNodeTitle = (nodeProps, schema, nodeKey) => {
  const {
    idx,
    path,
    data,
    onSelect,
    onRemove,
    removable,
    schemaKey,
    searchValue,
    parentSchema,
  } = nodeProps
  const schemaIsHidden = schema?.metadata?.hidden
  const className = schemaIsHidden ? "hidden-tree-node" : null
  const title = getSchemaTitle(schema, parentSchema, { data, idx })
  return (
    <TreeNode
      idx={idx}
      path={path}
      data={data}
      title={title}
      schema={schema}
      nodeKey={nodeKey}
      parentPath={path}
      onSelect={onSelect}
      onRemove={onRemove}
      removable={removable}
      className={className}
      schemaKey={schemaKey}
      searchValue={searchValue}
    />
  )
}

const getArrayTreeNodeSpec = (nodeProps, schema, nodeKey, Title) => {
  const { data, schemaKey, path: _parentPath } = nodeProps
  const { items } = schema
  const { path } = getCurrentPaths(_parentPath, schemaKey)
  const children = data
    ?.reduce((a, d, i) => {
      const subschema = getTreeNodeFromSchema({
        ...nodeProps,
        path,
        data: d,
        idx: `${i}`,
        schema: items,
        prevKey: nodeKey,
        schemaKey: `[${i}]`,
        parentSchema: schema,
        ...ARRAY_TREE_NODE_PROPS,
      })
      return subschema ? [...a, subschema] : a
    }, [])
    .filter(Boolean)
  return {
    ...nodeProps,
    schema,
    key: nodeKey,
    title: Title,
    ...(children?.length ? { children } : {}),
  }
}

const getTreeSections = schemaSections => {
  return Object.entries(schemaSections || {}).reduce(
    (acc, [sectionKey, section]) => {
      if ([null, undefined, true].includes(section.tree?.enabled)) {
        return { ...acc, [sectionKey]: section }
      }
      return acc
    },
    {}
  )
}

const getObjectTreeNodesBySection = (nodeProps, schema, nodeKey, Title) => {
  const { data, schemaKey, path: _parentPath } = nodeProps
  const { path } = getCurrentPaths(_parentPath, schemaKey)

  const { sections } = schema.metadata || {}

  // only use sections that are not explicitely disabled in the tree view.
  // sections can be disabled by setting tree.enabled to false
  const treeSections = getTreeSections(schema.metadata?.sections || {})

  const { sectionedFields, sectionlessFields } = deconstructObjectProperties({
    ...schema,
    metadata: { ...(schema?.metadata || {}), sections: treeSections },
  })

  let index = 0
  const getTreeNodesForProperties = (properties, parentNodeKey) => {
    return properties
      ?.reduce((a, [propKey, prop], i) => {
        let idx = i
        if (!parentNodeKey) {
          idx = index
          index++
        }
        const subschema = getTreeNodeFromSchema({
          ...nodeProps,
          path,
          schema: prop,
          idx: `${idx}`,
          schemaKey: propKey,
          parentSchema: schema,
          data: data?.[propKey],
          prevKey: parentNodeKey || nodeKey,
          ...BASE_TREE_NODE_PROPS,
        })
        return subschema ? [...a, subschema] : a
      }, [])
      .filter(Boolean)
  }

  const sectionedTreeNodes = sectionedFields.reduce((a, [section, fields]) => {
    const sectionKey = getNodeKey(index, nodeKey)
    const sectionChildren = getTreeNodesForProperties(fields, sectionKey)
    // do not include section if no properties are included
    if (!sectionChildren.length) return a
    index++
    return [
      ...a,
      {
        key: sectionKey,
        children: sectionChildren,
        title: sections[section].title,
        ...SECTION_TREE_NODE_PROPS,
      },
    ]
  }, [])

  const sectionlessTreeNodes = getTreeNodesForProperties(sectionlessFields)
  const nestedTreeNodes = [...sectionedTreeNodes, ...sectionlessTreeNodes]
  return {
    ...nodeProps,
    schema,
    key: nodeKey,
    title: Title,
    ...(nestedTreeNodes?.length ? { children: nestedTreeNodes } : {}),
  }
}

const getObjectTreeNodeSpec = (nodeProps, schema, nodeKey, Title) => {
  const { data, schemaKey, path: _parentPath } = nodeProps
  const { properties = {} } = schema
  const { path } = getCurrentPaths(_parentPath, schemaKey)
  const { sectionType } = schema.metadata || {}
  if (sectionType === "tabs") {
    const children = Object.entries(properties)
    const nestedNodes = children
      .reduce((a, [propKey, prop], i) => {
        const subschema = getTreeNodeFromSchema({
          ...nodeProps,
          path,
          idx: `${i}`,
          schema: prop,
          prevKey: nodeKey,
          schemaKey: propKey,
          parentSchema: schema,
          data: data?.[propKey],
          ...BASE_TREE_NODE_PROPS,
        })
        return subschema ? [...a, subschema] : a
      }, [])
      .filter(Boolean)
    return {
      ...nodeProps,
      schema,
      key: nodeKey,
      title: Title,
      ...(nestedNodes?.length ? { children: nestedNodes } : {}),
    }
  }
  return getObjectTreeNodesBySection(nodeProps, schema, nodeKey, Title)
}

const getTreeNodeSpec = (nodeProps, schema, nodeKey, Title) => {
  const { type } = schema
  const nullable = schema.nullable && isArray(type)

  switch (true) {
    case (nullable && type.includes("array")) || type === "array": {
      return getArrayTreeNodeSpec({ ...nodeProps }, schema, nodeKey, Title)
    }
    case (nullable && type.includes("object")) || type === "object": {
      return getObjectTreeNodeSpec({ ...nodeProps }, schema, nodeKey, Title)
    }
    default:
      return {
        ...nodeProps,
        schema,
        key: nodeKey,
        title: Title,
        className: "scalar-tree-node",
        ...SCALAR_TREE_NODE_PROPS,
      }
  }
}

// this function recursively loops through a schema (with the root data) and returns it
// in a tree data structure that can be used by the antd Tree component
const getTreeNodeFromSchema = nodeProps => {
  const { idx, data, schema, rootSchema, prevKey = "0" } = nodeProps
  const nodeKey = getNodeKey(idx, prevKey)
  const safeSchema = getResolvedSchema(schema, rootSchema, data)
  const schemaIsHidden =
    safeSchema?.metadata?.hidden || safeSchema?.metadata?.treeViewHidden
  if (schemaIsHidden) return null
  const TreeTitle = getTreeNodeTitle(nodeProps, safeSchema, nodeKey)
  const nodeSpec = getTreeNodeSpec(nodeProps, safeSchema, nodeKey, TreeTitle)
  return { ...nodeSpec, title: TreeTitle }
}

export { ROOT_TREE_KEY, getSchemaTitle, getTreeNodeFromSchema }
