import { createSelector } from "reselect"

export const selectCurrentCustomer = state => state.currentCustomer.customer
export const selectCurrentCustomerNormalizedName = createSelector(
  selectCurrentCustomer,
  customer => customer.normalizedName
)
export const selectCurrentCustomerLegacy = createSelector(
  selectCurrentCustomer,
  customer => [customer]
)
