import { actions } from "../../reducers/appReducer"
import { addVariableObserver } from "./variableObserver"
import { observeYjsArray, removeArrayItem } from "./utils"

const handleInsert = (dispatch, variableMap) => {
  addVariableObserver(dispatch, variableMap)
  const variable = variableMap.toJSON()
  dispatch(actions.addAppVariable(variable))
}

const handleExternalChange =
  dispatch =>
  ({ delta, yArrayEvent, yArray }) => {
    if (delta.insert) {
      delta.insert.forEach(variableMap => {
        handleInsert(dispatch, variableMap)
      })
    }

    if (delta.delete) {
      yArrayEvent.changes.deleted.forEach(item => {
        removeArrayItem(item, id => {
          dispatch(actions.removeAppVariable({ id }))
        })
      })
    }

    if (delta.move) {
      // reset array in redux to ensure it is in the correct order
      dispatch(actions.setAppVariables(yArray.toJSON()))

      // when an array item is moved, it is removed and reinitialized in the moved to position. because of this, we
      // must add an observer to the reinitialized item
      delta.move.forEach(variableMap => {
        addVariableObserver(dispatch, variableMap)
      })
    }
  }

const handleLocalChange =
  dispatch =>
  ({ delta }) => {
    if (delta.insert) {
      delta.insert.forEach(variableMap => {
        addVariableObserver(dispatch, variableMap)
      })
    }

    if (delta.move) {
      // when an array item is moved, it is removed and reinitialized in the moved to position. because of this, we
      // must add an observer to the reinitialized item
      delta.move.forEach(variableMap => {
        addVariableObserver(dispatch, variableMap)
      })
    }
  }

const variablesObserver = (yDoc, dispatch) => {
  const specMap = yDoc.getMap("spec")
  const variablesArray = specMap.get("variables")

  observeYjsArray({
    yArray: variablesArray,
    onLocalChange: handleLocalChange(dispatch),
    onExternalChange: handleExternalChange(dispatch),
  })
}

export default variablesObserver
