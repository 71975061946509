import React, { useState, forwardRef } from "react"

import handles from "./handles"

// Custom resize handle for grid items
const ResizeHandle = forwardRef((props, ref) => {
  const { handleAxis, containerId } = props
  const [hovered, setHovered] = useState(false)
  const Handle = handles[handleAxis]

  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)

  if (!Handle) return null

  return (
    <Handle
      ref={ref}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isHovered={hovered}
      className={`${containerId} react-resizable-handle react-resizable-handle-${handleAxis} `}
      {...props}
    />
  )
})

export default React.memo(ResizeHandle)
