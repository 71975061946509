import React, { useState, useMemo } from "react"
import styled from "styled-components"
import { Tooltip, Row, Col, Button, Popconfirm } from "antd"
import { useQuery, useMutation } from "@apollo/client"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPencilAlt,
  faTrashAlt,
  faPlus,
  faUser,
  faKey,
} from "@fortawesome/pro-solid-svg-icons"

import {
  Input,
  DBTable,
  ApiForm,
  SidePane,
  INVITE_USER,
  PageSpinner,
  RemoteSelect,
  ErrorMessage,
  AuthBoundary,
  DELETE_INVITATION,
  UPDATE_USER_ROLES,
  GET_CUSTOMER_ROLES,
} from "@dbai/ui-staples"
import { stringSorter } from "@dbai/tool-box"

import {
  GET_IDENTITIES,
  DELETE_SERVICE_ACCOUNT,
  UPDATE_SERVICE_ACCOUNT_ROLES,
} from "queries"
import withCustomer from "components/shared/withCustomer"

const Badge = styled.span`
  margin-right: 3px;
`

const isUser = ident => ident.__typename === "User"
const isServiceAccount = ident => !isUser(ident)

const IdentityActions = props => {
  const { row, select, customerId, refetchQueries, deleteServiceAccount } =
    props

  const handleServiceAccountDeletion = () => {
    deleteServiceAccount({
      variables: {
        id: row.id,
        cid: customerId,
      },
      refetchQueries,
    })
  }

  return (
    <Button.Group>
      <Button
        onClick={() => select(row)}
        icon={<FontAwesomeIcon icon={faPencilAlt} className="action-icon" />}
      />
      {isServiceAccount(row) && (
        <Popconfirm
          okText="Yes"
          cancelText="No"
          placement="left"
          title="Delete Service Account"
          onConfirm={handleServiceAccountDeletion}
          description="Are you sure to delete this service account?"
        >
          <Button danger icon={<FontAwesomeIcon icon={faTrashAlt} />} />
        </Popconfirm>
      )}
    </Button.Group>
  )
}

const RoleSelect = ({ customer, valueField = "id", name = "roleIds" }) => {
  return (
    <RemoteSelect
      label="ROLES"
      name={name}
      query={GET_CUSTOMER_ROLES}
      collectionName="customer.roles"
      labelField="name"
      valueField={valueField}
      variables={{ cname: customer.normalizedName }}
      isMulti
    />
  )
}

const RolesForm = props => {
  const { selected, customer, afterSubmit, refetchQueries } = props
  const roles = selected ? selected.roles : []
  const roleIds = roles.map(r => r.id)
  const id = selected ? selected.id : 0
  const initialState = { roleIds, id, cid: customer.id }

  if (!selected) {
    return null
  }

  const isUser = selected?.__typename === "User"
  const mutation = isUser ? UPDATE_USER_ROLES : UPDATE_SERVICE_ACCOUNT_ROLES

  return (
    <ApiForm
      initialState={initialState}
      mutation={mutation}
      afterSubmit={afterSubmit}
      refetchQueries={refetchQueries}
    >
      <RoleSelect customer={customer} />
    </ApiForm>
  )
}

const InviteForm = props => {
  const { afterSubmit, customer, refetchQueries } = props

  return (
    <ApiForm
      initialState={{ email: "", roles: [], cid: customer.id }}
      mutation={INVITE_USER}
      afterSubmit={afterSubmit}
      refetchQueries={refetchQueries}
    >
      <Input name="email" />
      <RoleSelect customer={customer} valueField="name" name="roles" />
    </ApiForm>
  )
}

const BadgeList = props => {
  const { value } = props
  return value.map((v, idx) => (
    <Badge key={idx} className="badge badge-secondary">
      {v.name}
    </Badge>
  ))
}

const TypeFormatter = ({ value }) => {
  const icon = value === "User" ? faUser : faKey
  return (
    <Tooltip title={value}>
      <FontAwesomeIcon icon={icon} />
    </Tooltip>
  )
}

const DeleteInvitation = props => {
  const { value: invitationId, cid, refetchQueries } = props
  const [deleteInvitation] = useMutation(DELETE_INVITATION)

  const handleClick = () => {
    deleteInvitation({
      variables: {
        invitationId,
        cid,
      },
      refetchQueries,
    })
  }

  return (
    <Button className="btn-icon" type="danger" onClick={handleClick} danger>
      <FontAwesomeIcon icon={faTrashAlt} className="action-icon" fixedWidth />
    </Button>
  )
}

const InvitationsCard = props => {
  const { openInvite, invitations, customerId, refetchQueries } = props

  const columns = useMemo(
    () => [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: stringSorter("email"),
      },
      {
        title: "Roles",
        dataIndex: "roles",
        key: "roles",
        render: (_, row) => {
          return <BadgeList value={row.roles} />
        },
      },
      {
        title: "Accepted",
        dataIndex: "accepted",
        key: "accepted",
        render: (_, row) => {
          return row.accepted ? "YES" : "NO"
        },
        sorter: stringSorter("accepted"),
      },

      {
        title: "",
        key: "actions",
        render: (_, row) => {
          return (
            <DeleteInvitation
              value={row.id}
              cid={customerId}
              refetchQueries={refetchQueries}
            />
          )
        },
        width: 1,
      },
    ],
    [customerId, refetchQueries]
  )
  return (
    <DBTable
      title="INVITATIONS"
      extra={
        <Button
          type="primary"
          size="small"
          onClick={openInvite}
          icon={<FontAwesomeIcon icon={faPlus} />}
        >
          INVITE USER
        </Button>
      }
      dataSource={invitations}
      columns={columns}
      emptyText="No Invitations Found"
    />
  )
}

const UsersCard = props => {
  const { customerId, setSelected, identities = [], refetchQueries } = props
  const [deleteServiceAccount] = useMutation(DELETE_SERVICE_ACCOUNT)

  const columns = useMemo(
    () => [
      {
        title: "",
        dataIndex: "__typename",
        key: "typename",
        render: (_, row) => {
          return <TypeFormatter value={row.__typename} />
        },
        width: 30,
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: stringSorter("name"),
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        sorter: stringSorter("email"),
      },
      {
        title: "Roles",
        dataIndex: "roles",
        key: "roles",
        render: (_, row) => {
          return <BadgeList value={row.roles} />
        },
      },
      {
        title: "",
        key: "actions",
        render: (_, row) => {
          return (
            <IdentityActions
              row={row}
              select={setSelected}
              customerId={customerId}
              refetchQueries={refetchQueries}
              deleteServiceAccount={deleteServiceAccount}
            />
          )
        },
        width: 1,
      },
    ],
    [setSelected, customerId, refetchQueries, deleteServiceAccount]
  )

  const dataSource = useMemo(() => {
    return identities.map((ident, idx) => {
      return { ...ident, key: idx }
    })
  }, [identities])

  return (
    <DBTable
      title="Users"
      rowKey="key"
      columns={columns}
      dataSource={dataSource}
    />
  )
}

const Users = props => {
  const { customer } = props
  const [selected, setSelected] = useState(null)
  const [isInviteOpen, setIsInviteOpen] = useState(null)
  const deselect = () => setSelected(null)

  const { error, loading, data } = useQuery(GET_IDENTITIES, {
    variables: { cname: customer.normalizedName },
  })

  const openInvite = () => setIsInviteOpen(true)
  const closeInvite = () => setIsInviteOpen(false)

  if (error) return <ErrorMessage error={error} />
  if (loading) return <PageSpinner />

  const refetchQueries = [
    {
      query: GET_IDENTITIES,
      variables: { cname: customer.normalizedName },
    },
  ]

  return (
    <AuthBoundary subject="users" action="LIST">
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <UsersCard
            customerId={customer.id}
            setSelected={setSelected}
            refetchQueries={refetchQueries}
            identities={data?.customer?.identities}
          />
        </Col>
        <Col span={24}>
          <InvitationsCard
            openInvite={openInvite}
            customerId={customer.id}
            refetchQueries={refetchQueries}
            invitations={data?.customer?.invitations}
          />
        </Col>
      </Row>
      <SidePane
        title="Edit User"
        isVisible={!!selected}
        width="30vw"
        onCloseClicked={deselect}
      >
        <RolesForm
          customer={customer}
          selected={selected}
          afterSubmit={() => setSelected(null)}
          refetchQueries={refetchQueries}
        />
      </SidePane>
      <SidePane
        title="Invite User"
        isVisible={isInviteOpen}
        width="30vw"
        onCloseClicked={closeInvite}
      >
        <InviteForm
          afterSubmit={closeInvite}
          customer={customer}
          refetchQueries={refetchQueries}
        />
      </SidePane>
    </AuthBoundary>
  )
}

export default withCustomer(Users)
