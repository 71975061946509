import {
  useEffect,
  useRef,
  useMemo,
  useState,
  useContext,
  useCallback,
} from "react"
import { useSelector } from "react-redux"
import { get, capitalize, debounce, isEqual, isArray } from "lodash"

import getQuery from "../../lib/getQuery"
import { useWidgetContext } from "../../hooks"
import SchemaFormContext from "../SchemaFormContext"
import { getWidgetFormId } from "../../lib/widgetEditor"
import { selectEditingWidgetId } from "../../selectors/app"
import { selectFormValue, selectFormSchema } from "../../selectors/forms"
import {
  normalizeFormData,
  normalizeFormDataWithSchemaPaths,
} from "../../lib/formData"
import {
  getValueFromEvent,
  withoutLeadingDot,
  getResolvedSchema,
  orderSchemaProperties,
  getSelectOptionsForSchema,
} from "../utils"

const convertDataPathToInstancePath = dataPath => {
  return dataPath
    .replace(/\['([^']+)'\]/g, "$1")
    .replace(/\./g, "/")
    .replace(/\[(\d+)\]/g, "/$1")
}

const useFieldErrors = instancePath => {
  const errors = useFormErrors()
  const fieldErrors = useMemo(() => {
    return errors.filter(({ params, dataPath }) => {
      const { missingProperty } = params
      if (!missingProperty) return false
      const fullDataPath = dataPath
        ? `${dataPath}.${missingProperty}`
        : missingProperty
      const fullInstancePath = convertDataPathToInstancePath(fullDataPath)
      return fullInstancePath === instancePath
    })
  }, [errors, instancePath])

  return fieldErrors || []
}

const useValidation = ({ value, instancePath, readonly, validateFirst }) => {
  const [touched, setTouched] = useState(readonly || validateFirst)
  const { showErrors, resetCounter } = useFormContext()
  const resetCounterRef = useRef(resetCounter)
  const errors = useFieldErrors(instancePath)

  useEffect(() => {
    // if show errors changes from true to false, then we want to reset 'touched'
    if (resetCounter !== resetCounterRef.current) {
      resetCounterRef.current = resetCounter
      setTouched(false)
    }
  }, [resetCounter])

  const shouldShowErrors = (touched || showErrors) && errors.length

  const message = shouldShowErrors ? capitalize(errors[0].message) : null
  const status = shouldShowErrors ? "error" : null
  return [status, message, setTouched]
}

const useFormContext = () => {
  return useContext(SchemaFormContext)
}

export const useWidgetEditorSideEffects = () => {
  const type = useFormData("type")
  const { widgetRegistry } = useWidgetContext()
  const currentWidgetSpec = widgetRegistry[type]
  return currentWidgetSpec?.EditorSideEffects
}

const useFormData = (name, def) => {
  const { formId } = useFormContext()
  const safeName = withoutLeadingDot(name || "")
  return useSelector(
    state => selectFormValue(state, { name: safeName, formId, def }),
    isEqual
  )
}

const useSavedFormValueSpec = (name, def) => {
  const { formId: id } = useFormContext()
  const safeName = withoutLeadingDot(name || "")
  return useSelector(state => {
    const normalizedData = normalizeFormDataWithSchemaPaths(
      state.forms[id]?.data
    )
    return name ? get(normalizedData, safeName, def || {}) : normalizedData
  }, isEqual)
}

const getNormalizedFormValue = (state, name, formId, def) => {
  const safeName = withoutLeadingDot(name || "")
  const normalizedData = normalizeFormData(state.forms[formId]?.data)
  if (name === "") {
    return normalizedData
  }
  return name ? get(normalizedData, safeName, def) : null
}

const useSavedFormValue = (name, def) => {
  const { formId: id } = useFormContext()
  const data = useSelector(
    state => getNormalizedFormValue(state, name, id, def),
    isEqual
  )
  return data
}

const useFormErrors = () => {
  const { formId: id } = useFormContext()
  const formErrors = useSelector(state => state.forms[id]?.errors)
  return formErrors || []
}

const useFormSchema = () => {
  const { formId: id } = useFormContext()
  return useSelector(state => selectFormSchema(state, { formId: id }), isEqual)
}

const useEditingWidgetSchema = () => {
  const { widgetSchema } = useWidgetContext()
  const widgetId = useSelector(selectEditingWidgetId)
  const formId = getWidgetFormId(widgetId)
  const editingWidgetSchema = useSelector(
    state => selectFormSchema(state, { formId }),
    isEqual
  )
  return editingWidgetSchema ?? widgetSchema
}

const useFormSchemaWithId = id => {
  const form = useSelector(state => state.forms[id])
  return get(form, "schema")
}

const useFormDataWithId = id => {
  const form = useSelector(state => state.forms[id])
  return get(form, "data")
}

const useWidgetQuery = (schema, parentSchema, parentSchemaKey) => {
  const widget = useSavedFormValue("")
  const widgetSchemaData = useFormData()
  const rootSchema = useFormSchema()
  const { widgetRegistry } = useWidgetContext()
  return getQuery({
    widget,
    schema,
    rootSchema,
    parentSchema,
    widgetRegistry,
    parentSchemaKey,
    widgetSchemaData,
  })
}

const swapPathSchemaKey = (path, newKey) => {
  const pathWithoutSchemaKey = path
    .split(".")
    .reverse()
    .splice(1)
    .reverse()
    .join(".")
  return `${pathWithoutSchemaKey}.${newKey}`
}

const INVALID_PATH = "INVALID_PATH"
const usePlaceholder = (schema, path) => {
  const { metadata = {} } = schema
  const { fieldProps, placeholderSource } = metadata
  const { placeholder: fieldPropsPlaceholder } = fieldProps || {}
  const placeholderPath = placeholderSource
    ? swapPathSchemaKey(path, placeholderSource)
    : INVALID_PATH
  const value = useFormData(placeholderPath)
  return [null, undefined].includes(value) ? fieldPropsPlaceholder : value
}

const useResolvedSchema = (schema, value) => {
  const rootSchema = useFormSchema()
  const resolvedSchema = useMemo(() => {
    return getResolvedSchema(schema, rootSchema, value)
  }, [schema, rootSchema, value])
  const [currentSchema, setCurrentSchema] = useState(resolvedSchema)

  if (!isEqual(resolvedSchema, currentSchema)) {
    setCurrentSchema(resolvedSchema)
    return resolvedSchema
  }
  return currentSchema
}

const useSchemaWithResolvedItems = (schema, value) => {
  const resolvedSubSchema = useResolvedSchema(schema.items, value)
  return useMemo(() => {
    const orderedItemProperties = orderSchemaProperties(resolvedSubSchema)
    return {
      ...schema,
      items: {
        ...resolvedSubSchema,
        properties: orderedItemProperties,
      },
    }
  }, [resolvedSubSchema, schema])
}

const filterNulls = option => option.label !== null

const getLabel = (labelPath, option) => {
  if (isArray(labelPath)) {
    return labelPath.reduce((final, path) => {
      if (final) return final
      return get(option, path)
    }, null)
  }
  return get(option, labelPath)
}

function splitPath(path) {
  // Split the path by slashes first
  let parts = path.split(".")

  // Further split each part by array indices, but keep the indices as separate parts
  return parts.flatMap(part => {
    return part
      .split(/(\[\d+\])/)
      .filter(Boolean)
      .map(segment => {
        return segment.replace(/[\[\]]/g, "") // Remove brackets from array indices
      })
  })
}

// Resolves a dynamic path based on a parent path and a relative list path.
const resolveDynamicPath = (parentPath, listPath) => {
  let pathArray = splitPath(parentPath)

  // Process each segment of the list path for relative navigation
  listPath.split("/").forEach(segment => {
    if (segment === "..") {
      // Navigate up one level in the path hierarchy
      pathArray.pop()
    } else {
      // Append the segment for direct or nested path navigation
      pathArray.push(segment)
    }
  })

  return pathArray
}

const useFormFieldDropdownOptions = (schema, parentName) => {
  const { metadata = {} } = schema
  const { listPath, valuePath, relativeListPath, labelPath } = metadata
  const { formId } = useFormContext()

  // Dynamic resolution of the list path based on the schema's relative or absolute path
  const listData = useSelector(state => {
    if (relativeListPath) {
      // Resolve dynamic path for relative list paths
      const name = listPath.startsWith("..")
        ? resolveDynamicPath(parentName, listPath).join(".")
        : listPath
      return selectFormValue(state, { name, formId })
    }
    // Fetch normalized data for absolute list paths
    return getNormalizedFormValue(state, listPath, formId, null)
  }, isEqual)

  // Map and filter the options based on the schema metadata
  if (!listData) return getSelectOptionsForSchema(schema)
  return listData
    .map(option => {
      // Extract and format label and value for each dropdown option
      const label = getLabel(labelPath, option)
      const value = String(get(option, valuePath))
      return { label, value }
    })
    .filter(filterNulls)
}

// this hook is used to debounce the onChange event and provide a local state
// value. This is useful for fields that have a lot of onChange events.
const useLocalFormFieldControls = (_value, onChange, waitTime) => {
  const [value, setValue] = useState(_value)
  const shouldSync = useRef(true)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceStateUpdate = useCallback(
    debounce(v => {
      onChange && onChange(v)
    }, waitTime || 50),
    [onChange, waitTime]
  )

  useEffect(() => {
    if (shouldSync.current) {
      setValue(_value)
    }
    shouldSync.current = true
  }, [_value])

  const handleChange = useCallback(
    e => {
      debounceStateUpdate.cancel()
      // cancel any sync events to prevent the local state from being reset
      shouldSync.current = false
      const value = getValueFromEvent(e)
      setValue(value)
      debounceStateUpdate(value)
    },
    [debounceStateUpdate]
  )

  return [value, handleChange]
}

export {
  useFormData,
  useValidation,
  useFormSchema,
  useFormErrors,
  useFormContext,
  usePlaceholder,
  useWidgetQuery,
  useFormDataWithId,
  useResolvedSchema,
  useFormSchemaWithId,
  useSavedFormValue,
  useEditingWidgetSchema,
  useLocalFormFieldControls,
  useSchemaWithResolvedItems,
  useFormFieldDropdownOptions,
  useSavedFormValueSpec,
}
