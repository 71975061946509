import {
  SPACE_UNICODE,
  DBAI_NODE_ID_ATTR,
  APP_VARIABLE_ID_ATTR,
  APP_VARIABLE_CLASSNAME,
} from "../../lib/tinyMce"

const KEY_ENTER = 13
const KEY_DELETE = 46
const KEY_BACKSPACE = 8

export function updateVariableValues(doc, appVariables) {
  if (!doc) return
  doc.querySelectorAll(`.${APP_VARIABLE_CLASSNAME}`).forEach(element => {
    var variableId = element.getAttribute(APP_VARIABLE_ID_ATTR)
    var variable = appVariables.find(v => v.id === variableId)
    if (variable) {
      element.innerText = variable.value
    }
  })
}

const configurableStyles = [
  "--conditional-display",
  "--conditional-color",
  "--conditional-background-color",
]

function setStylesOnElement(element, styles) {
  const styleEntries = Object.entries(styles)
  if (!styleEntries.length) {
    configurableStyles.forEach(style => {
      element.style.removeProperty(style)
    })
  }
  for (let [property, value] of styleEntries) {
    element.style.setProperty(property, value)
  }
}

export function updateNodeStyles(doc, nodeId, styles) {
  if (!doc) return
  Array.from(doc.querySelectorAll(`[${DBAI_NODE_ID_ATTR}]`)).forEach(
    element => {
      var elementNodeId = element.getAttribute(DBAI_NODE_ID_ATTR)
      if (nodeId === elementNodeId) {
        setStylesOnElement(element, styles)
      }
    }
  )
}

export function updateNodeText(doc, nodeId, text) {
  if (!doc) return
  Array.from(doc.querySelectorAll(`[${DBAI_NODE_ID_ATTR}]`)).forEach(
    element => {
      var elementNodeId = element.getAttribute(DBAI_NODE_ID_ATTR)
      if (nodeId === elementNodeId) {
        if (text !== undefined) {
          element.innerHTML = text || SPACE_UNICODE
        }
      }
    }
  )
}

export function handleNodeSelection(editor, classname) {
  return function (e) {
    const selectedNode = e.element
    if (selectedNode.classList.contains(classname)) {
      editor.selection.select(selectedNode)
    } else {
      const parentNodes = e.parents
      const selectedParentNode = parentNodes.find(node => {
        return node.classList.contains(classname)
      })
      if (selectedParentNode) {
        editor.selection.select(selectedParentNode)
      }
    }
  }
}

// TODO: This is incomplete. Deleting an app variable doesnt delete the entire node
// This handles deleting app variable elements
export function handleRemoveNode(editor, classname) {
  return function (e) {
    if (e.keyCode === KEY_BACKSPACE || e.keyCode === KEY_DELETE) {
      const startNode = editor.selection.getStart()
      const endNode = editor.selection.getEnd()
      if (
        startNode.classList.contains(classname) ||
        endNode.classList.contains(classname)
      ) {
        e.preventDefault()
        editor.dom.remove(
          startNode.classList.contains(classname) ? startNode : endNode
        )
        editor.undoManager.add() // Add undo level after deletion
      }
    }
  }
}

// This prevents the user from adding a new line inside an app variable tag
export function preventBreakingNode(editor, classname) {
  return function (e) {
    const node = editor.selection.getNode()
    if (e.keyCode === KEY_ENTER && node.classList.contains(classname)) {
      e.preventDefault()
      // Move cursor to a new line below the app variable
      const newNode = editor.dom.create("p", null, '<br data-mce-bogus="1">') // Create a new paragraph node
      editor.dom.insertAfter(newNode, node.parentNode) // Insert the new paragraph after the app variable's div
      editor.selection.setCursorLocation(newNode) // Set the cursor into the new paragraph
    }
  }
}
