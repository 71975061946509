import React, { useCallback } from "react"
import styled from "styled-components"
import { Button as AntButton } from "antd"
import { useDispatch } from "react-redux"
import { useApolloClient } from "@apollo/client"

import { signout } from "@dbai/ui-staples"

import { useWidgetContext } from "../../hooks"
import { actions } from "../../reducers/appReducer"

const StyledButton = styled(AntButton)`
  ${props =>
    props.fullHeight
      ? `
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
      : null}
`

const Button = props => {
  const { widget = {} } = props
  const {
    text,
    href,
    block,
    shape,
    ghost,
    style,
    danger,
    endpoints,
    fullHeight,
    buttonType,
    clickActions = [],
  } = widget.options || {}

  const dispatch = useDispatch()
  const client = useApolloClient()
  const { appConfig } = useWidgetContext()

  const handleClick = useCallback(() => {
    if (clickActions.includes("triggerEndpoint")) {
      endpoints?.forEach(endpointId => {
        dispatch(
          actions.triggerEndpoint({ id: endpointId, apiUrl: appConfig.api })
        )
      })
    }
    if (clickActions.includes("clearFilters")) {
      dispatch(actions.clearSessionFilters())
    }
    if (clickActions.includes("logout")) {
      signout(client)
    }
  }, [clickActions, dispatch, endpoints, appConfig.api, client])

  return (
    <StyledButton
      href={href}
      style={style}
      block={block}
      shape={shape}
      ghost={ghost}
      danger={danger}
      type={buttonType}
      onClick={handleClick}
      fullHeight={fullHeight}
    >
      {text}
    </StyledButton>
  )
}

export default React.memo(Button)
