import { actions } from "../../reducers/appReducer"
import { addEndpointObserver } from "./endpointObserver"
import { observeYjsArray, removeArrayItem } from "./utils"

const handleInsert = (dispatch, endpointMap) => {
  addEndpointObserver(dispatch, endpointMap)
  const endpoint = endpointMap.toJSON()
  dispatch(actions.addAppEndpoint(endpoint))
}

const handleExternalChange =
  dispatch =>
  ({ yArrayEvent, delta }) => {
    if (delta.insert) {
      delta.insert.forEach(item => {
        handleInsert(dispatch, item)
      })
    }

    if (delta.delete) {
      yArrayEvent.changes.deleted.forEach(item => {
        removeArrayItem(item, endpointId => {
          dispatch(actions.removeAppEndpoint({ endpointId }))
        })
      })
    }
  }

const handleLocalChange =
  dispatch =>
  ({ delta }) => {
    if (delta.insert) {
      delta.insert.forEach(item => addEndpointObserver(dispatch, item))
    }
  }

const endpointsObserver = (yDoc, dispatch) => {
  const specMap = yDoc.getMap("spec")
  const endpointsArray = specMap.get("endpoints")

  observeYjsArray({
    yArray: endpointsArray,
    onLocalChange: handleLocalChange(dispatch),
    onExternalChange: handleExternalChange(dispatch),
  })
}

export default endpointsObserver
