import React, { useMemo, useCallback } from "react"
import styled from "styled-components"
import { Row, List, Col, Button } from "antd"
import { useDispatch, useSelector } from "react-redux"

import { uuidv4 } from "@dbai/tool-box"

import { actions } from "../reducers/appReducer"
import { selectAllAppEndpoints } from "../selectors/app"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons"

const StyledRow = styled(Row)`
  padding: 0 16px;
  width: 100%;
`

const AppEndpointsList = props => {
  const { onEdit } = props
  const appEndpoints = useSelector(selectAllAppEndpoints)
  const dispatch = useDispatch()

  const data = useMemo(() => {
    return appEndpoints?.map(e => ({
      title: e.name || "Untitled",
      id: e.id,
    }))
  }, [appEndpoints])

  const handleEdit = useCallback(
    endpointId => {
      return () => {
        onEdit(endpointId)
      }
    },
    [onEdit]
  )
  const handleRemove = useCallback(
    endpointId => {
      return () => {
        dispatch(actions.removeAppEndpointWithSync({ endpointId }))
      }
    },
    [dispatch]
  )

  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item, index) => (
        <List.Item
          actions={[
            <Button type="link" onClick={handleEdit(item.id)}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>,
            <Button type="link" onClick={handleRemove(item.id)}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>,
          ]}
        >
          <List.Item.Meta
            title={
              <Button type="link" onClick={handleEdit(item.id)}>
                {item.title}
              </Button>
            }
          />
        </List.Item>
      )}
    />
  )
}
const AppEndpointsForm = props => {
  const dispatch = useDispatch()

  const handleEdit = useCallback(
    endpointId => {
      dispatch(actions.toggleShowEndpoint({ endpointId }))
    },
    [dispatch]
  )

  const addAppEndpoint = useCallback(() => {
    const id = uuidv4()
    dispatch(actions.addAppEndpointWithSync({ id, name: "Untitled" }))
    handleEdit(id)
  }, [dispatch, handleEdit])

  return (
    <StyledRow gutter={[0, 16]}>
      <Col span={24}>
        <AppEndpointsList onEdit={handleEdit} />
      </Col>
      <Col span={24}>
        <Button block onClick={addAppEndpoint}>
          <FontAwesomeIcon icon={faPlus} /> Add New Endpoint
        </Button>
      </Col>
    </StyledRow>
  )
}

export default AppEndpointsForm
