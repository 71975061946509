import React, { useState, useMemo, useCallback, useEffect } from "react"
import { debounce } from "lodash"
import { useMutation } from "@apollo/client"
import { useParams } from "react-router"

import { SchemaForm } from "@dbai/applet"

import columnMetadataSchema from "./schema"
import { UPDATE_DATASET_META } from "queries"
import useDatasetOverview from "../useDatasetOverview"

const handleUpdateData = debounce(
  ({
    id,
    data,
    refetch,
    columns,
    customer,
    datasetName,
    setUpdateData,
    updateTableColumnMeta,
  }) => {
    const columnData = data.map(data => {
      const { datasetId, type, ...rest } = data
      return rest
    })

    updateTableColumnMeta({
      variables: {
        id,
        name: datasetName,
        customerId: customer.id,
        tableMeta: {
          columns: columnData,
          // save computed columns as well
          computedColumns: columns
            .filter(({ __typename }) => __typename === "ComputedColumnSpec")
            .map(({ __typename, type, ...column }) => column),
        },
      },
    }).then(refetch)

    setUpdateData(false)
  },
  200
)

const ColumnMetadataTable = props => {
  const { customer } = props
  const { id } = useParams()
  const [updateData, setUpdateData] = useState(false)
  const [updateTableColumnMeta] = useMutation(UPDATE_DATASET_META)
  const { refetch, loading, columns, datasetName } = useDatasetOverview(
    id,
    customer,
    ["string", "float", "datetime"]
  )

  const dataSource = useMemo(() => {
    return (
      columns
        // .filter(c => c.__typename !== "ComputedColumnSpec")
        .map(({ __typename, ...column }) => ({ ...column, datasetId: id }))
    )
  }, [columns, id])

  const handleFormChange = useCallback(data => {
    setUpdateData(data)
  }, [])

  useEffect(() => {
    if (updateData) {
      handleUpdateData({
        id,
        data: updateData,
        columns,
        refetch,
        customer,
        datasetName,
        setUpdateData,
        updateTableColumnMeta,
      })
    }
  }, [
    id,
    columns,
    refetch,
    customer,
    updateData,
    datasetName,
    updateTableColumnMeta,
  ])

  if (loading || !columns?.length) return null
  return (
    <div>
      <SchemaForm
        hideSave
        cname={customer.normalizedName}
        schema={columnMetadataSchema}
        initialState={dataSource}
        onFormChange={handleFormChange}
      />
    </div>
  )
}

export default ColumnMetadataTable
