import React from "react"
import Modal from "react-modal"
import styled from "styled-components"

import Card from "./Card"

const StyledModal = styled(Modal)`
  position: absolute;
  width: 600px;
  height: 20%;
  top: 45%;
  left: 50%;
  margin-top: -50px;
  margin-left: -300px;
`

export default props => {
  const { children, isOpen, onRequestClose, modalProps, ...rest } = props
  return (
    <StyledModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{ overlay: { zIndex: 1002 } }}
      {...modalProps}
    >
      <Card {...rest}>{children}</Card>
    </StyledModal>
  )
}
