import React from "react"

import JSONSchemaForm from "./JSONSchemaForm"
import widgetRegistry from "./widgetRegistry"
import WidgetContextWrapper from "./WidgetContextWrapper"
import JSONSchemaField from "./JSONSchemaForm/JSONSchemaField"

const FormWithContext = React.forwardRef((props, ref) => {
  const { cname, customerId, ...rest } = props
  return (
    <WidgetContextWrapper
      cname={cname}
      customerId={customerId}
      widgetRegistry={widgetRegistry}
    >
      <JSONSchemaForm ref={ref} {...rest} />
    </WidgetContextWrapper>
  )
})

FormWithContext.Field = JSONSchemaField
FormWithContext.Provider = JSONSchemaForm.Provider
export default FormWithContext
