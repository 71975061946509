import { gql } from "@apollo/client"

export const GET_USER_PREFERENCES = gql`
  query UserPreferences {
    userPreferences {
      theme
      fontSize
    }
  }
`

export const GET_ME = gql`
  query Me {
    me {
      id
      preferences {
        fontSize
        theme
      }
      customers {
        id
        normalizedName
        roles {
          id
          name
          policies {
            id
            name
            rule {
              actions
              effect
              subjects
            }
          }
        }
      }
    }
  }
`

export const PING_ME = gql`
  query PingMe {
    me {
      id
    }
  }
`

export const GET_CURRENT_USER = gql`
  {
    currentUser @client {
      id
      name
      email
    }
  }
`

export const SET_CURRENT_USER = gql`
  mutation SetCurrentUser($jwt: String!) {
    setCurrentUser(jwt: $jwt) @client
  }
`

export const ACCEPT_INVITE = gql`
  mutation AcceptInvite($token: String!) {
    acceptInvite(token: $token) {
      resetPasswordToken
      normalizedName
      email
    }
  }
`

export const GET_PREFS = gql`
  query UserPreferences {
    me {
      id
      preferences {
        theme
        fontSize
      }
    }
  }
`

export const UPDATE_PREFS = gql`
  mutation UpdateCurrentUserPreferences($theme: String!, $fontSize: String!) {
    updateCurrentUserPreferences(theme: $theme, fontSize: $fontSize) {
      id
      preferences {
        theme
        fontSize
      }
    }
  }
`

export const GET_MY_PROFILE_DATA = gql`
  query GetMe {
    me {
      id
      email
      name
    }
  }
`

export const UPDATE_USER = gql`
  mutation UpdateCurrentUser($name: String!, $email: String!) {
    updateCurrentUser(name: $name, email: $email) {
      id
      email
      name
    }
  }
`

export const GET_ROLES = gql`
  query GetRoles($cid: Int!, $roleType: String) {
    roles(customerId: $cid, roleType: $roleType) {
      id
      name
    }
  }
`

export const UPDATE_USER_ROLES = gql`
  mutation UpdateUserRoles($cid: Int!, $id: Int!, $roleIds: [Int]!) {
    updateUserRoles(userId: $id, customerId: $cid, roleIds: $roleIds) {
      id
      name
      email
      roles {
        id
        name
      }
    }
  }
`

export const INVITE_USER = gql`
  mutation InviteUser($cid: Int!, $email: String!, $roles: [String]!) {
    inviteUser(customerId: $cid, email: $email, roles: $roles) {
      id
      email
    }
  }
`

export const DELETE_INVITATION = gql`
  mutation DeleteInvitation($cid: Int!, $invitationId: Int!) {
    deleteInvite(customerId: $cid, invitationId: $invitationId) {
      id
    }
  }
`

export const GET_CUSTOMER_USER_EMAILS = gql`
  query GetCustomerUserEmails($cname: String!) {
    customer(name: $cname) {
      users {
        email
      }
    }
  }
`
