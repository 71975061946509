import React from "react"

const ErrorMessage = props => {
  const { error } = props

  return (
    <div className="card">
      <div className="card-body">
        <p>{error.message}</p>
      </div>
    </div>
  )
}

export default ErrorMessage
