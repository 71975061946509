import React, { useMemo, useCallback } from "react"
import dayjs from "dayjs"
import { Table, Button } from "antd"
import { useQuery } from "@apollo/client"
import { useDispatch, useSelector } from "react-redux"

import { stringSorter } from "@dbai/tool-box"
import { ErrorMessage, AuthBoundary } from "@dbai/ui-staples"

import { GET_COMPONENT_REVISIONS } from "queries"
import CurrentCheckbox from "./CurrentCheckbox"
import { actions } from "reducers/componentReducer"
import { selectComponentLoadedRevision } from "selectors"
const LoadButton = props => {
  const { value } = props
  const loadedRevision = useSelector(selectComponentLoadedRevision)
  const dispatch = useDispatch()
  const handleClick = useCallback(() => {
    dispatch(actions.loadRevision(value))
  }, [dispatch, value])

  if (loadedRevision === value) {
    return <Button type="disabled">LOADED</Button>
  }

  return (
    <Button type="primary" onClick={handleClick}>
      LOAD
    </Button>
  )
}

const RevisionsTable = props => {
  const { cname, componentId } = props
  const variables = { cname, componentId }
  const { error, loading, data } = useQuery(GET_COMPONENT_REVISIONS, {
    fetchPolicy: "network-only",
    variables,
  })

  const component = data?.customer?.component || {}
  const currentRevisionId = component.currentRevision?.id || null
  const revisions = useMemo(
    () => component.componentRevisions || [],
    [component.componentRevisions]
  )
  const tableData = useMemo(() => {
    return revisions.map(rev => ({ ...rev, loadKey: `load-${rev.id}` }))
  }, [revisions])

  if (error) return <ErrorMessage error={error} />

  return (
    <AuthBoundary subject="components" action="LIST">
      <div id="components">
        <Table
          size="small"
          dataSource={tableData}
          pagination={false}
          scroll={{ y: "calc(100vh - 300px)" }}
        >
          <Table.Column
            dataIndex="id"
            title="Current"
            render={id => (
              <CurrentCheckbox
                value={id}
                loading={loading}
                currentRevisionId={currentRevisionId}
              />
            )}
          />
          <Table.Column
            dataIndex="id"
            title="ID"
            render={id => <span>{id}</span>}
          />
          <Table.Column
            dataIndex="createdAt"
            title="Created At"
            defaultSortOrder="desc"
            sorter={stringSorter("createdAt")}
            render={createdAt =>
              dayjs(createdAt).format("MM/DD/YYYY h:mm:ss a")
            }
          />
          <Table.Column
            dataIndex="loadKey"
            title=""
            render={(_, rev) => <LoadButton value={rev.id} />}
          />
        </Table>
      </div>
    </AuthBoundary>
  )
}

export default RevisionsTable
