import React, { useEffect, useState } from "react"
import { Layout } from "antd"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { useDispatch } from "react-redux"

import { PageSpinner, ErrorMessage } from "@dbai/ui-staples"

import Widget from "./Widget"
import AppConfig from "./AppConfig"
import AppHeader from "./AppHeader"
import AppPage from "./shared/AppPage"
import widgetRegistry from "./widgetRegistry"
import ContextMenu from "./shared/ContextMenu"
import { actions } from "./reducers/appReducer"
import { GET_ACTIVE_APP_SNAPSHOT } from "./queries/app"
import WidgetContextWrapper from "./WidgetContextWrapper"
import BreakpointObserver from "./BreakpointObserver"

const StyledContent = styled(Layout.Content)`
  display: flex;
  flex-flow: row;
  flex: 1 1 auto;
  height: 100vh;
`

/**
 *  AppletView is a component that renders an applet in a view-only context.
 *  Note: This component expects to recieve an appId for an Applet that is
 *  published. It will not render an applet that is not published.
 */
const AppletView = props => {
  const { app: _app, appId, cname } = props
  const [entryPageId, setEntryPageId] = useState(_app?.spec?.rootPageId)
  const [loading, setLoading] = useState(!_app)
  const dispatch = useDispatch()

  const { error, data } = useQuery(GET_ACTIVE_APP_SNAPSHOT, {
    skip: !!_app,
    variables: { cname, appId },
    onCompleted: results => {
      dispatch(actions.loadAppSnapshot(results.customer)).then(snapshot => {
        setEntryPageId(snapshot.spec.rootPageId)
        setLoading(false)
      })
    },
    onError: () => setLoading(false),
  })

  useEffect(() => {
    return () => {
      dispatch(actions.reset())
    }
  }, [dispatch])

  // TODO: Show friendly error message if applet is not published
  if (error) return <ErrorMessage error={error} />
  if (loading) return <PageSpinner />

  const app = _app || data.customer?.app

  return (
    <WidgetContextWrapper
      app={app}
      Widget={Widget}
      widgetRegistry={widgetRegistry}
      {...props}
    >
      <BreakpointObserver>
        <ContextMenu>
          <AppConfig>
            <Layout id="app-area">
              <AppHeader />
              <StyledContent>
                <AppPage pageId={entryPageId} />
              </StyledContent>
            </Layout>
          </AppConfig>
        </ContextMenu>
      </BreakpointObserver>
    </WidgetContextWrapper>
  )
}

AppletView.propTypes = {
  cname: PropTypes.string.isRequired,
  appId: PropTypes.number.isRequired,
  customerId: PropTypes.string.isRequired,
  exportDataset: PropTypes.func.isRequired,
}

export default AppletView
