import React, { memo, useRef, useEffect } from "react"
import { Input } from "antd"
import styled from "styled-components"

import fieldOptionsSchema from "./fieldOptionsSchema"
import { useLocalFormFieldControls } from "../../hooks"
import { withFormFieldWrapper } from "../../FormFieldWrapper"

const StyledInput = styled(Input)`
  min-width: 100px;
`

const BaseInputCore = memo(props => {
  const { schema = {}, onChange, value: _value, ...rest } = props
  const { metadata = {}, nullable } = schema
  const { focusOnMount } = metadata
  const inputRef = useRef()

  useEffect(() => {
    if (focusOnMount && inputRef.current) {
      inputRef.current.focus({
        cursor: "start",
      })
    }
  }, [focusOnMount])

  const [value, handleChange] = useLocalFormFieldControls(_value, onChange, 100)
  return (
    <StyledInput
      ref={inputRef}
      value={value}
      onChange={handleChange}
      allowClear={nullable}
      {...rest}
    />
  )
})

const BaseInput = memo(withFormFieldWrapper(BaseInputCore))

BaseInput.fieldOptionsSchema = fieldOptionsSchema
BaseInput.Core = BaseInputCore
export default BaseInput
