import React, { useMemo, useCallback } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import dayjs from "dayjs"
import { useDispatch } from "react-redux"
import { Button } from "antd"

import { numberSorter, dateSorter } from "@dbai/tool-box"
import { DBTable, SidePane, ErrorMessage } from "@dbai/ui-staples"

import { GET_REVISIONS } from "queries"
import {
  ACTION_ID_LABEL,
  ACTION_REVISION_ID_LABEL,
} from "reducers/notebookReducer/thunks"
import { actions } from "reducers/notebookReducer"

const LoadButton = ({ node, revision, onClose }) => {
  const dispatch = useDispatch()
  const handleClick = useCallback(() => {
    dispatch(actions.switchActionCodeInNode(node, revision))
    onClose()
  }, [dispatch, node, revision, onClose])

  if (revision.isLoaded) {
    return <Button type="disabled">LOADED</Button>
  }

  return (
    <Button type="primary" onClick={handleClick}>
      LOAD
    </Button>
  )
}

const UpdateActionPane = props => {
  const { cname } = useParams()
  const { visible, onClose, node } = props
  const actionId = node.metadata[ACTION_ID_LABEL]
  const loadedRevisionId = node.metadata[ACTION_REVISION_ID_LABEL]

  const variables = { cname, actionId }
  const { error, data } = useQuery(GET_REVISIONS, {
    fetchPolicy: "network-only",
    variables,
  })

  const action = data?.customer?.action || {}
  const revisions = useMemo(
    () => action.actionRevisions || [],
    [action.actionRevisions]
  )
  const tableData = useMemo(() => {
    return revisions.map(rev => ({
      ...rev,
      isLoaded: rev.id === parseInt(loadedRevisionId),
    }))
  }, [revisions, loadedRevisionId])

  if (error) return <ErrorMessage error={error} />

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: numberSorter("id"),
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: dateSorter("createdAt"),
      defaultSortOrder: "descend",
      render: createdAt => dayjs(createdAt).format("MM/DD/YYYY h:mm:ss a"),
    },
    {
      title: "Load",
      key: "load",
      dataIndex: "id",
      width: 1,
      render: (_, revision) => {
        return <LoadButton node={node} revision={revision} onClose={onClose} />
      },
    },
  ]

  return (
    <SidePane
      title="Update Action Code"
      width="30vw"
      isVisible={visible}
      onCloseClicked={onClose}
    >
      <DBTable dataSource={tableData} columns={columns} />
    </SidePane>
  )
}

export default UpdateActionPane
