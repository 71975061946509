import React from "react"
import { Button } from "antd"
import styled from "styled-components"
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { pathToDataPath } from "../../JSONSchemaForm/utils"
import useFormFieldActions from "../../JSONSchemaForm/hooks/useFormFieldActions"

const StyledButton = styled(Button)`
  &&& {
    svg {
      margin-right: 0;
    }
  }
`

const RemoveFormFieldButton = props => {
  const { idx, path, onRemove: beforeRemove } = props
  const stopPropagation = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  const name = pathToDataPath(path)
  const { onRemove: removeField } = useFormFieldActions(name)

  const handleRemove = () => {
    beforeRemove && beforeRemove()
    removeField(idx)
  }

  return (
    <div onClick={stopPropagation}>
      <StyledButton
        type="text"
        onClick={handleRemove}
        icon={<FontAwesomeIcon icon={faTrashAlt} />}
      />
    </div>
  )
}

export default RemoveFormFieldButton
