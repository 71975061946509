import { useMemo, useState, useEffect, useContext, useCallback } from "react"

import frames from "components/pages/Workflows/Edit/shared/kernel/frames"
import { useKernel } from "components/pages/Workflows/Edit/shared/kernel"
import { CommsChannelContext } from "components/shared/CommsContext"

/*
 * Given a filter and a message handler, listen to and respond to messages on
 * the given channel.
 */
export const useCommSubscription = ({
  filter,
  handleMessage,
  channel = "iopub",
}) => {
  const { kernel } = useKernel()

  useEffect(() => {
    if (!kernel) return
    const [outputs] = kernel.channel(channel)

    const sub = outputs.filter(filter).subscribe(handleMessage)

    return () => sub.unsubscribe()
  }, [kernel, filter, handleMessage, channel])
}

/*
 * Given a the name of a channel create a new IPython Comms channel.
 */
export const useCommChannel = (channelName, msgType) => {
  const [commId, setCommId] = useState(null)
  const { kernel } = useKernel()

  const exec = useMemo(() => {
    if (!kernel) return
    return kernel.channel("shell")[1]
  }, [kernel])

  const send = useCallback(
    (data, content, header = {}) =>
      exec &&
      exec(frames.makeCommMsg({ commId, data, msgType, content, header })),
    [commId, exec, msgType]
  )

  useEffect(() => {
    if (!exec) return

    if (!commId) {
      const frame = frames.makeCommOpen({
        target: channelName,
      })

      exec(frame)
      setCommId(frame.content.comm_id)
    }

    return () => {
      if (!commId) return

      const closeFrame = frames.makeCommClose({
        commId,
      })
      exec(closeFrame)
    }
  }, [channelName, commId, exec])

  const restart = () => setCommId(null)
  return useMemo(() => ({ send, commId, restart }), [commId, send])
}

export const useCommsChannels = () => {
  return useContext(CommsChannelContext)
}
