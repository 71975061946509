import React, { useCallback, useMemo, useState, useEffect } from "react"
import dayjs from "dayjs"
import { get } from "lodash"
import { useSelector } from "react-redux"

import { ErrorMessage } from "@dbai/ui-staples"

import getQuery from "../../lib/getQuery"
import { useDatasetData, useWidgetContext } from "../../hooks"
import { selectRefreshInterval } from "../../selectors/app"

const chartTypePath = "options.chart.type"

const emptyDataset = {
  data: [],
  meta: {},
}

const pollingData = query =>
  !isNaN(query.pollInterval) && query.pollInterval > 0

/*
 * QueryWrapper reaches out to the GQL API to retrieve data for the given
 * widget. It also handles loading/error states prior to rendering the child
 * widget with the included data result.
 */
const QueryWrapper = props => {
  const { children, widget, pageId, ignoreError } = props
  const { widgetRegistry } = useWidgetContext()
  const refreshInterval = useSelector(selectRefreshInterval)

  const query = useMemo(() => {
    return getQuery({ widget, widgetRegistry })
  }, [widget, widgetRegistry])

  const [lastPollTime, setLastPollTime] = useState()
  const [safeDataset, setSafeDataset] = useState(emptyDataset)

  const onCompleted = useCallback(data => {
    setLastPollTime(dayjs())
    setSafeDataset({ ...emptyDataset, ...data })
  }, [])

  const {
    error,
    called,
    loading,
    skipped,
    fetchMore,
    stopPolling,
    startPolling,
  } = useDatasetData({
    query,
    pageId,
    onCompleted,
    widgetId: widget.id,
    widgetType: widget.type,
    chartType: get(widget, chartTypePath),
  })

  useEffect(() => {
    if (pollingData(query)) {
      startPolling(query.pollInterval * 60000)
    } else {
      startPolling(refreshInterval * 60000)
    }
    return () => {
      if (pollingData(query)) {
        stopPolling()
      }
    }
  }, [startPolling, stopPolling, query])

  if (error && !ignoreError) return <ErrorMessage error={error} />

  return React.cloneElement(children, {
    called,
    skipped,
    loading,
    fetchMore,
    dataset: skipped ? emptyDataset : safeDataset,
    lastPollTime: lastPollTime?.format("hh:mm:ss A"),
  })
}

export default QueryWrapper
