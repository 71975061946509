import { flow } from "lodash"

import { processScalarFieldOptions } from "../../lib/generateSchemaHelpers"

const generateSchema = flow([
  options => {
    return {
      ...options,
      type: "string",
      metadata: {
        ...options.metadata,
        component: "ColumnSelect",
      },
    }
  },
  processScalarFieldOptions,
])

export default generateSchema
