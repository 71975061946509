import React from "react"
import { useDispatch } from "react-redux"

import { DBDropzone, SidePane, useCurrentCustomer } from "@dbai/ui-staples"

import { actions } from "reducers/datalakeReducer"

const formatResults = fileData => results => {
  const createNode = results?.data?.createNode
  if (!createNode) return null
  const method = "put"
  const { uploadUrl: url, id: nodeId } = createNode
  const meta = { nodeId }

  return { method, url, meta, body: fileData.file }
}

const useBuildParams = cname => {
  const dispatch = useDispatch()
  const options = { cname }

  return fileData => {
    return dispatch(actions.uploadFile(options, fileData)).then(
      formatResults(fileData)
    )
  }
}

const useHandleChangeStatus = cname => {
  const dispatch = useDispatch()
  return ({ meta }) => {
    if (meta.status === "done") {
      dispatch(
        actions.updateNodeStatus({
          cname,
          id: meta.nodeId,
          status: "COMPLETED",
        })
      )
    }
  }
}

const Uploader = props => {
  const [customer] = useCurrentCustomer()
  const buildParams = useBuildParams(customer.normalizedName)
  const handleStatusChange = useHandleChangeStatus(customer.normalizedName)

  return (
    <DBDropzone
      getUploadParams={buildParams}
      onChangeStatus={handleStatusChange}
    />
  )
}

const DatalakeUpload = props => {
  const { isVisible, handleClose } = props

  return (
    <SidePane
      isVisible={isVisible}
      onCloseClicked={handleClose}
      title="UPLOAD FILES"
    >
      <Uploader />
    </SidePane>
  )
}

export default DatalakeUpload
