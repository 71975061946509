import React, { useState, useCallback } from "react"
import { Dropdown } from "antd"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import useContextMenuItems from "./useContextMenuItems"
import { actions } from "../../reducers/appReducer"
import { useWidgetContext } from "../../hooks"

const Content = props => {
  const dispatch = useDispatch()
  const {
    children,
    onVisibilityChange,
    additionalTriggers = [],
    ...rest
  } = props
  const [contextMenuOpen, setContextMenuOpen] = useState(false)

  const closeMenu = useCallback(() => {
    setContextMenuOpen(false)
    dispatch(actions.removeContext())
    onVisibilityChange && onVisibilityChange(false)
  }, [dispatch, setContextMenuOpen, onVisibilityChange])

  const openMenu = useCallback(() => {
    setContextMenuOpen(true)
  }, [setContextMenuOpen])

  const items = useContextMenuItems(closeMenu)

  const handleVisibilityChange = useCallback(
    (willBeVisible, { source }) => {
      // ignore if visibility is changing due to a menu item click
      if (source === "menu") return
      if (willBeVisible) {
        openMenu()
        onVisibilityChange && onVisibilityChange(true)
      }
      if (!willBeVisible) closeMenu()
    },
    [closeMenu, openMenu, onVisibilityChange]
  )

  return (
    <Dropdown
      menu={{ items }}
      open={contextMenuOpen}
      destroyPopupOnHide={true}
      onOpenChange={handleVisibilityChange}
      trigger={["contextMenu", ...additionalTriggers]}
      {...rest}
    >
      <div>{children}</div>
    </Dropdown>
  )
}

const ContextMenu = props => {
  const { children } = props
  const { editable } = useWidgetContext()
  if (!editable) return <div>{children}</div>

  return <Content {...props} />
}

ContextMenu.propTypes = {
  additionalTriggers: PropTypes.array,
  onVisibilityChange: PropTypes.func,
}

export default ContextMenu
