import React, { useMemo, useCallback } from "react"
import styled, { css } from "styled-components"
import { Statistic } from "antd"

import { getConditionalStyles } from "../../lib/conditionalFormat"
import {
  findColumnConfig,
  formatColumnValue,
  getAliasedColumnName,
} from "../../lib/datasetColumns"

const conditionalStylesCss = css`
  ${props => {
    const { conditionalStyles = {} } = props
    const {
      backgroundColor = "transparent",
      color = props.theme.colorTextBase,
    } = conditionalStyles
    return `
    background-color: ${backgroundColor};
    .ant-statistic-content {
      color: ${color};
    }
    .ant-statistic-title {
      color: ${color};
    }
  `
  }}
`
const positionCss = css`
  ${props => {
    const { vPosition = "flex-start", hPosition = "flex-start" } = props
    return `
  align-items: ${vPosition};
  justify-content: ${hPosition};
  `
  }}
`

const StyledKPIContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  ${conditionalStylesCss}
  ${positionCss}
`

const textAlignStyleMap = {
  "flex-start": "left",
  "flex-end": "right",
}
const StyledStatisticTitle = styled.div`
  ${props => {
    const { hPosition = "flex-start" } = props
    return `
  text-align: ${textAlignStyleMap[hPosition] ?? hPosition};
  `
  }}
`

const KPI = props => {
  const { dataset, widget = {}, loading } = props
  const { showTitle, vPosition, hPosition } = widget.options
  const data = useMemo(() => {
    if (!dataset?.data?.length) return []
    const { rows, columns } = dataset.data[0]
    if (!rows.length) return []
    return columns.reduce((acc, column, index) => {
      const columnName = getAliasedColumnName(column)
      return { ...acc, [columnName]: rows[0][index] }
    }, {})
  }, [dataset])

  const conditionalStyles = useMemo(() => {
    return getConditionalStyles(widget.options.conditionalFormat, data, true)
  }, [widget.options.conditionalFormat, data])

  const style = useMemo(() => {
    const { backgroundColor, color, ...style } = conditionalStyles
    return style
  }, [conditionalStyles])

  const value = useMemo(() => {
    const columnName = getAliasedColumnName(widget.options.datasetColumn || {})
    return data[columnName] || data[widget.options.datasetColumn?.column]
  }, [data, widget.options.datasetColumn])

  const columnConfig = useMemo(() => {
    if (!dataset?.data?.length) return {}
    const data = dataset.data[0]
    return findColumnConfig(widget.options.datasetColumn, data.columns)
  }, [widget.options.datasetColumn, dataset])

  const formatter = useCallback(
    value => {
      return formatColumnValue(columnConfig, value)
    },
    [columnConfig]
  )

  return (
    <StyledKPIContainer
      conditionalStyles={conditionalStyles}
      vPosition={vPosition}
      hPosition={hPosition}
    >
      <Statistic
        style={style}
        value={value}
        loading={loading}
        formatter={formatter}
        title={
          showTitle ? (
            <StyledStatisticTitle hPosition={hPosition}>
              {widget.name}
            </StyledStatisticTitle>
          ) : null
        }
      />
    </StyledKPIContainer>
  )
}

export default React.memo(KPI)
