import React from "react"
import styled from "styled-components"
import { Button } from "antd"
import { get, isEqual } from "lodash"

import { stringToColor } from "@dbai/tool-box"
import { formatOptionsSchema, FieldTypeIcon } from "@dbai/applet"

import { GET_LABELS_FOR_COLUMN } from "queries"

const StyledIcon = styled(FieldTypeIcon)`
  margin: 0 16px;
`

const createHandleChange =
  (selectedKeys, setSelectedKeys, confirm) => value => () => {
    const checked = !selectedKeys.includes(value)
    const newSelectedKeys = checked
      ? [...selectedKeys, value]
      : selectedKeys.filter(s => s !== value)
    setSelectedKeys(newSelectedKeys)
    confirm({ closeDropdown: false })
  }

const handleFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm }) => {
  const handleChange = createHandleChange(
    selectedKeys,
    setSelectedKeys,
    confirm
  )

  return (
    <Button.Group style={{ width: "100%" }}>
      {["datetime", "string", "float"].map(type => (
        <Button
          key={type}
          icon={<FieldTypeIcon type={type} />}
          type={selectedKeys.includes(type) ? "primary" : "text"}
          style={{ width: "100%" }}
          onClick={handleChange(type)}
        />
      ))}
    </Button.Group>
  )
}

const handleLoadLabels = async ({
  name,
  cname,
  value,
  client,
  rootData,
  onChange,
  setLoading,
}) => {
  const parentName = name.slice(0, 1)
  const parentData = get(rootData, parentName)
  const { name: columnName, datasetId } = parentData

  requestIdleCallback(async () => {
    setLoading(true)
    // query column labels
    const mergedLabels = await client
      .query({
        query: GET_LABELS_FOR_COLUMN,
        variables: { id: datasetId, column: columnName, cname },
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => {
        let labels = data?.customer?.dataset?.meta?.labels || []

        // merge existing labels with new labels, remove labels that do not exist anymore
        let mergedLabels = value.reduce((acc, labelConfig) => {
          const labelExists = labels.find(l => l === labelConfig.label)
          if (!labelExists) return acc
          labels = labels.filter(l => l !== labelConfig.label)
          return [...acc, labelConfig]
        }, [])

        // add new labels to the end of the list
        return [
          ...mergedLabels,
          ...labels.map(label => ({
            label,
            title: label,
            color: stringToColor(label),
            usable: true,
          })),
        ]
      })

    // update the labels if they have changed
    if (!isEqual(mergedLabels, value)) onChange(mergedLabels)
    setLoading(false)
  })
}

const handleDefaultColumnColor = ({ name, rootData }) => {
  const parentName = name.slice(0, 1)
  const parentData = get(rootData, parentName)
  return stringToColor(parentData.name)
}

const handleDefaultLabelColor = ({ name, rootData }) => {
  const parentName = name.slice(0, -1)
  const parentData = get(rootData, parentName)
  return stringToColor(parentData.label)
}

const handleTypeRender = value => (
  <FieldTypeIcon style={{ display: "block", margin: "auto" }} type={value} />
)

const tableListOptions = {
  split: true,
  virtual: true,
  bordered: true,
  rowKeyName: "name",
  pagination: { pageSize: 10, hideOnSinglePage: true, position: ["topLeft"] },
  scroll: { x: "100%", y: "calc(100vh - 302px)" },
  sections: ["formatOptions"],
  columns: [
    {
      render: handleTypeRender,
      width: 45,
      sorter: {
        compare: (a, b) =>
          a.type.localeCompare(b.type, "en", { numeric: true }),
        multiple: 3,
      },
      onFilter: (value, record) => record.type === value,
      schemaKey: "type",
      filterDropdown: handleFilterDropdown,
      defaultSortOrder: "ascend",
      filters: [
        { text: <StyledIcon type="datetime" />, value: "datetime" },
        { text: <StyledIcon type="string" />, value: "string" },
        { text: <StyledIcon type="float" />, value: "float" },
      ],
    },
    {
      width: 200,
      sorter: {
        compare: (a, b) =>
          a.name.localeCompare(b.name, "en", { numeric: true }),
        multiple: 2,
      },
      schemaKey: "name",
      defaultSortOrder: "ascend",
    },
    {
      width: 200,
      sorter: {
        compare: (a, b) =>
          a.label.localeCompare(b.label, "en", { numeric: true }),
        multiple: 1,
      },
      schemaKey: "label",
    },
  ],
}

const labelTableListOptions = {
  dnd: true,
  // scroll: { x: "100%", y: "calc(100vh - 398px)" },
  scroll: { x: 500, y: 510 },
  virtual: true,
  bordered: true,
  rowKeyName: "label",
  pagination: { pageSize: 10, hideOnSinglePage: true, position: ["topLeft"] },
  columns: [
    {
      width: 200,
      schemaKey: "label",
    },
    {
      width: 200,
      schemaKey: "title",
    },
    {
      width: 70,
      schemaKey: "color",
    },
    {
      width: 70,
      schemaKey: "usable",
      onFilter: (value, record) => record.usable === value,
      filters: [
        { text: "Visible", value: true },
        { text: "Hidden", value: false },
      ],
    },
  ],
}

const schema = {
  type: "array",
  metadata: {
    hideAdd: true,
    listType: "table",
    tableListOptions: tableListOptions,
  },
  items: {
    type: "object",
    dependencies: {
      type: {
        allOf: [
          {
            if: {
              properties: {
                type: {
                  enum: ["string"],
                },
              },
            },
            then: {
              properties: {
                formatOptions: {
                  type: "object",
                  title: "Format Options",
                  default: {},
                  metadata: {
                    noStyle: true,
                  },
                  properties: {
                    "formatOptions/color": {
                      type: ["string", "null"],
                      title: "Color",
                      nullable: true,
                      metadata: {
                        width: 70,
                        component: "ColorPicker",
                        getDefaultValue: handleDefaultColumnColor,
                      },
                    },
                    description: {
                      type: "string",
                      title: "Description",
                      metadata: {
                        autoSize: { minRows: 1, maxRows: 4 },
                        component: "TextArea",
                      },
                    },
                    "formatOptions/labels": {
                      type: "array",
                      metadata: {
                        events: {
                          onLoad: handleLoadLabels,
                        },
                        hideAdd: true,
                        listType: "table",
                        hideLabel: true,
                        tableListOptions: labelTableListOptions,
                      },
                      items: {
                        type: "object",
                        properties: {
                          label: {
                            type: "string",
                            title: "Label",
                            metadata: {
                              editable: false,
                              hideLabel: true,
                            },
                          },
                          title: {
                            type: "string",
                            title: "Title",
                            metadata: {
                              hideLabel: true,
                              validateFirst: true,
                              fieldProps: {
                                placeholder: "<Current Label>",
                              },
                            },
                          },
                          usable: {
                            type: "boolean",
                            title: "Visible",
                            metadata: {
                              hideLabel: true,
                            },
                          },
                          color: {
                            type: ["string", "null"],
                            title: "Color",
                            nullable: true,
                            metadata: {
                              width: 70,
                              component: "ColorPicker",
                              hideLabel: true,
                              getDefaultValue: handleDefaultLabelColor,
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            else: {
              properties: {
                formatOptions: {
                  type: "object",
                  ...formatOptionsSchema,
                  properties: {
                    "formatOptions/color": {
                      type: ["string", "null"],
                      title: "Color",
                      nullable: true,
                      metadata: {
                        width: 70,
                        component: "ColorPicker",
                        getDefaultValue: handleDefaultColumnColor,
                      },
                    },
                    description: {
                      type: "string",
                      title: "Description",
                      metadata: {
                        autoSize: { minRows: 1, maxRows: 6 },
                        component: "TextArea",
                      },
                    },
                    ...formatOptionsSchema.properties,
                  },
                },
              },
            },
          },
        ],
      },
    },
    properties: {
      datasetId: {
        type: "string",
        metadata: {
          editable: false,
          hidden: true,
        },
      },
      type: {
        type: "string",
        metadata: {
          hideLabel: true,
        },
      },
      name: {
        type: "string",
        title: "Column",
        metadata: {
          editable: false,
          hideLabel: true,
        },
      },
      label: {
        type: "string",
        title: "Label",
        metadata: {
          hideLabel: true,
        },
      },
    },
  },
}

export default schema
