import React from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { getStatusIndicator } from "components/pages/Workflows/Edit/shared/StatusIndicator.js"
import {
  nodeIconMap,
  iconMap,
} from "components/pages/Workflows/shared/IconPicker"
import { selectNodes } from "selectors"
import { FAMemo } from "@dbai/ui-staples"

const getIcon = (type, isAction) => {
  if (isAction) return iconMap.action
  return iconMap[type]
}

const NodeType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  border-radius: 10px;
  height: 40px;
  color: white;
  background-color: ${({ color }) => color};
  margin: 6px 6px 3px;
`

const NodeIcon = props => {
  const { id, type, color, status, isAction } = props
  const nodes = useSelector(selectNodes)
  const node = nodes.find(n => n.id === id)

  const icon = node.icon || getIcon(type, isAction) || "Expand"

  const nodeStatus = getStatusIndicator(status)
  const iconColor = "#fff"
  const className = `type ${type} ${status}`

  return (
    <NodeType className={className} color={color || "#0c2963"}>
      {nodeStatus || (
        <FAMemo icon={nodeIconMap[icon]} size="lg" color={iconColor} />
      )}
    </NodeType>
  )
}

export default NodeIcon
