import { createSlice } from "@reduxjs/toolkit"

import { GET_APPS, DELETE_APP, CREATE_APP } from "@dbai/applet"
import {
  loadResource,
  createCommonActions,
  createResourceActions,
  uuidv4,
} from "@dbai/tool-box"

const initialState = {
  data: [],
  loading: false,
  error: null,
}

const appsSlice = createSlice({
  name: "apps",
  initialState,
  reducers: {
    ...createResourceActions({ initialState }),
    ...createCommonActions(initialState),
  },
})

const { actions } = appsSlice

const loadApps =
  () =>
  (dispatch, getState, { client }) => {
    const cname = getState().currentCustomer.customer.normalizedName

    return dispatch(
      loadResource({
        fetchFn: () => {
          return client
            .query({
              query: GET_APPS,
              variables: { cname },
              fetchPolicy: "network-only",
            })
            .then(({ data }) => data?.customer?.apps)
        },
        actions,
      })
    )
  }

const createApp = initApp => {
  return (dispatch, getState, { client }) => {
    const cname = getState().currentCustomer.customer.normalizedName
    const rootPageId = uuidv4()
    const pages = {
      [rootPageId]: {
        widgetIds: [],
      },
    }
    const app = {
      ...initApp,
      spec: {
        ...initApp.spec,
        pages,
        rootPageId,
      },
    }
    return client
      .mutate({
        mutation: CREATE_APP,
        variables: { cname, ...app },
      })
      .then(() => {
        return dispatch(loadApps())
      })
  }
}

const deleteApp =
  id =>
  (_, getState, { client }) => {
    const cname = getState().currentCustomer.customer.normalizedName

    return client.mutate({
      mutation: DELETE_APP,
      variables: { cname, id },
    })
  }

const allActions = {
  loadApps,
  createApp,
  deleteApp,
  ...actions,
}

export { allActions as actions }
export default appsSlice
