import React, { useState, useCallback, useMemo } from "react"
import { Button, Popconfirm } from "antd"
import styled from "styled-components"
import { useParams } from "react-router"
import { useQuery } from "@apollo/client"
import { useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencilAlt, faPlus, faTrash } from "@fortawesome/pro-solid-svg-icons"

import {
  SidePane,
  PageSpinner,
  ErrorMessage,
  TableWithFolders,
  GET_CUSTOMER_ROLES,
} from "@dbai/ui-staples"
import { stringSorter } from "@dbai/tool-box"

import RoleForm from "./RoleForm"
import { actions } from "reducers/roleReducer"
import PolicyMatrix, { MatrixRow } from "./PolicyMatrix"

const Description = styled.div`
  font-style: italic;
`

const RoleActions = props => {
  const { openEditPanel, row } = props
  const dispatch = useDispatch()

  const handleEdit = useCallback(() => openEditPanel(row), [openEditPanel, row])
  const handleRemove = useCallback(
    () => dispatch(actions.deleteRole(row)),
    [dispatch, row]
  )

  if (row.roleType === "internal" || row.roleType === "public") return null

  return (
    <Button.Group>
      <Button
        onClick={handleEdit}
        icon={<FontAwesomeIcon icon={faPencilAlt} />}
      />
      <Popconfirm
        okText="Yes"
        cancelText="No"
        placement="left"
        title="Delete Role"
        onConfirm={handleRemove}
        description="Are you sure to delete this role?"
      >
        <Button danger icon={<FontAwesomeIcon icon={faTrash} />} />
      </Popconfirm>
    </Button.Group>
  )
}

const RoleName = ({ name, description }) => {
  return (
    <div>
      <div>{name}</div>
      <Description>{description}</Description>
    </div>
  )
}

const RolePolicyMatrix = ({ policies = [] }) => {
  return (
    <PolicyMatrix>
      {policies.map(policy => (
        <MatrixRow key={policy.id} policy={policy} />
      ))}
    </PolicyMatrix>
  )
}

const columnOrder = ["name", "policies", "createdAt", "updatedAt", "actions"]

const Roles = props => {
  const { policies } = props
  const { cname } = useParams()
  const { data, loading, error } = useQuery(GET_CUSTOMER_ROLES, {
    variables: { cname },
  })

  const dispatch = useDispatch()

  const [isEditPanelOpen, setIsEditPanelOpen] = useState(false)

  const openNewPanel = useCallback(
    () => setIsEditPanelOpen(true),
    [setIsEditPanelOpen]
  )

  const openEditPanel = useCallback(
    row => {
      setIsEditPanelOpen(true)
      dispatch(actions.load(row))
    },
    [dispatch, setIsEditPanelOpen]
  )

  const closeEditPanel = useCallback(() => {
    setIsEditPanelOpen(false)
    dispatch(actions.reset())
  }, [dispatch, setIsEditPanelOpen])

  const columns = useMemo(
    () => [
      {
        key: "name",
        title: "Name",
        dataIndex: "name",
        sorter: stringSorter("name"),
        render: ({ row }) => {
          return <RoleName name={row.name} description={row.description} />
        },
      },
      {
        key: "policies",
        title: "Policy Rules",
        dataIndex: "policies",
        render: ({ row }) => {
          return <RolePolicyMatrix policies={row.policies} />
        },
      },
      {
        title: "",
        key: "actions",
        dataIndex: "actions",
        render: ({ row }) => {
          return <RoleActions openEditPanel={openEditPanel} row={row} />
        },
        width: 1,
      },
    ],
    [openEditPanel]
  )

  if (error) return <ErrorMessage error={error} />
  if (!data || loading) return <PageSpinner />

  const {
    customer: { roles },
  } = data

  const rolesWithoutInternal = roles.filter(
    role => role.roleType !== "internal"
  )

  return (
    <>
      <TableWithFolders
        dataSource={rolesWithoutInternal}
        columns={columns}
        folderType="roles"
        columnOrder={columnOrder}
        emptyText="No Roles Found"
        extra={
          <Button
            type="primary"
            onClick={openNewPanel}
            icon={<FontAwesomeIcon icon={faPlus} />}
          >
            New Role
          </Button>
        }
      />
      <SidePane
        width="80vw"
        isVisible={isEditPanelOpen}
        title="Set Role Policies"
        onCloseClicked={closeEditPanel}
      >
        <RoleForm policies={policies} closePanel={closeEditPanel} />
      </SidePane>
    </>
  )
}

export default Roles
