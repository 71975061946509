import React, { useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

import Select from "../../shared/Select"
import { useFormContext } from "../../hooks"
import FormFieldWrapper from "../../FormFieldWrapper"
import { selectAppSpec } from "../../../selectors/app"
import { actions } from "../../../reducers/formsReducer"

const AppVariableSelectCore = props => {
  const { schema } = props
  const { nullable } = schema
  const { variables } = useSelector(selectAppSpec)
  const options = variables?.map(v => ({
    value: v.id,
    label: v.name,
    variableType: v.type,
  }))
  return <Select options={options} allowClear={nullable} {...props} />
}

const AppVariableSelect = props => {
  const { name, schema } = props
  const { metadata = {} } = schema
  const { variableTypeName = "type", saveVariableType = true } = metadata
  const dispatch = useDispatch()
  const { formId } = useFormContext()

  const handleChange = useCallback(
    (value, { value: column, variableType: type }) => {
      if (!saveVariableType) return
      dispatch(
        actions.setFormField({
          id: formId,
          value: type,
          name: name ? `${name}.${variableTypeName}` : variableTypeName,
        })
      )
    },
    [dispatch, formId, name, variableTypeName]
  )

  return (
    <FormFieldWrapper {...props}>
      <AppVariableSelectCore onChange={handleChange} />
    </FormFieldWrapper>
  )
}

AppVariableSelect.Core = AppVariableSelectCore
export default AppVariableSelect
