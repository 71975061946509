import React from "react"
import { Col } from "antd"
import styled from "styled-components"
import { NavLink } from "react-router-dom"

import { Content, AuthBoundary } from "@dbai/ui-staples"

import withCustomer from "components/shared/withCustomer"

const StackedNav = styled.nav`
  display: flex;
  flex-direction: column;
`

const StyledLink = styled(NavLink)`
  padding: 15px 20px;
  border-radius: 2px;

  &.active {
    background-color: ${props => props.theme.neutral};
    font-weight: bold;
    &:hover {
      text-decoration: none;
      color: ${props => props.theme.text};
    }
  }
`

const WorkflowsContent = props => {
  const { customer } = props
  const cname = customer.normalizedName
  const workflowsPath = `/${cname}/workflows`
  const schedulesPath = `${workflowsPath}/schedules`
  const libraryPath = `${workflowsPath}/library`
  const componentsPath = `${workflowsPath}/components`
  const packagesPath = `${workflowsPath}/packages`

  return (
    <Content id="workflows">
      <Col span={4}>
        <StackedNav>
          <StyledLink to={workflowsPath} exact>
            Workflows
          </StyledLink>
          <StyledLink to={schedulesPath}>Schedules</StyledLink>
          <AuthBoundary subject="actions" action="LIST">
            <StyledLink to={libraryPath}>Action Library</StyledLink>
          </AuthBoundary>
          <AuthBoundary subject="components" action="LIST">
            <StyledLink to={componentsPath}>Components</StyledLink>
          </AuthBoundary>
          <StyledLink to={packagesPath}>Packages</StyledLink>
        </StackedNav>
      </Col>
      <Col span={20}>{props.children}</Col>
    </Content>
  )
}

export default withCustomer(WorkflowsContent)
