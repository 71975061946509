import { curry } from "lodash"

export const checkIfDbUser = customers => {
  return customers?.some(customer => customer.normalizedName === "delta-bravo")
}

const dbName = "delta-bravo"
const allow = "ALLOW"

/*
 * Creates a regex from the given string, turning a string of "*" into a
 * wildcard.
 */
const createRegex = str => {
  const isWildcard = str === "*"
  if (isWildcard) return new RegExp(".*")
  return new RegExp(str)
}

/*
 * Given a policy, check if the policy's rule attribute contains an item at
 * the given key that has a value that is a regex match for one of the rules
 * contained in the policy. Curry it for easier use.
 */
export const checkPolicyContains = curry(
  (key, value, valueId, effect, policy) => {
    if (policy.rule.effect !== effect) return false
    const items = policy.rule[key]
    const valueWithId = value + (valueId ? `/${valueId}` : "")
    const testItem = item => {
      // if item contains a '/', we can assume it's looking for a specific id
      if (item.includes("/")) return createRegex(item).test(valueWithId)
      return createRegex(item).test(value)
    }
    return Boolean(items?.some(testItem))
  }
)

export const checkPolicyContainsAction = checkPolicyContains("actions")
export const checkPolicyContainsSubject = checkPolicyContains("subjects")

export const checkForRole = ({
  roles,
  subject,
  resourceId,
  action,
  effect = allow,
}) => {
  return roles?.some(role => {
    return (
      role?.policies?.some(
        checkPolicyContainsSubject(subject, resourceId, effect)
      ) &&
      role?.policies?.some(
        checkPolicyContainsAction(action, resourceId, effect)
      )
    )
  })
}

export const checkForDBAdmin = customers => {
  const db = customers?.find(customer => customer.normalizedName === dbName)
  const isAdmin = checkForRole({
    roles: db?.roles || [],
    subject: "*",
    action: "*",
  })

  return isAdmin
}
