import React from "react"
import styled from "styled-components"
import { useQuery } from "@apollo/client"

import { ErrorMessage, Spinner, Input } from "@dbai/ui-staples"

import { GET_WORKFLOW_NOTEBOOK_VERSION } from "queries"

// StyledInput has been duplicated way to many times.
const StyledInput = styled(Input)`
  && {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;

    &:focus {
      box-shadow: none;
      border-bottom-width: 2px;
    }
  }
`

const ImageFormatter = ({ value, row, onChange }) => {
  const { loading, data, error } = useQuery(GET_WORKFLOW_NOTEBOOK_VERSION)
  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  if (row.status !== "DEV") {
    return value || null
  }

  return (
    <StyledInput
      name="image"
      value={value || data.workflowNotebookVersion.version}
      onChange={onChange}
      className="form-control"
      noWrapper
      disabled
    />
  )
}

export default ImageFormatter
