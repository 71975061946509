import React, { useMemo } from "react"
import { Link } from "react-router-dom"
import { Table } from "antd"

import { stringSorter, dateSorter, logger } from "@dbai/tool-box"

import DateFormatter from "./formatters/DateFormatter"

/*
 * renderGuard adds a function that checks the arrity of the given render
 * function. If the arrity is > 1 then it collects the args and passes them to
 * the function. If not, it collects `value` and `row`, bundles them into an
 * object, and passes them to the function. This allows us to pass a normal
 * react component instead of just render functions.
 */
const renderGuard = func => {
  if (typeof func !== "function") return
  if (func.length > 1) {
    return (...args) => func(...args)
  }

  return (value, row) => func({ value, row })
}

const addRenderGuard = columns => {
  return columns.reduce((acc, column) => {
    return [
      ...acc,
      {
        ...column,
        render: renderGuard(column.render),
      },
    ]
  }, [])
}

const timeColumns = [
  {
    type: "date",
    key: "createdAt",
    title: "Created",
    dataIndex: "createdAt",
    sorter: dateSorter("createdAt"),
    render: (_, row) => {
      return <DateFormatter value={row.createdAt} />
    },
  },
  {
    type: "date",
    key: "updatedAt",
    title: "Updated",
    dataIndex: "updatedAt",
    sorter: dateSorter("updatedAt"),
    render: (_, row) => {
      return <DateFormatter value={row.updatedAt} />
    },
  },
]

const DEFAULT_COLUMNS = [
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
    sorter: stringSorter("name"),
  },
  ...timeColumns,
]

const makeDefaultLinkColumns = linkFn => [
  {
    key: "name",
    title: "Name",
    dataIndex: "name",
    sorter: stringSorter("name"),
    render: (text, row) => {
      return <Link to={linkFn(row)}>{text}</Link>
    },
  },
  ...timeColumns,
]

const mergeColumns = (defaults, columns = []) => [
  ...defaults,
  ...addRenderGuard(columns),
]

const defaultColumnOrder = ["name", "createdAt", "updatedAt"]

const logWarning = (column, columns) => {
  const keys = columns.map(c => c.dataIndex)
  logger.warn(`"${column}" not found in keys: ${keys}`)
}

const retrieveColumn = (columns, defaultSort = {}, column) => {
  const desiredValue = columns.find(c => c.dataIndex === column)
  if (!desiredValue) logWarning(column, columns)

  if (column === defaultSort.column) {
    return {
      ...desiredValue,
      defaultSortOrder: defaultSort.order,
    }
  }

  return desiredValue
}

const createColumns = (
  columns = DEFAULT_COLUMNS,
  columnOrder = defaultColumnOrder,
  defaultSort
) => {
  return columnOrder.reduce((acc, column) => {
    const foundColumn = retrieveColumn(columns, defaultSort, column)
    if (!foundColumn) return acc
    return [...acc, foundColumn]
  }, [])
}

const createActions = (Actions, columns) => {
  if (!Actions) return columns
  return [
    ...columns,
    {
      title: "",
      width: 1,
      key: "actions",
      dataIndex: "actions",
      render: (value, row) => {
        return <Actions value={value} row={row} />
      },
    },
  ]
}
/*
 * Creates a table with some sane defaults.
 * `columns` is expected to be an object containing valid AntD column objects.
 * Note that these columns can either contain "render functions" under the
 * `render` attribute (as AntD demands) or a React component. These objects
 * will be merged with the `defaultColumns` object, favoring column definitions
 * provided in the props here.
 * `columnOrder` should be an array containing strings that correspond to keys
 * of attributes of `columns` and these strings should be ordered
 * first-to-last corresponding to the order that you want the columns to render
 * in, left-to-right.
 * Example: ["name", "created", "updated"]
 * If `Actions` is provided it is expected to be a valid React component. The
 * given component will be utilized to create our standard "actions" column
 * and this will be added to the available columns under the key `actions`.
 * All other props should correspond to AntD Table props with some common
 * defaults applied over them.
 * If `defaultSort` is provided it is expected to have a valid AntD sort order
 * defined under the `order` attribute of the object and a `name` attribute
 * that coresponds to the key of the column that should be sorted. Note that
 * this functionality can also be accomplished by adding normal AntD options
 * directly to the column definition.
 */
const withDefaultTableColumns = TableComp => props => {
  const {
    columns,
    Actions,
    columnOrder,
    defaultSort,
    rowKey = "id",
    rowLink = null,
    size = "middle",
    pagination = false,
    emptyText = "No Records Found",
    ...rest
  } = props
  const tableColumns = useMemo(() => {
    const defaultColumns = rowLink
      ? makeDefaultLinkColumns(rowLink)
      : DEFAULT_COLUMNS
    const mergedColumns = mergeColumns(defaultColumns, columns)
    const constructedColumns = createActions(Actions, mergedColumns)
    return createColumns(constructedColumns, columnOrder, defaultSort)
  }, [columns, Actions, columnOrder, defaultSort, rowLink])
  return (
    <TableComp
      size={size}
      rowKey={rowKey}
      columns={tableColumns}
      pagination={pagination}
      locale={{ emptyText: emptyText }}
      {...rest}
    />
  )
}

const AltTable = withDefaultTableColumns(Table)
export { withDefaultTableColumns }
export default AltTable
